export default class SearchForm {
  toggle(element, elementAction = '.search-form', searchObject = '') {
    $(elementAction).toggleClass('d-none')
    if ($(element).is(':checked')) {
      if (searchObject) {
        localStorage.setItem(searchObject, true);
        sessionStorage.setItem(searchObject, true);
      }
    } else {
      if (searchObject) {
        localStorage.setItem(searchObject, false);
        sessionStorage.setItem(searchObject, false);
        sessionStorage.setItem(searchObject, '');
      }
    }
  }
  toggle2(element, elementAction = '.search-form') {
    $(element).closest('form').find(elementAction).toggleClass('d-none')
  }
  filterDisplay(type){
    const body = $('body');
    switch(type){
      case 'toggle':
        body.toggleClass('popup-on'); break;
      case 'sp':
        body.addClass('popup-on'); break;
      case 'spclose':
        $('.search-form-sp ').addClass('d-none');
        body.removeClass('popup-on'); break;
    }
  }

  selectSortDisplay(type, event){
    $('.dropdown-new-stype').addClass('d-none')
    switch(type){
      case 'toggle':
        $('#sortBox').toggleClass('d-none'); break;
      case 'sp':
        $('#sortBox').removeClass('d-none'); break;
      case 'spclose':
        $('#sortBox').addClass('d-none'); break;
    }
    $('.input-customize-sort-name').addClass('d-none')
    $('.customize-sort-name').removeClass('d-none')
    event.stopPropagation();
  }

  selectFilterDisplay(type, element, event){
    $('.filter-content').addClass('d-none')
    if (!$('#sortBox').hasClass('d-none')) $('#sortBox').addClass('d-none');
    switch(type){
      case 'toggle':
        $(element).toggleClass('d-none'); break;
      case 'sp':
        $(element).removeClass('d-none'); break;
      case 'spclose':
        $(element).addClass('d-none'); break;
    }
    Shared.checkShowPopupForNewSearch($(element));
    if(event) event.stopPropagation();
  }

  resetDataSearchAll(ele) {
    $('button.reset-data').click()
    $(ele).parents('.filter-content').find('input[type="text"]').val('')
    const form = $(ele).parents('form')
    form.find('input[type="text"]:not(.item_each_page_search_new)').val('').trigger('change')
    form.find('.option').removeClass('slted')
    form.find('input[type="checkbox"]').prop('checked', false).trigger('change')
    this.selectFilterDisplay('toggle', '.filter-common-all')
    this.countNumberForSearchNew(ele)
  }

  resetDataSearch(element) {
    $(element).closest('.filter-content').find('input').val('')
    $(element).closest('.filter-content').find('input[type="checkbox"]').prop('checked', false)
    $(element).closest('.filter-content').find('.option').removeClass('slted')
    $(element).closest('.container-new.search').find('input').val('')
    $(element).closest('.container-new.search').find('.option').removeClass('slted')
    $(element).closest('.container-new.search').find('input[type="checkbox"]').prop('checked', false)
    $(element).closest('.filter-content').find('#type_id').val('id_cont').change()
    if ($(element).closest('.filter-content').find('#type_id').length > 0) {
      $(element).closest('.filter-content').find('#type_id').val($(element).closest('.filter-content').find('#type_id option:first').val()).change()
    }
    if ($(element).parents('.status-filter-search')) {
      $(element).parents('.status-filter-search').find('div.option-all').trigger('click')
    }
    this.countNumberForSearchNew(element)
  }

  filterCount(type, element){
    const countFilter = $('#countFilter')
    const count = $('#countFilterQuotation')
    let countNumber = 0
    let inputsSearch = $('#popupFilter').closest('form').find('.search_outer').find('input')
    let inputsIndex = $('#popupFilter').find('.input_for_count');
    let inputsDate = $('#popupFilter').find('.date');
    let checkboxsStatus = $('#popupFilter').find('.check-box-status');
    let selects = $('#popupFilter').find('select:not(#type_id):not(.sort_items):not(.sort_type)');

    countNumber = this._handleCountNumber(inputsSearch, countNumber)
    countNumber = this._handleCountNumber(inputsIndex, countNumber)
    countNumber = this._handleCountNumber(inputsDate, countNumber)
    countNumber = this._handleCountNumber(checkboxsStatus, countNumber,'checkbox')
    countNumber = this._handleCountNumber(selects, countNumber)

    if (type === 'deselectAll') {
      const popupFilter = $('.search-form')
      popupFilter.find('select:not(.sort_items):not(.sort_type)').val('').trigger('change')
      popupFilter.find('.datetimepicker').val('');
      popupFilter.find('input').val('')
      popupFilter.find('.countFlg').text('');
      popupFilter.find('.status_label').find(':checkbox').prop('checked', false);
      popupFilter.find('.selectpicker').val('');
      $('.search-form__text_field').val('')
      $('.selectpicker').selectpicker('refresh')
      countNumber = 0;
    }
    if(countNumber === 0) {
      countFilter.removeClass('text-danger')
      count.removeClass('text-danger');
    }else {
      countFilter.addClass('text-danger')
    }
    countFilter.text(countNumber)
    count.text(countNumber)
    this.countNumberForSearchNew(element)
  }

  _handleCountNumber(array, countNumber,type = '') {
    array.toArray().forEach(element => {
      if (type === 'checkbox') {
        if ($(element).is(':checked')) countNumber++
      }else {
        if ($(element).val() && $(element).val().length > 0) countNumber++
      }
    });
    return countNumber
  }



  handleCounterSearch(type, element) {
    let popupFilterModal = $(element).parents('#popupFilterModal')
    let countFilter = popupFilterModal.find('#countFilterModal')
    let countNumber = 0
    let inputsDate = popupFilterModal.find('.form-search__input');
    let checkboxsStatus = popupFilterModal.find('.check-box-status');
    let selects = popupFilterModal.find('select:not(#type_id)');

    countNumber = this._handleCountNumber(inputsDate, countNumber)
    countNumber = this._handleCountNumber(checkboxsStatus, countNumber,'checkbox')
    countNumber = this._handleCountNumber(selects, countNumber)

    if (type === 'deselectAll') {
      const popupFilter = $('.search-form')
      popupFilter.find('select:not(.sort_items):not(.sort_type)').val('')
      popupFilter.find('.datetimepicker').val('');
      popupFilter.find('input').val('')
      popupFilter.find('.countFlg').text('');
      popupFilter.find('.status_label').find(':checkbox').prop('checked', false);
      countNumber = 0;
    }
    if(countNumber == 0)
      countFilter.removeClass('text-danger')
    else
      countFilter.addClass('text-danger')
    countFilter.text(countNumber)
  }

  filterDisplay2(type, element){
    const parentForm = $(element).closest('form');
    switch(type){
      case 'toggle':
        parentForm.toggleClass('popup-on2'); break;
      case 'sp':
        parentForm.addClass('popup-on2'); break;
      case 'spclose':
        parentForm.removeClass('popup-on2'); break;
    }
  }

  settingSortCustomizeShowAgain(element, type) {
    const url = Routes.setting_show_again_sort_customize_owner_users_path();
    const check_status = $(element).is(":checked");
    $('#notifyChangeSortProject').attr('data-show-notify', check_status)
    $('#sortBox').attr('data-show-notify', check_status)
    Shared.callAjax(url, { checked: check_status, type: type }, 'script', 'POST')
  }

  selectMultiOptionModal(type, value, element){
    const classOfElement = $(element).attr('class');
    if(classOfElement.includes('slted')) {
      $(element).removeClass('slted')
      $(element).find("input[type='checkbox']").prop('checked', false)
    } else {
      $(element).addClass('slted')
      $(element).parent().find('.option-all').removeClass('slted')
      $(element).find("input[type='checkbox']").prop('checked', true).change()
    }
    const parentElement = $(element).parents('.dropdown-body')
    let checkedAll = true;
    let uncheckAll = true;
    parentElement.find('.option:not(.option-all)').each(function(){
      if (!$(this).hasClass('slted')) {
        checkedAll = false;
      }
      if ($(this).hasClass('slted')) {
        uncheckAll = false;
      }
    });
    if ((checkedAll || uncheckAll) && parentElement.find('.option-all').length > 0) {
      parentElement.find('.option:not(.option-all)').each(function(){
        $(this).removeClass('slted');
        $(this).find("input[type='checkbox']").prop('checked', false)
      });
      parentElement.find('.option-all').addClass('slted')
    }
    this.countNumberForSearchNew(element)
  }

  countNumberForSearchNew(ele) {
    return
    const form = $(ele).parents('form')
    const count = this.calculatorCountNumberSearchNew(form)
    $(`div[data-target="#modalFilterCommonAll"]`).find('span.number').text(`${count}`)
    const divAll = $(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.filter-common-all', event)"]`)
    divAll.find('.number').text(count)
    if (count > 0) divAll.find('.number').removeClass('d-none')
    this.countNumberFilter(ele)
  }

  countNumberFilter(ele) {
    if ($(ele).parents('td').length > 0) {
      const count = this.calculatorCountNumberSearchNew($(ele).parents('td'))
      const divAll = $(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.filter-common-all', event)"]`)
      const dataFilter = $(ele).parents('td').find('.filter-content').data('filter')
      const divEle = divAll.parents('td').find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.${dataFilter}', event)"]`)
      if ($(ele).parents('.filter-common-all').length === 0) {
        $($(ele).parents('td').find('span.number')[0]).text(`${count}`)
      }
      if (count > 0) {
        $($(ele).parents('td').find('span.number')[0]).removeClass('d-none')
        divEle.find('.count-filter').removeClass('d-none')
      } else {
        $($(ele).parents('td').find('span.number')[0]).addClass('d-none')
        divEle.find('.count-filter').addClass('d-none')
      }
      divEle.find('.count-filter').text(count)
    } else {
      const form = $(ele).parents('.modal')
      const idForm = form.attr('id')
      const count = this.calculatorCountNumberSearchNew(form)
      const divShowCount = $(`div[data-target="#${idForm}"]`)
      if (count > 0) {
        divShowCount.find('.count-filter').removeClass('d-none')
      } else {
        divShowCount.find('.count-filter').addClass('d-none')
      }
      divShowCount.find('.count-filter').text(count)
    }
  }

  calculatorCountNumberSearchNew(ele) {
    let count = 0
    count = $(ele).find("input[type='checkbox']:checked").length
    const listInput =
      $(ele).find("input:not(:checkbox):not(.input-customize-sort-name):not(.item_each_page_search_new):not(#items_each_page)").toArray()
    listInput.forEach(input => {
      if ($(input).val() && $(input).val() !== '') {
        count += 1
      }
    })
    return count
  }

  removeOptionSelected(element) {
    $(element).parent().find('.option').removeClass('slted')
    $(element).addClass('slted')
    $(element).parent().find('input').val('')
    $(element).parent().find('.option').find('input').val('')
    $(element).parent().find('.option').find('input').prop('checked', false)
  }

  removeOptionCheckbox(element) {
    $(element).parent().find('.option').removeClass('slted')
    $(element).addClass('slted')
    $(element).parent().find('.option').find('input').prop('checked', false)
    this.countNumberForSearchNew(element)
  }

  handleSelectSort(ele, attribute, value = 'desc') {
    const componentSort = $(ele).parents('.sort_list')
    componentSort.find('div.sort_item').removeClass('slted')
    componentSort.find('li.sort-option').removeClass('slted')
    $(ele).addClass('slted')
    $(ele).parents('li.li-sort-list').find('div.sort_item').addClass('slted')
    componentSort.find('#q_s').val(`${attribute} ${value}`)
  }
}

$(function () {
  // date select in popupfilter
  $('#popupFilter').find('.datetimepicker').datetimepicker({
    format: 'YYYY/MM/DD',
    locale: 'ja',
    useCurrent: false,
  }).on('dp.change', function(e){
    const countFilterDate = $('#countFilter')
    let countNumber = parseInt(countFilterDate.text())
    let countFlg = $(this).closest('.search_popup_box').find('.countFlg');
    let flg = countFlg.text();
    if(!e.date){
      // un selected
      if(flg == 'selected'){  // selected -> non select
        countNumber -= 1
        countFlg.text('')
      }
    }else{
      // selected
      if(flg == ''){  // non select -> selected
        countNumber += 1
        countFlg.text('selected')
      }
    }
    if(countNumber == 0)
      countFilterDate.removeClass('text-danger')
    else
      countFilterDate.addClass('text-danger')
    countFilterDate.text(countNumber)
    $('.bootstrap-datetimepicker-widget').hide()
  })
});
