import React, { Component } from 'react';
import { Button, Input, Modal } from 'reactstrap';

export default class ModalConfirmSendMailNotLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  toggle = () => {
    this.props.togglePopup()
  }

  render() {
    const { sendMailNotLogin, isOpen } = this.props

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.toggle()}
        className="modal-dialog-centered w-fit-content m-auto modal-style"
        modalClassName="modal-second"
      >
        <div className='swal2-html-container text-center'>
          <div class="text-center font-weight-bold text-black">
            メンバーにコメントを通知しますか？
          </div>
          <div className="mt-3">
            <button type="button" onClick={() => this.toggle()} className="btn btn-red">コメントを続ける</button>
            <button type="button" onClick={() => sendMailNotLogin()} className="btn btn-black ml-3">送信する</button>
          </div>
        </div>
      </Modal>
    )
  }
}
