import './new_quotation'
import 'packs/jquery-ui.min'
import 'packs/sortable/sort_function'

export default class Quotation {
  fetchDataOnBasicInfo(eleProject, eleOwner, eleChanged, projectPresent = false) {
    const $eleOwner = $(eleOwner);
    const $eleProject = $(eleProject);
    const clientID = $eleProject.find('option:selected').attr('data-client-id')

    let projectId = $('#js-container-project-id').data('project-id');
    let quotationId = $('#quotation_id').val();
    let user = $('#input_select_user').val()
    let rfqId = $('input[name="quotation[request_id]"]').val();
    let ownerId = $eleOwner.val();

    if (!projectId) {
      $('.hidden-project').val(projectId);
      projectId = $eleProject.val();
    }

    if (eleChanged == 'project' && !projectId) ownerId = ''

    let params = {
      project_id: projectId,
      quotation_id: quotationId,
      owner_id: ownerId
    }
    const callAjax = Shared.callAjax(Routes.data_on_basic_info_quotations_path(), params, 'json', 'GET')
    const quatationModel = this
    callAjax.done(function(data) {
      if (data.success) {
        const isError = quatationModel.showPopupError(eleProject, eleOwner, eleChanged, data)
        if (isError) {
          return
        }
        defineOwner(ownerId);
        setPaymentStageType(data.billingType)
        if (data.isClientTemp) {
          $('#select_owner_users').attr('is-client-temp', 'true')
        } else {
          $('#select_owner_users').removeAttr('is-client-temp')
        }
        Visiable.toggle('.btn-save-q', data.isClientTemp)
        if (data.isClientTemp) {
          Visiable.disabled_change('#quotation_payment_stage_type')
          $('#quotation_payment_stage_type').val('契約通り').trigger('change');
        } else {
          Visiable.enabled_change('#quotation_payment_stage_type')
          Visiable.enabled_change('#quotation_contract_type')
          Visiable.enabled_change('#quotation_contract_sub_type')
          Visiable.enabled_change('#quotation_contract_duration')
          Visiable.enabled_change('[name="quotation[deadline_quotation]"]')
          Visiable.enabled_change('#quotation_setting_payment_method')
        }
        if (data.html_payment_methods) {
          if (!data.is_old_owner) {
            $('#quotation_setting_payment_method').text('設定されていません')
            $('input#set_quotation_payment_id').val('')
          }
          $('#content_list_payment_methods').empty().html(data.html_payment_methods)
        }
        if (!projectPresent) {
          Visiable.enabled_change('#select_project')
        } else {
          $eleProject.next("span").addClass('disabled');
        }
        if (data.is_owner && !!clientID && !ownerId) {
          if ($eleOwner.find(`option[value="${clientID}"]`).length > 0){
            $eleOwner.val(clientID).trigger('change')
          }
        }
        setCompanyUsersData(data.users, $('#select_owner_users'), data.firstUser);
        $('#select_owner_users').attr('required', !data.isClientTemp)
        if (data.isClientTemp) {
          $('button#setting_option_payment_method').trigger('click')
        }

        if(!$(document).find('.btn-approve').length) return

        const projectID = $eleProject.val();
        $(document).find('.btn-approve').attr('href', Routes.owner_quotation_approval_path(quotationId, {project_id: projectID}))
      } else {
        $eleOwner.val('')
        toastr.error('契約書又は支払サイトを締結する必要です。', "", {
          closeButton: true,
          progressBar: true,
        });
      }
    })
    .fail(function() {
      $eleOwner.val('')
      console.log('Call ajax fail');
    })
  }

  showPopupError(eleProject, eleOwner, eleChanged, data) {
    const $eleOwner = $(eleOwner);
    const $eleProject = $(eleProject);
    const ownerSelected = $eleOwner.find('option:selected')
    const projectSelected = $eleProject.find('option:selected')
    const quatationModel = this
    let is_error = false
    if (projectSelected.val() && projectSelected.attr('is_client') === 'false' && !data.not_owner_or_client) {
      Popup.alert(I18n.t('quotation.owner_select_supplier_to_client'))
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      return true
    }
    if (eleChanged === 'owner' && ownerSelected.attr('is_show_temp') &&
            ownerSelected.attr('is_show_temp') === 'true') {
      if (projectSelected.val() && projectSelected.attr('is_client') === 'true' &&
          data.type && data.type === 'owner') {
        Popup.alert(I18n.t('quotation.owner_select_client_not_correct_and_is_temp'))
        quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
        is_error = true
      } else if (projectSelected.val() && projectSelected.attr('is_client') === 'true' &&
                  data.type && data.type === 'supplier_owner') {
        Popup.alert(I18n.t('quotation.supplier_select_owner_not_correct_and_is_temp'))
        quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
        is_error = true
      } else {
        $('.btn-modal-q').trigger('click')
        is_error = true
      }
    }

    if (eleChanged === 'owner' && projectSelected.val() && projectSelected.attr('is_client') === 'true' &&
       data.type === 'owner' && (data.isClientTemp || data.client_is_not_correct)) {
      Popup.alert(I18n.t('quotation.owner_select_client_is_not_correct_and_is_temp'))
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      is_error = true
    } else if (eleChanged === 'owner' && projectSelected.val() &&
              data.client_is_not_correct && data.type === 'supplier_owner') {
      Popup.alert(I18n.t('quotation.supplier_select_iowner_is_correct_or_is_temp'))
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      is_error = true
    }

    if (data.is_client) {
      Popup.alert('販売先プロジェクトの為、<br>選択できません。')
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      is_error = true
    } else if(data.client_is_not_correct && eleChanged === 'project') {
      let message = I18n.t('quotation.client_is_not_correct')
      if (data.type && data.type === 'supplier_owner') {
        message = I18n.t('quotation.supplier_select_client_not_correct')
      }
      Popup.alert(message)
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      is_error = true
    } else if(eleChanged === 'project' && ownerSelected.attr('is_show_temp') &&
             ownerSelected.attr('is_show_temp') === 'true' && projectSelected.val() &&
             projectSelected.attr('is_client') === 'true') {
      Popup.alert(I18n.t('quotation.client_is_not_correct'))
      quatationModel._resetProjectSelected($eleProject, eleOwner, eleChanged)
      is_error = true
    }
    return is_error
  }

  _resetProjectSelected(eleProject, eleOwner, eleChanged) {
    if (eleChanged === 'project') {
      eleProject.val('')
      eleProject.parents('div.show-project-info').find('.select2-selection__rendered')
                .text('プロジェクトを選択してください')
    } else {
      $(eleOwner).val('')
      $(eleOwner).parents('div.company-name').find('.select2-selection__rendered')
                 .text('会社名を選択してください')
      setCompanyUsersData([], $('#select_owner_users'))
    }
  }

  edit(element, periods) {
    Visiable.hide(element)
    Visiable.show($('.btn-submited-quotation-draft'))
    this.fetchDataOnBasicInfo('#select_project', '#select_owner_company', 'owner')
    this.enableSortQuotation()
    $('#quotation-form').attr('action_quotation', 'edit')
    Visiable.show('.hide-button')
    Visiable.hide('.issue-button')
    $('.accordion_box').find($('select')).prop('disabled', false)
    $('.document-name').addClass('d-flex')
    Visiable.hide($('#draft-btn'));
    Shared.toggleDisabledClass($('.js-disabled'), false)
    Shared.toggleDisabledClass($('.disabled:not(.creater, .created_at_element, .unit)'), false)
    $('#new_client').find('.client-temp-id').val($('#select_owner_company option:selected').val())
    this.enabledSelectIcon();
    if ($('.remove_fields').length == 1) {
      Shared.toggleDisabledClass($('.remove_fields'), true)
    }
    switch($('#quotation_contract_sub_type').val()) {
      case 'other':
        $('#quotation_payment_stage_type').removeClass('disabled')
        break;
      case 'by_hours':
        $('#quotation_payment_stage_type').removeClass('disabled')
        break;
      default:
        $('#quotation_payment_stage_type').val('契約通り');
        $('#quotation_payment_stage_type').addClass('disabled')
        $('.js-clone_quotation_payment_stage').addClass('d-none')
        $('.quantity_to_number_currency').addClass('disabled').attr('disabled', true)
      }
    if (!periods)
      return
    $('.td-checkbox').each(function(){
      if ($(this).children('input[type="radio"]').attr('checked') == 'checked') {
        $(this).children("i").removeClass('d-none')
        if (!$(this).hasClass('disabled')) {
          Disabled.toggle($(this).next('td').children('input'), false)
          Required.add($(this).next('td').children('input'))
          Disabled.toggle($(this).next('td').children(), false)
        }
      } else {
        $(this).children("i").addClass('d-none')
        Disabled.toggle($(this).next('td').children('input'), true)
        Required.remove($(this).next('td').children('input'))
        Shared.toggleDisabledClass($(this).next('td').children(), true)
      }
    })
  }

  enabledSelectIcon(){
    $('.arrow-q').find('i.fa-chevron-down').removeClass('d-none');
    $('table.table-qt').find('tr').find('th:first').addClass('d-xx-table-cell');
    $('table.table-qt').find('tr').find('td:first').addClass('d-xx-table-cell');
  }

  disabledNotChooseCompany() {
    $('.contract-info').find('select, input, .fa-chevron-down').addClass('disabled')
    $('.quotation-item').find('a, button').addClass('disabled')
    $('.quotation-policy').find('select, button').addClass('disabled')
    $('#quotation_setting_payment_method').addClass('disabled')
  }

  enabledChooseCompany() {
    $('.contract-info, .quotation-item').find('select, input, .fa-chevron-down').removeClass('disabled')
    $('.quotation-item').find('a.add_fields, button').removeClass('disabled')
    if ($('.quotation-item').find('a.remove_fields').length > 1)
      $('.quotation-item').find('a.remove_fields').removeClass('disabled')
    $('.quotation-policy').find('select, button').removeClass('disabled')
    $('#quotation_setting_payment_method').removeClass('disabled')
    if ($('#quotation_contract_sub_type').val() == 'by_number'|| $('#quotation_contract_sub_type').val() == 'by_time') {
      $('input.quantity_to_number_currency').addClass('disabled');
    }
  }

  changeRequestForQuotation() {
    const $eleRequest = $("#request-select option:selected")
    setRequestVal($eleRequest)
    if (!$eleRequest.val())
      return
    let created = $eleRequest.attr('created')
    let pic = $eleRequest.attr('pic')
    $.when($('#select_owner_company').val(created).trigger('change')).done(function(){
      setTimeout(function() {
        $('#select_owner_users').val(pic)
      }, 500)
    });
  }

  accordionToggle(element){
    if($(window).width() <= 768){
      const outer = $(element).closest('.accordion_outer');
      outer.toggleClass('show');
      outer.find('.accordion_box').slideToggle('normal', function(){
        $(this).removeAttr('style').toggleClass('show');
      });
    }
  }

  questionModalOpen(element){
    const questionOuter = $(element).next('.question_outer');
    questionOuter.addClass('show');
    $(questionOuter).find('question_answer').addClass('show');
  }
  questionModalClose(){
    $('.question_icon').removeClass('show');
    $('.question_outer').removeClass('show');
  }

  selectCompany(element) {
    const isTemp = $(element).find(':selected').attr('is_temp') === 'true';
    Visiable.toggle($('.btn-submit-quotation'), isTemp);
    Disabled.toggle($('#quotation_payment_stage_type'), isTemp);
    $('#select_project').prop('disabled', isTemp)
  }

  toggleOrders(element) {
    const ordersEle = $(element).parents('tr').next();
    const quotationId = $(element).parents('tr').attr('data-id');
    if ($(element).is(':checked')) {
      // setLocalStorage(quotationId);
      ordersEle.slideDown(300);
      ordersEle.find('th > div, td > div').slideDown(300);
    } else {
      // removeLocalStorage(quotationId)
      ordersEle.find('th > div, td > div').slideUp(300);
      ordersEle.slideUp(300);
    }
  }

  showListOrder() {
    let elements = $('.toggle-list-order')
    elements.toArray().forEach(ele => {
      this.toggleOrders(ele)
    })
  }

  showListOrderWithBack() {
    if (localStorage.getItem('list_order_show')) {
      const listQuotationShowOrder = localStorage.getItem('list_order_show').split(', ');
      $('.table-quotations').find('tr').toArray().forEach(tr => {
        listQuotationShowOrder.forEach(quotationId => {
          if ($(tr).attr('data-id') === quotationId) {
            $($(tr).find('.toggle-list-order').prop('checked', true));
            $(tr).next().slideDown(300);
            $(tr).next().find('th > div, td > div').slideDown(300);
          }
        })
      })
    }
  }

  copy(event, element, quotationId, projectId, isCopyFromEdit = false) {
    event.stopPropagation()
    let url;
    url = Routes.owner_quotation_copy_quotation_path(quotationId)
    Shared.callAjax(url, { is_copy_form_edit: isCopyFromEdit}, 'json', 'post')
    .done(function(data) {
      Popup.alert(I18n.t('quotation.copy.success'));
      location.href = `${Routes.owner_quotation_show_path(data.quotation_id)}/edit?quotation_for=client`
    })
    .fail(function (data) {
      Popup.alert(I18n.t('quotation.copy.error'))
    })
  }

  delete(element, quotationId) {
    const url = Routes.owner_owner_quotation_destroy_path(quotationId)
    let msg_delete_quotation = '';
    if(['order_received','ordered'].includes($(element).parents('tr').find('p[data-quotation-status]').data("quotation-status"))) {
      msg_delete_quotation = I18n.t('quotation.confirm_delete_orderd')
    }
    else {
    msg_delete_quotation = I18n.t('quotation.confirm_delete', { quotation_id: quotationId })
    }
    Popup.confirm('', {
      description: msg_delete_quotation,
      confirmText: 'OK',
      success: () => this._callDeleteQuotation(element, url, quotationId)
    })
  }

  delete_quotation_order(element, orderId) {
    const url = Routes.owner_cancel_quotation_order_path(orderId)
    Popup.confirm('', {
      description: I18n.t('quotation.confirm_delete_order'),
      confirmText: 'OK',
      success: () => this._callDeleteQuotationOrder(element, url, orderId),
      cancelBtn: true
    })
  }

  approval(dataObj) {
    let quotationId = $(dataObj.element).closest('tr').data('id') || $('#quotation_id').val()
    if(dataObj.url == '') return Popup.alert('error','error');
    if(dataObj.orderSize !== '' && parseInt(dataObj.orderSize) > 0) {
      let msg = dataObj.lastOrderDate.split('-');
      msg = `${msg[0]}年${msg[1]}月${msg[2]}日 に発注済です。<br>再度発注してよろしいですか？`
      Popup.confirm("", {
        description: msg,
        confirmText: 'OK',
        success: () => {
          this._callApprovalQuotation(dataObj.url, quotationId)
        }
      })
    }
    else {
      this._callApprovalQuotation(dataObj.url, quotationId)
    }
  }

  showPopupWhenSupplierCanceled() {
    Popup.alert('この会員は解約済です。')
  }

  searchStatus(element) {
    $(element).parents('.search-form').find('.search-status').val($(element).val());
    this.formSearchQuotation();
  }

  closed(quotationId) {
    const releaseDate = $('#modal-closed-status').find('.js-datepicker').val()
    if (releaseDate !== '') return
    Quotation._handleChangeStatusToClosed(quotationId)
  }

  releaseAllTicket(element, quotationId, releaseAllTicket) {
    const releaseDate = $(element).parents('.modal-body').find('.js-datepicker').val();
    if (releaseDate === '' || releaseDate === undefined || isNaN(new Date(releaseDate))) return
    Popup.confirm('', {
      description: I18n.t('quotation.confirm_release_ticket_all', { date: I18n.l('date.formats.ja', new Date(releaseDate))} ),
      confirmText: 'OK',
      success: () => {
        Quotation._handleChangeStatusToClosed(quotationId, releaseAllTicket, releaseDate)
      }
    })
  }

  selectPaymentMethod() {
    $('#modalPaymentMethodForQuotationOrder').modal('show')
  }

  static _handleChangeStatusToClosed(quotationId, releaseAllTicket = 'false', releaseDate = '') {
    let data = {};
    if (releaseAllTicket === 'true' && releaseDate !== '') {
      data = { release_all_ticket: releaseAllTicket, release_date: releaseDate }
    }
    Shared.callAjax(Routes.owner_quotation_close_quotation_path(quotationId), data).done(function(data) {
      showPopupStatusToClosed(quotationId, 'success')
    })
    .fail(function(data) {
      showPopupStatusToClosed(quotationId, 'error')
    })
  }

  _callDeleteQuotation(element, url, quotationId) {
    Shared.callAjax(url, {}, 'json', 'delete').done(function(data) {
      if (data.success) {
        $(element).parents('tr').remove();
        location.href = `${Routes.owner_owner_quotations_list_path()}?deleted_id=${quotationId}`
      } else {
        location.href = `${Routes.owner_owner_quotations_list_path()}?deleted_error=true`
      }
    })
  }

  _callDeleteQuotationOrder(element, url, orderId) {
    Shared.callAjax(url, {}, 'json', 'delete').done(function(data) {
      if (data.success) {
        $(element).parents('tr')[0].remove();
        Popup.alert(I18n.t('quotation.delete.error_order_quotation',{order_id: orderId}))
      } else {
        location.href = '/quotations?deleted_error=true'
      }
    })
  }

  _callApprovalQuotation(url, quotationId) {
    Shared.callAjax(url, {}, 'json', 'post').done(function(data) {
      if (data.success) {
        setLocalStorage(quotationId);
        Popup.confirm("",{
          description: data.message,
          confirmText: 'OK',
          cancelBtn: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          success: () => {
            if(location.pathname == Routes.owner_owner_received_quotations_list_path()){
              location.reload()
            }else{
              location.href = Routes.owner_owner_received_quotations_list_path()
            }

        }})
      } else {
        toastr.error(data.message, "", {
          closeButton: true,
          progressBar: true,
        });
      }
    }).fail(() => {
      toastr.error(I18n.t('shared.one_error_please_try_again'), "", {
        closeButton: true,
        progressBar: true,
      });
    })
  }

  changeTextContractDate(element, bindElement) {
    if ($(element).val() == 0) {
      $(element).val(1)
    }
    $(bindElement).text('現在の設定は、契約後に自動更新日の【 ' + $(element).val() + ' 日前】に取引先に通知されます。')
  }

  showHideSearch() {
    $('.search-form ').removeClass('d-none');
    $('.search-form-sp ').removeClass('d-none');
    $('#popupFilter').trigger('click');
  }

  formSearchQuotation() {
    const countFilter = $('#countFilterQuotation');
    let countNumber = 0 ;
    let searchFields = $('.search-field')
    searchFields.toArray().forEach((searchField) => {
      if ($(searchField).val()) {
        countNumber++
      }
    })

    countFilter.text(countNumber)
    if (countNumber !== 0) countFilter.addClass('text-danger')
  }

  setValueCompany(element) {
    $('.search-duplicate').val($(element).val());
    if ($(element).hasClass('search-field')) this.formSearchQuotation()
  }

  showExplainReleaseStep2(element) {
    const modalBody = $(element).parents('.modal-body')
    modalBody.find('.show-explain-step1').addClass('d-none')
    modalBody.find('.show-explain-step2').removeClass('d-none')
    modalBody.find('.show-explain-release-date').removeClass('d-none')
  }

  showExplainReleaseStep1(element, quotationId) {
    Shared.callAjax(Routes.quotation_work_present_path(quotationId), {quotation_id: quotationId}).done(function(data) {
      Quotation._showPopupConfirmStatusToEnd(quotationId)
    }).fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  static _showPopupConfirmStatusToEnd(quotationId) {
    Popup.confirm('', {
      description: I18n.t('quotation.confirm_end', { quotation_id: quotationId }),
      confirmText: 'OK',
      success: () => {
        Quotation._handleChangeStatusToClosed(quotationId)
      }
    })
  }

  // static _showExplainRelease(element, quotationId) {
  //   console.log(element)
  //   const modalEle = $(`#${element}`)
  //   modalEle.find('.show-explain-step1').removeClass('d-none')
  //   modalEle.find('.show-explain-step2').addClass('d-none')
  //   modalEle.find('.show-explain-release-date').addClass('d-none')
  //   modalEle.find('.show-explain-release-date-2').addClass('d-none')
  //   modalEle.find('.btn-status-to-closed').attr('onclick',`Quotation.releaseAllTicket(this, '${quotationId}', 'true')`)
  //   modalEle.find('.btn-status-to-closed-not-release').attr('onclick',`Quotation.closed('${quotationId}')`)
  //   modalEle.find('.quotation-id').val(quotationId)
  //   modalEle.find('.js-datepicker').val('').attr('onchange', `Quotation.checkReleaseDate(this, '${quotationId}')`)
  //   modalEle.modal('show')
  // }

  checkReleaseDate(element, quotationId) {
    const modalBody = $(element).parents('.modal-body')
    modalBody.find('.show-explain-release-date').addClass('d-none')
    modalBody.find('.show-explain-release-date-2').removeClass('d-none')
    const releaseDate = $(element).val()
    if (releaseDate === '' || releaseDate === undefined || isNaN(new Date(releaseDate))) {
      modalBody.find('.show-release-all').removeAttr('disabled')
      return
    }
    const url = Routes.owner_quotation_check_release_date_path(quotationId)
    Shared.callAjax(url, { release_date: releaseDate }).done(function(data) {
      if (data.is_error) {
        Popup.alert(I18n.t('quotation.not_select_release_date'))
        $(element).val('');
        Shared.errorFieldMessage($(element), I18n.t('quotation.not_select_release_date'))
      } else {
        modalBody.find('.show-release-all').attr('disabled', true)
      }
    }).fail(function() {
      Popup.alert(I18n.t('shared.one_error_please_try_again'));
    })
  }

  showPopupWarningMustSelectPaymentMethod() {
    $('#modalWarningMustSelectPaymentMethod').modal('show');
  }

  initQuotationSort() {
    var fixHelperModified = function(e, tr) {
      var $originals = tr.children();
      var $helper = tr.clone();
      $helper.children().each(function(index)
      {
        $(this).outerWidth($originals.eq(index).outerWidth())
      });

      return $helper;
    };
    $(".quotation_item_table").sortable({
      cursor: 'grabbing',
      start: function(event, ui) {
        ui.helper.css('display', 'table')
      },
      stop: function(event, ui) {
        ui.item.css('display', '')
      },
      helper: fixHelperModified,
      start: function(event, ui) {
        return ui.placeholder.children().each(function(index, child) {
          var source;
          source = ui.helper.children().eq(index);
          $(child).removeAttr('class').removeAttr('colspan');
          $(child).addClass(source.attr('class'));
          if (source.attr('colspan')) {
            return $(child).attr('colspan', source.attr('colspan'));
          }
        });
      },
      update: function(event, ui) {
        $(".quotation_item_table").find('.nested-fields').each(function(index) {
          $( this ).find('.temp_sort').val(index + 1)
        });
      }
    });

    if(Shared.checkDeviceWithTouchAction()) {
      this.disableSortQuotation()
    }
  }

  disableSortQuotation() {
    $(".quotation_item_table").sortable("disable");
  }

  enableSortQuotation() {
    $(".quotation_item_table").sortable("enable");
  }

  setDataSortQuotationItem() {
    $(".quotation_item_table").find('.nested-fields').each(function(index) {
      $( this ).find('.temp_sort').val(index + 1)
    });
  }
}
const quotation = new Quotation;

function showPopupStatusToClosed(quotation_id, type) {
  Popup.confirm('', {
    description: I18n.t(`quotation.status_to_closed.${type}`, { quotation_id: quotation_id }),
    cancelBtn: 'false' , success: () => { location.reload() }, cancel: () => { location.reload() },
  })
}

function setRequestsData(requests, element, rfqId) {
  element.empty().append("<option value>見積依頼を選択してください</option>");
  if (requests) {
    requests.forEach(request => {
      element.append('<option value="' + request.id + '" created="' + request.owner_id + '"  pic="' + request.person_in_charge_id + '">'
      + getDescription(request) + '</option>');
    })
    if (rfqId) {
      element.val(rfqId);
      setRequestVal(element.find('option:selected'));
    }
  }
}

function setCompanyData(companies, element, companyID, domain_owner) {
  if (domain_owner)
    element.empty().append(`<option value='new_client'>${I18n.t('quotation.add_temporary_client')}</option>`);
  else
    element.empty().append(`<option value='new_client'>${I18n.t('quotation.select_owner_company')}</option>`);
  if (companies) {
    companies.forEach(company => {
      element.append(`<option value="${company[1]}" is_temp="${company[2].is_temp}">${company[0]}</option>`);
    })
  }
  element.val(companyID)
}

function getDescription(request_for_quotation) {
  let description = request_for_quotation.id + request_for_quotation.name_project_type;
  if (request_for_quotation.information) {
    description = '(ID) ' + request_for_quotation.id + ' (依頼日) ' + request_for_quotation.created_at.substring(0, 10) +
    ' (種別) ' + request_for_quotation.name_project_type
  }

  return description;
}

function setPaymentStageType(billingType) {
  const $elePaymentStageType = $('select[name="quotation[payment_stage][type]"]')
  if ($elePaymentStageType.attr('data-form') == '') {
    if (billingType == 'bill_once_time') {
      $elePaymentStageType.attr('data-value', 'billed_each_time').removeClass('d-disabled');
    } else if(billingType == 'bill_close_date'){
      $elePaymentStageType.val('契約通り').trigger('change');
      $elePaymentStageType.attr('data-value', 'closing').addClass('d-disabled');
      $elePaymentStageType.prevAll('.fa-chevron-down').addClass('disabled');
    }else{
      $elePaymentStageType.attr('data-value', 'billed_each_time').removeClass('disabled');
      $elePaymentStageType.removeClass('d-disabled');
    }
  }
}

function defineOwner(value) {
  if ($('#quotation-form').attr('action_quotation') == 'show') return;
  let element = $('#quotation_contract_type, #quotation_contract_sub_type, #quotation_payment_stage_type')
  if ($('#quotation_payment_stage_type').attr('data-edit-form') == 'edit')
    Shared.toggleDisabledClass(element, false)
  else
    Shared.toggleDisabledClass(element, true)
  if ($('#quotation_payment_stage_type').attr('rfq') != '' && $('#quotation_payment_stage_type').attr('rfq') != undefined)
    Shared.toggleDisabledClass(element, false)
  if ($('#select_owner_company').val() == '' || $('#select_owner_company').val() == undefined)
    quotation.disabledNotChooseCompany()
  else
    quotation.enabledChooseCompany()

  if ($('#quotation_payment_stage_type').attr('data-form') == '') {
    if (value != '') {
      Shared.toggleDisabledClass(element, false)
      Disabled.toggle(element, false)
      Disabled.toggle($('button#select-plan'), false)
      $('a.add_fields').show();
    } else {
      Visiable.hide('#add_split')
    }
  } else {
    Disabled.toggle($('#quotation_payment_stage_type'), true)
  }
}

function setCompanyUsersData(arr, element, user = '') {
  element.empty()
  element.append('<option value>未選択</option>');
  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      let selected =  user && arr[i].name == user.name ? 'selected' : ''
      element.append('<option value="' + arr[i].id +  '" ' + selected +'>' + arr[i].name + '</option>');
    }
  }else {
    element.append("<option value>担当者名を選択してください</option>");
  }
}

function setProjectsData(arr, element) {
  element.attr('selected_project', element.val());
  element.empty().append("<option value>プロジェクトを選択してください</option>");
  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      element.append('<option value="' + arr[i].id + '">' + arr[i].name + '</option>');
    }
  }
  element.val(element.attr('selected_project'));
}

function setRequestVal($eleRequest) {
  $(".agency-input").val($eleRequest.text())
  $(".hidden-request-id").val($eleRequest.val())
}

function setLocalStorage(quotationId) {
  if (localStorage.getItem('list_order_show')) {
    localStorage.setItem('list_order_show', localStorage.getItem('list_order_show') + `, ${quotationId}`)
  } else {
    localStorage.setItem('list_order_show', quotationId)
  }
}

function removeLocalStorage(quotationId) {
  if (!localStorage.getItem('list_order_show')) return
  const listQuotationShowOrder = localStorage.getItem('list_order_show').split(', ');
  const listNew = listQuotationShowOrder.filter(item => item !== quotationId).join();
  localStorage.setItem('list_order_show', listNew);
}
