export default class Campaign {
  validateDiscountValueForAdmin(element) {
    const $ele = $(element);
    const discountValue = Number($ele.val());
    if (discountValue > 100 || discountValue < 0) {
      $ele.val('')
    }
  }

  validateDiscountValue(element) {
    const $ele = $(element);
    const discountValue = Number($ele.val());
    if (discountValue > 20 || discountValue < 0) {
      $ele.val('')
    }
  }

  submitCreateCampaign() {
    $("#addCampaignModal").find(".submit-button").attr("disabled", true)
    $("#addCampaignModal").find(".submit-create-form").click()
  }

  showEditCampaignModal(campaign_id) {
    $(".text-danger.render-by-js").remove();
    const modal = $("#editCampaignModal")
    const url = Routes.admins_campaign_path(campaign_id);
    const callAjax = Shared.callAjax(url, {}, 'json', 'GET');
    callAjax.done(function(data) {
      modal.find('#campaign_company_name').val(data.company_name)
      modal.find('#campaign_expired_date').val(data.expired_date)
      modal.find('#campaign_discount_value').val(data.discount_value)
      modal.find('#campaign_id').val(data.id)
      modal.find('.submit-button').attr('disabled', false)
      modal.modal('show')
    })
  }

  showModalCreateCampaignSP(e) {
    e.stopPropagation();
    $('.button-create-campaign').trigger('click')
    setTimeout(() => {
      $('.fm_item_outer').show()
    }, 200)
  }

  showSettingPaymentMethod(companyName, cacId) {
    const modal = $('#settingPaymentMethod');
    modal.find('#just_every_year').prop('checked', false);
    modal.find('#every_month_or_year').prop('checked', false);
    const paymentMethod = $(`#data_payment_method_${cacId}`).val();
    modal.find('.company-name').html(companyName);
    modal.find('#company_apply_campaign_id').val(cacId);
    modal.find('.payment-method-name').html(I18n.t(`shared.${paymentMethod}`))
    modal.find('#payment_method').val(paymentMethod);
    modal.find(`#${paymentMethod}`).prop('checked', true);
    modal.modal('show');
  }

  showSettingStorePaymentMethod(paymentMethod) {
    const currentPaymentMethod = $('#data_store_payment_method').val();
    const modal = $('#settingStorePaymentMethod');
    modal.find('#just_every_year').prop('checked', false)
    modal.find('#every_month_or_year').prop('checked', false)
    modal.find('.payment-method-name').html(I18n.t(`shared.${currentPaymentMethod}`))
    modal.find('#payment_method').val(paymentMethod);
    modal.find(`#${paymentMethod}`).prop('checked', true);
    modal.modal('show');
  }

  handleCheckboxSettingPaymentMethod(element, modalId) {
    const modal = $(`#${modalId}`);
    if($(element).attr('id') === 'every_month_or_year') {
      modal.find('#every_month_or_year').prop('checked', true);
      modal.find('#just_every_year').prop('checked', false);
      modal.find('#payment_method').val('every_month_or_year');
    } else {
      modal.find('#every_month_or_year').prop('checked', false);
      modal.find('#just_every_year').prop('checked', true);
      modal.find('#payment_method').val('just_every_year');
    }
  }

  submitChangePaymentMethod(){
    const modal = $('#settingPaymentMethod');
    const cacId = modal.find('#company_apply_campaign_id').val();
    const data = {
      payment_method: modal.find('#payment_method').val()
    }
    const url = Routes.owner_company_apply_campaign_path(cacId);
    const callAjax = Shared.callAjax(url, data, 'json', 'PATCH');
    callAjax.done(function(response) {
      modal.modal('hide');
      Popup.alert('支払方法が更新されました。');
      $(`#data_payment_method_${cacId}`).val(response.payment_method);
      $(`#payment_method_${cacId}`).html(I18n.t(`shared.${response.payment_method}`))
    })
  }

  submitChangeStorePaymentMethod(){
    const modal = $('#settingStorePaymentMethod');
    const data = {
      payment_method: modal.find('#payment_method').val()
    }
    const url = Routes.update_store_owner_company_apply_campaigns_path();
    const callAjax = Shared.callAjax(url, data, 'json', 'PATCH');
    callAjax.done(function(response) {
      modal.modal('hide');
      Popup.alert('支払方法が更新されました。');
      $('#data_store_payment_method').val(response.payment_method);
      $('#store_payment_method_name').html(I18n.t(`shared.${response.payment_method}`));
    })
  }

  copyResigterUrl(elementId) {
    $('#inviteUrlModal').modal('hide')
    Shared.copyText($(`#${elementId}`), '招待リンクをコピーしました。')
  }

  confirmSettingPaymentMethodDone(companyName, event) {
    Popup.alert(`${companyName}社は、既に支払方法を登録済みの為、変更できません。`);
    event.stopPropagation();
  }

  openAndResetDataModalInvite() {
    const modal = $('#storeInviteNewCompany');
    const currentPaymentMethod = $('#data_store_payment_method').val();
    modal.find('#name').val('');
    modal.find('#email').val('');
    modal.find('#phone_number').val('');
    modal.find(".invite_email_error").html("");
    modal.find(".invite_name_error").html("");
    modal.find(".invite_company_type_error").html("")
    modal.find('#akapon-company').prop('checked', true);
    modal.find(`#${currentPaymentMethod}`).prop('checked', true);
    if(currentPaymentMethod === 'every_month_or_year') {
      modal.find(`#every_month_or_year_label`).text('月払い｜年払い（初期設定）')
      modal.find(`#just_every_year_label`).text('年払いのみ')
    } else {
      modal.find(`#every_month_or_year_label`).text('月払い｜年払い')
      modal.find(`#just_every_year_label`).text('年払いのみ（初期設定）')
    }
    modal.modal('show');
  }

  validateDataBeforeInvite() {
    let isSubmit = true;
    const modal = $('#storeInviteNewCompany');
    const name = modal.find('#name').val();
    const email = modal.find('#email').val();
    const company_type = modal.find('input[name=company_type]:checked');

    if(email == "") {
      isSubmit = false;
      modal.find(".invite_email_error").html("空白にはできません")
    } else {
      if(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email)) {
        modal.find(".invite_email_error").html("")
      } else {
        isSubmit = false;
        modal.find(".invite_email_error").html(I18n.t('admin.attributes.email.invalid'))
      }
    }

    if(name == "") {
      isSubmit = false;
      modal.find(".invite_name_error").html("空白にはできません")
    } else {
      if(Shared.checkValidationUserName(name)) {
        isSubmit = false;
        modal.find(".invite_name_error").html("「名前」に利用できない文字があります。")
      } else {
        modal.find(".invite_name_error").html("")
      }
    }

    if(company_type.length == 0) {
      isSubmit = false;
      modal.find(".invite_company_type_error").html("空白にはできません")
    } else {
      modal.find(".invite_company_type_error").html("")
    }

    if(isSubmit) {
      modal.find('#save_data').val('false');
      $('#submit_invite_new_company').click();
    }
  }

  sendMailToInvitedUser() {
    const modal = $('#emailConfirmModal');
    const cacId = modal.find('#cac_id').val();
    const url = Routes.send_mail_invite_owner_company_apply_campaign_path(cacId);
    const callAjax = Shared.callAjax(url, {}, 'json', 'POST');
    callAjax.done(function() {
      modal.modal('hide');
      Popup.alert('招待メールが送信されました。');
    })
  }

  transitToInvoicesList(cac_id, e) {
    if(e.target.classList.value.includes('not-transit')) return;

    window.location.href = Routes.invoices_list_owner_company_apply_campaign_path(cac_id);
  }
}
