export default class TaskPic {
  openModalInviteByEmail(element){
    const isShowListmember = $('#addPersonInChargeOfObject').attr('data-is-back-list-member')
    if (isShowListmember && isShowListmember === 'true') {
      $('#addPersonInChargeOfObject').attr('data-is-back-list-member', 'false')
    }
    $("#addPersonInChargeOfObject").modal('hide')
    $('#inviteByEmail').find('#add_new_email_permission').val('')
    $('#inviteByEmail').find('#permission_email').val('')
    $('#inviteByEmail').modal('show')
    if (!!$('#js_select_company_type_task').val()) {
      const permissionType = $('#js_select_company_type_task').val().slice(0, -1);
      const titles = { owner: '自社メンバー新規招待', supplier: '仕入先メンバー新規招待', client: '販売先メンバー新規招待', collaborator: '外部メンバー新規招待' }
      $("#inviteByEmail").find('#add_new_email_permission_type').val(permissionType)
      $("#inviteByEmail").find('#add_new_email_permission_type').addClass('d-none');
      $("#inviteByEmail").find('#permission_type_label').addClass('d-none');
      $("#inviteByEmail").find('#add_new_member_title').text(titles[permissionType])
    } else {
      $("#inviteByEmail").find('#add_new_email_permission_type').removeClass('d-none');
      $("#inviteByEmail").find('#permission_type_label').removeClass('d-none');
    }

    $('#inviteByEmail').on('hidden.bs.modal', function () {
      Task.openAddPicModal();
    })
  }

  openModalInviteMemberOfPartner(){
    const isShowListmember = $('#addPersonInChargeOfObject').attr('data-is-back-list-member')
    if (isShowListmember && isShowListmember === 'true') {
      $('#addPersonInChargeOfObject').attr('data-is-back-list-member', 'false')
    }
    $("#addPersonInChargeOfObject").modal('hide')
    $("#inviteExistUserByEmail").modal('show')
    $("#inviteExistUserByEmail").find('#permission_name').val('')
    $("#inviteExistUserByEmail").find('#permission_email').val('')
  }

  validateEmailBeforeInvite(popup_id, task_id, project_id) {
    let isSubmit = true
    let email = $(popup_id).find(".add_new_email_permission").val();
    let type = $(popup_id).find("#add_new_email_permission_type").val();
    let name = $(popup_id).find("#permission_name").val();
    let type_text = this._getTypeText(type);
    if(email == "") {
      isSubmit = false;
      $(popup_id).find(".invite_email_error").html("空白にはできません")
    } else {
      if(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email)) {
        $(popup_id).find(".invite_email_error").html("")
      } else {
        isSubmit = false;
      $(popup_id).find(".invite_email_error").html(I18n.t('admin.attributes.email.invalid'))
      }
    }

    if($(popup_id).find("#add_new_email_permission_type")) {
      if(type == "") {
        isSubmit = false;
        $(popup_id).find(".invite_type_error").html("空白にはできません")
      } else {
        $(popup_id).find(".invite_type_error").html("")
      }
    }

    if($(popup_id).find("#permission_name")) {
      if(name == "") {
        isSubmit = false;
        $(popup_id).find(".invite_name_error").html("空白にはできません")
      } else {
        $(popup_id).find(".invite_name_error").html("")
      }
    }

    if(isSubmit) {
      $('.preloader').fadeIn();
      $(popup_id).attr('data-is-show-list-member', 'false');
      $(popup_id).find(".button-submit").click()
    }
  }

  handleCheckAction(element) {
    const ele = $(element);
    if(ele.find("input").is(":checked")) {
      ele.find("input").prop("checked", false).trigger("change")
      ele.find(".check-view").remove()
    } else {
      ele.find("input").prop("checked", true).trigger("change")
      ele.append("<div class='check-view fs-18 c-red'>✔️</div>")
    }
  }

  handleCheckRemoveUnapproveMember(element, project_id, task_id) {
    let user_name = $(element).parent().find(".t_name").text()
    let user_id = $(element).attr("data-user-id")
    Popup.confirm('', {
        description: `${user_name}さんを解除します。<br> よろしいですか？`,
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
          const url = Routes.owner_remove_member_unapprove_of_task_path(project_id, task_id)
          Shared.callAjax(url, {user_id: user_id}, 'json', 'post')
          .done(function(data) {
            if($('.list-user-invite-new').length) {
              var list_waiting_name = $('.list-waiting-permission').find('.list-permission-name').html()
              if(list_waiting_name.includes(`, ${data.user_name}`)) {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(`, ${data.user_name}`, ''))
              } else {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(data.user_name, ''))
              }
            }
            Popup.confirm('',{
              description: `${user_name}さんの <br> 招待を解除しました。`,
              confirmText: 'OK',
              cancelBtn: 'false',
              success: () => {
                Task.showHideSettingPic(project_id, task_id)
              }
            })
          })
          .fail(function(data) {
            Popup.confirm('',{
              description: 'false',
              confirmText: 'OK',
              cancelBtn: 'false',
            })
          })
        },
      }
    )
  }

  handleCheckRemoveUnapproveRequest(element, project_id, task_id, akaire_file_id = null) {
    let user_name = $(element).parent().find(".t_name").text()
    let request_id = $(element).attr("data-request-id")
    Popup.confirm('', {
        description: `${user_name}さんを解除します。<br> よろしいですか？`,
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
          const url = Routes.owner_remove_request_unapprove_of_task_path(project_id, task_id)
          Shared.callAjax(url, {request_id: request_id, akaire_file_id: akaire_file_id}, 'json', 'delete')
          .done(function(data) {
            if($('.list-user-invite-new').length) {
              var list_waiting_name = $('.list-waiting-permission').find('.list-permission-name').html()
              if(list_waiting_name.includes(`, ${data.user_name}`)) {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(`, ${data.user_name}`, ''))
              } else {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(data.user_name, ''))
              }
            }
            Popup.confirm('',{
              description: `${user_name}さんの <br> 招待を解除しました。`,
              confirmText: 'OK',
              cancelBtn: 'false',
              success: () => {
                Task.showHideSettingPic(project_id, task_id)
              }
            })
          })
          .fail(function(data) {
            Popup.confirm('',{
              description: 'false',
              confirmText: 'OK',
              cancelBtn: 'false',
            })
          })
        },
      }
    )
  }

  closeModalError(element_id) {
    $(`#${element_id}`).modal('hide');
    if($('#inviteByEmail').length) {
      $('#inviteByEmail').modal('show');
    } else {
      $('#addAuthority').modal('show');
    }
  }

  confirmCloseModalSettingMember(project_id = null, task_id = null) {
    const new_task = $("#personInChargeOfObject").attr("data-new-task") == 'true'
    if(new_task) {
      Popup.confirm(
        '<div>選択済みのメンバーはリセットされます。<br> 初期設定時のメンバーのみが招待されます。<br> キャンセルしてよろしいですか？</div>',
        {
          success: () => {
            if(project_id && task_id){
              const url = Routes.owner_send_mail_to_default_permission_of_task_path(project_id, task_id)
              Shared.callAjax(url, {}, 'json', 'post')
            }
            this.refreshUrlAfterSetting()
            $('#personInChargeOfObject').modal('hide');
          },
          cancel: () => {},
          confirmText: 'OK',
          cancelBtn: 'false',
          width: 'auto'
        }
      )
    } else {
      if($('#personInChargeOfObject').attr("data-is-change") == "true") {
        Popup.confirm(
          '<div>選択済みのメンバーはリセットされます。<br>キャンセルしてよろしいですか？</div>',
          {
            success: () => {
              this.refreshUrlAfterSetting()
              $('#personInChargeOfObject').modal('hide');
            },
            cancel: () => {},
            confirmText: 'OK',
            cancelText: 'キャンセル',
            width: 'auto'
          }
        )
        $('.swal2-container').click(function(event){
          event.stopPropagation();
        });
      } else {
        this.refreshUrlAfterSetting()
        $('#personInChargeOfObject').modal('hide')
      }
    }


  }

  _getTypeText(type) {
    if(type == "collaborator") {
      return '外部'
    } else if (type == 'client') {
      return '販売'
    } else if (type == 'supplier') {
      return '仕入'
    } else {
      return '自社'
    }
  }

  prevPage(ele) {
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    const numberPage = Number(pageSelected.data('index'))
    if (numberPage <= 1) return
    pageSelected.removeClass('active')
    ulEle.find(`li[data-index="${numberPage - 1}"]`).addClass('active')
    $('div.show-list-member-for-page').css('display', 'none')
    $(`div[data-page="${numberPage - 1}"]`).css('display', 'flex')
  }

  nextPage(ele, maxPage) {
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    const numberPage = Number(pageSelected.data('index'))
    if (numberPage === Number(maxPage)) return
    pageSelected.removeClass('active')
    ulEle.find(`li[data-index="${numberPage + 1}"]`).addClass('active')
    $('div.show-list-member-for-page').css('display', 'none')
    $(`div[data-page="${numberPage + 1}"]`).css('display', 'flex')
  }

  selectPage(ele, page = null) {
    const ulEle = $(ele).parents('ul.pagination')
    const pageSelected = ulEle.find('li.active')
    pageSelected.removeClass('active')
    if (!page) {
      page = $(ele).attr('data-index')
    }
    ulEle.find(`li[data-index="${page}"]`).addClass('active')
    $('div.show-list-member-for-page').attr('style', 'display: none !important')
    $(`div[data-page="${page}"]`).attr('style', 'display: flex !important')
  }

  async changeNumberPage(ele, totalObject, numberOfPage, parentElement = '#popupInviteMember') {
    const widthWindow = $(window).width()
    const modalEle = ele.closest(`div${parentElement}`)
    const totalEle = Number(totalObject)
    const pagynate = await Shared.fetchPagynateWithJs(totalEle, numberOfPage)
    let listTr = $(modalEle).find('.tr.show-list-member-for-page')
    listTr.css('display', 'none')
    $(ele).parent().find('.option').removeClass('slted');
    $(ele).addClass('slted')
    $('.show-number-of-page-js').html(numberOfPage)
    if (widthWindow <= 768) $(ele).closest('div.modal').modal('hide')
    if (numberOfPage > 30 && widthWindow <= 768) {
      Shared.loadingIndicator()
      await Project.renderPages(listTr, pagynate, modalEle, numberOfPage)
      return
    }
    for (let i = 1; i <= pagynate; i++) {
      let numberEleOfPage = 0;
      if (numberEleOfPage === numberOfPage) {
        numberEleOfPage = 0
        continue
      }
      for (let x = 0; x < listTr.length; x ++) {
        listTr[x].style.display = 'none'
        const isCalculator = listTr[x].getAttribute('calculator-page')
        if (numberEleOfPage < numberOfPage && !isCalculator) {
          listTr[x].setAttribute('calculator-page', 'true')
          listTr[x].setAttribute('data-page', i)
          numberEleOfPage += 1
        }
      }
    }

    for (let i = 0; i < listTr.length; i++) {
      listTr[i].removeAttribute('calculator-page')
    }

    const listPageOne = $(modalEle).find('.tr.show-list-member-for-page[data-page="1"]')
    for (let i = 0; i < listPageOne.length; i++) {
      listPageOne[i].style.display = '';
    }

    const newPagy = await Shared.reRenderPagyNate(
      pagynate, 'TaskPic.prevPage(this)', 'TaskPic.selectPage(this)',
      `TaskPic.nextPage(this, '${pagynate}')`
    )
    document.getElementById('render-list-pagynate-of-member-project-author').innerHTML = newPagy
    SearchForm.selectFilterDisplay('hide', '.filter-content-number-record-task', event)
  }

  checkAllOrCancelCheckAllMember(ele, objectId, projectId) {
    const isCheckAllMember = $(ele).prop('checked')
    if (isCheckAllMember) {
      this._handleCheckAllMember(ele, objectId, projectId)
    } else {
      this._handleCancelCheckAllMember(ele, objectId, projectId)
    }
  }

  removeParam(key, sourceURL) {
    if (!sourceURL) {
      return;
    }

    let rtn = sourceURL.split("?")[0];
    let param;
    let params_arr = [];
    let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  changePagynationLinks() {
    const array = $(document).find('.task-list-pagination').find('a.page-link').toArray()
    array.forEach(tagA => {
      let tagAUrl = $(tagA).attr('href')
      tagAUrl = this.removeParam("new_task", tagAUrl);
      tagAUrl = this.removeParam("task_id", tagAUrl);
      $(tagA).attr('href', tagAUrl)
    })
  }

  refreshUrlAfterSetting() {
    const currentUrl = window.location.href
    if(currentUrl.includes('new_task=true')) {
      let newUrl = this.removeParam("new_task", currentUrl);
      newUrl = this.removeParam("task_id", newUrl);
      window.history.pushState( '', '', newUrl);
      this.changePagynationLinks();
    }
  }

  async _handleCancelCheckAllMember(ele, objectId, projectId) {
    const userIds = []
    const listUserUncheck = $('#list-user-uncheck')
    await $('#show-table-list-member').find('div.t_invite').toArray().forEach(divEle => {
      const input = $(divEle).find('input').length > 0 ? $(divEle).find('input') : undefined
      if (input && input.prop('checked')) {
        $("#list-user-uncheck").val(`${listUserUncheck.val()}${$(divEle).find('input').val()},`)
        userIds.push($(divEle).find('input').val())
      }
    })
    const url = Routes.check_add_permission_to_task_with_list_memeber_owner_project_tasks_path(projectId)
    Shared.callAjax(url, { task_id: objectId, user_ids: userIds, is_remove: true }).done(function(data) {
      data.list_users_add.forEach(userId => {
        $("#personInChargeOfObject").attr('data-is-change', 'true')
        $(`input[data-user-id="${userId}"]`).prop('checked', false).trigger('change')
        $(`input[data-user-id="${userId}"]`).parents('div.t_invite').find("div.check-view").remove()
      })
    })
  }

  async _handleCheckAllMember(ele, objectId, projectId) {
    const userIds = []
    const listUserUncheck = $('#list-user-uncheck')
    await $('#show-table-list-member').find('div.t_invite').toArray().forEach(divEle => {
      const input = $(divEle).find('input').length > 0 ? $(divEle).find('input') : undefined
      $("#list-user-uncheck").val(listUserUncheck.val().replace(`${$(divEle).find('input').val()},`, ''));
      if (input && !input.prop('checked')) {
        userIds.push($(divEle).find('input').val())
      }
    })
    const url = Routes.check_add_permission_to_task_with_list_memeber_owner_project_tasks_path(projectId)
    Shared.callAjax(url, { task_id: objectId, user_ids: userIds }).done(function(data) {
      data.list_users_add.forEach(userId => {
        $("#personInChargeOfObject").attr('data-is-change', 'true')
        $(`input[data-user-id="${userId}"]`).prop('checked', true).trigger('change')
        $(`input[data-user-id="${userId}"]`).parents('div.t_invite').append("<div class='check-view fs-18 c-red'>✔️</div>")
      })
    })
  }
}
