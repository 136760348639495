import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Card, CardBody, CardText, CardLink } from 'reactstrap';
import moment from 'moment';
import nl2br from 'react-nl2br';

export default class AnimationMarkerPointsDisplay extends Component {
  constructor() {
    super();
    this.state = {
      isReady: false
    };
  }
  componentDidMount() {
    const controlBarElm = document.querySelector('.video-react-control-bar');
    const progressControlElm = document.querySelector('.video-react-progress-control');
    const thisElm = findDOMNode(this);
    progressControlElm.appendChild(thisElm);
    this.setState({ isReady: true });
  }
  render() {
    const { markers, player: { duration } } = this.props;
    const { isReady } = this.state;
    return (
      <div className="animation-marker-points-display">
        {
          isReady && markers.map(({ seek_time: time }, i) => {
            return (
              <div className="animation-marker-point" style={{ left: `calc(${time / duration * 100}% - 4px)` }} key={i}>
              </div>
            );
          })
        }
      </div>
    );
  }
};
