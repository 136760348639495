import datepicker from 'js-datepicker'
export default class DefaultAction {
  run() {
    this.removeLoading()
    this.runDatepicker()
    this.runPopupJS()
    this.activeTooltip()
    this.focusOutSelectIfChat()
    this.hidePopupFilter()
    this.hidePopupSort()
    this.closePopupSettingMember()
    this.hideSideBar()
  }

  runDatepicker() {
    var current_date = new Date();
    $('.js-datepicker').each(function() {
      if(!$(this).data("datepicker")) {
        let dateSelected = $(this).val() ? new Date($(this).val()) : ''
        $(this).data('datepicker', true)
        datepicker(this, {
          customDays: I18n.t('shared.customDays'),
          customMonths: I18n.t('shared.customMonths'),
          formatter: (input, date, _) => input.value = moment(date).format('Y/MM/DD'),
          dateSelected: dateSelected,
          onSelect: (dateText) => {
            $(this).trigger('change');
          }
        })
      }
    });
    $('.js-datepicker-pc').each(function() {
      if(!$(this).data("datepicker")) {
        let dateSelected = $(this).val() ? new Date($(this).val()) : ''
        $(this).data('datepicker', true)
        datepicker(this, {
          customDays: I18n.t('shared.customDays'),
          customMonths: I18n.t('shared.customMonths'),
          formatter: (input, date, _) => input.value = moment(date).format('Y/MM/DD'),
          dateSelected: dateSelected,
          onSelect: (dateText) => {
            $(this).trigger('change');
          }
        })
      }
    });
    $('.campaign-datepicker').each(function() {
      if(!$(this).data("datepicker")) {
        let dateSelected = $(this).val() ? new Date($(this).val()) : ''
        $(this).data('datepicker', true)
        datepicker(this, {
          customDays: I18n.t('shared.customDays'),
          customMonths: I18n.t('shared.customMonths'),
          formatter: (input, date, _) => input.value = moment(date).format('Y/MM/DD'),
          dateSelected: dateSelected,
          maxDate: new Date(current_date.setDate(current_date.getDate() + 365)),
          onSelect: (dateText) => {
            $(this).trigger('change');
          }
        })
      }
    })
  }

  activeTooltip() {
    const listTooltips = [{
      theme: 'light',
      className: 'bg-light border unset--max-width'
    }]
    $(`[data-toggle="tooltip"]:not([data-theme])`).tooltip()
    listTooltips.forEach(ele => {
      $(`[data-toggle="tooltip"][data-theme="${ele.theme}"]`).tooltip({
        template: this._tooltipTemplate(ele.className)
      });
    })
  }

  _tooltipTemplate(className) {
    return `<div class="tooltip" role="tooltip">
              <div class="tooltip-inner ${className}"></div>
            </div>`
  }

  removeLoading() {
    $('.preloader').fadeOut();
  }

  runPopupJS() {
    gon.popupJS.forEach(ele => {
      const messages = typeof(ele.message) === 'string' ? ele.message : ele.message.join('<br>')
      Popup.alert(messages, ele.type)
    })
  }

  focusOutSelectIfChat() {
    $('select').on('click', function(e) {
      const listChat = $('.body-chat-area').toArray();
      listChat.forEach(chat => {
        if ($(chat).width() > 500) {
          $(e.target).blur()
          $(chat).focus();
        }
      })
    })
  }

  hidePopupFilter() {
    $(document).on('click', function(e) {
      if ($(e.target).parents('td.td-filter-box').length > 0) return
      Visiable.hide('.filter-content')
    })
  }

  hidePopupSort() {
    $(document).on('click', function(e) {
      if ($(e.target).parents('td.td-sort-box').length > 0) return
      Visiable.hide('.sort_box')
    })
  }

  closePopupSettingMember() {
    $(document).on('click', function(e) {
      if ($(e.target).hasClass('dt-paging-button')) return
      if($('#popupInviteMember').length > 0 && !$('#popupInviteMember').hasClass('d-none')) {
        if ($(e.target).parents('.div-popup-invite-member').length > 0) return
        if ($(e.target).parents('.popup-invite-member').length > 0) return
        if ($(e.target).parents('.new_alert_popup').length > 0) return
        if ($(e.target).parents('.akaire_alert_popup').length > 0) return
        if ($(e.target).hasClass('swal2-backdrop-hide')) return
        if ($(e.target).parents('.swal2-backdrop-show').length > 0) return
        if ($(e.target).parents('.paging_full_numbers').length > 0) return
        if ($(e.target).hasClass('dt-paging-button')) return

        if($('#popupInviteMember').is(':visible')) {
          if ($('.button-cancel-update-permission').length) {
            $('.button-cancel-update-permission').click()
          } else {
            Project.hideSettingMemberModal()
          }
        }
      }
    })
  }

  hideSideBar() {
    $('.hidePopupSideBar').on('click', function(e) {
      $('#sidebar-recently').addClass('d-none');
    })
  }
}
