export default class PersonInchargeTicket {
  showListMember(ele, workId, projectId) {
    const tbodyEle = $(ele).parents('tbody')
    const isSendMail = tbodyEle.attr('is-send-mail')
    const url = Routes.owner_project_ticket_get_list_participants_path(projectId, workId);
    Shared.callAjax(url, {}, 'html').done((data) => {
      $('.render-pic-modal').html(data)
      $('#personInChargeOfObject').modal('show');
      if (isSendMail && isSendMail === 'true') {
        $('#personInChargeOfObject').attr('is-send-mail', 'true')
        tbodyEle.removeAttr('is-send-mail')
      }
    })
    .fail(function(data) {
      Popup.confirm(I18n.t('shared.one_error_please_try_again'), {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    })
  }

  showListMemberAfterCreate(workId, projectId, workPics) {
    const isSendMail = 'true'
    const url = Routes.owner_project_ticket_get_list_participants_path(projectId, workId);
    Shared.callAjax(url, {}, 'html').done((data) => {
      $('.render-pic-modal').html(data)
      $('#personInChargeOfObject').modal('show');
      if (isSendMail && isSendMail === 'true') {
        $('#personInChargeOfObject').attr('is-send-mail', 'true')
      }
    })
    .fail(function(data) {
      Popup.confirm(I18n.t('shared.one_error_please_try_again'), {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    })
  }

  changePersonInChargeList(ele, workId, projectId, taskId) {
    const $ele = $(ele);
    const addAction = $ele.is(":checked");
    if (!addAction) {
      Ticket._checkMemberBeforeDeletePic(ele, workId, projectId, taskId)
    } else {
      Task.showEleFilterNewAdd(ele)
    }
    $("#personInChargeOfObject").attr('data-is-change', 'true')
  }

  unCheckMemberInChargeList(ele, workId) {
    const divEle = $(ele).parents('div.t_invite');
    divEle.find('input').prop('checked', false);
    $("#personInChargeOfObject").attr('data-is-change', 'true')
    Task.showEleFilterNewAdd(ele, false)
  }

  checkAllOrUnCheckAllMemeber(ele, currentUserId, projectId, workId) {
    const tableMemberEle = $(ele).parents('div.modal-body').find('div#show-table-list-member')
    const listMemberEle = tableMemberEle.find('input.pick-pics').toArray()
    const listUserRemove = []
    const me = this
    $("#personInChargeOfObject").attr('data-is-change', 'true')
    if($(ele).is(':checked')) {
      listMemberEle.forEach((input) => {
        if (!$(input).is(':checked')) {
          $(input).prop('checked', true).trigger('change')
          $(input).parents('div.t_invite').append("<div class='check-view fs-18 c-red'>✔️</div>")
        }
      })
    } else {
      listMemberEle.forEach((input) => {
        const userId = $(input).data('user-id')
        if (userId !== Number(currentUserId)) {
          listUserRemove.push(userId)
        }
      })
      const url = Routes.owner_project_ticket_check_remove_all_members_path(projectId, workId)
      Shared.callAjax(url, { user_ids: listUserRemove }).done((data) => {
        if (data.is_remove_main_admin) {
          Popup.alert(I18n.t('task.cannot_remove_project_main_admin'))
          this.checkedCheckAllMemberTicket()
          return
        }
        if (data.is_show_popup) {
          let message = `<div class='text-center font-weight-normal'><p>未納品作業が存在します。</p>
            紐づけを解除すると提出済（未納品）作業は削除されます。
            解除すると、未納品作業は<a class="text-underline font-weight-bold" href="${Routes.deleted_works_efforts_path()}">削除済一覧ページ</a>に移動します。
          </div>`
          if (data.company_name) {
            message = `<div class='text-center font-weight-normal'><p>${result.company_name}の未納品作業が存在します。</p>
              紐づけを解除すると提出済（未納品）作業は削除されます。
              解除すると、未納品作業は<a class="text-underline font-weight-bold" href="${Routes.deleted_works_efforts_path()}">削除済一覧ページ</a>に移動します。
            </div>`
          }
          me.showPopupWithUncheckMember(message, listMemberEle, currentUserId)
        } else {
          me.unCheckAllMemberTicket(listMemberEle, currentUserId)
        }
      })
      .fail((data) =>{
        me.checkedCheckAllMemberTicket()
      })
    }
  }

  unCheckAllMemberTicket(listMemberEle, currentUserId) {
    listMemberEle.forEach((input) => {
      const userId = $(input).data('user-id')
      if (userId !== Number(currentUserId)) {
        $(input).parents('div.t_invite').find("div.check-view").remove()
        $(input).prop('checked', false).trigger('change')
      }
    })
  }

  showPopupWithUncheckMember(message, listMemberEle, currentUserId) {
    Popup.confirm(message, {
      confirmText: '解除する]',
      cancelText: 'キャンセル',
      width: 'auto',
      success: () => {
        this.unCheckAllMemberTicket(listMemberEle, currentUserId)
      },
      cancel: () => {
        this.checkedCheckAllMemberTicket()
      }
    })
  }

  checkedCheckAllMemberTicket() {
    $("#personInChargeOfObject").find('#check-all-in-list-ticket').prop('checked', true)
  }

  checkedInput(ele) {
    $(ele).prop('checked', true);
    $(ele).parents('div.t_invite').append("<div class='check-view fs-18 c-red'>✔️</div>")
  }

  handleUpdatePicOfTicket(element, projectId, workId) {
    const user_invite_ids = [];
    const user_delete_ids = [];
    const user_invite_emails = [];
    const picTicket = this
    let list_invite_member_of_partner = [];
    const dataOldPic = JSON.parse($(`#pic_list_${workId}`).attr('data-old-pic-ids'))
    $(element).parent().parent().find(".pick-pics").each(function( _index, ele ) {
      if($(ele).is(":checked")) {
        if($(ele).attr("name").includes("email")) {
          user_invite_emails.push({email: $(ele).val(), type: $(ele).attr("data-type")})
        } else if($(ele).attr("name").includes("partners")) {
          list_invite_member_of_partner.push({email: $(ele).val(), type: $(ele).attr("data-type"), company_id: $(ele).attr("data-company-id"), name: $(ele).attr("data-name")})
        } else {
          user_invite_ids.push($(ele).val())
        }
      } else {
        user_delete_ids.push($(ele).val())
      }
    });
    const nameTicket = $('#personInChargeOfObject').find('#name-for-ticket-pic').text()
    const isSendMail = $('#personInChargeOfObject').attr('is-send-mail')
    const url = Routes.owner_project_ticket_update_ticket_pics_path(projectId, workId)
    Shared.callAjax(
      url,
      { list_user_invite: user_invite_ids, list_user_delete: user_delete_ids,
        list_user_invite_by_email: user_invite_emails, data_old_pics: dataOldPic,
        is_send_mail: isSendMail, list_invite_member_of_partner: list_invite_member_of_partner
      }
    ).done(function(result){
      $('#personInChargeOfObject').attr('data-is-change', 'false');
      $('#personInChargeOfObject').attr('data-is-show', 'false');
      $("#personInChargeOfObject").modal("hide")
      if (result.is_reload) {
        Shared.reloadPage()
        return
      }
      let text = ''
      if ($(window).width() < 1024) {
        const arr = result.number_user.split('')
        arr.forEach((e) => {
          if (Number(e) > 0) {
            text = `${Number(e) + 1}${arr[arr.length - 1]}`
          }
        })
      } else {
        text = result.number_user
      }
      if (text.trim() == '') text = '自分'
      $(`#pic_list_${workId}`).text(text)
      $(`#pic_list_${workId}`).attr('data-old-pic-ids', `[${result.pic_ids}]`)
      picTicket.hideDivAndChangeNumberMember(workId, text)
      if (isSendMail === 'true') {
        Popup.alert(`${nameTicket}<br>を作成しました。`)
        return
      }
      $('#ticketUpdatePicSuccess').find('.ticket-name').text(nameTicket)
      $('#ticketUpdatePicSuccess').modal('show')
    })
    .fail(function(data) {
      if (data.responseJSON && data.responseJSON.changed_data_by_someone) {
        newPopupConfirmReload(I18n.t('work.changed_refresh'))
      } else if (data.responseJSON && data.responseJSON.message) {
        Popup.alert(data.responseJSON.message)
      } else {
        newPopupConfirmReload(I18n.t('work.changed_refresh'))
      }
    })
  }

  handleCheckRemoveUnapproveMember(element, projectId, workId) {
    const userName = $(element).parent().find(".t_name").text()
    const userId = $(element).attr("data-user-id")
    const dataOldPic = JSON.parse($(`#pic_list_${workId}`).attr('data-old-pic-ids'))
    const picTicket = this
    Popup.confirm('', {
        description: `${userName}さんを解除します。<br> よろしいですか？`,
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
          $('div#personInChargeOfObject').attr('data-is-change', 'false');
          $('div#personInChargeOfObject').modal('hide')
          const url = Routes.owner_project_ticket_remove_member_unapprove_path(projectId, workId)
          Shared.callAjax(url, { user_id: userId, data_old_pics: dataOldPic })
          .done(function(data) {
            if($('.list-user-invite-new').length) {
              var list_waiting_name = $('.list-waiting-permission').find('.list-permission-name').html()
              if(list_waiting_name.includes(`, ${data.user_name}`)) {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(`, ${data.user_name}`, ''))
              } else {
                $('.list-waiting-permission').find('.list-permission-name').html(list_waiting_name.replace(data.user_name, ''))
              }
            }
            $(`#pic_list_${workId}`).text(data.fetch_text_of_number_user)
            $(`#pic_list_${workId}`).attr('data-old-pic-ids', `[${data.pic_ids}]`)
            Popup.confirm('',{
              description: `${data.user_name}さんの <br> 招待を解除しました。`,
              confirmText: 'OK',
              cancelBtn: 'false',
              success: () => {
                $(`#pic_list_${workId}`).trigger('click');
              }
            })
          })
          .fail(function(data) {
            if (data.responseJSON.changed_data_by_someone) {
              newPopupConfirmReload(I18n.t('work.changed_refresh'))
            } else {
              Popup.alert(data.responseJSON.message)
            }
          })
        },
      }
    )
  }

  handleCheckRemoveUnapproveRequest(element, projectId, workId, akaire_file_id = null) {
    const userName = $(element).parent().find(".t_name").text()
    const requestId = $(element).attr("data-request-id")
    const picTicket = this
    Popup.confirm('', {
        description: `${userName}さんを解除します。<br> よろしいですか？`,
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
          $('div#personInChargeOfObject').attr('data-is-change', 'false');
          $('div#personInChargeOfObject').modal('hide')
          const url = Routes.owner_project_ticket_remove_request_unapprove_path(projectId, workId)
          Shared.callAjax(url, { request_id: requestId, akaire_file_id: akaire_file_id })
          .done(function(data) {
            $(`#pic_list_${workId}`).text(data.fetch_text_of_number_user)
            $(`#pic_list_${workId}`).attr('data-old-pic-ids', `[${data.pic_ids}]`)
            Popup.confirm('',{
              description: `${userName}さんの <br> 招待を解除しました。`,
              confirmText: 'OK',
              cancelBtn: 'false',
              success: () => {
                $(`#pic_list_${workId}`).trigger('click');
              }
            })
          })
          .fail(function(data) {
            if (data.responseJSON.changed_data_by_someone) {
              newPopupConfirmReload(I18n.t('work.changed_refresh'))
            } else {
              Popup.alert(data.responseJSON.message)
            }
          })
        },
      }
    )
  }

  hideDivAndChangeNumberMember(workId, text) {
    $(`.show-text-ticket-${workId}`).text(text)
  }

  sendMailWithNewTicket(projectId, workId) {
    const modalEle = $('#personInChargeOfObject')
    const isSendMail = modalEle.attr('is-send-mail')
    if (!isSendMail || isSendMail !== 'true') return

    const url = Routes.owner_project_ticket_send_mail_with_create_ticket_path(projectId, workId)
    Shared.callAjax(url, {}).done(function(response) {
    }).fail(function(data) {
      let message = I18n.t('shared.one_error_please_try_again')
      if (data.responseJSON && data.responseJSON.message) message = data.responseJSON.message
      newPopupConfirmReload(message)
    })
  }

  confirmCloseModalSettingMember(projectId, workId) {
    if($('#personInChargeOfObject').attr("data-is-change") === "true") {
      this.showPopupConfirmCloseMember(projectId, workId)
    } else if ($('#personInChargeOfObject').attr("is-send-mail") === "true") {
      Popup.confirm(
        `<div>選択済みのメンバーはリセットされます。<br> 初期設定時のメンバーのみが招待されます。<br>キャンセルしてよろしいですか？</div>`,
        {
          success: () => {
            $('#personInChargeOfObject').attr('data-is-show', 'false')
            $('#personInChargeOfObject').modal('hide');
            this.sendMailWithNewTicket(projectId, workId)
          },
          cancel: () => {},
          cancelBtn: 'false',
          confirmText: 'OK',
          width: 'auto'
        }
      )
    } else {
      $('#personInChargeOfObject').modal('hide');
      this.sendMailWithNewTicket(projectId, workId)
    }
  }

  showPopupConfirmCloseMember(projectId, workId) {
    Popup.confirm(
      '<div>選択済みのメンバーはリセットされます。<br> キャンセルしてよろしいですか？</div>',
      {
        success: () => {
          $('#personInChargeOfObject').attr('data-is-change', 'false');
          $('#personInChargeOfObject').modal('hide');
          this.sendMailWithNewTicket(projectId, workId)
        },
        cancel: () => {},
        confirmText: 'OK',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  openAddPicModal(resetValues = false) {
    if (resetValues) {
      $('#show-selected-company-type').text('');
      $('#js_select_company_invites_task').empty();
      $('#js-select_users_invites').empty();
      $('#add_new_email_permission').val('');
      $('#add_new_email_permission_type').val('')
    }
    $("#personInChargeOfObject").attr('data-is-show', 'false');
    $("#personInChargeOfObject").modal("hide")
    $('#addPersonInChargeOfObject').attr('data-is-back-list-member', 'true');
    Shared.appendSelect2('#addPersonInChargeOfObject', '#js_select_company_invites_task')
    Shared.appendSelect2('#addPersonInChargeOfObject', '#js-select_users_invites')
    $("#addPersonInChargeOfObject").modal("show")
  }

  showPopupUserAkapon(ele) {
    Popup.alert('アカポン会員はチケット<br>に招待できません。')
  }
}

function newPopupConfirmReload(message){
  Popup.confirm(message, {
    success: () => { location.reload() },
    cancel: () => {},
    confirmText: I18n.t('work.reload_page'),
    width: '35rem'
  })
}
