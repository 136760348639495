import 'packs/jquery-ui.min'
import 'packs/sortable/sort_function'

export default function refreshSortableWorkQuantity () {
  var fixHelperModified = function(e, tr) {
    var $originals = tr.children();
    var $helper = tr.clone();
    $helper.children().each(function(index)
    {
      $(this).outerWidth($originals.eq(index).outerWidth())
    });

    return $helper;
  };

  $(".work-quantity-tbody").sortable({
    cursor: 'grabbing',
    start: function(event, ui) {
      ui.helper.css('display', 'table')
    },
    stop: function(event, ui) {
      ui.item.css('display', '')
    },
    helper: fixHelperModified,
    start: function(event, ui) {
      return ui.placeholder.children().each(function(index, child) {
        var source;
        source = ui.helper.children().eq(index);
        $(child).removeAttr('class').removeAttr('colspan');
        $(child).addClass(source.attr('class'));
        if (source.attr('colspan')) {
          return $(child).attr('colspan', source.attr('colspan'));
        }
      });
    },
    update: function(event, ui) {
      const url = Routes.update_effort_sort_object_sorts_path();
      const workID = $('.show-work-quantity.show').find('.work-quantity-tbody').attr('data-work-id');
      const companyID = $('.show-work-quantity.show').find('.show-name-select-company').attr('data-company-id');
      const dataSort = SortFunction.listDataSortEffort()
      Shared.callAjax(url, {data_sort: dataSort, type: 'effort', work_id: workID, work_target_id: companyID}, 'json')
    }
  });
}
