import React, { Component } from "react";
import axios from 'axios';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Progress,
  Tooltip,
} from "reactstrap";
import apiClient from "../util/api-client";
import iconPcAndMobile from "../ui/images/iconPc_Mobile.svg";
import iconPc from "../ui/images/iconPc.svg";
import iconMobile from "../ui/images/iconMobile.svg";
import iconMobileSmall from "../ui/images/iconMobileSmall.svg";
import iconCam from "../ui/images/icon_camera.svg";
import IconUploadYoutube from 'images/upload_youtube.png';

const regex = new RegExp("^(http[s]?:\\//){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

const deviceOptions = [
  {
    icon: iconPcAndMobile,
    text: "画面サイズオプション",
    value: "",
    size: "",
    width: "43px",
    height: "24px",
  },
  {
    icon: iconPc,
    text: "デスクトップ大",
    value: "pc",
    size: "1920px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconPc,
    text: "デスクトップ中",
    value: "laptop",
    size: "1280px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconMobile,
    text: "タブレット",
    value: "tablet",
    size: "768px",
    width: "20px",
    height: "28px",
  },
  {
    icon: iconMobileSmall,
    text: "スマートフォン",
    value: "smartphone",
    size: "375px",
    width: "14px",
    height: "20px",
  },
];

export default class GetUrlYoutube extends Component {
  constructor(props) {
    super(props);
    const { projectId, baseURL } = props;
    this.state = {
      completed: 0,
      time: 0,
      showProgressBar: true,
      link: "",
      checked: false,
      toggleAdvancedSettings: false,
      supportSpecialPage: false,
      tooltipLazyPageOpen: false,
      supportLazyLoad: false,
      tooltipSpecialPageOpen: false,
      deviceSelected: deviceOptions[0],
      isOpenPolicyVideoYoutube: false,
      isOpenModalUploadFirstAkaire: false,
    };
    this.apiClient = apiClient({ baseURL: baseURL || "/" });
    this.intervalId = null;
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.closeModal();
    }
  };

  calculateTime = () => {
    this.intervalId = setInterval(() => {
      const { completed } = this.state;

      if (completed < 99) {
        this.setState({ completed: completed + 1 });
      }
    }, 200);
  };

  preprocess = () => {
    this.calculateTime();
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      link: event.target.value
    });
  }

  checkedChange(event) {
    if (event.target.checked) {
      this.setState({
        checked: true,
      });
    } else {
      this.setState({
        checked: false,
        username: "",
        password: "",
      });
    }
  }

  closeModal = (event) => {
    const { onClickClose } = this.props;
    onClickClose();
    this.setState({ checked: false });
  };

  finishProgress = () => {
    clearInterval(this.intervalId);
    this.intervalId = null;
    this.setState({ completed: 0 });
  };

  fromProjectId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_project_id');
  }
  fromTaskId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_task_id');
  }
  fromWorkId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_work_id');
  }
  fromPageId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_page_id');
  }

  handleSubmit = (event) => {
    const { onFinish, onStart, is_create_video_embed, checkAnimationLastPage, firstUploadAkaire } = this.props;
    this.preprocess();
    const {
      link,
    } = this.state;

    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    event.preventDefault();
    if (link) {
      if (pageId === 'new' || pageId == undefined){
        this.screenYoutube(pageId, link, onStart)
      }else {
        this.apiClient.get(`${pageId}/check_final_version`).then((response) => {
          if(response.data.is_has_final_version){
            Popup.confirm('現在、このページには最終稿バージョンが存在します。<br>新しいバージョンをアップロードすると、最終稿が解除されます。<br>アップロードしてよろしいですか？', {
              confirmText: 'OK',
              cancelBtn: false,
              success: () => {
                this.screenYoutube(pageId, link, onStart)
              },
            });
          }else{
            this.screenYoutube(pageId, link, onStart)
          }
        })
        .catch(() => {
          Popup.alert('エラーが発生しました');
        })
      }
    } else {
      this.finishProgress();
      Popup.alert("URLは不正な値です。", '', '', {kind: 'akaire'});
    }
  };

  screenYoutube = (pageId, link, onStart) => {
    onStart();
    axios.get(`https://www.youtube.com/oembed?format=json`, { params: { url: link } })
    .then(res => {
      const data = {
        url: link,
        page_id: pageId,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      };
      this.apiClient
      .post(`${pageId ? 'animations/save_animation_embed' : '/save_animation_embed'}`, data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ link: "" });
          this.resetAdvancedSettings();
          this.closeModal();
          setTimeout(() => {
            if(response.data.page_id) {
              window.location = window.location.href.split('?')[0].replace('#!', '') + `?update_version_page=${response.data.page_id}&animation_id=${response.data.animation_id}&new_page=${pageId == 'new'}`;
            } else {
              window.location.href = Routes.list_temp_file_akaire_feature_akaires_path({upload_status: 'success'})
            }
          }, 2000)
        } else {
          $("#overVideoURLModal").modal('show');
        }
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。", '', '', {kind: 'akaire'});
      })
    })
    .catch((data) => {
      Popup.alert("このページにアクセスできません。", '', '', {kind: 'akaire'});
    })
    .finally((_) => {
      this.props.endLoading()
      this.finishProgress();
    });
  }

  checkboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  toggleAdvancedSettings = () => {
    if (!this.state.toggleAdvancedSettings) {
      this.setState({ toggleAdvancedSettings: true });
    } else {
      this.resetAdvancedSettings();
    }
  };

  resetAdvancedSettings = () => {
    this.setState({
      toggleAdvancedSettings: false,
      username: "",
      password: "",
      supportSpecialPage: false,
      supportLazyLoad: false,
    });
  };

  handleSelectDevice = (value) => {
    let device = deviceOptions.filter((option) => option.value === value);
    device = device.length > 0 ? device[0] : deviceOptions[0];
    this.setState({ deviceSelected: device });
  };

  toggleTooltipSpecialPage = () => {
    this.setState({
      tooltipSpecialPageOpen: !this.state.tooltipSpecialPageOpen,
    });
  };

  toggleTooltipLazyPage = () => {
    // if not setTimeout, tooltip on mobile will not hide after open
    // references: https://stackoverflow.com/a/49294377
    setTimeout(() => {
      this.setState({
        tooltipLazyPageOpen: !this.state.tooltipLazyPageOpen,
      });
    }, 0);
  };

  render() {
    const { isOpen, onClickClose } = this.props;
    const {
      checked,
      link,
      deviceSelected,
      toggleAdvancedSettings,
      username,
      password,
      supportSpecialPage,
      tooltipSpecialPageOpen,
      supportLazyLoad,
      tooltipLazyPageOpen,
      isOpenPolicyVideoYoutube,
      isOpenModalUploadFirstAkaire
    } = this.state;
    const { completed, showProgressBar } = this.state;
    const isBrowser = window.screen.width >= 768;
    let advancedSettings = "";

    var checkbox = "";
    var progressBar = "";

    const unsubmittable =
      link.length === 0 ||
      !regex.test(link) ||
      deviceSelected.value === "";

    if (showProgressBar) {
      progressBar = (
        <Progress value={completed} className="mx-2">
          {completed}%
        </Progress>
      );
    }
    if (checked) {
      checkbox = (
        <div className="basic-authen">
          <div className="row align-items-center">
            <div className="col-3">ユーザーID</div>
            <div className="col-9">
              <Input
                type="text"
                placeholder="ユーザーID"
                name="username"
                autoComplete="username"
                onChange={(event) => this.handleChange(event)}
              />
            </div>
          </div>
          <div className="my-2 row align-items-center">
            <div className="col-3">パスワード</div>
            <div className="col-9">
              <Input
                type="password"
                placeholder="パスワード"
                name="password"
                autoComplete="password"
                onChange={(event) => this.handleChange(event)}
              />
            </div>
          </div>
        </div>
      );
    }

    if (toggleAdvancedSettings) {
      advancedSettings = (
        <div className="my-2 row w-100">
          <div className="vl ml-3 w-100">
            <div className="ml-3">
              <p className={`font-weight-bold ${!isBrowser && "text-center"}`}>
                ベーシック認証
              </p>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="d-flex w-100 align-items-center border-left-radius-none">
                    <Input
                      type="text"
                      placeholder=" ユーザーID"
                      name="username"
                      onChange={(event) => this.handleChange(event)}
                      value={username}
                    />
                  </div>
                </div>
                <div className={`col-md-6 col-sm-12`}>
                  <div className="d-flex w-100 align-items-center border-left-radius-none">
                    <Input
                      type="password"
                      placeholder=" パスワード"
                      name="password"
                      onChange={(event) => this.handleChange(event)}
                      value={password}
                    />
                  </div>
                </div>
              </div>
              <p
                className={`mt-2 small text-muted ${
                  !isBrowser && "text-center"
                }`}
              >
                ※ユーザーID、パスワード情報は一切保存されません。
              </p>
              <div className="bg-soft-peach p-3">
                <span className="h6 font-weight-bold">追加オプション</span>
                <div className="ml-5 mt-2 h6">
                  <Input
                    type="checkbox"
                    name="supportSpecialPage"
                    onChange={(event) => this.checkboxChange(event)}
                    className="scale-1_5"
                    id="supportSpecialPage"
                    checked={supportSpecialPage}
                  />
                  <label
                    htmlFor="supportSpecialPage"
                    className="font-weight-bold"
                  >
                    {" "}
                    特殊なページに対応
                  </label>
                  <i
                    className="fas fa-question-circle ml-3 scale-1_3 text-muted"
                    id="TooltipSupportSpecialPage"
                  ></i>
                  <Tooltip
                    className="akaire-tooltip"
                    placement={isBrowser ? "right" : "bottom"}
                    isOpen={tooltipSpecialPageOpen}
                    target="TooltipSupportSpecialPage"
                    toggle={() => this.toggleTooltipSpecialPage()}
                  >
                    スクロールに合わせて画像や文字が動くページ
                    <br />
                    （視差効果・パララックス）や、ブラウザの幅
                    <br />
                    に合わせてメイン画像がリサイズされるページ
                    <br />
                    （フレックスサイズヘッダー）の時にお試しく
                    <br />
                    ださい。
                  </Tooltip>
                </div>
                <div className="ml-5 h6">
                  <Input
                    type="checkbox"
                    name="supportLazyLoad"
                    onChange={(event) => this.checkboxChange(event)}
                    className="scale-1_5"
                    id="supportLazyLoad"
                    checked={supportLazyLoad}
                  />
                  <label htmlFor="supportLazyLoad" className="font-weight-bold">
                    {" "}
                    遅延読み込みに対応
                  </label>
                  <i
                    className="fas fa-question-circle ml-3 scale-1_3 text-muted"
                    id="TooltipSupportLazyLoad"
                  ></i>
                  <Tooltip
                    className="akaire-tooltip"
                    placement={isBrowser ? "right" : "bottom"}
                    isOpen={tooltipLazyPageOpen}
                    target="TooltipSupportLazyLoad"
                    toggle={() => this.toggleTooltipLazyPage()}
                  >
                    画像を一度に読み込まず、スクロ一ルごとに
                    <br />
                    必要な分だけ読み込むペ一ジの時にお試しく
                    <br />
                    ださい。
                  </Tooltip>
                </div>
                <div className="ml-5 h6">
                  <p className="small text-muted">
                    CSSやJavaScriptの影響でうまく表示されないときにお試しください。
                    <br />
                    ただしキャプチャにしばらく時間がかかります。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Modal
          isOpen={isOpen} toggle={onClickClose} innerRef={node => (this.modalRef = node)}
          className="animation-public-url-modal modal-dialog-centered modal-style additional-akaire"
        >
          <form onSubmit={ (event) =>  { event.preventDefault(); this.handleSubmit(event) } }>
            <ModalBody
              onProgress={this.onCaptureProgress}
              className="py-0 bg-white mt-3"
            >
              <div className='justify-content-center text-center'>
                <span className='additional-akaire-title'>
                  <img className="icon-upload" src={IconUploadYoutube} alt="アップロード" width="50" />
                  YouTube動画を赤入れする</span><br/>
              </div>
              <div className="align-center-div div-upload-youtube">
                <div className="row px-3">
                  <Label className="font-weight-bold h6">
                    赤入れする動画のURL
                  </Label>
                </div>
                <div className="row">
                  <div
                    className={`col-md-12 col-sm-12 ${
                      isBrowser ? "pr-0" : ""
                    }`}
                  >
                    <Input
                      type="text"
                      placeholder="動画のURLを入力"
                      name="link"
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                </div>

                <div className="mb-3 mt-1">
                  <div className="row">
                    <div
                      className="col-md-12 col-sm-12"
                    >
                      <span style={{color: '#6E6E6E', opacity: '1'}}>
                        （注）赤入れ途中に動画の元データが編集されると、赤入れ箇所がずれる場合があります。
                      </span> <br />
                      <span style={{color: '#6E6E6E', opacity: '1'}}>
                        （注）YouTube動画を表示中に赤入れすると「その他の動画」などの広告が表示する場合があります。
                      </span> <br />
                      <span style={{color: '#6E6E6E', opacity: '1'}}>
                        （注）メディア画面下部に表示する「歯車」機能は、弊社オリジナル以外は使用できません。
                      </span> <br />
                      <span style={{color: '#6E6E6E', opacity: '1'}}>
                        （注）赤入れ作業中に、YouTube動画を編集すると赤入れコメントが消える場合があります。
                      </span> <br />
                      <span style={{color: '#6E6E6E', opacity: '1'}}>
                        （注）Freeプランは3ページまで赤入れできます。（削除されたページはカウントされません）
                      </span> <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="additional-akaire-button text-center">
                <Button color="primary" className="">再生する</Button>
                <Button color="secondary" className="btn-button-close"
                            onClick={(event) => this.closeModal(event)} >閉じる</Button>
              </div>
            </ModalBody>
          </form>
        </Modal>
        <div className="show-modal-policy-youtube" ref={(modalPolicy) => this.modalPolicy = modalPolicy}>
          <Modal
            isOpen={isOpenPolicyVideoYoutube}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            size="xl"
            style={{maxWidth: '1000px'}}
            className="modal-policy-video-youtube modal-dialog-centered"
          >
            <ModalHeader className="text-center w-100 justify-content-center">
              <div className="text-center">
                動画URL使用に関する規約
              </div>
            </ModalHeader>
            <ModalBody style={{paddingTop: '0px'}}>
              <div className="font-size-12px mb-3">
                <span>この規約（以下「本規約」といいます）は、弊社運営のCreate Cloud内の赤入れ機能（以下「機能」といいます）で動画URLを使用する為の条件を定めたものです。 </span>
                <span>本規約に同意した場合、同機能内で動画URLを使用することができます。</span>
                <br />
                <span> 以下の事項をお読みいただき、同意の上ご利用ください。</span>
                <br />
                <span className="mt-3"> 第１条（著作権侵害に関する責任） </span>
                <br />
                <span className="ml-3"> 1.　動画URLを使用する場合、著作権や知的財産権に関する法律を遵守しなければなりません。 　</span>
                <span className="ml-3"> 2.　サービスを利用することで生じた著作権侵害やその他の法的問題については、弊社は一切の責任を負いません。</span>
                <br />
                <span className="mt-3"> 第２条（法的手続きの遵守）  </span>
                <br />
                <span className="ml-3"> 1.　動画URLを使用する場合、国内の法的措置に準拠し、個人情報保護法やプライバシーに関連する法律に留意する必要があります。 </span>
                <span className="ml-3"> 2.　動画URLを使用した事による法的な問題が生じた場合においても、弊社は一切の責任を負いません。会員は自らの法的義務を認識し、遵守する責任を負います。 </span>
                <br />
                <span className="mt-3"> 第３条（使用許諾）  </span><br />
                <span className="ml-3"> 動画URLを使用する場合、会員は当該動画のURLを使用する権利を持っているとみなします。 </span>
                <br />
                <span className="mt-3"> 第４条（免責事項）  </span><br />
                <span className="ml-3"> 動画URLの使用によって生じた、いかなる損害やトラブルに対して、弊社は一切の責任を負いません。 </span>
                <br />
                <span className="mt-3"> 第５条（その他の規定）  </span><br />
                <span className="ml-3"> 本規約に記載がない事項については、Create Cloud 利用規約（アカポン会員は、Akapon 利用規約）を遵守するものとします。 </span>
              </div>
              <div className="font-size-13px mt-3">
                2023年05月31日 制定
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
