export default class AkaireFile {
  openModalSettingStatusAkaireFile(task_id, project_id, current_status, bypass_confirm) {
    $('#offTaskNoticeStatus').find('.submit-update-status').attr('data-task-id', task_id)
    $('#offTaskNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    $('#onTaskNoticeStatus').find('.submit-update-status').attr('data-task-id', task_id)
    $('#onTaskNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    const bypass_after_setting = $('#tbody-list-akaire_file').attr('data-show-confirm-again')
    if((bypass_confirm === 'false' || bypass_confirm === '') && bypass_after_setting === 'false') {
      if(current_status || current_status === 'true') {
        $('#offTaskNoticeStatus').modal('show')
      } else {
        $('#onTaskNoticeStatus').modal('show')
      }
    } else {
      this.updateNotifyStatus($('#onTaskNoticeStatus').find('.submit-update-status'), !current_status)
    }
  }

  cannotSettingNotify() {
    $('#cannotSettingNotify').modal('show')
  }

  updateNotifyStatus(element, status) {
    let task_id = $(element).attr('data-task-id')
    let url = Routes.update_notify_status_akaire_file_index_path()
    Shared.callAjax(url, { status: status, akaire_file_id: task_id }, 'json')
    .done(function(data) {
      if(data.status) {
        $('#onTaskNoticeStatus').modal('hide')
        $(`#status-${task_id}`).find('.accept-notify').removeClass("d-none")
        $(`#status-${task_id}`).find('.reject-notify').addClass("d-none")
      } else {
        $('#offTaskNoticeStatus').modal('hide')
        $(`#status-${task_id}`).find('.accept-notify').addClass("d-none")
        $(`#status-${task_id}`).find('.reject-notify').removeClass("d-none")
      }
      location.reload()
    }).fail(function(result) {
      $('#onTaskNoticeStatus').modal('hide')
      $('#offTaskNoticeStatus').modal('hide')
      $('#cannotSettingNotify').modal('show')
    })
  }

  changeStatus(element, akaireFileID, projectID, status_new) {
    if ($(element).hasClass('active')) return

    const componentChangeStatus = $(`#status-akaire_file-${akaireFileID}`)
    const status_old = componentChangeStatus.attr('data-status-old')
    const url = Routes.akaire_change_status_akaire_file_index_path(akaireFileID)
    const statusStyle = this.fetchStatusStyle(status_new);
    const oldStatusStyle = this.fetchStatusStyle(status_old);
    Shared.callAjax(url, { status: status_new }).done(function(data) {
      componentChangeStatus.parent().find('.style-point').removeClass(oldStatusStyle).addClass(statusStyle)
      componentChangeStatus.parent().find('.status_text_description').html(data.status)
      componentChangeStatus.attr('data-status-old', status_new)
      $(element).parent().find('li').removeClass('active')
      $(`#modalSelectStatus-${akaireFileID}`).find(`li[data-status='${status_new}']`).addClass('active')
      $(`#modalSelectStatus-${akaireFileID}`).modal('hide')

      if (window.innerWidth < gon.shared.break_point_for_mobile) {
        Popup.confirm('', {
          description: Shared.textTaskAkaire(I18n.t('task.update.status.success'), I18n.t('task.update.status.success_akapon')),
          confirmText: 'OK',
          cancelBtn: 'false',
          success: () => { window.location.reload() },
          cancel: () => { window.location.reload() },
        })
        return
      }
      Popup.alert(Shared.textTaskAkaire(I18n.t('task.update.status.success'), I18n.t('task.update.status.success_akapon')))
    }).fail(function(data) {
      if (data.status && data.status_code === 401) {
        Popup.alert(Shared.textTaskAkaire(I18n.t('task.update.status.not_permission'), I18n.t('task.update.status.success_akapon')))
        return
      }
      Popup.alert(data.error)
    })
  }

  showHideSettingPic(objectId, akaireFileId) {
    const url = Routes.participants_for_akaire_file_index_path(objectId)
    Shared.callAjax(url, { object_id: objectId, akaire_file_id: akaireFileId }, 'script', 'GET')
  }

  showModalChangeStatusSp(ele, akaireFileId) {
    $(`#modalMenu-${akaireFileId}`).modal('hide')
    $(`#modalSelectStatus-${akaireFileId}`).modal('show')
  }

  settingDeadlineDate(ele, akaireFileId, objectId) {
    $(`#modal_expiration_task_${akaireFileId}`).modal('show')
  }

  setExpirationDate(ele, objectId, akaireFileId) {
    const modalComponent = $(ele).parents('.modal_expiration_task')
    const startDate = modalComponent.find('#expiration-date-start').val()
    const endDate = modalComponent.find('#expiration-date-end').val()
    const color = modalComponent.find(`input#colorInput_task_${akaireFileId}`).val()
    const url = Routes.change_dateline_for_akaire_file_index_path(objectId)
    const akaire = this
    Shared.callAjax(url, { akaire_file_id: akaireFileId, start_date: startDate, due_date: endDate, colors: color }).done(function(data) {
      akaire._showPopupReload('期限を更新しました。')
    })
    .fail(function(data) {
      akaire._showPopupReload('変更に失敗しました。')
    })
  }

  _showPopupReload(message) {
    Popup.confirm(message, {
      cancelBtn: 'false',
      confirmText: 'OK',
      success: () => { location.reload() },
      cancel: () => { location.reload() },
      width: 'auto',
      confirmButtonColor: this.colorPlanAkapon()
    })
  }

  settingNotifyShowAgain(element, type) {
    const url = Routes.setting_show_again_notify_akaire_file_index_path()
    const check_status = $(element).is(":checked")
    $(`#tbody-list-${type}`).attr('data-show-confirm-again', check_status)
    Shared.callAjax(url, { checked: check_status, type: type })
  }

  colorPlanAkapon(){
    const plan_akapon = $('body').find('nav#navbar-common').data('plan-akapon');
    if (plan_akapon == 'undefined'){
      return '#202f55';
    }
    return plan_akapon ? '#E60C11' : '#202f55';
  }

  resetExpirationDate(ele, taskId) {
    const modalComponent = $(ele).parents('.modal-content');
    let color = $(ele).attr('data-current-color') || 'blue';
    modalComponent.find('input#expiration-date-start').val('');
    modalComponent.find('input#expiration-date-end').val('');
    modalComponent.find('#colorPicker').find(`div[data-color="${color}"]`).trigger('click');
  }

  fetchStatusStyle(status_task) {
    let status_style = ''
    switch(status_task) {
      case 'preparation':
          status_style = 'status_green_point'
          break;
      case 'working':
          status_style = 'status_orange_point'
          break;
      case 'complete':
          status_style = 'status_red_point'
          break;
      case 'on_hold':
          status_style = 'status_gray_point'
      break;
    }
    return status_style
  }

  showMessageWithChangeAkaireFileType(ele) {
    const currentValue = $(ele).attr('task_category')
    let description = I18n.t('akaire_file.change_akaire_file_type_web_to_video')
    if (currentValue !== $(ele).val()) {
      if($(ele).val() == 'web') {
        description = I18n.t('akaire_file.change_akaire_file_type_video_to_web')
      }

      Popup.confirm('',{
        description: description,
        confirmText: 'OK',
        cancelText: 'キャンセル',
        success: () => {
          $(ele).attr('task_category', $(ele).val())
          this.submitEditTaskDetail()
        },
        cancel: () => {
          $(".edit_task").find("#task_category").val(currentValue)
        }
      })
    }
  }

  submitEditTaskDetail() {
    $("#submit-edit-task").click()
  }


  showSettingPicProject(projectId, akaireFileId) {
    const url = Routes.project_participants_akaire_file_index_path(projectId)
    Shared.callAjax(url, { akaire_file_id: akaireFileId }, 'script', 'GET')
  }

  handleCheckToAddPermission(element, userID){
    $('#popupInviteMember').find('#element-is-change').val('true')
    if($(element).is(":checked")) {
      const modalEle = $(element).parents('#popupInviteMember')
      const listMemberCheck = modalEle.find('input.check-box-check-member:checkbox:checked').toArray()
      let companyClientChecked = [];
      listMemberCheck.forEach(input => {
        if ($(input).val() === 'client' && $(input).is(':checked') && $(input).attr('data-invited-by-owner') === 'true') {
          companyClientChecked.push($(input).data('company-id'))
        }
      })
      let uniqueArray = [...new Set(companyClientChecked)];
      if (uniqueArray.length > 1 && element.value == 'client') {
        Popup.alert('複数の販売先が招待できません。')
        $(element).prop('checked', false)
        return
      }
      if ($(element).parents('.checkbox_noborder_intable').hasClass('c-red-check')) {
        $(element).parents('.show_permission_tr').attr('is-new-add', 'true')
        this.showEleFilterNewAdd()
      }
      $(element).parent().parent().parent().find('.label-checkbox').removeClass("disabled")
    } else {
      if (gon.shared.current_user.id == userID) {
        Popup.alert('自分を解除する事は出来ません。');
        $(element).prop('checked', true)
        return
      }
      if ($(element).parents('.checkbox_noborder_intable').hasClass('c-red-check')) {
        $(element).parents('.show_permission_tr').removeAttr('is-new-add')
        this.showEleFilterNewAdd(false)
      }
      $(element).parent().parent().parent().find('.label-checkbox').addClass('disabled')
    }

    const dataUserDelete = $(".list-user-delete");
    const dataUserAdd = $('.list-user-new-checked-akaire-file')
    if (dataUserAdd.length > 0) {
      if($(element).is(":checked")) {
        dataUserAdd.val(`${dataUserAdd.val()}${userID},`)
      } else {
        dataUserAdd.val(dataUserAdd.val().replace(`${userID},`, ''))
      }
    }
    if(dataUserDelete.length > 0) {
      if($(element).is(":checked")) {
        $(".list-user-delete").val(dataUserDelete.val().replace(`${userID},`, ''));
      } else {
        $(".list-user-delete").val(`${dataUserDelete.val()}${userID},`)
      }
    }
  }

  showListNewAdd(ele) {
    const tbody = $('#popupInviteMember').find('tbody.permission-row')
    const listTr = tbody.find('tr.show_permission_tr').toArray()
    const numerCurrentPageMember = $('#popupInviteMember').find('.render_pagy_of_list_member_project').find('.page-item.active').attr('data-index')
    if ($(ele).attr('is-show') && $(ele).attr('is-show') === 'true') {
      listTr.forEach(tr => {
        if($(tr).attr('data-page') === numerCurrentPageMember || $(tr).hasClass('tr-new-email')) {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'false')
      const text = $(ele).data('text')
      const number = $(ele).attr('data-number')
      $(ele).text(`${number} ${text}`)
      $('#popupInviteMember').find('.new-style-with-js').removeClass('d-none')
    } else {
      listTr.forEach(tr => {
        if ($(tr).attr('is-new-add') && $(tr).attr('is-new-add') === 'true') {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'true')
      $(ele).text($(ele).attr('data-text-hide'))
      $('#popupInviteMember').find('.new-style-with-js').addClass('d-none')
    }
  }


  showEleFilterNewAdd(isPositive = true) {
    const spanShow = $('#popupInviteMember').find('.show-number-member-add')
    const text = spanShow.data('text')
    const number = isPositive ? Number(spanShow.attr('data-number')) + 1 : Number(spanShow.attr('data-number')) - 1
    spanShow.attr('data-number', number)
    if (spanShow.attr('is-show') === 'false') {
      spanShow.text(`${number} ${text}`)
    } else {
      spanShow.text(spanShow.attr('data-text-hide'))
    }
    number === 0 ? spanShow.addClass('d-none') : spanShow.removeClass('d-none')
  }

  handleUpdatePicOfTask(element, objectId, akaireFileId) {
    let user_invite_ids = [];
    const user_delete_ids = $('#list-user-uncheck').val().split(',').filter(Boolean);
    let user_invite_emails = [];
    let list_invite_member_of_partner = [];
    $(element).parent().parent().find(".pick-pics").each(function( _index, ele ) {
      if($(ele).is(":checked")) {
        if($(ele).attr("name").includes("email")) {
          user_invite_emails.push({email: $(ele).val(), type: $(ele).attr("data-type")})
        } else if($(ele).attr("name").includes("partners")) {
          list_invite_member_of_partner.push({email: $(ele).val(), type: $(ele).attr("data-type"), company_id: $(ele).attr("data-company-id"), name: $(ele).attr("data-name")})
        } else {
          user_invite_ids.push($(ele).val())
        }
      }
    });
    const url = Routes.update_pic_for_task_or_ticket_akaire_file_index_path(objectId)
    Shared.callAjax(url,
      { list_user_invite: user_invite_ids, list_user_delete: user_delete_ids,
        list_user_invite_by_email: user_invite_emails, akaire_file_id: akaireFileId,
        list_invite_member_of_partner: list_invite_member_of_partner
      }
    ).done(function(result){
      if(result.self_remove) location.reload()
      $("#personInChargeOfObject").modal("hide")
      if (result.number_user === 0) {
        $(`#pic_list_${result.task_id}`).html('自分')
      } else {
        $(`#pic_list_${result.task_id}`).html(`自分と他${result.number_user}名`)
      }
      if (result.is_uncheck_member) {
        Popup.alert('メンバーを更新しました。')
      }else {
        Popup.alert('メンバーを更新しました。<br>新しいファイルの作成権限も付与されます。')
      }
      $('.list-permission').find('.list-permission-name').html(result.approve_participants_name)
    }).fail(function() {
      Popup.confirm(I18n.t('work.changed_refresh'), {
        success: () => {
          window.location.reload()
        },
        cancel: () => {
          window.location.reload()
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: '35rem'
      })
    })
  }

  handleCheckRemoveUnapproveMember(element, projectId, objectId, objectType, akaireFileId) {
    let user_name = $(element).parent().find(".t_name").text()
    let user_id = $(element).attr("data-user-id")
    const akaire = this
    Popup.confirm('', {
        description: `${user_name}さんを解除します。<br> よろしいですか？`,
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
          let url = Routes.owner_remove_member_unapprove_of_task_path(projectId, objectId)
          if(objectType == 'Work') url = Routes.owner_project_ticket_remove_member_unapprove_path(projectId, objectId)
          Shared.callAjax(url, {user_id: user_id}, 'json', 'post')
          .done(function(data) {
            Popup.confirm('',{
              description: `${user_name}さんの <br> 招待を解除しました。`,
              confirmText: 'OK',
              cancelBtn: 'false',
              success: () => {
                akaire.showHideSettingPic(objectId, akaireFileId)
              }
            })
          })
          .fail(function(data) {
            Popup.confirm('',{
              description: 'false',
              confirmText: 'OK',
              cancelBtn: 'false',
            })
          })
        },
      }
    )
  }

  handleCheckRemoveUnapproveRequest(element, project_id, task_id, akaireFileId) {

  }

  onClickSetPasswordUrl(ele, akaireFileId, isCreatePass) {
    const componentModal = $(ele).parents('.modal-body');
    const divParent = $(ele).parents('.show-content-on-off-share-password')
    if (isCreatePass === 'false') {
      $(ele).prop('checked', false)
      divParent.find('.show-on').addClass('d-none')
      divParent.find('.show-off').removeClass('d-none')
      Popup.alert('パスワード設定の権限がありません。')
      return
    }
    if ($(ele).is(':checked')) {
      divParent.find('.show-on').removeClass('d-none')
      divParent.find('.show-off').addClass('d-none')
      componentModal.find('.demo-pass-share-akaire-file').addClass('d-none')
      componentModal.find('.show-change-pass-share-akaire').removeClass('d-none')
    } else {
      const divEle = $(ele).parents('.show-text-of-modal-share-akaire-file')
      const password = divEle.attr('data-password')
      const divForPass = divEle.find('.show-all-input-pass-share')
      if (password.length > 0) {
        Popup.confirm(
          'パスワード設定をOFFにすると<br>設定したパスワードはリセットされます。<br>OFFにしてよろしいですか？',
          {
            success: () => {
              this._handleSetPassWordShare(akaireFileId, '', divForPass, divEle, false)
              this._handleOffSharePass(divParent, componentModal)
            },
            cancel: () => {
              $(ele).prop('checked', true)
            },
            confirmText: 'OK',
            width: 'auto',
          }
        )
        return
      }
      this._handleOffSharePass(divParent, componentModal)
    }
  }

  _handleOffSharePass(divParent, componentModal) {
    divParent.find('.show-on').addClass('d-none')
    divParent.find('.show-off').removeClass('d-none')
    componentModal.find('.demo-pass-share-akaire-file').addClass('d-none')
    componentModal.find('.show-change-pass-share-akaire').addClass('d-none')
  }

  checkPassWordShare(ele) {
    if ($(ele).val().length >= 6) {
      $(ele).parents('.show-change-pass-share-akaire').find('.btn-save-password-share').attr('disabled', false)
    } else {
      $(ele).parents('.show-change-pass-share-akaire').find('.btn-save-password-share').attr('disabled', true)
    }
  }

  savePassWordShare(ele, akaireFileId) {
    const password = $(ele).parents('.show-change-pass-share-akaire').find('.input-share-video').val()
    const divParent = $(ele).parents('.show-all-input-pass-share')
    const divEle = $(ele).parents('.show-text-of-modal-share-akaire-file')
    this._handleSetPassWordShare(akaireFileId, password, divParent, divEle)
  }

  _handleSetPassWordShare(akaireFileId, password, divParent, divEle, isChangeShow = true) {
    const url = Routes.set_password_for_share_akaire_file_index_path(akaireFileId)
    Shared.callAjax(url, { password: password }).done(function(data) {
      if (isChangeShow) {
        divParent.find('.demo-pass-share-akaire-file').removeClass('d-none')
        divParent.find('.show-change-pass-share-akaire').addClass('d-none')
      }
      divEle.find('.input-share-video').val(password)
      divEle.attr('data-password', password)
      Popup.alert('パスワードを保存しました。')
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  changeFocusInput(ele) {
    const divParent = $(ele).parents('.show-all-input-pass-share')
    divParent.find('.demo-pass-share-akaire-file').addClass('d-none')
    divParent.find('.show-change-pass-share-akaire').removeClass('d-none')
  }

  showUploadAnimation(ele, akaireFIleId, url, fileName) {
    Popup.confirm(
      `「${fileName}」に最新データをアップロードします。<br>ファイルに移動します。よろしいですか？`,
      {
        success: () => {
          window.location = `${url}?show_upload_animation=true`
        },
        cancel: () => {},
        confirmText: 'OK',
        width: 'auto',
      }
    )
  }

  downloadComment(ele, url) {
    if (url === '') {
      Popup.alert('データがありません。')
      return
    }
    window.location.href = url;
    Popup.alert('ダウンロードが完了しました。')
  }

  changeTypeFile(ele, akaireFIleId, newType, currentType) {
    if (currentType === newType) return

    const text = newType === 'web' ? 'WEB' : '動画'
    Popup.confirm(
      `ファイル種別を${text}に変更します。<br>種別を変更すると、前の種別データは削除されます。<br>変更してよろしいですか？`,
      {
        success: () => {
          this._handleChangeType(ele, akaireFIleId, newType)
        },
        cancel: () => {},
        confirmText: 'OK',
        width: 'auto',
      }
    )
  }

  _handleChangeType(ele, akaireFIleId, newType) {
    const url = Routes.change_type_akaire_akaire_file_index_path(akaireFIleId)
    Shared.callAjax(url, { category: newType }).done((data) => {
      const divELe = $(ele).parents('.display-center-div')
      divELe.find('div[type="button"]').attr('disabled', false)
      $(ele).attr('disabled', true)
      $(ele).parents('.modal').modal('hide')
      location.reload()
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  changeAkaireFileName(ele, akaireFileId) {
    $(`#modal-change-akaire-file-name-${akaireFileId}`).modal('show')
  }

  changeFileName(ele, akaireFileId) {
    const newName = $(ele).parents('.modal-body').find('.input-change-name').val()
    if (newName.length <= 0) {
      $(ele).parents('.modal-body').find('.input-change-name').attr('placeholder', 'ファイル名を入力してください。')
      return
    }
    const url = Routes.change_akaire_file_name_akaire_file_index_path(akaireFileId)
    Shared.callAjax(url, { name: newName }).done((data) => {
      $(ele).parents('.modal').modal('hide')
      Popup.confirm('ファイル名を更新しました。', {
        success: () => { location.reload() },
        cancel: () => {location.reload()},
        successBtn: 'false',
        width: 'auto',
      })
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  copy(akaireFileId, name) {
    const url = Routes.copy_akaire_file_akaire_file_index_path(akaireFileId)
    Shared.callAjax(url, {}).done((data) => {
      var messagePopup = `現在、${name}のコピー中です。<br>コピー完了後、リロードするとファイル一覧に表示されます。<br> ※コピー完了後、ご登録のメールアドレス宛に完了通知が届きます。`
      Popup.alert(messagePopup, '', '', { kind: 'akaire' })
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  delete(akaireFileId, name, fromAkairePage = false) {
    Popup.confirm(
      `「${name}」の赤入れデータを削除します。<br>復元できません。削除してよろしいですか？`,
      {
        success: () => {
          this._handleDelete(akaireFileId, name, fromAkairePage)
        },
        cancel: () => {},
        confirmText: 'OK',
        width: 'auto',
      }
    )
  }

  _handleDelete(akaireFileId, name, fromAkairePage) {
    const url = Routes.delete_akaire_file_akaire_file_index_path(akaireFileId)
    Shared.callAjax(url, {}).done((data) => {
      Popup.confirm( `「${name}」のデータが削除されました。`, {
        success: () => { window.location.reload() },
        cancel: () => { window.location.reload() },
        successBtn: 'false',
        width: 'auto',
      })
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async deleteAkaireFileInfo(akaireFileId, name) {
    Popup.confirm(
      `「${name}」の赤入れデータを削除します。<br>復元できません。削除してよろしいですか？`,
      {
        success: () => {
          this._handleDelete(akaireFileId, name)
        },
        cancel: () => {},
        confirmText: 'OK',
        width: 'auto',
      }
    )
  }

  selectSearchType(ele, type) {
    $(ele).addClass('slted')
    const divEle = $(ele).parents('.dropdown-body')
    divEle.find(`input.search_for_${type}`).val('true')
  }

  resetAllSearchType(ele) {
    $(ele).parents('.td-filter-box').find('input[type="checkbox"]').prop('checked', false)
    $(ele).parents('.td-filter-box').find('div.slted').removeClass('slted')
  }

  showModalSearchForType(ele, type) {
    $(ele).parents('.modal').modal('hide')
    $(`#modalShowSearchList${type}`).modal('show')
  }

  selectPage(element) {
    const modal = $('#uploadVersionPopup')
    var page = $(element).attr('data-page-id')
    var page_name = $(element).text()
    var total_version = $(element).attr('data-total-vesion');
    modal.attr('data-page-id', page)
    modal.find('.akaire-page-name').html(page_name)
    modal.find('.count-total-version').html(`Ver ${total_version} にアップロードされます。`)
  }

  updateAkaireFileName(akaire_file_id) {
    const akaireFileName = $('#settingAkaireFileNamePopup').find('#akaire-file-name').val()
    $('.akaire_file_name').val(akaireFileName).trigger('change')
    Popup.confirm( `赤入れファイル名：${akaireFileName} <br> に変更されました。`, {
        success: () => { this.openSettingMember() },
        cancel: () => { this.openSettingMember() },
        successBtn: 'false',
        width: 'auto',
        kind: 'akaire'
      })
  }

  updateProjectName(project_id) {
    const projectName = $('#settingProjectNamePopup').find('#project-name').val()
    Shared.callAjax(
      Routes.update_project_name_owner_project_path(project_id),
      {project_name: projectName},
      '',
      'put'
    ).done((data) => {
      $('#settingAkaireFileNamePopup').modal('show');
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  handleOpenSettingMember(event) {
    if($(event.target).parents('.loading-overlay').length == 1) return
    if($(event.target).parents('.preloader').length == 1) return
    if(event.target.tagName == 'BODY') return

    if((($(event.target).parents('#noticeAssignSuccess').length == 0) && $('#noticeAssignSuccess').is(':visible')) ||
      (($(event.target).parents('#settingAkaireFileNamePopup').length == 0) && $('#settingAkaireFileNamePopup').is(':visible')) ||
      (($(event.target).parents('#noticeAssignSuccessWithFirstProject').length == 0) && $('#noticeAssignSuccessWithFirstProject').is(':visible')) ||
      ($(event.target).parents('#settingProjectNamePopup').length == 0) && $('#settingProjectNamePopup').is(':visible')){
      this.openSettingMember()
    }
  }

  openSettingMember(){
    if($('#modal-show-notify-mobile').length == 1) return

    setTimeout(function(){
      $('#click-show-list-member-of-akaire-page-detail').trigger('click')
    }, 200);
  }

  updateSelectDisplayFile(object_id, objectType, selectValue, typeMode = 'pcMode') {
    const url = Routes.update_display_type_file_akaire_file_index_path()
    Shared.callAjax(url, { select_value: selectValue, object_id: object_id, object_type: objectType }, '', 'post').done((data) => {
      location.reload()
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  selectMultiOptionModalSearchTask(type, taskId, element){
    const classOfElement = $(element).attr('class');
    if(classOfElement.includes('slted')) {
      $(element).removeClass('slted')
      $(element).find("input[type='checkbox']").prop('checked', false)
    } else {
      $(element).addClass('slted')
      $(element).parent().find('.option-all').removeClass('slted')
      $(element).find("input[type='checkbox']").prop('checked', true).change()
    }
    this.checkedAndRemoveCheckedSearchTicket(element, taskId)
  }

  checkedAndRemoveCheckedSearchTicket(ele, taskId) {
    const componentListWork = $('.filter-list-work')
    const componentListTask = $('.filter-list-task')
    const modalSearchListTask = $('#modalShowSearchListTask')
    const modalSearchListWork = $('#modalShowSearchListWork')
    const listTaskChecked = []
    const allListWork = componentListWork.find('div.option')
    if (window.innerWidth > 1023) {
      componentListTask.find('input[type="checkbox"]:checked').toArray().forEach(task => {
        listTaskChecked.push($(task).val())
      })
    } else {
      modalSearchListTask.find('input[type="checkbox"]:checked').toArray().forEach(task => {
        listTaskChecked.push($(task).val())
      })
    }
    modalSearchListWork.find('.dropdown-body').find('div.option').toArray().forEach(div => {
      if (listTaskChecked.includes($(div).attr('data-task-id'))) {
        $(div).removeClass('d-none')
      } else {
        $(div).removeClass('slted').addClass('d-none')
        $(div).find('input[type="checkbox"]').prop('checked', false)
      }
    })
    allListWork.toArray().forEach(div => {
      if (listTaskChecked.includes($(div).attr('data-task-id'))) {
        $(div).removeClass('d-none')
      } else {
        $(div).removeClass('slted').addClass('d-none')
        $(div).find('input[type="checkbox"]').prop('checked', false)
      }
    })
  }

  openUploadNewPageModal() {
    Shared.showModalForElement('#uploadNewPagePopup')
    $('#uploadNewPagePopup').attr('data-upload-new', 'true')
  }

  openUploadVersionModal() {
    Shared.showModalForElement('#uploadVersionPopup')
    $('#uploadNewPagePopup').attr('data-upload-new', 'false')
  }

  selectProjectForChange(ele, akaireFileID) {
    const modalProject = $('#modal-change-position-akaire-file-project')
    const url = Routes.fetch_list_project_of_user_akaire_file_index_path()
    const file = this;
    Shared.callAjax(url, { akaire_file_id: akaireFileID }).done(function(response) {
      modalProject.find('#render-list-project').empty().append(response.html)
      modalProject.modal('show')
    }).fail(function(response) {
      file.showErrorNotChanPosition(response)
    })
  }

  showErrorNotChanPosition(response) {
    if (response.responseJSON && response.responseJSON.not_owner) {
      Popup.alert('ファイルの所有者以外は<br>保存先を変更できません。')
      return
    }
    Popup.alert(I18n.t('shared.one_error_please_try_again'))
  }

  showSelectProject(ele, projectId, akaireFileID, projectName) {
    const modalShowSelectProject = $('#modal-accept-change-position-akaire-file-project')
    modalShowSelectProject.find('.show-project-select').text(projectName)
    let onclick = ''
    if (gon.is_plan_akapon) {
      onclick = `AkaireFile.acceptSelectProject(this, '${projectId}', '${akaireFileID}')`
    } else {
      onclick = `AkaireFile.selectTaskChangeFile(this, '${projectId}', '${akaireFileID}')`
    }
    modalShowSelectProject.find('.btn-select-project').attr('onclick', onclick)
    $('#modal-change-position-akaire-file-project').modal('hide')
    modalShowSelectProject.modal('show')
  }

  acceptSelectProject(ele, projectId, akaireFileID) {
    $('#modal-accept-change-position-akaire-file-project').modal('hide')
    $('#modal-accept-change-position-akaire-file-task').modal('hide')
    if (gon.is_show_popup_confirm_change_position) {
      this.showPopupConfirm(`AkaireFile.hangdleChangeFileToProject('${projectId}', '${akaireFileID}')`, akaireFileID, projectId)
    } else {
      this.hangdleChangeFileToProject(projectId, akaireFileID)
    }
  }

  acceptSelectTask(ele, taskId, projectId, akaireFileID) {
    $('#modal-change-position-akaire-file-task').modal('hide')
    $('#modal-accept-change-position-akaire-file-task').modal('hide')
    if (gon.is_show_popup_confirm_change_position) {
      this.showPopupConfirm(`AkaireFile.hangdleChangeFileToTask('${taskId}', '${projectId}', '${akaireFileID}')`, akaireFileID, projectId)
    } else {
      this.hangdleChangeFileToTask(taskId, projectId, akaireFileID)
    }
  }

  acceptSelectTicket(ele, ticketId, akaireFileID) {
    $('#modal-change-position-akaire-file-ticket').modal('hide')
    $('#modal-accept-change-position-akaire-file-ticket').modal('hide')
    if (gon.is_show_popup_confirm_change_position) {
      this.showPopupConfirm(`AkaireFile.hangdleChangeFileToTicket('${ticketId}', '${akaireFileID}')`, akaireFileID, ticketId, 'ticket')
    } else {
      this.hangdleChangeFileToTicket(ticketId, akaireFileID)
    }
  }

  hangdleChangeFileToProject(projectId, akaireFileID) {
    this._handleChangePosition(akaireFileID, null, projectId, null, 'project_change')
  }

  hangdleChangeFileToTask(taskId, projectId, akaireFileID) {
    this._handleChangePosition(akaireFileID, taskId, projectId, null, 'task_change')
  }

  hangdleChangeFileToTicket(ticketId, akaireFileID) {
    this._handleChangePosition(akaireFileID, null, null, ticketId, 'ticket_change')
  }

  _handleChangePosition(akaireFileID, taskId, projectId, ticketId, typeChange) {
    $('#popup-confirm-change-position-akaire-file').modal('hide')
    const modalSuccess = $('#popup-success-change-position-akaire-file')
    const url = Routes.change_position_akaire_file_akaire_file_index_path()
    Shared.callAjax(url, {
      akaire_file_id: akaireFileID, task_id: taskId, type_change: typeChange,
      project_id: projectId, ticket_id: ticketId
    }).done(function(response) {
      location.href = response.url + `?show_popup_change_file_id=${akaireFileID}`
    }).fail(function(response) {
      if (response.responseJSON && response.responseJSON.error_type) {
        let message = ''
        switch(response.responseJSON.error_type) {
          case 'max_quantity_files':
            message = '赤入れファイル数をオーバーしている為、保管先を更新できません。'
            break;
          case 'max_total_file_size':
            message = '容量をオーバーしている為、保管先を更新できません。'
            break;
          case 'max_total_video_embed':
            message = 'YouTubeの赤入れをオーバーしている為、保管先を更新できません。'
            break;
          case 'max_total_login_pages':
            message = 'ID・PASSサイトの赤入れをオーバーしている為、保管先を更新できません。'
            break;
        }
        Popup.alert(message)
        return
      }
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  changeShowPopupConfirmPositionFile(ele) {
    const url = Routes.change_show_popup_confirm_akaire_file_akaire_file_index_path()
    const isChecked = $(ele).prop('checked')
    Shared.callAjax(url, { is_show: !isChecked }).done(function(response) {
      gon.is_show_popup_confirm_change_position = response.is_show
    }).fail(function(error) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  showPopupConfirm(functionOnclick, akaireFileId, objectId, type = 'project') {
    let data;
    if (type === 'project') {
      data = { project_id: objectId, akaire_file_id: akaireFileId }
    } else {
      data = { ticket_id: objectId, akaire_file_id: akaireFileId }
    }
    const url = Routes.check_show_popup_change_akaire_file_akaire_file_index_path()
    let confirmPopup = $('#popup-confirm-change-position-akaire-file')
    Shared.callAjax(url, data).done(function(response) {
      if (response.new_company) {
        confirmPopup = $('#popup-confirm-change-position-akaire-file-not-compare-project')
        confirmPopup.find('.show-name-company-new').text(response.company_name)
        confirmPopup.find('.file-name').text(response.file_name)
      }
      confirmPopup.find('.btn-onclick-confirm-change-position').attr('onclick', functionOnclick)
      confirmPopup.modal('show')
    }).fail(function(error) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  selectTaskChangeFile(ele, projectId, akaireFileID) {
    const modalTask = $('#modal-change-position-akaire-file-task')
    const modalShowSelectProject = $('#modal-accept-change-position-akaire-file-project')
    modalShowSelectProject.modal('hide')
    const url = Routes.fetch_list_task_of_user_akaire_file_index_path()
    const file = this;
    Shared.callAjax(url, { akaire_file_id: akaireFileID, project_id: projectId }).done(function(response) {
      modalTask.find('#render-list-task').empty().append(response.html)
      modalTask.modal('show')
    }).fail(function(response) {
      file.showErrorNotChanPosition(response)
    })
  }

  showSelectTask(ele, taskId, akaireFileID, projectId, taskName) {
    const modalShowSelectProject = $('#modal-accept-change-position-akaire-file-task')
    $('#modal-change-position-akaire-file-task').modal('hide')
    modalShowSelectProject.find('.show-task-select').text(taskName)
    let onclick = `AkaireFile.showSelectTicket(this, '${taskId}', '${projectId}', '${akaireFileID}')`
    modalShowSelectProject.find('.btn-select-task').attr('onclick', onclick)
    $('#modal-change-position-akaire-file-project').modal('hide')
    modalShowSelectProject.modal('show')
  }

  showSelectTicket(ele, taskId, projectId, akaireFileID) {
    const modalTicket = $('#modal-change-position-akaire-file-ticket')
    $('#modal-accept-change-position-akaire-file-task').modal('hide')
    const url = Routes.fetch_list_ticket_of_user_akaire_file_index_path()
    const file = this
    Shared.callAjax(url, { akaire_file_id: akaireFileID, task_id: taskId, project_id: projectId }).done(function(response) {
      modalTicket.find('#render-list-ticket').empty().append(response.html)
      modalTicket.modal('show')
    }).fail(function(response) {
      file.showErrorNotChanPosition(response)
    })
  }

  showAcceptSelectTicket(ele, ticketId, akaireFileID, taskName) {
    const modalShowSelectProject = $('#modal-accept-change-position-akaire-file-ticket')
    $('#modal-change-position-akaire-file-ticket').modal('hide')
    modalShowSelectProject.find('.show-ticket-select').text(taskName)
    let onclick = `AkaireFile.acceptSelectTicket(this, '${ticketId}', '${akaireFileID}')`
    modalShowSelectProject.find('.btn-select-ticket').attr('onclick', onclick)
    modalShowSelectProject.modal('show')
  }

  changeStatusWithSelect(ele, akaireFileId, reload = false) {
    const status = $(ele).attr('value')
    const url = Routes.akaire_change_status_akaire_file_index_path(akaireFileId)
    Shared.callAjax(url, { status: status }, 'json').done(function(data) {
      if (reload) {
        Shared.reloadCurrentPage();
      } else {
        Popup.alert(I18n.t('task.update.status.success'))
      }
      $('#change-status-of_task-new').html(data)
    })
  }
}
