import $ from 'jquery';
import 'bootstrap';

$(() => {
  $('[data-toggle="popover"]').popover({
    placement: 'bottom',
    html: true,
  })

  $('.animation-inspector').on('mouseenter', '.show-mark-shape', function() {
    hover_in_player($(this).attr('data'), 'rgba(253, 239, 135, 0.25)', 'rgba(253, 239, 135, 0.25)', 'block')
  })

  $('.animation-inspector').on('mouseleave', '.show-mark-shape', function() {
    hover_in_player($(this).attr('data'), 'unset', 'rgba(253, 239, 135, 0.25)', 'block')
  })

  $('.animation-inspector').on('click', '.show-mark-shape', function () {
    $('.items').animate({
      scrollTop: $('.animation-marker-' + $(this).attr('data')).position().top
    }, 'slow');
  })

  $('.items').on('mouseenter', '.item', function(){
    hover_in_player($(this).attr('data'), 'rgba(253, 239, 135, 0.25)', 'rgba(253, 239, 135, 0.25)', 'block')
  })

  $('.items').on('click', '.item', function () {
    $('.animation-preview').animate({
      scrollTop: $('.marker-' + $(this).attr('data')).position().top - 200
    }, 'slow');
  })

  $('.items').on('mouseleave', '.item', function () {
    hover_in_player($(this).attr('data'), 'none', 'rgba(255, 0, 0, 0.2)', 'none')
  })
})

function hover_in_player(marker, background_animation, background_maker, display) {
  $('.animation-marker-' + marker).css('background', background_animation);
  $('.marker-' + marker).find('.red-rect').css('background', background_maker);
  $('.marker-' + marker).closest('.animation-marker').find('.card').css('display', display);
}
