export default class InviteUserPage {
  constructor() {
    this.clickUpdate = false;
  }

  setClickUpdate(status) {
    this.clickUpdate = status;
  }

  getClickUpdate() {
    return this.clickUpdate;
  }

  updateValueCheckbox(element) {
    element = $(element)
    element.val(!(element.val() == 'true'))
    this._handleAuthorizeProjectAll(element)
  }

  popupInforOrderOfTicket(element, projectID, userID, isProject = false, userName) {
    Shared.callAjax(Routes.check_user_belong_order_owner_project_path(projectID), {user_id: userID}, null, 'get').done(function(data){
      if (data.belong_order) {
        InviteUserPage.modalListTickets(projectID, userID)
      } else {
        if (isProject) {
          InviteUserPage.modalDeleteUser(projectID, userID, userName)
        } else {
          Task.deleteUserInvite(element)
        }
      }
    })
  }

  addInviteUserRow(element){
    let length = $('#inviteTable').find('.invite_row').not('.invite_add_block').length //1
    let index = $('#inviteTable').find('.invite_row').not('.invite_add_block').eq(length - 1).attr('data-index')
    index = (Number(index) + 1) || 0

    let addElement = $('#inviteTable').find('.invite_add_block').clone(true)
    addElement.removeClass('invite_add_block').attr('data-index', index)
    addElement.find('.transaction_type').find('.select-2').attr('id', `js_select_company_type${index}`).select2({width: "100%", minimumResultsForSearch : Infinity});
    addElement.find('.company_name').find('.select-2').attr('id', `js-select_company_invites${index}`).select2({width: "100%"});
    addElement.find('.member_name').find('.select-2').attr('id', `js-select_users_invites${index}`).select2({width: "100%"});

    $('#inviteTable').append(addElement)

    $(".select2-selection").on("click", function() {
      $('li:contains("新規に自社メンバーを招待する")').addClass("text-underline")
      $('li:contains("新規に仕入先メンバーを招待する")').addClass("text-underline")
      $('li:contains("新規に販売先メンバーを招待する")').addClass("text-underline")
      $('li:contains("新規に外部メンバーを招待する")').addClass("text-underline")
    })

    $('#addButton').addClass('disabled')
    this.handleEventForInviteItem(`#js-select_company_invites${index}`);
  }

  selectInviteType(element){
    var type_text = {
      owners: { type: 'owner', text: '自社' },
      suppliers: { type: 'supplier', text: '仕入先' },
      clients: { type: 'client', text: '販売先' },
      collaborators: { type: 'collaborator', text: '外部' }
    }
    var project_id = $(element).attr('project_id');
    let parentRow = $(element).closest('.invite_row')
    let index = parentRow.attr('data-index')
    let type_select = $(element).val()

    if(type_select == 'owners') {
      $("#addButton").click()
      parentRow.remove();
      return
    }

    $('#show_hidden_user_permirssions').addClass('d-none');
    parentRow.find($("select[id^=js-select_users_invites]")).empty().append('<option value="0">ユーザーを選択</option>');
    const url = Routes.owner_get_company_by_company_type_path(project_id);
    const data = {
      'company_type': $(element).val()
    };
    const callAjax = Shared.callAjax(url, data);
    callAjax.done(function(data){
      $(`#js-select_company_invites${index}`).empty().append('<option value="0">会社を選ぶ</option>');
      if($(`#js-select_company_invites${index}`).closest('form').attr('has_client') == 'false' || type_select != 'clients') {
        $(`#js-select_company_invites${index}`).append(`<option value="${type_text[type_select].type}">新規に${type_text[type_select].text}メンバーを招待する</option>`);
      }
      if (data.length == 0){
      }else{
        for(let i = 0; i < data.length; i++){
          $(`#js-select_company_invites${index}`).append('<option value="' +data[i].id + '">' + data[i].name + '</option>');
        }
      }

    })
    callAjax.fail(function(){
      Popup.alert('エラーが発生しましたもう一度確認してください');
    })

  }

  removeInviteUserRow(element){
    if($(element).parent().data('index') == $("#inviteTable").find(".invite_row").not(".invite_add_block").last().data('index')) {
      $('#addButton').removeClass('disabled')
    }
    if ($('#inviteTable > div:not(.invite_add_block)').length == 1) {
      $(element).closest('.invite_row').remove();
      const index = 0;
      let addElement = $('#inviteTable').find('.invite_add_block').clone(true)
      addElement.removeClass('invite_add_block').attr('data-index', index)
      addElement.find('.select-2').attr('id', `js-select_company_invites${index}`).select2({width: "100%"});
      $('#inviteTable').append(addElement)

      this.handleEventForInviteItem(`#js-select_company_invites${index}`);
    } else {
      $(element).closest('.invite_row').remove();
      if ($('#inviteTable > div:not(.invite_add_block)').length == 1) {
        $('#submit_invite_user_to_project').html('招待する');
      }
    }
  }

  removeInviteNewUserRow(element, type, email) {
    var inviteNew = $('.list-user-invite-new').val()
    if(inviteNew.includes(`, ${email},${type}`)) {
      $('.list-user-invite-new').val(inviteNew.replace(`, ${email},${type}`, ''))
    } else {
      $('.list-user-invite-new').val(inviteNew.replace(`${email},${type}`, ''))
    }
    this.removeInviteUserRow(element)
  }

  clickUserPermissionCheckbox(element) {
    var list_ids = JSON.parse($('#user_permission_checkbox_change_ids').val());

    if (list_ids.includes(element.id)) {
      let ids = list_ids.filter(id => id != element.id);
      list_ids = ids;
      $('#user_permission_checkbox_change_ids').val(JSON.stringify(ids));
    } else {
      list_ids.push(element.id);

      $('#user_permission_checkbox_change_ids').val(JSON.stringify(list_ids));
    }

    if (list_ids.length > 0) {
      $('.submit_update_project_permissions').removeClass('disabled');
    } else {
      $('.submit_update_project_permissions').addClass('disabled');
    }
  }

  hardSubmitInviteUser() {
     $('#addAuthority').find('#hard_submit').val('true')
     $('#addAuthority').find('.button-submit').click()
  }

  handleEventForInviteItem(element) {
    $('body').on('select2:select', element, function(e){
      let element = $(this);

      var e_data = e.params.data;
      $(this).attr('id_company', e_data.id);
      var project_id = $('#current_project_id').attr('project_id');
      let parentRow = $(this).closest('.invite_row')
      let indexRow = parentRow.data('index')
      let company_id = $(this).val();
      let company_type = parentRow.find(`#js_select_company_type${indexRow}`).val();

      if (company_id == 0 || company_id == undefined){
        parentRow.find(`#js-select_users_invites${indexRow}`).empty().append('<option value="0">ユーザーを選択</option>');
      }else {
        const url = Routes.owner_list_user_not_user_permission_path(project_id);
        const data = {
          'company_id': company_id,
          'company_type': company_type
        };
        const callAjax = Shared.callAjax(url, data);
        callAjax.done(function(data){
          parentRow.find(`#js-select_users_invites${indexRow}`).empty().append('<option value="0">ユーザーを選択</option>');
          if (company_type == 'owners') {
            parentRow.find(`#js-select_users_invites${indexRow}`).append('<option value="owner">新規に自社メンバーを招待する</option>');
          }

          if (data.length != 0) {
            for(let i =0; i < data.length; i++){
              parentRow.find(`#js-select_users_invites${indexRow}`).append('<option value="' +data[i].id + '">' + data[i].name + '</option>');
            }
          }
        })
        callAjax.fail(function(){
          parentRow.find(`#js-select_users_invites${indexRow}`).empty().append('<option value="0">ユーザーを選択</option>');
        })
      }
    });
  }

  checkMemberBeforeDeletePermission(element, projectId, userId, project_list = false) {
    const url = Routes.check_member_before_delete_permission_owner_project_path(projectId)
    const listUserUncheck = $(".list-user-uncheck");
    if(project_list){
      if(listUserUncheck.length > 0) {
        if($(element).is(":checked")) {
          $(".list-user-uncheck").val(listUserUncheck.val().replace(`${userId},`, ''));
        } else {
          $(".list-user-uncheck").val(`${listUserUncheck.val()}${userId},`)
        }
      }
    }
    const data = project_list ? { user_id: userId, list_user_uncheck: listUserUncheck.val() } : { user_id: userId };
    Shared.callAjax(url, data)
    .done(function(result) {
      if (!!result.backlog_of_orders) {
        InviteUserPage.popupWarningHasBacklogOfOrders(result, projectId, userId, 'backlog_of_orders', element, project_list)
      } else if (result.undelivered_efforts_and_not_assigned_orders) {
        InviteUserPage.undeliveredEffortsAndNotAssignedOrders(result, projectId, userId, 'undelivered_efforts_and_not_assigned_orders', element, project_list)
      } else if (result.show_popup_no_undelivered_efforts) {
        InviteUserPage.popupWarningNoUndeliveryEffort(result, projectId, userId, 'no_undelivered_efforts', element, project_list)
      } else if (result.show_popup_not_assigned_orders) {
        if (project_list){
          InviteUserPage.handleDeleteMemberInProjectList(element, userId)
        }else{
          InviteUserPage.popupWarningNotAssignedOrders(result, projectId, userId, 'not_assigned_orders')
        }
      }
      else if (result.only_send_mail) {
        if (project_list){
          InviteUserPage.handleDeleteMemberInProjectList(element, userId)
        }else{
          Popup.confirm(`${result.user_name}さんをプロジェクトから削除します。<br>よろしいですか？`, {
            success: () => {
              InviteUserPage.handleDeleteMemberInProject(projectId, userId, 'only_send_mail')
            },
            cancel: () => {},
            confirmText: '削除する',
            cancelText: 'キャンセル',
            width: 'auto',
          })
        }
      }
    }).fail(function(result) {

    })
  }

  deleteRequest(request_id, projectID, name) {
    Popup.confirm(`${name}さんをプロジェクトから削除します。<br>よろしいですか？`, {
      success: () => {
        const data = { request_id: request_id }
        Shared.callAjax(Routes.destroy_request_permission_owner_project_path(projectID), data, 'json', 'delete')
        .done(function(result) {
          Popup.confirm(`${result.user_name}さんを招待解除しました。`, {
            cancelBtn: 'false',
            confirmText: 'OK',
            width: 'auto',
            success: () => { window.location.reload(); }
          })
        })
      },
      cancel: () => {},
      confirmText: '削除する',
      cancelText: 'キャンセル',
      width: 'auto',
    })
  }

  submitSettingAll() {
    $('.preloader').fadeIn();
    $('#submit-update-all').click();
  }

  handleCheckSettingProjectSuperAll(element) {
    var checked = $(element).find('#permission_project_supper').is(':checked');
    var parent = $(element).closest('.autoryity_table_modal');
    var task_checked = $('.all-check-task').is(':checked');
    if(checked) {
      Popup.confirm('<div>Project内の全タスクに自動的に招待されます。<br> チェックしてよろしいですか？</div>', {
        success: () => {
          if(!task_checked) parent.find('.all-check-task').prop("checked", true).trigger('change');
          parent.find('.all-check-task').parent().addClass('disabled')
        },
        cancel: () => {
          parent.find('#permission_project_supper').prop("checked", false);
          parent.find('.all-check-task').parent().removeClass('disabled')
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto',
      })
    } else {
      Popup.confirm('<div>メイン管理者以外のメンバーに <br>『Project』権限がなくなります。<br> チェックを外してよろしいですか？</div>', {
        success: () => { parent.find('.all-check-task').parent().removeClass('disabled') },
        cancel: () => {
          parent.find('#permission_project_supper').prop("checked", true);
          parent.find('.all-check-task').parent().addClass('disabled')
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto',
      })
    }
  }

  tryToDeletePermission(permission_type) {
    if(permission_type == 'main_admin') {
      $("#tryToRemoveMainAdmin").modal("show")
    } else {
      $("#tryToRemoveMember").modal("show")
    }
  }

  validateEmailBeforeInvite(modal_id) {
    let isSubmit = true
    let email = $(modal_id).find("#permission_email").val();
    let name = $(modal_id).find("#permission_name").val();
    let type = $(modal_id).find("#add_new_email_permission_type").val();

    if(email == "") {
      isSubmit = false;
      $(modal_id).find(".invite_email_error").html("空白にはできません")
    } else {
      if(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email)) {
        $(modal_id).find(".invite_email_error").html("")
      } else {
        isSubmit = false;
      $(modal_id).find(".invite_email_error").html(I18n.t('admin.attributes.email.invalid'))
      }
    }

    if($(modal_id).find("#add_new_email_permission_type")) {
      if(type == "") {
        isSubmit = false;
        $(modal_id).find(".invite_type_error").html("空白にはできません")
      } else {
        $(modal_id).find(".invite_type_error").html("")
      }
    }

    if($(modal_id).find("#permission_name").length > 0) {
      if(name == "") {
        isSubmit = false;
        $(modal_id).find(".invite_name_error").html("空白にはできません")
      } else {
        $(modal_id).find(".invite_name_error").html("")
      }
      if (Shared.checkValidationUserName(name)) {
        Popup.alert('「ご担当者名」に利用できない文字があります。<br>ご担当者名を変更してください。')
        isSubmit = false;
      }
    }

    if(isSubmit) {
      $('.preloader').fadeIn();
      $(modal_id).find(".button-submit").click()
    }
  }

  handleChangeValueSelect(element) {
    const type = $(element).val();
    $("#addAuthority").find("#permission_type").val(type);
    if(type == "owner") {
      $("#addAuthority").find(".modal-title").html("自社メンバー新規招待")
      $(element).val($(element).find('option:first').val());
      $("#addAuthority").modal('show');
    } else if(type == "client") {
      $("#addAuthority").find(".modal-title").html("販売先メンバー新規招待")
      $(element).val($(element).find('option:first').val());
      $("#addAuthority").modal('show');
    } else if(type == "supplier") {
      $("#addAuthority").find(".modal-title").html("仕入先メンバー新規招待")
      $(element).val($(element).find('option:first').val());
      $("#addAuthority").modal('show');
    } else if(type == "collaborator") {
      $("#addAuthority").find(".modal-title").html("外部メンバー新規招待")
      $(element).val($(element).find('option:first').val());
      $("#addAuthority").modal('show');
    } else {
      if(($(element)).attr("id").includes("js-select_users_invites")) this._enableButton($(element))
    }
    $("#addAuthority").find("#permission_email").val('');
    $("#addAuthority").find(".type-authority-box").addClass("d-none");
  }

  handleCheckProject(element) {
    if($(element).is(":checked")) {
      $(element).closest(".show_permission_tr").find(".task-checkbox").prop("checked", true)
      $(element).closest(".show_permission_tr").find(".task-checkbox").parent().addClass("task-disabled")
    } else {
      $(element).closest(".show_permission_tr").find(".task-checkbox").parent().removeClass("task-disabled")
    }
  }

  _enableButton(element) {
    $("#submit_invite_user_to_project").removeClass("disabled")
    $("#addButton").removeClass("disabled")
    element.closest(".invite_row").find(".member_trash").removeClass("disabled")
  }

  static popupWarningHasBacklogOfOrders(data, projectID, userID, removeType, element, project_list) {
    let orderList = ''
    data.backlog_of_orders.forEach(function(order, index) {
      let path = order.assign_project ? data.project_assign_orders_path : data.freely_orders_path
      orderList += `・<a href="${path}" target="_blank" class="white-text custom-underline text-underline line-height-1-6 font-weight-bold">注文ID：${order.id}　${order.name}の注文</a><br>`
    })
    let textDetail = `※プロジェクトと紐づいている書類は削除されません。書類管理から削除できます。
      ※解除後、${data.company_name}を招待するとチケット作業以外のデータ共有が可能です。
    `
    let textWithType = `また、このプロジェクトで提出済（未納品）作業は削除されます。
      解除すると、未納品作業は<a target="_blank" class="font-weight-bold text-underline" href="${Routes.deleted_works_efforts_path()}">削除済一覧ページ</a>に移動します。
      ${textDetail}
    `
    if (!data.is_client) {
      textWithType = `また、このプロジェクトで提出済（未納品）作業は削除されます。
        <a href="${Routes.undelivered_works_efforts_path({search_kind: 'project_id_cont', q: { kind_id: projectID, created_by_eq: userID }})}" target="_blank" class="font-weight-bold">未納品作業一覧ページ</a>より未納品作業をご確認の上、メンバー解除を行ってください。
        メンバー解除後、10日間は<a href="${Routes.deleted_works_efforts_path()}" target="_blank" class="font-weight-bold">削除一覧ページ</a>より未納品作業のStatus変更が可能です。
        10日を超過すると未納品作業のStatus変更は出来ません。<br>
        ${textDetail}
      `
    }
    Popup.confirm(
      `<div class='text-center font-weight-normal white-text'><p class="white-text">${data.user_name}さんを招待解除すると、未納品分の下記注文が停止され<br>このプロジェクトの紐づけが解除されます。</p><p class="white-text">${orderList}
      ※既に納品分の注文（数量）は、このプロジェクトで計上されます。
      </p>${textWithType}</div>`,
      {
        success: () => {
          project_list ? InviteUserPage.handleDeleteMemberInProjectList(element, userID) : InviteUserPage.handleDeleteMemberInProject(projectID, userID, removeType)
        },
        cancel: () => {
          if (project_list) {
            $(element).prop('checked', true);
            $(".list-user-uncheck").val($(".list-user-uncheck").val().replace(`${userID},`, ''));
          }
        },
        confirmText: '解除する',
        cancelText: '後で行う',
        width: 'auto'
      }
    )
  }

  static undeliveredEffortsAndNotAssignedOrders(data, projectID, userID, removeType, element, project_list) {
    let message =  `<div class='text-center font-weight-normal white-text'><p class="white-text">${data.user_name}を招待解除すると、未納品作業が削除され<br>このプロジェクトの紐づけが解除されます。</p><a href="${Routes.undelivered_works_efforts_path({search_kind: 'project_id_cont', q: { kind_id: projectID, created_by_eq: data.is_client ? null : userID }})}" target='_blank' class="text-underline font-weight-bold">未納品作業一覧ページ</a>より未納品作業をご確認の上、メンバー解除を行ってください。
      メンバー解除後、10日間は<a href="${Routes.deleted_works_efforts_path()}" target='_blank' class="text-underline font-weight-bold">削除一覧ページ</a>より未納品作業のStatus変更が可能です。
      10日を超過すると未納品作業のStatus変更は出来ません。

      ※プロジェクトと紐づいている書類は削除されません。書類管理から削除できます。
    </div>`
    if (data.is_client) {
      message = `<div class='text-center font-weight-normal white-text'>${data.company_name}の未納品作業が存在します。
        紐づけを解除すると提出済（未納品）作業は削除されます。
        解除すると、未納品作業は<a href="${Routes.deleted_works_efforts_path()}" target='_blank' class="text-underline font-weight-bold">削除済一覧ページ</a>に移動します。
      </div>`
    }
    Popup.confirm(
      message,
      {
        success: () => {
          project_list ? InviteUserPage.handleDeleteMemberInProjectList(element, userID) : InviteUserPage.handleDeleteMemberInProject(projectID, userID, removeType)
        },
        cancel: () => {
          if (project_list) {
            $(element).prop('checked', true);
            $(".list-user-uncheck").val($(".list-user-uncheck").val().replace(`${userID},`, ''));
          }
        },
        confirmText: '解除する',
        cancelText: '後で行う',
        width: 'auto'
      }
    )
  }

  static popupWarningNoUndeliveryEffort(data, projectID, userID, removeType, element, project_list) {
    Popup.confirm(
      `${data.user_name}さんを招待解除すると、
      このプロジェクトの紐づけが解除され
      ${data.company_name}の注文履歴が削除されます。`,
      {
        success: () => {
          project_list ? InviteUserPage.handleDeleteMemberInProjectList(element, userID) : InviteUserPage.handleDeleteMemberInProject(projectID, userID, removeType)
        },
        cancel: () => {
          if (project_list) {
            $(element).prop('checked', true);
            $(".list-user-uncheck").val($(".list-user-uncheck").val().replace(`${userID},`, ''));
          }
        },
        confirmText: '解除する',
        cancelText: '後で行う',
        width: 'auto'
      }
    )
  }

  static popupWarningNotAssignedOrders(data, projectID, userID, removeType) {
    Popup.confirm(
      `${data.user_name}さんを招待解除すると、
      このプロジェクトに参加できなくなります。
      解除してよろしいですか？`,
      {
        success: () => {
          InviteUserPage.handleDeleteMemberInProject(projectID, userID, removeType)
        },
        cancel: () => {},
        confirmText: '解除する',
        cancelText: '後で行う',
        width: 'auto'
      }
    )
  }

  static handleDeleteMemberInProject(projectID, userID, removeType) {
    const data = { remove_type: removeType }
    Shared.callAjax(Routes.owner_destroy_user_permission_path(projectID, userID), data, 'json', 'delete')
    .done(function(result) {
      Popup.confirm(`${result.user_name}さんを招待解除しました。`, {
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto',
        success: () => { window.location.reload(); }
      })
    })
  }


  static modalListTickets(projectID, userID) {
    Shared.callAjax(Routes.get_tickets_owner_project_path(projectID), {user_id: userID}, 'html', 'get').done(function(data) {
      Popup.confirm('', {
        description: data,
        iconHtml: '<i class="fas fa-info"></i>',
        confirmText: 'OK',
        width: '35em',
        cancelBtn: 'false',
        success: () => {}
      })
    })
    .fail(function(data) {
      Popup.alert(
        I18n.t('destroy_user_permission.errors.has_orders'), 'warning', '', { popupCustomClass: 'w-auto' }
      )
    })
  }

  static modalDeleteUser(projectID, userID, userName) {
    Popup.confirm('', {
      description: `${userName} ${I18n.t('delete_user')}`,
      confirmText: '削除する',
      cancelText: 'キャンセル',
      width: '35em',
      success: () => {
        Shared.callAjax(Routes.owner_destroy_user_permission_path(projectID, userID), {}, 'html', 'delete').done(function(result) {
          window.location.href = result
        })
      }
    })
  }

  static handleDeleteMemberInProjectList(element, userID){
    if($(element).is(":checked")) {
      const modalEle = $(element).parents('#popupInviteMember')
      const listMemberCheck = modalEle.find('input.check-box-check-member:checkbox:checked').toArray()
      let companyClientChecked = [];
      listMemberCheck.forEach(input => {
        if ($(input).val() === 'client' && $(input).is(':checked') && $(input).attr('data-invited-by-owner') === 'true') {
          companyClientChecked.push($(input).data('company-id'))
        }
      })
      let uniqueArray = [...new Set(companyClientChecked)];
      if (uniqueArray.length > 1) {
        Popup.alert('複数の販売先が招待できません。')
        $(element).prop('checked', false)
        return
      }
      $(element).parent().parent().parent().find('.label-checkbox').removeClass("disabled")
    } else {
      $(element).parent().parent().parent().find('.label-checkbox').addClass('disabled')
    }

    const dataUserDelete = $(".list-user-delete");
    if(dataUserDelete.length > 0) {
      if($(element).is(":checked")) {
        $(".list-user-delete").val(dataUserDelete.val().replace(`${userID},`, ''));
      } else {
        $(".list-user-delete").val(`${dataUserDelete.val()}${userID},`)
      }
    }
  }

  popupConfirmUpdatePermission(element, projectId) {
    this.clickUpdate = true;
    let values = $('form#form_update_project_permission').serialize();
    const url = Routes.owner_save_user_permirssion_path(projectId)
    Shared.callAjax(url, values, 'json').done(function(result) {
      $(element).parents('.modal').modal('hide')
      Popup.confirm('権限を更新しました。', {
        confirmText: 'OK',
        cancelBtn: true,
        success: () => {
          location.href = Routes.owner_invite_users_path(projectId);
        },
        cancel: () => {
          $('#submit_update_project_permissions').addClass('disabled');
          $('#user_permission_checkbox_change_ids').val('[]');
        }
      })
    })
    .fail(function(data) {
      this.clickUpdate = false
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  closeCreateProjectSuccessSPPopup(element_id) {
    $(element_id).modal('hide');
    const currentUrl = window.location.href
    window.history.pushState( '', '', currentUrl.replace('?after_update_permission=true', ''));
  }

  _handleAuthorizeProjectAll(element) {
    const allCheckBox = $('input[type="checkbox"]').toArray();
    const listPermissionOfProject = [
      'user_permission[roles[task[create]]]',
      'user_permission[roles[chat[admin_project]]]',
      'user_permission[roles[chat[group_project]]]',
      'user_permission[roles[chat[task]]]',
      'user_permission[roles[chat[ticket]]]',
      'user_permission[roles[chat[akaire_file]]]'
    ]
    if ($(element).is(':checked') && $(element).attr('name') === "user_permission[roles[project[super]]]") {
      allCheckBox.forEach(checkBox => {
        if (!listPermissionOfProject.includes($(checkBox).attr('name'))) return
        $(checkBox).val('true')
        $(checkBox).prop('checked', true);
      })
    } else if (listPermissionOfProject.includes($(element).attr('name')) && !$(element).is(':checked')) {
      $('input[name="user_permission[roles[project[super]]]"]').prop('checked', false).val('false');
    }
  }

  showAboutAuthority() {
    $('#addAuthority').modal('hide');
    $('#defaultAuthority').modal('show');
  }

  changeTextHeader() {
    $('#addAuthority').find('.modal-title').html('新規で招待するメンバー情報 ')
  }

  showModalInvite() {
    $('#addAuthority').find('.modal-title').html('招待するメンバーの ')
    $('#addAuthority').find('#permission_email').val('');
    $('#addAuthority').find('#permission_type').val('owner');
    $('#addAuthority').find('.invite_email_error').html('');
    $('#addAuthority').modal('show');
  }

  openSettingPermissionAll() {
    $('#modalAutorityTableAll').modal('show')
  }
}
