import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';


export default class ModalFirstUploadAkaire extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  OpenModalUploadFirstAkaireFunction = () => {
    const {OpenModalUploadFirstAkaire} = this.props
    OpenModalUploadFirstAkaire()
  }

  settingMemberFunction = (event) => {
    const {settingMember} = this.props
    settingMember(event)
  }

  render() {
    const { isOpen } = this.props;
    return (
      <div className="show-modal-upload-first-akaire">
        <Modal
          isOpen={isOpen}
          size="xl"
          style={{maxWidth: '450px'}}
          className="modal-policy-video-youtube modal-dialog-centered"
        >
          <ModalBody style={{paddingTop: '0px'}}>
            <div className="text-center font-weight-bold mt-4" style={{fontSize: '18px'}}>
              <p class="mb-0">アップロードが完了しました。</p>
              <p class="mb-4">この赤入れに他のメンバーを招待しますか？</p>
              <Button
                className="align-items-center"
                color="danger"
                onClick={(event) => this.settingMemberFunction(event)}
              >
                <span className="">招待する</span>
              </Button>
              <Button
                className="ml-3"
                onClick={(event) => this.OpenModalUploadFirstAkaireFunction()}
              >
                <span className="">後にする</span>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};
