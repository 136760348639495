import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import reactWindowSize from 'react-window-size';

class NotifyModal extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowNotifyModal: true,
      startDay: '2020/03/02',
      endDay: '2020/03/07'
    }
  }
  toggle = bool => name =>
    this.setState({ [`shouldShow${name}`]: bool })
  open = this.toggle(true)
  close = this.toggle(false)
  renderNotify() {
    const { shouldShowNotifyModal } = this.state;
    return (
      <Modal className="registration-modal modal-lg" isOpen={shouldShowNotifyModal}>
        <ModalHeader >
          アカポンからのお知らせ
        </ModalHeader>
        <ModalBody>
          <div className="box not-registration">
            サーバーメンテナンスのお知らせ <br />
            【メンテナンス日時】<br />
            2020年03月07日（土）19時～23時頃<br />
            の間、サーバーのメンテナンス実施の為、アカポンのご利用が出来ません。<br />
            お客様にはご不便をお掛けし誠に申し訳ございません。<br />

            ※作業の状況により終了時間が前後することがございますのでご了承下さい。<br />
          </div>
          <div className="text-center mb-3">
            <Button color="primary" size="lg" onClick={this.close.bind(this, 'NotifyModal')}>OK<br /></Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };
  render() {
    const { startDay, endDay } = this.state;
    const dayShow = new Date(startDay);
    const dayHint = new Date(endDay);
    const currentDate = new Date();
    if (dayShow <= currentDate && currentDate <= dayHint) {
      return this.renderNotify();
    }
    return ""
  }
}

export default reactWindowSize(NotifyModal);
