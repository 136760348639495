export default class PasswordReset {
  resetPassword(event){
    event.preventDefault()
    const email =  $('#email').val();
    const url = Routes.owner_password_resets_path();
    const me = this
    Shared.callAjax(url,{
      email: email
    }).done(function(data){
      if(data.status){
        me._popupLink(email, 'resetPassword');
      }else{
        if($('#password_resets').hasClass('show')) $('#password_resets').modal('hide');
        Popup.confirm(`<span class="mb-2">入力されたメールアドレス宛に<br>パスワード変更URLをお送りしました</span>`, {
          confirmText: '再送信',
          cancelBtn: 'false',
          classPopup: 'reset-pw-popup',
          classBtnPopup: 'send-to-email-btn',
          success: () => {
            $('#password_resets').find("button.forgot-btn").click();
          }
        })
      }
    }).fail(function(data){
      Popup.alert(`このアカウントは存在しません。<br>正しいメールアドレスを入力してください。<br>新規会員登録は<a href="${Routes.owner_signup_path()}">こちら</a>`)
      $('.new_alert_popup').removeClass('reset-pw-popup');
    })
  }

  resendPassword(email) {
    let url;
    if (window.location.href.includes('akapon_signup')) {
      url = Routes.owner_akapon_resend_confirm_email_path()
    } else {
      url = Routes.owner_post_resend_mail_path();
    }
    Shared.callAjax(url,{
      email: email
    }).done(function(data){
      Popup.close();
      // Popup.alert('本登録の通知を再送しました。');
    }).fail(function(data){
    })
  }

  _popupLink(email, type = 'comfirmEmail') {
    let text = '';
    if (type === 'resetPassword') {
      text = `本登録が完了しておりません。<br>本登録完了後よりパスワード変更が可能になります。<br><br>本登録の通知を再送する場合は<a type='button' aria-label='' class='text-underline cursor-pointer text-white' herf='#' onclick='PasswordReset.resendPassword("${email}")'>こちら。</a>`;
    } else {
      text = `<p class='p-4 text-white'>現在、${email}は仮登録中です。<br>本登録の通知より、本登録を進めてください。<br><br>本登録の通知を再送する場合は<a type='button' aria-label='' class='text-underline cursor-pointer text-white' herf='#' onclick='PasswordReset.resendPassword("${email}")'>こちら。</a></p>`;
    }
  
    Popup.confirm(text, {
      cancelBtn: 'false',
      successBtn: 'false',
      width: 'fit-content',
    })
  }
}
