import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { canUserNotPermission } from '../authorizations';

import AnimationMarkerForm from './AnimationMarkerForm';
import AnimationMarkersInPlayer from './AnimationMarkersInPlayer';
import AnimationMarkerPointsDisplay from './AnimationMarkerPointsDisplay';
import AnimationMarkersToggler from './AnimationMarkersToggler';
import MarkShape from './MarkShape';
import {getStyleOfSize} from "../util/getStyleofSize"
import { styles } from "../util/styleDrawComponent";
import PolyLineSvg from "./PolyLineSvg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { fetchHeightHeader } from '../shared';
import CommentDetail from './CommentDetail';

const showSVGIndex = 1000;
const hideSVGIndex = -1;


const markersVisibilities = ['all', 'circle', 'none'];

export default class AnimationImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      isMarking: false,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      currentWidth: 0,
      currentHeight: 0,
      currentShape: 'point',
      shouldShowForm: false,
      markersVisibility: 'all',
      temp: false,
      changeCss: false,
      canvasWidth: 0,
      canvasHeight: 0,
      zIndexSvg: hideSVGIndex,
      listItemSvg: [],
      currentItemSvg: null,
      settingDraw: {
        color: 'black',
        lineWidth: 3
      },
      canvasPositionImgs: [],
      currentCanvasWidth: 0,
      currentCanvasHeight: 0,
      isShowModalNotCompareVersion: false,
      timestamp: new Date().getTime(),
    };
    // this.overlayComponent = this.overlay()
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount() {
    this.reset();
    // window.addEventListener("resize", this.update);
  }

  componentWillMount() {
    Shared.loadingIndicator()
  }

  update = () => {
    let width = document.getElementById("animation-image").offsetWidth;
    let height = document.getElementById("animation-image").offsetHeight;
    if (width !== this.state.canvasWidth){
      this.setState({
        canvasWidth: width,
        canvasHeight: height,
        listItemSvg: []
      }, () => {
        this.ctx = this.canvas.getContext("2d");
        this.ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
        this.ctx.drawImage(
          this.img,
          0,
          0,
          this.state.canvasWidth,
          this.state.canvasHeight
        );
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      settingDraw: newProps.settingDraw,
    });
  }

  componentDidUpdate(newProp) {
    if (newProp.changeCss != this.props.changeCss) {
      this.setState({
        changeCss: newProp.changeCss
      })
    }

  }

  mouseRelativeXY(event) {
    return this.relativeXY(event.clientX, event.clientY);
  }
  mouseRelativeXYDraw(event) {
    return this.relativeXY(
      event.nativeEvent.clientX,
      event.nativeEvent.clientY
    );
  }
  touchRelativeXY(event) {
    return this.relativeXY(event.touches[0].clientX, event.touches[0].clientY);
  }
  relativeXY(eventX, eventY) {
    const playerRect = findDOMNode(this.image).getBoundingClientRect();
    return [
      (eventX - playerRect.left) / playerRect.width * 10000,
      (eventY - playerRect.top) / playerRect.height * 10000,
    ];
  }
  startMark = ({ x, y }) => {
    this.setState({
      isMarking: true,
      shouldShowForm: false,
      startX: x,
      startY: y,
      currentX: x,
      currentY: y,
      currentWidth: 0,
      currentHeight: 0,
      currentShape: 'point'
    });
  }
  moveMark = ({ x, y }) => {
    const { isMarking, startX, startY } = this.state;
    if (!isMarking) return;
    const { max, abs } = Math;
    const distance = max(abs(x - startX), abs(y - startY));
    distance > 10 && startX < x && startY < y
      ? this.setState({
        currentWidth: max(x - startX, 0),
        currentHeight: max(y - startY, 0),
        currentShape: 'rect',
      })
      : this.setState({
        currentWidth: 0,
        currentHeight: 0,
        currentShape: 'point',
      })
  }
  endMark = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { currentAnimationId, animationSelectCurrent, toggleShowModalInputInfoOfNotLogin, isInputInfoMationOfNotLogin }= this.props;
    if (currentAnimationId === animationSelectCurrent) {
      if (gon.is_login || isInputInfoMationOfNotLogin) {
        this.setState({
          shouldShowForm: true,
          isMarking: false,
        });
      } else {
        this.setState({ isMarking: false, });
        toggleShowModalInputInfoOfNotLogin();
      }
      this.disableMmount();
    }
  }
  disableMmount(e = '') {
    var menus = document.querySelectorAll('.show-mark-shape');
    menus = [].slice.call(menus);
    menus.forEach(function (element) {
      element.style.pointerEvents = e;
    });
  }
  onSubmitMarkerForm = (body, files, editorSetting) => {
    const { onSubmitMarkerForm } = this.props;
    const { currentX: x, currentY: y, currentWidth: width, currentHeight: height, currentShape: shape, currentTime: time } = this.state;
    return onSubmitMarkerForm({ body, x, y, width, height, shape, time, files, editorSetting }).then(() => this.setState({ shouldShowForm: false }));
  }
  onCloseMarkerForm = () => {
    this.setState({
      shouldShowForm: false,
    }, () => {
      this.ctx = this.canvas.getContext("2d");
      this.ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
      this.ctx.drawImage(
        this.img,
        0,
        0,
        this.state.canvasWidth,
        this.state.canvasHeight
      );
    })
  }
  onImgLoad({ target: img }) {
    $('.preloader').fadeOut();
    const { getHeightAnimaiton } = this.props;
    this.setState({
      canvasHeight: img.offsetHeight,
      canvasWidth: img.offsetWidth,
      currentCanvasWidth: img.offsetWidth,
      currentCanvasHeight: img.offsetHeight
    });
    getHeightAnimaiton(img.offsetHeight);
  }
  draw(e) {
    this.setState({
      isDel: false,
      shouldShowForm: false,
    });
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    context.drawImage(
      this.img,
      0,
      0,
      this.state.currentCanvasWidth,
      this.state.currentCanvasHeight,
    );
    this.setState({
      zIndexSvg: showSVGIndex,
    });
  }

  eraser() {
    this.setState({
      isDel: true,
    });
  }

  capture(e) {
    const fileDrawName = `draw-image-${moment.now()}`;
    const files = [];
    var svgURL = new XMLSerializer().serializeToString(this.svg);
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    var img = new Image();
    img.onload = () => {
      context.drawImage(
        img,
        0,
        0,
        this.state.canvasWidth,
        this.state.canvasHeight
      );
      var imageSrc = this.canvas.toDataURL();
      const { listItemSvg} = this.state;
      if (listItemSvg.length === 0) {
        return;
      } else {
       const {listItemSvg} = this.state
       const arr = listItemSvg[0].capturePoint.split(",")
       const convertArr = arr[1].trim().split(' ')
       const markPointCaptureY = Number(convertArr[0])
       const markPointCaptureX = Number(convertArr[1])
       this.setState({
         currentX: markPointCaptureX,
         currentY: markPointCaptureY
       })
      }
      this.urltoFile(imageSrc, fileDrawName, "image/png").then((file) => {
        files.push(file);
        this.setState(
          {
            currentShape: "draw",
            currentTime: 0,
            shouldShowForm: true,
          },
          () => {
            this.makerForm.onFileChange(null, files);
          }
        );
      });
    };
    img.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgURL);
  }

  urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  deleteAllDraw() {
    this.setState({
      isDel: false,
      listItemSvg: [],
      shouldShowForm: false,
      zIndexSvg: hideSVGIndex,
    });
  }

  reset(isDelete) {
    if(!isDelete){
      this.setState({
        isDraw: false,
        isDel: false,
        zIndexSvg: hideSVGIndex,
        listItemSvg: [],
        shouldShowForm: false,
      });
    } else {
      this.setState({
        isDraw: false,
        isDel: false,
        shouldShowForm: false,
      });
    }
    this.ctx = this.canvas.getContext("2d");
    this.ctx.fillStyle = "transparent";
    this.ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
    this.ctx.drawImage(
      this.img,
      0,
      0,
      this.state.canvasWidth,
      this.state.canvasHeight
    );
  }

  disableScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  enableScroll() {
    window.onscroll = function() {};
  }

  onMouseDown(event, isMobile) {
    const { mobile } = this.props;
    if (!this.props.changeCss) {
      if (mobile || platform.product === "iPad") {
        document.getElementById("animation-preview").style["overflow"] ="hidden";
        document.body.style.overflow = "hidden";
      } else {
        // document.getElementById("animation-preview").style["overflow"] ="auto";
        document.body.style.overflow = "auto";
      }
      if (!this.state.isDraw && !this.state.isDel) {
        this.setState({
          isDraw: true,
        });
        var newItem = {
          id: Date.now(),
          points: "",
          color: this.state.settingDraw.color,
          lineWidth: this.state.settingDraw.lineWidth,
          capturePoint: ""
        };
        if (!isMobile){
          var [x, y] = this.mouseRelativeXYDraw(event);
          newItem.capturePoint = ` , ${y} ${x}`
        } else {
          var [x, y] = this.touchRelativeXY(event);
          newItem.capturePoint = ` , ${y} ${x}`
          this.disableScroll();
        }
        this.setState({
          listItemSvg: [...this.state.listItemSvg, newItem],
          currentItemSvg: newItem,
        });
      }
    }
  }

  onMouseMove = (event, isMobile) => {
    if (!this.props.changeCss) {
      if (this.state.isDraw) {
        let heightTopToAnimation = fetchHeightHeader();
        var item = document.getElementById(this.state.currentItemSvg.id);
        var points = item.getAttribute("points");
        var capturePoint = item.getAttribute("capturePoint");
        if (!isMobile) {
          let pointsX = event.nativeEvent.layerX;
          let pointsy = event.nativeEvent.layerY
          pointsX = (pointsX * this.state.currentCanvasWidth / this.img.offsetWidth).toFixed()
          pointsy = (pointsy *  this.state.currentCanvasHeight / this.img.offsetHeight).toFixed()
          points += ` ${pointsX}, ${pointsy}`;
          var [x, y] = this.mouseRelativeXYDraw(event);
          capturePoint += ` ${x}, ${y}`;
        } else {
          var [x, y] = this.touchRelativeXY(event);
          capturePoint += ` ${x}, ${y}`;
          points += ` ${
            event.touches[0].pageX +
            document.getElementById("animation-preview").scrollLeft -
            document.getElementById("animation-preview").offsetLeft
          }, ${
            event.touches[0].pageY +
            document.getElementById("animation-preview").scrollTop -
            document.getElementById("animation-preview").offsetTop - heightTopToAnimation
          }`;
        }
        let itemInList = this.state.listItemSvg.find(
          (obj) => obj.id === this.state.currentItemSvg.id
        );
        if (itemInList) {
          itemInList.points = points;
          itemInList.capturePoint = capturePoint;
        }
        item.setAttribute("points", points);
        item.setAttribute("capturePoint", capturePoint);
      }
    }
  };

  onMouseUp() {
    document.body.style.overflow = "auto";
    if (this.props.changeCss) {
      document.getElementById("animation-preview").style["overflow"] ="auto";
    }
    if (this.state.isDraw) {
      this.setState({
        isDraw: false,
      });
      this.enableScroll();
    }
  }

  onItemMouseOver(event) {
    const { mobile } = this.props;
    const polyWidht = event.target.getBBox().width;
    const polyHeight = event.target.getBBox().height;
    if (mobile ){
      return
    } else if (platform.product === 'iPad'){
      return
    } else {
      if (!this.state.isDraw && this.state.isDel) {
        event.target.setAttribute("stroke-dasharray", "5 5");
        if (polyWidht <= 35 && polyHeight <= 35) {
          let id = event.target.getAttribute("id");
          let newLists = this.state.listItemSvg.filter((obj) => obj.id != id);
          this.setState({
            listItemSvg: newLists,
          });
        }
      }
    }
  }

  onItemMouseLeave(event) {
    if (!this.state.isDraw && this.state.isDel) {
      event.target.setAttribute("stroke-dasharray", "");
    }
  }

  onDelete(event) {
    if (!this.state.isDraw && this.state.isDel) {
      let id = event.target.getAttribute("id");
      let newLists = this.state.listItemSvg.filter((obj) => obj.id != id);
      this.setState({
        listItemSvg: newLists,
      });
    }
  }
  setOption = () => {
    const { currentAnimationId, animationSelectCurrent } = this.props;
    if (currentAnimationId !== animationSelectCurrent) {
      this.fetchCurrentAnimation()
    }
  }

  handleOutsideClick = (e) => {
    if (!this.nodeModalVersion.contains(e.target)) {
      this.setOption();
    }
  };

  fetchCurrentAnimation = () => {
    const { setCurrentAnimation } = this.props;
    this.setState({ isShowModalNotCompareVersion: false }, () => setCurrentAnimation())
  }

  scrollImage = (image, {x, y}) => {
    // scroll in image
    const { isMarking, startX, startY } = this.state;
    const { max, abs } = Math;
    if (startY >= y || y - startY <= 100 || !isMarking ) return
    let debounceTimer;
    let playerRect = findDOMNode(image).getBoundingClientRect();
    let maxHeightImage = 10000;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(function() {
      const element = document.getElementById("wrap-center");
      const scrollSpeed = 3; // speed sroll
      // x, y
      const mouseX = max(x - startX, 0) - element.offsetLeft;
      const mouseY = max(y - startY, 0) - element.offsetTop;
      // calculator
      const distanceFromTop = mouseY;
      const distanceFromBottom = element.offsetHeight - mouseY;
      const distanceFromLeft = mouseX;
      const distanceFromRight = element.offsetWidth - mouseX;
      let scrollX = 0;
      let scrollY = 0;
      let newY = (y * playerRect.height / 10000) + playerRect.top;
      var windowHeight = window.innerHeight;
      var scrollThreshold = (3 / 4) * windowHeight;
      if (distanceFromTop < scrollSpeed) {
        scrollY = -1;
      } else if (newY >= (scrollThreshold)) {
        scrollY = 1;
      }
      if (distanceFromLeft < scrollSpeed) {
        scrollX = -1;
      } else if (distanceFromRight < scrollSpeed) {
        scrollX = 1;
      }
      element.scrollBy(scrollX * scrollSpeed, scrollY * scrollSpeed);
    }, 100);
  }

  render() {
    const { src, downloadUrl, animationMarkerCreationPath, markers, markerCreatable, onClickDownloadButton, mobile,
      onMarkerUpdationCalled, updateMakerShow, currentUserId, role, owner, changeCss, user, scaleImage,
      animationSelectCurrent, currentAnimationId, onMarkerDeletionCalled, reloadMarkers, apiClient,
      currentAnimation, deleteReplyMarker, avatarUrl} = this.props;
    const { currentX, currentY, currentWidth, currentHeight, currentShape, shouldShowForm, markersVisibility,
            isMarking, temp, isShowModalNotCompareVersion, timestamp
          } = this.state;
    const encodeName = src.split('/').pop()
    const decodeName = decodeURI(decodeURI(encodeName));
    let src2 = `${src.replace(encodeName, decodeName)}?v=${timestamp}`
    var preview = <img src={src} alt='logo' />;
    return (
      <div
        id="wrap-center"
        style={{minHeight:"94%"}}
        className={`wrap-animation-preview ${mobile ? 'wrap-animation-preview-image-for-mobile' : ''} ${getStyleOfSize(scaleImage,"wrap")}`}>
        <div
          className={`animation-preview ${getStyleOfSize(scaleImage)}` }
          id="animation-preview"
          style={{width:`${scaleImage}%`}}
          ref={(ref) => (this.wrap = ref)}
        >
          <div
            className={classnames('animation-image', { marking: isMarking })}
            id="animation-image"
          >
            <div className='project__image' ref={ref => this.image = ref} id="project__image">
              <img
                crossOrigin="anonymous" onLoad={this.onImgLoad} src={src2}
                alt='logo' id="img" ref={(ref) => (this.img = ref)}
              />
            </div>
            <canvas
                id="canvas"
                ref={(ref) => (this.canvas = ref)}
                width={`${this.state.canvasWidth}px`}
                height={`${this.state.canvasHeight}px`}
                style={Object.assign({}, styles.canvas, {zIndex: this.state.zIndexSvg})}
              ></canvas>
              {/* {
                scaleImage !== 100 &&
                <div style={Object.assign({}, styles.canvas, {
                  zIndex: this.state.zIndexSvg + 1,
                })}>
              </div>
              } */}
              <div
                ref={(ref) => (this.drawSvg = ref)}
                className="draw-svg"
                style={{
                  zIndex: this.state.zIndexSvg,
                }}
                onMouseDown={(event) => this.onMouseDown(event, false)}
                onMouseMove={(event) => this.onMouseMove(event, false)}
                onMouseUp={() => this.onMouseUp()}
                onMouseLeave={() => this.onMouseUp()}
                onTouchStart={(event) => this.onMouseDown(event, true)}
                onTouchEnd={() => this.onMouseUp()}
                onTouchMove={(event) => this.onMouseMove(event, true)}
              >
                <svg
                  preserveAspectRatio="none"
                  viewBox={`0 0 ${this.state.canvasWidth} ${this.state.canvasHeight}`}
                  className={this.state.isDel ? 'cursor_eraser': `cursor_pencil_${this.state.settingDraw.color}`}
                  width={`${this.state.canvasWidth}px`}
                  height={`${this.state.canvasHeight}px`}
                  ref={(ref) => (this.svg = ref)}
                  style={Object.assign(
                    {},
                    styles.canvas,
                    { zIndex: this.state.zIndexSvg },
                  )}
                >
                  {this.state.listItemSvg.map((item, i) => (
                    <PolyLineSvg
                      id={item.id}
                      points={item.points}
                      color={item.color}
                      lineWidth={item.lineWidth}
                      onItemMouseOver={(event) => this.onItemMouseOver(event)}
                      onItemMouseLeave={(event) => this.onItemMouseLeave(event)}
                      onDelete={(event) => this.onDelete(event)}
                    />
                  ))}
                </svg>
              </div>
            {
              !canUserNotPermission(role) && markerCreatable || !gon.is_login ? ( <div
                className={changeCss ? 'overlay-fix' : 'overlay akapon'}
                onMouseDown={(event) => {
                  this.disableMmount('none');
                  event.stopPropagation();
                  event.preventDefault();
                  const [x, y] = this.mouseRelativeXY(event);
                  this.startMark({ x, y });
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.setOption();
                  const [x, y] = this.touchRelativeXY(event);
                  this.startMark({ x, y });
                }}
                onMouseMove={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  const [x, y] = this.mouseRelativeXY(event);
                  this.moveMark({ x, y });
                  this.scrollImage(this.image, {x, y});
                }}
                onTouchMove={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  const [x, y] = this.touchRelativeXY(event);
                  this.moveMark({ x, y });
                }}
                onMouseUp={this.endMark}
                onTouchEnd={this.endMark}
                onClick={this.setOption}
              >
              </div>) : ( <div />)
            }
            {
              isMarking && <MarkShape x={currentX} y={currentY} width={currentWidth} height={currentHeight} shape={currentShape} />
            }
            <AnimationMarkersInPlayer
              markers={markers} visibility={markersVisibility} order={11}
              onMarkerUpdationCalled={onMarkerUpdationCalled} image={this.image}
              updateMakerShow={updateMakerShow}
            />
            {
              shouldShowForm && (
                <AnimationMarkerForm
                  x={currentX} y={currentY} width={currentWidth} height={currentHeight}
                  shape={currentShape} onSubmit={this.onSubmitMarkerForm}
                  onClickClose={this.onCloseMarkerForm} order={12} mobile={mobile}
                  shouldShowForm={shouldShowForm} currentUserId={currentUserId} role={role} owner={owner} user={user}
                  scaleImage={scaleImage}
                  ref={(ref) => (this.makerForm = ref)}
                  is_embed_video={false}
                  is_show_draw_component={true}
                />
              )
            }
            
          </div>
        </div>
        <CommentDetail
          selectedMarker={this.props.selectedMarker}
          updateSelectedMarker={this.props.updateSelectedMarker}
          onMarkerUpdationCalled={onMarkerUpdationCalled}
          currentUserId={currentUserId}
          owner={owner}
          role={role}
          animationSelectCurrent={animationSelectCurrent}
          currentAnimationId={currentAnimationId}
          onMarkerDeletionCalled={onMarkerDeletionCalled}
          reloadMarkers={reloadMarkers}
          apiClient={apiClient}
          markers={markers}
          deleteReplyMarker={deleteReplyMarker}
          avatarUrl={avatarUrl}
          shouldShowForm={shouldShowForm}
        />
        <div ref={nodeModalVersion => { this.nodeModalVersion = nodeModalVersion }}>
          <Modal isOpen={isShowModalNotCompareVersion}
               className="animation-uploader-modal modal-dialog-centered modal-not-compare-version "
               size="md" >
            <ModalBody>
              <div className='text-center text-black'>
                <div className='font-size-of-modal-not-compare font-weight-bold text-black text-center'>
                  <span>メディア画面とコメントツールの </span>
                  <span>バージョンが異なる為、赤入れできません。</span>
                </div>
                <Button className="btn btn-danger my-2 button-set-current-animation" onClick={this.fetchCurrentAnimation}>
                  表示中のメディア画面を赤入れする
                </Button>
                <span class="text-of-current-title">
                  ※クリックするとコメントツールのバージョンが{currentAnimation.final ? '最終稿' : currentAnimation.title}に変わります。
                </span>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    )
  }
}
