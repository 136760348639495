import React, { Component } from 'react';
import { Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import { canUpdateProject } from '../authorizations';

export default class ProjectFormModal extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      description: '',
      isSubmitting: false,
    }
  }
  componentDidUpdate(prevProps, _) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setValues();
    }
  }
  setValues() {
    const { project: { name, description } } = this.props;
    this.setState({ name, description: description || '' });
  }
  clear() {
    this.setState({
      name: '',
      description: '',
    });
  }
  onChangeName = ({ target: { value } }) => {
    this.setState({ name: value });
  }
  onChangeDescription = ({ target: { value } }) => {
    this.setState({ description: value });
  }
  onSubmit = (event) => {
    const { onSubmit } = this.props;
    const { name, description } = this.state;
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    onSubmit(name, description).then(() => {
      this.setState({
        isSubmitting: false,
      });
      this.clear();
    });
  }
  render() {
    const { role, currentUserId, isOpen, onClickClose, nameOnly = false } = this.props;
    const { name, isSubmitting, description } = this.state;
    const isUnsubmittable = isSubmitting || name.length === 0
    return (
      <Modal isOpen={isOpen} className="project-form-modal">
        <ModalHeader>
          プロジェクトの編集
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <Input className="mb-3" type="text" name="name" value={name} placeholder="プロジェクト名" disabled={!canUpdateProject(role) || isSubmitting} onChange={this.onChangeName} />
            {
              !nameOnly && (
                <Input className="mb-3" type="textarea" name="description" value={description} placeholder="概要" disabled={!canUpdateProject(role) || isSubmitting} rows={4} onChange={this.onChangeDescription} />
              )
            }
            <Button type="submit" color="primary" disabled={!canUpdateProject(role) || isUnsubmittable}>保存する</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={onClickClose}>閉じる</Button>
        </ModalFooter>
      </Modal>
    );
  }
};
