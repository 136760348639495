import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UploadAkaire from 'images/upload-img.png'

export default class ListUrlErrorsUpload extends Component {
  constructor(props) {
    super(props);
    const { baseURL } = props;
    this.state = {
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { resetUrlErrors } = this.props;
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      resetUrlErrors()
      this.props.onClickClose();
    }
  };

  closeModal = () => {
    const { resetUrlErrors, onClickClose } = this.props;
    resetUrlErrors()
    onClickClose()
  }

  render() {
    const { isOpen, onClickClose, type, is_mobile, urls } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.closeModal} ref={node => (this.modalRef = node)} className="modal-dialog-centered modal-style additional-akaire">
        <ModalBody>
          <div className='justify-content-center'>
            <div className='text-left'>
              <span className='additional-akaire-title '>
                下記理由の為、{urls.length}件が除外されました。
              </span>
            </div>
          </div>
          <div className="align-center-div mt-3 show-list-file-error">
            <div className='show-list-table-list-files'>
              <div className='d-flex text-center justify-content-center align-items-center mt-2 mx-3 border-1px-black'>
                <div className='w-100'>
                  <div className='show-table-files'>
                    <table className='w-100 table table-bordered mb-1'>
                      <thead className='bg-black w-100 text-white sticky-top'>
                        <tr>
                          <th>URL</th>
                          <th width="30%">エラー理由</th>
                        </tr>
                      </thead>
                      {
                        <tbody className=''>
                          {(
                            urls.map((item, index) => (
                              <tr key={index}>
                                <td className='text-left mr-1' style={{padding: '1px', paddingLeft: '3px'}}>
                                  {item}
                                </td>
                                <td className='text-left ml-1' style={{padding: '1px', paddingLeft: '3px'}}>
                                  {item === '' ? '空白行あり' : '正しくない形式'}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      }
                    </table>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          <div className="additional-akaire-button text-center">
            <Button type="button" color="black" className="btn-primary" onClick={this.closeModal} style={{ height: 'auto' }}>
              <img className="create-akaire-box-icon-upload"
                          src={UploadAkaire} alt="アップロード" width="30" />
              再度キャプチャする
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
