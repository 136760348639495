import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import mov from '../ui/icons/MOV.svg';
import mp4 from '../ui/icons/MP4.svg';
import wmv from '../ui/icons/WMV.svg';
import avi from '../ui/icons/AVI.svg';
import { version } from '../util/animation';
import { canUserNotPermission, canSwitchVersion } from '../authorizations';
import apiClient from '../util/api-client';

export default class ModalDownloadVideo extends Component {
  constructor(props) {
    super(props);
    const { currentPageId, currentAnimationId, animations, pages, baseURL } = props;
    this.state = {
      selectPageId: currentPageId,
      selectVersion: currentAnimationId,
      animationsRender: animations,
      pagesRender: pages,
      disabledChangeVersion: false,
    };
    this.apiClient = apiClient({ baseURL: baseURL });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pages !== this.props.pages || nextProps.animations !== this.props.animations) {
      this.setState({ pagesRender: nextProps.pages, animationsRender: nextProps.animations })
    }
  }

  changeTypeDownLoad = (event, type) => {
    const {changeTypeDownLoad} = this.props;
    changeTypeDownLoad(event, type);
  }

  onChangePage = async (event) => {
    const newPageId = event.target.value
    const { fetchListAnimationsDownload } = this.props;
    this.setState({ selectPageId: newPageId });
    const newAnimations = await this.apiClient.get(`pages/${newPageId}/animations`);
    const lastAnimations = newAnimations.data[newAnimations.data.length - 1]
    this.setState({ animationsRender: newAnimations.data, selectVersion: lastAnimations.id });
    fetchListAnimationsDownload(newPageId)
  }

  onChangeVersion = (event) => {
    const {animationsRender} = this.state;
    const newAnimation = this.fetchNewAnimation(event.target.value)
    if (newAnimation.is_video_embed) {
      Popup.alert('YouTubeデータはダウンロードできません。')
      return
    }
    const versionId = event.target.value
    this.setState({ selectVersion: versionId });
  }

  downloadVideo = () => {
    const { downloadVideo } = this.props;
    const selectDownloadAnimationId = this.state.selectVersion || this.props.currentAnimationId
    const newAnimation = this.fetchNewAnimation(selectDownloadAnimationId)
    if (newAnimation.is_video_embed) {
      Popup.alert('YouTubeデータはダウンロードできません。')
      return
    }
    downloadVideo(selectDownloadAnimationId)
  }

  fetchNewAnimation = (animationId) => {
    const { animationsRender } = this.state;
    return animationsRender.find((animation) => animation.id === Number(animationId))
  }

  render() {
    const { isOpen, selectTypeDownload, role } = this.props;
    const { selectPageId, selectVersion, pagesRender, animationsRender} = this.state;
    const newAnimation = this.fetchNewAnimation(selectVersion)
    const versionDownload = newAnimation?.is_video_embed ? null : selectVersion
    return (
      <Modal isOpen={isOpen} className="animation-uploader-modal modal-dialog-centered modal-download-animation modal-style" size="md">
        <ModalBody>
          <div className='justify-content-center text-center'>
            <span className='font-weight-bold text-black title-modal'>Downloadする形式を選択してください。</span><br/>
          </div>
          <div className='justify-content-center text-center d-flex'>
            <div className='w-30 show-select-akaire-page-and-version'>
              {pagesRender &&
                <Input type="select" id="select-current-page-animation"
                      onChange={(event) =>this.onChangePage(event)} value={selectPageId} disabled={canUserNotPermission(role)}>
                  {
                    pagesRender.map((page, i) => {
                      return (
                        <option value={page.id} key={i}>
                          {page.title}
                        </option>
                      );
                    })
                  }
                </Input>
              }
              {animationsRender &&
                <Input type="select" id="animation-selector-comemnt" className="text-right w-30" value={versionDownload || null}
                      onChange={(event) => this.onChangeVersion(event)} disabled={!canSwitchVersion(role, animationsRender)}>
                  {
                    animationsRender.map((animation, i) => {
                      return (
                        <option value={animation.id} data-is_video_embed={animation.is_video_embed} key={i}>
                          {version(animation)}
                        </option>
                      );
                    })
                  }
                </Input>
              }
            </div>
          </div>
          <div className='d-flex mb-2 mt-2 align-items-center justify-content-center'>
            <div className='bg-wild-sand d-block pd-10px mr-3 list-downloads-type'>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'mp4')}>
                <img className="img" width={28} height={28} src={mp4} alt="mp4" />
                <span className='font-size-20px font-weight-bold ml-2'>MP4</span>
              </div>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'mov')}>
                <img className="jpg" width={28} height={28} src={mov} alt="mov" />
                <span className='font-size-20px font-weight-bold ml-2'>MOV</span>
              </div>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'wmv')}>
                <img className="png" width={28} height={28} src={wmv} alt="wmv" />
                <span className='font-size-20px font-weight-bold ml-2'>WMV</span>
              </div>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'avi')}>
                <img className="png" width={28} height={28} src={avi} alt="avi" />
                <span className='font-size-20px font-weight-bold ml-2'>AVI</span>
              </div>
            </div>
            {
              selectTypeDownload === '' ? (
                <span className="ml-5 font-size-16px text-black font-weight-bold">形式を選択</span>
              ) : (
                <span className="ml-5 font-size-16px text-red font-weight-bold">
                  {selectTypeDownload.toUpperCase()}形式
                </span>
              )
            }
          </div>
          <div className="justify-content-center text-center mt-2">
            <Button type="button" color="primary" disabled={selectTypeDownload === ''} onClick={this.downloadVideo}
                    className="text-white">ダウンロードする</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
