export const styles = {
  canvas: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  buttonSquare: {
    border: '0px',
    height: '45px',
    minWidth: '40px',
    borderRadius: '3px',
    background: 'transparent'
  },
  buttonSquareDraw: {
    height: '55px',
    minWidth: '40px',
    borderRadius: '50%',
    backgroundColor: '#0778FF',
    width: '55px',
    boxShadow: '1px 2px 1px #00000029',
    border: '2px solid #FFFFFF'
  },
  buttonSquareSetting: {
    height: '55px',
    minWidth: '40px',
    borderRadius: '50%',
    backgroundColor: '#ff0e20',
    width: '55px',
    boxShadow: '1px 2px 1px #00000029',
    border: '2px solid #FFFFFF'
  },
  buttonSquareEraser: {
    height: '55px',
    minWidth: '40px',
    borderRadius: '50%',
    backgroundColor: '#E5900C',
    width: '55px',
    boxShadow: '1px 2px 1px #00000029',
    border: '2px solid #FFFFFF'
  },
  buttonSquareCapture: {
    height: '55px',
    minWidth: '40px',
    borderRadius: '50%',
    backgroundColor: '#24A806',
    width: '55px',
    boxShadow: '1px 2px 1px #00000029',
    border: '2px solid #FFFFFF'
  },
  button: {
    border: '0px',
    margin: '1px 4px',
    width: '24px',
    height: '24px',
    verticalAlign: 'top',
    borderRadius: '50%',
    margin: 'auto'
  },
  colorSwatches: {
    red: { 'backgroundColor': 'red' },
    orange: { 'backgroundColor': 'orange' },
    yellow: { 'backgroundColor': 'yellow' },
    green: { 'backgroundColor': 'green' },
    blue: { 'backgroundColor': 'blue' },
    purple: { 'backgroundColor': 'purple' },
    black: { 'backgroundColor': 'black' },
    white: { 'backgroundColor': 'white' },
    lightgrey: { 'backgroundColor': 'lightgrey'},
    darkgray: {'backgroundColor': 'darkgray'},
    gray: { 'backgroundColor': 'gray' },
    dimgrey: {'backgroundColor': 'dimgrey'},
    mediumvioletred: {'backgroundColor': 'mediumvioletred'},
    orangered: { 'backgroundColor': 'orangered' },
    gold: { 'backgroundColor': 'gold' },
    greenyellow: { 'backgroundColor': 'greenyellow' },
    limegreen: { 'backgroundColor': 'limegreen' },
    cadetblue: { 'backgroundColor': 'cadetblue' },
    midnightblue: { 'backgroundColor': 'midnightblue' },
    rebeccapurple: { 'backgroundColor': 'rebeccapurple' },
    antiquewhite: { 'backgroundColor': 'antiquewhite' },
    peru: { 'backgroundColor': 'peru' },
    sienna: { 'backgroundColor': 'sienna' },
    saddlebrown: { 'backgroundColor': 'saddlebrown' },
    hotpink: { 'backgroundColor': 'hotpink' },
    peachpuff: { 'backgroundColor': 'peachpuff' },
    lightyellow: { 'backgroundColor': 'lightyellow' },
    lightgreen: { 'backgroundColor': 'lightgreen' },
    skyblue: { 'backgroundColor': 'skyblue' },
    mediumslateblue: { 'backgroundColor': 'mediumslateblue' },
  }
}
