import { showTagUser } from '../event_message'

const MIN_DISTANCE_TOP = 70
export default class Chat {
  hiddenModal() {
    $('.modal.show').modal('hide');
  }

  toggleChatUsers(element) {
    let elementPopupUser = $(element).parents('form.new_message').find('.chat-users')
    elementPopupUser.toggleClass('d-none')
  }

  warmingOffChat(type) {
    if(type == "NewProject") {
      Popup.alert(`プロジェクト通知設定OFFの為<br>トーク権限がありません。`)
    } else if (type == "Task") {
      let type = gon.is_plan_akapon ? '赤入れ' : 'タスク'
      Popup.alert(`${type}通知設定OFFの為<br>トーク権限がありません。`)
    } else {
      Popup.alert(`ファイル通知設定OFFの為<br>トーク権限がありません。`)
    }
  }

  warmingSendChatIfOff(type) {
    if(type == "NewProject") {
      $('#warmingOffChatModal').find('.warming-content').html('プロジェクト通知設定OFFの為<br>トーク権限がありません。')
    } else if (type == "Task") {
      let type = gon.is_plan_akapon ? '赤入れ' : 'タスク'
      $('#warmingOffChatModal').find('.warming-content').html(`${type}通知設定OFFの為<br>トーク権限がありません。`)
    } else {
      $('#warmingOffChatModal').find('.warming-content').html('ファイル通知設定OFFの為<br>トーク権限がありません。')
    }
    Shared.showModalForElement('#warmingOffChatModal')
  }

  closeChatUsers(element) {
    Chat._clearUserSelected($(element))
  }

  selectUser(element) {
    let userId = $(element).find('p').attr('user_id')
    let userName = $(element).find('p').text()
    let elementSelectedUser = $(element).parents('.chat-users').find('.js-list-user-selected')
    let select = $(element).parents('form.new_message').find('.js-select-users')
    Chat._addUser(elementSelectedUser, userId, userName)
    Visiable.hide(element)
    $(element).addClass('selected')
    select.find(`option[value=${userId}]`).attr('selected', true)
  }

  searchUser(event) {
    let element = event.target
    let value = $(element).val()
    let select = $(element).parents('form.new_message').find('.js-select-users')

    if (event.keyCode === 8 && element.hasAttribute('remove_selected')) {
      let spanLast = $(element).parent().find('.js-list-user-selected span:last-child')
      let userId = spanLast.attr('user_id')
      select.find(`option[value='${userId}']`).attr('selected', false)
      Chat._removeUser($(element).parents('.chat-users').find(`p[user_id='${userId}']`).parent())
      spanLast.remove()
    }

    if (value) {
      $(element).removeAttr('remove_selected')
      $(element).parents('.chat-users').find('.js-chat-user').each ( (index, e) => {
        let userName = $(e).children('p').text()
        if ((userName.search(value) === -1 || $(e).hasClass('selected')))
          Visiable.hide(e)
        else
          Visiable.show(e)
      })
    } else {
      $(element).attr('remove_selected', true)
      $(element).parents('.chat-users').find('.js-chat-user').each ( (index, e) => {
        if (!$(e).hasClass('selected'))
          Visiable.show(e)
      })
    }
  }

  aprovalUsers(element) {
    let elementPopupUser = $(element).parents('.chat-users')
    let form = $(element).parents('form.new_message')
    Chat.showUserSelected(form)
    Chat._clearUserSelected(elementPopupUser)
  }

  appendToInContentChat(form) {
    const contentEle = form.find('textarea.message_content')
    const listUsers = form.find('.show-text-users').find('.show-text-user-name').toArray();
    if (listUsers.length === 0) return
    this._handleAddToFormContentChat(listUsers, contentEle)
    this._handleAppendUserToChat(form)
  }

  _handleAddToFormContentChat(listUsers, contentEle) {
    const honorifics = "さん\n"
    const contentChat = contentEle.val();
    let newText = ''
    listUsers.forEach(user => {
      newText += `[To:${$(user).data('user-id')}]${$(user).text()}${honorifics}`
    })
    const newTextChat = this.handleContentChat(contentChat, 'To', newText)
    contentEle.val(newTextChat)
  }

  _handleAppendUserToChat(form) {
    form.find('.show-text-users').find('.show-text-user-name').remove()
    form.find('.view_box_lists').find('li').remove();
    form.find('.show-list-user-chat-view').addClass('d-none')
  }

  static showUserSelected(form) {
    const listUser = form.find('select').children("option").filter(":selected");
    if (listUser.length === 0) return
    const showUsersTo = form.find('.show-list-users-to')
    const showUsersToView = form.find('.show-list-user-chat-view')
    showUsersToView.removeClass('d-none')
    $.each(listUser, function(index) {
      if (showUsersTo.find('.show-text-users').find(`span[data-user-id="${$(this).val()}"]`).length === 0) {
        showUsersToView.find('.view_box_lists').append(`<li class="dropdown-item" onclick="Chat.removeUserChat(this, '${$(this).val()}')">${$(this).text()}</li>`)
        showUsersTo.find('.show-text-users').append(`<span class="show-text-user-name" data-user-id="${$(this).val()}">${$(this).text()},  </span>`)
      }
    })
  }

  static showHideResultMessage(chatComponent, isShow = true) {
    if (isShow) {
      chatComponent.find('.body-chat-message').addClass('d-none')
      chatComponent.find('.result-search-chat').empty()
    } else {
      chatComponent.find('.body-chat-message').removeClass('d-none')
      chatComponent.find('.result-search-chat').remove()
    }
  }

  toggleResultMessage(element, isShow = true) {
    const chatComponent = $(element).parents('.chat-outer');
    Chat.showHideResultMessage(chatComponent, isShow)
    $(element).parents('.chat-outer').find('.chat_search_text').val('')
    this.checkListTicketChanged(element)
  }

  checkListTicketChanged(ele) {
    const componentChat = $(ele).parents('.chat-newstyle')
    const workId = componentChat.find(`#js-tab_chat-popup`).attr('data-channel-id')
    const isSend = componentChat.find('button.send-message').attr('is-send') === 'true'
    if (componentChat.hasClass('chat-type-Work') && isSend) {
      const tbodyWork = $(`tbody[data-work-id="${workId}"]`)
      tbodyWork.find(`input[name="work[description][${workId}]"]`).trigger('change')
    }
  }

  static _handleMessageAfterSearch(el, chatComponent, number_unread, searchTxt) {
    let resultSearch = el.closest('.chat-search').find('.result');
    el.find('.result_search').removeClass('result_search')
    if(searchTxt){
      let chatDetails = chatComponent.find('.result-search-chat').find('.chat-details')
      chatDetails.each(function(i){
        let contentArea = $(this).find('.base.content');
        let txtOriginal = contentArea.html();
        let txtReplace = txtOriginal.replace(searchTxt, `<span class="result_search" style="background: rgba(255, 193, 7, .6)"> ${searchTxt} </span>`)
        contentArea.html(txtReplace)
      })
      // display number of result
      resultSearch.find('.num-mess-search').text(number_unread)
      resultSearch.fadeIn()
      window.setTimeout(function(){
        resultSearch.fadeOut()
      }, 5000);
    }
  }

  search(element, msg_source_id, msg_source_type, group){
    const el = $(element);
    const searchTxt = el.closest('.chat-search').find('.chat_search_text').val();
    if (searchTxt === '') {
      $('img.icon_chat_close').trigger('click');
      this._reopenChat(msg_source_type, msg_source_id);
      return
    }
    const chatComponent = el.closest('.chat-outer');
    const data = {
      msg_source_id: msg_source_id, msg_source_type: msg_source_type,
      group: group, search_text: searchTxt
    }
    Shared.callAjax(Routes.search_message_messages_path(), data).done(function(data) {
      Chat._appendResultSearch(chatComponent)
      Chat.showHideResultMessage(chatComponent)
      chatComponent.find('.result-search-chat').append(data.message_html)
      Chat._handleMessageAfterSearch(el, chatComponent, data.messages_size, searchTxt)
    }).fail(function() {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _reopenChat(msg_source_type, msg_source_id) {
    switch(msg_source_type) {
      case 'NewProject':
        document.getElementById(`js_show_popup_chat_project_${msg_source_id}`).click()
        break;
      case 'Task':
        document.getElementById(`js_icon_chat_task_${msg_source_id}`).click()
        break;
      case 'Work':
        document.getElementById(`js_chat_ticket_${msg_source_id}`).click()
        break;
      case 'AkaireFile':
        document.getElementById(`js_chat_akaire_file_${msg_source_id}`).click()
        break;
      case 'Room':
        document.getElementById(`js_show_popup_chat_company_${$('input#message_receiver_id').val()}`).click()
        break;
      case 'User':
          document.getElementById(`js_show_popup_chat_user_${msg_source_id}`).click()
          break;
    }
  }

  checkViewUser(){
    const showChat = $('.chat-outer.show')
    const userOuter = showChat.find('.group-user-chat')
    const userOuterWidth = userOuter.innerWidth()
    const userList = userOuter.find('.list-user-chat')
    const userListOuter = userList.find('.user_list_outer')
    const userMore = userOuter.find('.drop-list')
    const userMoreList = userMore.find('.overflow_list_user_name_chat')
    let member, addList

    // const userInnerWidth = userList.width() + userMore.width();
    const userInnerWidth = userList.width() + 81;
    if (window.innerWidth < gon.shared.break_point_for_mobile && userListOuter.text().split('、').length >= 2) {
      member = userListOuter.text()
      if(member.length > 0){
        const index = member.lastIndexOf('、');
        if(index > 0){
          addList = member.substr(index+1)
        }else{
          addList = member
        }
        userListOuter.text(member.substr(0, index))
        userMoreList.prepend(`<div class="dropdown-item">${addList}</div>`)
        if(userMore.find('.view-more').hasClass('d-none')){
          userMore.find('.view-more').removeClass('d-none')
        }
      }
    }
    if((userOuterWidth - userInnerWidth) > 100){
      const moreLength = userMoreList.find('.dropdown-item').length

      if(moreLength >= 1){
        const backList = userMoreList.find('.dropdown-item:first').text()
        member = userListOuter.text()
        if(member.length > 0){
          userListOuter.text(`${member}、${backList}`)
        }else{
          userListOuter.text(backList)
        }
        if(moreLength == 1){
          userMore.find('.view-more').addClass('d-none')
          userMoreList.removeClass('show')
        }
        userMoreList.find('.dropdown-item:first').remove()
      }
    }
  }

  handleContentChat(contentChat, action, newText) {
    if (action === 'quote') {
      const arrayString = contentChat.split('')
      const isEndBreakLine = arrayString[arrayString.length - 1] === '\n'
      if (contentChat.trim() === '' || isEndBreakLine) return `${contentChat}` + newText
      return `${contentChat}\n` + newText
    }
    if (contentChat.trim() === '') return newText
    let textAction = ''
    let newContentChat = ''
    action === 'reply' ? textAction = '[返信:' : textAction = '[To:'
    let indexLastAction = contentChat.lastIndexOf(textAction)
    if (indexLastAction <= 0) {
      if (action === 'reply') {
        return newText + contentChat.trim()
      } else {
        textAction = '[返信:'
        indexLastAction = contentChat.lastIndexOf(textAction)
        if (indexLastAction < 0) {
          return newText + contentChat.trim()
        } else if (indexLastAction === 0 && contentChat.includes(textAction)) {
          indexLastAction = contentChat.indexOf(']') + 1
          let firstContentChat = contentChat.slice(0, indexLastAction)
          let lastContentChat = contentChat.slice(indexLastAction, contentChat.length)
          newContentChat += firstContentChat
          newContentChat += newText
          newContentChat += lastContentChat
        } else {
          newContentChat = Chat._handleChatContent(contentChat, indexLastAction, newText, newContentChat)
        }
      }
    } else {
      newContentChat = Chat._handleChatContent(contentChat, indexLastAction, newText, newContentChat)
    }
    return newContentChat;
  }

  removeUserChat(element, userId) {
    if ($(element).attr('not-remove') && $(element).attr('not-remove') === 'true') { return }
    const userChatComponent = $(element).parents('form').find('.show-list-users-to')
    const componentViewBoxList = $(element).parents('.view_box_lists')
    userChatComponent.find(`span[data-user-id="${userId}"]`).remove()
    $(element).remove()
    if (componentViewBoxList.find('li').length === 0) {
      componentViewBoxList.parents('#modalShowListUsersSelected').modal('hide')
      componentViewBoxList.parents('.show-list-user-chat-view').addClass('d-none')
    }
  }

  focusInputSearchMessage(element) {
    if (!$(element).attr('data-focused')) {
      $('.modal.show').modal('hide')
      $(element).attr('data-focused', 'true')
      $(element).focus();
    }
  }

  static _handleChatContent(contentChat, indexLastAction, newText, newContentChat) {
    const firstContentChat = contentChat.slice(0, indexLastAction)
    const lastContentChat = contentChat.slice(indexLastAction, contentChat.length)
    newContentChat += firstContentChat
    newContentChat += newText
    newContentChat += lastContentChat
    return newContentChat
  }

  static _appendResultSearch(chatComponent) {
    $(chatComponent).find('.result-search-chat').remove()
    let showResult = '<div class="chat-messages result-search-chat"></div>'
    $(chatComponent).find('.body-chat-message').after(showResult)
  }

  static _addUser(element, userId, userName) {
    let html = `<span user_id='${userId}'>${userName}</span>`
    $(element).append(html)
  }

  static _clearUserSelected(element) {
    element.parents('form.new_message').find('.js-select-users').find('option').removeAttr('selected')
    element.addClass('d-none').removeAttr('style')
    element.find('.js-list-user-selected').empty()
    Chat._removeUser(element.find('.js-chat-user'))
  }

  static _removeUser(element) {
    element.removeClass('d-none').removeClass('selected')
  }

  static _drag = {}
  initDrag(ele, e){
    Chat._drag.chatAbove = ele.previousElementSibling;
    Chat._drag.startAbove = parseInt(document.defaultView.getComputedStyle(Chat._drag.chatAbove).height, 10);

    Chat._drag.chatBelow = ele.nextElementSibling;
    Chat._drag.startBelow = parseInt(document.defaultView.getComputedStyle(Chat._drag.chatBelow).height, 10);

    Chat._drag.startY = e.clientY;
    document.documentElement.addEventListener('mousemove', Chat._doDrag, false);
    document.documentElement.addEventListener('mouseup', Chat._stopDrag, false);
    if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
      document.documentElement.addEventListener('touchmove', Chat._doDrag, false);
      document.documentElement.addEventListener('touchend', Chat._stopDrag, false);
    }
  }

  static _doDrag(event) {
    let touch = event;
    let movedY;
    if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
      event.preventDefault();
      touch = event.touches[0];
      movedY = touch.pageY - Chat._drag.startY
    } else {
      movedY = touch.clientY - Chat._drag.startY
    }
    let newAboveHeight = Chat._drag.startAbove + movedY;
    let newBelowHeight = Chat._drag.startBelow - movedY;
    if(newAboveHeight > 0 && newBelowHeight > 110){
      Chat._drag.chatAbove.style.height = `${newAboveHeight}px`;
      Chat._drag.chatBelow.style.height = `${newBelowHeight}px`;
      Chat._drag.chatAbove.style.overflowY = 'hidden';
    }
  }

  static _stopDrag(e) {
      document.documentElement.removeEventListener('mousemove', Chat._doDrag, false);
      document.documentElement.removeEventListener('mouseup', Chat._stopDrag, false);
      if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
        document.documentElement.removeEventListener('touchmove', Chat._doDrag, false);
        document.documentElement.removeEventListener('touchend', Chat._stopDrag, false);
      }
      Chat._drag.chatAbove.style.overflowY = 'scroll';
  }

  static _stopScroll(e){
    e.preventDefault();
  }

}
