$(document).ready(function() {
  $('body').on('click', '#show-popup-setting-otp-off', function() {
    const message = "<div class='title'>二段階認証設定</div><hr class='hr'><div class='d-flex justify-content-center'><div class='content'>「二段階認証設定」は、アカポン及びCreate Cloudへのログイン時に <br>ご登録のメールアドレス宛てに認証コードを送付し、二段階認証を行うセキュリティ機能です。<br><br><div class='text-center font-weight-bold'>現在、二段階認証は 設定 の状態です。</div></div></div></div>";
    Popup.confirm(message, {
      confirmText: '解除する',
      cancelBtn: false,
      classPopup: 'popup-seting-token',
      success: () => { 
        Shared.callAjax(Routes.owner_turn_off_2fa_path(), {}, 'json')
        .done(function(data){
          Popup.alert("<div class='title'>二段階認証設定</div><hr class='hr'><div class='text-center'>ただ今、ご登録のメールアドレス宛に認証コードを送信しました。</div>")
        })
      },
    })
  })
  
  $('body').on('click', '#show-popup-setting-otp-on', function() {
    const message = "<div class='title'>二段階認証設定</div><hr class='hr'><div class='d-flex justify-content-center'><div class='content'>「二段階認証設定」は、アカポン及びCreate Cloudへのログイン時に <br>ご登録のメールアドレス宛てに認証コードを送付し、二段階認証を行うセキュリティ機能です。<br><br><div class='text-center font-weight-bold'>現在、二段階認証は OFF の状態です。</div></div></div>";
    Popup.confirm(message, {
      confirmText: '設定する',
      cancelBtn: false,
      classPopup: 'popup-seting-token',
      success: () => { 
        Shared.callAjax(Routes.owner_send_mail_setting_token_path(), { num_of_auth: 'first' }, 'json')
        .done(function(data){
          Popup.alert("<div class='title'>二段階認証設定</div><hr class='hr'><div class='text-center'>ただ今、ご登録のメールアドレス宛に認証コードを送信しました。</div>")
        })
      },
    })
  })
})
