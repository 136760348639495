export function detectIpad() {
  if (navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPadPro/i)
    || navigator.userAgent.match(/iPadAir/i)
    || platform.product === 'iPadPro'
    || platform.product === 'iPadAir'
  ) {
    return true;
  }
  return false;
}
