import CompanySelection from './company_selection'
import SelectCC from '../select_cc'
export default class WorkCopy {
  copy(ele, workId, projectId) {
    const trCopy =  $(ele).parents('tr')
    const url = Routes.owner_project_ticket_copy_ticket_path(projectId, workId)
    const taskId = this._fetchTaskId();

    Shared.callAjax(url, {}).done(function(response) {
      window.location.href = Routes.owner_log_work_task_path(projectId, taskId) + `?is_create_new_ticket=true&show_popup_copy_ticket=true&name=${response.name}`;
    })
    .fail(function(response) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _fetchTaskId() {
    return $('#get_data_url').attr('task_id');
  }

  _modify(tbodyCopy) {
    const selectStatusParent = $('.show_body_table').data('select-status-parent');
    const selectStatusChild = $('.show_body_table').data('select-status-child');
    const currentCompany = $('div.vip').data('current-company');
    const companySender = tbodyCopy.find('.company_received_order').val()
    const index = new Date().getTime();
    tbodyCopy.find('tr.invoice__table--new').remove();
    const trParent = tbodyCopy.find('tr:first');
    const quotationSender = trParent.find('.quotation_sender');

    tbodyCopy.attr({ 'index': index , 'current_company': currentCompany });
    trParent.find('input.invoice__table--checkbox').attr({'index': index, 'id': `invoice__table_${index}`});
    trParent.find('label.label_company_infor').attr('for', `invoice__table_${index}`);
    trParent.find('div.show_work_history_change').remove();
    trParent.find('div.show_quotation > a.open-popup-chat').remove();
    tbodyCopy.find('tr.invoice__table--new > input.work_parent_id').val(index);
    tbodyCopy.find('tr.work_child_clone').attr('parent_id', index);
    trParent.find('.invoice__table--checkbox').prop('checked', false);
    if (quotationSender) {
      trParent.find('.select_quotation_sender')
              .attr('onclick', 'logWorkAction.showModalSelectQuotation(event, "sender")')
    }
    trParent.find('.select_quotation_received')
            .attr('onclick', 'logWorkAction.showModalSelectQuotation(event, "received")')

    tbodyCopy.find('.select_status').remove();
    tbodyCopy.find('.show-quantity').text('0');
    tbodyCopy.find('.show_total_quantity').text('0');
    tbodyCopy.find('.work_quantity').val('').trigger('change');
    tbodyCopy.find('.show-work-quantity input').attr('readonly', false);

    tbodyCopy.find('.input_status').val('preparation');
    $(selectStatusParent).insertAfter($(trParent).find('.input_status'));
    tbodyCopy.find('select.company_received_order').val(companySender)
    tbodyCopy.find('.select_status').val('preparation');
    tbodyCopy.find('td.text_status input').val('');

    const copyDescription = tbodyCopy.find('.description_new');
    copyDescription.val(`Copy - ${copyDescription.val()}`);

    this._replaceAttrName(trParent.find('[prefix_name]').toArray(), index);

    this._removeReadonly($(tbodyCopy));
    trParent.find('.select_unit_work').val(trParent.find('.render_unit_work').val())
    trParent.find('span.select2-container').remove();
    trParent.find('select.company_received_order').removeClass('select2-hidden-accessible')
    trParent.find('select.company_received_order').select2();
    trParent.find('input.work_id').val('').attr('readonly', true);
    trParent.find('.before-status').val('preparation');
    tbodyCopy.find('.last-updated-at').text('');
    trParent.find('input.created_at').val('').attr('readonly', true);
    trParent.find('.before-status').val('preparation');
    trParent.find('.input_delivery_date').val('');
    Visiable.show(trParent.find('.delete-work'));
    Disabled.toggle(trParent.find('select.company_received_order'), false);

    trParent.find('.list-work-quantity').find('.work-quantities').remove();
    trParent.find('.list-work-quantity').find('.user-name').val(gon.log_work_feature.current_user.name);
    trParent.find('.total-number-quantity').val('').attr('readonly', true);
    trParent.find('.total-hours').val('').attr('readonly', true);
    trParent.find('.total-minutes').val('').attr('readonly', true);
    trParent.find('.max-quantity').val('');
    trParent.find('.work-quantity-work-id').val(index);
    trParent.find('.person_incharge_id').find('option').removeAttr('disabled');
    trParent.find('.text-show-id-ticket').text('チケットID： ');
    trParent.find('.text-show-description-ticket').text('');
    trParent.find('.work_order_received_id_received').val('');
    trParent.find('.work_order_received_id_sender').val('');
    tbodyCopy.find('.release-date-with-order').val('')
    trParent.parents('tbody').removeClass('bg-light-gray')
    trParent.find('.percentage-complete').remove();
    trParent.find('.expiration_time').text('')
    trParent.find('.value-expiration-date').val('')
    logWorkAction.addRedColorToTbody(trParent);
    logWorkAction.removeClassOfTdTag(trParent);
    if(!gon.shared.is_domain_member) trParent.find('.max-quantity').attr('readonly', true)

    return tbodyCopy;
  }

  _replaceAttrName(array, index) {
    array.forEach(element => {
      const prefixName = $(element).attr('prefix_name');
      if (prefixName) { $(element).attr('name', `${prefixName}[${index}]`) }
    });
  }

  _removeReadonly(element) {
    const inputs = element.find('input').toArray();
    inputs.forEach(input => {
      const prefixName = $(input).attr('prefix_name');
      if (prefixName) Shared.toggleAttribute($(input), 'readonly', false)
    })
  }
}
