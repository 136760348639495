import React, { Component } from 'react';
import reactWindowSize from 'react-window-size';

import apiClient from '../util/api-client';
import { AnimationInspector } from './AnimationInspector';

export default reactWindowSize(class AdminAnimationInspector extends AnimationInspector {
  constructor(props) {
    super({ ...props, baseURL: `/admin/projects/${props.id}` });
  }
});
