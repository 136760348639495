import React, { Component } from 'react';
import { Card, CardBody, Button, Label, Input, Form, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import { positionStyle } from '../util/animation-marker';
import TextEditor from './TextEditor';
import MarkShape from './MarkShape';
import TextEditorWidgetComponent from './TextEditorWidgetComponent';
import { canCreateCommentPublicUser } from '../authorizations';
import { isValidAttachFiles } from '../util/checkValidate';
import iconDeleteFile from "../../../assets/images/icon_x_circle.svg";

export default class AnimationMarkerForm extends Component {
  constructor() {
    super();
    this.state = {
      body: '',
      isSubmitting: false,
      files: [],
      editorSetting: {
        borderColor: gon.is_login ? '#E60C11' : '#000000'
      },
      editor: null,
    };
  }
  onChangeBody = (value) => {
    this.setState({ body: value });
  }
  onAddFiles = (files) => {
    if (!isValidAttachFiles(this.state.files, files)) return;
    this.setState({ files: [...this.state.files, ...files] });
  }
  deleteUploadFile = (fileIndex) => {
    const { files } = this.state;
    const newListFile = [];
    $.each(files, function(i, file) {
      if (i !== fileIndex) {
        newListFile.push(file)
      }
    })
    this.setState({ files: newListFile });
  }
  onFileChange = (event, files) => {
    if (event) {
      const { files } = event.target;

      if (!isValidAttachFiles(this.state.files, files)) {
        event.target.value = null;
        return;
      }

      this.setState({ files: [...this.state.files, ...files], });
      event.target.value = null;
    } else {
      if (files) {
        this.setState({ files: [...this.state.files, ...files], });
      }
    }
  }
  clearForm = (text) => {
    this.setState({
      isSubmitting: false,
      body: text,
      files: [],
    });
  };
  handleSetEditorSetting = (settingData) => {
    this.setState({
      editorSetting: settingData
    })
  };
  is_still_space = (file_size) => {
    const { user: { total_file_size: totalFileSize, total_file_size_on_this_cycle: totalAllCycle, max_storage: maxStorage, total_file_size_limit: totalFileSizeLimit } } = this.props;
    return (file_size + Number(totalAllCycle) <= Number(totalFileSizeLimit) || file_size + Number(totalFileSize) <= Number(maxStorage) || Number(maxStorage) == 0 || file_size == 0)
  }
  async onSubmit(event) {
    const { time, onSubmit } = this.props;
    const { body, files } = this.state;
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const totalFilesSize = files.reduce((sum, file) => sum + file.size, 0)
    if (totalFilesSize >= Math.pow(10, 8)) {
      toastr.error('100MB以上の為、アップロードが出来ません');
      this.clearForm(body);
      return
    }
    const { editorSetting } = this.state
    onSubmit(body, files, editorSetting).then(() => {
      this.clearForm('');
    });
  }

  onClickFileButton = () => {
    this.refs.fileInput.click();
  }
  onClickClose() {
    const { onClickClose } = this.props;
    onClickClose();
  }

  setEditor = (value) => {
    this.setState({ editor: value });
  }
  isIpadOS() {
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad'
  }
  renderForm() {
    const { x, y, width, height, shape, time, mobile: isMobile, currentUserId, role: currentUserRole, owner, scaleImage, onlyBUAForCommentPeriod } = this.props;
    const { body, isSubmitting, files, editorSetting, editor } = this.state;
    const commentBox = true;
    const unsubmittable = isSubmitting || body.length === 0;
    const formStyle = isMobile ? {} : positionStyle({ x, y, width, height, commentBox })
    formStyle.borderColor = editorSetting.borderColor
    return (
      <Form className={`remove-pd ${this.isIpadOS() ? 'marker-form-new-comemnt-ipad' : ''}`} onSubmit={(event) => this.onSubmit(event)}
        style={formStyle}>
        <TextEditorWidgetComponent
          mobile={isMobile}
          settingEditor={this.handleSetEditorSetting}
          closeEditor={this.onClickClose.bind(this)}
          fileUpload={this.onClickFileButton}
          editor={editor}
          onlyBUAForCommentPeriod={onlyBUAForCommentPeriod}
        />
        <TextEditor
          className={'mb-4'}
          files={files}
          onChangeBody={this.onChangeBody}
          onAddFiles={this.onAddFiles}
          disabled={isSubmitting}
          rows={4}
          placeholder={"ここにコメントを入力"}
          setEditor={this.setEditor}
          inputId="animation-marker-body" />
        <div id="btn-submit-comment-box" className='d-flex justify-content-space-between editor-button'>
          <div className='attach_file mt-3 text-ellipsis'>
            <div className="attached-files ml-2 pb-1">
              {
                files.map((file, i) => {
                  return (
                    <div className="attached-file d-flex align-items-center" key={i}>
                      <span className='text-ellipsis max-width-80percent h-100'>{file.name}</span>
                      {/* <img className="ml-1 preview-image" src={file.preview} /> */}
                      {
                        <span className="attatchment-file-coment-akaire cursor-pointer" onClick={() => this.deleteUploadFile(i)}>
                          <img src={iconDeleteFile} alt="アップロード" />
                        </span>
                      }
                    </div>
                  )
                })
              }
            </div>
            ※１ファイル100MB以内・ファイル数は10個以内となります。
          </div>
          <Button type="submit" disabled={unsubmittable} className="submit">保存</Button>
        </div>
        <input type="file" hidden onChange={this.onFileChange} ref="fileInput" multiple="multiple" accept="image/*, .wav, application/pdf" />

      </Form>
    );
  }
  render() {
    const { x, y, width, height, shape, currentUserId, currentUserRole, shouldShowForm } = this.props;
    const { editorSetting } = this.state
    return (
      <div className="animation-marker-form animation-marker">
        <MarkShape x={x} y={y} width={width} height={height} shape={shape} color={editorSetting.borderColor} />
        {
          shouldShowForm && this.renderForm()
        }
      </div>
    )
  }
}
