import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import {Button} from 'reactstrap';
import bytes from 'bytes';
import DrawComponent from "./DrawComponent";
import { canUserNotPermission } from '../authorizations';
import userIcon from '../../../assets/images/user_icon_2.png'
import { detectIpad } from '../detect-device'

export default class ProjectInfoSection extends Component {
  handleSetDraw = (settingData) => {
    this.props.handleSetDraw(settingData);
  };
  handleDraw = () => {
    this.props.onClickDraw();
  };
  handleEraser = () => {
    this.props.onClickEraser();
  };
  handleReset = () => {
    this.props.onClickReset();
  };
  handleCapture = () => {
    this.props.onClickCapture();
  };
  handleEraserAll = () => {
    this.props.onClickEraserAll();
  }
  changeShowDrawComponent= (isShow) => {
    this.props.changeShowDrawComponent(isShow)
  }
  showListMemberAkaireFile = () => {
    $('#click-show-list-member-of-akaire-page-detail').trigger('click')
  }

  render() {
    const { statuses, currentUserId, project: { status, updated_at: updatedAt,
       owner = {}, total_file_size: totalFileSize }, assignments, markers,
       onClickMembers, onClickShareButton, mobile: isMobile, isFreePlan, scaleImage,
       rootUrl, role, category, toggleModeDrawComponent, isShowDrawComponent, showMode, currentAnimation, toggleShowModalInputInfoOfNotLogin, isInputInfoMationOfNotLogin } = this.props;
    const assignmentInnerHtml = <span>{owner && owner.name || '（非ログイン）'}</span>
    // const assignmentInnerHtml = <span>{owner && owner.name || '（非ログイン）'}<span className="tag--member ml-1">{assignments.length}</span></span>
    const red_button = {
      background: '#E60C11',
      color: '#fff',
      wordBreak: 'break-word!important',
      whiteSpace: 'break-spaces',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      right: '5%'
    }
    let info = "l-main u-clearfix"
    if (isFreePlan == false) {
      info = info.concat(' info-project')
    }
    return (
      // <div className="l-container u-bg--gray">
      //   <div className={info}>
      //     <div className="logo">
      //     </div>
      //     <div className="project-info-section" style={ category == 'web' ? {top: -66} : {} }>
      //       {/* <table className="project-info">
      //         <thead>
      //           <tr>
      //             <th>進捗</th>
      //             <th>オーナー</th>
      //             {
      //               !isMobile && (
      //                 <th>チェック状況</th>
      //               )
      //             }
      //             <th>更新日時</th>
      //             <th>総容量</th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           <tr>
      //             <td>{status}</td>
      //             <td>
      //               <a>{assignmentInnerHtml}</a>
      //             </td>
      //             {
      //               !isMobile && (
      //                 <td><span className="font-l">{markers.filter(_ => _.status === 'completed').length}</span>/{markers.length}</td>
      //               )
      //             }
      //             <td>{moment(updatedAt).format("YYYY/MM/DD HH:mm")}</td>
      //             <td>{bytes(Number(totalFileSize))}</td>
      //           </tr>
      //         </tbody>
      //       </table> */
      //       }

      //       {
      //         !isMobile && (
      //           currentUserId ? ''
      //             : ''
      //         )
      //       }
      //     </div>
      //   </div>
      // </div>
      ((currentUserId && !canUserNotPermission(role)) || canUserNotPermission(role)) && !isMobile && !detectIpad() ? (
        <div
          className={`draw-component ${ showMode == 'compare' && 'd-none' }`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <DrawComponent
            toggleModeDrawComponent={toggleModeDrawComponent}
            isShowDrawComponent={isShowDrawComponent}
            scaleImage={scaleImage}
            settingDraw={this.handleSetDraw}
            onClickDraw={this.handleDraw}
            onClickEraser={this.handleEraser}
            onClickReset={this.handleReset}
            onClickCapture={this.handleCapture}
            mobile={true}
            onClickEraserAll={this.handleEraserAll}
            changeShowDrawComponent={this.changeShowDrawComponent}
            currentAnimation={currentAnimation}
            role={role}
            toggleShowModalInputInfoOfNotLogin={toggleShowModalInputInfoOfNotLogin}
            isInputInfoMationOfNotLogin={isInputInfoMationOfNotLogin}
          />
          {
            currentUserId && !canUserNotPermission(role) && role !== 'reader' && (
              <div className='show-member-of-detail-akaire-page mr-3 ml-3' onClick={isShowDrawComponent ? null : this.showListMemberAkaireFile}>
                <img src={userIcon} className='show-icon-list-member-akaire-page-detail' width="35" height="35"></img>
              </div>
            )
          }
        </div>
      ) : ''
    );
  }
};
