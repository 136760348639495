import React, { Component } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import apiClient from "../util/api-client";

export default class ModalShowPopupNotifyReplyToNotLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  toggle = () => {
    this.props.togglePopup()
  }

  changeNotShowModal = (event) => {
    const isChecked = $(event.target).prop('checked')
    const {changeIsShowModalNotLogin} = this.props;
    return apiClient()
      .post(
        `user_update_not_show_modal_reply_not_login`,
        { is_show: isChecked }
      ).then((_) => {
        changeIsShowModalNotLogin(!isChecked)
      })
      .catch((e) => {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      })
  }

  render() {
    const { isOpen } = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.toggle()}
        className="modal-dialog-centered modal-create-comment-not-login w-fit-content m-auto modal-style modal-not-reply-to-not-login"
        modalClassName="modal-second modal-style modal-not-reply-to-not-login"
      >
        <div className='align-center-div'>
          <div className='text-center text-for-header'>
            <h5 className='text-white font-weight-bold text-title'></h5>
          </div>
          <div className='text-center mt-2'>
            <div className='text-left text-white text-show-create-comment-not-login'>
              <span>非ログインメンバーのレビューです。</span><br/>
              <span>TO指定にかかわらず、送信すると</span><br/>
              <span>非ログインメンバーに返信されます。</span><br/>
              <span>招待メンバーに送信する場合は、</span><br/>
              <span>TOから追加してください。</span><br/>
            </div>
          </div>
          <div className='text-center mt-2'>
            <div className='d-flex mt-2 text-white text-right justify-content-end align-items-center'>
              <input className='form-control input-check-not-show' type='checkbox' onClick={(event) => this.changeNotShowModal(event)}></input>
              今後、表示させない
            </div>
            <button className='btn btn-primary mt-2 text-primary hover-primary' type='button' style={{backgroundColor: 'white', color: 'black !important'}} onClick={() => this.toggle()}>OK</button>
          </div>
        </div>
      </Modal>
    )
  }
}
