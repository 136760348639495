export function canUpdateProject(role) {
    return ['owner', 'editor', 'public_visitor'].includes(role);
}

export function canGenerateProjectPublicToken(role) {
    return ['owner', 'editor'].includes(role);
}

export function canRequestAgency(role) {
    return role === 'owner'
}

export function canCreateAnimation(role, animations) {
    return ['owner', 'editor'].includes(role) || (role === 'public_visitor' && animations && animations.length === 0);
}

export function canSwitchVersion(role, animations) {
    return ['owner', 'editor', 'commenter', 'reader'].includes(role) || (role === 'public_visitor' && animations && animations.length === 0);
}

export function canDeleteAnimation(role) {
    // return ['owner'].includes(role);
    return true;
}

export function canUpdateAnimation(role) {
    return ['owner', 'editor'].includes(role);
}

export function canCreateAssignment(role) {
    return ['owner'].includes(role);
};

export function canUpdateAssignment(role, currentUserId, userId) {
    return ['owner'].includes(role) && currentUserId !== userId;
};

export function canDeleteAssignment(role, currentUserId, userId) {
    return ['owner'].includes(role) && currentUserId !== userId;
};

export function canCreateAnimationMarker(role) {
    return ['owner', 'editor', 'commenter', 'public_visitor', 'reader'].includes(role);
}

export function canUpdateAnimationMarker(role) {
    return ['owner', 'editor', 'commenter', 'public_visitor', 'reader'].includes(role);
}

export function canDeleteAnimationMarker(animationMarkerUserId, currentUserId, role) {
    return animationMarkerUserId === currentUserId && ['editor'].includes(role) || role === 'owner';
}

export function canDeleteAnimationMarkerPublicUser(animationMarkerUserId, currentUserId) {
    return animationMarkerUserId === currentUserId || !animationMarkerUserId && !currentUserId;
}

export function canCreateComment(role) {
    return ['owner', 'editor', 'commenter'].includes(role);
}

export function canDeleteComment(commentUserId, currentUserId, role) {
    return commentUserId === currentUserId && ['editor', 'commenter'].includes(role) || role === 'owner'
}

export function canCreateCommentPublicUser(role) {
    return ['owner', 'editor'].includes(role);
}

export function canDeleteCommentLogin(commentUserId, currentUserId) {
    return commentUserId === currentUserId || !commentUserId && !currentUserId;
}

export function canUserNotPermission(role) {
    return ['public_visitor'].includes(role);
}

export function canDeleteCommentUserLogin(role) {
    return ['owner', 'editor', 'commenter', 'reader'].includes(role);
}

export function canDownloadCSV(subscriptionType) {
    // return subscriptionType == 'master' || subscriptionType == 'basic'
    return true
}
export function canCreateAndUpdatePage(role) {
    return ['owner', 'editor'].includes(role);
}
export function canDeletePage(role) {
    // return ['owner'].includes(role);
    return true;
}

export function canDraw(currentUserId) {
  return currentUserId ;

}
export function canShareSocial(subscriptionType, role) {
    return ['master', 'free', 'light', 'standard', 'pro', 'business'].includes(subscriptionType) && role == 'owner'
}
export function canChangeSetting(role) {
    return role == 'owner'
}

export function canUseUrlFeature(role,subscriptionType) {
    return true
}
export function canUseZoomImageFeature(role,subscriptionType) {
    return true
}
export function canEditComment(user_id, owner_id) {
    return user_id && owner_id && user_id == owner_id
}
export function canDeleteReplyComment(user_id, owner_id) {
    return user_id && owner_id && user_id == owner_id
}
export function canReplyComment(role) {
    return ['owner', 'editor', 'commenter'].includes(role)
}
export function canUseReplyFeature(role){
    return role !== 'public_visitor'
}

