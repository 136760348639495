import React, { Component } from 'react';
import { Card, CardBody, Button, Input, Form } from 'reactstrap';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';

export default class TextEditor extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowDropzone: false,
    };
  }
  onChange(value) {
    this.props.onChangeBody(value)
  }

  setEditor(editor) {
    this.props.setEditor(editor)
  }
  onDrop = (acceptedFiles, rejectedFiles) => {
    const { onAddFiles } = this.props;
    Array.prototype.push.apply(acceptedFiles, rejectedFiles);
    onAddFiles(acceptedFiles);
    this.hideDropzone();
  }
  showDropzone = () => this.setState({ shouldShowDropzone: true })
  hideDropzone = () => this.setState({ shouldShowDropzone: false })
  onClickHideDropzone = e => {
    e.stopPropagation();
    this.hideDropzone();
  };
  getRandomInt() {
    return new Date().getTime();
  }
  render() {
    const { inputId = '', disabled, rows, files, placeholder, is_comment, className = '', initialValue = '' } = this.props;
    const { shouldShowDropzone } = this.state;
    return (
      <div className="text-editor" onDragEnter={this.showDropzone}>
        <div className="textarea-container">
          <Editor
            className={className}
            id={`${inputId}-${this.getRandomInt()}`}
            onEditorChange={(content, editor) => {this.onChange(content)}}
            disabled={disabled}
            rows={rows}
            onInit={(evt, editor) => this.setEditor(editor)}
            initialValue={initialValue}
            textareaName={is_comment ? 'description_comment' : 'description_box'}
            init={{
              height: 140,
              menubar: false,
              toolbar: false,
              statusbar: false,
              placeholder: placeholder,
              inline_boundaries: false,
              forced_root_block: 'div',
              auto_focus : inputId
            }}
            autoFocus />
          {
            shouldShowDropzone && (
              <Dropzone className="dropzone row justify-content-center align-items-center" onDrop={this.onDrop} accept="image/*">
                <div className="col-12">
                  Drop image here or select a file.
                  <a href="#" className="ml-3" onClick={this.onClickHideDropzone}>
                    Close
                  </a>
                </div>
              </Dropzone>
            )
          }
        </div>
      </div>
    )
  }
}
