export default class RegisterPage {
  activeBtnUser() {
    Visiable.show('.sign-up-RegisterKeycode');
    Visiable.show('.sign-up-btn');
    Visiable.hide('.sign-up-user');
    Visiable.hide('.check-policy');
    Visiable.hide('.link-login');
    $('#registerTitle').text('キーコード入力')
    this.change_input_signup_keycode();
    if($('#showDescriptionKeycodeAuto').length !== 0) $('#showDescriptionKeycodeAuto').modal('show');
  }
  activeBtnCompany() {
    Visiable.hide('.sign-up-btn');
    Visiable.show('.sign-up-user');
    Visiable.show('.check-policy');
    Visiable.show('.link-login');
    $('#registerTitle').text('新規会員登録')
    $('.sign-up-user').text('登録する')
    this.change_input_signup_keycode(true);
    this.show_input_keycode();
  }
  keyCode(){
    let modal = document.getElementById('modal-signup-keycode');
    modal.style.display = "block";
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
  keyCodeAkapon(){
    let modal = document.getElementById('showDescriptionKeycode');
    modal.style.display = "block";
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }

  show_input_keycode() {
    $('.content-register').find('.text-header').removeClass('d-none');
    $('.content-register').find('#registerTitle').removeClass('d-none');
    $('.content-register').find('.register_email_outer').removeClass('d-none');
    $('.check-policy').removeClass('d-none');
    $('button.sign-up-user').removeClass('px-5 mt-3');
    $('button.sign-up-user').attr('disabled', true);
    $('#register-keycode').addClass('d-none');
    $('#back-signin').removeClass('d-none')
    $('.show-keycode-register').addClass('d-none')
  }
  show_page_keycode() {
    $('#show_info_company').addClass('d-none');
    $('#register-keycode').removeClass('d-none');
    $('#back-signin').removeClass('d-none');
    $('#registerTitle').removeClass('d-none');
    $('.content-register').find('.text-header').removeClass('d-none');
    $('.register_email_outer').removeClass('d-none');
    $('.show-keycode-register').addClass('d-none');
    $('button.sign-up-user').addClass('d-none');
    $('button.sign-up-btn').removeClass('d-none');
    $('#form-register-user').find('.keycode-form').removeAttr('required').attr('disabled', true);
  }
  change_input_signup_keycode(input_keycode = false){
    if (input_keycode) {
      Visiable.show('#register-email')
      Visiable.hide('#register-keycode')
      Visiable.show('#collapseOne')
      Visiable.hide('#collapseTwo')
      $('input[name="user[key]"').attr("required", true)
      $('input[name="user[email]"').attr("required", true)
      $('input[name="user[key]"').removeAttr("disabled")
      $('input[name="user[email]"').removeAttr("disabled")
      $('input[name="user[keycode]"').attr("disabled", true)
      $('#type_keycode').attr("disabled", true)
      $('input[name="user[keycode]"').removeAttr("required")
      $('#type_keycode').removeAttr("required")
      $("#email_register").attr('checked', true);
      $("#keycode_register").attr('checked', false);
      Visiable.hide('#show_info_company');
      Visiable.hide('.title-register-keycode');
      Visiable.show('.title-register-email');
      $('#form-register-user').attr('register_user', 'false');
      $.each($('.keycode-form'), function (index, field) {
        $(field).removeAttr("required").attr("disabled", true);        
      })
    }else{
      Visiable.hide('#register-email')
      Visiable.show('#register-keycode')
      Visiable.hide('#collapseOne')
      Visiable.show('#collapseTwo')
      $('input[name="user[key]"').attr("disabled", true)
      $('input[name="user[email]"').attr("disabled", true)
      $('input[name="user[keycode]"').removeAttr("disabled")
      $('#type_keycode').removeAttr("disabled")
      $('input[name="user[keycode]"').attr("required", true)
      $('#type_keycode').attr("required", true)
      $('input[name="user[key]"').removeAttr("required")
      $('input[name="user[email]"').removeAttr("required")
      $('#email_register').removeAttr('required')
      $('email_register').attr('disable', true)
      $("#email_register").attr('checked', false);
      $("#keycode_register").attr('checked', true);
      Visiable.show('.title-register-keycode');
      Visiable.hide('.title-register-email')
    }
  }
  submitFormRegister(plan_type = 'plan_not_free') {
    let data = new FormData();
    let formData = $('#register-form').serializeArray();
    $.each(formData, function (key, input) {
      data.append(input.name, input.value);
    });
    if ($('#user_avatar').length > 0 && $('#user_avatar')[0].files.length > 0) data.append("user[avatar][]", $('#user_avatar')[0].files[0]);
    const ajaxCall = Shared.callAjax($("form#register-form").attr('action'), data, 'json', 'post', true, true)
    ajaxCall.done(function (result) {
      $("#modal-confirm-submit").modal('hide')
      if (result.success) {
        let is_choose_bank_transfer = (plan_type === 'choose_bank_transfer')
        let thanksPage = result?.akapon ? Routes.owner_akapon_thanks_path() : Routes.owner_signup_thanks_path()
        if (plan_type === 'plan_free' || plan_type === 'choose_bank_transfer') {
          window.location.href = thanksPage  + `?is_choose_bank_transfer=` + is_choose_bank_transfer
          return
        }
        if (result.need_verify_card) {
          setTimeout(function() {
            const stripe = Stripe(gon.stripe_publishable_key);
            Shared.callAjax(Routes.check_confirm_setup_intents_owner_cards_path(), {}, 'json', 'GET').done(function(resp) {
              if (resp.has_setup_intents) {
                stripe.confirmCardSetup(resp.client_secret)
              }
              setTimeout(function() { Shared.loadingIndicator() }, 50)
            })
          }, 50)
          setTimeout(function() {
            const messages =
              `<span class="h3 font-weight-bold">クレジットカードでの決済予約が完了しました。</span>
              <p class="h4 font-weight-normal text-white">
                ご登録のメールアドレス宛にクレジットカード会社から
                決済についての連絡メールが届きます。
                承認が完了するまで<span>Freeプラン</span>をご利用頂けます。
                承認完了後より<span>${result.plan_name}プラン</span>のご利用が可能になります。
              </p>`
            Popup.confirm(messages, {
              cancelBtn: 'false', confirmText: 'OK', width: 'auto',
              success: () => {
                window.location.href = thanksPage
              }
            })
            $('.preloader').fadeOut();
          }, 2500)
        } else {
          Popup.confirm('クレジットカード決済が完了しました。', {
            cancelBtn: 'false', confirmText: 'OK',
            success: () => {
              window.location.href = thanksPage
            }
          })
        }
      } else {
        if (result.message_error === 'password_incorrect') {
          Popup.alert('パスワードは一致していません。', '', '', { popupCustomClass: 'w-auto' })
        } else if (result.message_error === 'user_is_logined') {
          Popup.alert(I18n.t('shared.user_is_logined'), '', '', { popupCustomClass: 'w-auto' })
        } else {
          const message = `このカードは現在ご利用いただけません。<br>恐れ入りますが、別のクレジットカードをご利用ください。<br><br>[ERR: ${result.message_error}]`
          Popup.alert(message, '', '', { popupCustomClass: 'w-auto' })
        }
      }
    })
  }
}
