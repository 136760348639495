export default class CompanySelection {
  handleClickSelect2Disabled(type) {
    if (type === 'sender') {
      $('body').on('click', 'select.company_received_order[disabled] + .select2-container', function(e) {
        $(this).parents('tr').find('.select_quotation_sender').trigger('click');
      })
    } else {
      $('body').on('click', 'select.company_sender_order[disabled] + .select2-container', function(e) {
        $(this).parents('tr').find('.img-quotation-received').trigger('click');
      })
    }
  }

  select(event, type) {
    const classCompanyName = type === 'sender' ? 'company_received_order' : 'company_ordered'
    const classQuotation = type === 'sender' ? 'quotation_sender' : 'work_quotation_id'
    const classImg = type === 'sender' ? 'select_quotation_sender' : 'img-quotation-received'
    const $element = $(event.target);
    const tdSelect = $element.parents('td');
    tdSelect.find(`input.${classCompanyName}`).val($element.val());
    tdSelect.find(`input.${classQuotation}`).val('')
    if ($element.val()) { tdSelect.find(`span.${classImg}`).trigger('click') }
  }
}
