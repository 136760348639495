export default class DetailProject {
  selectProject(evt) {
    const allProject = document.querySelectorAll(".single-project");
    allProject.forEach(singleProject => {
      //enable form element if it was disable
      singleProject.querySelectorAll("*").forEach(element => element.removeAttribute("disabled"));
      //hide form that isn't being selected
      singleProject.classList.add("d-none");
      const nameSelected = evt.target.options[evt.target.selectedIndex].dataset.name;
      if (singleProject.getAttribute("data-name") === nameSelected) singleProject.classList.remove("d-none");
      if (!!nameSelected) {
        $('div#project-type-status').removeClass('border-bottom-none');
        $('.th.item-project-type').css('border-bottom', '')
      } else if (screen.width >= 768) {
        $('.th.item-project-type').css('border-bottom', 'none')
      }else {
        $('div#project-type-status').addClass('border-bottom-none');
      }
    });
    this.submitEditProjectDetail()
  }

  editProject(evt) {
    const editBtn = $("#edit-bt");
    const quotationPresent = evt.target.dataset.quotation_present;

    //disable client list if quotation false.
    if (quotationPresent === "false") {
      $("#company-list").removeClass("disabled-form");
    }

    //disable or enable form between edit and detail button clicked.
    $(".editable-item").each(function (index, item) {
      $(item).toggleClass("disabled-form").toggleClass("form-opacity")})

    //switch edit btn text.
    editBtn.text(editBtn.text() === "編集する" ? "編集画面" : "編集する");

    //switch project-list-btn and update btn.
    Visiable.toggle("#project-list");

    //toggle image upload button area when switch between update and details.
    $(".upload-image-text").each((index, element) => {
      $(element).toggleClass("d-none");
    });
    $(".checkbox-and-radio").each((index, element) => {
      if (!$(element).hasClass("checked")) {
        Visiable.toggle(element);
      }
    });

    //toggle close icon when switch between edit and detail btn.
    $(".js-delete_project_documents").each((index, element) => {
      $(element).toggleClass("d-none");
    })

    //toggle select arrow of select option when switch between update and details.
    $("select").each((index, element) => {
      $(element).toggleClass("disable-select-arrow");
    })

    // toggle checkbox or radio icon when switch between update and details.
    $("input[type=checkbox],input[type=radio]").each((index, element) => {
      if (element.style.display === "inline-block") {
        element.style.display = "none";
      } else {
        element.style.display = "inline-block";
      }
    })
  }

  submitEditProjectDetail() {
    $('#update-btn').trigger('click')
  }
}
