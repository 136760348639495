export function fetchHeightHeader() {
  let heightTopToAnimation = 0;
  if (document.getElementsByClassName('navbar_outer')[0]) {
    heightTopToAnimation += document.getElementsByClassName('navbar_outer')[0].clientHeight
  }
  if (document.getElementsByClassName('card')[0]) {
    heightTopToAnimation += document.getElementsByClassName('card')[0].clientHeight
  }
  for (let i = 0; i < document.getElementsByClassName('ticket-newstyle').length; i++) {
    heightTopToAnimation += document.getElementsByClassName('ticket-newstyle')[i].clientHeight
  }
  if (document.getElementsByClassName('btn-public-url')[0]) {
    heightTopToAnimation += document.getElementsByClassName('btn-public-url')[0].clientHeight
  }
  if (document.getElementsByClassName('notice-area')[0]) {
    heightTopToAnimation += document.getElementsByClassName('notice-area')[0].clientHeight
  }
  return heightTopToAnimation;
}
