import React, { Component } from 'react';
import { Card, CardBody, CardText, CardLink } from 'reactstrap';
import moment from 'moment';
import nl2br from 'react-nl2br';
import FontAwesome from 'react-fontawesome';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';

import { canDeleteComment, canDeleteCommentUserLogin, canDeleteCommentLogin, canUserNotPermission } from '../authorizations';

export default class AnimationCommentsPane extends Component {
  onClickDelete(targetId) {
    return event => {
      const { onCommentDeletionCalled } = this.props;
      event.preventDefault();
      if (!confirm("本当に削除しますか？")) return;
      onCommentDeletionCalled(targetId)
    }
  }
  showDeleteComment(user){
    const { currentUserId, role, owner } = this.props;
    // current user have role owner, view, edit, comment
    if (currentUserId && canDeleteCommentUserLogin(role)){
      return canDeleteComment(user && user.id, currentUserId, role)
    }
    // current user have role pullicer_user
    if (currentUserId && canUserNotPermission(role) && !!owner || !currentUserId && canUserNotPermission(role) && !!owner){
      return false
    }
    // public user
    return canDeleteCommentLogin(user && user.id, currentUserId)
  }
  render() {
    const { comments } = this.props;

    return (
      <div className="animation-comment-pane">
        {
          comments.map(({ created_at, user, body, id, email_temp, user_name_temp, animation_comment_files: files }, i) => {
            const user_temp = user_name_temp ? `${user_name_temp}（非ログイン）` : null
            return (
              <div className={`item-akapon comment-${id}`} key={i}>
                {/* 投稿者名&時間 */}
                <div className="meta">
                  <div className="author">
                    {user && user.name_in_project || user_temp || '（非ログイン）'}
                  </div>
                  <div className="date">
                    {moment.utc(created_at).fromNow()}
                  </div>
                </div>
                {/* 投稿者名&時間 */}

                {/* 本文 */}
                <div className="body">
                  {nl2br(body)}
                  {
                    this.showDeleteComment(user) && (
                      <CardLink onClick={this.onClickDelete(id)} href="#">
                        <FontAwesome name="trash" className="delete" />
                      </CardLink>
                    )
                  }
                  <div className="attached-files">
                    {
                      files && files.map((file, i) => {
                        return (
                          <div className="attached-file my-2" key={i}>
                            <ReactFancyBox image={file.file_object.url} />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {/* 本文 */}
              </div>
            );
          })
        }
      </div>
    );
  }
};
