const OPEN_CLASS = 'fa-minus'
const CLOSE_CLASS = 'fa-plus'
export default class Collapsible {
  open(element) {
    element.addClass(OPEN_CLASS).removeClass(CLOSE_CLASS)
  }

  close(element) {
    element.addClass(CLOSE_CLASS).removeClass(OPEN_CLASS)
  }

  toggle(element, closingCallBack = () => {}, openingCallBack = () => {}) {
    element.toggleClass(`${CLOSE_CLASS} ${OPEN_CLASS}`)
    if (Collapsible._isClose(element)) closingCallBack()
  }

  static _isClose(element) {
    element.hasClass(CLOSE_CLASS)
  }

  static _isOpen(element) {
    element.hasClass(OPEN_CLASS)
  }
}
