window.addEventListener('DOMContentLoaded', function() {
  if (location.href.includes('/p/projects/')) {
    const links = document.getElementsByClassName('external_link');
    [].forEach.call(links, (link) => {
      link.addEventListener('click', (e) => {
        if (!confirm('このページから離脱してよいですか？')) {
          e.preventDefault();
        }
      })
    })
  }
});
