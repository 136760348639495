import Swal from 'sweetalert2'
import refreshSortableWorkQuantity from './sort_table_new'
export default class Ticket {
  save(element, saveQuantity = false, isShowMembersInvitedInTicket = false, isCheckOld = false, checkSave = false,
       is_not_update = false, isShowModalRemoveQuotation = false, type = 'updateTicket') {
    const workTrEle = $(element).parents('tr');
    const workId = workTrEle.find('input.work_id').val()
    const ticketParams = this._buildTicket(workTrEle);
    const isNew = $('.vip').data('is-new');
    const totalWorkChild = $(workTrEle).find('.total-work-child').val();
    const appendOrder = workTrEle.find(`#append-order-${workId}`)
    const appendToOrder = appendOrder.length > 0 ? appendOrder.val() : 'false'
    let workSender = ''
    let workReceived = ''
    let workOld = ''
    if (workTrEle.attr('data-old-work')){
      workOld = JSON.parse(workTrEle.attr('data-old-work'));
    }
    if (workTrEle.attr('data-old-work-order-sender')) {
      workSender = JSON.parse(workTrEle.attr('data-old-work-order-sender'));
    }
    if (workTrEle.attr('data-old-work-order-received')) {
      workReceived = JSON.parse(workTrEle.attr('data-old-work-order-received'));
    }
    const status = workTrEle.find('.input_status').val();
    const description = workTrEle.find('.description_new').val();
    const unit = workTrEle.find('.render_unit_work').val();
    const quantity = workTrEle.find('.show-quantity:first').text()
    const copyTicket = workTrEle.find('input.copy_ticket').val();
    if (!description) {
      Popup.alert(I18n.t('work.input_name_work'), 'warning');
      this._resetTicket(workTrEle);
      return
    }
    if (type !== 'updateTicket') window.history.pushState({},"",location.href.split('?')[0]);
    let paramsNotCheckOldWorkOrder = isCheckOld ? '&work_order_not_check_old=true' : ''
    let paramsWorkingOnTicketId = isShowMembersInvitedInTicket ? `&working_on_ticket_id=${workId}` : '';
    this._saveOwnerProjectTaskTicket(paramsNotCheckOldWorkOrder, paramsWorkingOnTicketId, workOld, workSender, workReceived, ticketParams, totalWorkChild, appendToOrder, checkSave, copyTicket, workId, saveQuantity, workTrEle, is_not_update, isShowModalRemoveQuotation, type);
  }

  selectStatus(element) {
    if (gon.log_work_feature.status_delivered.includes($(element).val())) {
      Visiable.show($(element).parents('tr').find('.delivery-date-mb'));
    } else {
      Visiable.hide($(element).parents('tr').find('.delivery-date-mb'));
      $(element).parents('tr').find('.delivery-date-with-mb').val('');
    }
  }
  addClassHighLightTicket(id) {
    $('tbody.invoice__payment--tbody').removeClass('ticket_just_been_copied')
    $(`tbody.invoice__payment--tbody`).removeClass('high_light_ticket')
    $(`tbody.invoice__payment--tbody[index=${id}]`).addClass('high_light_ticket')
  }
  showQuantityMobile(element) {
    const workEle = $(element).parents('table')
    const unit = workEle.find('#work_unit').val();
    const quantity = workEle.find('.show-quantity').text();
    if (!unit || unit === '' || unit === 'other') { Popup.alert(I18n.t('work.not_show_quantity')); return }
    logWorkAction._toggleQuantityType(unit, $(element).next(), quantity);
    $(element).next().modal('show');
    Visiable.hide($('body').find('.modal-backdrop'));
  }

  showQuantity(element, workId) {
    if (window.innerWidth >= gon.shared.break_point_for_mobile) return
    $(`span[data-id-modal="modal-quantity-${workId}"]`).trigger('click');
  }

  fetchWorkEle(workId) {
    const allWork = $('table.render_works').find('input.work_id').toArray()
    let workEle;
    allWork.some(inputId => {
      if (Number($(inputId).val()) === workId) {
        workEle = $(inputId).parents('tr');
        return true;
      }
    })
    return workEle;
  }

  setExpiration(element, workId) {
    // this.fetchWorkEle(Number(workId)).find('.expiration-date').val($(element).val());
    const date = $(element).find('span.show_date_modal').text()
    const time = $(element).find('span.show_time_modal').text()
    const modalExpirationMb = $(element).closest('.detail_popup').next('.modal_expiration')
    modalExpirationMb.find('input.datetimepicker').val(date.trim())
    modalExpirationMb.find('input.datetimepicker-time').val(time.trim())
    modalExpirationMb.modal('show');
    $('.modal-backdrop').addClass('d-none')
  }

  setPic(element, workId) {
    this.fetchWorkEle(Number(workId)).find('.person_incharge_id').val($(element).val());
  }

  setUnit(element, workId) {
    this.fetchWorkEle(Number(workId)).find('.select_unit_work').val($(element).val()).trigger('change');
  }

  setName(element, workId) {
    this.fetchWorkEle(Number(workId)).find('.description_new').val($(element).val());
  }

  showSelectOrder(element, workId, type) {
    this.fetchWorkEle(Number(workId)).find(`span[onclick="logWorkAction.showModalSelectQuotation(event, '${type}')"]`)
                                     .trigger('click')
  }

  showSelectOrderForSupplier(workId) {
    this.fetchWorkEle(Number(workId)).find(`span[onclick="logWorkAction.showModalOrderSupplier(event)"]`)
                                     .trigger('click')
  }

  update(element, workId) {
    logWorkAction.toggleDetailTicket(element);
    this.fetchWorkEle(Number(workId)).find('.description_new').trigger('change');
  }

  createTicket(element, saveQuantity = false, isShowMembersInvitedInTicket = false, isCheckOld = false, checkSave = false) {
    const ticketEle = $(element).parents('.create-new-ticket');
    const status = ticketEle.find('.select_status_mb').val();
    const quantity = Number(ticketEle.find('.show-quantity').text())
    const description = ticketEle.find('#work_description').val();
    const workId = ticketEle.find('input.work_id').val()
    const ticketParams = this._buildTicket(ticketEle);
    const totalWorkChild = $(ticketEle).find('.total-work-child').val();
    const appendOrder = ticketEle.find(`#append-order-${workId}`)
    const appendToOrder = appendOrder.length > 0 ? appendOrder.val() : 'false'
    let workSender = ''
    let workReceived = ''
    let workOld = ''
    if (ticketEle.attr('data-old-work')){
      workOld = JSON.parse(ticketEle.attr('data-old-work'));
    }
    if (ticketEle.attr('data-old-work-order-sender')) {
      workSender = JSON.parse(ticketEle.attr('data-old-work-order-sender'));
    }
    if (ticketEle.attr('data-old-work-order-received')) {
      workReceived = JSON.parse(ticketEle.attr('data-old-work-order-received'));
    }
    if (!description) {
      Popup.alert(I18n.t('work.input_name_work'), 'warning')
      return
    }
    if (gon.log_work_feature.status_completed.includes(status) && quantity === 0) {
      Popup.alert(I18n.t('work.errors.quantity_blank'), 'warning')
      return
    }
    window.history.pushState({},"",location.href.split('?')[0]);
    let paramsNotCheckOldWorkOrder = isCheckOld ? '&work_order_not_check_old=true' : ''
    let paramsWorkingOnTicketId = isShowMembersInvitedInTicket ? `&working_on_ticket_id=${workId}` : '';
    const copyTicket = false;
    const is_not_update = true;
    this._saveOwnerProjectTaskTicket(paramsNotCheckOldWorkOrder, paramsWorkingOnTicketId, workOld, workSender,
      workReceived, ticketParams, totalWorkChild, appendToOrder, checkSave, copyTicket, workId, saveQuantity,
      ticketEle, is_not_update, false, is_not_update
    )
  }

  handleInsertQuantity() {
    $('#mb-table-list-quantity').on('cocoon:before-insert' ,function(e, quantityAdd) {
      const workEle = $('body').find('#ticketNewPopup');
      const modal = workEle.find('.show-work-quantity');
      const orderType = workEle.find('#work_unit').val();
      const workQuantity = $(modal).find('.work-quantity-clone');
      const workDescription = workQuantity.find('.work-quantity-description').val();
      const status = workEle.find('.select_status_mb').val();
      const maxQuantity = $(modal).find('#work_max_quantity').val();
      const quantityComponent = modal;
      let hours, minute, quantity;
      if (orderType === 'by_time') {
        Visiable.hide($(quantityAdd).find('.number-quantity'));
        Visiable.show($(quantityAdd).find('.time-quantity'))
        hours = $(workQuantity).find('.quantity-hours-input').val();
        quantityAdd.find('.quantity-hours-input').val(hours);
        minute = $(workQuantity).find('.quantity-minutes-input').val();
        if (minute === ''){
          quantityAdd.find('.quantity-minutes-input').val('00');
        }else {
          quantityAdd.find('.quantity-minutes-input').val(minute);
        }
        quantityAdd.find('.quantity-number').val(logWorkAction._toAmount(Number(hours), Number(minute)));
      } else {
        Visiable.show($(quantityAdd).find('.number-quantity'));
        Visiable.hide($(quantityAdd).find('.time-quantity'))
        quantity = $(workQuantity).find('.quantity-number').val();
        quantityAdd.find('.quantity-number').val(quantity);
      }
      if(gon.log_work_feature.status_delivered.includes(status)) {
        e.preventDefault();
        return
      }
      if (!workDescription ||
        (orderType === 'by_time' && !hours) ||
        (orderType !== 'by_time' && !quantity)) {
        let message = ''
        if (!hours) {
          Shared.errorFieldMessage($(workQuantity).find('.quantity-hours-input'), I18n.t('work.cannot_blank_time'));
        }
        if (!quantity) {
          Shared.errorFieldMessage($(workQuantity).find('.quantity-number'), I18n.t('work.cannot_blank_quantity'));
        }
        if (!workDescription) {
          Shared.errorFieldMessage($(workQuantity).find('.work-quantity-description'), I18n.t('work.cannot_blank_description'));
          message += (I18n.t('work.cannot_blank_description') + '<br>')
        }
        if(orderType === 'by_time') {
          if (!hours) message += (I18n.t('work.cannot_blank_time') + '<br>')
        } else {
          if (!quantity) message += (I18n.t('work.cannot_blank_quantity') + '<br>')
        }
        Popup.alert(message, 'warning');
        e.preventDefault();
        return
      }else{
        quantityAdd.find('.work-quantity-description').val(workDescription)
      }
      const workQuantityNew = Number(quantityAdd.find('.quantity-number').val());
      const workParentMaxQuantity = Number($(modal).find('.max-quantity').val())
      const totalAllWorkQuantity = logWorkAction._totalAllWorkQuantity($(modal))
      if ($(modal).find('.max-quantity').val() && (totalAllWorkQuantity + workQuantityNew) > workParentMaxQuantity) {
        Popup.alert(I18n.t('work.limit_quantity'), 'warning');
        e.preventDefault();
        return
      } else if (workParentMaxQuantity > 0 && (totalAllWorkQuantity + workQuantityNew) > workParentMaxQuantity) {
        Popup.alert(I18n.t('work.limit_quantity'), 'warning');
        e.preventDefault();
        return
      }
      quantityAdd = logWorkAction._modifyWorkQuantityEle(quantityAdd, orderType, modal);
      $(modal).find('.work-quantity-clone').find('input').val('');
      $(workQuantity).find('.date-current').val(I18n.l("date.formats.default", new Date()));
      $(modal).find('.work-quantity-clone').find('.work-quantity-status').val('pending')
      const allWorksNew = logWorkAction._fetchAllWorksQuantity($(modal).find('#work_max_quantity'));
      logWorkAction._renderTotalQuantity(quantityComponent, allWorksNew)
    })
  }

  removeQuantity(element) {
    $(element).prev().val(true)
    $(element).parents('.work-quantity-clone2').addClass('deleted').fadeOut('normal');
  }

  delete(workId, ele) {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const objectId = searchParams.get("show_popup_chat");
    Shared.callAjax(
      Routes.owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId()),
        { work_id: workId }, 'json', 'delete').done(function(data) {
      $(ele).parents('tr').remove();
      if (!!objectId) {
        history.replaceState(null, null, window.location.pathname);
      }
      Popup.confirm(`${I18n.t('work.i_deleted_the_ticket')}`, {
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    }).fail(function(){
      popupConfirmReload(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _calculatorQuantity(workEle, allTotalQuantity) {
    workEle.find('.total-hours').val(allTotalQuantity.totalHours)
    workEle.find('.total-number-quantity').val(allTotalQuantity.totalQuantity)
    workEle.find('.total-minutes').val(allTotalQuantity.totalMinutes)
    workEle.find('.show-quantity').text(allTotalQuantity.totalQuantity.toFixed(gon.number_round))
    workEle.find('.quantity-work').val(allTotalQuantity.totalQuantity.toFixed(gon.number_round))
  }

  restoreQuantity(element) {
    const workEle = $('body').find('#ticketNewPopup');
    const listQuantities = workEle.find('.mb-list-quantity');
    listQuantities.find('.work-quantity-clone2').toArray().forEach(quantity => {
      if (!$(quantity).hasClass('confirmed')) {
        $(quantity).remove();
      }
    })
    this._calculatorQuantity(workEle, allWorksNew)
  }

  approvalQuantity(element) {
    const workEle = $('body').find('#ticketNewPopup');
    const workQuantity = $(element).parents('.work-quantity-clone2');
    const workQuantityStatus = workQuantity.find('.work-quantity-status').val();
    const status = workEle.find('.select_status_mb').val();
    if (!gon.log_work_feature.status_delivered.includes(status) && gon.shared.is_domain_member) {
      if (workQuantityStatus === 'pending') {
        workQuantity.find('.work-quantity-status').val('approval');
        $(element).next().prop('checked', true);
      } else {
        workQuantity.find('.work-quantity-status').val('pending');
        $(element).next().prop('checked', false);
      }
    } else {
      $(element).next().prop('checked', workQuantityStatus === 'approval');
    }
  }

  changePersonInChargeList(element, workId) {
    const $ele = $(element);
    const addAction = $ele.is(":checked");
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    if (!addAction) {
      this._checkMemberBeforeDeletePic(element, workId, projectId, taskId)
    } else {
      this._handleChangePersonInChargeList(element, workId)
    }
  }

  _checkMemberBeforeDeletePic(element, workId, projectId, taskId) {
    const $ele = $(element);
    const listUserUnCheck = []
    $ele.parents('div.modal-body').find('input.pick-pics').toArray().forEach(input => {
      if (!$(input).is(':checked') && $(input).val()) {
        listUserUnCheck.push($(input).val())
      }
    })
    const me = this;
    const url =
      Routes.check_member_before_delete_pic_owner_project_task_ticket_path(projectId, taskId)
    const data = { work_id: workId, user_id: $ele.val(), delete_pic: true , list_user_uncheck: listUserUnCheck };
    Shared.callAjax(url, data, 'json', 'post')
    .done(function(result) {
      if (result.show_popup_warning) {
        if(result.cannot_delete) {
          $ele.prop("checked", true)
          Popup.alert('注文または未納品作業がある為、1名以上のメンバーが必要です。<br>解除する場合、同じ会社の他メンバーを招待してください。')
          PersonInchargeTicket.checkedInput(element)
        } else {
          let message = `<div class='text-center font-weight-normal popup-check-member-before-delete'>${result.user_name}さんを招待解除すると、このチケットの紐づけが解除され <br>未納品作業が削除されます。
            <a href="${Routes.undelivered_works_efforts_path({search_kind: 'work_id_cont', q: { kind_id: workId, created_by_eq: result.is_client ? null : $ele.val() }})}" target='_blank' class="text-underline font-weight-bold">未納品作業一覧ページ</a>より未納品作業をご確認の上、メンバー解除を行ってください。
            メンバー解除後、10日間は<a href="${Routes.deleted_works_efforts_path()}" target='_blank' class="text-underline font-weight-bold">削除一覧ページ</a>より未納品作業のStatus変更が可能です。
            10日を超過すると未納品作業のStatus変更は出来ません。
          </div>`
          if (result.is_client) {
            message = `<div class='text-center font-weight-normal popup-check-member-before-delete'>${result.company_name}の未納品作業が存在します。
              紐づけを解除すると提出済（未納品）作業は削除されます。
              解除すると、未納品作業は削<a class='font-weight-bold text-underline' href="${Routes.deleted_works_efforts_path()}" target='_blank'>除済一覧ページ</a>に移動します。
            </div>`
          }
          Popup.confirm(
            message,
            {
              success: () => {
                PersonInchargeTicket.unCheckMemberInChargeList(element, workId)
              },
              cancel: () => {
                PersonInchargeTicket.checkedInput(element)
              },
              confirmText: '解除する',
              cancelText: 'キャンセル',
              width: 'auto',
            }
          )
        }
      } else if (result.only_send_mail) {
        PersonInchargeTicket.unCheckMemberInChargeList(element, workId)
      }
    }).fail(function(result) {
      PersonInchargeTicket.checkedInput(element)
      var description = I18n.t('work.not_remove_main_admin')
      if (result.responseJSON.delete_self == 'self'){
        description = 'メンバーから自分の解除はできません。';
      }
      Popup.confirm('',{
        description: description,
        confirmText: 'OK',
        cancelBtn: 'false',
      })
    })
  }

  _handleChangePersonInChargeList(element, workId, deleteAllEffort = false) {
    const $ele = $(element);
    const addAction = $ele.is(":checked");
    const picListElement = $(`#pic_list_${workId}`);
    const oldPicList = picListElement.data('old-pic-ids') || [''];
    const data = {
      work_id: workId, user_id: $ele.val(), add_pic: addAction,
      old_pic_ids: oldPicList, delete_all_efforts: deleteAllEffort
    };
    const descriptionEle = picListElement.parents('tr.tr_index').find('input.description_new')
    const ticketJs = new Ticket
    const url = Routes.update_pic_list_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId())
    $("#personInChargeOfObject").attr('data-is-change', 'true')
    Shared.callAjax(url, data, 'json', 'post')
    .done(function(result) {
      picListElement.text(result.text_number_pic);
      picListElement.data('old-pic-ids', result.work.pic_ids);
      $ele.parents('tr').attr('data-old-work', JSON.stringify(result.work));
      ticketJs.save(descriptionEle, false, true)
      Shared.loadingIndicator();
      if (result.delete_all_efforts) {
        Popup.alert(`${result.user_name}さんを招待解除しました。`)
      }
    })
    .fail(function(result) {
      $ele.prop('checked', !addAction);
      if (result.responseJSON.changed_data_by_someone) {
        newPopupConfirmReload(I18n.t('work.changed_refresh'))
      } else {
        var description = 'このメンバーの解除権限はありません。';
        if(result.responseJSON.add_pic == 'true') {
          description = 'このユーザは追加出来ません。';
        }
        if(result.responseJSON.delete_pic_role == 'main_admin') {
          description = 'メイン管理者は解除できません。';
        }
        Popup.confirm('', {
          description: description,
          confirmText: 'OK',
          cancelBtn: 'false',
        })
      }
    })
  }

  _resetTicket(workEle) {
    if (workEle.find('.work_id').val()) return
    workEle.find('.select_status').val('preparation');
    workEle.find('.input_status').val('preparation');
    workEle.find('.list-work-quantity').find('.work-quantities').remove();
    workEle.find('.show-quantity').text(0);
    workEle.find('.expiration-date').val('');
    workEle.find('.input_delivery_date').val('');
    if (workEle.find('.work_parent_id').val()) return
    workEle.find('select.person_incharge_id').val(gon.log_work_feature.current_user.id);
    workEle.find('.select_unit_work').val('');
    workEle.find('.render_unit_work').val('');
    workEle.find('.name-company-order-received').text(`${I18n.t('shared.sales_destination')} :`);
    workEle.find('.name-company-order-sender').text(`${I18n.t('plan_show.number_of_supplier')} :`);
    workEle.find('.work-order-show').find('input').val('')
  }

  _fetchProjectId() {
    return $('#get_data_url').attr('project_id');
  }
  _fetchTaskId() {
    return $('#get_data_url').attr('task_id');
  }

  _totalWorkQuantity(trEle) {
    const listWorkQuantities = trEle.find('.list-work-quantity').find('tr.work-quantities');
    let total = 0;
    listWorkQuantities.toArray().forEach(workQuantity => {
      total += Number($(workQuantity).find('.quantity-number').val())
    })
    return total;
  }

  _totalAllQuantity(listQuantity) {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalQuantity = 0;
    listQuantity.forEach(quantity => {
      totalHours += Number($(quantity).find('.quantity-hours-input').val());
      totalMinutes += Number($(quantity).find('.quantity-minutes-input').val());
      totalQuantity += Number($(quantity).find('.work_quantity').val());
    })
    return { totalHours: totalHours, totalMinutes: totalMinutes, totalQuantity: totalQuantity }
  }

  _buildTicket(trEle) {
    const listWorkQuantities = trEle.find('.list-work-quantity').find('tr.work-quantities');
    const workParentEle = trEle.parents('tbody').find('tr:first');
    const quantityWork = this._totalWorkQuantity(trEle);
    let workQuantities = {}
    let workOrders = {}
    listWorkQuantities.toArray().forEach((workQuantity, index) => {
      workQuantities[`${index}`] = {
        id: $(workQuantity).find('.work-quantity-id').val(),
        quantity: $(workQuantity).find('.quantity-number').val(),
        description: $(workQuantity).find('.work-quantity-description').val(),
        status: $(workQuantity).find('.work-quantity-status').val(),
        created_at: $(workQuantity).find('.work-quantity-created-at').val(),
      }
    });
    workOrders[`0`] = {
      id: trEle.find('.work_order_received_id_received').val(),
      order_id: workParentEle.find('.work_quotation_id').val(),
      status: trEle.find('.input_status').val(),
      company_received_quotation: $('.vip').data('current-company'),
      company_sender_quotation: workParentEle.find('.company_ordered').val()
    }
    workOrders[`1`] = {
      id: trEle.find('.work_order_received_id_sender').val(),
      order_id: workParentEle.find('.quotation_sender').val(),
      status: trEle.find('.input_status').val(),
      company_received_quotation: workParentEle.find('.company_received_order').val(),
      company_sender_quotation: $('.vip').data('current-company')
    }
    let ticket = {
      id: trEle.find('.work_id').val(),
      parent_id: trEle.find('.work_parent_id').val(),
      status: trEle.find('.input_status').val(),
      description: trEle.find('.description_new').val(),
      unit: workParentEle.find('.render_unit_work').val(),
      expiration_date: trEle.find('.value-expiration-date').val(),
      delivery_date: trEle.find('.input_delivery_date').val(),
      person_in_charge_id: workParentEle.find('select.person_incharge_id').val(),
      task_id: $('.show_body_table').attr('task_id'),
      max_quantity: trEle.find('.max-quantity').val(),
      quantity: `${quantityWork}`,
      representative_supplier_id: $('input[name="work_representative_supplier_id"]').val(),
      work_quantities_attributes: workQuantities,
      work_orders_attributes: workOrders,
    }
    return ticket;
  }

  changeLinkPagynation(action, params) {
    this._changeLink($(document).find('.pagy-bootstrap-nav').find('a.page-link').toArray(), action, params)
    this._changeLink($(document).find('#thead-list-ticket').find('a.link-sort-ticket').toArray(), action, params)
    this._changeLink($(document).find('#sortBox').find('a.link-sort-ticket').toArray(), action, params)
    this._changeLink($(document).find('.chage_counting_items').find('a.dropdown-item').toArray(), action, params)
  }

  changeLinkSearch(action, value) {
    if (action === 'add') {
      $('#works_search').append(`<input type="hidden" id="${value}" value="true" name="${value}">`)
    } else {
      $('#works_search').find(`input#${value}`).remove()
    }
  }

  _changeLink(array, action, params) {
    array.forEach(tagA => {
      let paramsObject = new URLSearchParams($(tagA).attr('href').split('?')[1])
      if (action === 'add') {
        if ($(tagA).attr('href') !== '#') paramsObject.set(params, 'true')
      } else {
        paramsObject.delete(params)
      }
      $(tagA).attr('href', `${$(tagA).attr('href').split('?')[0]}?${paramsObject.toString()}`)
    })
  }

  handleTimeExpriration(ele) {
    const time = $(ele).val().replace(':', '');
    if (time.length >= 4) {
      const newTime = time.slice(0, 4).replace(':', '');
      let hours = newTime.slice(0, 2);
      let minutes = newTime.slice(2, 4)
      if (parseInt(hours) >= 24)  hours = '00';
      if (parseInt(minutes) >= 60) minutes = '00';
      $(ele).val(`${hours}:${minutes}`)
    }
  }

  appendExpendTicket() {
    const paramsByUrl = window.location.href.split('?')[1]
    if (paramsByUrl === '' || paramsByUrl === undefined) return
    const listParams = paramsByUrl.split('&')
    let listParamsExpend = []
    listParams.forEach(params => {
      const paramsSplit =  params.split('=')
      if (paramsSplit[0].includes('show_work_child')) listParamsExpend.push(paramsSplit[0])
    })
    if (listParamsExpend.length === 0) return
    listParamsExpend.forEach(param => {
      this._changeLink(
        $(document).find('#thead-list-ticket').find('a.link-sort-ticket').toArray(), 'add', `${param}`
      )
      this._changeLink(
        $(document).find('#sortBox').find('a.link-sort-ticket').toArray(), 'add', `${param}`
      )
      this._changeLink(
        $(document).find('.chage_counting_items').find('a.dropdown-item').toArray(), 'add', `${param}`
      )
      this.changeLinkSearch('add', param)
    })
  }

  hideSortTicket() {
    $(document).on('click', function(e) {
      if ($(e.target).parents('div.search_sort').length > 0) return
      Visiable.hide('#sortBox')
    })
  }

  setExpirationDateMb(ele) {
    const modalExpirationMb = $(ele).parents('.modal-content')
    let expirationDate = modalExpirationMb.find('input.datetimepicker').val().trim();
    let expirationTime = modalExpirationMb.find('input.datetimepicker-time').val().trim();
    if (expirationDate !== '' || expirationTime !== '') {
      if (expirationDate === '' || expirationDate === undefined) {
        expirationDate = new Date().toJSON().slice(0,10)
      }
      if (expirationTime === '' || expirationTime === undefined) {
        expirationTime = '00:00'
      }
    }
    $(ele).parents('td.popup-ticket-mb').find('span.show_date_modal').text(expirationDate);
    $(ele).parents('td.popup-ticket-mb').find('span.show_time_modal').text(expirationTime);
    $(ele).parents('tbody.invoice__payment--tbody').find('tr').first()
          .find('input.value-expiration-date').val(`${expirationDate}, ${expirationTime}`)
    $('form#new_work').find('span.show_date_modal').text(expirationDate);
    $('form#new_work').find('span.show_time_modal').text(expirationTime);
    $('form#new_work').find('input#new-expiration-date').val(`${expirationDate}, ${expirationTime}`)
  }

  selectWorkType(ele, unit) {
    $('#modalSelectWorkType').modal('hide')
    const workId = $(ele).parents('div#modalSelectWorkType').attr('work-id')
    const isParent = $(ele).parents('div#modalSelectWorkType').attr('is-parents')
    const parentEle = $(`tbody[data-work-id="${workId}"]`).find('tr:first')
    const workEle = isParent === 'true' ? $(`tr[data-work-id="${workId}"]`) : parentEle
    const quantity = workEle.find('.show-quantity').text();
    workEle.find('input.render_unit_work').val(unit);
    workEle.find('input.unit-work-for-company').val(unit)
    if (!parentEle.find('input.render_unit_work').val()) parentEle.find('input.render_unit_work').val(unit)
    if (unit === 'other') {
      Popup.confirm('', {
        description: I18n.t('shared.popup_custom_quantity'),
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => {
        }
      })
      return
    } else if (unit === 'by_time') {
      logWorkAction._toggleQuantityType(unit, workEle.find('.show-work-quantity'), quantity);
    } else if (unit === 'by_number') {
      logWorkAction._toggleQuantityType(unit, workEle.find('.show-work-quantity'), quantity);
    }
    saveUnitOfCompany(workId, this._fetchProjectId(), this._fetchTaskId(), unit)
  }

  changeCompanyQuantity(ele, workId, companyId) {
    $(ele).parents('.z-1052').modal('hide')
    const modalHearder = $(ele).parents('.show-body-quantiy-of-work').find('div.head-work-quantity')
    const modalBody = $(ele).parents('div.modal-body')
    const modalQuantity = $(ele).parents(`div#modal-quantity-${workId}`)
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const option = $(ele).attr('data-is-not-order')
    let dataSend = { work_id: workId, company_id: companyId }
    const divDropdown = $(ele).parents('div.show-body-quantiy-of-work')
    const buttonDropdown = divDropdown.find('button.show-name-select-company')
    const workEle = $(ele).parents('tr.tr_index')
    const dataIndex = $(ele).data('index')
    divDropdown.find('#show_tr_index_with_quantity').val(dataIndex)
    modalQuantity.find('.company-id-for-quantity').val(companyId)
    const ticket = this
    if (option) {
      dataSend = { work_id: workId, company_id: companyId, is_not_order: $(ele).data('is-not-order') }
    }
    Shared.callAjax(
      Routes.quantity_of_company_owner_project_task_ticket_path(projectId, taskId),
      dataSend
      ,
      'html'
    )
    .done(function(data) {
      ticket.resetAllDataSearchForQuantity(ele, workId)
      $(`#list-work-quantity-${workId}`).html(data)
      $(ele).parents('table.breakdown-table').attr('data-select', companyId)
      setCompanyNameToButton(buttonDropdown, $(ele))
      buttonDropdown.attr('data-company-id', $(ele).data().companyId);
      $('.change-list-company-effort ').find('.show-text-company-of-effort-change').removeClass('active')
      $('.change-list-company-effort ').find(`div`).removeClass('active')
      $('.change-list-company-effort ').find(`div[data-company-id="${companyId}"]`).addClass('active')
      Shared.callAjax(
        Routes.fetch_max_quantity_owner_project_task_ticket_path(projectId, taskId),
        {
          work_id: workId,
          company_id: companyId,
        }
      )
      .done(function(data) {
        setValueQuantityByCompany(modalHearder, data)
        toggleElementWithCompany(data.is_hidden_add_quantity, modalBody, data.is_supplier)
        showQuantityByUnit(data.unit, modalQuantity)
        workEle.find('input.unit-work-for-company').val(data.unit)
        refreshSortableWorkQuantity()
      })
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  closeModalChangeQuantity(ele) {
    $(ele).parents('.modal-for-ticket-show-info').modal('hide')
  }

  changeUnitQuantity(ele, workId) {
    const modalHearder = $(ele).parents('div.render-work-quantity-new')
    const unit = $(ele).val()
    const companyId = modalHearder.find(`#modalFilterCompanyQuantity${workId}`).find('input#company_id').val()
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const workParentId = $(this).parents('tr.tr_index').find('.work_parent_id').val()
    const ListQuantity = $(ele).parents('div.modal-body').find('table.list-work-quantity')
                              .find('tbody').find('tr.work-quantities')
    if (ListQuantity.length > 0) return
    Shared.callAjax(
      Routes.update_unit_quantity_owner_project_task_ticket_path(projectId, taskId),
      {
        work_id: workId,
        company_id: companyId,
        unit: unit,
      }
    )
    .done(function(data) {
      if (workParentId) {
        $(ele).parents('tbody').find('tr:first').find('.render_unit_work').val(unit)
      } else {
        $(ele).parents('tr.tr_index').find('.render_unit_work').val(unit)
      }
      $(ele).parents('tr.tr_index').find('input.unit-work-for-company').val(unit)
      showQuantityByUnit(unit, $(ele).parents(`div#modal-quantity-${workId}`))
    })
    .fail(function() {
      Popup.confirm(I18n.t('shared.cannot_change_unit_quantity'), {
        cancelBtn: 'false',
        success: () => { location.reload() },
        cancel: () => { location.reload() },
        confirmText: 'OK',
      })
    })
  }

  validateMaxQuantity(event) {
    const element = $(event.target);
    const MAX_NUMBER = 7;
    element.val(element.val().replace(/[^0-9\.]/g,''));
    const decimalSplitDot = element.val().toString().split('.')
    if (decimalSplitDot[0].length > MAX_NUMBER) {
      if (!!decimalSplitDot[1]) {
        element.val(`${decimalSplitDot[0].slice(0, MAX_NUMBER)}.${decimalSplitDot[1]}`);
      } else {
        element.val(decimalSplitDot[0].slice(0, MAX_NUMBER));
      }
    }
  }

  changeMaxQuantity(ele, workId) {
    const modalHearder = $(ele).parents('div.render-work-quantity-new')
    const companyId = modalHearder.find(`#modalFilterCompanyQuantity${workId}`).find('input#company_id').val()
    const maxQuantity = $(ele).val()
    const unit = modalHearder.find('select#select-unit-of-quantity').val()
    const filter = $(document).find('#params_filter_ticket').val()
    const paramsFilter = JSON.parse(filter === '' ? '{}' : filter)
    Shared.callAjax(
      Routes.update_max_quantity_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId()),
      {
        work_id: workId,
        company_id: companyId,
        max_quantity: maxQuantity,
        unit: unit,
        filter: paramsFilter
      }, 'html'
    )
    .done(function(data) {
      $(document).find(`#show_percentage_html_${workId}`).html(data)
      Popup.alert('制限が更新されました。')
    })
    .fail(function(data) {
      Popup.alert(`${data.responseText.replace('&lt;br&gt;', `<br/>`)}`)
      return
    })
  }

  showModalTotalEffortOfCompanySp(ele, workId) {
    const modal = $(`#modalShowTotalEffortSp${workId}`)
    const componentBody = $(ele).parents('.show-body-quantiy-of-work')
    const companyId = componentBody.find('input#company_id').val()
    Shared.callAjax(
      Routes.fetch_total_effort_of_company_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId()),
      {
        work_id: workId,
        company_id: companyId,
      }
    )
    .done(function(data) {
      modal.find('.total-effort-for-sp').text(data.total_text)
      modal.find('.company-name-for-effort').text(data.company_name)
      modal.modal('show')
    })
    .fail(function(data) {
      Popup.alert(`${data.responseText.replace('&lt;br&gt;', `<br/>`)}`)
      return
    })
  }

  changeMaxQuantityForSp(ele, workId) {
    const componentBody = $(ele).parents('.show-body-quantiy-of-work')
    const inputMaxQuantity = componentBody.find(`input[name="work[max_quantity][${workId}]"]`)
    inputMaxQuantity.val($(ele).val()).trigger('change')
  }

  selectUnitTypeForSp(ele, unit, workId) {
    $(ele).parents(`#modal-change-effort-type-${workId}`).modal('hide')
    const componentBody = $(ele).parents('.show-body-quantiy-of-work')
    const unitType = componentBody.find('#select-unit-of-quantity')
    unitType.val(unit).trigger('change')
  }

  _fetchSearchPopupEle(ele) {
    return $(ele).parents('div.pop_search')
  }

  changeCheckedStatusQuantity(ele, is_show_error_for_order, js_show_of_supplier = false) {
    if (is_show_error_for_order === 'true') {
      let message = I18n.t('work_quantity.not_submitted_for_order')
      if (js_show_of_supplier === 'true') {
        message = I18n.t('work_quantity.not_approve_supplier_for_order')
      }
      Popup.alert(message)
      return
    }
    $(ele).find('input').prop('checked', !$(ele).find('input').is(':checked'))
  }

  removeClient(ele) {
    const modalEle = $(ele).parents('#selectTargetCompanyReceived')
    modalEle.modal('hide')
    this.checkDeleteCompany(modalEle, 'client')
  }

  removeSupplier(ele) {
    const modalEle = $(ele).parents('#selectTargetCompanySender')
    modalEle.modal('hide')
    this.checkDeleteCompany(modalEle, 'supplier')
  }

  async showPopupConfirmRemoveCompany(modalEle, type) {
    let companyClass = type === 'client' ? 'company-received' : 'company-sender'
    const ticket = this
    const companyName = modalEle.find(`select.${companyClass} option:selected`).text()
    const url = Routes.check_effort_release_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId())
    await Shared.callAjax(url, {
      work_id: modalEle.find(`select.${companyClass}`).attr('data-work-id'),
      type: type,
      company_id: modalEle.find(`select.${companyClass} option:selected`).val()
    }).done(function(data) {
      let message = I18n.t('work.do_you_want_to_remove_company')
      if (type === 'client') {
        if (data.status === 'not_release_and_submited_effort') {
          message = I18n.t('work.delete_all_effort_submited', {company_name: companyName})
          message += `<br>解除すると<a href="${Routes.deleted_works_efforts_path()}" target="_blank">削除済一覧ページ</a>に移動しません。`
        } else if (data.status === 'release_and_submited_effort') {
          message = I18n.t('work.ticket_release_remove_all_effort', { company_name: companyName })
        } else if (data.status === 'release_and_not_submited') {
          message = I18n.t('work.delete_client_not_delete_effort', { company_name: companyName })
        } else if (data.status === 'not_release_and_not_submited') {
          message = I18n.t('work.message_remove_client_not_submited', { client_name: companyName })
        }
      } else {
        if (data.status === 'not_release_and_submited_effort') {
          message = I18n.t('work.delete_supplier_delete_effort', { supplier_name: companyName })
        } else if (data.status === 'not_release_and_not_submited') {
          message = I18n.t('work.message_remove_client_not_submited', { client_name: companyName })
        } else if (data.status === 'release_and_submited_effort') {
          message = I18n.t('work.delete_supplier_release_and_submitted', { company_name: companyName })
        } else if (data.status === 'release_and_not_submited') {
          message = I18n.t('work.delete_client_not_delete_effort', { company_name: companyName })
        }
      }
      Popup.confirm(message, {
        success: () => {
          ticket.removeCompanyOfTicketWithType(modalEle, type)
        },
        cancel: () => {},
        confirmText: I18n.t('shared.release_full_text'),
        width: 'auto'
      })
    })
    .fail(function() {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async checkDeleteCompany(modalEle, type) {
    const ticket = this
    let companyClass = type === 'client' ? 'company-received' : 'company-sender'
    const workId = modalEle.find(`select.${companyClass}`).attr('data-work-id');
    const companyId = modalEle.find(`select.${companyClass} option:selected`).val();
    const companyName = modalEle.find(`select.${companyClass} option:selected`).text();
    const url = Routes.check_delete_company_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId())
    await Shared.callAjax(url, {
      work_id: workId,
      company_id: companyId,
      type: type
    }).done(function(data) {
      if (data.not_remove) {
        let message = I18n.t('work.is_release')
        if (data.status === 'effort') {
          if (type === 'client') {
            message = I18n.t('work.cannot_delete_client_with_effort', { company_name: companyName })
          } else {
            message = I18n.t('work.cannot_delete_supplier_with_effort', { company_name: companyName })
          }
        }
        Popup.alert(message)
      } else {
        ticket.showPopupConfirmRemoveCompany(modalEle, type)
      }
    })
  }

  removeCompanyOfTicketWithType(modalEle, type) {
    let companyClass;
    let url;
    const ticket = this
    if (type === 'client') {
      companyClass = 'company-received'
      url = Routes.remove_client_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId())
    } else {
      companyClass = 'company-sender'
      url = Routes.remove_supplier_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId())
    }
    const workId = modalEle.find(`select.${companyClass}`).attr('data-work-id');
    const companyId = modalEle.find(`select.${companyClass} option:selected`).val();
    const companyName = modalEle.find(`select.${companyClass} option:selected`).text();
    const workEle = $(`input[name="work[id][${workId}]"]`).parents('tr')
    if (!workId || !companyId) return
    Shared.callAjax(url, { work_id: workId, company_id: companyId })
    .done(function(data) {
      if (type == 'client') {
        workEle.find('input.work_order_received_id_received').val('')
        workEle.find('input.work_quotation_id').val('')
        workEle.find('input.company_ordered').val('')
      }
      if (data.quantity_remove_ids) {
        data.quantity_remove_ids.forEach(id => {
          workEle.find(`#list-work-quantity-${workId}`)
                 .find(`input[name="work_quantity[id][${id}]"]`).parents('tr.work-quantities').remove()
        })
      }
      Popup.confirm(`${companyName}が <br>チケットから解除されました。`, {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { ticket.save(workEle.find('input.description'), false, false, true) },
        cancel: () => { ticket.save(workEle.find('input.description'), false, false, true) }
      })

    })
    .fail(function(data) {
      if (data.responseJSON.message) {
        let message = I18n.t('shared.one_error_please_try_again')
        if (data.responseJSON.message === 'work_release') {
          message = I18n.t('work.is_release')
        } else if (data.responseJSON.message === 'client_submited') {
          message = `${companyName}への提出済作業が存在する為<br>解除できません。`
        } else if(data.responseJSON.message === 'supplier_submited') {
          message = `${companyName}からの提出済作業が存在する為<br>解除できません。`
        }
        Popup.alert(message)
        return
      }
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async deleteTicket(ele, workId, ticketName) {
    const ticket = this
    const url = Routes.check_effort_release_with_delete_ticket_owner_project_task_ticket_path(
      this._fetchProjectId(), this._fetchTaskId()
    )
    await Shared.callAjax(url, { work_id: workId }).done(function(data) {
      if (data.status) {
        showPopupDeleteTicketWithEffortRelease(ele, workId, ticketName, ticket)
      } else {
        showPopupDeleteTicket(ele, workId, ticketName, ticket)
      }
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async fetchOrderWithSupplier(projectId, workId) {
    const url = Routes.owner_project_ticket_fetch_orders_for_supplier_path(projectId, workId)
    const componentSelectQuotation = $('#modal-select-quotation-for-supplier')
    componentSelectQuotation.find('#work-id').val(workId)
    Shared.callAjax(url, {}).done(function(data) {
      const selectQuotationEle = componentSelectQuotation.find('.select-quotations-for-supplier')
      selectQuotationEle.empty()
      selectQuotationEle.append($('<option>', { value: '', text: '選択する' }))
      data.orders.forEach(order => {
        selectQuotationEle.append($('<option>', { value: order.id, text: order.name || order.id }))
      })
      $('#modal-select-quotation-for-supplier').modal('show')
      const quotationId = componentSelectQuotation.find('.select-quotations-for-supplier').val()
      if (quotationId) {
        componentSelectQuotation.find('.btn-show-detail-order').removeClass('d-none')
      } else {
        componentSelectQuotation.find('.btn-show-detail-order').addClass('d-none')
      }
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async fetchOrderWithClient(projectId, workId) {
    const url = Routes.owner_project_ticket_fetch_orders_for_client_path(projectId, workId)
    const componentSelectQuotation = $('#modal-select-quotation-for-client')
    componentSelectQuotation.find('#work-id').val(workId)
    Shared.callAjax(url, {}).done(function(data) {
      const selectQuotationEle = componentSelectQuotation.find('.select-quotations-for-client')
      selectQuotationEle.empty()
      selectQuotationEle.append($('<option>', { value: '', text: '選択する' }))
      data.orders.forEach(order => {
        selectQuotationEle.append($('<option>', { value: order.id, text: order.name || order.id }))
      })
      $('#modal-select-quotation-for-client').modal('show')
      const quotationId = componentSelectQuotation.find('.select-quotations-for-client').val()
      if (quotationId) {
        componentSelectQuotation.find('.btn-show-detail-order').removeClass('d-none')
      } else {
        componentSelectQuotation.find('.btn-show-detail-order').addClass('d-none')
      }
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  selectOrderWithClient(ele) {
    const componentSelectQuotation = $('#modal-select-quotation-for-client')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-client').val()
    if (quotationId) {
      componentSelectQuotation.find('.btn-show-detail-order').removeClass('d-none')
    } else {
      componentSelectQuotation.find('.btn-show-detail-order').addClass('d-none')
    }
  }

  selectOrderWithSupplier(ele) {
    const componentSelectQuotation = $('#modal-select-quotation-for-supplier')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-supplier').val()
    if (quotationId) {
      componentSelectQuotation.find('.btn-show-detail-order').removeClass('d-none')
    } else {
      componentSelectQuotation.find('.btn-show-detail-order').addClass('d-none')
    }
  }

  acceptSelectOrderWithSupplier(ele, projectId) {
    const componentSelectQuotation = $('#modal-select-quotation-for-supplier')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-supplier').val()
    const workId = componentSelectQuotation.find('#work-id').val()
    const ticket = this
    if (quotationId) {
      const url = Routes.owner_project_ticket_save_work_order_request_with_supplier_path(projectId, workId)
      Shared.callAjax(url, { order_id: quotationId }).done(function(data) {
        Popup.confirm(
          `${data.order_name}<br> の注文をチケットに紐づけました。<br> ${data.owner_name}が承認すると<br> 注文の紐づけが完了します。`,
          {
            confirmText: 'OK',
            cancelBtn: 'false',
            width: 'auto'
          }
        )
      })
      .fail(function(data) {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      })
    } else {
      componentSelectQuotation.modal('hide')
    }
  }

  acceptSelectOrderWithClient(ele, projectId) {
    const componentSelectQuotation = $('#modal-select-quotation-for-client')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-client').val()
    const workId = componentSelectQuotation.find('#work-id').val()
    const ticket = this
    if (quotationId) {
      const url = Routes.owner_project_ticket_save_work_order_with_client_path(projectId, workId)
      Shared.callAjax(url, { order_id: quotationId }).done(function(data) {
        Popup.alert(`注文ID:${quotationId}を 紐づけました。`)
        ticket.save($(`input[name="work[description][${workId}]"]`), false, false, true, false, true)
      })
      .fail(function(data) {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      })
    } else {
      componentSelectQuotation.modal('hide')
    }
  }

  async showDetailOrderOfSupplierForClient(ele) {
    const componentSelectQuotation = $('#modal-select-quotation-for-client')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-client').val()
    const workId = componentSelectQuotation.find('.work-id').val()
    const companyName = componentSelectQuotation.find('.company-name').val()
    const companyId = componentSelectQuotation.find('#client-select-owner').val()
    const order = await logWorkAction.fetchOrderOfTicket(workId, quotationId)
    this.renderTextForDetailOrderOfClient(order, companyId, companyName)
  }

  async showDetailOrderOfSupplierForSupplier(ele) {
    const componentSelectQuotation = $('#modal-select-quotation-for-supplier')
    const quotationId = componentSelectQuotation.find('.select-quotations-for-supplier').val()
    const workId = componentSelectQuotation.find('.work-id').val()
    const companyName = componentSelectQuotation.find('.company-name').val()
    const companyId = componentSelectQuotation.find('#supplier-select-owner').val()
    const order = await logWorkAction.fetchOrderOfTicket(workId, quotationId)
    this.renderTextForDetailOrderOfClient(order, companyId, companyName, 'supplier')
  }

  renderTextForDetailOrderOfClient(order, companyId, companyName, type = 'client') {
    const componentShowOrder = type === 'client' ? $('#showOrderDetailForClient') : $('#showOrderDetailForSupplier')
    const quotationPrice = `ID: ${order.id}  |  ${logWorkAction.addPeriod(order.amount)}  円`;
    let orderTypeText = order.is_create_by_quotation ? '見積書' : '発注書'
    componentShowOrder.find('.order-type').text(orderTypeText)
    componentShowOrder.find('.company-id').val(companyId)
    componentShowOrder.find('.company-order-name').text(companyName);
    componentShowOrder.find('.order-id').val(order.id);
    componentShowOrder.find('.order-amount').text(quotationPrice);
    componentShowOrder.find('.order-name').text(order.order_name);
    componentShowOrder.find('.order-contract-type').text(order.contract_type);
    componentShowOrder.find('.order-contract-sub-type')
                      .text(I18n.t(`quotation.contract_sub_type.${order.contract_sub_type}`));
    const timeBySelectOrder = logWorkAction._fetchTimeBySelectOrder(order)
    componentShowOrder.find('.time-by-order').text(timeBySelectOrder.orderTime);
    componentShowOrder.find('.title-time-by-order').text(timeBySelectOrder.titleOrderTime);
    componentShowOrder.modal('show')
  }

  backToSelectQuotationClient(ele) {
    $('#modal-select-quotation-for-client').modal('show')
  }

  backToSelectQuotationSupplier(ele) {
    $('#modal-select-quotation-for-supplier').modal('show')
  }

  showModalRequestWorkOrder() {
    $('#show_request_work_order').modal('show')
  }

  acceptRequestOrder(ele, requestOrderId, workId, orderId, orderName, supplierId, supplierName) {
    $('#show_request_work_order').modal('hide')
    const modalAcceptRq = $('#modal-accept-order-for-request')
    modalAcceptRq.find('.company-name').val(supplierName)
    modalAcceptRq.find('#supplier-id').val(supplierId)
    modalAcceptRq.find('#show-order-name').val(orderName)
    modalAcceptRq.find('#order-id').val(orderId)
    modalAcceptRq.find('#request-work-order-id').val(requestOrderId)
    modalAcceptRq.find('#work-id').val(workId)
    modalAcceptRq.modal('show')
  }

  async rejectRequestOrder(ele, projectId) {
    const modalShowRequest = $('#show_request_work_order')
    const modalAcceptRq = $('#modal-accept-order-for-request')
    const requestOrderId = modalAcceptRq.find('#request-work-order-id').val()
    const workId = modalAcceptRq.find('#work-id').val()
    const url = Routes.owner_project_ticket_reject_request_order_path(projectId, workId)
    const ticket = this
    await Shared.callAjax(url, { request_order_id: requestOrderId }, 'html').done(function(data) {
      const isError = ticket._checkErrorUpdateRequestOrder(data)
      if (isError) return
      ticket._showModalShowRequest(modalShowRequest, data)
    })
  }

  _showModalShowRequest(modalShowRequest, data) {
    modalShowRequest.find('#list-request-work-orders').html(data)
    const number = modalShowRequest.find('span.text-mineshaft').length
    if (number > 0) modalShowRequest.modal('show')
  }

  handleAcceptRequestOrder(ele, projectId) {
    const modalAcceptRq = $('#modal-accept-order-for-request')
    const requestOrderId = modalAcceptRq.find('#request-work-order-id').val()
    const workId = modalAcceptRq.find('#work-id').val()
    const orderId = modalAcceptRq.find('#order-id').val()
    const ticket = this
    Shared.callAjax(
      Routes.check_quantity_present_owner_project_task_ticket_path(projectId, logWorkAction._fetchTaskId()),
      { work_id: workId, order_id: orderId }
    ).done(function(data) {
      if (data.is_select_order) {
        if (data.is_show_popup) {
          Popup.confirm(
            I18n.t('work.confirm_select_order', {company_name: data.company_name, order_id: orderId}),
            {
              success: () => {
                ticket._handleApproveRequestOrder(projectId, workId, requestOrderId, true)
              },
              confirmText: I18n.t('shared.connect'),
              cancelText: I18n.t('shared.not_connect'),
              cancel: () => {
                ticket._handleApproveRequestOrder(projectId, workId, requestOrderId, false)
              },
              allowOutsideClick: false,
              allowEscapeKey: false,
            }
          )
        } else {
          ticket._handleApproveRequestOrder(projectId, workId, requestOrderId, false)
        }
      } else {
        Popup.alert(I18n.t('work.cannot_select_order'))
      }
    })
  }

  _checkErrorUpdateRequestOrder(data) {
    if (data.responseText === 'not_update') {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
      return true
    }
    return false
  }

  _handleApproveRequestOrder(projectId, workId, requestOrderId, is_append_order) {
    const ticket = this
    const url = Routes.owner_project_ticket_approve_request_order_path(projectId, workId)
    const modalShowRequest = $('#show_request_work_order')
    Shared.callAjax(
      url, { request_order_id: requestOrderId, is_append_order: is_append_order }, 'html'
    ).done(function(data) {
      ticket.save($(`input[name="work[description][${workId}]"]`), false, false, true, false, true)
      const isError = ticket._checkErrorUpdateRequestOrder(data)
      if (isError) return
      Popup.confirm('注文の紐づけが完了しました。', {
        cancelBtn: 'false',
        success: () => { ticket._showModalShowRequest(modalShowRequest, data) },
        cancel: () => { ticket._showModalShowRequest(modalShowRequest, data) },
        width: 'auto',
        confirmText: 'OK'
      })
    })
  }

  async showDetailOrderOfRequestOder(ele, projectId) {
    const componentSelectQuotation = $('#modal-accept-order-for-request')
    const workId = componentSelectQuotation.find('#work-id').val()
    const quotationId = componentSelectQuotation.find('#order-id').val()
    const companyName = componentSelectQuotation.find('.company-name').val()
    const companyId = componentSelectQuotation.find('#supplier-id').val()
    const order = await logWorkAction.fetchOrderOfTicket(workId, quotationId)
    this._renderTextForDetailRequestOrder(order, companyId, companyName)
  }

  _renderTextForDetailRequestOrder(order, companyId, companyName) {
    const componentShowOrder = $('#showOrderDetailForRequestOrder')
    const quotationPrice = `ID: ${order.id}  |  ${logWorkAction.addPeriod(order.amount)}  円`;
    let orderTypeText = order.is_create_by_quotation ? '見積書' : '発注書'
    componentShowOrder.find('.order-type').text(orderTypeText)
    componentShowOrder.find('.company-id').val(companyId)
    componentShowOrder.find('.company-order-name').text(companyName);
    componentShowOrder.find('.order-id').val(order.id);
    componentShowOrder.find('.order-amount').text(quotationPrice);
    componentShowOrder.find('.order-name').text(order.order_name);
    componentShowOrder.find('.order-contract-type').text(order.contract_type);
    componentShowOrder.find('.order-contract-sub-type')
                      .text(I18n.t(`quotation.contract_sub_type.${order.contract_sub_type}`));
    const timeBySelectOrder = logWorkAction._fetchTimeBySelectOrder(order)
    componentShowOrder.find('.time-by-order').text(timeBySelectOrder.orderTime);
    componentShowOrder.find('.title-time-by-order').text(timeBySelectOrder.titleOrderTime);
    componentShowOrder.modal('show')
  }

  backToAppendRequestOrder(ele) {
    $('#modal-accept-order-for-request').modal('show')
  }
  _saveOwnerProjectTaskTicket(paramsNotCheckOldWorkOrder, paramsWorkingOnTicketId, workOld, workSender, workReceived, ticketParams, totalWorkChild, appendToOrder, checkSave, copyTicket, workId, saveQuantity, workTrEle, is_not_update, isShowModalRemoveQuotation = false, type = 'updateTicket'){
    const projectId = this._fetchProjectId();
    const taskId = this._fetchTaskId();

    Shared.callAjax(Routes.save_owner_project_task_ticket_path(projectId, taskId) + '?' + location.href.split('?')[1] + paramsWorkingOnTicketId + paramsNotCheckOldWorkOrder, {
      work_old: workOld,
      work_order_sender: workSender,
      work_order_received: workReceived,
      work: ticketParams,
      total_work_child: totalWorkChild,
      save_quantity: saveQuantity,
      work_quantity_delete: $('.list-work-quantity-delete').val(),
      items_each_page: $('.select-items-each-page').val(),
      append_order_to_quantity: appendToOrder,
      copy_ticket: copyTicket,
      is_not_update: is_not_update
    }, 'json')
    .done(function(data){
      if (type !== 'updateTicket') {
        $('.invoice__table--main .invoice__payment--tbody.bg-light-gray').removeClass('bg-light-gray');
        window.location.href = Routes.owner_log_work_task_path(projectId, taskId) + `?is_create_new_ticket=true&new_work_id=${data.new_work}`;
        return;
      }

      $('#render-list-work').html(data.html);
      if (type !== 'updateTicket') SearchForm.filterCount('deselectAll');
      if(copyTicket === "true"){
        popupNotification(I18n.t('work.copy_ticket'));
        $(".tr_index.work-details:first").addClass('bg-light-gray not_reload')
      }else if (checkSave && !workId){
        popupNotification(I18n.t('work.save_ticket'));
      }else{};

      logWorkAction.runDatePicker();
      datepicker();
      defaultAction.runDatepicker();
      const ticket = new Ticket
      ticket.appendExpendTicket()
      $(document).find('div.modal-backdrop').remove()
      $(document).find('div.detail_popup').addClass('d-none');
      $('.preloader').fadeOut();
      workTrEle.find('.work_order_received_id_sender').val('')
      if(isShowModalRemoveQuotation) $('#doneRemoveQuotation').modal('show')
      if (workId && paramsWorkingOnTicketId) $(`#personInCharge${workId}`).modal('show')
      if(data.is_change_status) Popup.alert('Statusを更新しました。')
      let quantity_tickets = $('tbody.invoice__payment--tbody').length;
      $('table.search-list').find('span.quantity_tickets').html(quantity_tickets);
    })
    .fail(function(data) {
      if (data.error === 'changed_release') {
        popupConfirmReload(I18n.t('work.is_release'))
        return
      } else if (data.error === 'work_release') {
        Popup.alert(Shared.textWithTextServeResponse(I18n.t('work.is_release')))
        return
      } else if(data.error && data.error !== '{}') {
        Popup.alert(Shared.textWithTextServeResponse(data.error))
        if (typeof(element) !== 'undefined' && $(element).hasClass('accept-release-date')) logWorkAction._rollbackStatus($(element))
        return
      }
      newPopupConfirmReload(I18n.t('work.changed_refresh'))
    })
  }

  showHideSettingPic(element, projectID, taskId, workID) {
    const url = Routes.owner_project_task_get_list_participants_path(projectID, taskId)
    Shared.callAjax(url, { task_id: taskId, work_id: workID }, 'script', 'GET')
  }

  settingDeadlineDate(element) {
    $(".modal_expiration").modal('show')
  }

  ShowFileSize(ele, workId, fileSize) {
    Popup.confirm(`総容量：${fileSize}`, {
      cancelBtn: 'false',
      confirmText: 'OK',
      width: 'auto'
    })
  }

  updateDeadlineWork(ele, workId) {
    const ticket = this
    const trWork = $(`tbody[data-work-id="${workId}"]`).find('tr:first')
    const modalDeadline = trWork.find(`#modal_expiration_${workId}`)
    const componentExpirationDate = $(ele).parents('.modal-content')
    const expirationDateEle = modalDeadline.find('input.value-expiration-date');
    const expirationDate = componentExpirationDate.find('input.datetimepicker').val();
    const expirationTime = componentExpirationDate.find('input.datetimepicker-time').val();
    expirationDateEle.val(`${expirationDate}, ${expirationTime}`);
    ticket.save(trWork.find('input.description_new'));
  }

  showModalSelectWorkTargetClientOfListWork(ele) {
    $(ele).parents('tr').find('#select-work-target-of-client-list-work').trigger('click')
    this.closeModalShowWorkTarget(ele)
  }

  showModalSelectWorkTargetSupplierOfListWork(ele) {
    $(ele).parents('tr').find('#select-work-target-of-supplier-list-work').trigger('click')
    this.closeModalShowWorkTarget(ele)
  }

  closeModalShowWorkTarget(ele) {
    $(ele).parents('div.modal').modal('hide')
  }

  disableSorableTicket(modal_id) {
    logWorkAction.disableSortWork();
    $(modal_id).on('hide.bs.modal', function(){
      logWorkAction.enableSortWork();
    })
  }

  newTicket(event) {
    event.stopPropagation();
    $('#clone-btn').trigger('click')
    $(event.target).parents('div').css('display', '')
  }

  closeModalQuantityForSp(ele) {
    $(ele).parents('.z-1052').modal('hide')
  }

  submitFormSearchQuantity(ele) {
    Visiable.toggle($(ele).parents('div.filter-content'))
    this.closeModalQuantityForSp(ele)
    const form = $(ele).parents('form#search_for_quantity')
    const url = form.attr('action')
    const data =  form.serialize()
    const workId = form.find('#work-id-for-quantity').val()
    $(`#edit-status-efforts-for-work-${workId}`).addClass('opacity-blur')
    const companyId = form.find('input#company_id').val()
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const workEle = $(ele).parents('tr.tr_index')
    const modalHearder = $(ele).parents('.show-body-quantiy-of-work').find('div.head-work-quantity')
    const modalBody = $(ele).parents('div.modal-body')
    const modalQuantity = $(ele).parents(`div#modal-quantity-${workId}`)

    Shared.callAjax(url, data, 'html').done(function(data) {
      $(`#list-work-quantity-${workId}`).html(data);
      Shared.callAjax(
        Routes.fetch_max_quantity_owner_project_task_ticket_path(projectId, taskId),
        {
          work_id: workId,
          company_id: companyId,
        }
      )
      .done(function(data) {
        setValueQuantityByCompany(modalHearder, data)
        toggleElementWithCompany(data.is_hidden_add_quantity, modalBody, data.is_supplier)
        showQuantityByUnit(data.unit, modalQuantity)
        workEle.find('input.unit-work-for-company').val(data.unit)
      })
    }).fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  countNumberSearchQuantity(ele, workId) {
    const component = $(ele).parents(`#show-list-quantity-of-${workId}`)
    const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
    let number = Number($(spanText).text())
    if ($(ele).val() === '' || !$(ele).val()) {
      if (number > 0) {
        number -= 1
        $(ele).attr('is-counter', 'false')
      }
    } else {
      if ($(ele).attr('is-counter') === 'true') return
      number += 1
      $(ele).attr('is-counter', 'true')
    }
    let spanShow;
    let spanNumber;
    let numberDate = 0
    let allInput;
    if ($(ele).parents('td.td-filter-box').length > 0) {
      spanShow = $(ele).parents('td.td-filter-box').find('div.border-new').find('span.number')
      allInput = $(ele).parents('div.dropdown-body').find('input.date').toArray()
      const searchAll =
        $(ele).parents('table.search-list').find('td.show-search-common-all').find('div.dropdown-body')
      spanNumber =
          searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.created-at-filter', event)"]`).find('span.number').find('span.number')
    } else {
      const modalEle = $(ele).parents('div.created-at-filter')
      allInput = modalEle.find('input.date').toArray()
      spanShow =
        $(ele).parents('div.show-body-quantiy-of-work').find('div.search-list-for-sp').find('td.search-sp-for-created-at').find('span.number')
      spanNumber =
        $(`div[id="modalFilterQuantityCommonAll${workId}"]`).find(`div[data-target="#modalFilterCreatedAtQuantity${workId}"]`).find('span.number')
    }
    allInput.forEach(input => {
      if (!!$(input).val()) {
        numberDate += 1
      }
    })
    component.find(`.show-number-search-for-quantity-${workId}`).text(number)
    if (numberDate > 0) {
      spanShow.removeClass('d-none')
      spanShow.text(numberDate)
      spanNumber.removeClass('d-none')
      spanNumber.text(numberDate)
    } else {
      spanShow.addClass('d-none')
      spanShow.text('0')
      spanNumber.addClass('d-none')
      spanNumber.text('0')
    }
  }

  selectOptionModalNewSearch(elementSearch, value, element, workId) {
    const statusValue = $(element).parent().find(`${elementSearch}`).val();
    if (value === 'all') {
      let count = $(element).parents('div.option').find('div.slted').length
      $(element).parent().find('.option').removeClass('slted')
      $(element).parent().find(`${elementSearch}`).val(value);
      $(element).addClass('slted')
      const component = $(element).parents(`#show-list-quantity-of-${workId}`)
      const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
      let number = Number($(spanText).text())
      if(number > 0) {
        number -= count
        component.find(`.show-number-search-for-quantity-${workId}`).text(number)
      }
      this.reCounterNumberSearch(element, workId)
      return
    } else {
      $(element).parents('div.option').find('div[data-value-status-quantity="all"]').removeClass('slted')
      if (statusValue.includes('all')) {
        let newValue = statusValue.replaceAll('all', '')
        $(element).parent().find(`${elementSearch}`).val(newValue);
      }
      if (elementSearch.includes('_in')) {
        const statusValue = $(element).parent().find(`${elementSearch}`).val();
        if (statusValue.includes(value)) return
        $(element).parent().find(`${elementSearch}`).val(`${statusValue} ${value}`);
      } else {
        $(element).parent().find('.option').removeClass('slted')
        $(element).parent().find(`${elementSearch}`).val(value);
      }
    }
    $(element).addClass('slted')
    const isCounter = $(element).parents('div.option').attr('is-counter')
    const component = $(element).parents(`#show-list-quantity-of-${workId}`)
    const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
    let number = Number($(spanText).text())
    number += 1
    component.find(`.show-number-search-for-quantity-${workId}`).text(number)
    $(element).parents('.option').attr('is-counter', 'true')
    const allCheck = $(element).parents('div.option').find('div.option:not(.is-select-all)').toArray()
    let checkedAll = true
    allCheck.forEach(div => {
      if (!$(div).hasClass('slted')) {
        checkedAll = false
      }
    })
    if (checkedAll) {
      $(element).parents('div.option').find('.is-select-all').trigger('click')
    }

    this.reCounterNumberSearch(element, workId)
  }

  selectOptionModalNewSearchCreatedBy(elementSearch, value, element, workId) {
    const statusValue = $(element).parent().find(`${elementSearch}`).val();
    if (value === 'all') {
      let count = $(element).parents('div.option').find('div.slted').length
      $(element).parent().find('.option').removeClass('slted')
      $(element).parent().find(`${elementSearch}`).val(value);
      $(element).addClass('slted')
      const component = $(element).parents(`#show-list-quantity-of-${workId}`)
      const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
      let number = Number($(spanText).text())
      if(number > 0) {
        number -= count
        component.find(`.show-number-search-for-quantity-${workId}`).text(number)
      }
      this.reCounterNumberSearchCreatedBy(element, workId)
      return
    } else {
      $(element).parents('div.option').find('div[data-value-created-by-quantity="all"]').removeClass('slted')
      if (statusValue.includes('all')) {
        let newValue = statusValue.replaceAll('all', '')
        $(element).parent().find(`${elementSearch}`).val(newValue);
      }
      if (elementSearch.includes('_in')) {
        const statusValue = $(element).parent().find(`${elementSearch}`).val();
        if (statusValue.includes(value)) return
        $(element).parent().find(`${elementSearch}`).val(`${statusValue} ${value}`);
      } else {
        $(element).parent().find('.option').removeClass('slted')
        $(element).parent().find(`${elementSearch}`).val(value);
      }
    }
    $(element).addClass('slted')
    const isCounter = $(element).parents('div.option').attr('is-counter')
    const component = $(element).parents(`#show-list-quantity-of-${workId}`)
    const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
    let number = Number($(spanText).text())
    number += 1
    component.find(`.show-number-search-for-quantity-${workId}`).text(number)
    $(element).parents('.option').attr('is-counter', 'true')
    const allCheck = $(element).parents('div.option').find('div.option:not(.is-select-all)').toArray()
    let checkedAll = true
    allCheck.forEach(div => {
      if (!$(div).hasClass('slted')) {
        checkedAll = false
      }
    })
    if (checkedAll) {
      $(element).parents('div.option').find('.is-select-all').trigger('click')
    }

    this.reCounterNumberSearchCreatedBy(element, workId)
  }

  reCounterNumberSearchCreatedBy(element, workId) {
    if ($(element).parents('td.td-filter-box').length === 0) {
      const componenSp = $(element).parents('div.render-work-quantity-new').find('.search-list-for-sp')
      const spanNumber = componenSp.find('.filter-created_by-work-quantity-sp').find('span.number')
      const numberCheck = $(element).parents('.modal-body').find('div.slted').not('.is-select-all').length
      const divAll = $(`div[id="modalFilterQuantityCommonAll${workId}"]`)
      const spanOfAll = divAll.find(`div[data-target="#modalFilterCreatedByQuantity${workId}"]`).find('span.number')
      if (numberCheck > 0) {
        spanNumber.removeClass('d-none')
        spanNumber.text(numberCheck)
        spanOfAll.removeClass('d-none')
        spanOfAll.text(numberCheck)
      } else {
        spanNumber.addClass('d-none')
        spanNumber.text(0)
        spanOfAll.addClass('d-none')
        spanOfAll.text(0)
      }
      return
    }

    const showNumber = $(element).parents('td.td-filter-box').find('.border-new').find('span.number')
    const divAllCheck = $(element).parents('div.dropdown-body')
    const numberCheck = divAllCheck.find('div.slted').not('.is-select-all').length
    const searchAll =
      $(element).parents('table.search-list').find('td.show-search-common-all').find('div.dropdown-body')
    const spanNumber =
      searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.created-by-filter', event)"]`).find('span.number')
    if (numberCheck > 0) {
      showNumber.removeClass('d-none')
      showNumber.text(numberCheck)
      spanNumber.removeClass('d-none')
      spanNumber.text(numberCheck)
    } else {
      showNumber.addClass('d-none')
      showNumber.text('0')
      spanNumber.addClass('d-none')
      spanNumber.text('0')
    }
  }

  reCounterNumberSearch(element, workId) {
    if ($(element).parents('td.td-filter-box').length === 0) {
      const componenSp = $(element).parents('div.render-work-quantity-new').find('.search-list-for-sp')
      const spanNumber = componenSp.find('.filter-status-work-quantity-sp').find('span.number')
      const numberCheck = $(element).parents('.modal-body').find('div.slted').not('.is-select-all').length
      const divAll = $(`div[id="modalFilterQuantityCommonAll${workId}"]`)
      const spanOfAll = divAll.find(`div[data-target="#modalFilterStatusQuantity${workId}"]`).find('span.number')
      if (numberCheck > 0) {
        spanNumber.removeClass('d-none')
        spanNumber.text(numberCheck)
        spanOfAll.removeClass('d-none')
        spanOfAll.text(numberCheck)
      } else {
        spanNumber.addClass('d-none')
        spanNumber.text(0)
        spanOfAll.addClass('d-none')
        spanOfAll.text(0)
      }
      return
    }

    const showNumber = $(element).parents('td.td-filter-box').find('.border-new').find('span.number')
    const divAllCheck = $(element).parents('div.dropdown-body')
    const numberCheck = divAllCheck.find('div.slted').not('.is-select-all').length
    const searchAll =
      $(element).parents('table.search-list').find('td.show-search-common-all').find('div.dropdown-body')
    const spanNumber =
      searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.status-filter', event)"]`).find('span.number')
    if (numberCheck > 0) {
      showNumber.removeClass('d-none')
      showNumber.text(numberCheck)
      spanNumber.removeClass('d-none')
      spanNumber.text(numberCheck)
    } else {
      showNumber.addClass('d-none')
      showNumber.text('0')
      spanNumber.addClass('d-none')
      spanNumber.text('0')
    }
  }

  resetDataSearchQuantity(element, workId, type) {
    const component = $(element).parents(`#show-list-quantity-of-${workId}`)
    const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
    const searchAll =
      $(element).parents('table.search-list').find('td.show-search-common-all').find('div.dropdown-body')
    let spanNumber;
    let number = Number($(spanText).text())
    if (type === 'search_quantity') {
      number -= 1
    } else if (type === 'status_quantity') {
      const divStatus = $(element).parents('.status-filter')
      number -= divStatus.find('div.slted:not(.is-select-all)').length
      spanNumber =
        searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.status-filter', event)"]`).find('span.number')
    } else if (type == 'created_by') {
      const divStatus = $(element).parents('.created-by-filter')
      number -= divStatus.find('div.slted:not(.is-select-all)').length
      spanNumber =
        searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.status-filter', event)"]`).find('span.number')
    } else {
      const divCreated = $(element).parents('.created-at-filter')
      let count = 0
      divCreated.find('input.date').toArray().forEach(input => {
        if (!!$(input).val()) count += 1
      })
      number -= count
      spanNumber =
        searchAll.find(`div[onclick="SearchForm.selectFilterDisplay('toggle', '.created-at-filter', event)"]`).find('span.number')
    }
    if (number < 0 ) number = 0
    component.find(`.show-number-search-for-quantity-${workId}`).text(number)
    const spanShow = $(element).parents('td.td-filter-box').find('div.border-new').find('span.number')
    spanShow.addClass('d-none')
    spanNumber.addClass('d-none')
    spanNumber.text('0')
    spanShow.text('0')
    this.handleResetDataSearchQuantity(element)
    if ($(element).parents('.option')) {
      $(element).parents('.option').attr('is-counter', 'false')
    }
    component.find('input[is-counter]').attr('is-counter', 'false')
  }

  resetDataSearchQuantityCreated(ele, workId) {
    $(ele).parents('.created-at-filter').find('input.date').val('').trigger('change')
  }

  resetDataSearchAllQuantity(element, workId) {
    const component = $(element).parents(`#show-list-quantity-of-${workId}`)
    const form = $(element).parents('form')
    form.find('input[type="text"]').val('')
    form.find('input').val('')
    form.find('input[type="checkbox"]').prop('checked', false)
    form.find('.option').removeClass('slted')
    $(element).closest('.container.search').find('input').val('')
    $(element).closest('.container.search').find('.option').removeClass('slted')
    $(element).closest('.container.search').find('input[type="checkbox"]').prop('checked', false)
    form.find('span.number').text('0')
    $('.search-list-for-sp').find('span.number').text('0')
    $('.search-list-for-sp').find('span.number').addClass('d-none')
    form.find('span.number').addClass('d-none')
    form.find('input[is-counter]').attr('is-counter', 'false')
    component.find(`.show-number-search-for-quantity-${workId}`).text('0').removeClass('d-none')
  }

  handleResetDataSearchQuantity(element) {
    $(element).closest('.filter-content').find('input').val('')
    $(element).closest('.filter-content').find('input[type="checkbox"]').prop('checked', false)
    $(element).closest('.filter-content').find('.option').removeClass('slted')
    $(element).closest('.container.search').find('input').val('')
    $(element).closest('.container.search').find('.option').removeClass('slted')
    $(element).closest('.container.search').find('input[type="checkbox"]').prop('checked', false)
    $(element).closest('.filter-content').find('#type_id').val('id_cont').change()
    if ($(element).parents('.status-filter-search')) {
      $(element).parents('.status-filter-search').find('div.option-all').trigger('click')
    }
    if ($(element).parents('form.search-for-sp').length > 0) {
      $(element).parents('.container-new').find('div[data-value-status-quantity="all"]').trigger('click')
      $(element).parents('.container-new').find('div[data-value-created-by-quantity="all"]').trigger('click')
    }
  }

  changeInputSearchQuantity(ele, workId) {
    const component = $(ele).parents(`#show-list-quantity-of-${workId}`)
    const spanText = component.find(`.show-number-search-for-quantity-${workId}`)[0]
    let number = Number($(spanText).text())
    const isCouter = $(ele).attr('is-counter') === 'false'
    if ($(ele).val().length > 0 && isCouter) {
      number += 1
      $(ele).attr('is-counter', 'true')
    } else if ($(ele).val() === '') {
      $(ele).attr('is-counter', 'false')
      number -= 1
    }
    component.find(`.show-number-search-for-quantity-${workId}`).text(number)
  }

  resetAllDataSearchForQuantity(ele, workId) {
    const component = $(ele).parents(`#show-list-quantity-of-${workId}`)
    component.find('form').find('input:not(.company-id-for-quantity, .work-id-for-quantity-new)').val('')
    component.find('form').find('div.slted').removeClass('slted')
    component.find('form').find('div[is-counter="true"]').attr('is-counter', 'false')
    component.find(`.show-number-search-for-quantity-${workId}`).text(0)
  }

  showTextFortSortQuantity(ele, attribute, value) {
    let textAttribute = $(ele).parents('li.li-sort-list-quantity').find('div.sort_item').text().trim()
    let textValue = ''
    if (attribute === 'updated_at') {
      if (value === 'desc') {
        textValue = '新しい'
      } else {
        textValue = '古い'
      }
    } else {
      if (value === 'desc') {
        textValue = '降順'
      } else {
        textValue = '昇順'
      }
    }
    $(ele).parents('td.col-select-company-quantity').find('span.mr-1').text(`${textAttribute} (${textValue})`)
  }

  sortListQuantity(ele, workId, attribute, value = 'desc') {
    Visiable.toggle($(ele).parents('#sortBox'))
    this.showTextFortSortQuantity(ele, attribute, value)
    const form = $(ele).parents('form#search_for_quantity')
    const url = form.attr('action')
    const data =  form.serialize()
    const newDataSort = data + `&sort_by%5D${attribute}%5D=${value}`
    const ticket = this
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const workEle = $(ele).parents('tr.tr_index')
    const modalHearder = $(ele).parents('.show-body-quantiy-of-work').find('div.head-work-quantity')
    const modalBody = $(ele).parents('div.modal-body')
    const modalQuantity = $(ele).parents(`div#modal-quantity-${workId}`)
    this.closeModalQuantityForSp(ele)
    $(ele).parents('.sort_list').find('div.float-right').removeClass('active')
    $(ele).parents('.li-sort-list-quantity').find('div.float-right').addClass('active')
    $(`#edit-status-efforts-for-work-${workId}`).addClass('opacity-blur')
    const companyId = form.find('input#company_id').val()
    Shared.callAjax(url, newDataSort, 'html').done(function(data) {
      ticket.resetAllDataSearchForQuantity(ele, workId)
      $(`#list-work-quantity-${workId}`).html(data)
      Shared.callAjax(
        Routes.fetch_max_quantity_owner_project_task_ticket_path(projectId, taskId),
        {
          work_id: workId,
          company_id: companyId,
        }
      )
      .done(function(data) {
        setValueQuantityByCompany(modalHearder, data)
        toggleElementWithCompany(data.is_hidden_add_quantity, modalBody, data.is_supplier)
        showQuantityByUnit(data.unit, modalQuantity)
        workEle.find('input.unit-work-for-company').val(data.unit)
      })
    }).fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  async changeNumberPage(ele, totalObject) {
    const numberOfPage = Number(ele.value)
    const modalEle = ele.closest('div#personInChargeOfObject')
    const totalEle = Number(totalObject)
    const pagynate = await Shared.fetchPagynateWithJs(totalEle, numberOfPage)
    let listTr = modalEle.querySelectorAll('div.show-list-member-for-page')
    for (let i = 1; i <= pagynate; i++) {
      let numberEleOfPage = 0;
      if (numberEleOfPage === numberOfPage) {
        numberEleOfPage = 0
        continue
      }
      for (let x = 0; x < listTr.length; x ++) {
        listTr[x].style.display = 'none'
        $(listTr[x]).addClass('d-none')
        const isCalculator = listTr[x].getAttribute('calculator-page')
        if (numberEleOfPage < numberOfPage && !isCalculator) {
          listTr[x].setAttribute('calculator-page', 'true')
          listTr[x].setAttribute('data-page', i)
          numberEleOfPage += 1
        }
      }
    }
    for (let i = 0; i < listTr.length; i++) {
      listTr[i].removeAttribute('calculator-page')
    }
    const listPageOne = modalEle.querySelectorAll('div[data-page="1"]')
    for (let i = 0; i < listPageOne.length; i++) {
      listPageOne[i].style.display = '';
      $(listPageOne[i]).removeClass('d-none').addClass('d-flex')
    }
    const newPagy = await Shared.reRenderPagyNate(
      pagynate, 'TaskPic.prevPage(this)', `TaskPic.selectPage(this)`,
      `TaskPic.nextPage(this, '${pagynate}')`
    )
    document.getElementById('render-list-pagynate-of-member-project-author').innerHTML = newPagy
  }

  showModalPerPageSp(ele, idModal) {
    $(`#${idModal}`).modal('show');
  }
}

function showPopupDeleteTicketWithEffortRelease(ele, workId, ticketName, ticket) {
  const urlEffort = Routes.undelivered_works_efforts_path({ search_kind: 'work_id_cont', q: { kind_id: workId } })
  const urlEffortNotReleased =
    Routes.deleted_works_efforts_path({search_kind: 'work_delete_id_cont', q: { kind_id: workId } })
  Popup.confirm('', {
    description: `
    <div style="font-size: 14px;" class="font-weight-normal">
      <div class="text-center font-weight-bold" style="color: #FC0D0D">未納品作業が存在します。 </div><br>
      <span>
        チケットを削除すると、関連書類及び未納品作業データ以外は全て削除されます。 <br>
        <a class="text-underline font-weight-bold" target="_blank" href="${urlEffort}">未納品作業一覧ページ</a>より未納品作業をご確認の上、削除を行ってください。<br>
        チケット削除後、10日間は<a class="text-underline font-weight-bold" target="_blank" href="${urlEffortNotReleased}">削除一覧ページ</a>より未納品作業のStatus変更が可能です。<br>
        チケット削除後、10日を超過すると未納品作業のStatus変更は出来ません。 <br>
        ※チケットと紐づいている書類は削除されません。書類管理から削除できます。
      </span>
    </div>
  `,
    confirmText: '削除する',
    width: '40rem',
    success: () => {
      ticket.delete(workId, ele)
    }
  })
}

function showPopupDeleteTicket(ele, workId, ticketName, ticket) {
  Popup.confirm(`「${ticketName}」を削除します。ファイル内のデータが全て削除されます。<br> 復元できません。削除してよろしいですか？`, {
    confirmText: 'OK',
    success: () => {
      ticket.delete(workId, ele)
    }
  })
}

function setCompanyNameToButton(button, trSelected) {
  const companyName = $(trSelected).find('.company-name').text()
  const companyType = $(trSelected).find('.company-type').text()
  button.find('.show-company-name').text(`${companyName}`)
  button.find('.show-company-type').text(`${companyType}`)
  if ($('button.select-company-on-sp').length > 0) {
    $('button.select-company-on-sp').find('.show-company-name').text(`${companyName}`)
    $('button.select-company-on-sp').find('.show-company-type').text(`${companyType}`)
  }
}

function setValueQuantityByCompany(modalHearder, data) {
  modalHearder.find('.total-hours').text(data.total_hour_quantity)
  modalHearder.find('.total-number-quantity').text(data.total_of_list_quantity)
  modalHearder.find('.total-minutes').text(data.total_minutes_quanitty)
  modalHearder.find('select#select-unit-of-quantity').val(data.unit)
  if (data.max_quantity) {
    modalHearder.parents('.show-body-quantiy-of-work').find('input.max-quantity').val(data.max_quantity)
    modalHearder.find('input.max-quantity').val(data.max_quantity)
  } else {
    modalHearder.parents('.show-body-quantiy-of-work').find('input.max-quantity').val('')
    modalHearder.find('input.max-quantity').val('')
  }
  if (data.is_change_max_quantity) {
    modalHearder.find('input.max-quantity').removeAttr('readonly')
  } else {
    modalHearder.find('input.max-quantity').attr('readonly', true)
  }
  if (data.is_change_unit) {
    modalHearder.find('#select-unit-of-quantity option').toArray().forEach(option => {
      $(option).removeAttr('disabled')
    })
  } else {
    modalHearder.find('#select-unit-of-quantity').find('option').toArray().forEach(option => {
      $(option).attr('disabled', true)
    })
  }
}

function saveUnitOfCompany(workId, projectId, taskId, type) {
  Shared.callAjax(
    Routes.save_unit_for_company_owner_project_task_ticket_path(projectId, taskId),
    {
      work_id: workId,
      unit: type
    }
  )
  .done(function(data) {})
  .fail(function() {
    Popup.alert(I18n.t('shared.one_error_please_try_again'))
  })
}

function showQuantityByUnit(orderType, element) {
  let selectEle = element.find("select#select-unit-of-quantity[data-is-owner='true'][data-is-sp='true']");
  if (orderType == 'by_time') {
    Visiable.show($(element).find('.table-input-effort'))
    selectEle.css('width', '60px');
    selectEle.parent().css('max-width', '95px')
    $(element).removeClass('type_time type_number').addClass('type_time')
    // $(element).find('.text-unit-quantity').text(I18n.t('work.limited_to_time'))
    $(element).find('.text-unit-quantity').text('時間')
    Visiable.hide(element.find('.number-quantity'));
    Visiable.show($(element).find('.time-quantity'))
    Visiable.toggle(element.find('div.work-time'), false);
    Visiable.toggle(element.find('div.quantity-work'), true);
    $(element).find('.list-time-title').attr('colspan', 4)
    $(element).find('.text-quantity-title').text(I18n.t('by_time'))
    $(element).find('.col-title-quantity').removeClass('col-xl-3 col-3').addClass('col-xl-4 col-4');
  } else {
    $(element).removeClass('type_time type_number').addClass('type_number')
    selectEle.css('width', '');
    selectEle.parent().css('max-width', '')
    Visiable.show($(element).find('.table-input-effort'))
    // $(element).find('.text-unit-quantity').text(I18n.t('work.limited_to_number'))
    $(element).find('.text-unit-quantity').text('個')
    Visiable.show(element.find('.number-quantity'));
    Visiable.hide($(element).find('.time-quantity'))
    Visiable.toggle(element.find('div.work-time'), true);
    Visiable.toggle(element.find('div.quantity-work'), false);
    $(element).find('.list-time-title').attr('colspan', 2)
    $(element).find('.text-quantity-title').text(I18n.t('by_number'))
    $(element).find('.col-title-quantity').removeClass('col-xl-4 col-4').addClass('col-xl-3 col-3')
  }
  if (orderType == 'other') {
    Visiable.hide($(element).find('.table-input-effort'))
    $(element).find('.text-quantity-title').text(I18n.t('other'))
  }
  logWorkAction._resideComponentByOrderType($(element), orderType);
  if (element.find('.list-work-quantity').find('.work-quantities').length > 0) {
    element.find('.list-work-quantity').find('.th-quantities')
           .find('.cell').removeClass('border-bottom-2-cerulean-blue');
    element.find('.list-work-quantity').find('.work-quantities:last')
           .removeClass('border-bottom-2-cerulean-blue')
  }
}

function toggleElementWithCompany(isHidden, modal, isSupplier) {
  Visiable.toggle($(modal).find('.work-quantity-clone').parent(), isHidden)
  Visiable.toggle($(modal).find('button.confirm-work-quantity'), isHidden || isSupplier)
  Visiable.toggle($(modal).find('div.cc-table').find('div.work-quantity-clone'), isHidden || isSupplier)
  if (isHidden || isSupplier) {
    $(modal).find('div.cc-table').find('div.work-quantity-clone').removeClass('d-flex')
  } else {
    $(modal).find('div.cc-table').find('div.work-quantity-clone').addClass('d-flex')
  }
}

function renderTicket(workEle, work, work_quantities, work_order_sender, work_order_received, company_orders) {
  const allTicket = workEle.parents('tbody').find('tr:not(.d-none)');
  workEle.attr('data-old-work', JSON.stringify(work));
  workEle.attr('data-old-work-order-sender', JSON.stringify(work_order_sender));
  workEle.attr('data-old-work-order-received', JSON.stringify(work_order_received));
  workEle.find('.work_id').val(work.id);
  workEle.find('.select_status').val(work.status);
  workEle.find('.input_status').val(work.status);
  workEle.find('.mb-work-status').val(work.status);
  workEle.find('.before-status').val(work.status);
  workEle.find('.description').val(work.description);
  workEle.find('.mb-description').val(work.description);
  allTicket.find('td.work-target').attr('data-company-name', company_orders.company_order_received_name || '')
  allTicket.find('td.work-target').attr('data-company-received-name', company_orders.company_order_sender_name || '')
  workEle.find('.name-company-order-received').text(`販売先 : ${company_orders.company_order_sender_name }`);
  workEle.find('.name-company-order-sender').text(`仕入先 : ${company_orders.company_order_received_name }`);
  if (work_order_received) {
    allTicket.find('.work_order_received_id_received').val(work_order_received.id);
    workEle.find('.work_order_received_id_received').val(work_order_received.id || '')
    workEle.find('.work_quotation_id').val(work_order_received.order_id || '');
    workEle.find('.company_ordered').val(work_order_received.company_sender_quotation || '');
    changeColorWithWorkOrder(work_order_received, 'received', workEle)
  } else {
    workEle.find('.img-quotation-received').removeClass('bg-emperor bg-thunderbird').addClass('bg-silver')
  }
  if (work_order_sender) {
    allTicket.find('.work_order_received_id_sender').val(work_order_sender.id);
    workEle.find('.work_order_received_id_sender').val(work_order_sender.id || '');
    workEle.find('.quotation_sender').val(work_order_sender.order_id || '');
    workEle.find('.company_received_order').val(work_order_sender.company_received_quotation || '');
    changeColorWithWorkOrder(work_order_sender, 'sender', workEle)
  } else {
    workEle.find('.img-quotation-sender').removeClass('bg-emperor bg-thunderbird').addClass('bg-silver')
  }
  workEle.find('.person_incharge_id').val(work.person_in_charge_id);
  workEle.find('.select_unit_work').val(work.unit || '');
  workEle.find('.render_unit_work').val(work.unit || '');
  workEle.find('.show-quantity').text(work.quantity);
  workEle.find('.max-quantity').val(work.max_quantity);
  workEle.find('.expiration-date').val(work.expiration_date || '');
  workEle.find('.input_delivery_date').val(work.delivery_date || '');
}

function changeColorWithWorkOrder(work_order, type, workEle) {
  const classColor = type === 'sender' ? 'img-quotation-sender' : 'img-quotation-received'
  if (work_order.order_id) {
    workEle.find(`.${classColor}`).removeClass('bg-silver bg-emperor').addClass('bg-thunderbird')
  } else if (work_order.company_received_quotation && work_order.company_sender_quotation) {
    workEle.find(`.${classColor}`).removeClass('bg-silver bg-thunderbird').addClass('bg-emperor')
  } else {
    workEle.find(`.${classColor}`).removeClass('bg-emperor bg-thunderbird').addClass('bg-silver')
  }
}

function datepicker(){
  $(function () {
    $('input.datetimepicker').datetimepicker({
      format: 'YYYY/MM/DD',
      locale: 'ja',
      useCurrent: false
    })
    $('input.datetimepicker-time').datetimepicker({
      format: 'HH:mm',
      locale: 'ja',
      useCurrent: false
    })
  });
};

// add function reload page not conflict code old
// function add button cancel and not action in click cancel
function newPopupConfirmReload(message){
  Popup.confirm(message, {
    success: () => { location.reload() },
    cancel: () => {},
    confirmText: I18n.t('work.reload_page'),
    width: '35rem'
  })
}

function popupConfirmReload(message) {
  Popup.confirm(message, {
    cancelBtn: 'false',
    success: () => { location.reload() },
    cancel: () => { location.reload() }
  })
}

function popupNotification(text, start = 'success'){
  Popup.confirm(text,{
    timer: 3000,
    cancelBtn: 'false',
    successBtn: 'false',
    icon: start
  })
}
