import React,{Component} from 'react';
import { Button, Modal, ModalHeader } from 'reactstrap';

export default class Alert extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isShow: false,
      };
    }
 render() {
  const { open, handleChange, content } = this.props;
  return (
    <div className="wrap-alert">
      <Modal isOpen={open} wrapClassName="wrap-alert">
        <ModalHeader ></ModalHeader>
           <p>{content}</p> 
          <Button color="primary" onClick={handleChange}>Ok</Button>{' '}
      </Modal>
    </div>
  );
    }
}
