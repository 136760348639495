export default class CompanyProfilePublicationStatus {
  onClickApprovalCheckbox(el,event) {
    event.preventDefault()
    const companyId = $(el).attr("id")
    const isPublic = $(el).attr("data-is_public")
    const textMessage = isPublic === 'true' ? 'この会社を非公開してもよろしいでしょうか？' : 'この会社を公開してもよろしいでしょうか？'
    $("#togglePublicationStatusConfirmModal #ok-btn").attr("data-id", companyId)
    $("#togglePublicationStatusConfirmModal #cancel-btn").attr("data-id", companyId);
    $("#togglePublicationStatusConfirmModal #content-message").text(textMessage);
  }

  onClickRemoveRequest(el, event, company_status) {
    event.preventDefault()

    const companyId = $(el).attr("data-company-id")
    const textMessage = company_status === 'request_public' ? '一般公開申請を削除します。よろしいですか。' : '一般公開を解除します。よろしいですか。'
    $("#toggleRemoveRequestConfirmModal #ok-btn").attr("data-id", companyId)
    $("#toggleRemoveRequestConfirmModal #cancel-btn").attr("data-id", companyId);
    $("#toggleRemoveRequestConfirmModal #content-message").text(textMessage);
  }

  toggle(el) {
    const company_id = $(el).attr("data-id");

    function toggleCheckbox(checkboxId) {
      const elementId = `#${checkboxId}`;
      if ($(elementId).attr("checked") === "checked") {
        $(elementId).removeAttr("checked")
      } else {
        $(elementId).attr("checked")
      }
    }

    $.ajax({
      url: `/admins/companies/${company_id}/update_profile_publication_status`,
      type: "POST",
      dataType: "json",
      headers: {
        "X-Transaction": "POST Example",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      success: function (data) {
        toggleCheckbox(company_id)
        toastr.success(data.message);
        location.reload();
      },
      error: function (data) {
        toggleCheckbox(company_id)
        toastr.error(data.message);
        location.reload();
      },
    });
  }

  submitRemoveRequest(el) {
    const company_id = $(el).attr("data-id");

    $.ajax({
      url: `/admins/companies/${company_id}/remove_public_request`,
      type: "DELETE",
      dataType: "json",
      success: function (data) {
        location.reload();
      },
      error: function (data) {
        location.reload();
      },
    });
  }

  abort(el) {
    const company_id = $(el).attr("data-id");
    this.toggleCheckbox(company_id)
  }
}
