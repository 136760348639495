import React from 'react';
import IconDelete from 'images/trash3.png'

const TableUrlToCapture = ({ urls, handleDeleteUrl, changeUrl, getAccountByDomain, addUrl, handleCaptureUrls, isDisableCapture, isProcessing }) => (
  <div className=''>
    <div className='d-flex text-center justify-content-center align-items-center mt-2 mx-3 border-1px-black'>
      <div className='w-80'>
        <div className='text-left mb-1 d-flex align-item-center' onClick={addUrl}>
          <button className='btn btn-mint plus plus-icon p-0 text-center add-url-file' type='button'>
            +
          </button>
          <span className='text-black fs-14 ml-1 text-underline cursor-pointer'>URLを追加する</span>
        </div>
        <div className='show-table-files'>
          <table className='w-100 table table-bordered mb-1'>
            <thead className='bg-black w-100 text-white sticky-top'>
              <tr>
                <th width="10%">削除</th>
                <th className='text-center'>URL</th>
              </tr>
            </thead>
            <tbody className=''>
              {urls.map((item, index) => (
                <tr key={index}>
                  <td style={{padding: '1px'}}>
                    <img className='filter-black-icon cursor-pointer' width={15} src={IconDelete}
                         onClick={() => handleDeleteUrl(item, index)} />
                  </td>
                  <td className='text-left' style={{padding: '0px'}}>
                    <input className='input-url-multiple' type='text'
                      value={item} onChange={(event) => changeUrl(event, index)}
                      onBlur={(event) => getAccountByDomain(event, item)} placeholder='URLを入力してください。'/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className='text-center create-akaire-box-content my-3'>
      <button type='button' className='btn btn-primary' disabled={isDisableCapture && !isProcessing} onClick={(event) => handleCaptureUrls(event)}>キャプチャする</button>
    </div>
  </div>
);

export default TableUrlToCapture;
