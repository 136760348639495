export default class Supplier {
  checkDeleteSupplier(supplierId) {
    const url = Routes.check_destroy_supplier_companies_path()
    Shared.callAjax(url, { supplier_id: supplierId }, 'html').done(function(data) {
      $('#render-content-with-remove-supplier').html(data)
      $('#show-modal-for-delete-supplier').modal('show')
    })
  }

  removeSupplier(supplierId) {
    const url = Routes.owner_delete_supplier_path(supplierId)
    Shared.callAjax(url, {}, 'json', 'delete').done(function(data) {
      Popup.confirm('', {
        description: `${data.company_name}<br>との取引を解除しました。`,
        cancelBtn: 'false',
        confirmText: 'OK',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    })
    .fail(function(data) {
      Popup.alert(data.responseJSON.message)
    })
  }

  checkDeleteClient(clientId) {
    const url = Routes.check_destroy_client_companies_path()
    Shared.callAjax(url, { client_id: clientId }, 'html').done(function(data) {
      $('#render-content-with-remove-client').html(data)
      $('#show-modal-for-delete-client').modal('show')
    })
  }

  removeClient(clientId) {
    const url = Routes.owner_delete_client_path(clientId)
    Shared.callAjax(url, {}, 'json', 'get').done(function(data) {
      Popup.confirm('', {
        description: `${data.company_name}<br>との取引を解除しました。`,
        cancelBtn: 'false',
        confirmText: 'OK',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    })
    .fail(function(data) {
      Popup.alert(data.responseJSON.message)
    })
  }

  checkDeleteCollaborator(collabId) {
    const url = Routes.check_destroy_collaborator_companies_path()
    Shared.callAjax(url, { collab_id: collabId }, 'html').done(function(data) {
      $('#render-content-with-remove-collaborator').html(data)
      $('#show-modal-for-delete-collaborator').modal('show')
    })
  }

  removeCollaborator(collabId) {
    const url = Routes.owner_delete_collaborator_path(collabId)
    Shared.callAjax(url, {}, 'json', 'delete').done(function(data) {
      Popup.confirm(`${data.company_name}<br>との取引を解除しました。`, {
        cancelBtn: 'false',
        confirmText: 'OK',
        success: () => { location.reload() },
        cancel: () => { location.reload() }
      })
    })
    .fail(function(data) {
      Popup.alert(data.responseJSON.message)
    })
  }
}
