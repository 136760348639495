export default class VisitContact {
  checkValue(element, bindElement) {
    const $element = $(element)
    if ($element.val() === '訪問') {
      $(bindElement).attr('disabled', !$element.is(':checked'))
    }
  }

  async validationForm(e) {
    e.preventDefault();
    const form = $(e.target).parents('form')
    const inputs = form.find('input:not(:hidden)').toArray()
    const isNumberError = await this._handleValidation(inputs)
    if (isNumberError > 0) {
      return
    } else {
      form.submit();
    }
  }

  _handleValidation(inputs) {
    let isNumberError = 0;

    inputs.forEach(input => {
      if ($(input).attr('type') === 'text' && $(input).attr('name') !== 'company[company_profile_attributes][url]') {
        $(input).val($.trim($(input).val()))
        if ($(input).val() === '') {
          Shared.errorFieldMessage($(input), I18n.t('shared.require_present'))
          isNumberError += 1;
        }
      } else if ($(input).attr('type') === 'number') {
        isNumberError += this._validateInputNumber($(input))
      } else if ($(input).attr('type') === 'checkbox') {
        isNumberError += this._validateInputCheckBox($(input))
      } else if ($(input).attr('type') === 'radio' &&
                 $(input).parents('.work-type-check').find('input:radio:checked').length === 0) {
        $('.checkmark').css({ 'border-block-color': 'red'})
       Shared.runFunctionSetTimeout(() => { $('.checkmark').removeAttr('style') })
        isNumberError += 1;
      }
    })
    if ($('#company_company_profile_attributes_career').val() === '') {
      Shared.errorFieldMessage($('#company_company_profile_attributes_career'), I18n.t('shared.require_present'))
      isNumberError += 1;
    }
    return isNumberError;
  }

  disabledForm() {
    const isEditEle = $('#is-update-company');
    const form = isEditEle.parents('form');
    form.find('#registration_tax_number_new').attr('disabled', true);
    form.find('.input_registration_tax_number').attr('disabled', true);
    if (isEditEle.val() === 'true') return
    form.find('input:not(#company_name):not(#company_name_alias):not(.no-disabled)').attr('disabled', true)
    form.find('select').attr('disabled', true)
    form.find('input[type="checkbox"]').attr('disabled', true)
    form.find('input[type="radio"]').attr('disabled', true)
    form.find('textarea').attr('readonly', true)
  }

  _validateInputCheckBox(input) {
    let isError = 0
    if ($(input).attr('name') === 'company[company_profile_attributes][type_of_company][]' &&
        $(input).parents('.type_of_company').find('input:checkbox:checked').length === 0) {
      $('input[name="company[company_profile_attributes][type_of_company][]"]').addClass('errors-field')
      Shared.runFunctionSetTimeout(
        () => { $('input[name="company[company_profile_attributes][type_of_company][]"]').removeClass('errors-field') }
      )
      isError += 1;
    } else if ($(input).attr('name') === 'company[company_profile_attributes][contact_information_collection][]') {
      if ($(input).parents('.contact_information_collection').find('input:checkbox:checked').length > 0) {
        if ($(input).val() === I18n.t('shared.visit') && $(input).is(':checked')) {
          const arr = $('select[name="company[prefecture_ids][]"]').val();
          if (Array.from(arr).length == 0){
            $('span.select2').addClass('errors-field');
            Shared.runFunctionSetTimeout(() => { $('span.select2').removeClass('errors-field') })
            isError += 1;
          }
        }
      } else {
        $('input[name="company[company_profile_attributes][contact_information_collection][]"]').addClass('errors-field')
        Shared.runFunctionSetTimeout(
          () => { $('input[name="company[company_profile_attributes][contact_information_collection][]"]').removeClass('errors-field') }
        )
        isError += 1;
      }
    }
    return isError;
  }

  _validateInputNumber(input) {
    let isError = 0;
    if ($(input).val() === '') {
      Shared.errorFieldMessage($(input), I18n.t('shared.require_present'));
      isError += 1;
    } else {
      if ($(input).attr('name') === 'company[company_profile_attributes][postal_code]') {
        var expression = /^[0-9]{7}$/;
        var regex = new RegExp(expression);
        if (!$(input).val().match(regex)) {
            Shared.errorFieldMessage($(input), I18n.t('shared.format_poster_code'));
            isError += 1;
        }
      } else {
        if ($(input).val().length < 10 || $(input).val().length > 11) {
            Shared.errorFieldMessage($(input), I18n.t("errors.messages.phone_number_validate"));
            isError += 1;
        }
      }
    }
    return isError;
  }
}
