export default class CheckList {
  showListQaOfCheckList(checkListId, workId, projectId) {
    const url = Routes.messages_of_check_list_owner_project_check_list_work_index_path(projectId)
    Shared.callAjax(url, { work_id: workId, check_list_id: checkListId }, 'html').done(function(data) {
      if (['work_blank', 'check_list_blank'].includes(data)) {
        Popup.alert(I18n.t('work.not_check_list'))
        return
      }
      if (data === 'message_blank') {
        Popup.alert('このアイテムに関する投稿がありません。')
        return
      }
      const modal = $('#show_list_chat_of_check_list')
      modal.find('#id-show-modal-of-chat-check-list').html(data)
      modal.modal('show')
    })
  }

  changeBtnAddCheckList(ele) {
    Disabled.toggle(
      $(ele).parents('div').find('.btn-add-checklist'), $(ele).val() === '' || $(ele).val() === undefined
    )
  }

  showPopupCheckListDelete(ele) {
    Popup.alert('チェックリストが削除されました。')
  }

  showChangeNameCheckList(checkListId, checkListName) {
    $(`#modal-show-change-checklist-${checkListId}`).find('input').val(checkListName)
    $(`#modal-show-change-checklist-${checkListId}`).modal('show');
  }

  changeChecklistName(ele, checkListId, workId, projectId) {
    const newCheckListName = $(`#modal-show-change-checklist-${checkListId}`).find('input').val().trim()
    if (!newCheckListName) {
      Popup.alert('空白にはできません')
      return
    }
    const modalCheckList = $(`#checkListModal-${workId}`)
    const url = Routes.change_name_owner_project_check_list_work_index_path(projectId)
    Shared.callAjax(url, {
      work_id: workId,
      check_list_id: checkListId,
      name: newCheckListName,
    }, 'html').done(function(data) {
      if (data === 'not_changed') {
        Popup.confirm(I18n.t('work.not_check_list'), {
          cancelBtn: 'false',
          success: () => { Shared.reloadCurrentPage() },
          cancel: () => { Shared.reloadCurrentPage() },
          confirmText: 'OK',
          width: 'auto'
        })
        return
      }
      modalCheckList.find('#render_check_list_work').html(data)
      logWorkAction._calculateCheckList(modalCheckList)
      $(`#modal-show-change-checklist-${checkListId}`).modal('hide')
      const modalBacDrop = $('body').find('div.modal-backdrop').length
      if (modalBacDrop > 1) {
        $('body').find('div.modal-backdrop:last').remove()
      }
    })
  }
}
