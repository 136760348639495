export default class Ticket {
  async deleteQuantity(ele, quantityId, projectId, taskId, workId) {
    let isShowDelete;
    let isSubmitted;
    let companyName;
    await Shared.callAjax(
      Routes.check_delete_work_quantity_owner_project_task_ticket_path(
        projectId, taskId), {
      work_id: workId,
      work_quantity_id: quantityId
    })
    .done(function(data) {
      isShowDelete = data.is_show_delete
      isSubmitted = data.is_submitted
      companyName = data.company_name
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
    if (isShowDelete) {
      this.handleRemoveQuantity(quantityId)
    } else {
      const text = isSubmitted ? `仕入先作業を解除にします。<br>よろしいですか?` : I18n.t('work.do_you_reject_quantity_not_submited', { company_name: companyName })
      Popup.confirm(text, {
        confirmText: 'OK',
        success: () => this.rejectStatusQuantity(quantityId),
        cancel: () => {}
      })
    }
  }

  cancelRejectQuantity(quantityId) {
    Popup.confirm(I18n.t('work.cancal_request_remove_quantity'), {
      confirmText: 'OK',
      success: () => this.actionCancelRequestDeleteQuantity(quantityId),
      cancel: () => {}
    })
  }

  actionCancelRequestDeleteQuantity(quantityId) {
    const url = Routes.cancel_rejected_work_efforts_path()
    Shared.callAjax(url, { quantity_id: quantityId }).done(function(data) {
      location.reload();
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  handleRemoveQuantity(quantityId) {
    Popup.confirm(I18n.t('work.do_you_want_delete_quantity'), {
      confirmText: 'OK',
      success: () => this.actionRemoveQuantity(quantityId),
      cancel: () => {}
    })
  }

  actionRemoveQuantity(quantityId) {
    const url = Routes.destroy_work_efforts_path()
    this.actionRemoveOrReject(url, quantityId)
  }

  rejectStatusQuantity(quantityId) {
    const url = Routes.reject_work_efforts_path()
    this.actionRemoveOrReject(url, quantityId)
  }

  async actionRemoveOrReject(url, quantityId) {
    await Shared.callAjax(url, {
      quantity_id: quantityId
    }).done(function(data) {
      location.reload();
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  confirmReleaseEffort(event, effortId, ticketHasClient, effortOfOwner) {
    event.preventDefault();
    const url = Routes.get_client_name_efforts_path();
    const data = { quantity_id: effortId }
    let text = ''
    Shared.callAjax(url, data).done(function(result) {
      if (result.is_submited) {
        text += `${result.client_name}へ納品されます。`
      } else {
        if (ticketHasClient) {
          text += `${result.client_name}へ納品されます。`
        }
        if (ticketHasClient && !effortOfOwner) {
          text += `<br>`
        }
        if (!effortOfOwner) {
          text = `${result.supplier_name}へ納品されます。`
        }
      }
      Popup.confirm(`<p class="text-white font-weight-normal">${text}<br>よろしいですか？</p>`, {
        success: () => {
          WorkQuantity.showPopupReleaseEffort(effortId, ticketHasClient, effortOfOwner, result.is_submited)
        },
        confirmText: 'OK',
        cancelText: 'キャンセル',
        width: 'auto',
      })
    })
  }

  showPopupReleaseEffort(effortId, ticketHasClient, effortOfOwner, is_submited) {
    const modal = $('#releaseEffort');
    modal.find('#input-effort-id').val(effortId)
    if (ticketHasClient && (effortOfOwner || is_submited)) {
      modal.find('div#input-delivery-date-of-client').show();
    } else {
      modal.find('div#input-delivery-date-of-client').hide();
    }
    if (ticketHasClient && !effortOfOwner) {
      modal.find('div#input-delivery-date-of-client').addClass('mt-3')
    }
    if (effortOfOwner) {
      modal.find('div#input-delivery-date-of-supplier').hide();
    } else {
      modal.find('div#input-delivery-date-of-supplier').show();
    }
    modal.modal('show')
  }

  handleReleaseEffort(event) {
    event.preventDefault();
    const $ele = $(event.target);
    const $modal = $ele.parents('#releaseEffort');
    const inputClientDeliveryDate = $modal.find('input[name="work_quantity[client_delivery_date]"]')
    const inputSupplierDeliveryDate = $modal.find('input[name="work_quantity[supplier_delivery_date]"]')
    const clientDeliveryDate = inputClientDeliveryDate.val();
    const supplierDeliveryDate = inputSupplierDeliveryDate.val();
    const effortId = $modal.find('input[name="work_quantity[id]"]').val()

    if (!clientDeliveryDate && !supplierDeliveryDate) {
      inputClientDeliveryDate.addClass('is-invalid')
      inputSupplierDeliveryDate.addClass('is-invalid')
    } else {
      inputClientDeliveryDate.removeClass('is-invalid')
      inputSupplierDeliveryDate.removeClass('is-invalid')
      const url = Routes.release_efforts_path()
      const data = {
        client_delivery_date: clientDeliveryDate,
        supplier_delivery_date: supplierDeliveryDate,
        quantity_id: effortId
      }
      Shared.callAjax(url, data).done(function(result) {
        $modal.modal('hide')
        Popup.confirm('納品にしました。', {
          width: 'auto',
          cancelBtn: 'false',
          confirmText: 'OK'
        })
        inputClientDeliveryDate.val('')
        inputSupplierDeliveryDate.val('')
        $(`td#td_quantity_status_${effortId}`).empty().append(result.html)
      })
    }
  }

  confirmDoNotRelease(event, effortId, ticketHasClient, effortOfOwner) {
    event.preventDefault();
    const url = Routes.get_client_name_efforts_path();
    const data = { quantity_id: effortId }
    let text = ''
    Shared.callAjax(url, data).done(function(result) {
      if (result.is_submited) {
        text += `${result.client_name}へ納品されません。`
      } else  {
        if (ticketHasClient) {
          text += `${result.client_name}へ納品されません。`
        }
        if (ticketHasClient && !effortOfOwner) {
          text += `<br>`
        }
        if (!effortOfOwner) {
          text = `${result.supplier_name}へ納品されません。`
        }
      }
      Popup.confirm(`<p class="text-white font-weight-normal">${text}<br>よろしいですか？</p>`, {
        success: () => {
          Shared.callAjax(Routes.do_not_release_efforts_path(), data).done(function(result) {
            $(`td#td_quantity_status_${effortId}`).empty().append(result.html)
          })
        },
        confirmText: 'OK',
        width: 'auto',
      })
    })
  }
}
