import toastr from 'toastr'

const PUBLICATION_STATUS = {
  nonpublic: "nonpublic",
  request_public: "request_public",
  is_public: "is_public"
}

$(document).ready(function() {
  // function getParameterByName(name, url = window.location.href) {
  //   name = name.replace(/[\[\]]/g, '\\$&')
  //   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //     results = regex.exec(url)
  //   if (!results) return null
  //   if (!results[2]) return ''
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '))
  // }
  // const requestPublicSuccess = getParameterByName('request_public') === 'success'
  // if (requestPublicSuccess)
})

export default class ProfilePage {
  submitPortfolios(){
    let amountInvalidPortfolios = 0;

    amountInvalidPortfolios = this.invalidVideo(amountInvalidPortfolios)

    var checked = 0;

    $('#portfolio-form').find('input[type="checkbox"]').each(function(e){
      if ($(this).is(':checked')){
        checked++
      }
    })

    if (checked == 0){
      amountInvalidPortfolios++;
      $('#portfolio-form').find('input[type="checkbox"]').each(function(e){
        if ($(this).is(':checked')){
          $('#portfolio-form').submit();
        }else{
          $("#category-error").text("空白にはできません")
          setTimeout(() => $("#category-error").text(''), 5000)
        }
      })
    }

    if (amountInvalidPortfolios == 0) {
      $('#portfolio-form').submit();
    }
    else{
      toastr.error("入力した情報を再確認してください", "", {
        closeButton: true,
        progressBar: true,
      })
    }
  }

  validate(e, formElement) {
    const formValue = $(formElement).serializeJSON();
    const contactInformation = formValue.company.company_profile_attributes.contact_information_collection
    const companyName = formValue.company.name
    const phoneNumber = formValue.company.company_profile_attributes.phone_number
    const address = formValue.company.company_profile_attributes.address
    const postalCode = formValue.company.company_profile_attributes.postal_code
    const career = formValue.company.company_profile_attributes.career
    const postalCodeRegex =/(^[0-9]+$)/;
    const companyType = $('#js-value-company-type')

    const workType = formValue.company.company_profile_attributes.work_type
    const typeOfCompany = formValue.company.company_profile_attributes.type_of_company
    const prefecture_ids = formValue.company.prefecture_ids
    let amountInvalidCompanyProfile = 0

    if (address.length == 0)
    {
      amountInvalidCompanyProfile++
      $('#address-error').text('空白にはできません')
    }
    else {
      $('#address-error').text('')
    }

    if (companyName.length == 0)
    {
      amountInvalidCompanyProfile++
      $('#name-error').text('空白にはできません')
    }
    else {
      $('#name-error').text('')
    }

    if(phoneNumber.length == 0) {
      amountInvalidCompanyProfile++
      $('#phone-number-error').text('空白にはできません')
    }
    else if (phoneNumber.length >= 10 && phoneNumber.length <= 11 )
    {
      $('#phone-number-error').text('')
    }
    else {
      amountInvalidCompanyProfile++
      $('#phone-number-error').text('スペース, ハイフン等無しで10桁or11桁の正の整数のみを許可されています')
    }

    if (postalCode.length == 0)
    {
      amountInvalidCompanyProfile++
      $('#postal-code-error').text('空白にはできません')
    }
    else if (!postalCodeRegex.test(postalCode))
    {
      amountInvalidCompanyProfile++
      $('#postal-code-error').text('数字を入力してください')
    }
    else {
      $('#postal-code-error').text('')
    }

    const url = '/company/info/update_company_profiles'

    if (contactInformation && contactInformation.length === 1 && contactInformation[0] === '')
    {
      amountInvalidCompanyProfile++
      $('#profile-form--contact-information-error').text('打合わせ種類は選んでください')
      e.stopPropagation()
      e.preventDefault()
    }
    else {
      $('#profile-form--contact-information-error').text('')
    }

    if (contactInformation && contactInformation.includes("訪問") && (prefecture_ids == undefined || (prefecture_ids.length == 1 && prefecture_ids[0] == '')))
    {
      amountInvalidCompanyProfile++
      $('#prefectures-error').text('空白にはできません')
      e.stopPropagation()
      e.preventDefault()
    }

    if (companyType.val() == '')
    {
      amountInvalidCompanyProfile++
      $('#profile-form--type-of-company-error').text(I18n.t('errors.messages.blank'))
      e.stopPropagation()
      e.preventDefault()
    }
    else {
      $('#profile-form--type-of-company-error').text('')
    }

    if (typeOfCompany == '')
      {
        amountInvalidCompanyProfile++
        $('#profile-form--type-of-company-error').text('空白にはできません')
        e.stopPropagation()
        e.preventDefault()
      }
    else {
      $('#profile-form--type-of-company-error').text('')
    }

    if (workType == '')
    {
      amountInvalidCompanyProfile++
      $('#work-type-error').text('空白にはできません')
      e.stopPropagation()
      e.preventDefault()
    }
    else {
      $('#work-type-error').text('')
    }

    if (career == '')
    {
      amountInvalidCompanyProfile++
      $("#career-error").text("空白にはできません")
      e.stopPropagation()
      e.preventDefault()
    }
    else {
      $("#career-error").text("")
    }

    if(amountInvalidCompanyProfile == 0)
    {
      const callAjax = Shared.callAjax(url, new FormData($(formElement)[0]), 'json', 'POST', true, true)
      callAjax.done(function(data) {

        if (data.success == 'success')
        {
          window.location.assign('/company/portfolios?op_mode=true')
        }
        if (data.success == 'hide_modal') {
          $('#companyProfileModal').modal('hide')
        }

      }).fail(function(data) {
        toastr.error('データフィールドを再確認する', "", {
          closeButton: true,
          progressBar: true,
        })
      })
    }
    else{
      toastr.error('データフィールドを再確認する', "", {
        closeButton: true,
        progressBar: true,
      })
    }
  }

  validate_without_opmode(e, formElement, show_request_public_info_form) {
    const formValue = $(formElement).serializeJSON();
    const career = formValue.company.company_profile_attributes.career
    const workType = formValue.company.company_profile_attributes.work_type
    const typeOfCompany = formValue.company.company_profile_attributes.type_of_company
    const taxNumber = formValue.company.registration_tax_number
    const inputCheckTaxNumber = $(formElement).find('input#registration_tax_number_new')
    let amountInvalidCompanyProfile = 0

    if (show_request_public_info_form) {
      if (workType == '')
        {
          amountInvalidCompanyProfile++
          $('#work-type-error').text('空白にはできません')
        }
      else {
        $('#work-type-error').text('')
      }

      if (typeOfCompany == '')
        {
          amountInvalidCompanyProfile++
          $('#profile-form--type-of-company-error').text('空白にはできません')
        }
      else {
        $('#profile-form--type-of-company-error').text('')
      }

      if (career == '')
        {
          amountInvalidCompanyProfile++
          $("#career-error").text("空白にはできません")
        }
      else {
        $("#career-error").text("")
      }
    }

    if (!taxNumber && inputCheckTaxNumber.is(':checked')) {
      amountInvalidCompanyProfile++
      const inputTaxNumber = $(formElement).find('input.input_registration_tax_number')
      inputTaxNumber.addClass('is-invalid');
      inputTaxNumber.val('').attr('placeholder', '空白にはできません');
      setTimeout(function () {
        inputTaxNumber.removeClass('is-invalid');
      }, 5000);
    }

    if(amountInvalidCompanyProfile == 0) {
      $(formElement).submit()
    }
  }

  changeUsePaymentFunction(element) {
    let usePaymentValue = $(element).is(':checked') ? 1 : 0;
    let pathNames = window.location.pathname.split('/').slice(0, 3);
    pathNames.push('update_use_payment_function');
    const url = pathNames.join('/');
    const callAjax = Shared.callAjax(url, { 'use_payment_function': usePaymentValue });

    callAjax.done(function(data) {
      Popup.alert(data.success, 'success')
    })
    callAjax.fail(function(data) {
      Popup.alert(data.error, 'error')
    })
  }

  onOpenPublic(){
    $('#flgOpenPublic').addClass('modal-open-public')
    const url = new URL(window.location.href)
    if(!url.search){
      const params = url.searchParams;
      if(params.get('op_mode') == null){
        history.replaceState('', '', url.pathname+'?op_mode=true')
      }
    }
  }

  closeOpenPublic(){
    // $('#flgOpenPublic').removeClass('modal-open-public')
    const url = new URL(window.location.href)
    const reload_url = url.origin + '/company/info'
    location.replace(reload_url)
  }

  cancelOpenPublic(){
    return this.updatePublicationStatus(PUBLICATION_STATUS.nonpublic)
  }

  updatePublicationStatus(publicationStatus) {
    let pathNames = window.location.pathname.split('/').slice(0, 3)
    pathNames.push('update_publication_status')
    const url = pathNames.join('/')
    const data = { publication_status: publicationStatus }
    const callAjax = Shared.callAjax(url, data, 'json', 'PUT')

    callAjax.done(function(data) {
      window.location.reload()
    }).fail(function(data) {
      Popup.alert(data.responseJSON.error, 'error')
    })
  }
}
