import toastr from 'toastr';

export default class PortfolioPage {
  submitPortfolios(e) {
    let amountInvalidPortfolios = 0;

    amountInvalidPortfolios = this.invalidVideo(amountInvalidPortfolios)
    const companyType = $(".company-video").attr("data-type")
    const companyVideoFile = $("#company_video_file").val()
    const checkHasVideo = $(".company-video").attr("data-has-video")

    var checked = 0;

    $('#portfolio-form').find('input[type="checkbox"]').each(function(e){
      if ($(this).is(':checked')){
        checked++
      }
    })

    if(companyType == 'individual' && checkHasVideo == "false") {
      if(companyVideoFile == '') {
        amountInvalidPortfolios++;
        $('#input-video-error').text('空白にはできません')
        e.stopPropagation()
        e.preventDefault()
        setTimeout(() => $("#input-video-error").text(''), 5000)
      } else {
        $('#input-video-error').text('')
      }
    }

    if (checked == 0){
      amountInvalidPortfolios++;
      $('#portfolio-form').find('input[type="checkbox"]').each(function(e){
        if ($(this).is(':checked')){
          $('#portfolio-form').submit();
        }else{
          $("#category-error").text("空白にはできません")
          setTimeout(() => $("#category-error").text(''), 5000)
        }
      })
    }

    if (amountInvalidPortfolios == 0) {
      $('#portfolio-form').submit();
    }
    else{
      toastr.error("入力した情報を再確認してください", "", {
        closeButton: true,
        progressBar: true,
      })
    }
  }

  reIndexPortfolio() {
    let $clonePortfolio = $('.clone-portfolio')
    Visiable.hide('.dropdown-divider')
    $clonePortfolio.each(function(index, ele){
      $(ele).find('h2').html(`実績 ${index + 1}）`);
      $(ele).find('.del-clone').html(`実績${index + 1}を削除する`);
      Visiable.show($(ele).prev('.dropdown-divider'))
    });
    Visiable.toggle($('.clone-portfolio .del-clone'), $clonePortfolio.length <= 1)
    defaultAction.run()
  }

  isPortfolioValid(ele) {
    let isInvalidPortfolio = 0;
    const inputPortfolios = $(ele).find('[data-required="true"]');
    const startDate = $(ele).find('input.start_date');
    const endDate = $(ele).find('input.end_date');
    const inputUrls = $(ele).find('input[type="url"]');
    let errorInputs = []
    $.each(inputPortfolios, (index, input) => {
      if ($(input).val() === '') {
        errorInputs.push([input, I18n.t('shared.require_present')])
        isInvalidPortfolio++
      }
    })

    inputUrls.toArray().forEach(inputUrl => {
      if (!Input.validateUrl(inputUrl)) {
        errorInputs.push([inputUrl, I18n.t('shared.url_is_incorrect')])
        isInvalidPortfolio++
      }
    })

    if (isInvalidPortfolio === inputPortfolios.length) return true

    if (startDate.val() !== '' && endDate.val() !== '' && startDate.val() > endDate.val()) {
      errorInputs.push([endDate, I18n.t('shared.start_date_less_than_end_date')])
      isInvalidPortfolio++
    }

    errorInputs.forEach(errorInput => this.checkValidation(errorInput[0], errorInput[1]))

    return isInvalidPortfolio === 0
  }

  checkValidation(element, message) {
    const $errorElement = $(element).next('p.text-danger')
    const $errorInputVideo = $(element).find('p#input-video')
    $(element).val('')
    $errorElement.text(message)
    $errorInputVideo.text(message)
    setTimeout(() => $errorElement.text(''), 5000)
    setTimeout(() => $errorInputVideo.text(''), 5000)
  }

  validateVideo(element, bindElement) {
    Input.validateFile(
      $(element), '', /(\.mp4|\.mov|\.wmv|\.AVI)$/i, 100242880,
      {
        errorMessageExtensions: I18n.t('shared.portfolio_file_video'),
        errorMessageSize: I18n.t('shared.file_oversize_upload')
      }
    )

    Input.changeLabel($(element) , $(bindElement))
    $(".delete-video-icon").removeClass("d-none")
    $("#delete-video-flag").val("")
    $(".company-video").attr("data-has-video", "true")
    $(element).parents('td').find('.hidden-video-name').val($(element).val())
  }

  openConfirmDeleteVideoModal() {
    $("#confirm-delete-video-file").modal("show")
  }

  deleteVideo() {
    $("#company_video_file").val(null)
    $(".video-file--name").text("ファイルが選択されていません")
    $(".delete-video-icon").addClass("d-none")
    $("#confirm-delete-video-file").modal("hide")
    $(".company-video").attr("data-has-video", "false")
    $("#delete-video-flag").val("true")
  }

  validateFile(element, bindElement) {
    Input.validateFile (
      $(element), '', /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.xls|\.xlsx|\.mp4|\.wmv|\.avi|\.mov|\.csv|\.psd|\.ai|\.mp3|\.ppt|\.pptx|\.zip|\.draw|\.ait|\.png|\.jpg|\.gif)$/i, 100242880,
      {
        errorMessageExtensions: I18n.t('shared.portfolio_file_types'),
        errorMessageSize: I18n.t('shared.file_oversize_upload')
      }
    )
    Input.changeLabel($(element) , $(bindElement))
    $(element).parents('td').find('.hidden-video-name').val($(element).val())
  }

  invalidVideo(amountInvalidPortfolios) {
    const typeOfCompany =  $('.company-video').data('type')
    if ( $('.video-file').attr('value') === 'false' && typeOfCompany == 'individual') {
      if( $('.video-file')[0].files[0] === undefined ) {
        this.checkValidation('.video-file--name', I18n.t('shared.require_present'))
        return amountInvalidPortfolios += 1
      }
    }

    return amountInvalidPortfolios

  }

  popupVideo() {
    Popup.confirm('', {
      description: I18n.t('shared.popup_company_video'),
      iconHtml: '<i class="fas fa-info"></i>',
      confirmText: 'OK',
      cancelBtn: 'false',
      width: '46em',
      success: () => {
        $('#portfolio-form').submit();
      }
    })
  }
}
