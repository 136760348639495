$(document).ready(function() {
  $('body').on('click', 'a.download-image', function(e) {
    e.preventDefault();
    console.info('run download file')
    //Store user entered value
    const userUrl =  $(this).attr('href');
    const filename = $(this).attr('download');
    // window.open(userUrl, '_blank').focus();
    saveFile(userUrl, filename);
  })

  function saveFile(url, filename = 'download') {
    // Get file name from url.
    if (!url) {
      console.error("Error downloading file url emtpy")
    }
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
      a.download = filename; // Set the file name.
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click()
      a.remove()
    };
    xhr.open('GET', url);
    xhr.send();
  }
})
