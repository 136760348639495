export default class ProjectInfo {
  detail(url){
    $.ajax({
      method: 'GET',
      url: url,
      dataType: 'html',
    }).done( (res) => {
      $('#modal-project').html(res)
      $('.modal_contents').fadeIn();
    })
  }
  hide(){
    $('.modal_contents').fadeOut();
    return false;
  }
}
