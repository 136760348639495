export default class User {
  constructor() { this.isSubmitChangeSuccess = false; }

  removeUserWhenUnapproved(event, userId, isRegisterKeyCode = '') {
    event.preventDefault();
    const url = Routes.remove_user_when_unapproved_owner_user_path(userId)
    Shared.callAjax(url, {}).done(function(result) {
      let message = '';
      if (result.isUnapprovedStatus) {
        message = `${result.user_name}さんからの <br>承認申請を却下しました。`
      } else {
        message = '招待を解除しました。'
      }
      if (isRegisterKeyCode === 'register_key_code') {
        message = '申請を解除しました。'
      }
      Popup.confirm(message, {
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto',
        success: () => { window.location.reload(); }
      })
    }).fail(function() {
      Popup.alert(I18n.t('shared.not_authorized_to_access_content'))
    })
  }

  handleDeleteMember(event, userId, companyName, mainAdmin, unapprovedStatus) {
    event.preventDefault();
    if (unapprovedStatus) {
      return
    }
    if (mainAdmin) {
      Popup.alert('メイン担当者は解除できません。')
      return;
    }
    Popup.confirm(`${companyName}のメンバー権限を解除すると
                  全データの閲覧およびアクセス権限がなくなります。
                  解除してよろしいですか？`,
      {
        confirmText: '解除する',
        cancelText: 'キャンセル',
        width: 'auto',
        success: () => { User.deleteMember(userId) }
      })
  }

  static deleteMember(userId) {
    const url = Routes.owner_user_path(userId)
    Shared.callAjax(url, {}, 'json', 'delete').done(function(result) {
      if (result.delete_your_self) {
        window.location.href = Routes.owner_goodbye_path()
        return;
      }
      if (!!result.one_member_projects) {
        User.hasOnlyUserLeftInProjects(result)
        return;
      }
      Popup.confirm(`${result.user_name}さんをメンバー権限から解除しました。`, {
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto',
        success: () => { window.location.href = Routes.owner_users_path(); }
      })
    }).fail(function(result) {
      Popup.alert(result.responseJSON.message)
    })
  }

  clickBtnChooseUploadAvatar() {
    event.preventDefault();
    $('input#avatar').trigger('click');
  }

  static hasOnlyUserLeftInProjects(data) {
    let projectList = ''
    data.one_member_projects.forEach(function(project) {
      projectList += `・${project.id}:　${project.name}<br>`
    })
    Popup.confirm(
      `<div class='text-center font-weight-normal h5 black-text'><p>解除するとプロジェクトに自社のメンバーが0名になる為、解除できません。</p><p>${projectList}</p><p>上記のすべてプロジェクトから解除したいメンバーを削除すると、 解除が可能になります。</p></div>`,
      {
        success: () => {},
        cancelBtn: 'false',
        confirmText: 'OK',
        width: 'auto'
      }
    )
  }

  approvalUser(userId, userName) {
    const userModel = this
    const url = Routes.owner_users_update_approve_status_path()
    Shared.callAjax(url, { user_id: userId }, 'json').done(function(data) {
      userModel._reloadPageWithMessage(`${userName}さんが<br>自社メンバーになりました。`)
    })
    .fail(function(data) {
      userModel._reloadPageWithMessage(I18n.t('shared.one_error_please_try_again'))
    })
  }

  sendApprovalUser(userId, userName) {
    const userModel = this
    const url = Routes.owner_users_send_mail_approve_path()
    Shared.callAjax(url, { user_id: userId }, 'json').done(function(data) {
      if (data.is_resend_mail) {
        userModel._reloadPageWithMessage(`申請メールを再送信しました。`)
      } else {
        userModel._reloadPageWithMessage(`${userName}さんからの <br>承認申請を許可しました。`)
      }
    })
    .fail(function(data) {
      userModel._reloadPageWithMessage(I18n.t('shared.one_error_please_try_again'))
    })
  }

  resendEmailInviteMember(userId) {
    const userModel = this
    const url = Routes.owner_users_resend_mail_invite_member_path()
    Shared.callAjax(url, { user_id: userId }, 'json').done(function(data) {
      userModel._reloadPageWithMessage(`招待メールを再送信しました。`)
    })
    .fail(function(data) {
      userModel._reloadPageWithMessage(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _reloadPageWithMessage(message) {
    Popup.confirm(message, {
      cancelBtn: 'false',
      confirmText: 'OK',
      success: () => { location.reload() },
      cancel: () => { location.reload() }
    })
  }

  editUser(ele) {
    const isErrorValid = this.validateFormUserEdit()
    if (!isErrorValid) return
    const form = $(ele).parents('form.edit_user')
    const url = form.attr('action')
    const dataForm = new FormData(form[0])
    Shared.callAjax(url, dataForm, 'json', 'post', true, true).done((data) => {
      this.isSubmitChangeSuccess = true;

      Popup.confirm(data.message, {
        success: () => { Shared.reloadCurrentPage() },
        confirmText: 'OK',
        cancelBtn: 'false',
        cancel: () => { Shared.reloadCurrentPage() },
        width: 'auto'
      })
    })
    .fail((data) => {
      this.isSubmitChangeSuccess = false;
      Popup.alert(data.responseJSON.message)
    })
  }

  validateFormUserEdit() {
    let submit_flag = true;
    var fields_user = this.fetchAllFieldsUser()
    for (var i = 0; i < fields_user.length; i++) {
        var field_user = $("[name='" + fields_user[i] + "']");
        if (field_user.is('input[type="text"]')) {
            field_user.val($.trim(field_user.val()))
            if (field_user.val() === '') {
                Shared.errorFieldMessage(field_user, '空白にはできません', true);
                submit_flag = false;
            }
            if (field_user.attr('name') === 'user[name]') {
              if (Shared.checkValidationUserName(field_user.val())){
                Shared.errorFieldMessage(field_user, '', false);
                field_user.val($('form.edit_user').find('#user_name_origin').val());
                Popup.alert('「氏名」に利用できない文字があります。<br>氏名を変更してください。')
                submit_flag = false;
              }
            }
        } else if (field_user.is('input[type="password"]')) {
            if (field_user.val().length > 32) {
                Shared.errorFieldMessage(field_user, '英・数を各1文字以上で最低8文字以上32文字以下入力してください。E.g: a1A1ayy6', true);
                submit_flag = false;
            } else {
                if (field_user.val() === '') {
                    Shared.errorFieldMessage(field_user, '空白にはできません', true);
                    submit_flag = false;
                } else {
                    var expression_for_pass = /(?=.*[a-zA-Z])(?=.*\d).{8,32}/
                    var regex_pass = new RegExp(expression_for_pass);
                    if (!(field_user.val().match(regex_pass))) {
                        Shared.errorFieldMessage(field_user, '英・数を各1文字以上で最低8文字以上32文字以下入力してください。E.g: a1A1ayy6', true);
                        submit_flag = false;
                    } else {
                        if ((field_user.val() !== $('input[name="user[password]"]').val()) && (!field_user.is($('input[name="user[password]"]')))) {
                            Shared.errorFieldMessage(field_user, 'パスワードは一致していません', true);
                            submit_flag = false;
                        }
                    }
                }
            }
        }
    }

    var user_email = $('input[name="user[email]"]');
    if (user_email.val() === '') {
        Shared.errorFieldMessage(user_email, '空白にはできません', true);
        submit_flag = false;
    } else {
        let expression_for_email = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
        let regex_email = new RegExp(expression_for_email)
        if (!(user_email.val().match(regex_email))) {
            Shared.errorFieldMessage($('input#user_email'), I18n.t('admin.attributes.email.invalid'), true);
            Popup.alert('メールアドレスが正しくありません。')
            submit_flag = false;
        }
    }
    return submit_flag
  }

  fetchAllFieldsUser() {
    var allFields_user = [];
    var allInputs = $('.edit_user').find('.user');
    allInputs.each(function(index, element) {
        var fieldName_user = $(element).attr('name');
        if (jQuery.inArray(fieldName_user, allFields_user) === -1 && fieldName_user !== undefined && $(element).attr('required') !== undefined) {
            allFields_user.push(fieldName_user);
        }
    });
    return allFields_user;
  }

  checkBeforeLogin(element) {
    event.preventDefault();
    const $ele = $(element);
    const form = $ele.parents('form');
    const email = form.find('input[name="user[email]"]').val()
    const password = form.find('input[name="user[password]"]').val()
    if (!!email) {
      Shared.callAjax(Routes.owner_akapon_first_login_path(), { email: email, password: password }, 'json', 'get')
      .done(function(result) {
        if (result && result.show_popup_force_read_term) {
          $('button#btn-show-force-read-term').click();
        } else {
          form.submit();
        }
      })

    }
  }

  hidePopupWarningTicketForAkapon() {
    Shared.callAjax(Routes.hide_popup_warning_ticket_akapon_owner_users_path())
    .done(function() {
      $('#errorModalForTicketWithCheckbox').modal('hide')
    })
  }

  hidePopupNotifyAboutAutoSettingChanged() {
    Shared.callAjax(Routes.hide_popup_notify_auto_setting_effort_owner_users_path())
  }

  chooseImageUpload(userId, deleteIconUrl) {
    const REGEX_FILE_EXTENSIONS = /(\.jpg|\.gif|\.png|\.jpeg)$/i;
    if (!Input.validateFileType(event.currentTarget, REGEX_FILE_EXTENSIONS, "PNG・GIF・JPGファイルがアップロード可能")) {
      $('input#avatar').val("");
      return;
    }

    let formData = new FormData();
    formData.append("file", event.target.files[0]);

    const callAjax = Shared.callAjax(
      Routes.upload_avatar_owner_user_path(userId),
      formData, 'json', 'POST', true, true
    );

    callAjax.done(function(data){
      $('div.user-avatar div.td p').remove();
      $("div[class^='js-project_document_']").remove();
      $("div#filename").remove();
      $('input#avatar').val("");

      $("#btn-choose-file").after(
        `<div id="filename" class="ml-1 d-inline-block">` +
          `${data.avatar[0].name}` +
          `<img class="ml-1 mr-3" onclick="User.deleteAvatar(${userId})" src="${deleteIconUrl}" width="20" height="20">` +
        `</div>`
      );

      $('div.user-avatar div.td').append(
        `<div class="js-project_document_${data.avatar[0].id} d-flex align-items-center">` +
          `<a class="download-image" download="${data.avatar[0].name}" href="${data.avatar[0].file.url}">` +
            `<img alt="" class="show_image image_project_document" src="${data.avatar[0].file.url}">` +
          `</a>` +
        `</div>`
      );
      Popup.alert("顔写真がアップされました。");
    });

    callAjax.fail(function(){
      $('input#avatar').val("");
      Popup.alert("顔写真のアップロードが失敗しました。");
    });
  }

  deleteAvatar(userId) {
    Popup.confirm('顔写真を削除します。よろしいですか？', {
      success: () => {
        const callAjax = Shared.callAjax(Routes.delete_avatar_owner_user_path(userId));

        callAjax.done(function(data){
          $("div[class^='js-project_document_']").remove();
          $("<p class='small' style='margin-left: 15px;'>※PNG・GIF・JPGファイルがアップロード可能</p>").insertAfter("#filename");
          $("#filename").remove();
          $('input#avatar').val("");
          Popup.alert("顔写真が削除されました。");
        });

        callAjax.fail(function(){
          $('input#avatar').val("");
          Popup.alert("顔写真の削除が失敗しました。");
        });
      },
      cancel: () => {},
      confirmText: 'OK',
      cancelText: 'キャンセル',
      width: 'auto',
    })
  }

  readAllNoticeNews() {
    Shared.callAjax(Routes.readed_all_news_path()).done(function () { location.reload() })
  }

  selectOptionModal(type, value, element){
    const parentElement = $(element).parent()
    parentElement.find('.option-all').removeClass('slted')
    $(element).addClass('slted')
    if(type === 'role') {
      parentElement.find('#q_order_by_role').val(value);
      if (value === '0') {
        parentElement.find('.option:not(.option-all)').removeClass('slted')
        return
      }
      let checkedAll = true;
      let uncheckAll = true;
      parentElement.find('.option:not(.option-all)').each(function(){
        if (!$(this).hasClass('slted')) {
          checkedAll = false;
        }
        if ($(this).hasClass('slted')) {
          uncheckAll = false;
        }
      });
      if (checkedAll || uncheckAll) {
        parentElement.find('.option:not(.option-all)').each(function(){
          $(this).removeClass('slted');
          $(this).find("input[type='checkbox']").prop('checked', false)
        });
        parentElement.find('.option-all').addClass('slted')
        parentElement.find('#q_order_by_role').val('0')
      }
    } else if(type === 'status') {
      parentElement.find('#q_user_status').val(value);
    } else {
      parentElement.find(`#q_${type}`).val(value);
    }
    SearchForm.countNumberForSearchNew(element)
  }

  selectMultiOptionModal(type, value, element){
    $(element).addClass('slted')
    if(type === 'status') {
      $(element).parent().find('#q_user_status').val(value);
    }
  }

  alertPopup() {
    Popup.alert("顔写真の削除が失敗しました。", '', '', {
      popupCustomClass: 'new_alert_popup'
    });
  }
}
