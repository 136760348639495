export default class SupplierStatus {

  reInvite(element) {
    var supplier_id = $(element).parent().data('supplier-id');
    var url = Routes.owner_supplier_re_invite_path()
    var data = { supplier_id: supplier_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待メールを再送信しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  removeInvitation(element) {
    var supplier_id = $(element).parent().data('supplier-id');
    var url = Routes.owner_supplier_remove_invitation_path()
    var data = { supplier_id: supplier_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待を解除しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
      $("#modalWaitToApprove").find(".button-back-list").addClass("back-to-collabs");
    })
  }

  quickApproveInvitation(element) {
    var supplier_id = $(element).parent().data('supplier-id');
    var url = Routes.owner_supplier_quick_approve_invitation_path()
    var data = { supplier_id: supplier_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("が仕入先になりました。")
      $("#modalBeInvited").find(".supplier-name").html(data.company_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  rejectInvitation(element) {
    var supplier_id = $(element).parent().data('supplier-id');
    var url = Routes.owner_supplier_reject_invitation_path()
    var data = { supplier_id: supplier_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("からの招待を解除しました。")
      $("#modalBeInvited").find(".company-name").html(data.company_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }
}
