import 'select2'
import 'select2/dist/css/select2.css'

export default class SelectCC {
  runWithCallback(className, functionCallback) {
    $(`select.${className}:not(.select2-hidden-accessible)`).select2();

    $('body').on('select2:close', `select.${className}`, (event) => {
      const onSelect = functionCallback.onSelect
      if (typeof onSelect === 'function') onSelect(event)
    })
  }

  removeSelect2(element) {
    if ($(element).data('select2')) {
      $(element).select2('destroy');
    }
  }
}
