import Nouislider from "nouislider-react";
import React, { Component } from "react";
import "nouislider/distribute/nouislider.css";

export default class AnimationMarkerPeriod extends Component {
  constructor() {
    super();

    this.state = {
      targetNouislider: 0,
    };
    this.sliderRef = React.createRef();
  }

  isShowPeriodRangePresent() {
    const { showPeriodRange } = this.props;
    return Array.isArray(showPeriodRange) && showPeriodRange.length > 1
  }

  handleClickOpenNouislider = (event) => {
    const sliderBounds = this.sliderRef.current.getBoundingClientRect();
    const clickPosition = event.clientX - sliderBounds.left;
    const duration =
      this.props.player != undefined && this.props.player.state != null
        ? this.props.player.state.totalDuration
        : this.props.duration;
    const percentage = (clickPosition / sliderBounds.width) * duration * 1000;
    this.setState({
      targetNouislider: percentage,
    });
    this.props.updateIsOpenNouislider()
  };

  openFrameVideo = (render, handle, value, un, percent) => {
    if (this.isShowPeriodRangePresent()) return;

    const { isEmbedVideo, player } = this.props;
    if (!player) return;

    let seekTime = handle === 0 ? value[0] : value[1];
    if (isEmbedVideo) {
      player.pauseVideo();
      player.seekTo(seekTime / 1000, true)
    } else {
      player?.pause();
      player.seek(seekTime / 1000);
    }
  }

  handelUpdateNouislider = (render, handle, value, un, percent) => {
    if (this.isShowPeriodRangePresent()) return;

    this.openFrameVideo(render, handle, value, un, percent)

    if (value[0] !== value[1]) {
      this.props.openPeriodCommentBox([value[0] / 1000, value[1] / 1000]);
    }
  };

  showTimeOnHover = (event, duration) => {
    const overX = 1000;
    const sliderBounds = this.sliderRef.current.getBoundingClientRect();
    const clickPosition = event.clientX - sliderBounds.left;
    const percentage = (clickPosition / sliderBounds.width) * duration * overX;
    const positionShowTimeLine = 1 - (clickPosition / sliderBounds.width)

    $('#noui-parent').css('transform', 'translate(-' + (positionShowTimeLine * overX) + '%, 0)')
    $('#show-time-line').removeClass('d-none').html(moment.utc(percentage).format("HH:mm:ss.S"))
  }

  render() {
    const { player, isOpenNouislider, showPeriodRange } = this.props;
    const { targetNouislider } = this.state;
    const duration =
      player != undefined && player.state != null
        ? player.state.totalDuration
        : this.props.duration;

    return (
      <div className="comment-period-container">
        <div style={{ display: "flex" }}>
          <p className="title">範囲指定</p>

          {duration > 0 && (this.isShowPeriodRangePresent() || isOpenNouislider) ? (
            <div className="animation-marker-period noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr noUi-show-period"
              onMouseMove={e => {this.showTimeOnHover(e, duration)}}
              ref={this.sliderRef}
            >
              <div class="noUi-origin" id="noui-parent" style={{ transform: `translate(-10px, 0)` }}>
                <div class="noUi-handle noUi-handle-lower hidden-noui">
                  <div class="noUi-tooltip d-none" id="show-time-line"></div>
                </div>
              </div>
              <Nouislider
                range={{ min: 0, max: duration * 1000 }}
                start={this.isShowPeriodRangePresent() ? showPeriodRange : [targetNouislider, targetNouislider]}
                step={1}
                connect
                className="animation-marker-period"
                ref={this.sliderRef}
                onUpdate={this.openFrameVideo}
                onChange={this.handelUpdateNouislider}
                disabled={this.isShowPeriodRangePresent()}
                tooltips = {[
                  {
                    to: function(value) {
                      return moment.utc(value).format("HH:mm:ss.S");
                    },
                  },
                  {
                    to: function(value) {
                      return moment.utc(value).format("HH:mm:ss.S");
                    }
                  }
                ]}
              />
            </div>
          ) : (
            <div
              className="animation-marker-period noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr noUi-show-period"
              onClick={this.handleClickOpenNouislider}
              ref={this.sliderRef}
            >
              <div className="noUi-base">
                <div className="noUi-connects" onMouseMove={e => {this.showTimeOnHover(e, duration)}}>
                </div>
                <div class="noUi-origin" id="noui-parent" style={{ transform: `translate(-10px, 0)` }}>
                  <div class="noUi-handle noUi-handle-lower hidden-noui">
                    <div class="noUi-tooltip" id="show-time-line"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="comment-period-note">
          クリック後
          <div className="marker-circle"></div>
          スクロールで範囲指定
        </div>
      </div>
    );
  }
}
