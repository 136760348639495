import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CardLink, Input, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import objectToFormdata from 'object-to-formdata';
import { toast } from 'react-toastify';
import { version } from '../util/animation';
import bytes from 'bytes';
import ReactTooltip from 'react-tooltip';
import { findDOMNode } from 'react-dom';
import { Resizable } from "re-resizable";
import AnimationPages from './AnimationPages.jsx'
import $ from 'jquery';
import { Range } from "../ui"
import AnimationUrl from './AnimationUrl.jsx'
import iconPen from '../ui/icons/icon_pen_new_white.svg'
import iconUpload from '../ui/icons/upload-img.png'
import iconGlobal from '../ui/icons/iconEarth.svg'
import iconLink from '../ui/icons/link-img.png'
import iconEmail from '../../../assets/images/email_icon.svg'
import iconDownload from '../../../assets/images/icon-download-white.svg'
import iconQuestion from '../../../assets/images/question_mark_icon.svg'
import ReplyComment from './ReplyComment.jsx';
import { btnLoadTicketPage } from '../util/buildUrl';
import Select, { components } from "react-select";
import iconWeb from "../../../assets/images/type_web.png";
import iconLinkNew from "../../../assets/images/icon_link_new.png";


import { canUpdateAnimationMarker, canUpdateProject, canUseReplyFeature,
  canDeleteAnimation, canCreateAnimation, canCreateComment, canUpdateAnimation, canSwitchVersion, canReplyComment,
  canCreateCommentPublicUser, canUserNotPermission,
  canDownloadCSV, canUseUrlFeature, canUseZoomImageFeature } from '../authorizations';
import { truncate } from '../util/truncate.js';

export default class AnimationMarkersPane extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowAll: true,
      temp: false,
      heightAniamtion: 0,
      body: '',
      shouldShowUpdateMarker: false,
      shouldShowSelectTo: false,
      parentReplyId: null,
      userReply: null,
      sortComment: 'asc'
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  componentDidUpdate() {
    const {heightAniamtion}=this.props
    if (!this.state.temp) {
      this.setState({ temp: true, heightAniamtion: heightAniamtion })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.heightAniamtion !== this.state.heightAniamtion) {
      this.setState({ temp: false})
    }
    if (nextProps.markers !== this.props.markers) {
      this.setState({ parentReplyId: null, shouldShowSelectTo: false })
    }
  }
  renderUnregisterUserTooltip() {
    return (
      <ReactTooltip id="marker-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          権限がありません
        </div>
      </ReactTooltip>
    );
  }
  renderPromotionTooltip() {
    return (
      <ReactTooltip id="marker-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          こちらは会員専用機能となります。
        </div>
        <div className="mt-2 mb-2">
          無料会員でも全機能が利用可！<br />
          保存期間も『無期限』
          <span className="small">※利用状況により3ヶ月間</span>
        </div>
        <a href="/signup" style={{ pointerEvents: 'initial' }} className="btn btn-warning" target="_blank">
          会員登録はこちら
        </a>
      </ReactTooltip>
    );
  }

  toggle (name, id = null, flagBool) {
    const commentMarker = this.markers().find(marker => marker.id === id)
    const body = commentMarker ? commentMarker.body : ''

    this.setState({ [`shouldShow${name}`]: flagBool, formMarkerId: id, body: body })
  }

  open(event, name, id) {
    event.stopPropagation();
    const {animationSelectCurrent, currentAnimationId} = this.props;
    if (animationSelectCurrent !== currentAnimationId) return

    this.toggle(name, id, true)
  }

  close(name, id) { this.toggle(name, id, false) }

  publicUserLogin(){
    const { currentUserId, owner, role: currentUserRole } = this.props;
    return !currentUserId && owner
  }
  puplicUserNotPermission(){
    const { currentUserId, owner, role: currentUserRole } = this.props;
    return currentUserId && !canCreateCommentPublicUser(currentUserRole) && owner
  }
  scrollToReply = id => {
    if ($('#reply-comments-' + id).length <= 0) return

    $('.items').animate({
      scrollTop: $('#reply-comments-' + id).position().top
    }, 'slow');
  }
  onClickUpdate(values, targetId, marker) {
    let scrollTopPosition = this.fetchPositionComment(targetId);
    const { currentUserId, owner, role: currentUserRole, animationSelectCurrent, currentAnimationId } = this.props;
    if (this.publicUserLogin() || this.puplicUserNotPermission() || animationSelectCurrent !== currentAnimationId) {
      return
    }
    return event => {
      event.preventDefault();
      event.stopPropagation();
      const { onMarkerUpdationCalled } = this.props;
      onMarkerUpdationCalled(values, targetId);
      this.keepPositionComment(targetId, scrollTopPosition)
      this.openCommentDetail(marker)
    }
  }
  onClickMarker(id) {
    return () => {
      const { onClickMarker } = this.props;
      onClickMarker(id);
    };
  }
  onChangeBody = ({ target: { value: value }}) => {
    this.setState({body: value})
  }

  onSubmitUpdateMarker = (event) => {
    const { apiClient, reloadMarkers } = this.props;
    const { body, formMarkerId } = this.state;
    let scrollTopPosition = this.fetchPositionComment(formMarkerId);
    const keepPosition = this.keepPositionComment(formMarkerId, scrollTopPosition)
    const commentMarker = this.markers().find(marker => marker.id === formMarkerId)
    const { animation_id, id } = commentMarker;
    const data = objectToFormdata({ animation_marker: { body: body } })

    event.preventDefault();
    this.setState({ isSubmitting: true })

    return apiClient.patch(`animations/${animation_id}/animation_markers/${id}`, data)
      .then(_ => {
        this.setState({ isSubmitting: false, formMarkerId: null, shouldShowUpdateMarker: false })
        reloadMarkers();
        setTimeout(function() {
          keepPosition
        }, 600)
      })
      .catch(_ => {
        this.setState({ isSubmitting: false, formMarkerId: null })
        toast.error('コメントの投稿に失敗しました。');
      })
  }
  showHeight() {
    const { heightAniamtion, mobile } = this.props;
    if(mobile)
      return
    const height = findDOMNode(this.image).getBoundingClientRect().height;
    const heightMarker = heightAniamtion - height - 60;
    if (heightAniamtion < 940 && heightMarker != 0)
      return heightMarker < 0 ? 270 : heightMarker
    let size = window.innerWidth;
    let x = 410;
    switch (true){
      case (size < 717):
        break;
      case (size < 926):
        x = 'auto'
        break;
      case (size < 1025):
        x = 320;
        break;
      case (size < 1026):
        x = 'auto'
        break;
      case (size < 1253):
        x = 300;
    }
    return x
  }
  mountEnter =(event, id) =>{
    this.hover_in_player(id, '#DBDBDB', 'rgba(253, 239, 135, 0.4)', 'block');
  }
  mountLeave=(event, id)=> {
    this.hover_in_player(id, 'unset', 'rgba(255, 239, 135, 0.2)', 'none');
  }
  onClick =(event, id, marker)=>{
    let scrollTopPosition = this.fetchPositionComment(id);
    const { onClickMarker } = this.props;
    onClickMarker(id);
    if ($('.marker-' + id).length > 0) {
      $('.wrap-animation-preview').animate({
        scrollTop: $('.marker-' + id).position().top - 200
      }, 'slow');
    }
    this.openCommentDetail(marker)
    $('.animation-marker').removeClass('highlight');
    $('.animation-marker-' + id).addClass('highlight');
    $('#show-period').val(false);
    if ($('.remove-pd').length > 0) $('.remove-pd').find('.widget_close_wrapper').trigger('click');
  }

  openCommentDetail = (marker) => {
    this.props.updateSelectedMarker(marker);
  }

  fetchPositionComment = (id) => {
    let scrollTopPosition = 0;
    if ($(`.animation-marker-${id}`).length > 0) {
      const divScroll = $(`.animation-marker-${id}`).parents('.items')[0];
      scrollTopPosition = divScroll.scrollTop;
    }
    return scrollTopPosition
  }

  keepPositionComment = (id, scrollTopPosition) => {
    if (scrollTopPosition !== 0) {
      $(`.animation-marker-${id}`).parents('.items').animate({
        scrollTop: scrollTopPosition
      }, 'slow');
    }
  }

  hover_in_player(marker, background_animation, background_maker, display) {
    var menus = document.querySelector('.animation-marker-' + marker);
    menus.style.backgroundColor = background_animation;
    var markers = document.querySelector('.marker-' + marker);
    if(markers == null)
      return
    var child = markers.querySelector('.red-rect') || markers.querySelector('.red-point');
    var card = markers.parentNode.querySelector('.card');

    if(child) {
      child.style.backgroundColor = background_maker;
    }
    if(card) {
      card.style.display = display;
    }
  }

  usersToInReplyComment = (replyIds, assignments) => {
    return assignments.filter(assignment => replyIds.includes(`${assignment.value}`))
                      .map(assignment => truncate(assignment.label, 10))
  }

  toggleSelectTo = (isShow) => {
    this.setState({shouldShowSelectTo: isShow})
  }

  wasSeeReply = (replyId) => {
    const { neverSeenReplyIds } = this.props;
    return neverSeenReplyIds && !neverSeenReplyIds.includes(replyId)
  }

  memberIdsInComment() {
    const { membersInComment } = this.props;
    return membersInComment.map(({ _label, value }) => value)
  }

  markersSection(markers = []) {
    const {
      currentUserId, apiClient, role, shouldShowAd, mobile,
      assignments, reloadMarkers, isStillAvailableStorage,
      deleteReplyMarker, taskId, currentTicketId, rootUrl, port, env, membersInComment
    } = this.props;
    const { parentReplyId, shouldShowSelectTo, userReply, sortComment } = this.state;
    return (
      <Resizable
        className={ mobile && !this.isVideo() ? 'mobile-sp-image-list-comment' : '' }
        defaultSize={{ height: mobile ? this.isVideo() ? 225 : 270 : 498 }}
        enable={{ top: false, right: false, bottom: true, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }} >
        <div className={this.isVideo() ? 'items for-video-list-items' : 'items for-image-list-items'}>
          <input type="hidden" id="show-period" value="false" />
          <div className='items-animate'>
            {
              markers.sort((a, b) => { return this.isVideo() ? sortComment === 'asc' ? a.seek_time - b.seek_time || b.id - a.id : b.seek_time - a.seek_time || a.id - b.id : sortComment === 'asc' ? a.number_comment - b.number_comment : b.number_comment - a.number_comment }).map(({ seek_time: time, period_end: periodEnd, body, id, number_comment, status, user, animation_marker_files: files, reply_comments: replyComments, created_at, border_color, animation_id, email_temp, user_name_temp }, i) => {
                const marker = {
                  body: body,
                  time: time,
                  periodEnd: periodEnd,
                  isVideo: this.isVideo(),
                  status: status,
                  id: id,
                  border_color: border_color,
                  index: number_comment,
                  user: user,
                  created_at: created_at,
                  isComment: true,
                  animation_id: animation_id,
                  files: files,
                  receivers: membersInComment,
                  userReply: user,
                  email_temp: email_temp,
                  user_name_temp, user_name_temp
                }
                const user_temp = user_name_temp ? `${user_name_temp}（非ログイン）` : null

                return (
                  <div key={i}>
                    <div className={`item-akapon p-2 d-flex animation-marker animation-marker-${id}`}
                         onClick={(event)=>this.onClick(
                           event,
                           id,
                           marker
                         )}
                         data={id}
                         onMouseEnter={(event) => { this.mountEnter(event, id) }}
                         onMouseLeave={(event) => { this.mountLeave(event, id) }}
                    >
                      <div className={`status-field ${!canCreateCommentPublicUser(role) && ('disabled') }`}>
                        {
                          canUpdateAnimationMarker(role) && (
                            status === 'initial' ? (
                              <div data-tip data-for="marker-tooltip">
                                <CardLink onClick={this.onClickUpdate({ status: 'completed' }, id, Object.assign({}, marker, { status: 'completed' }))} href="#" >
                                  <span className="far fa-circle status-circle"></span>
                                </CardLink>
                              </div>
                            ) : (
                              <div data-tip data-for="marker-tooltip">
                                <CardLink onClick={this.onClickUpdate({ status: 'initial' }, id, Object.assign({}, marker, { status: 'initial' }))} href="#">
                                  <span className="far fa-check-circle status-circle status--completed"></span>
                                </CardLink>
                              </div>
                            )
                          )
                        }
                      </div>
                      <div className='comment-container'>
                        <div className="comment-info d-flex justify-content-between">
                          <div className={`d-inline-block author-name text-ellipsis ${periodEnd ? 'truncate-name-has-period' : 'truncate-name-not-period'}`} style={{marginRight: '8px'}}>{user && user.name_in_project || user_temp || '（非ログイン）'}{user && user.deleted_at ? '（解約済み）' : ''}</div>
                          {
                            periodEnd ? (
                              <div className='d-inline create-at' style={{float: 'right'}}>{moment.utc(time * 1000).format("HH:mm:ss.S")} ~ {moment.utc(periodEnd * 1000).format("HH:mm:ss.S")}</div>
                              ) : (
                              <div className='d-inline create-at'>{moment(created_at).format('YYYY/MM/DD HH:mm:ss', 'Asia/Tokyo')}</div>
                            )
                          }
                        </div>
                        <div className="comment-content">
                          <div className="body message truncate line-clamp-1" dangerouslySetInnerHTML={{__html: body }}></div>
                        </div>
                      </div>

                      <div className='border-none numerical-order-container d-flex justify-content-center align-items-center' style = {{backgroundColor: border_color }}>
                        <div className="comment-no">
                          {number_comment}
                        </div>
                      </div>
                    </div>
                    {
                      assignments && replyComments && replyComments.map((comment, index) => {
                        return <ReplyComment userReply={comment.user} user={comment.user} id={comment.id} deleteReplyMarker={deleteReplyMarker} assignments={membersInComment} replyComment={comment} reloadMarkers={reloadMarkers} currentUserId={currentUserId} disabledReply={!canReplyComment(role)} shouldShowReplyButton={canUseReplyFeature(role)} seen={this.wasSeeReply(comment.id)}
                        reply={this.openReplyForm}
                        positionComment={this.fetchPositionComment}
                        keepPositionComment={this.keepPositionComment}
                        updateSelectedMarker={this.props.updateSelectedMarker}
                        receivers={membersInComment} parentsComment={body}
                        key={comment.id} onClickMarker={this.props.onClickMarker}
                        />
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </Resizable>
    );
  }

  currentVersion = () => {
    const {animations, currentAnimationId} = this.props;
    return (animations && animations.filter(animation => animation.id == currentAnimationId)[0]?.title)
  }

  currentPage = () => {
    const {pages, currentPageId} = this.props;
    return (pages && pages.filter(page => page.id == currentPageId)[0]?.title)
  }

  ticketsSection(){
    const {tickets, currentTicketId, onChangeTicket, role, project} = this.props
    return (
      <Input type="select" onChange={onChangeTicket} value={currentTicketId} disabled={canUserNotPermission(role)} style={{maxWidth: "372px"}}>
        { <option value='task' token={project && project.url_token}>タスク管理</option> }
        {
          tickets.map((ticket) => {
            return (
              <option value={ticket[0]} token={ticket[2]}>
                {ticket[1]}
              </option>
            );
          })
        }
      </Input>
    )
  }
  imageButton(){
    const { role, animations, currentAnimation, getUrlModal, completionModal, publicUrlModal, onClickShareButton } = this.props;
    return (
      <div className="control-button-bottom d-none">
        <div className="btn-url button" data-tip data-for="sign-up-promotion-tooltip">
          <Button className="button-url" disabled={!canCreateAnimation(role, animations)} onClick={getUrlModal}>
            <img src={iconGlobal} />
            <p className='white-p break-work-button'>URL入力</p>
          </Button>
        </div>
        {
          currentAnimation ? (
            <div className="btn-share-url button" data-tip data-for="sign-up-promotion-tooltip">
              <Button className="button-completion" onClick={onClickShareButton} disabled={canUserNotPermission(role)}>
                <img src={iconLink} />
                <p className='white-p break-work-button'>赤入れ共有URL</p>
              </Button>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    )
  }

  videoButton(){
    const {
      role, animations, currentAnimation, completionModal, publicUrlModal, getUrlModal, onClickShareButton, disabled,
      getUrlYoutube
    } = this.props;
    return(
      <div className="control-button-bottom d-none">
        <div className="btn-url button" data-tip data-for="sign-up-promotion-tooltip">
          <Button className="button-url" disabled={!canCreateAnimation(role, animations)} onClick={getUrlYoutube}>
            <img src={iconGlobal} />
            <p className='white-p break-work-button'>URL入力</p>
          </Button>
        </div>

        {
          currentAnimation ? (
            <div className="btn-url btn-share-url button" data-tip data-for="sign-up-promotion-tooltip">
              <Button onClick={onClickShareButton} disabled={canUserNotPermission(role)}>
                <img src={iconLink} />
                <p className='white-p break-work-button'>赤入れ共有URL</p>
              </Button>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    )
  }
  showMarker(){
    const { markers } = this.props
    return typeof markers != "undefined" && markers.length === 0
  }
  showExtension(){
    const { changeExtension, selectAnimationExtension, role, animations, extensions}=this.props
    const { currentAnimation } = this.props;
    if (typeof extensions != "undefined" && this.isVideo() && currentAnimation && !currentAnimation.is_video_embed) {
      return (''
      )
    }
  }
  showAnimation(text){
    const { animations, currentAnimationId, onChange, disabled, role, currentAnimation, scaleImage, onChangeScaleImage,
            currentSubscriptionType,currentUserRole, isShowDrawComponent }=this.props
    var versionSelector = "version-selector pb-0 pt-0 text-left mb-3"
    if(!this.isVideo()){
      versionSelector = versionSelector.concat(" version-selector-image")
    }
    const classWithVideo = this.fetchClassWithVideoEmbed()
    const currentUrlFile = currentAnimation?.file_object?.url?.split('?')[0]?.split('.')
    const extensionFile = currentAnimation && !currentAnimation.is_video_embed &&
                            currentUrlFile[currentUrlFile.length -1]?.toUpperCase();
    if (typeof animations != "undefined") {
      return currentAnimation && !currentAnimation.is_video_embed ? (
        <div className={versionSelector} data-tip data-for="sign-up-promotion-tooltip">
        </div>
      ) : currentAnimation && this.isVideo() && canUseZoomImageFeature(currentUserRole,currentSubscriptionType) &&
          currentAnimation.is_video_embed ? (
        <div className='d-flex video-embed-youtube flex-wrap'>
        <div className={"zoom-of-video-youtube " + versionSelector} data-tip data-for="sign-up-promotion-tooltip">
        </div>
          <Range
            containerClass={"ml-3"}
            className={classWithVideo + " ml-1 col-6"}
            value={scaleImage}
            step={5}
            handleChange={onChangeScaleImage}
            isShowDrawComponent={isShowDrawComponent}
          />
        </div>
      ) : ''
    }
  }
  showDownloadImage(text){
    const { disabled, downloadUrl, onClickDownloadImageButton } = this.props;
    return(
      <div className="item-akapon modal-download-animation " disabled={disabled}>
        <Button className="btn-download-video" onClick={() => onClickDownloadImageButton(downloadUrl, !disabled)} download disabled={disabled}>
          <p>
            {text}Download
          </p>
          <span className="app-icon icon-download"></span>
        </Button>
      </div>
    )
  }
  showDownloadVideo(text){
    const { disabled, downloadUrl, onClickDownloadButton, is_video_embed } = this.props;
    return (
      <div className="item-akapon modal-download-animation " disabled={disabled}>
        <Button className="btn-download-video" onClick={onClickDownloadButton} disabled={disabled}>
          <p>
            {text}Download
          </p>
          <span className="app-icon icon-download"></span>
        </Button>
      </div>
    )
  }
  downloadCSV = () => {
    const { currentAnimationId, projectId, taskId, currentTicketId } = this.props;
    const animationType = this.isVideo() ? 'video' : 'image'
    let url = `/akaire_feature/akaire_files/${taskId}/animations/${currentAnimationId}/animation_markers.csv?project_type=${animationType}`;
    window.open(url, '_blank');
  }

  showDownloadCSV() {
    const { disabled } = this.props;
    return (
      <div className="item-akapon">
        <Button className="btn-download-csv" onClick={this.downloadCSV} style={{whiteSpace: 'normal'}} disabled={disabled}>
          <span>
            <p>コメントDownload</p>
          </span>
          <span className="app-icon icon-download"></span>
        </Button>
      </div>)
  }
  changeSortComment = (value) => {
    this.setState({ sortComment: value });
  }

  showPage = () => {
    const { pages, onChangePage, currentPageId, onSubmit, role, deletePage, currentAnimation, onClickFinalizeAnimation,
            onClickCancelFinalAnimation, animations, page_last_animation } = this.props;
    return (
      <AnimationPages currentAnimation={currentAnimation} onClickFinalizeAnimation={onClickFinalizeAnimation} onClickCancelFinalAnimation={onClickCancelFinalAnimation} pages={pages} onChangePage={onChangePage} currentPageId={currentPageId} onSubmit={onSubmit} role={role} deletePage={deletePage} animations={animations} page_last_animation={page_last_animation}/>
    )
  }
  showControlAnimation = (text) => {
    const { role,uploadAnimation, autoComment, owner, animations, scaleImage, onChangeScaleImage,
            isDisabledButtonSendMailNotLogin, showPopupConfirmSendMailNotLogin, isShowDrawComponent } = this.props;
    const classWithVideo = this.fetchClassWithVideoEmbed()
    return (
      <div className="control-down">
        {/* <div className='mb-2 text-light'>アクションツール</div> */}
        <div className="control-button" data-tip data-for="sign-up-promotion-tooltip">
          <div className="control-button-top flex-wrap">
            {
              (!canCreateComment(role) || this.publicUserLogin() || this.puplicUserNotPermission() || !gon.is_login) && role !== 'reader' ? (
                <div className="btn-donwload button" data-tip data-for="sign-up-promotion-tooltip">
                  <Button disabled={isDisabledButtonSendMailNotLogin || isShowDrawComponent} onClick={showPopupConfirmSendMailNotLogin} className="button-auto-comment">
                    <img src={iconEmail} style={{transform: 'scale(1.6)'}} />
                    <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                    <span class="ap-detail-show-popup-comment"
                      onClick={ (event) => {
                        event.stopPropagation()
                        Popup.alert("コメント作成後、メンバーへ赤入れ通知はされません。<br>メンバーへ赤入れ通知を行う場合は「赤入れ通知」または各コメント「更新」ボタンをクリックすると通知されます。<br>※コメント単位の返信については、都度、メンバーへ送信されます。", '', '', {kind: 'akaire'});
                      }}
                    >？</span>
                  </Button>
                </div>
              ) : (
                <>
                  <div className="btn-donwload button mb-2" data-tip data-for="sign-up-promotion-tooltip">
                    <Button onClick={isShowDrawComponent ? null : autoComment} className="button-auto-comment">
                      <img src={iconEmail} style={{transform: 'scale(1.6)'}} />
                      <p className='white-p break-work-button btn-small-mb'>赤入れ通知</p>
                      <span className="ap-detail-show-popup-comment"
                        onClick={ (event) => {
                          event.stopPropagation()
                          Popup.alert("コメント作成後、メンバーへ赤入れ通知はされません。<br>メンバーへ赤入れ通知を行う場合は「赤入れ通知」または各コメント「更新」ボタンをクリックすると通知されます。<br>※コメント単位の返信については、都度、メンバーへ送信されます。", '', '', {kind: 'akaire'});
                        }}
                      >？</span>
                    </Button>
                    <Button className="button-download" onClick={(event) => {
                      setTimeout(function() {
                        SideBar.showModalDownloadAnimation(this);
                        event.stopPropagation()
                      }, 100);
                        }}
                        >
                      <img src={iconDownload} style={{transform: 'scale(1.6)'}} />
                      <p className='white-p break-work-button btn-small-mb'>ダウンロード</p>
                    </Button>
                  </div>
                  <div className="btn-donwload button btn-url" data-tip data-for="sign-up-promotion-tooltip">
                    <Button onClick={() => $('.btn-share-url button').click()} className="button-auto-comment btn-share-url">
                      <img className="filter-to-white" src={iconLinkNew} style={{transform: 'scale(1)'}} />
                      <p className='white-p break-work-button btn-small-mb'> 赤入れ共有URL</p>
                      <span className="ap-detail-show-popup-comment"
                        onClick={(e) => {e.stopPropagation(); $('#sidebar-recently').toggleClass('d-none'); $('#modalInforShareUrl').modal('show')}}
                      >？</span>
                    </Button>
                    {this.isVideo() && (
                      <Button onClick={() => openPublicUrlPopup()} className="button-auto-comment btn-share-url">
                        <img className="filter-to-white" src={iconWeb} style={{transform: 'scale(1)', width: '24px'}} />
                        <p className='white-p break-work-button btn-small-mb'>動画視聴用URL</p>
                        <span className="ap-detail-show-popup-comment"
                          onClick={(e) => {e.stopPropagation(); $('#sidebar-recently').toggleClass('d-none'); $('#modalInforShareUrlVideo').modal('show')}}
                        >？</span>
                      </Button>
                    )}
                  </div>
                </>
              )
            }
          </div>
          {this.isVideo() ? this.videoButton() : this.imageButton()}
          <div id="slider-scale-box" className="container-scale-box">
            <p className="title-scale-line">拡大縮小</p>
            <div className='slider-scale-box' style={{position: "relative"}}>
              <Range
                containerClass={"ml-3"}
                className={classWithVideo + " ml-1 col-6"}
                value={scaleImage}
                step={5}
                handleChange={onChangeScaleImage}
                isShowDrawComponent={isShowDrawComponent}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  showControlVersion = (text) => {
    const { role, deleteAnimation, currentSubscriptionType } = this.props;
    return (
      <div className="animation-buttons new--asdasd-a-sd">

        {this.isVideo() ? this.showDownloadVideo(text) : this.showDownloadImage(text)}
        {canDownloadCSV(currentSubscriptionType) ? this.showDownloadCSV() : ''}
        <div className="item-akapon" data-tip data-for="sign-up-promotion-tooltip">
          <Button className="btn-delete-animation" onClick={deleteAnimation} disabled={!canDeleteAnimation(role)} >
            <span className="u-fontColor--red">
              <p>バージョン削除</p>
            </span>
            <span style={{fontSize: '14px'}} className="fas fa-trash-alt"></span>
          </Button>
        </div>
      </div>
    )
  }
  fetchClassWithVideoEmbed() {
    return 'col-12 text-right'
  }

  showMarkerButton(text){
    const { animations, canUsePageManagement, currentAnimation,onMode,onChangeMode, scaleImage, onChangeScaleImage,
            mobile, currentSubscriptionType,currentUserRole, isShowDrawComponent } = this.props;
    const classWithVideo = this.fetchClassWithVideoEmbed()
    return (
      <div className="d-none">
        <hr style={{ border: this.props.isAkaponMember ? 'none' : '' }} />
        <div className="">
          <div className="version">
            {/* { canUsePageManagement ? this.showPage() : ''} */}
            {
              canUseUrlFeature(currentUserRole,currentSubscriptionType) && currentAnimation && currentAnimation.url &&
              <AnimationUrl currentAnimationUrl={currentAnimation.url} isMobile={mobile}/>
            }

            <div style={{display: 'flex', justifyContent: 'space-between'}} className={`version-top ${this.isVideo() ? "video" : ''} row ml-0 mr-0`}>
              { animations && animations.length > 0 ? this.showAnimation(text) : '' }
              { animations && animations.length > 0 ? this.showExtension() : '' }
              { currentAnimation && !currentAnimation.is_video_embed &&
                canUseZoomImageFeature(currentUserRole,currentSubscriptionType) &&
                <Range
                containerClass={"ml-3"}
                className='col-6 mb-0 text-right mb-3'
                value={scaleImage}
                step={5}
                handleChange={onChangeScaleImage}
                isShowDrawComponent={isShowDrawComponent}
              />}
            </div>
            { animations && animations.length > 0 ? this.showControlVersion(text) : '' }
          </div>
        </div>
      </div>
    )
  }
  isVideo(){
    const { project_type } = this.props;
    return ['動画', 'video'].includes(project_type)
  }
  truncateName(name){
    let size = window.innerWidth;
    if (typeof name != "undefined") {
      switch(true){
        case(size < 1025):
          return name.length > 12 ? name.substring(0, 10) + "..." : name;
        case(size < 1300):
          return name.length > 15 ? name.substring(0, 14) + "..." : name;
      }
      return name.length > 30 ? name.substring(0, 20) + "..." : name;
    }
  }
  markersForm(){
    const {
      role, projectFormModal, name, currentUserId, currentAnimation,
      currentPageId, canUsePageManagement, currentAnimationTotalSize,
      tickets, currentTicketId, rootUrl, port, taskId, projectId, env, animations, isAkaponMember
    } = this.props;
    var text = "画像";

    if (this.isVideo()){
      text = "動画";
    }
    var ticketsSection = this.ticketsSection();
    return (
      <div className='marker-button top-pane' ref={ref => this.image = ref}>
        {/* Dont display it anymore */}
        {/* {!currentUserId && this.renderPromotionTooltip()} */}
        {/* <div className="animation-file-size">
          <div className="dt position-text-top-1">
            {isAkaponMember ? '赤入れ名：' : 'タスク名：'}
            <span className='text-light text-truncate position-text-top'>
              { name }
            </span>
          </div>
          <div className="dt">
            ファイル容量：
            <span className='text-light'>
              {bytes(currentAnimationTotalSize)}
            </span>
          </div>
        </div> */}
        {/* <div className="markerpane-control"> */}
          {/* <hr /> */}
          {/* <div className='mb-2 text-light'>セレクトツール</div> */}
          {/* <div className="control-box">
            {
              (!currentUserId || !isAkaponMember) &&
              <div className="ticket-name">
                <p>
                  赤入れ保存場所&nbsp;&nbsp;&nbsp;
                  {
                    currentTicketId != 'task' &&
                    <a href={canUserNotPermission(role) ? 'javascript:void(0)' : btnLoadTicketPage(env, rootUrl, port, currentTicketId != 'task', projectId, taskId, currentTicketId)}
                      className="link-load-ticket-page">(このページに移動)
                    </a>
                  }
                </p>
                { ticketsSection }
              </div>
            }

            {currentPageId && canUsePageManagement || !canUsePageManagement ? this.showMarkerButton(text) : ""}
          </div> */}
          {currentPageId && canUsePageManagement || !canUsePageManagement ? this.showMarkerButton(text) : ""}
          { animations && animations.length > 0 ? this.showControlAnimation(text) : ''}
        {/* </div> */}
      </div>
    )
  }
  markers() {
    const { shouldShowAll } = this.state;
    const { markers } = this.props;
    return shouldShowAll ? markers : markers.filter(_ => _.status === 'initial');
  }
  onChangeCheck = ({ target: { checked } }) => {
    this.setState({ shouldShowAll: checked });
  }

  render() {
    const { disabled, mobile, currentUserId, owner, role: currentUserRole, currentAnimationTotalSize,
            currentAnimationId, onChangePage, currentPageId, pages, animations, role, onChangeCommentVersion,
            animationSelectCurrent, setCurrentAnimation, isShowFullscreenAnimation, isShowDrawComponent, showMode } = this.props;
    const { shouldShowAll, temp, shouldShowUpdateMarker, body, isSubmitting, sortComment } = this.state;
    const unsubmittable = isSubmitting || body.length === 0;
    var showMarkerSection = '';
    if (temp){
      showMarkerSection = this.markersSection(this.markers());
    }
    var versionSelector = "version-selector-comment pb-0 pt-0 text-left d-flex align-items-center pl-0 pr-0";
    if(!this.isVideo()){
      versionSelector = versionSelector.concat(" version-selector-image")
    }
    let classAnimationMarker = isShowFullscreenAnimation ? 'animation-markers-pane d-none' : 'animation-markers-pane'
    let classForImageAndmMobile = mobile && !this.isVideo() ? 'show-animation-markers-pane-for-image-mobile' : ''
    return (
      <div className={`${ classAnimationMarker } ${classForImageAndmMobile} ${ showMode == 'compare' && 'd-none' }`}>
        {!mobile && (<div className='animation-markers-pane-title'>コメントツール</div>)}
        <div className="header row d-flex flex-wrap align-items-ceter">
          <div className={`${versionSelector} select-page-animation-container`} data-tip data-for="sign-up-promotion-tooltip">
            <div id="select-current-page-animation" className='truncate line-clamp-1'>{pages.find(_ => _.id === parseInt(currentPageId))?.title}</div>
          </div>
          <div className={`${versionSelector} select-comment-animation-container`} data-tip data-for="sign-up-promotion-tooltip">
            <Input type="select" id="animation-selector-comemnt" value={animationSelectCurrent || ''}
                    onChange={onChangeCommentVersion} disabled={!canSwitchVersion(role, animations)} >
              {
                animations.map((animation, i) => {
                  return (
                    <option value={animation.id} key={i}>
                      {version(animation)}
                    </option>
                  );
                })
              }
            </Input>
          </div>
          { mobile && !this.isVideo() && (
            <div style={{ color: 'white', cursor: 'pointer' }} className={`d-flex align-items-center `}>
               {bytes(currentAnimationTotalSize)}
            </div>
          )}
          {
            <div className='d-flex text-white align-items-center text-underline dropdown-toggle dropdown-toggle-split change-sort-comemnt-akaire'
                 data-toggle={isShowDrawComponent ? '' : "dropdown"} aria-haspopup="true" aria-expanded="false">
              <span className=''>{ this.isVideo() ? sortComment === 'asc' ? '尺昇順' : '尺降順' : sortComment === 'asc' ? 'No 昇順' : 'No 降順' } </span>
              <div className={isShowDrawComponent ? 'd-none dropdown-for-change-sort-comment-akaire' :"dropdown-menu dropdown-for-change-sort-comment-akaire"}>
                  <div className={`dropdown-item first ${sortComment === 'asc' ? 'active' : ''}`}
                       onMouseDown={() => this.changeSortComment('asc')}>{this.isVideo() ? 'コメント並び順（尺昇順）' : 'コメント並び順（No 昇順）'}</div>
                  <div className={`dropdown-item last ${sortComment === 'desc' ? 'active' : ''}`}
                       onMouseDown={() => this.changeSortComment('desc')}>{this.isVideo() ? 'コメント並び順（尺降順）' : 'コメント並び順（No 降順）'}</div>
              </div>
            </div>
          }

          <FormGroup check className="pl-0 d-flex align-items-center">
            <Input type="checkbox" className="mr-1 mt-0 ml-0" style={{ width: '18px', height: '18px', position: 'static', transform: 'initial' }} checked={shouldShowAll} onChange={isShowDrawComponent ? null : this.onChangeCheck} disabled={disabled} />
            <span className="text-of-input-check">全て表示</span>
          </FormGroup>

        </div>
        <Modal isOpen={shouldShowUpdateMarker}>
          <Form
            onSubmit={this.onSubmitUpdateMarker}
          >
            <ModalHeader>
              コメントを変更する
            </ModalHeader>
            <ModalBody>
              <Input type="textarea" value={body} autoFocus onChange={this.onChangeBody} rows={4}/>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between">
                <Button onClick={ () => this.close('UpdateMarker') } className="cancel mr-2">
                  キャンセル
                </Button>
                <Button type="submit" disabled={unsubmittable} className="submit btn btn-danger" color='primary'>
                  保存
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
        {
          showMarkerSection
        }
        {mobile ? " " : this.markersForm()}
        {/* {this.publicUserLogin() && this.renderPromotionTooltip()} */}
        {/* {this.puplicUserNotPermission() && this.renderUnregisterUserTooltip()} */}
      </div>
    );
  }
};
