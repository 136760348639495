import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import pdf from '../ui/icons/pdf_new.svg';
import jpg from '../ui/icons/jpg_new.svg';
import png from '../ui/icons/png_new.svg';
import gif from '../ui/icons/icon_gif.png';
import { version } from '../util/animation';
import { canUserNotPermission, canSwitchVersion } from '../authorizations';
import apiClient from '../util/api-client';

const MIME_ALLOWED_UPLOAD_WITH_WEB_TYPE = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf'];

export default class ModalDownloadImage extends Component {
  constructor(props) {
    super(props);
    const { currentPageId, currentAnimationId, animations, pages, baseURL } = props;
    const animation = this.selectedAnimation(animations, currentAnimationId)
    this.state = {
      selectPageId: currentPageId,
      selectVersion: currentAnimationId,
      animationsRender: animations,
      pagesRender: pages,
      selectedAnimation: animation,
    };
    this.apiClient = apiClient({ baseURL: baseURL });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pages !== this.props.pages || nextProps.animations !== this.props.animations) {
      this.setState({ pagesRender: nextProps.pages, animationsRender: nextProps.animations })
    }
  }

  onChangePage = async (event) => {
    const newPageId = event.target.value
    const { fetchListAnimationsDownload } = this.props;
    this.setState({ selectPageId: newPageId });
    const newAnimations = await this.apiClient.get(`pages/${newPageId}/animations`);
    const lastAnimations = newAnimations.data[newAnimations.data.length - 1]
    const animation = this.selectedAnimation(newAnimations.data, lastAnimations.id)
    this.setState({ animationsRender: newAnimations.data, selectVersion: lastAnimations.id, selectedAnimation: animation });
    fetchListAnimationsDownload(newPageId)
  }

  onChangeVersion = (event) => {
    const { animationsRender } = this.state;
    const versionId = event.target.value
    this.setState({
      selectVersion: versionId,
      selectedAnimation: this.selectedAnimation(animationsRender, versionId),
    });
  }

  downloadImage = () => {
    const { downloadImage } = this.props;
    const selectDownloadAnimationId = this.state.selectVersion || this.props.currentAnimationId
    downloadImage(selectDownloadAnimationId)
  }

  changeTypeDownLoad = (event, type) => {
    const {changeTypeDownLoad} = this.props;
    changeTypeDownLoad(event, type);
  }

  isGifAnimation = () => {
    const { selectedAnimation } = this.state;
    if (!selectedAnimation.file_object || !selectedAnimation.file_object.url) {
      return false;
    }

    return selectedAnimation.file_object.url.split('.').pop().trim().toLowerCase() === 'gif'
  }

  selectedAnimation = (animations, currentVersionId) => {
    return animations.filter(item => item.id === parseInt(currentVersionId))[0]
  }

  render() {
    const { isOpen, selectTypeDownload, role } = this.props;
    const { selectPageId, selectVersion, pagesRender, animationsRender, downloadImage, isGifAnimation } = this.state;
    return (
      <Modal isOpen={isOpen} className="animation-uploader-modal modal-dialog-centered modal-download-animation modal-style" size="md">
        <ModalBody>
          <div className='justify-content-center text-center'>
            <span className='font-weight-bold text-black title-modal'>Downloadする形式を選択してください。</span><br/>
          </div>
          <div className='justify-content-center text-center d-flex'>
            <div className='w-30 show-select-akaire-page-and-version'>
              {pagesRender &&
                <Input type="select" id="select-current-page-animation"
                      onChange={(event) =>this.onChangePage(event)} value={selectPageId} disabled={canUserNotPermission(role)}>
                  {
                    pagesRender.map((page, i) => {
                      return (
                        <option value={page.id} key={i}>
                          {page.title}
                        </option>
                      );
                    })
                  }
                </Input>
              }
              {animationsRender &&
                <Input type="select" id="animation-selector-comemnt" className="text-right w-30" value={selectVersion || ''}
                      onChange={(event) => this.onChangeVersion(event)} disabled={!canSwitchVersion(role, animationsRender)}>
                  {
                    animationsRender.map((animation, i) => {
                      return (
                        <option value={animation.id} key={i}>
                          {version(animation)}
                        </option>
                      );
                    })
                  }
                </Input>
              }
            </div>
          </div>
          <div className='d-flex mb-2 mt-2 align-items-center justify-content-center'>
            <div className='bg-wild-sand d-block pd-10px mr-3 list-downloads-type'>
              {this.isGifAnimation() &&
                <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'GIF')}>
                  <img className="img" width={20} height={25} src={gif} alt="gif" />
                  <span className='font-size-20px font-weight-bold ml-2'>GIF</span>
                </div>
              }
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'PDF')}>
                <img className="img" width={20} height={25} src={pdf} alt="pdf" />
                <span className='font-size-20px font-weight-bold ml-2'>PDF</span>
              </div>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'PNG')}>
                <img className="png" width={20} height={25} src={png} alt="png" />
                <span className='font-size-20px font-weight-bold ml-2'>PNG</span>
              </div>
              <div className='d-flex cursor-pointer' onClick={(event) => this.changeTypeDownLoad(event, 'JPG')}>
                <img className="jpg" width={20} height={25} src={jpg} alt="jpg" />
                <span className='font-size-20px font-weight-bold ml-2'>JPG</span>
              </div>
            </div>
            {
              selectTypeDownload === '' ? (
                <span className="ml-5 font-size-16px text-black">形式を選択</span>
              ) : (
                <span className="ml-5 font-size-16px text-red font-weight-bold">{selectTypeDownload}形式</span>
              )
            }
          </div>
          <div className="justify-content-center text-center mt-2">
            <Button color="primary" disabled={selectTypeDownload === ''} onClick={this.downloadImage}
                    className="text-white">ダウンロードする</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
