import toastr from 'toastr';
import Swal from 'sweetalert2'
export default class OrderNew {
  remove(orderId) {
    Popup.confirm('', {
      description: I18n.t('order.confirm_delete',{order_id: orderId}),
      confirmText: I18n.t('shared.delete'),
      confirmClass: 'btn-danger',
      success: () => {
        Shared.callAjax(Routes.owner_orders_path(), { order_id: orderId }, 'json', 'delete').done(function(data) {
          Popup.confirm(I18n.t('order.delete_success',{ order_id: orderId }), {
            cancelBtn: 'false' , success: () => { location.reload() }, cancel: () => { location.reload() }
          })
        }).fail(function(){
          Popup.confirm(I18n.t('order.delete_error', { order_id: orderId }), {
            cancelBtn: 'false' , success: () => { location.reload() }, cancel: () => { location.reload() },
          })
        })
      }
    })
  }

  remove_order_received(orderId) {
    Popup.confirm('', {
      description: I18n.t('order.confirm_delete_received',{order_id: orderId}),
      confirmText: I18n.t('shared.delete'),
      confirmClass: 'btn-danger',
      confirmButtonColor: '#f10d0e !important',
      success: () => {
        Shared.callAjax(Routes.destroy_order_received_owner_order_path(orderId), {}, 'json', 'delete').done(function(data) {
          Popup.confirm(I18n.t('order.delete_success',{ order_id: orderId }), {
            confirmText: 'OK', cancelBtn: 'false' , success: () => { location.reload() }, cancel: () => { location.reload() }
          })
        }).fail(function(){
          Popup.confirm(I18n.t('order.delete_error', { order_id: orderId }), {
            cancelBtn: 'false' , success: () => { location.reload() }, cancel: () => { location.reload() },
          })
        })
      }
    })
  }

  reset(element) {
    const form = $(element).parents('form#new_order');
    form.trigger('reset');
    form.find('#quotation_setting_payment_method').text('設定されていません').addClass('disabled')
    form.find('#set_quotation_payment_id').val('')
  }

  resetEdit(element){
    const form = $(element).parents('form');
    form.find('input.on-unlock').val('').trigger('change');
    form.find('#order_description').val('');
    form.find('#add_order_price_intax').val('');
    form.find('#add_order_price_intax').removeAttr('value');
    form.find('#order_description').removeAttr('value');
    form.find('input.on-unlock').removeAttr('value');
    form.find('#quotation_setting_payment_method').text('設定されていません')
    form.find('#set_quotation_payment_id').val('')
  }

  selectSupplier(element) {
    const supplierId = $(element).val();
    if (!supplierId) return
    const componentAddOrder = $(element).parents('form');
    componentAddOrder.find('.quotation_supplier_id').val(supplierId);
    const orderId = componentAddOrder.find('input#order_id').val()
    const selectUserSupplier = $(element).parents('.order_popup_company').find('.select-user-supplier');
    selectUserSupplier.empty();
    selectUserSupplier.append(`<option value="">${I18n.t('shared.enter_name')}</option>`);
    Shared.callAjax(Routes.person_in_charge_owner_orders_path(), { company_id: supplierId, order_id: orderId })
    .done(function(data){
      if (!!data) {
        if (data.users.length > 0) {
          data.users.forEach(user => {
            selectUserSupplier.append(`<option value="${user[1]}">${user[0]}</option>`);
          });
        }
        if (!!data.html_payment_methods) {
          componentAddOrder.find('#quotation_setting_payment_method').removeClass('disabled')
          if (!data.is_old_supplier) {
            componentAddOrder.find('#quotation_setting_payment_method').text('設定されていません')
            componentAddOrder.find('input#set_quotation_payment_id').val('')
          }
          $('#content_list_payment_methods').empty().html(data.html_payment_methods)
        }
        if (!!data.supplier_representative_id) {
          selectUserSupplier.val(data.supplier_representative_id)
        }
      }
    })
  }

  selectSupplierUser(element) {
    const componentAddOrder = $(element).parents('form');
    const user = $(element).val();
    componentAddOrder.find('.quotation_person_in_charge_id').val(user);
  }

  selectOrderType(element) {
    const componentAddOrder = $(element).parents('form');
    const type = $(element).val();
    componentAddOrder.find('.quotation_contract_sub_type').val(type);
    componentAddOrder.find('.quotation_item_unit').val(type);
  }

  changeName(element) {
    const componentAddOrder = $(element).parents('form');
    const name = $(element).val();
    componentAddOrder.find('.quotation_name').val(name);
    componentAddOrder.find('.quotation_items_name').val(name);
  }

  changeRemarks(element) {
    $(element).parents('form').find('.remarks-for-quotation').val($(element).val())
  }

  create(element) {
    Disabled.toggle($(element).find('input[name="order[status]"]'), false);
    const componentCreateOrder = $(element).parents('#new_order');
    if ($(element).find('input[name="order[status]"]').val() !== 'draft') {
      componentCreateOrder.find('.status-for-quotation').val('submitted')
      if (!componentCreateOrder.find('#set_quotation_payment_id').val()) {
        $('#modalWarningMustSelectPaymentMethod').find('#content').text('支払サイトが未設定です。')
        $('#modalWarningMustSelectPaymentMethod').modal('show');
        Shared.errorFieldMessage(componentCreateOrder.find('#quotation_setting_payment_method'), '')
        return;
      }
    }
    const allInput = componentCreateOrder.find('input:not(:disabled, :hidden)');
    const allSelect = componentCreateOrder.find('select:not(:disabled)')
    let isSubmit = this.checkValidate(allInput, allSelect)

    if (isSubmit) {
      if ($(element).find('input[name="order[status]"]').val() !== 'draft') {
        this._showPopupConfirmWhenSubmitOrder(componentCreateOrder)
      } else {
        componentCreateOrder.submit();
      }
    } else {
      Popup.alert(I18n.t('shared.attribute_not_blank'));
    }
  }

  checkValidate(allInput, allSelect) {
    let isSubmit = true
    allInput.toArray().forEach(input => {
      if (($(input).attr('id') !== 'add_order_price_intax' && $(input).val() === '') || $(input).val().trim() === "")  {
        Shared.errorFieldMessage($(input), '')
        isSubmit = false;
      }
    })
    allSelect.toArray().forEach(select => {
      if ($(select).val() === '') {
        Shared.errorFieldMessage($(select), '')
        isSubmit = false;
      }
    })
    return isSubmit
  }

  approval(element, orderId) {
    Popup.confirm('', {
      description: I18n.t('order.confirm_approval', {
        order_id: orderId
      }),
      confirmText: I18n.t('order.approve'),
      confirmClass: 'btn-danger',
      success: () => {
        Shared.callAjax(Routes.approval_owner_orders_path(), {
          order_id: orderId
        }).done(function (data) {
          Popup.confirm(I18n.t('order.approve_success', {
            order_id: orderId
          }), {
            confirmText: 'OK',
            cancelBtn: 'false',
            success: () => {
              location.reload()
            },
            cancel: () => {
              location.reload()
            }
          })
        }).fail(function () {
          Popup.confirm(I18n.t('order.approve_error', {
            order_id: orderId
          }), {
            cancelBtn: 'false',
            success: () => {
              location.reload()
            },
            cancel: () => {
              location.reload()
            },
          })
        })
      }
    })
  }

  release(element, orderId) {
    Popup.confirm(I18n.t('order.confirm_canceled', { order_id: orderId }), {
      success: () => { this._changeStatusOrder(Routes.release_owner_orders_path(), orderId); },
      confirmText: '解除する',
    })
  }

  tax(element){
    if ($(element).parents('form').find('#add_order_tax_select').val() === 'tax_exemption') return
    if($(element).val() == 'other'){
      this.showInput($(element).parents('form'));
    }else{
      $(element).parents('form').find('.show-tax').text(`${$(element).val()}%`);
      $(element).parents('.order_popup_tax').find('.tax-order').val($(element).val());
      this._calculatorPriceWithTax($(element).parents('form').find('#add_order_price'));
    }
  }

  changeTaxType(element) {
    this.changeImageTax($(element));
    const taxType = $(element).val();
    const componentCreateOrder = $(element).parents('form');
    if (taxType !== 'tax_exemption') {
      Disabled.toggle(componentCreateOrder.find('.tax-order'), true);
      componentCreateOrder.find('.tax-order').val('10').trigger('change')
      componentCreateOrder.find('.show-tax').text(`${componentCreateOrder.find('.tax-order').val()}%`)
      this._calculatorPriceWithTax(componentCreateOrder.find('#add_order_price'));
    } else {
      componentCreateOrder.find('.tax-order').val('0')
      componentCreateOrder.find('.show-tax').text(`${componentCreateOrder.find('.tax-order').val()}%`)
      Disabled.toggle(componentCreateOrder.find('.tax-order'), true);
      componentCreateOrder.find('#add_order_price_intax').val(componentCreateOrder.find('#add_order_price').val());
    }
  }

  changeImageTax(element){
    const select = $(element);
    const tax_value = select.val();
    select.removeClass('image-internal_tax')
    select.removeClass('image-external_tax')
    select.removeClass('image-tax_exemption')
    select.addClass(`image-${tax_value}`)
  }

  copy(event = null, orderId){
    if (event) event.stopPropagation();
    Shared.callAjax(Routes.copy_owner_orders_path(), {order_id: orderId}).done(function(data){
      Popup.alert(I18n.t('order.copy.success'));
      setTimeout(() => {
        location.reload()
      }, 2000)
    }).fail(function(data){
      Popup.alert(I18n.t('order.copy.error'));
    })
  }

  changePrice(element) {
    const price = Number($(element).val());
    const componentOrderCreate = $(element).parents('form');
    if (price > 0) {
      this._calculatorPriceWithTax($(element));
    } else {
      toastr.error(I18n.t('order.tax_cannot_less_than_zero'), "", { closeButton: true, progressBar: true });
      $(element).val('');
    }
  }

  openOrderDetailFromMail() {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const orderId = searchParams.get("show_detail_order");
    const type = searchParams.get("type")
    if (orderId && type) {
      this.edit(null,orderId, type)
      searchParams.delete('show_detail_order')
      searchParams.delete('type')
      history.replaceState('', '', url.pathname);
    }
  }

  async showInput(componentCreateOrder) {
    const { value: tax } = await Swal.fire({
      title: I18n.t('shared.enter_tax'),
      input: 'text',
      inputValue: 10,
      showCancelButton: false,
      inputLabel: I18n.t('shared.consumption_tax_rate')
    })
    if (Number(tax) > 0) {
      $(componentCreateOrder).find('.tax-order').val(tax);
      $(componentCreateOrder).find('.show-tax').text(`${tax}%`);
      this._calculatorPriceWithTax($(componentCreateOrder).find('#add_order_price'));
    } else {
      this.showInput(componentCreateOrder);
    }
  }

  unlockOrder(element, type = 'draft') {
    const dataButton = $(element).attr('data-button');
    const form = $(element).parents('form');

    if (type === 'submitted') {
      form.append('<input type="hidden" name="order[status]" value="submited" />');
      if (!form.find('#set_quotation_payment_id').val()) {
        $('#modalWarningMustSelectPaymentMethod').find('#content').text('支払サイトが未設定です。')
        $('#modalWarningMustSelectPaymentMethod').modal('show');
        Shared.errorFieldMessage(form.find('#quotation_setting_payment_method'), '')
        return;
      }
    }

    if (dataButton === 'unlock') {
      $(element).text(I18n.t('shared.save_order'));
      $(element).attr('data-button', 'save');
      form.find('.on-unlock').removeClass('d-none').removeAttr('readonly').removeAttr('disabled');
      form.find('#quotation_setting_payment_method').removeClass('disabled');
      form.find('#order_document').removeAttr('readonly');
      $('select#edit_order_supplier_id').trigger('change')
    } else {
      const allInput = form.find('input.on-unlock');
      const allSelect = form.find('select:not(:disabled)')
      let isSubmit = this.checkValidate(allInput, allSelect)

      if (isSubmit) {
        if (type === 'submitted') {
          this._showPopupConfirmWhenSubmitOrder(form)
        } else {
          form.submit();
        }
      } else {
        Popup.alert(I18n.t('shared.attribute_not_blank'))
      }
    }
  }

  edit(event = null, order_id, type) {
    if (event) event.stopPropagation();
    const url = Routes.edit_owner_orders_path()
    Shared.callAjax(url, { order_id: order_id, type: type }, 'html', 'get').done(function(data) {
      $(`#show-detail-order${order_id}`).find('.show-content-edit-order').empty().append(data)
      $(`#show-detail-order${order_id}`).modal('show');
      datepickerCustom();
      $('.select2-cc').select2();
    })
    .fail(function(e) {
      Popup.confirm(I18n.t('shared.one_error_please_try_again'), {
        cancelBtn: 'false' ,
        success: () => { Routes.orders_sended_owner_orders_path() },
        cancel: () => { Routes.orders_sended_owner_orders_path() },
      })
    })
  }

  _calculatorPriceWithTax(priceEle) {
    const price = Number($(priceEle).val());
    const componentOrderCreate = $(priceEle).parents('form');
    const tax = Number(componentOrderCreate.find('.tax-order').val())
    const taxType = componentOrderCreate.find('#add_order_tax_select').val();
    const priceWithTax = componentOrderCreate.find('#add_order_price_intax');
    if (price > 0 && tax > 0 && taxType) {
      switch (taxType) {
        case 'external_tax':
          priceWithTax.val(((tax * price) / 100) + price);
          break;
        default:
          priceWithTax.val(price);
      }
    }
  }

  _changeStatusOrder(url, orderId) {
    Shared.callAjax(url, { order_id: orderId }, 'json').done(function(data){
      Popup.confirm('解除しました。', {
        success: () => { location.reload() },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto'
      })
    }).fail(function(data){
      Popup.confirm(I18n.t('order.change_status.errors'), {
        success: () => { location.reload()},
        cancelBtn: 'false'
      })
    })
  }

  _optionDatePicker() {
    return {
      minDate: new Date(),
      customDays: I18n.t('shared.customDays'),
      customMonths: I18n.t('shared.customMonths'),
      formatter: (input, date, _) => input.value = moment(date).format('Y/MM/DD')
    }
  }

  _showPopupConfirmWhenSubmitOrder(element) {
    Popup.confirm('', {
      description: `<div class="text-center">この発注書は</div>
      <div class="text-center">
        <span class="font-weight-bold">
          ${element.find('#quotation_setting_payment_method').text().replace('銀行振込｜', '').replace('払い', '')}がお支払期限になります。
        </span>
      </div>
      発注後、承認されると確定になります。<br>
      発注してよろしいですか？`,
      success: () => { element.submit(); },
      confirmText: 'OK',
      cancelBtn: 'false',
      width: 'auto',
    })
  }
}
function datepickerCustom() {
  const orderNew = new OrderNew;
  Shared.customDatepicker('js-datepicker-new', orderNew._optionDatePicker())
}

