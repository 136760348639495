import memoize from "memoize-one";
import React, { Component } from "react";
import "../css/text-editor-widget-component.scss";
import { styles } from "../util/styleDrawComponent";
import platform from 'platform';

class TextEditorWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "draw",
      pen: "up",
      lineWidth: 3,
      textColor: "black",
      isBold: false,
      isUnderline: false,
      editorSetting: {
        borderColor: '#000'
      }
    };
  }

  isIpadOS() {
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad'
  }

  setBorderWhenCommentPeriod = memoize(
    (onlyBUAForCommentPeriod) => {
      if (gon.is_login && onlyBUAForCommentPeriod) {
        this.setEditorBorder({ borderColor: '#0066FF' })
      }
    }
  );

  render() {
    const { mobile, onlyBUA, onlyBUAForCommentPeriod } = this.props;
    const { isBold, isUnderline } = this.state
    this.setBorderWhenCommentPeriod(onlyBUAForCommentPeriod)
    const borders = [
      ['pink', '#ffc0c1'],
      ['green', '#75d579'],
      ['blue', '#63a7ff'],
      ['yellow', '#eddc4b'],
      ['red', '#E60C11'],
      ['gray', '#aea8ac'],
    ]
    const textColors = [
      ['black', 'white', 'lightgrey', 'darkgray', 'gray', 'dimgrey'],
      ['mediumvioletred', 'red', 'orangered', 'orange', 'gold', 'yellow'],
      ['greenyellow', 'limegreen', 'green', 'cadetblue', 'blue', 'midnightblue'],
      ['rebeccapurple', 'purple', 'antiquewhite', 'peru', 'sienna', 'saddlebrown'],
      ['hotpink', 'peachpuff', 'lightyellow', 'lightgreen', 'skyblue', 'mediumslateblue']
    ];
    return (
      <div className={'right-component'} style={{ display: "flex", alignItems: "center", position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', ...(mobile ? { top: '6px' } : {}) }}
          className="ml-2 size-text-sp widget_wrapper"
        >
          {
            !onlyBUA && !onlyBUAForCommentPeriod && gon.is_login && (
              <div className="widget_color_wrapper" >
                {
                  borders.map(([color, hex], i) => {
                    return (
                      <div className={`div_widget text-center ${color}`} onClick={() => this.setEditorBorder({ borderColor: hex })} key={i}>
                        <div className="text-center mt-1">
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }

          <div className="widget_text_wrapper" >
            <button className={`text_widget text-center ${isBold ? 'widget-active' : ''}`} type='button' onClick={() => this.setTextSetting('bold')}>
              <div className="text-center mt-1">
                B
              </div>
            </button>
            <button className={`text_widget text-center ${isUnderline ? 'widget-active' : ''}`}  type='button' onClick={() => this.setTextSetting('underline')}>
              <div className="text-center mt-1 underline">
                U
              </div>
            </button>
            <button className="text_widget text-center" type='button' onClick={() => this.setOption()}>
              <div className="text-center mt-1 underline" style={{ borderColor: this.state.textColor }}>
                A
              </div>
            </button>

            {
              !onlyBUA && (
                <button className={`text_widget file_upload ${this.isIpadOS() ? 'for-ipad-upload-icon' : ''}`}
                        type="button" onClick={this.props.fileUpload} >
                  <i className="fas fa-paperclip"></i>
                </button>
              )
            }
          </div>

          {
            !onlyBUA && (
              <div className="widget_close_wrapper" onClick={() => this.props.closeEditor()}>
                <i className="fas fa-times-circle"></i>
              </div>
            )
          }

          {this.state.isShowTextColorOption && (
            <div className={!mobile ? 'color-option' : 'color-option-mobile'}
              onClick={(event) => { event.stopPropagation() }}>
              {
                textColors.map((colors, i) => {
                  return (
                    <div style={{ display: "flex" }} className={i == 0 ? '' : 'mt-2'} >
                      {
                        colors.map((color) => {
                          return (
                            <button
                              style={Object.assign(
                                {},
                                styles.colorSwatches[color],
                                styles.button
                              )}
                              type='button'
                              onClick={() => this.setTextColor(color)}
                            ></button>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          )}
        </div>
      </div>
    )
  }

  setOption() {
    this.setState((prevState) => ({
      isShowTextColorOption: !prevState.isShowTextColorOption,
    }));
  }

  setTextSetting(command) {
    const { editor } = this.props
    editor.editorCommands.execCommand(command)
    if (command == 'bold') {
      this.setTextBold()
    } else {
      this.setTextUndeline()
    }
    this.setState(
      () => ({
        isShowTextColorOption: false,
      })
    );
  }

  setEditorBorder(setting) {
    const editorSetting = { ...this.state.editorSetting, ...setting };
    this.setState(
      () => ({
        editorSetting: editorSetting,
      }),
      () => {
        this.props.settingEditor(editorSetting);
      }
    );
  }

  setTextColor(color) {
    const { editor } = this.props
    editor.editorCommands.execCommand('ForeColor', false, color)
    this.setState(
      (prevState) => ({
        isShowTextColorOption: !prevState.isShowTextColorOption,
        textColor: color
      })
    );
  }

  setTextBold() {
    this.setState(
      (prevState) => ({
        isBold: !prevState.isBold
      })
    );
  }

  setTextUndeline() {
    this.setState(
      (prevState) => ({
        isUnderline: !prevState.isUnderline
      })
    );
  }

  triggerCloseEditor() {
    this.props.closeEditor()
  }

}

export default TextEditorWidgetComponent;
