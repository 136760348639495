import 'select2'
import 'select2/dist/css/select2.css'
import Swal from 'sweetalert2'
import 'packs/jquery-ui.min'

export default class LogWorkAction {

  selectCompanySender;
  selectCompanyReceived;
  listQuotationSender;
  listQuotationReceived;
  listOrderDelete = [];

  changeUnitWork(event){
    const $element = $(event.target);
    $element.prev().val($element.val());
    $element.parents('tr').find('input.quotation_sender').val('');
    $element.parents('tr').find('input.work_quotation_id').val('');
    if ($element.val() == 'other') {
      toastr.warning('作業種別がカスタマイズがある見積書に変更したら、数量が消えるようになります。', "", {
        closeButton: true,
        progressBar: true,
      });
    }
    Shared.toggleAttribute(
      $element.parents('tr').find('input.work_quantity'), 'readonly', $element.val() == 'other'
    )
    $element.parents('tr').data('order-present', false);
    this.removeAllWorkQuantity($element.parents('tbody'));
    const trWorkChilds = $element.parents('tbody').find('tr.invoice__table--new');
    Shared.toggleAttribute(trWorkChilds.find('input.work_quantity'), 'readonly', $element.val() === 'other');
    if (window.innerWidth < gon.shared.break_point_for_mobile) return
    Ticket.save($element);
  }

  _fetchIdModalSelectCompany(type) {
    return type == 'sender' ? 'modal_select_company_sender' : 'modal_select_company_received'
  }

  _fetchIdModalSelectQuotation(type) {
    return type == 'sender' ? 'modal_select_quotation_sender' : 'modal_select_quotation_received'
  }

  _fetchClassInputCompany(type) {
    return type == 'sender' ? 'company_received_order' : 'company_ordered'
  }

  _renderCompanyForSelect2(type, element, companyId, companyName) {
    if (type == 'sender') {
      element.parents('tr').find('.sender-show').find(`select.company_received_order`).val(companyId);
      element.parents('tr').find('.sender-show').find('span.select2-selection__rendered').text(companyName);
    }
  }

  showAccepSelectCompany(event, type) {
    const imgSelect = this._fetchImageSelect(type);
    const idModal = this._fetchIdModalSelectCompany(type);
    const classSelectCompany = type == 'sender' ? 'select_quotation_company_sender' : 'select_quotation_company_received'
    const companyId = $(`div#${idModal}`).find(`select.${classSelectCompany}`).val();
    const companyName = $(`div#${idModal}`).find(`select.${classSelectCompany} :selected`).text();
    const classInputCompany = type == 'sender' ? 'company_received_order' : 'company_ordered'
    imgSelect.parents('td').data('company-name', companyName);
    imgSelect.parents('tr').find(`input.${classInputCompany}`).val(companyId);
    this._renderCompanyForSelect2(type, imgSelect, companyId, companyName);
    this._toggleDisabledSelectCompany(idModal, classSelectCompany, false);
    this._showListButtonAccepCompany(idModal);
  }

  _showListButtonAccepCompany(idModal) {
    Visiable.toggle($(`#${idModal}`).find('div.btns-select-company'), true);
    Visiable.toggle($(`#${idModal}`).find('div.btns-confirm-company'), false);
  }

  _hideListButtonAccepCompany(idModal) {
    Visiable.toggle($(`#${idModal}`).find('div.btns-select-company'), false);
    Visiable.toggle($(`#${idModal}`).find('div.btns-confirm-company'), true);
  }

  _fetchImageSelect(type) {
    return type == 'sender' ? this.selectCompanySender : this.selectCompanyReceived
  }

  _fetchClassSelectQuotation(type) {
    return type == 'sender' ? 'show_select_quotation_sender' : 'show_select_quotation_received'
  }

  _fetchClassInputQuotation(type) {
    return type == 'sender' ? 'quotation_sender' : 'work_quotation_id'
  }

  _fetchProjectId(){
    return $('#get_data_url').attr('project_id');
  }

  _fetchIdDetailQuotation(type) {
    return type == 'sender' ? 'showOrderSender' : 'showOrderReceived'
  }

  _toggleCloseModalQuotaiton(element, isBoolean) {
    Visiable.toggle(element, isBoolean);
  }

  _fetchCompanyName(element, type) {
    if (type === 'sender') {
      return element.parents('td').data('company-name');
    } else {
      return element.parents('td').data('company-received-name');
    }
  }

  backToSelectQuotation(event, type) {
    const idModalShowQuotation = this._fetchIdModalSelectQuotation(type);
    $(`#${idModalShowQuotation}`).modal('show');
  }

  accepSelectCompany(event, type) {
    const imgSelect = this._fetchImageSelect(type);
    const idModal = this._fetchIdModalSelectCompany(type);
    const idModalShowQuotation = this._fetchIdModalSelectQuotation(type);
    const classSelectCompany = type == 'sender' ? 'select_quotation_company_sender' : 'select_quotation_company_received'
    const companyId = $(`div#${idModal}`).find(`select.${classSelectCompany}`).val();
    const companyName = $(`div#${idModal}`).find(`select.${classSelectCompany} :selected`).text();
    const classInputCompany = type == 'sender' ? 'company_received_order' : 'company_ordered'
    imgSelect.parents('td').data('company-name', companyName);
    imgSelect.parents('tr').find(`input.${classInputCompany}`).val(companyId);
    this._renderCompanyForSelect2(type, imgSelect, companyId, companyName);
    this._quotations(imgSelect, type, companyId, idModalShowQuotation);
  }

  _getQuotation(quotations, quotationId) {
    return quotations.find(quotation => quotation.id == Number(quotationId));
  }

  _getListQuotation(type) {
    return type == 'sender' ? this.listQuotationSender : this.listQuotationReceived
  }

  _fetchTaskId() {
    return $('#get_data_url').attr('task_id');
  }

  _getLinkToOrder(type) {
    const projectId = this._fetchProjectId();
    const taskId = this._fetchTaskId();
    return type == 'sender' ? Routes.orders_sended_owner_orders_path() :
                              Routes.orders_received_owner_orders_path()
  }

  detailQuotation(event, type) {
    const imgSelect = this._fetchImageSelect(type);
    const idDetailQuotation = this._fetchIdDetailQuotation(type);
    const idModalSelectQuotation = this._fetchIdModalSelectQuotation(type);
    const quotationData = this._getListQuotation(type)
    const companyName = this._fetchCompanyName(imgSelect, type);
    const classSelectQuotation = this._fetchClassSelectQuotation(type);
    const quotationId = $(event.target).parents('.modal-body').find(`select.${classSelectQuotation}`).val();
    const quotation = this._getQuotation(quotationData.quotations, quotationId);
    $(`div#${idModalSelectQuotation}`).modal('hide');
    Visiable.toggle($(`div#${idDetailQuotation}`).find('button.close-modal-quotation'), true);
    Visiable.toggle($(`div#${idDetailQuotation}`).find(`button.confirm-quotation`), false);
    Visiable.toggle($(`div#${idDetailQuotation} .back-to-select-quotation`), false);
    this._renderQuotation(quotation, idDetailQuotation, type, companyName);
    $(`div#${idDetailQuotation}`).modal('show');
  }

  _renderQuotation(quotation, idModal, type, companyName) {
    const classCompanyName = type == 'sender' ? 'company_sender' : 'company_received'
    const classQuotationName = type == 'sender' ? 'quotation_sender_name' : 'quotation_received_name'
    const classQuotationPrice = type == 'sender' ? 'quotation_sender_price' : 'quotation_received_price'
    const classQuotationContract = type == 'sender' ? 'quotation_sender_contract' : 'quotation_received_contract'
    const classQuotationType = type == 'sender' ? 'quotation_sender_type' : 'quotation_received_type'
    const quotationPrice = `ID: ${quotation.id}  |  ${this.addPeriod(quotation.amount)}  円`;
    const quotationContractSub = this.renderContractSubType(quotation.contract_sub_type);
    const quotationName = this.rendeNameQuotation(quotation.name);
    const inputs = $(`div#${idModal}`).find('input');
    this._renderTimeAndMonthPayment(quotation, idModal);
    inputs.each((i, input) => {
      if ($(input).hasClass(`${classCompanyName}`)) { $(input).val(companyName) }
      if ($(input).hasClass(`${classQuotationName}`)) { $(input).val(quotationName) }
      if ($(input).hasClass(`quotation-id`)) { $(input).val(quotation.id) }
      if ($(input).hasClass(`${classQuotationPrice}`)) { $(input).val(quotationPrice) }
      if ($(input).hasClass(`${classQuotationContract}`)) { $(input).val(quotation.contract_type) }
      if ($(input).hasClass(`${classQuotationType}`)) { $(input).val(quotationContractSub) }
      if ($(input).hasClass(`quotation_contract_sub_type`)) { $(input).val(quotation.contract_sub_type) }
      if ($(input).hasClass('quotation_duration')) $(input).val(quotation.month_payment_now)
    })
    const linkToOrder = this._getLinkToOrder(type);
    $(`div#${idModal}`).find('a.link_quotation_sender').attr('href', linkToOrder)
  }

  _renderTimeAndMonthPayment(quotation, idModal) {
    Visiable.toggle($(`#${idModal}`).find('.title_duration'), !quotation.month_payment_now)
    Visiable.toggle($(`#${idModal}`).find('.quotation_duration'), !quotation.month_payment_now)
    const currentDate = new Date()
    let quotationTime = ''
    let titleQuotationTime = ''
    switch (quotation.contract_sub_type) {
      case 'by_time':
        titleQuotationTime = I18n.t('work.delivery_meter')
        quotationTime = `${quotation.time_by_select_quotation.toFixed(gon.number_round)}   /h`
        break;
      case 'by_number':
        titleQuotationTime = I18n.t('work.delivery_meter')
        quotationTime = `${quotation.time_by_select_quotation.toFixed(gon.number_round)}   /個`
        break;
      case 'by_hours':
        titleQuotationTime = I18n.t('work.time_remaining')
        quotationTime = `${quotation.time_by_select_quotation.toFixed(gon.number_round)}   /h`
        break;
      case 'fixed_quantity':
        titleQuotationTime = I18n.t('work.delivery_meter')
        quotationTime = `${quotation.time_by_select_quotation.toFixed(gon.number_round)}   /個`
        break;
      default:
        titleQuotationTime = I18n.t('work.contract_period');
        quotationTime = I18n.l("date.formats.ja", new Date(quotation.time_by_select_quotation || currentDate));
    }

    $(`#${idModal}`).find('.title_time').text(titleQuotationTime)
    $(`#${idModal}`).find('.quotation_time').val(quotationTime)

    if (quotation.month_payment_now) {
      $(`#${idModal}`).find('.quotation_time').removeClass('col-9').addClass('col-3')
    } else {
      $(`#${idModal}`).find('.quotation_time').removeClass('col-3').addClass('col-9')
    }
  }

  changeQuotation(event) {
    const projectId = this._fetchProjectId();
    const quotationId = $(event.target).val();
    $(event.target).parents('div.modal-body')
                   .find('a.show_detail_quotation')
                   .attr('href', Routes.owner_quotation_show_path(projectId, quotationId))
  }

  notAccepSelectCompany(event, type) {
    const idModal = this._fetchIdModalSelectCompany(type);
    this._hideListButtonAccepCompany(idModal);
    $(`div#${idModal}`).modal('hide');
  }

  async confirmSelectQuotation(element, type, step = 1) {
    const selected = type == 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const trSelected = selected.parents('tr');
    const unit = trSelected.find('.select_unit_work').val();
    let orderId, classCompany, companyId;
    if (step === 1) {
      orderId = $(element).parents('.target_popup1').find('.select-quotations').val();
      classCompany = type === 'sender' ? 'company-sender' : 'company-received'
      companyId = $(element).parents('.target_popup1').find(`.${classCompany}`).val();
    } else {
      orderId = $(element).parents('.select_document').find('.order-id').val();
      companyId = $(element).parents('.select_document').find('.company-id').val();
    }

    const listClassWorkOrder = await this._fetchListClassWorkOrder(type)
    if (!companyId) {
      trSelected.find(`.${listClassWorkOrder.classCompany}`).val('');
      trSelected.find(`.${listClassWorkOrder.classQuotation}`).val('');
      Ticket.save(trSelected.find(`.${listClassWorkOrder.classCompany}`));
      return
    } else if (companyId && !orderId) {
      trSelected.find(`.${listClassWorkOrder.classCompany}`).val(companyId);
      Ticket.save(trSelected.find(`.${listClassWorkOrder.classCompany}`));
      return
    }
    const orders = this._getListQuotation(type);
    const order = this._getQuotation(orders, orderId);
    const quantity = Number(trSelected.find('.show-quantity:last').text());
    const orderByClient = $(trSelected).find('.work_quotation_id').val();
    if (gon.log_work_feature.is_client_or_owner &&
          ((type === 'sender' && Number(orderByClient) === 0) || type === 'received') &&
          gon.log_work_feature.quotation_fixed.includes(order.contract_sub_type)) {
      if (quantity > order.time_by_select_quotation) {
        this._showPopupMuchQuantity($(element), order, order.time_by_select_quotation, type, step)
      } else {
        this._renderQuotationSelected(type, $(element), order, step)
      }
    } else {
      if (quantity > order.time_by_select_quotation &&
            gon.log_work_feature.quotation_fixed.includes(order.contract_sub_type)) {
        this._showPopupMuchQuantity($(element), order, order.time_by_select_quotation, type, step)
      } else {
        this._renderQuotationSelected(type, $(element), order, step)
      }
    }
    $('div#showOrderSender').modal('hide')
    $('div#showOrderReceived').modal('hide')
  }

  async _showQuotations(type, companyName, orderId) {
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const workEle = selected.parents('tr')
    const workId = workEle.find('input.work_id').val()
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const listClassWorkOrder = this._fetchListClassWorkOrder(type);
    const companyId = workEle.find(`.${listClassWorkOrder.classCompany}`).val();
    const unit = workEle.find('.render_unit_work').val();
    const dataAjax = { type: type, company_id: companyId }
    const isInvoiceConfirm = workEle.attr('data-is-invoice') === 'true';
    const ajaxCall = this._fetchQuotations(Routes.owner_project_task_all_orders_between_company_path(projectId,taskId ), dataAjax);
    const idModalShowQuotation = this._fetchIdDetailQuotation(type);
    await ajaxCall.done(function(data){
      type == 'sender' ? logWorkAction.listQuotationSender = data.orders : logWorkAction.listQuotationReceived = data.orders
    })
    const order = await this.fetchOrderOfTicket(workId, orderId)
    if (!order) return
    this._renderOrderToComponentShowOrder(orderId, companyId, type, companyName, order);
    this._showHideListButton(type, $(`#${idModalShowQuotation}`), isInvoiceConfirm);
    $(`div#${idModalShowQuotation}`).modal('show');
  }

  _showHideListButton(type, modalDetailOder, isInvoiceConfirm = false) {
    const isOwnerMain = $('div.vip').data('is-owner-main');
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const workEle = selected.parents('tr')
    const listClassWorkOrder = this._fetchListClassWorkOrder(type);
    const companyId = workEle.find(`.${listClassWorkOrder.classCompany}`).val();
    const orderId = workEle.find(`.${listClassWorkOrder.classQuotation}`).val();
    if (companyId && orderId ) {
      Visiable.show(modalDetailOder.find('.list-button-work-order'))
      Visiable.hide(modalDetailOder.find('.list-button-no-work-order'))
      if (isOwnerMain && !isInvoiceConfirm) {
        Visiable.show(modalDetailOder.find('.remove-order-id'))
      } else {
        Visiable.hide(modalDetailOder.find('.remove-order-id'))
      }
    } else {
      Visiable.hide(modalDetailOder.find('.list-button-work-order'))
      Visiable.show(modalDetailOder.find('.list-button-no-work-order'))
      Visiable.hide(modalDetailOder.find('.remove-order-id'));
    }
  }

  _dataAjax(type, companyId, unit) {
    return { 'company_id': companyId, 'unit': unit, 'get_type': type }
  }

  _fetchQuotations(url, dataAjax) {
    return Shared.callAjax(url, dataAjax);
  }

  _quotations(element, type, companyId, idModalSelectQuotation) {
    const labelName = type == 'sender' ? I18n.t('project.invite.search.company_types.supplier') :
                                         I18n.t('project.invite.search.company_types.client')
    const textMessage = type == 'sender' ? '発注がありません' : '受注がありません'
    const projectId = this._fetchProjectId();
    const classQuotationCurrent = type == 'sender' ? 'work_quotation_id' : 'quotation_sender'
    const quotationCurrent = element.parents('tr').find(`input.${classQuotationCurrent}`).val();
    const companyName = element.parents('td').data('company-name');
    let unit = element.find('input.render_unit_work').val();
    if (!quotationCurrent) { unit = '' }
    const dataAjax = this._dataAjax(type, companyId, unit);
    const ajaxCall = this._fetchQuotations(gon.url_get_quotations, dataAjax);
    ajaxCall.done(function (data) {
      type == 'sender' ? logWorkAction.listQuotationSender = data : logWorkAction.listQuotationReceived = data
      if (data.quotations.length > 0) {
        logWorkAction._appendListQuotation(data.quotations, type, idModalSelectQuotation, projectId);
        $(`div#${idModalSelectQuotation}`).modal('show');
      } else {
        $(`div#${idModalSelectQuotation}`).modal('hide');
        $(`div#show_select_company`).find('input.company-id').val(companyName)
        $('div#show_select_company').find('span#show_text_select_quotation').text(labelName);
        $('div#show_select_company').find('p.text-message').text(textMessage);
        $(`div#show_select_company`).modal('show');
      }

    })
    ajaxCall.fail(function (data) {
    })
  }

  _appendListQuotation(quotations, type, idModal, projectId) {
    const calssSelectQuotation = this._fetchClassSelectQuotation(type);
    const selectQuotation = $(`div#${idModal}`).find(`select.${calssSelectQuotation}`);
    selectQuotation.empty();
    quotations.forEach(quotation => {
      const quotationName = logWorkAction.rendeNameQuotation(quotation.name);
      selectQuotation.append(
        `<option value="${quotation.id}" type="${quotation.types}" >${quotationName}</option>`
      );
    })
    $(`div#${idModal}`).find('a.show_detail_quotation')
                       .attr('href', Routes.owner_quotation_show_path(projectId, selectQuotation.val()))
  }

  selectCompany(element, type, is_select_all = false) {
    const projectId = this._fetchProjectId()
    const taskId = this._fetchTaskId()
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const componentSelectQuotation = this._fetchComponentSelectQuotation($(element), type);
    const workEle = selected.parents('tr')
    const workId = workEle.find('input.work_id').val()
    const orderType = workEle.find('.select_unit_work').val();
    const classOrderId = type == 'sender' ? 'quotation_sender' : 'work_quotation_id'
    if ($(element).val()) {
      let dataAjax = { type: type, company_id: $(element).val(), order_type: orderType, work_id: workId }
      if (type === 'received' && is_select_all) {
        dataAjax = {
          type: type, company_id: $(element).val(), order_type: orderType,
          work_id: workId, show_all: true
        }
      }
      Shared.callAjax(Routes.owner_project_task_orders_between_company_path(projectId, taskId), dataAjax).done(function(data){
        const orderID = data.work_order?.order_id
        workEle.find(`input.${classOrderId}`).val(orderID)
        if (!!data.work_order) {
          workEle.attr('data-old-work-order-received', JSON.stringify(data.work_order))
        }
        logWorkAction._removeSelectOrder(componentSelectQuotation);
        if( data.orders.length > 0 ) {
          data.orders.forEach(order => {
            componentSelectQuotation.find('.select-quotations').append(
              $('<option>', {
                value: order.id, ownerId: order.owner_id,
                createbyquo: order.is_create_by_quotation,
                enabled: order.is_enabled,
                order_type_jp: order.order_type_jp,
                'data-class': !order.is_enabled || order.is_used_other ? 'opacity-blur' : '',
                'data-used-other': order.is_used_other,
                'data-ticket-name': order.ticket_name,
                'data-is-expiration': order.is_expiration,
                text: order.name || order.id
              }
            ))
          })
          type === 'sender' ? logWorkAction.listQuotationSender = data.orders :
                              logWorkAction.listQuotationReceived = data.orders
          componentSelectQuotation.find(`select.select-quotations`).attr('data-type-of-ticket', data?.unit)
          if (orderID && type !== 'sender') {
            $('body').find(`div#selectTargetCompanyReceived`).find('.btn-select-quotation').addClass('d-none')
            componentSelectQuotation.find(`select.select-quotations`).attr('disabled', true)
            componentSelectQuotation.find(`select.select-quotations`).val(orderID)
          } else if (type !== 'sender') {
            $('body').find(`div#selectTargetCompanyReceived`).find('.btn-select-quotation').removeClass('d-none')
            componentSelectQuotation.find(`select.select-quotations`).removeAttr('disabled')
          }
        }
      })
      .fail(function(){
      })
    } else {
      logWorkAction._removeSelectOrder(componentSelectQuotation);
    }
  }

  async acceptSelectCompany(element, type, step) {
    const classOrderId = type === 'sender' ? 'quotation_sender' : 'work_quotation_id'
    const classCompanyId = type === 'sender' ? 'company_received_order' : 'company_ordered'
    let idComponent, orderClass, classSelectCompany;
    if (step === 1) {
      idComponent = type === 'sender' ? 'selectTargetCompanySender' : 'selectTargetCompanyReceived'
      orderClass = 'select-quotations'
      classSelectCompany = type === 'sender' ? 'company-sender' : 'company-received'
    } else {
      idComponent = type === 'sender' ? 'showOrderSender' : 'showOrderReceived'
      orderClass = 'order-id'
      classSelectCompany = 'company-id'
    }
    const componentSelectCompany = $(element).parents(`#${idComponent}`);
    const orderId = componentSelectCompany.find(`.${orderClass}`).val();
    const companyId = componentSelectCompany.find(`.${classSelectCompany}`).val()
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const classDestroyWorkOrder = type === 'sender' ? 'order-sender-destroy' : 'order-received-destroy'
    const workEle = selected.parents('tr');
    const orders = this._getListQuotation(type);
    const order = this._getQuotation(orders, orderId);
    const unitWork = workEle.find('.render_unit_work').val()

    if (window.innerWidth < gon.shared.break_point_for_mobile) {
      if (order) {
        const workMbEle = $('body').find('#ticketNewPopup');
        const orderTypeMb = workMbEle.find('#work_unit').val();
        const classOrderOld = type === 'sender' ? 'work_quotation_id' : 'quotation_sender'
        const orderOld = workMbEle.find(`.${classOrderOld}`).val();
        if (orderTypeMb && orderTypeMb !== order.contract_sub_type) {
          if (orderOld) {
            Popup.alert(I18n.t('work.quotation_present'), 'error')
            return
          }
          workMbEle.find('.mb-table-list-quantity').find('.work-quantity-clone2').remove();
          workMbEle.find('.show-quantity').text(0);
        }
        workMbEle.find('#work_unit').val(order.contract_sub_type);
      }
      componentSelectCompany.modal('hide');
    }
    workEle.find(`.${classOrderId}`).val(orderId);
    workEle.find(`.${classCompanyId}`).val(companyId);
    let unitNew = order.contract_sub_type
    if (order.contract_sub_type === 'fixed_quantity') unitNew = 'by_number'
    if (order.contract_sub_type === 'by_hours') unitNew = 'by_time'
    workEle.find('.render_unit_work').val(unitNew);

    if ((!unitWork || (order.contract_sub_type === unitWork)) && window.innerWidth > gon.shared.break_point_for_mobile) {
      Ticket.save(selected);
      return
    }
    if (window.innerWidth < gon.shared.break_point_for_mobile) return
    componentSelectCompany.modal('hide');
    Ticket.save(selected)
  }

  _removeSelectOrder(component) {
    component.find('.select-quotations').find('option').remove();
    component.find('.select-quotations').append($('<option>', { value: '', text: '選択する' }))
  }

  selectQuotation(element, type, companyId) {
    const componentSelectQuotation = this._fetchComponentSelectQuotation($(element), type);
    const quotationId = $(element).val();
    const createbyquo = $(element).find(":selected").attr('createbyquo');
    const ownerId = $(element).find(":selected").attr('ownerId');
    const optionSelected = $(element).find("option:selected")
    if (!!optionSelected) {
      if (optionSelected.attr('data-used-other') === 'true') {
        Popup.alert(`この注文は、既に${optionSelected.attr('data-ticket-name')}チケットに紐づいています。<br>種別がカスマイズの場合、紐づけできるのは1チケットになります。`)
        $(element).val('').trigger("change");
        return
      }
      if (optionSelected.attr('data-is-expiration') === 'true') {
        $(element).val('').trigger("change");
        Popup.alert('注文済み書類の契約期間が終了しました。<br>チケットへ紐づけできません。')
        return
      }
      if (optionSelected.attr('enabled') === 'false') {
        const typeOder = optionSelected.attr('order_type_jp')
        Popup.alert(
          `現在、このチケットの種別は「${I18n.t($(element).attr('data-type-of-ticket'))}」です。<br>この書類の種別は「${typeOder}」です。<br>数量ページから、種別を変更の上、<br>再度、書類を選択してください。`
        )
        $(element).val('').trigger("change");
        return
      }
    }
    if (quotationId) {
      $('button.btn-show-detail-order').show();
      this._renderLinkOrder(componentSelectQuotation, type, quotationId, createbyquo, companyId, ownerId);
    } else {
      $('button.btn-show-detail-order').hide();
      componentSelectQuotation.find('.show-link-order').attr('href', '#');
    }
  }

  _renderLinkOrder(componentSelectQuotation, type, quotationId, createbyquo, companyId, ownerId) {
    let linkOrder
    if (createbyquo === 'true') {
      linkOrder = companyId === ownerId  ? Routes.owner_owner_received_quotations_list_path({quo_id: quotationId}) :
      Routes.owner_owner_quotations_list_path({quo_id: quotationId})
    } else {
      linkOrder = type === 'sender' ? Routes.orders_sended_owner_orders_path({order_id: quotationId}) :
      Routes.orders_received_owner_orders_path({order_id: quotationId})
    }

    componentSelectQuotation.find('.show-link-order').attr('href', linkOrder);
  }

  async showDetailOrder(element, type) {
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const workEle = selected.parents('tr')
    const workId = workEle.find('input.work_id').val()
    const orderId = $(element).parents('.target_popup1').find('.select-quotations').val();
    const idComponent = type === 'sender' ? 'selectTargetCompanySender' : 'selectTargetCompanyReceived'
    const classCompany = type === 'sender' ? 'company-sender' : 'company-received'
    const companyId = $(`#${idComponent}`).find(`.${classCompany}`).val();
    const companyName = $(`#${idComponent}`).find('.select2-selection__rendered').attr('title')
    const idModalShowQuotation = this._fetchIdDetailQuotation(type);
    if (!orderId) {
      Popup.alert(I18n.t('work.quotation_cannot_blank'));
      return
    }
    const order = await this.fetchOrderOfTicket(workId, orderId)
    if (!order) return
    const componentShowOrder = type === 'sender' ? $('body').find('#showOrderSender') :
                                                   $('body').find('#showOrderReceived')
    this._renderOrderToComponentShowOrder(orderId, companyId, type, companyName, order);
    $(`#${idComponent}`).modal('hide');
    this._showHideListButton(type, $(`#${idModalShowQuotation}`));
    componentShowOrder.modal('show');
  }

  backToSelectCompany(element, type) {
    let componentSelectQuotation
    const backToModal = $(element).parents('div#showOrderSender').attr('back-to-modal');
    if (backToModal === 'newSender') {
      componentSelectQuotation = $('body').find('div#selectTargetNewCompanySender');
    }
    else if (backToModal === 'editSender') {
      componentSelectQuotation = $('body').find('div#selectTargetEditCompanySender');
    } else {
      componentSelectQuotation = this._fetchComponentSelectQuotation($(element), type);
      type === 'sender' && componentSelectQuotation.find('select.company-sender').trigger('change')
    }
    componentSelectQuotation.modal('show');
  }

  acceptCompanyOrder(element, type) {
    const classComponent = type === 'sender' ? 'showOrderSender' : 'showOrderReceived'
    const selected = type === 'sender' ? this.selectCompanySender : this.selectCompanyReceived
    const workEle = selected.parents('tr');
    const componentShowOrder = $(element).parents(`#${classComponent}`);
    const orderId = componentShowOrder.find('.order-id').val();
    const companyId = componentShowOrder.find('.company-id').val();
    const classOrderId = type === 'sender' ? 'quotation_sender' : 'work_quotation_id'
    const classCompanyId = type === 'sender' ? 'company_received_order' : 'company_ordered'
    workEle.find(`.${classCompanyId}`).val(companyId);
    workEle.find(`.${classOrderId}`).val(orderId);
    componentShowOrder.modal('hide');
    Ticket.save(selected);
  }

  _renderOrderToComponentShowOrder(orderId, companyId, type, companyName, order) {
    const componentShowOrder = type === 'sender' ? $('body').find('#showOrderSender') :
                                                       $('body').find('#showOrderReceived')
    const orders = this._getListQuotation(type);
    // const order = this._getQuotation(orders, orderId);
    const quotationPrice = `ID: ${order.id}  |  ${this.addPeriod(order.amount)}  円`;
    let orderTypeText = order.is_create_by_quotation ? '見積書' : '発注書'
    componentShowOrder.find('.order-type').text(orderTypeText)
    componentShowOrder.find('.company-id').val(companyId)
    componentShowOrder.find('.company-order-name').text(companyName);
    componentShowOrder.find('.order-id').val(orderId);
    componentShowOrder.find('.order-amount').text(quotationPrice);
    componentShowOrder.find('.order-name').text(order.order_name);
    componentShowOrder.find('.order-contract-type').text(order.contract_type);
    componentShowOrder.find('.order-contract-sub-type')
                      .text(I18n.t(`quotation.contract_sub_type.${order.contract_sub_type}`));
    const timeBySelectOrder = this._fetchTimeBySelectOrder(order)
    componentShowOrder.find('.time-by-order').text(timeBySelectOrder.orderTime);
    componentShowOrder.find('.title-time-by-order').text(timeBySelectOrder.titleOrderTime);
  }

  _fetchTimeBySelectOrder(order) {
    const currentDate = new Date()
    let orderTime = ''
    let titleOrderTime = ''
    switch (order.contract_sub_type) {
      case 'by_time':
        titleOrderTime = I18n.t('work.delivery_meter')
        orderTime = `${order.time_by_select_quotation}   /h`
        break;
      case 'by_number':
        titleOrderTime = I18n.t('work.delivery_meter')
        orderTime = `${order.time_by_select_quotation}   /個`
        break;
      case 'by_hours':
        titleOrderTime = I18n.t('work.time_remaining')
        orderTime = `${order.time_by_select_quotation}   /h`
        break;
      case 'fixed_quantity':
        titleOrderTime = I18n.t('work.delivery_meter')
        orderTime = `${order.time_by_select_quotation}   /個`
        break;
      default:
        titleOrderTime = I18n.t('work.contract_period');
        orderTime = I18n.l("date.formats.ja", new Date(order.expiration_date_contract || currentDate));
    }
    return { titleOrderTime: titleOrderTime, orderTime: orderTime }
  }

  _handleSelectCompany(element, type) {
    const trSelected = type == 'sender' ? this.selectCompanySender.parents('tr') :
                                          this.selectCompanyReceived.parents('tr')
    const listClassWorkOrder = this._fetchListClassWorkOrder(type);
    if ($(element).val()) {

    } else {

    }
  }

  _fetchListClassWorkOrder(type) {
    const classCompany = type === 'sender' ? 'company_received_order' : 'company_ordered'
    const classQuotation = type === 'sender' ? 'quotation_sender' : 'work_quotation_id'
    const classWorkOrder = type === 'sender' ? 'work_order_received_id_sender' : 'work_order_received_id_received'
    return { classCompany: classCompany, classQuotation: classQuotation, classWorkOrder: classWorkOrder }
  }

  _fetchComponentSelectQuotation(element, type) {
    const idComponentSelectQuotation = type === 'sender' ? 'selectTargetCompanySender' : 'selectTargetCompanyReceived'
    return $('body').find(`div#${idComponentSelectQuotation}`);
  }

  showModalSelectQuotation(event, type){
    const logWork = this
    const trSelected = $(event.target).parents('tr');
    type == 'sender' ? this.selectCompanySender = $(event.target) : this.selectCompanyReceived = $(event.target)
    const status = trSelected.find('input.input_status').val();
    const workId = trSelected.find('input.work_id').val();
    const idModalSelectCompany = type == 'sender' ? 'selectTargetCompanySender' : 'selectTargetCompanyReceived'
    if (type == 'received') {
      $('body').find(`div#${idModalSelectCompany}`).find('select.company-received').attr('data-work-id', workId)
    }
    if (!trSelected.hasClass('detail_popup_outer')) {
      const classOrderId = type == 'sender' ? 'quotation_sender' : 'work_quotation_id'
      const classCompanyId = type == 'sender' ? 'company_received_order' : 'company_ordered'
      const ClassWorkOrderId = type == 'sender' ? 'work_order_received_id_sender' : 'work_order_received_id_received'
      const classSelectCompany = type == 'sender' ? 'company-sender' : 'company-received'
      const listCompany = type == 'sender' ? gon.suppliers : gon.owners
      const companyName = type === 'sender' ? $(event.target).parents('td').attr('data-company-name') :
                                              $(event.target).parents('td').attr('data-company-received-name')
      const companyId = trSelected.find(`input.${classCompanyId}`).val();
      const orderId = trSelected.find(`input.${classOrderId}`).val();
      if (companyId && orderId) {
        if (type === 'sender') {
          this._showQuotations(type, companyName, orderId);
        } else {
          $(`#${idModalSelectCompany}`).find(`select.${classSelectCompany}`).val(companyId)
          const text = $(`#${idModalSelectCompany}`).find(`select.${classSelectCompany} option:selected`).text()
          $(`#${idModalSelectCompany}`).find('#select2--container').text(text)
          logWork.selectCompany($(`#${idModalSelectCompany}`).find(`select.${classSelectCompany}`), type, false)
          $('body').find(`div#${idModalSelectCompany}`).find('#btn-delete-client').removeClass('d-none')
          $('body').find(`div#${idModalSelectCompany}`).modal('show')
        }
      } else if (companyId) {
        $(`#${idModalSelectCompany}`).find(`select.${classSelectCompany}`).val(companyId).trigger('change');
        $('body').find(`div#${idModalSelectCompany}`).find('#btn-delete-client').removeClass('d-none')
        $('body').find(`div#${idModalSelectCompany}`).find('.btn-select-quotation').removeClass('d-none')
        $(`#${idModalSelectCompany}`).find(`select.select-quotations`).removeAttr('disabled')
        $('body').find(`div#${idModalSelectCompany}`).modal('show');
      } else if (listCompany.length > 0){
        $('body').find(`div#${idModalSelectCompany}`).find('.btn-select-quotation').removeClass('d-none')
        $(`#${idModalSelectCompany}`).find(`select.${classSelectCompany}`).val(companyId).trigger('change');
        $('body').find(`div#${idModalSelectCompany}`).find('#btn-delete-client').addClass('d-none')
        $('body').find(`div#${idModalSelectCompany}`).modal('show');
      } else {
        const message = type === 'sender' ? I18n.t('shared.not_supplier') : I18n.t('shared.not_client')
        Popup.notice(message, 'warning');
      }
    }
  }

  showModalOrderSupplier(event) {
    const trSelected = $(event.target).parents('tr');
    const workId = trSelected.find('.work_id').val();
    const projectId = this._fetchProjectId();
    const me = this
    this.selectCompanySender = $(event.target)
    Shared.callAjax(Routes.owner_project_ticket_suppliers_path(projectId, workId))
    .done(function(result) {
      let idModalSelectCompany = 'selectTargetNewCompanySender';
      me._addSupplierToSelect(
        result.suppliers_have_not_ordered_yet, idModalSelectCompany, workId, result.supplier_have_ordered_first
      );
      if (result.ordered) {
        idModalSelectCompany = 'selectTargetCompanySender';
        me._addSupplierToSelect(
          result.suppliers_have_ordered, idModalSelectCompany, workId, result.supplier_have_ordered_first
        );
      } else {
        $(`#${idModalSelectCompany}`).find('button.btn-secondary').attr('onclick', '');
      }
      setTimeout(function() {
        $('body').find(`div#${idModalSelectCompany}`).modal('show');
      }, 500)
    })
  }

  _addSupplierToSelect(suppliers, idModalSelectCompany, workId, supplier_have_ordered_first) {
    const selectCompany = $(`#${idModalSelectCompany}`).find(`select.company-sender`);
    selectCompany.empty().attr('disabled', false)
    selectCompany.attr('data-work-id', workId)
    if (idModalSelectCompany === 'selectTargetNewCompanySender') selectCompany.append($('<option>'))
    suppliers.forEach(supplier => {
      selectCompany.append(
        $(
          '<option>',
          { value: supplier.id, text: supplier.name, selected: supplier_have_ordered_first === supplier.id }
        )
      )
    });
    selectCompany.trigger('change')
  }

  openModalAddNewSupplier() {
    const componentSelectWorkOrder = $('#selectTargetNewCompanySender');
    componentSelectWorkOrder.find('button.btn-primary').attr('work-order-id', '');
    const selectCompanyEle = componentSelectWorkOrder.find('select.company-sender');
    selectCompanyEle.trigger('change')
    componentSelectWorkOrder.find('button.btn-secondary')
                            .attr('onclick', "logWorkAction.backToSelectCompany(this, 'sender')")
    componentSelectWorkOrder.modal('show')
  }

  handleSelectSupplier(event) {
    const $ele = $(event.target);
    const workId = $ele.attr('data-work-id');
    const companyId = $ele.val();
    const projectId = this._fetchProjectId();
    const componentSelectQuotation = $ele.parents('.modal')
    $('button.btn-show-detail-order').hide();
    if (companyId) {
      Shared.callAjax(Routes.owner_project_ticket_orders_path(projectId, workId), { company_id: companyId })
      .done(function(result){
        logWorkAction._removeSelectOrder(componentSelectQuotation);
        if (result.work_order?.order_id) {
          $('input[name="work_order_sender_id"]').val(result.work_order.id)
          $('.icon-edit-order-supplier').hide();
          $('button.btn-show-detail-order').show();
        } else {
          $('.icon-edit-order-supplier').show();
        }
        if(result.orders.length > 0 ) {
          result.orders.forEach(order => {
            componentSelectQuotation.find('.select-quotations').append(
              $('<option>', {
                value: order.id, ownerId: order.owner_id,
                createbyquo: order.is_create_by_quotation,
                selected: result.work_order?.order_id === order.id,
                order_type_jp: order.order_type_jp,
                enabled: order.is_enabled,
                'data-class': !order.is_enabled || order.is_used_other ? 'opacity-blur' : '',
                'data-used-other': order.is_used_other,
                'data-ticket-name': order.ticket_name,
                'data-is-expiration': order.is_expiration,
                text: order.name || order.id,
                ordered: result.work_order?.order_id === order.id
              })
            )
          })
          logWorkAction.listQuotationSender = result.orders
        }
        componentSelectQuotation.find(`select.select-quotations`).attr('data-type-of-ticket', result?.unit)
        componentSelectQuotation.find('.select-quotations').attr('work-order-id', result.work_order?.id)
      })
      .fail(function(){
      })
    } else {
      logWorkAction._removeSelectOrder(componentSelectQuotation);
    }
  }

  confirmOrderSupplier(element) {
    const $ele = $(element);
    const modalEle = $ele.parents('div.modal')
    const companySelectEle = modalEle.find('select.company-sender')
    const workId = companySelectEle.attr('data-work-id');
    const orderSelectEle = modalEle.find('select.select-quotations')
    const inputWorkId = $ele.parents('body').find(`input[name="work[id][${workId}]"]`)
    const trWork = inputWorkId.parents('tr')
    const workParentEle = trWork.parents('tbody').find('tr:first');
    trWork.find('.work_order_received_id_sender').val($ele.attr('work-order-id'))
    if (orderSelectEle.val()) {
      Shared.callAjax(
        Routes.check_quantity_present_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId()),
        { work_id: workId, order_id: orderSelectEle.val() }
      )
      .done(function(data) {
        if (data.is_select_order) {
          if (data.is_show_popup) {
            Popup.confirm(
              I18n.t('work.confirm_select_order', {company_name: data.company_name, order_id: orderSelectEle.val()}),
              {
                success: () => {
                  trWork.append(`<input type="hidden" id="append-order-${workId}" value="true">`)
                  Popup.alert(I18n.t('work.order_linked_work', { order_id: orderSelectEle.val() }))
                  setTimeout(() => {
                    appendCompanyAndOrder(workParentEle, orderSelectEle, inputWorkId, companySelectEle)
                  }, 1000);
                },
                confirmText: I18n.t('shared.connect'),
                cancelText: I18n.t('shared.not_connect'),
                cancel: () => {
                  appendCompanyAndOrder(workParentEle, orderSelectEle, inputWorkId, companySelectEle)
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
              }
            )
          } else {
            setTimeout(() => {
              appendCompanyAndOrder(workParentEle, orderSelectEle, inputWorkId, companySelectEle)
            }, 600);
          }
        } else {
          Popup.alert(I18n.t('work.cannot_select_order'))
        }
      })
    } else {
      appendCompanyAndOrder(workParentEle, orderSelectEle, inputWorkId, companySelectEle)
    }
  }

  setRepresentativeSupplier(element) {
    const $ele = $(element);
    const modal = $ele.parents('#selectTargetCompanySender');
    const companyId = modal.find('select.company-sender option:selected').val();
    const workId = modal.find('select.company-sender').attr('data-work-id');
    $('input[name="work_representative_supplier_id"]').val(companyId);
    const inputWorkId = $ele.parents('body').find(`input[name="work[id][${workId}]"]`)
    modal.modal('hide');
    Ticket.save(inputWorkId)
  }

  openModalEditOrder(event) {
    const $ele = $(event.target);
    event.preventDefault();
    const workID = $ele.parents('div#selectTargetCompanySender').find(`select.company-sender`).attr('data-work-id')
    const $modal = $('#selectTargetEditCompanySender');
    const $selectSupplier = $modal.find('select.company-sender')
    const currentModal = $ele.parents('#selectTargetCompanySender')
    const supplier = currentModal.find('select.company-sender option:selected')
    const workOrderId = currentModal.find('select.select-quotations').attr('work-order-id')
    $selectSupplier.empty().append(
      $('<option>', { value: supplier.val(), selected: true, text: supplier.text() })
    )
    $selectSupplier.attr('data-work-id', workID)
    $selectSupplier.attr('disabled', true).trigger('change');
    currentModal.modal('hide');
    $modal.find('button.btn-primary').attr('work-order-id', workOrderId)
    $modal.modal('show');
  }

  async showDetailOrderOfSupplier(element, type) {
    const senderType = type === 'sender';
    let idComponent = senderType ? 'selectTargetCompanySender' : 'selectTargetNewCompanySender'
    if (type === 'editSender') {
      idComponent = 'selectTargetEditCompanySender'
    }
    const orderId = $(`#${idComponent}`).find('.select-quotations').val();
    const orderedAttr = $(`#${idComponent}`).find('.select-quotations option:selected').attr('ordered');
    const companyId = $(`#${idComponent}`).find('.company-sender option:selected').val();
    const companyName = $(`#${idComponent}`).find('.company-sender option:selected').text();
    const idModalShowQuotation = this._fetchIdDetailQuotation('sender');
    const selected = this.selectCompanySender;
    const workEle = selected.parents('tr');
    const workId = workEle.find('input.work_id').val()
    const listClassWorkOrder = this._fetchListClassWorkOrder('sender');
    workEle.find(`.${listClassWorkOrder.classCompany}`).val(companyId);
    workEle.find(`.${listClassWorkOrder.classQuotation}`).val(orderId);
    if (!orderId) {
      Popup.alert(I18n.t('work.quotation_cannot_blank'));
      return
    }
    const order = await this.fetchOrderOfTicket(workId, orderId)
    if (!order) return
    const componentShowOrder = $('body').find('#showOrderSender')
    this._renderOrderToComponentShowOrder(orderId, companyId, 'sender', companyName, order);
    $(`#${idComponent}`).modal('hide');
    this._showHideListButton('sender', $(`#${idModalShowQuotation}`));
    componentShowOrder.attr('back-to-modal', type);
    if (idComponent === 'selectTargetCompanySender') {
      componentShowOrder.find('button.btn-primary').hide()
    } else {
      componentShowOrder.find('button.btn-primary').show()
    }
    componentShowOrder.find('button.btn-secondary')
                      .attr('onclick', "logWorkAction.backToSelectCompany(this, 'sender')")
    if (orderId && orderedAttr === 'true') {
      componentShowOrder.find('button.remove-order-id').show()
    } else {
      componentShowOrder.find('button.remove-order-id').hide()
    }
    componentShowOrder.modal('show');
  }

  async fetchOrderOfTicket(workId, orderId) {
    let order;
    await Shared.callAjax(
      Routes.fetch_order_with_ticket_owner_project_task_ticket_path(this._fetchProjectId(), this._fetchTaskId()),
      { work_id: workId, order_id: orderId }
    )
    .done(function(data) {
      order = data
    })
    .fail(function(data) {
      Popup.alert(data.message)
    })
    return order
  }

  toggleDetailTicket(element) {
    const workId = $(element).parents('tr').find('.work_id').val();
    Visiable.toggle($(element).parents('tbody').find(`#ticketDetailPopup-${workId}`))
  }

  _toggleDisabledSelectCompany(idModal, classSelectCompany, isBoolean) {
    Disabled.toggle($(`#${idModal} select.${classSelectCompany}`), isBoolean)
  }

  _toggleQuantityType(orderType, element, quantity) {
    $(document).find('.modal-backdrop').remove()
    this.disableSortWork();
    if (orderType == 'by_time') {
      $(element).removeClass('type_time type_number').addClass('type_time')
      // $(element).find('.text-unit-quantity').text(I18n.t('work.limited_to_time'))
      $(element).find('.text-unit-quantity').text('時間')
      Visiable.hide(element.find('.number-quantity'));
      Visiable.show($(element).find('.time-quantity'))
      Visiable.toggle(element.find('div.work-time'), false);
      Visiable.toggle(element.find('div.quantity-work'), true);
      $(element).find('.list-time-title').attr('colspan', 4)
      $(element).find('.text-quantity-title').text(I18n.t('by_time'))
      $(element).find('.col-title-quantity').removeClass('col-xl-3 col-3').addClass('col-xl-4 col-4');
    } else {
      $(element).removeClass('type_time type_number').addClass('type_number')
      // $(element).find('.text-unit-quantity').text(I18n.t('work.limited_to_number'))
      $(element).find('.text-unit-quantity').text('個')
      Visiable.show(element.find('.number-quantity'));
      Visiable.hide($(element).find('.time-quantity'))
      Visiable.toggle(element.find('div.work-time'), true);
      Visiable.toggle(element.find('div.quantity-work'), false);
      $(element).find('.list-time-title').attr('colspan', 2)
      $(element).find('.text-quantity-title').text(I18n.t('by_number'))
      $(element).find('.col-title-quantity').removeClass('col-xl-4 col-4').addClass('col-xl-3 col-3')
    }
    element.find('input.quantity-type').val(orderType);
    this._resideComponentByOrderType($(element), orderType);
    element.find('select#select-unit-of-quantity').val(orderType)
    element.modal('show');
    if (element.find('.list-work-quantity').find('.work-quantities').length > 0) {
      element.find('.list-work-quantity').find('.th-quantities')
             .find('.cell').removeClass('border-bottom-2-cerulean-blue');
      element.find('.list-work-quantity').find('.work-quantities:last')
             .removeClass('border-bottom-2-cerulean-blue')
    }
    if ($(document).find('.modal-backdrop').length > 1) {
      $(document).find('.modal-backdrop')[1].remove()
    }
  }

  _toTimeAmount(time) {
    const hourTime = parseInt(time)
    const minutesTime = time - hourTime
    return [hourTime, Math.round(minutesTime * 60)]
  }

  _toAmount(hours, minutes) {
    const hourAmount = hours || 0
    const minutesAmount = minutes || 0
    return hourAmount + (minutesAmount / 60)
  }

  async addWorkQuantity(element) {
    const modal = $(element).parents('.show-work-quantity');
    const orderType = $(element).parents('tbody').find('tr:first').find('.render_unit_work').val();
    const workDescription = $(modal).find('.work-quantity-description').val();
    const workQuantity = $(modal).find('.work-quantity-clone');

    workQuantity.removeClass('d-none');

    let newWorkQuantity = modal.find('.work-quantity-clone2').clone();
    newWorkQuantity.removeClass('d-none')
    newWorkQuantity.find('.date-current').val($(modal).find('.work-quantity-clone').find('.date-current').val());

    const workId = $(element).parents('tbody').attr('index');

    let hours, minute, quantity;
    if (orderType === 'by_time') {
      hours = $(workQuantity).find('.quantity-hours-input').val();
      newWorkQuantity.find('.quantity-hours-input').val(hours);
      minute = $(workQuantity).find('.quantity-minutes-input').val();
      if (minute === ''){
        newWorkQuantity.find('.quantity-minutes-input').val('00');
      }else {
        newWorkQuantity.find('.quantity-minutes-input').val(minute);
      }
      newWorkQuantity.find('.quantity-number').val(this._toAmount(Number(hours), Number(minute)));
    } else {
      quantity = $(workQuantity).find('.quantity-number').val();
      let quantitySplit = quantity.split('.');
      if (quantitySplit.length === 2 && quantitySplit[1] === '') quantity = quantitySplit.join('');
      newWorkQuantity.find('.quantity-number').val(quantity);
    }

    let message = ''
    if (!workDescription) {
      Shared.errorFieldMessage($(workQuantity).find('.work-quantity-description'), I18n.t('work.cannot_blank_description'));
      message += (I18n.t('work.cannot_blank_description') + '<br>');
    }
    if (orderType === 'by_time' && Number(hours) === 0 && Number(minute) === 0) {
      message += (I18n.t('work.cannot_blank_time') + '<br>');
    }
    if (orderType !== 'by_time' && Number(quantity) === 0) {
      message += (I18n.t('work.cannot_blank_quantity') + '<br>')
    }
    if (message !== '') {
      Popup.alert(message, 'warning');
      return
    }
    newWorkQuantity.find('.work-quantity-description').val(workDescription)
    const index = new Date().getTime();
    newWorkQuantity.removeClass('work-quantity-clone2').addClass('work-quantity-new work-quantities');
    // Visiable.show(newWorkQuantity.find('.input-check-box-quantity'))
    // Visiable.show(newWorkQuantity.find('.delete-work-quantity'))
    // newWorkQuantity.find('.date-current_outer').removeClass('col-md-3').addClass('col-lg-2')
    newWorkQuantity.find('.date-current').removeClass('date-current').addClass('date-created-at-new');
    // newWorkQuantity.find('.quantity-number_outer').removeClass('col-md-2').addClass('col-lg-2');
    newWorkQuantity.find('.quantity-number').addClass('work_quantity');
    // newWorkQuantity.find('.quantity-number_text').removeClass('col-md-2 col-4').addClass('col-lg-1 col-3');
    newWorkQuantity.find('input').toArray().forEach(input => {
      if ($(input).attr('fix_name')) {
        $(input).attr('name', `${$(input).attr('fix_name')}[${index}]`)
      }
    })
    const workQuantityNew = Number(newWorkQuantity.find('.quantity-number').val());
    const totalAllWorkQuantity = this._totalAllWorkQuantity(modal)
    const workParentEle = $(element).parents('tbody').find('tr:first');
    const orders = [workParentEle.find('.work_quotation_id').val(), workParentEle.find('.quotation_sender').val()]
    const workIdCurrent = $(element).parents('tr').find('.work_id').val();
    const quantityWithOrder = await this._isMuchQuantity(workIdCurrent, orders, totalAllWorkQuantity + workQuantityNew)
    if (quantityWithOrder.is_much_quantity) {
      Popup.alert('制限を超えた為、追加できません', 'warning');
      return
    }
    if (quantityWithOrder.is_not_create) {
      Popup.alert('解除済みの発注書が紐づいている為、<br>作業追加できません。')
      return
    }

    const data = { work_quantity: {
      quantity: workQuantityNew, description: workDescription, work_id: workId,
      created_at: $(modal).find('.work-quantity-clone').find('.date-current').val(), unit_type: orderType
    } }
    await Shared.callAjax(Routes.efforts_path(), data, 'html')
    .done(function(result) {
      const quantityComponent = $(`div#modal-quantity-${workId}`)
      quantityComponent.find('div.render-work-quantity-new').html(result)

      if (orderType === 'by_time') {
        Visiable.hide($(modal).find('.number-quantity'));
        Visiable.show($(modal).find('.time-quantity'))
      } else {
        Visiable.show($(modal).find('.number-quantity'));
        Visiable.hide($(modal).find('.time-quantity'))
      }
      $(modal).find('.work-quantity-clone').find('input:not(.user-name)').val('');
      $(modal).find('.work-quantity-clone').find('.date-current').val(I18n.l("date.formats.default", new Date()));
    })
    .fail(function(data) {
      if (!!data.responseText) {
        Popup.alert(data.responseText)
      }
    })

    this.runDatePicker();
  }

  _renderTotalQuantity(quantityComponent, allWorks) {
    quantityComponent.find('.total-hours').val(allWorks.totalHours);
    quantityComponent.find('.total-minutes').val(allWorks.totalMinutes);
    quantityComponent.find('.total-number-quantity').val(allWorks.totalQuantity.toFixed(gon.number_round));
    quantityComponent.find('.quantity-work').val(allWorks.totalQuantity);
    this._calculateQuantity(quantityComponent);
    const showQuantityEle = $(quantityComponent).parents('tr').find('.show-quantity')
    if (isNaN(showQuantityEle.text())) return
    showQuantityEle.text(allWorks.totalQuantity.toFixed(gon.number_round));
  }

  validateQuantityDescription(element) {
    const quantityDescription = $(element).val();
    if (quantityDescription.length > gon.log_work_feature.max_description_work_quantity) {
      $(element).val(quantityDescription.slice(0, 20))
    }
    this.addClassChangeWorkQuantity($(element));
  }

  addClassChangeWorkQuantity(element) {
    if ($(element).parents('.work-quantity-old')) $(element).addClass('change-work-quantity');
  }

  _isChangeWorkQuantity(listWorkQuantity) {
    return listWorkQuantity.find('.change-work-quantity').length > 0
  }

  async removeWorkQuantity(element) {
    const workQuantityEle = $(element).parents('tr.work-quantities')
    const workQuantityId = workQuantityEle.find('.work-quantity-id').val()
    const workId = workQuantityEle.find('.work-quantity-work-id').val()
    if (!gon.log_work_feature.is_owner_project || workQuantityId.trim() === '') {
      Popup.confirm(I18n.t('work.do_you_want_delete_quantity'), {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => this.actionRemoveQuantity(element),
        cancel: () => {}
      })
    } else {
      let isShowDelete;
      let isSubmitted;
      let companyName;

      await Shared.callAjax(
        Routes.check_delete_work_quantity_owner_project_task_ticket_path(
          this._fetchProjectId(), this._fetchTaskId()), {
        work_id: workId,
        work_quantity_id: workQuantityId
      })
      .done(function(data) {
        isShowDelete = data.is_show_delete
        isSubmitted = data.is_submitted
        companyName = data.company_name
      })
      .fail(function(data) {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      })
      if (isShowDelete) {
        Popup.confirm(I18n.t('work.do_you_want_delete_quantity'), {
          confirmText: 'OK',
          cancelBtn: 'false',
          success: () => this.actionRemoveQuantity(element),
          cancel: () => {}
        })
      } else {
        const text = isSubmitted ? I18n.t('work.do_you_reject_quantity', { company_name: companyName }) : I18n.t('work.do_you_reject_quantity_not_submited', { company_name: companyName })
        Popup.confirm(text, {
          confirmText: 'OK',
          cancelBtn: 'false',
          success: () => this.rejectQuantity(element),
          cancel: () => {}
        })
      }
    }
  }

  changeQuantityToReject(element) {
    Popup.confirm(I18n.t('work.cancal_request_remove_quantity'), {
      confirmText: 'OK',
      cancelBtn: 'false',
      success: () => this.actionCancelRequestDeleteQuantity(element),
      cancel: () => {}
    })
  }

  actionCancelRequestDeleteQuantity(element) {
    const workQuantityEle = $(element).parents('tr.work-quantities')
    const status = workQuantityEle.find('input.check-quantity-status').data('status_was')
    this.changeStatusWorkQuantity(element, status)
  }

  rejectQuantity(element) {
    this.changeStatusWorkQuantity(element, 'rejected')
  }

  changeStatusWorkQuantity(element, status) {
    const workQuantityEle = $(element).parents('tr.work-quantities')
    const modalEle = workQuantityEle.parents('div.modal-body')
    const workQuantityId = workQuantityEle.find('.work-quantity-id').val()
    const workId = workQuantityEle.find('.work-quantity-work-id').val()
    const tableListCompany = modalEle.find('table.change-company-quantity')
    const companyId = tableListCompany.attr('data-select')
    const dataIndexTr = $(`#render-info-quantity-${workId}`).find('#show_tr_index_with_quantity').val()
    Shared.callAjax(
      Routes.change_status_quantity_owner_project_task_ticket_path(
        this._fetchProjectId(), this._fetchTaskId()), {
      work_id: workId,
      work_quantity_id: workQuantityId,
      status: status,
      company_id: companyId
    }, 'html')
    .done(function(data) {
      $(`#render-info-quantity-${workId}`).html(data)
      $(`#render-info-quantity-${workId}`).find(`tr[data-index="${dataIndexTr}"]`).trigger('click')
    })
    .fail(function(data) {
      if (data.responseText) {
        Popup.alert(data.responseText)
      } else {
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      }
    })
  }

  actionRemoveQuantity(element) {
    const status = $(element).parents('tr.tr_index').find('.input_status').val();
    const quantityComponent = $(element).parents('.show-work-quantity');
    const btnAddQuantity = quantityComponent.find('.add-work-quantity');
    if (!gon.log_work_feature.status_delivered.includes(status)) {
      const workQuantity = $(element).parents('.work-quantities');
      workQuantity.addClass('d-none work-quantities-delete').removeClass('work-quantities');
      const allWorks = this._fetchAllWorksQuantity(btnAddQuantity);
      this._renderTotalQuantity(quantityComponent, allWorks);
    }
    if (quantityComponent.find('.list-work-quantity').find('.work-quantities').length === 0) {
      quantityComponent.find('.list-work-quantity').find('.cell').addClass('border-bottom-2-cerulean-blue')
    } else {
      // quantityComponent.find('.list-work-quantity').find('.work-quantities:last').find('.cell')
      //                .addClass('border-bottom-2-cerulean-blue')
    }
  }

  _moveWorkEleToTopTable(workEle) {
    workEle.prependTo('.invoice__table--main');
  }

  selectAllToChangeStatusEffort(element, workId) {
    const $ele = $(element);
    const isChecked = $ele.attr('data-checked')
    const editStatusElement = $(`#edit-status-efforts-for-work-${workId}`);
    if (isChecked == 'true') {
      $ele.attr('data-content', '')
      $ele.attr('data-checked', false)
    } else {
      $ele.attr('data-content', '✔')
      $ele.attr('data-checked', true)
    }

    $(`#list-work-quantity-${workId} tr.work-quantities td.status-quantity`).each(function(){
      const labelElement = $(this).find('.check-label');
      if (labelElement.attr('data-is-supplier-and-not-pending') != 'true' &&
          labelElement.attr('data-is-client-and-released') != 'true'
         ) {
        const elementQuantityId = $(this).find('input.work-quantity-id-to-set-status')

        if (isChecked == 'true') {
          labelElement.attr('data-content', '')
        } else {
          labelElement.attr('data-content', '✔')
        }
        elementQuantityId.attr('checked', !(isChecked == 'true'))
      }
    })

    const quantityIds = this._inputQuantityIdToSetStatusList(workId);
    if (quantityIds.length > 0) {
      editStatusElement.removeClass('pointer-none opacity-blur')
    } else {
      editStatusElement.addClass('pointer-none opacity-blur')
    }
  }

  approvalWorkQuantity(element) {
    if ($(element).attr('data-is-client-and-released') == 'true') {
      $('#modalCannotChangeStatus').find('div.content-modal-text').text('納品済み作業は変更できません。')
      $('#modalCannotChangeStatus').modal('show')
      return
    }

    if ($(element).attr('data-is-supplier-and-not-pending') == 'true') {
      $('#modalCannotChangeStatus').find('div.content-modal-text').text('提出済み作業は変更できません。')
      $('#modalCannotChangeStatus').modal('show')
      return;
    }
    const quantityId = $(element).data('quantity-id')
    const workId = $(element).data('work-id')
    const elementQuantityId = $(element).parents('td.status-quantity').find('input.work-quantity-id-to-set-status')
    const oldValueOfStatusEle = elementQuantityId.attr('checked')
    const labelCheckBoxStatus = $(`label[data-quantity-id="${quantityId}"]`)
    const editStatusElement = $(`#edit-status-efforts-for-work-${workId}`);

    if (oldValueOfStatusEle) {
      labelCheckBoxStatus.attr('data-content', '')
    } else {
      labelCheckBoxStatus.attr('data-content', '✔')
    }
    elementQuantityId.attr('checked', !oldValueOfStatusEle)

    const quantityIds = this._inputQuantityIdToSetStatusList(workId);
    if (quantityIds.length > 0) {
      editStatusElement.removeClass('pointer-none opacity-blur')
    } else {
      editStatusElement.addClass('pointer-none opacity-blur')
    }
    const $checkAll = $(`#list-work-quantity-${workId} label.th-check-label-custom`);
    if ($(`#list-work-quantity-${workId} tr.work-quantities td.status-quantity`).length == quantityIds.length) {
      $checkAll.attr('data-content', '✔')
      $checkAll.attr('data-checked', true)
    } else {
      $checkAll.attr('data-content', '')
      $checkAll.attr('data-checked', false)
    }
  }

  showchangeStatusEffortsOptions(element, workId, companyId) {
    const me = this;
    const quantityIds = this._inputQuantityIdToSetStatusList(workId);
    if (quantityIds.length <= 0) {
      return
    }

    Shared.callAjax(Routes.change_status_efforts_options_efforts_path(), { work_id: workId, company_id: companyId, quantity_ids: quantityIds }, 'html')
    .done(function(data) {
      if (data.trim() === 'client_release_effort') {
        me._clientReleaseEffortConfirm(companyId, workId, 'client_release_effort')
      } else {
        $('#modalChangeStatusEffortOptions').find('#content_change_status_effort_options').html(data);
        $('#modalChangeStatusEffortOptions').modal('show')
      }
    })
  }

  changeStatusEfforts(element, companyId, workId, option) {
    const quantityIds = this._inputQuantityIdToSetStatusList(workId);
    if (quantityIds.length <= 0) {
      return
    }
    switch(option) {
      case 'submit_effort_option':
        this._submitToOwnerConfirm(companyId, workId, option)
        break;
      case 'delete_effort_option':
        this._supplierDeleteEffortConfirm(companyId, workId, option)
        break;
      case 'owner_release_effort_of_supplier_option':
      case "owner_release_of_supplier_and_submit_to_client_option":
      case 'owner_release_effort_to_client_option':
        this._ownerReleaseEffortSupplierConfirm(companyId, workId, option)
        break;
      case 'submit_effort_of_supplier_to_client_option':
        this._ownerSubmitEffortSupplierToClientConfirm(companyId, workId, option)
        break;
      case 'owner_unsubmit_effort_of_supplier_option':
        this._ownerUnsubmitEffortOfSupplierConfirm(companyId, workId, option)
        break;
      case 'owner_cancel_effort_released_of_supplier_option':
        this._ownerCancelEffortReleasedOfSupplierConfirm(companyId, workId, option)
        break;
      case 'owner_submit_its_own_effort_option':
        this._ownerSubmitItsOwnEffortConfirm(companyId, workId, option)
        break;
      case 'owner_delete_its_own_effort_option':
        this._ownerDeleteItsOwnEffortConfirm(companyId, workId, option, element)
        break;
      case 'owner_cancel_effort_submitted_to_client_option':
        this._ownerCancelEffortSubmittedToClientConfirm(companyId, workId, option, element)
        break;
      case 'owner_cancel_effort_released_by_client_option':
        this._ownerCancelEffortReleaseByClientConfirm(companyId, workId, option, element)
        break;
    }
  }

  handleOwnerReleaseEffortOfSupplier(element) {
    const $ele = $(element);
    const modal = $ele.parents('div#modalSelectReleaseDateEffort')
    const delivery_date = modal.find('input[name="work[delivery_date]"]').val()
    if (!delivery_date) {
      Popup.alert('納品日を選択してください。')
      return
    }
    const workId = modal.attr('data-work-id')
    const companyId = modal.attr('data-company-id')
    const option = modal.attr('data-option')
    const message = modal.attr('data-message-after-submit')
    this._requestHandleChangeStatusEfforts(companyId, workId, option, message, modal, delivery_date)
  }

  jumpToListEffortOfCompany(event, workId, companyId) {
    event.stopPropagation();
    if (!workId || !companyId) {
      return
    }

    Popup.close()
    $(`div#modal-quantity-${workId}`).find(`#show_list_company_quantity_work_${workId}`)
                                     .find(`div[data-company-id="${companyId}"]`).first().trigger('click')
  }

  _submitToOwnerConfirm(companyId, workId, option) {
    Popup.confirm(
      `この作業を販売先に提出します。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '提出済にしました。')
        },
        cancel: () => {},
        confirmText: '提出する',
        cancelText: 'キャンセル',
        width: 'auto'
      })
  }

  _supplierDeleteEffortConfirm(companyId, workId, option) {
    Popup.confirm(
      `この作業を削除します。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '削除しました。')
        },
        cancel: () => {},
        confirmText: '削除する',
        cancelText: 'キャンセル',
        width: 'auto'
      })
  }

  _ownerReleaseEffortSupplierConfirm(companyId, workId, option) {
    let message = '';
    let confirmText = '';
    let messageAfterSubmit = '';
    switch(option) {
      case 'owner_release_effort_of_supplier_option':
        message = `仕入先作業を納品にします。<br>宜しいですか？`
        confirmText = '納品する';
        messageAfterSubmit = '納品にしました。';
        break;
      case "owner_release_of_supplier_and_submit_to_client_option":
        message = `仕入先へ納品｜販売先へ提出します。<br>宜しいですか？`
        confirmText = '日付を入力';
        messageAfterSubmit = '仕入先へ納品｜販売先へ提出しました。';
        break;
      case 'owner_release_effort_to_client_option':
        message = `✔した作業を販売先に納品します。<br>宜しいですか？`
        confirmText = '納品する';
        messageAfterSubmit = '納品にしました。';
        break;
    }
    Popup.confirm(
      message, {
        success: () => {
          const modal = $('#modalSelectReleaseDateEffort')
          modal.attr('data-option', option)
          modal.attr('data-work-id', workId)
          modal.attr('data-company-id', companyId)
          modal.attr('data-message-after-submit', messageAfterSubmit)
          modal.modal('show')
        },
        cancel: () => {},
        confirmText: confirmText,
        cancelText: 'キャンセル',
        width: 'auto'
      })
  }

  _ownerSubmitEffortSupplierToClientConfirm(companyId, workId, option) {
    Popup.confirm(
      `　販売先に提出します。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '提出済にしました。')
        },
        cancel: () => {},
        confirmText: '提出する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerUnsubmitEffortOfSupplierConfirm(companyId, workId, option) {
    Popup.confirm(
      `仕入先作業を解除にします。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '削除しました。')
        },
        cancel: () => {},
        confirmText: '解除する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerCancelEffortReleasedOfSupplierConfirm(companyId, workId, option) {
    Popup.confirm(
      `仕入先納品作業を解除にします。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '納品を解除しました。')
        },
        cancel: () => {},
        confirmText: '解除する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerSubmitItsOwnEffortConfirm(companyId, workId, option) {
    Popup.confirm(
      `この作業を販売先に提出します。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '提出済にしました。')
        },
        cancel: () => {},
        confirmText: '提出する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerDeleteItsOwnEffortConfirm(companyId, workId, option, element) {
    if ($(element).attr('data-have-effort-not-pending') == 'true') {
      const clientId = $(element).attr('data-client-id')
      Popup.alert(
        `販売先へ提出済の作業があります。<br>提出済作業を削除する場合は<br><span class="text-underline cursor-pointer" onclick="logWorkAction.jumpToListEffortOfCompany(event, ${workId}, ${clientId})">販売先への提出を解除</span>してください。`
      )
      $('#modalChangeStatusEffortOptions').modal('hide')
      $('button.swal2-confirm').css('padding', '3px 36px')
      return
    }
    let confirmMessage = '';
    let confirmBtnText = '';
    if ($(element).attr('data-effort-customize') == 'true') {
      confirmMessage = `カスタマイズの作業は、<br>注文紐づけを解除すると削除できます。<br>紐づけを解除しますか？`
      confirmBtnText = '解除する'
    } else {
      confirmMessage = `✔した作業を削除します。<br>宜しいですか？`
      confirmBtnText = '削除する'
    }
    Popup.confirm(
      confirmMessage, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '削除しました。')
        },
        cancel: () => {},
        confirmText: confirmBtnText,
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerCancelEffortSubmittedToClientConfirm(companyId, workId, option) {
    Popup.confirm(
      `✔した作業を提出解除します。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '提出を解除しました。')
        },
        cancel: () => {},
        confirmText: '解除する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _ownerCancelEffortReleaseByClientConfirm(companyId, workId, option) {
    Popup.confirm(
      `納品作業を解除にします。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '納品を解除しました。')
        },
        cancel: () => {},
        confirmText: '削除する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _clientReleaseEffortConfirm(companyId, workId, option) {
    const deliveryDate = new Date()
    Popup.confirm(
      `この作業を納品にします。<br>宜しいですか？`, {
        success: () => {
          this._requestHandleChangeStatusEfforts(companyId, workId, option, '納品にしました。', '', deliveryDate)
        },
        cancel: () => {},
        confirmText: '納品する',
        cancelText: 'キャンセル',
        width: 'auto'
      }
    )
  }

  _requestHandleChangeStatusEfforts(companyId, workId, option, message, modalOption = '', delivery_date = '') {
    const quantityIds = this._inputQuantityIdToSetStatusList(workId);
    const me = this;
    if (quantityIds.length <= 0) {
      return
    }

    $('#modalChangeStatusEffortOptions').modal('hide')
    const url = Routes.check_release_effort_quotation_expiration_efforts_path()
    const data = {
      work_id: workId, company_id: companyId, quantity_ids: quantityIds, option: option, delivery_date: delivery_date
    };
    Shared.callAjax(
      url, data
    ).done(function (response) {
      if (response.status) {
        Popup.confirm('紐づいている注文書は期限切れの為、<br>納品しても支払請求には反映されません。<br>納品にしてよろしいですか？', {
          success: () => {
            me._handleChangeStatusEffort(workId, companyId, quantityIds, option, delivery_date, message)
          },
          cancel: () => {},
          confirmText: 'OK'
        })
      } else {
        me._handleChangeStatusEffort(workId, companyId, quantityIds, option, delivery_date, message)
      }
    }).fail(function (error) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
    if (!!modalOption) {
      modalOption.find('input[name="work[delivery_date]"]').val('')
      modalOption.modal('hide')
    }
  }

  _handleChangeStatusEffort(workId, companyId, quantityIds, option, delivery_date, message) {
    const me = this;
    Shared.callAjax(
      Routes.handle_change_status_efforts_path(),
      { work_id: workId, company_id: companyId, quantity_ids: quantityIds, option: option, delivery_date: delivery_date },
      'html'
    )
    .done(function(data) {
      if (!!message) { Popup.alert(message); }
      const quantityComponent = $(`div#modal-quantity-${workId}`)
      quantityComponent.find('div.render-work-quantity-new').html(data)
      quantityComponent.find(`#show_list_company_quantity_work_${workId}`)
                       .find(`div[data-company-id="${companyId}"]`).first().trigger('click')
      me.runDatePicker();
    })
    .fail(function(data) {
      if (!!data.responseText) {
        const errorInfo = data.responseText.split(';')
        if (errorInfo[0] === 'release_date_invalid') {
          me._showPopupWarningWhenInvoiceConfirmed(
            companyId, workId, option, delivery_date, errorInfo[1], errorInfo[2], errorInfo[3]
          )
        } else {
          Popup.alert(data.responseText)
        }
      }
    })
  }

  _inputQuantityIdToSetStatusList(workId) {
    let quantityIds = []
    $(`#list-work-quantity-${workId} input.work-quantity-id-to-set-status`).each(function(){
      if ($(this).prop('checked') == true) {
        quantityIds.push($(this).val())
      }
    })

    return quantityIds
  }

  _showPopupWarningWhenInvoiceConfirmed(
    companyId, workId, option, delivery_date, type, confirmedDate, nextConfirmedDate
  ) {
    const modal = $('#modalWarningWhenInvoiceConfirmed')
    if (type === 'client') {
      modal.find('.content-modal-text').html(
        `既に支払額の締め作業が完了しています。<br>
        ${confirmedDate}分として納品する場合は<br>
        「支払額変更」をクリックしてください。<br>
        ※支払額変更をクリック後、<br>${confirmedDate}納品分
        として支払額に追加され、<br>支払額の締め作業が自動的に再更新されます。<br>
        ※「納品する」で${nextConfirmedDate}納品として保存されます。`
      )
      modal.find('.btn-force-release').text('支払額変更')
    } else {
      modal.find('.content-modal-text').html(
        `既に請求額の締め作業が完了しています。<br>
        ${confirmedDate}分として納品する場合は<br>
        「請求額変更」をクリックしてください。<br>
        ※請求額変更をクリック後、<br>${confirmedDate}納品分
        として請求額に追加され、<br>請求額の締め作業が自動的に再更新されます。<br>
        ※「納品する」で${nextConfirmedDate}納品として保存されます。`
      )
    }
    modal.attr('data-company-id', companyId)
    modal.attr('data-work-id', workId)
    modal.attr('data-option', option)
    modal.attr('data-delivery-date', delivery_date)
    modal.attr('data-company-type', type)
    modal.modal('show')
  }

  forceReleaseEffort(element) {
    const $ele = $(element);
    const modal = $ele.parents('#modalWarningWhenInvoiceConfirmed')
    const option = modal.attr('data-option')
    const deliveryDate = modal.attr('data-delivery-date')
    const companyId = modal.attr('data-company-id')
    const workId = modal.attr('data-work-id')
    const quantityIds = this._inputQuantityIdToSetStatusList(workId);

    if (quantityIds.length <= 0) {
      return
    }

    Shared.callAjax(
      Routes.handle_change_status_efforts_path(),
      { work_id: workId, company_id: companyId, quantity_ids: quantityIds, option: option, delivery_date: deliveryDate, force_release: true },
      'html'
    )
    .done(function(data) {
      $('#modalWarningWhenInvoiceConfirmed').modal('hide')
      const quantityComponent = $(`div#modal-quantity-${workId}`)
      quantityComponent.find('div.render-work-quantity-new').html(data)
      quantityComponent.find(`#show_list_company_quantity_work_${workId}`)
                       .find(`div[data-company-id="${companyId}"]`).first().trigger('click')
      me.runDatePicker();
    })
    .fail(function(data) {
      if (!!data.responseText) {
        Popup.alert(data.responseText)
      }
    })
  }

  changeReleaseDateWhenReleaseEffort(element) {
    const $ele = $(element);
    const modal = $ele.parents('#modalWarningWhenInvoiceConfirmed')
    const option = modal.attr('data-option')
    const deliveryDate = modal.attr('data-delivery-date')
    const companyId = modal.attr('data-company-id')
    const workId = modal.attr('data-work-id')
    const quantityIds = this._inputQuantityIdToSetStatusList(workId);

    if (quantityIds.length <= 0) {
      return
    }

    Shared.callAjax(
      Routes.handle_change_status_efforts_path(),
      { work_id: workId, company_id: companyId, quantity_ids: quantityIds, option: option, delivery_date: deliveryDate, change_release_date: true },
      'html'
    )
    .done(function(data) {
      $('#modalWarningWhenInvoiceConfirmed').modal('hide')
      const quantityComponent = $(`div#modal-quantity-${workId}`)
      quantityComponent.find('div.render-work-quantity-new').html(data)
      quantityComponent.find(`#show_list_company_quantity_work_${workId}`)
                       .find(`div[data-company-id="${companyId}"]`).first().trigger('click')
      me.runDatePicker();
    })
    .fail(function(data) {
      if (!!data.responseText) {
        Popup.alert(data.responseText)
      }
    })
  }

  showQuantityDescription(event, text) {
    event.stopPropagation();
    $('#modalDetailDescriptionOfQuantity').find('.content-modal-text').text(text);
    $('#modalDetailDescriptionOfQuantity').modal('show')
  }

  showQuantityHistories(element, companyId, workId, quantityId) {
    const $ele = $(element)
    Shared.callAjax(
      Routes.effort_histories_path({ effort_id: quantityId }),
      { company_id: companyId, work_id: workId }, 'html', 'POST', false
    )
    .done(function(data) {
      if (!!data) {
        $('#modalQuantityHistories').find('div.content-modal-text').html(data)
        $('#modalQuantityHistories').modal('show')
      }
    })
    .fail(function(data) {
      if (!!data.responseText) {
        Popup.alert(data.responseText)
      }
    })
  }

  _fetchAllWorksQuantity(element) {
    const quantityComponent = $(element).parents('.show-work-quantity')
    const listWorkQuantity = quantityComponent.find($('.list-work-quantity'));
    let totalQuantity = 0;
    let totalHours = 0;
    let totalMinutes = 0;
    listWorkQuantity.find('.work-quantities').toArray().forEach(workQuantity => {
      totalQuantity += Number($(workQuantity).find('.quantity-number').val())
      totalHours += Number($(workQuantity).find('.quantity-hours-input').val())
      totalMinutes += Number($(workQuantity).find('.quantity-minutes-input').val())
    })
    if (totalMinutes >= 60) {
      const surplus = totalMinutes % 60
      totalHours += (totalMinutes - surplus) / 60
      totalMinutes = surplus
    }
    return {totalQuantity: totalQuantity, totalHours: totalHours, totalMinutes: totalMinutes}
  }

  cancelWorkQuantity(element) {
    const quantityComponent = $(element).parents('.show-work-quantity');
    const workQuantityNew = quantityComponent.find('.work-quantity-new');
    const listQuantities = quantityComponent.find('.list-work-quantity')
    const deleteWorksEle = listQuantities.find('.work-quantities-delete')
    const isChangeStatus =  listQuantities.find('.work-quantity-status').toArray()
                                          .some(statusEle => $(statusEle).data('status-old') !== $(statusEle).val())
    if (workQuantityNew.length > 0 || deleteWorksEle.length > 0 || isChangeStatus) {
      workQuantityNew.remove()
      this._restorePrevious(quantityComponent, deleteWorksEle, listQuantities)
    }
    this._calculateTotalQuantity(element, quantityComponent)
    this._rollbackStatus($(element))
    const workId = $(element).data('work-id')
    const ele = $(`#list-work-quantity-${workId}`)
    Ticket.resetAllDataSearchForQuantity(ele, workId)
  }

  _calculateTotalQuantity(element, quantityComponent) {
    const workQuantityClone = $(quantityComponent).find('.work-quantity-clone');
    this._setDefaultWorkQuantity(workQuantityClone);
    const allWorks = this._fetchAllWorksQuantity(element);
    this._renderTotalQuantity(quantityComponent, allWorks)
    Modal.hide(quantityComponent);
  }

  _setDefaultWorkQuantity(workQuantity) {
    workQuantity.find('.quantity-hours-input').val('');
    workQuantity.find('.quantity-number').val('');
    workQuantity.find('.quantity-minutes-input').val('');
    workQuantity.find('.work-quantity-description').val('');
  }

  _calculateQuantity(element) {
    let totalQuantity = 0;
    $(element).parents('tbody').find('.work_quantity').toArray().forEach(workQuantity => {
      totalQuantity += Number($(workQuantity).val())
    })
    $(element).parents('tbody').find('.show_total_quantity').text(totalQuantity.toFixed(gon.number_round))
  }

  validateQuantity(event, type) {
    const element = $(event.target);
    const MAX_NUMBER_OF_DIGITS_OF_HOUR = 5;
    element.val(element.val().replace(/[^0-9]/g,''));
    if (type == 'minutes') {
      const min = element.data('min');
      const max = element.data('max');
      if (element.val() > max) {
        element.val(max);
      } else if (element.val() < min) {
        element.val(min);
      }
    } else if (element.val().length > MAX_NUMBER_OF_DIGITS_OF_HOUR) {
      element.val(element.val().slice(0, MAX_NUMBER_OF_DIGITS_OF_HOUR));
    }
    const workQuantity = $(element).parents('.work-quantities');
    const hours = Number(workQuantity.find('.quantity-hours-input').val());
    const minute = Number(workQuantity.find('.quantity-minutes-input').val());
    workQuantity.find('.quantity-number').val(this._toAmount(hours, minute))
    this.addClassChangeWorkQuantity(element);
  }

  async showQuantityWork(event) {
    const trWorkParent = $(event.target).parents('tbody').find('tr:first');
    const statusEle = $(event.target).parents('tr').find('input.input_status')
    const statusWork = $(event.target).parents('tr').find('input.input_status').val();
    const orderType = trWorkParent.find('input.unit-work-for-company').val();
    const quantity = $(event.target).parents('td').find('.show-quantity').text();
    const parentId = $(event.target).parents('tr').find('.work_parent_id').val();
    const workId = $(event.target).parents('tr').find('.work_id').val();
    const modalQuantity = $(event.target).closest('tr').find('.show-work-quantity')
    const tableListCompanyEle = $(`.show_list_company_quantity_work_${workId}`);
    if (gon.log_work_feature.is_owner_project || gon.log_work_feature.is_company_type === 'supplier_partner') {
      this._checkEffortRemove(workId, modalQuantity, statusEle, statusWork,
                              event, quantity, orderType, parentId, trWorkParent, tableListCompanyEle)
    } else {
      const url = Routes.owner_project_ticket_check_order_of_company_path(this._fetchProjectId(), workId)
      await Shared.callAjax(url, {}).done(function(data) {
        if (data.company_type === 'client') {
          if (data.order_blank) {
            Popup.confirm('注文が紐付いていません。<br>このまま続けますか？', {
              success: () => {
                Ticket.fetchOrderWithClient(logWorkAction._fetchProjectId(), workId)
              },
              cancel: () => {
                logWorkAction._checkEffortRemove(
                  workId, modalQuantity, statusEle, statusWork, event, quantity,
                  orderType, parentId, trWorkParent, tableListCompanyEle
                )
              },
              confirmText: '紐づける',
              cancelText: '紐づけない',
              width: 'auto'
            })
          } else {
            logWorkAction._checkEffortRemove(
              workId, modalQuantity, statusEle, statusWork, event, quantity,
              orderType, parentId, trWorkParent, tableListCompanyEle
            )
          }
        } else {
          if (data.order_blank) {
            Popup.confirm('注文が紐付いていません。<br>このまま続けますか？', {
              success: () => {
                logWorkAction._checkEffortRemove(
                  workId, modalQuantity, statusEle, statusWork, event, quantity,
                  orderType, parentId, trWorkParent, tableListCompanyEle
                )
              },
              cancel: () => {
                Ticket.fetchOrderWithSupplier(logWorkAction._fetchProjectId(), workId)
              },
              confirmText: '続ける',
              cancelText: '注文選択',
              cancelButtonColor: '#FA0606',
              width: 'auto',
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          } else {
            logWorkAction._checkEffortRemove(
              workId, modalQuantity, statusEle, statusWork, event,
              quantity, orderType, parentId, trWorkParent, tableListCompanyEle
            )
          }
        }
      })
    }
  }

  async _checkEffortRemove(workId, modalQuantity, statusEle, statusWork,
                           event, quantity, orderType, parentId, trWorkParent, tableListCompanyEle) {
    await Shared.callAjax(
      Routes.show_remove_quantity_of_supplier_owner_project_task_ticket_path(
        this._fetchProjectId(), this._fetchTaskId()
      ), { work_id: workId, params_filter: gon.log_work_feature.params_filter }
    )
    .done(function(data) {
      const renderListQuantity = $(`#list-work-quantity-${workId}`)
      renderListQuantity.html(data.list_quantity)
      $(`.render-list-search-quantity-${workId}`).html(data.html_search)
      const tableListCompanyEleNew = $(`.show_list_company_quantity_work_${workId}`);
      tableListCompanyEleNew.html(data.options)
      $(`.show_list_company_quantity_work_${workId}.dropdown-company-list-on-sp`).find('td.total-effort').remove();
      $(`.show_list_company_quantity_work_${workId}.dropdown-company-list-on-sp`).find('td.col-select-company-quantity').css('font-size', '12px')
      $(`#render-info-quantity-${workId}`).html(data.html_info)
      if (data.is_show_remove) {
        Popup.confirm(
          I18n.t('work.have_job_delete_quantity'),
          {
            success: () => {
              logWorkAction.actionRemoveAllQuantity(workId, modalQuantity, statusEle, statusWork)
            },
            confirmText: I18n.t('shared.delete'),
            cancelText: I18n.t('shared.later'),
            cancel: () => {
              logWorkAction.actionShowModalWorkQuantity(
                event, quantity, data.unit, data.company_show_quantity, workId, data.is_not_show_quantity
              )
            }
          }
        )
      } else {
        if (!data.unit) {
          // Popup.notice('見積書か作業種別を選択してください。', 'warning');
          if (parentId) {
            $('#modalSelectWorkType').attr('work-id', $(event.target).parents('tr').find('input.work_id').val())
          } else {
            $('#modalSelectWorkType').attr('work-id', trWorkParent.find('input.work_id').val())
          }
          $('#modalSelectWorkType').attr('is-parents', !!parentId)
          $('#modalSelectWorkType').modal('show')
          return
        } else {
          if (gon.log_work_feature.is_owner_project && orderType !== '') {
            logWorkAction.actionShowModalWorkQuantity(
              event, quantity, data.unit, data.company_show_quantity, workId, data.is_not_show_quantity
            )
          } else {
            trWorkParent.find('input.unit-work-for-company').val(data.unit)
            logWorkAction.actionShowModalWorkQuantity(
              event, quantity, data.unit, data.company_show_quantity, workId, data.is_not_show_quantity
            )
          }
        }
      }
    })
    .fail(function(data) {
      Popup.alert(I18n.t('work.input_name_work'), 'warning')
    })
  }

  actionRemoveAllQuantity(workId, modalQuantity, statusEle, statusWork) {
    Shared.callAjax(
      Routes.delete_all_quantity_rejected_owner_project_task_ticket_path(
        logWorkAction._fetchProjectId(), logWorkAction._fetchTaskId()
      ), { work_id: workId }
    )
    .done(function(data) {
      modalQuantity.find('tr.work-quantities').toArray().forEach(quantity => {
        if ($(quantity).hasClass('rejected')) {
          $(quantity).remove()
        }
      })
      statusEle.val(statusWork).trigger('change')
    })
    .fail(function(data) {
      Popup.alert(I18n.t('work.input_name_work'), 'warning')
    })
  }

  actionShowModalWorkQuantity(event, quantity, orderType, companyShowQuantity, workId, isNotShowQuantity) {
    this._toggleQuantityType(orderType, $(event.target).closest('tr').find('.show-work-quantity'), quantity);
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const companyId = searchParams.get("company_quantity");
    let company_id = companyShowQuantity;

    $(event.target).parents('td').find('.render_unit_work').val(orderType);

    if (companyShowQuantity && gon.log_work_feature.is_owner_project) {
      company_id = companyId ? companyId : companyShowQuantity
    }
    $(event.target).closest('tr').find(`#show_list_company_quantity_work_${workId}`)
                                 .find(`div[data-company-id="${company_id}"]`).first().trigger('click')
  }

  showQuantityWorkCheckDeliver(event,workQuantities) {
    workQuantities.toArray().forEach(workQuantity => $(workQuantity).find('.check-quantity-status').prop('checked', true))
    workQuantities.toArray().forEach(workQuantity => $(workQuantity).find('.work-quantity-status').attr('value','approval'))
    const trWorkParent = $(event).parents('tbody').find('tr:first');
    const statusWork = $(event).parents('tr').find('input.input_status').val();
    const orderType = trWorkParent.find('input.render_unit_work').val();
    const quantity = $(event).parents('td').find('.show-quantity').text();
    if (orderType == '') {
      Popup.notice('見積書か作業種別を選択してください。', 'warning');
      return
    }
    if (orderType == 'other') {
      Popup.confirm(I18n.t('shared.popup_custom_quantity'), {
        confirmText: 'OK',
        cancelBtn: 'false',
        width: 'auto',
        success: () => {
        }
      })
    } else if (orderType == 'by_time') {
      this._toggleQuantityType(orderType, $(event).closest('tr').find('.show-work-quantity'), quantity);
    } else if (orderType == 'by_number') {
      this._toggleQuantityType(orderType, $(event).closest('tr').find('.show-work-quantity'), quantity);
    }
  }


  removeCompany(type) {
    if (type == 'sender') {
      this.selectCompanySender.parents('tr').find('input.company_received_order').val('');
      this.selectCompanySender.parents('tr').find('span.select2-selection__rendered').text('');
    } else {
      this.selectCompanyReceived.parents('tr').find('input.company_ordered').val('');
    }
  }

  _toggleButton(quotationId = null) {
    if (quotationId) {
      Visiable.toggle($('body').find('button.destroy_quotation'), false);
      Visiable.toggle($('body').find('a.link_quotation_sender'), false);
      Visiable.toggle($('body').find('button.accept_quotation'), true);
      Visiable.toggle($('body').find('span.cancel_button_modal'), false);
      Visiable.toggle($('body').find('button.cancel_button_modal_new'), true);
    } else {
      Visiable.toggle($('body').find('button.destroy_quotation'), true);
      Visiable.toggle($('body').find('a.link_quotation_sender'), true);
      Visiable.toggle($('body').find('span.cancel_button_modal'), true);
      Visiable.toggle($('body').find('button.accept_quotation'), false);
      Visiable.toggle($('body').find('button.cancel_button_modal_new'), false);
    }
  }

  _isChangeWorkOrder(type, element){
    let workOrderReceived;
    let workOrderSender;
    if (type == 'sender'){
      workOrderSender = element.parents('tr').find('input.work_order_received_id_sender').val();
    }else{
      workOrderReceived = element.parents('tr').find('input.work_order_received_id_received').val();
    }
    let isChange = false;
    let isOwnerMain = $('div.vip').data('is-owner-main');
    if(isOwnerMain){
      isChange = true;
    }else{
      if (type == 'sender'){
        if(!workOrderSender){
          isChange = true;
        }
      }else{
        if (!workOrderReceived){
          isChange = true;
        }
      }
    }
    return isChange;
  }

  _removeDisabledOption(arrayOption){
    arrayOption.each(function(){
      $(this).removeAttr('disabled');
    })
  }

  _addDisabledOption(arrayOption, company_id){
    arrayOption.each(function(){
      if ($(this).val() != company_id){
        $(this).attr('disabled', true);
      }
    })
  }

  actionShowModal(company_id, element, type){
    let isChange = this._isChangeWorkOrder(type, element);
    this._removeDisabledOption($('body').find('.select_quotation_company_sender option'));
    this._removeDisabledOption($('body').find('.select_quotation_company_received option'));
    let companiesNumber;
    if (type == 'sender'){
      if (!company_id){
        company_id = element.parents('tr').find('select.company_received_order').val();
      }
      this.selectCompanySender = element;
      this.removeValue();
      $('body').find('.select_quotation_company_sender').val(company_id);
        company_id = element.parents('tr').find('select.company_received_order').val();
      if (!isChange && company_id){
        this._addDisabledOption($('body').find('.select_quotation_company_sender option'), company_id);
      }
      if (company_id){
        $('body').find('.select_quotation_company_sender').trigger('change', [type]);
      }
      companiesNumber = $('body').find('select.select_quotation_company_sender > option').length
      this._showPopupAndCloseModal(companiesNumber, $('body').find('#modal_show_quotation_sender'));
    }else{
      this.selectCompanyReceived = element;
      this.removeValue()
      $('body').find('.select_quotation_company_received').val(company_id);
      if (!isChange && company_id){
        this._addDisabledOption($('body').find('.select_quotation_company_received option'), company_id);
      }
      if (company_id){
        $('body').find('.select_quotation_company_received').trigger('change', [type])
      }
      companiesNumber = $('body').find('select.select_quotation_company_received > option').length
      this._showPopupAndCloseModal(companiesNumber, $('body').find('#modal_show_quotation_received'));
    }
  }

  _showPopupAndCloseModal(companiesNumber, elementModal) {
    if (companiesNumber == 0) {
      Popup.alert('取引先が存在しません')
    } else {
      elementModal.modal('show');
    }
  }

  async removeQuotation(element, type) {
    const tr = type == 'sender' ? this.selectCompanySender.parents('tr') : this.selectCompanyReceived.parents('tr')
    const order = type === 'sender' ? tr.find('input.quotation_sender') : tr.find('input.work_quotation_id')
    const componentShowOrder = type === 'sender' ? $(element).parents('#showOrderSender') :
                                                   $(element).parents('#showOrderReceived')
    const companyName =  componentShowOrder.find('.company-order-name').text()
    const orderName = componentShowOrder.find('.order-name').text()

    order.val('');
    tr.find('.work_order_received_id_sender').val($('input[name="work_order_sender_id"]').val())

    $('#doneRemoveQuotation').find('#popupRemoveCompanyName').text(companyName)
    $('#doneRemoveQuotation').find('#pupupRemoveOrderName').text(orderName)
    Ticket.save(tr.find('.work_id'), false, false, false, false, false, true);
    componentShowOrder.modal('hide')
  }

  removeAllWorkQuantity(tbodyEle) {
    tbodyEle.find('.work-quantities').toArray().forEach(workQuantity => {
      const workQuantityId = $(workQuantity).find('.work-quantity-id').val();
      if (workQuantityId) {
        $('.list-work-quantity-delete').val(`${$('.list-work-quantity-delete').val()}, ${workQuantityId}`);
        $(workQuantity).remove();
      }
    })
    this._removeAllTotalQuantity(tbodyEle);
  }

  removeValueSelectOfModal(){
    $('body').find('.select_quotation_received_name').empty();
    $('body').find('.select_quotation_received_price').empty();
    $('body').find('.select_quotation_sender_name').empty();
    $('body').find('.select_quotation_sender_price').empty();
    $('body').find('.select_quotation_received_contract').val('');
    $('body').find('.show_quotation_received_month').val('');
    $('body').find('.show_quotation_received_type').val('');
  }

  _disabledSelectIsQuotation(quotationId, type) {
    const modalId = type == 'sender' ? 'modal_show_quotation_sender' : 'modal_show_quotation_received'
    Disabled.toggle($(`div#${modalId} select`), !!quotationId);
  }


  _setQuotation(element, type, classSelectQuotation, idModalShowQuotation) {
    const classInputQuotation = this._fetchClassInputQuotation(type);
    const projectId = this._fetchProjectId();
    const elementModal = element.parents('div.modal-body');
    const trSelect = this._fetchImageSelect(type).parents('tr');
    const quotationId = elementModal.find(`input.${classSelectQuotation}`).val();
    const quotationType = elementModal.find(`input.quotation_contract_sub_type`).val();
    let quotationIsPresent = this.isQuotationOtherPresent(type, quotationId, trSelect);
    if (quotationId) {
      if (quotationType == 'other') {
        if(quotationIsPresent) {
          if (type == 'sender') {
            trSelect.find('input.quotation_sender').val('');
            $('body').find('select.select_quotation_sender_price').val('');
            $('body').find('select.select_quotation_sender_name').val('');
            $('body').find('input.select_quotation_received_type').val('');
            $('body').find('input.select_quotation_received_contract').val('');
          } else {
            trSelect.find('input.work_quotation_id').val('');
            $('body').find('select.select_quotation_received_name').val('');
            $('body').find('select.select_quotation_received_price').val('');
            $('body').find('select.select_quotation_received_contract').val('');
          }
          $(`div#${idModalShowQuotation}`).modal('hide');
          Popup.notice(I18n.t('work.quotation_present'), 'error');
        } else {
          this._toggleButtonQuotation(trSelect, elementModal, quotationId, classInputQuotation);
          this.actionRenderQuotation(type, quotationId, quotationType, projectId, elementModal)
        }
      } else {
        this._toggleButtonQuotation(trSelect, elementModal, quotationId, classInputQuotation);
        this.actionRenderQuotation(type, quotationId, quotationType, projectId, elementModal)
      }
    } else {
      if (type == 'sender') {
        this.selectCompanySender.parents('tr').find('input.quotation_sender').val(quotationId);
      } else {
        this.selectCompanyReceived.parents('tr').find('input.work_quotation_id').val(quotationId);
      }
    }
    const idModal = logWorkAction._fetchIdModalSelectQuotation(type);
    $(`div#${idModal}`).modal('hide');
  }

  _toggleButtonQuotation(trSelect, elementModal, quotationId, classInputQuotation) {
    trSelect.find(`input.${classInputQuotation}`).val(quotationId);
    Visiable.toggle(elementModal.find(`button.confirm-quotation`), true);
    Visiable.toggle(elementModal.find('button.close-modal-quotation'), false);
    Visiable.hide(elementModal.find('button.back-to-select-quotation'));
  }

  _setCompany(type, elementModal) {
    const classCompanyName = type == 'sender' ? 'select_quotation_company_sender' : 'select_quotation_company_received'
    const companyId = elementModal.find(`select.${classCompanyName}`).val();
    const companyName = elementModal.find(`select.${classCompanyName} option:selected`).text();
    if (type == 'sender') {
      this.selectCompanySender.parents('tr').find('input.company_received_order').val(companyId);
      this.selectCompanySender.parents('tr').find('select.company_received_order').val(companyId);
      this.selectCompanySender.parents('tr').find('span.select2-selection__rendered').text(companyName);
    } else {
      this.selectCompanyReceived.parents('tr').find('input.company_ordered').val(companyId);
    }
  }

  actionRenderQuotation(type, quotationId, quotation_type, project_id, elementModal) {
    let orderType;
    let workOrderId;
    let status;
    let tr;
    if (type == 'sender') {
      orderType = logWorkAction.selectCompanySender.parents('tr').find('input.render_unit_work').val();
      let quotationIdSender = logWorkAction.selectCompanySender.parents('tr').find('input.quotation_sender').val();
      $('body').find('.select_quotation_sender_name').val(quotationId);
      $('body').find('.select_quotation_sender_price').val(quotationId);
      tr = this.selectCompanySender.parents('tr');
      status = tr.find('input.input_status').val();
      this.renderQuotationOfSelect(this.listQuotationSender, quotationId, type, orderType, status, tr);
      tr.find('input.quotation_sender').val(quotationId);
      workOrderId = tr.find('input.work_order_received_id_sender').val();
      this._toggleButton(quotationIdSender);
      this.renderValueType(type, quotation_type, quotationId, quotationIdSender);
      Disabled.toggle(tr.find('select.company_received_order'), true);
    } else {
      orderType = logWorkAction.selectCompanyReceived.parents('tr').find('input.render_unit_work').val();
      let quotationIdReceived = logWorkAction.selectCompanyReceived.parents('tr').find('input.work_quotation_id').val();
      $('body').find('.select_quotation_received_name').val(quotationId);
      $('body').find('.select_quotation_received_price').val(quotationId);
      tr = this.selectCompanyReceived.parents('tr');
      status = tr.find('input.input_status').val();
      this.renderQuotationOfSelect(this.listQuotationReceived, quotationId, type, orderType, status, tr);
      tr.find('input.work_quotation_id').val(quotationId);
      workOrderId = tr.find('input.work_order_received_id_received').val();
      this._toggleButton(quotationIdReceived);
      this.renderValueType(type, quotation_type, quotationId, quotationIdReceived);
    }
    tr.data('order-present', true)
    const linkToOrder = this._getLinkToOrder(type);
    $('body').find('a.link_quotation_sender').attr('href', linkToOrder);
  }

  renderValueType(type, quotation_type, quotationSelectNew, quotationSelectCurrent){
    if(quotation_type == 'other'){
      if (type == 'sender'){
        this.selectCompanySender.parents('tr').find('select.select_unit_work').attr('type_customize', quotation_type);
        this.selectCompanySender.parents('tbody').find('input.work_quantity').val('').attr('readonly', true);
      }else{
        this.selectCompanyReceived.parents('tr').find('select.select_unit_work').attr('type_customize', quotation_type);
        this.selectCompanyReceived.parents('tbody').find('input.work_quantity').val('').attr('readonly', true);
      }
    }else{
      if (type == 'sender'){
        this.selectCompanySender.parents('tr').find('select.select_unit_work').attr('type_customize', '');
        if (quotationSelectCurrent && quotationSelectNew != quotationSelectCurrent){
          this._removeQuntity(this.selectCompanySender.parents('tbody').find('input.work_quantity'));
        }
      }else{
        this.selectCompanyReceived.parents('tr').find('select.select_unit_work').attr('type_customize', '');
        if (quotationSelectCurrent && quotationSelectNew != quotationSelectCurrent){
          this._removeQuntity(this.selectCompanyReceived.parents('tbody').find('input.work_quantity'));
        }
      }
    }
  }

  _removeQuntity(element) {
    if (parseFloat(element.val()) > 0 && confirm('数量をリセットされますか。よろしいでしょうか。')) {
      element.val('').trigger('change');
    }
    element.attr('readonly', false);
  }

  isQuotationOtherPresent(type, quotationId, trSelect) {
    let isQuotationPresent = false;
    const listQuotationTypeOther = trSelect.parents('table').find('select.select_unit_work[type_customize="other"]');
    isQuotationPresent = this.checkQuotation(listQuotationTypeOther, quotationId, type);
    if (isQuotationPresent) { return isQuotationPresent };
    const arrayWorkOrder = type == 'sender' ? gon.work_order_sender : gon.work_order_received
    return arrayWorkOrder.some(element => element.id == Number(quotationId)) ? true : false
  }

  checkQuotation(listQuotationTypeOther, quotationId, type) {
    let value = false;
    let indexTbody;
    if (type == 'sender'){
      indexTbody = this.selectCompanySender.parents('tbody').attr('index');
    } else {
      indexTbody = this.selectCompanyReceived.parents('tbody').attr('index');
    }
    $.each(listQuotationTypeOther, function(i, td){
      if (type == 'sender'){
        if (indexTbody != $(td).parents('tbody').attr('index')){
          if(quotationId == $(td).parents('tr').find('input.quotation_sender').val()){
            value = true;
          }
        }

      }else{
        if (indexTbody != $(td).parents('tbody').attr('index')){
          if(quotationId == $(td).parents('tr').find('input.work_quotation_id').val()){
            value = true;
          }
        }
      }
    })
    return value
  }

  renderQuotationOfSelect(array, quotationId, type, orderType, status, element) {
    const isOrderPresent = element.data('order-present');
    const maxQuantityEle =  $(element).find('.max-quantity');
    $.each(array.quotations, function(i, quotation){
      if (quotation.id == quotationId) {
        let contract_sub_type = logWorkAction.renderContractSubType(quotation.contract_sub_type);
        $('body').find('input.select_quotation_received_contract').val(quotation.contract_type);
        $('body').find('input.select_quotation_received_type').val(contract_sub_type);
        logWorkAction._setValueTypeQuotation(type, quotation);
        logWorkAction._setValueTypeWork(quotation, element, isOrderPresent);
        const isQuotationFixed = gon.log_work_feature.quotation_fixed.includes(quotation.contract_sub_type)
        const isQuotationPresent = type === 'sender' ? Number($(element).find('.work_quotation_id').val()) > 0:
                                                       Number($(element).find('.quotation_sender').val()) > 0
        if (isQuotationFixed && (type === 'received' || gon.shared.is_client)) {
          $(maxQuantityEle).val(quotation.work_max_quantity)
                           .attr({ 'is-fixed-order': 'true',
                                   'max-quantity-by-order': quotation.order_client_max_quantity,
                                   'max-quantity-of-order-received': quotation.work_max_quantity })
                           .data('value-old', quotation.work_max_quantity);
        } else if ((isQuotationFixed && type === 'sender') && !$(element).find('.work_quotation_id').val()) {
          $(maxQuantityEle).val(quotation.work_max_quantity).data('value-old', quotation.work_max_quantity);
        }
      }
    })
  }

  fetchOderType(contract_sub_type) {
    let orderType = ''
    if (contract_sub_type == 'by_hours') {
      orderType = 'by_time'
    } else if (contract_sub_type == 'fixed_quantity') {
      orderType = 'by_number'
    } else {
      orderType = contract_sub_type
    }
    return orderType;
  }

  _setValueTypeWork(quotation, element, isOrderPresent) {
    if (!isOrderPresent) {
      const typeOder = this.fetchOderType(quotation.contract_sub_type);
      const optionOther = element.find('.select_unit_work option[value="other"]')
      if (typeOder == 'other' && optionOther.length === 0) {
        element.find('select.select_unit_work')
               .append(`<option value="other">${ I18n.t('enums.work.unit.other') }</option>`);
      }
      element.find('input.render_unit_work').val(typeOder);
      element.find('select.select_unit_work').val(typeOder);
    }
  }

  _setValueTypeQuotation(type, quotation) {
    if (type == 'sender') {
      logWorkAction.selectCompanySender.parents('tr').find('td.show_quotation').attr(
        'quotation_sender_type', quotation.contract_sub_type
      );
    } else {
      logWorkAction.selectCompanyReceived.parents('tr').find('td.text-description').attr(
        'quotation_received_type', quotation.contract_sub_type
      );
    }
  }

  removeValue() {
    $('body').find('.select_quotation_company_sender').val('');
    $('body').find('.select_quotation_company_received').val('');
    $('body').find('.select_quotation_received_name').empty();
    $('body').find('.select_quotation_received_price').empty();
    $('body').find('.select_quotation_sender_name').empty();
    $('body').find('.select_quotation_sender_price').empty();
    $('body').find('.select_quotation_received_contract').val('');
    $('body').find('.show_quotation_received_month').val('');
    $('body').find('.show_quotation_received_type').val('');
  }

  getQuotation(event, type) {
    let quotation_id;
    let company_id;
    const labelType = type === 'sender' ? I18n.t('project.invite.search.company_types.supplier') :
                                          I18n.t('project.invite.search.company_types.client')

    if (type == 'sender'){
      quotation_id = $(event.target).parents('td').find('input.quotation_sender').val();
      company_id = $(event.target).parents('td').find('.company_received_order').val();
    }else{
      company_id = $(event.target).parents('td').find('.company_ordered').val();
      quotation_id = $(event.target).parents('td').find('input.work_quotation_id').val();
    }

    $.ajax({
      method: 'POST',
      url: gon.url_show_quotation,
      dataType: 'json',
      data: {'quotation_id': quotation_id},
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      }
    })
    .done(function(data) {
      $('body').find('#show_quotation_company').val(company_id);
      if (data.quotation){
        logWorkAction._renderTimeAndMonthPayment(data.quotation, 'modal_show_quotation_present')
        let contract_sub_type = logWorkAction.renderContractSubType(data.quotation.contract_sub_type);
        let quotation_name = logWorkAction.rendeNameQuotation(data.quotation.name)
        $('body').find('.show_quotation_present_name').empty().append('<option value="' + data.quotation.id + '">' + quotation_name +'</option>');
        $('body').find('.show_quotation_present_price').empty().append('<option value="' + data.quotation.id + '" type="'+ data.quotation.contract_sub_type + '" >' + 'ID: ' + data.quotation.id + ' |  ' + logWorkAction.addPeriod(data.quotation.amount) + '円</option>');
        $('body').find('.show_quotation_received_contract').val(data.quotation.contract_type);
        $('body').find('input.show_quotation_received_type').val(contract_sub_type);
        $('#modal_show_quotation_present').find('.label-type').text(labelType);
        $('body').find('#modal_show_quotation_present').modal('show');
        $(`div#modal_show_quotation_present`).find('input.quotation_duration').val(data.quotation.month_payment_now)
      }
    })
    .fail(function(data) {
    })
  }

  renderContractSubType(contract_sub_type){
    let sub_type;
    switch(contract_sub_type) {
      case 'by_time':
        sub_type = '時間計算'
        break;
      case 'by_hours':
        sub_type = '固定時間'
        break;
      case 'by_number':
        sub_type = '個数計算'
        break;
      case 'fixed_quantity':
        sub_type = '固定個数'
        break;
      default:
        sub_type = 'カスタマイズ'
    }
    return sub_type;
  }

  rendeNameQuotation(name){
    return name || '見積書名がありません'
  }

  changeCompany(event){
    this.selectCompanyReceived = $(event.target);
    $(event.target).parents('td').find('input.company_received_order').val($(event.target).val());
    $(event.target).parents('td').find('input.quotation_sender').val('');
  }
  // fortmat currentcy ,example: addPeriod(100000) = 100,000 ; addPeriod(10000000) = 10,000,000
  addPeriod(amount) {
    amount += '';
    var x = amount.split('.');
    var x1 = x[0];
    var x2 = parseInt(x[1]) > 0 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  };

  selectExpirationDate(element, workId) {
    const expirationDate = $(element).find('.expiration_date').text().trim();
    const expirationTime = $(element).find('.expiration_time').text().trim();
    const modal = $(element).parents('tr').find(`#modal-deadline-work-for-sp-${workId}`)
    if (expirationDate) { modal.find('input.datetimepicker').val(expirationDate) }
    if (expirationTime) { modal.find('input.datetimepicker-time').val(expirationTime) }
    this.disableSortWork()
    modal.modal('show');
    modal.on('hide.bs.modal', function() {
      logWorkAction.enableSortWork()
    })
  }

  showExpirationDateSp(ele, workId) {
    const modal = $(`#modal-deadline-work-for-sp-${workId}`)
    const expirationDate = $(ele).find('.show_date_modal_sp').text().trim();
    const expirationTime = $(ele).find('.show_time_modal_sp').text().trim();
    if (expirationDate) { modal.find('input.datetimepicker').val(expirationDate) }
    if (expirationTime) { modal.find('input.datetimepicker-time').val(expirationTime) }
    modal.modal('show');
  }

  setExpirationDate(event) {
    const componentExpirationDate = $(event.target).parents('.modal-content')
    const expirationDateEle = componentExpirationDate.find('input.value-expiration-date');
    const expirationDate = componentExpirationDate.find('input.js-datepicker').val();
    const expirationTime = componentExpirationDate.find('input.datetimepicker-time').val();
    $(event.target).parents('td').find('span.show_date_modal').text(expirationDate);
    $(event.target).parents('td').find('span.show_time_modal').text(expirationTime);
    expirationDateEle.val(`${expirationDate}, ${expirationTime}`);
    Ticket.save($(event.target));
  }

  changeExpirationDate(event){
    const expirationDate = $(event.target).parents('.modal-content').find('input.js-datepicker').val();
    const expirationTime = $(event.target).parents('.modal-content').find('input.datetimepicker-time').val();
    $(event.target).parents('.modal-content').find('.expiration-data').val(`${expirationDate} ${expirationTime}`);
    alert(`${expirationDate} ${expirationTime}`)
  }


  cancelSelectExpirationDate(event) {
    const tdEle = $(event.target).parents('td')
    const expirationDate = tdEle.find('span.show_date_modal').text().trim();
    const expirationTime = tdEle.find('.show_time_modal').text().trim();
    $(event.target).parents('.modal-content').find('input.datetimepicker').val(expirationDate);
    $(event.target).parents('.modal-content').find('input.expiration-time').val(expirationTime);
  }

  resetExpirationDate(ele) {
    const modalComponent = $(ele).parents('.modal-content')
    modalComponent.find('input.js-datepicker').val('')
    modalComponent.find('input.expiration-time').val('')
  }

  deleteWork(element) {
    const trSelected = $(element).parents('tr');
    const parentID = $(trSelected).find('.work_parent_id').val();
    const workId = $(trSelected).find('.work_id').val();
    const status = trSelected.find('.input_status').val();
    let messageDelete = ''
    if (gon.log_work_feature.status_delivered.includes(status)) {
      messageDelete = I18n.t('work.delete_work_release')
    } else {
      messageDelete = I18n.t('work.delete_work')
    }
    if (parentID){
      Popup.confirm(messageDelete, {
        success: () => {trSelected.parents('tr').remove(); Ticket.delete(workId) },
        cancel: () => { return }
      })
    }else{
      Popup.confirm(messageDelete, {
        confirmText: 'OK',
        success: () => { trSelected.parents('tbody').remove(); Ticket.delete(workId)  },
        cancel: () => { return }
      })
    }
  }

  validateMaxQuantity(element) {
    const maxQuantityByOrderReceived = $(element).attr('max-quantity-of-order-received');
    const maxQuantity = Number($(element).val());
    const maxQuantityByOrderClient = Number($(element).attr('max-quantity-by-order'))
    const isWorkChild = Number($(element).parents('tr').find('.work_parent_id').val()) > 0;
    const workParent = $(element).parents('tbody').find('tr:first');
    const workParentMaxQuantity = Number(workParent.find('.max-quantity').val())
    const isWorkParentFixed = workParent.find('.max-quantity').attr('is-fixed-order') === 'true'
    if ($(element).attr('is-fixed-order') === 'true' &&
        Number(maxQuantityByOrderReceived) > maxQuantityByOrderClient &&
        maxQuantity > maxQuantityByOrderClient) {
      $(element).val(maxQuantityByOrderClient);
    } else if (maxQuantityByOrderReceived && maxQuantity > Number(maxQuantityByOrderReceived)) {
      $(element).val(maxQuantityByOrderReceived);
    } else if (isWorkParentFixed && isWorkChild && maxQuantity > workParentMaxQuantity) {
      $(element).val(workParentMaxQuantity);
    }
  }

  defaultPersonInCharge(element) {
    $(element).val(gon.log_work_feature.current_user.id)
  }

  ticketValidation(event) {
    const allWorksEle = $('#new_work').find('tr').toArray();
    let isNotSave = false;
    let worksNoSendingCompany = ''
    allWorksEle.forEach(workEle => {
      const quantity = Number($(workEle).find('.show-quantity').text());
      const status = $(workEle).find('.input_status').val();
      const orderType = $(workEle).parents('tbody').find('tr:first').find('.render_unit_work').val();
      const parentId = $(workEle).find('.work_parent_id').val();
      if(quantity === 0 && $(workEle).find('.description_new').val() === '') {
        $('.list_work_delete').val(`${$('.list_work_delete').val()}, ${$(workEle).find('.work_id').val()}`);
        if (parentId) {
          $(workEle).parents('tr').remove();
        } else if ($('#new_work').find('tbody').length === 1) {
          isNotSave = true;
          this._showMessageErrorOfElement($(workEle).find('.description_new'), I18n.t('work.errors.name_blank'))
          $(workEle).find('.description_new').val('');
        } else {
          $(workEle).parents('tbody').remove();
        }
      } else if(quantity > 0 && $(workEle).find('.description_new').val() === '') {
        isNotSave = true;
        this._showMessageErrorOfElement($(workEle).find('.description_new'), I18n.t('work.errors.name_blank'))
      } else if(quantity === 0 &&
                gon.log_work_feature.status_completed.includes(status) &&
                (orderType && orderType !== 'other')) {
        isNotSave = true;
        this._showMessageErrorOfElement($(workEle).find('.work_quantity'), I18n.t('work.errors.quantity_blank'))
      }
      if ($(workEle).find('.company_ordered').val() === '' && $(workEle).find('.work_id').val()) {
        worksNoSendingCompany += `${$(workEle).find('.work_id').val()}, `
      }
    })
    if (isNotSave){
      event.preventDefault();
    } else if (gon.log_work_feature.is_show_popup_check_client && worksNoSendingCompany !== '') {
      event.preventDefault();
      Popup.confirm(`${worksNoSendingCompany}  ${I18n.t('work.not_quotation')}` , {
        success: () => { Disabled.toggle($(event.target), true), $('#new_work').submit() },
        confirmText: 'OK',
        cancelText: I18n.t('shared.come_back'),
        cancel: () => event.preventDefault() }
      )
    }
  }

  setStatusAllWork(allWorksEle, status) {
    allWorksEle.find('.input_status').val(status);
    allWorksEle.find('.select_status').val(status);
  }

  modifyWorkEleBeforeClone(workEle) {
    workEle.find('.date-create-at').remove();
    // workEle.find('.last-updated-at').text(`更新日： ${I18n.l("date.formats.default", new Date())}`);
    workEle.find('.check-list, .test-list, .ticket-chat, .ticket-akaire, .ticket-copy').remove();
    workEle.find('.dropdown_icon_menu').remove();
    workEle.find('.received-show').find('.name-company-order-received').text('販売先 : ');
    workEle.find('.sender-show').find('.name-company-order-sender').text('仕入先 : ');
    workEle.find('.img-quotation-sender').remove();
    workEle.find('.img-quotation-received').remove();
    workEle.find('.delete-work').attr('onclick', 'logWorkAction.deleteWork(this)');
    const imgSender = $('.vip').data('img-sender');
    const imgReceived = $('.vip').data('img-received');
    workEle.find('.received-show').append(imgReceived);
    workEle.find('.sender-show').append(imgSender);
    workEle.find('.show_work_history_change').remove();
    workEle.find('.text-show-id-ticket').text('チケットID： ');
    workEle.find('.text-show-description-ticket').text('');
    workEle.find('.expiration_time').text('')
    workEle.find('.expiration-time').val('')
    workEle.find('.value-expiration-date').val('')
    workEle.find('.percentage-complete').remove();
    workEle.find('div.ticket-delete').remove()
    workEle.find('div.outer_status_newstyle').remove();
    workEle.find('td.ticket_list_menu').empty();
    workEle.find('td.show-total-file-size').empty();
    return workEle;
  }

  showDescriptionQuantity(element) {
    Popup.alert($(element).val());
  }

  resetWorkQuantity() {
    $(document).on('hide.bs.modal', '.show-work-quantity', function (event) {
      const quantityComponent = $(event.target);
      const workQuantityNew = quantityComponent.find('.work-quantity-new');
      const listQuantities = quantityComponent.find('.list-work-quantity');
      const deleteWorksEle = listQuantities.find('.work-quantities-delete');
      const isError = logWorkAction._isCannotCloseWorkQuantity(
        quantityComponent, workQuantityNew, listQuantities, deleteWorksEle
      )
      if (isError) {
        workQuantityNew.remove(),
        logWorkAction._restorePrevious(quantityComponent, deleteWorksEle, listQuantities),
        logWorkAction._calculateTotalQuantity(
          $(event.target).find('div.render-work-quantity-new'), quantityComponent
        )
        event.preventDefault();
      }
      const workQuantityClone = quantityComponent.find('.work-quantity-clone');
      logWorkAction._setDefaultWorkQuantity(workQuantityClone);
      const allWorks = logWorkAction._fetchAllWorksQuantity(
        $(event.target).find('div.render-work-quantity-new')
      );
      logWorkAction._renderTotalQuantity(quantityComponent, allWorks)
      logWorkAction.enableSortWork();
    })
  }

  _isCannotCloseWorkQuantity(quantityComponent, workQuantityNew, listQuantities, deleteWorksEle) {
    const isChangeStatus =  listQuantities.find('.work-quantity-status').toArray()
                                          .some(statusEle => $(statusEle).data('status-old') !== $(statusEle).val())
    if (workQuantityNew.length > 0 || deleteWorksEle.length > 0 || isChangeStatus) {
      return true
    }
    return false
  }

  _isWorkChange() {
    const numberOfWorksChange = $('.invoice__table--main').find('.bg-light-gray').not('.not_reload').length;
    if (numberOfWorksChange > 0 ) return true;
    return false;
  }

  _rollbackStatus(element) {
    const elementSelectStatus = element.parents('tr').find('.select_status')
    const allWorksEle = element.parents('tbody');
    const workEle = element.parents('tr');
    const statusBeforeChanged = workEle.find('.before-status').val();
    const parentId = workEle.attr('parent_id');
    const worksChild = allWorksEle.find('.invoice__table--new').toArray();
    elementSelectStatus.val(statusBeforeChanged);
    workEle.find('.input_status').val(statusBeforeChanged);
    if (!parentId && worksChild.length > 0) {
      elementSelectStatus.val('partially_delivered');
      elementSelectStatus.prev().val('partially_delivered')
    } else if (statusBeforeChanged)  {
      elementSelectStatus.val(statusBeforeChanged);
      workEle.find('.input_status').val(statusBeforeChanged);
    } else {
      elementSelectStatus.val(elementSelectStatus.prev().val());
    }
    if (!parentId && gon.log_work_feature.status_delivered.includes(statusBeforeChanged) && worksChild.length > 0) {
      this._restoreStatusWorkChild(worksChild);
    }
  }

  _addWorkDelete(workIds) {
    workIds.toArray().forEach(workId => {
      if ($(workId).val()) {
        $('.list_work_delete').val($('.list_work_delete').val() + ',' + $(workId).val());
      }
    })
    this._unlockTheSubmitAndTheReloadPage();
  }

  addBackgroundColor(element) {
    $(element).parents('td').addClass('bg-light-gray');
    this._unlockTheSubmitAndTheReloadPage();
  }

  addRedColorToTbody(element) {
    $(element).parents('tbody').addClass('bg-light-gray');
    this._unlockTheSubmitAndTheReloadPage();
  }

  removeClassOfTdTag(element) {
    $(element).parents('tbody').find('td').removeClass('bg-light-gray');
  }

  _unlockTheSubmitAndTheReloadPage() {
    Disabled.toggle($('#submit-form'), false)
    Disabled.toggle($('.btn-reload-page'), false)
  }

  _resideComponentByOrderType(component, orderType) {
    let textOrderType = '';
    let addClass = ''
    let removeClass = ''
    if (orderType === 'by_time') {
      // textOrderType = I18n.t('shared.total_time_so_far');
      // addClass = 'col-lg-2'
      // removeClass = 'col-3 col-lg-3'
    } else {
      // textOrderType = I18n.t('work.total_number_so_far');
      // addClass = 'col-lg-3'
      // removeClass = 'col-lg-2'
    }
    // $(component).find('.th-quantities').find('.th-description').removeClass(removeClass).addClass(addClass)
    // $(component).find('.description-title').removeClass(removeClass).addClass(addClass)
    // $(component).find('.text-order-type').text(textOrderType)

  }

  runDatePicker() {
    Shared.customDatepicker('js-datepicker-new', {
      maxDate: new Date(),
      customDays: I18n.t('shared.customDays'),
      customMonths: I18n.t('shared.customMonths'),
      formatter: (input, date, _) => input.value = moment(date).format('Y/MM/DD')
    })
  }

  workHistory(element){
    let elementDescripton = $(element).closest('.text-description')
    elementDescripton.find('.description_inner').dropdown('toggle')
  }

  async checkList(popup_id, projectId){
    const url = Routes.render_check_list_work_owner_project_check_list_work_index_path(projectId)
    const modalCheckList = $(`#checkListModal-${popup_id}`)
    await Shared.callAjax(url, { work_id: popup_id }, 'html').done(function(data) {
      modalCheckList.find('#render_check_list_work').html(data)
      modalCheckList.modal('show')
      logWorkAction._calculateCheckList(modalCheckList)
    })
  }

  async doneCheckList(ele, workId, checkListId, isFinish, projectId){
    const popup_obj = $(ele).closest('.modal')
    const url = Routes.change_status_check_list_owner_project_check_list_work_index_path(projectId)
    await Shared.callAjax(
      url, { work_id: workId, check_list_id: checkListId, is_finish: isFinish}, 'html'
    ).done(function(data) {
      if (['work_blank', 'check_list_blank', 'not_update'].includes(data)) {
        me._showPopupWhenNotFoundCheckList()
        return
      } else if (data === 'changed_reload') {
        Popup.confirm(I18n.t('work.changed_refresh'), {
          cancelBtn: 'false',
          success: () => { location.reload() },
          cancel: () => { location.reload() },
          confirmText: 'OK',
          width: 'auto'
        })
        return
      }
      popup_obj.find('#render_check_list_work').html(data)
      logWorkAction._calculateCheckList(popup_obj)
    })
  }

  async addCheckList(btn_ele, workId, projectId){
    let addItem = $(btn_ele).prevAll('.add_list').val()
    const url = Routes.owner_project_check_list_work_index_path(projectId)
    const modalCheckList = $(`#checkListModal-${workId}`)
    if (addItem === '' || addItem === undefined) {
      Popup.alert('空白にはできません')
      return
    }
    await Shared.callAjax(url, { work_id: workId, work_check_list: { name: addItem } }, 'html').done(function(data) {
      modalCheckList.find('#render_check_list_work').html(data)
      $(btn_ele).prevAll('.add_list').val('')
      logWorkAction._calculateCheckList(modalCheckList)
    })
  }

  deleteCheckList(btn_del, workId, checkListId, projectId){
    let listPopup = $(btn_del).closest('.modal')
    Popup.confirm(I18n.t('work.remove_check_list'), {
      success: () => {
        logWorkAction.handleRemoveCheckList(listPopup, workId, checkListId, projectId)
      },
      cancelBtn: 'false',
      confirmText: '削除する',
      width: 'auto',
    })
  }

  handleRemoveCheckList(listPopup, workId, checkListId, projectId) {
    const url = Routes.delete_check_list_owner_project_check_list_work_index_path(projectId)
    const me = this
    Shared.callAjax(url, { work_id: workId, check_list_id: checkListId }, 'html').done(function(data) {
      if (['work_blank', 'check_list_blank', 'not_delete'].includes(data)) {
        me._showPopupWhenNotFoundCheckList()
        return
      }
      listPopup.find('#render_check_list_work').html(data)
      logWorkAction._calculateCheckList(listPopup)
    })
  }

  showChatCheckList(ele, checkListId, workId, projectId) {
    const url = Routes.check_work_check_list_owner_project_check_list_work_index_path(projectId)
    const me = this
    Shared.callAjax(url, { work_id: workId, check_list_id: checkListId }).done(function(data) {
      $(`#show-modal-select-list-qa-${checkListId}`).modal('show');
    })
    .fail(function(data) {
      me._showPopupWhenNotFoundCheckList()
    })
  }

  _showPopupWhenNotFoundCheckList() {
    Popup.confirm(I18n.t('work.not_check_list'), {
      cancelBtn: 'false',
      success: () => { Shared.reloadCurrentPage() },
      cancel: () => { Shared.reloadCurrentPage() },
      confirmText: 'OK',
      width: 'auto'
    })
  }

  deleteCheckListAll(btn_del_all, workId, projectId){
    const logWork = this
    Popup.confirm(`<div class="text-white">${I18n.t('work.do_you_want_remove_all_checklist')}</div>`, {
      success: () => { logWork._removeAllCheckList(btn_del_all, workId, projectId) },
      cancelBtn: 'false',
      confirmText: '削除する',
      width: 'auto',
    })
  }

  _removeAllCheckList(btn_del_all, workId, projectId) {
    const url = Routes.delete_all_checklist_owner_project_check_list_work_index_path(projectId)
    Shared.callAjax(url, { work_id: workId }).done(function(response) {
      let listPopup = $(btn_del_all).closest('.modal')
      listPopup.find('.lists').find('.list-item').not('.d-none').remove()
      logWorkAction._calculateCheckList(listPopup)
    })
    .fail(function(response) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _calculateCheckList(popup_ele){
    // counting list item and change range level
    let listPopup = $(popup_ele)
    let listItems = listPopup.find('.lists').find('.list-item').not('.d-none')
    let listCountTotal = listItems.find('input').length
    let listCountChecked = listItems.find('input:checked').length
    let listRange = listPopup.find('.check_lists_range')
    let rangePersent = Math.ceil(100 / listCountTotal * listCountChecked)
    if(listCountTotal == 0) rangePersent = 0
    if(rangePersent > 100) rangePersent = 100
    listPopup.find('.display_persent').text(rangePersent)
    listRange.val(rangePersent)
    Disabled.toggle(listPopup.find('.c_list_delete_btn'), listPopup.find('input[type="checkbox"]').length === 0)
  }

  settingApproval(element, type, entity_id, is_quantity = false) {
    if (is_quantity) {
      $('#settingApproval').find('.show-text-for-quantity').removeClass('d-none')
    } else {
      $('#settingApproval').find('.show-text-for-quantity').addClass('d-none')
    }
    const entity_type = type === 'task' ? 'Task' : 'Work'
    const type_text = type === 'task' ? 'タスク' : 'チケット'
    $('#settingApproval').find('#type').text(type_text)
    $('#settingApproval').find('input:checkbox').prop('checked', false)
    $('input[name="approval_setting[entity_type]"]').val(entity_type);
    $('input[name="approval_setting[entity_id]"]').val(entity_id);
    Shared.callAjax(Routes.show_approval_settings_path(entity_type, entity_id), {}, 'json', 'GET')
    .done(function(result) {
      $('#settingApproval .need_have_client').attr('disabled', result.not_have_client)
      $('#settingApproval').find(`input:checkbox[name="approval_setting[option1]"]`).prop('checked', result.option1)
      $('#settingApproval').find(`input:checkbox[name="approval_setting[option2]"]`).prop('checked', result.option2)
      $('#settingApproval').find(`input:checkbox[name="approval_setting[option3]"]`).prop('checked', result.option3)
      $('#settingApproval').attr('data-entity-type', type);
      $('#settingApproval').modal('show');
    })
  }

  changeSettingApproval(element) {
    const $ele = $(element);
    const modal = $ele.parents('#settingApproval');
    const option1 = modal.find('input:checkbox[name="approval_setting[option1]"]:checked').val() || 0;
    const option2 = modal.find('input:checkbox[name="approval_setting[option2]"]:checked').val() || 0;
    const option3 = modal.find('input:checkbox[name="approval_setting[option3]"]:checked').val() || 0;
    const entity_type = modal.find('input[name="approval_setting[entity_type]"]').val();
    const entity_id = modal.find('input[name="approval_setting[entity_id]"]').val();

    const dataAjax = {
      option1: option1,
      option2: option2,
      option3: option3,
      entity_type: entity_type,
      entity_id: entity_id
    }
    Shared.callAjax(Routes.approval_settings_path(), dataAjax)
    .done(function() {
      Popup.alert(entity_type == 'Task' ? I18n.t('task.update_approval_setting_success') : I18n.t('work.save_success'))
      $('#settingApproval').modal('hide');
      $('.preloader').prop('style', '').addClass('loading').fadeIn();
    })
    .fail(function(result) {
      toastr.error(I18n.t('errors.messages.user_not_authorized'), "", { closeButton: true, progressBar: true });
    })
  }
  _releaseWorkParent(workParent) {
    $(workParent).find('.select_status').val('delivered').trigger('change')
    $(workParent).find('.input_status').val('delivered');
  }

  _restorePrevious(quantityComponent, deleteWorksEle, listQuantities) {
    $(deleteWorksEle).removeClass('d-none work-quantities-delete').addClass('work-quantities');
    $(listQuantities).find('.work-quantity-status').toArray().forEach(statusEle => {
      $(statusEle).val($(statusEle).data('status-old'));
      $(statusEle).parents('.status-quantity').find('.check-quantity-status')
                  .prop('checked', $(statusEle).val() === 'approval')
    })
  }

  _deleteWorkQuantity(deleteWorksEle) {
    deleteWorksEle.toArray().forEach(workDelete => {
      const workQuantityId = $(workDelete).find('.work-quantity-id').val();
      $('.list-work-quantity-delete').val(`${$('.list-work-quantity-delete').val()}, ${workQuantityId}`);
      this._moveWorkEleToTopTable($(workDelete).parents('.invoice__payment--tbody'));
      workDelete.remove();
    })
  }

  _changeDataStatus(listStatusEle) {
    $(listStatusEle).toArray().forEach(statusEle => {
      $(statusEle).attr('data-status-old', $(statusEle).val())
    })
  }

  _uncheckTicketCreation(workDeleteEle, childWorks) {
    const allWorksEle = $(workDeleteEle).parents('tbody');
    if(childWorks.length > 1) {
      const idWorkDelete = $(workDeleteEle).find('.work_id').val();
      const lastWorkChild = childWorks[childWorks.length - 1]
      if ($(lastWorkChild).find('.work_id').val() === idWorkDelete) {
        $(childWorks[childWorks.length - 2]).find('.invoice__table--checkbox').prop('checked', false);
      }
    } else {
      $(allWorksEle).find('.invoice__table--checkbox').prop('checked', false);
    }
  }

  async _renderQuotationSelected(type, element, quotation, step) {
    const workEle = this._fetchImageSelect(type).parents('tr');
    const idModalShowQuotation = this._fetchIdDetailQuotation(type);
    const quantity = Number(workEle.find('.show-quantity:last').text());
    const orderType = workEle.find('.render_unit_work').val();
    const workId = workEle.find('.work_id').val();
    const quotationType = this.fetchOderType(quotation.contract_sub_type);
    if (gon.log_work_feature.is_client_or_owner && type === 'received') {
      const isQuotationTypeFixed = gon.log_work_feature.quotation_fixed.includes(quotation.contract_sub_type)
      $(workEle).find('.select_unit_work')
                .attr({ 'is-quotation-type-fixed': isQuotationTypeFixed, 'quotation-id': quotation.id })
      $(workEle).find('.max-quantity').attr('is-fixed-order', isQuotationTypeFixed);
    }
    if (orderType && quotationType != orderType && quantity > 0) {
      Popup.confirm(I18n.t('work.change_order_type'), {
          success: () => this.acceptSelectCompany(element, type, step),
          cancel: () => Modal.hide($(`div#${idModalShowQuotation}`)),
        }
      )
    } else {
      this.acceptSelectCompany(element, type, step);
    }
  }

  _showPopupMuchQuantity(element, quotation, quantity, type, step) {
    Popup.confirm(I18n.t('work.much_quantity', { quantity: quantity }), {
      confirmText: 'OK',
      success: () => this._renderQuotationSelected(type, $(element), quotation, step),
      cancel: () => { return true}
    })
  }

  _showMessageErrorOfElement(element, message) {
    toastr.error(message, "", { closeButton: true, progressBar: true });
    Shared.errorFieldMessage($(element), message);
  }

  _restoreStatusOfWorkBranch(element) {
    const allWorksEle = $(element).parents('tbody');
    const workEle = $(element).parents('tr');
    const parentId = workEle.attr('parent_id');
    const worksChild = allWorksEle.find('.invoice__table--new').toArray();
    const releaseDate = workEle.find('.input_delivery_date').val();
    if (!parentId && releaseDate && worksChild.length > 0) {
      this._restoreStatusWorkChild(worksChild)
    }
  }

  _restoreStatusWorkChild(worksChild) {
    worksChild.forEach(workChild => {
      const statusWorkChildBeforeChange = $(workChild).find('.before-status').val();
      if (statusWorkChildBeforeChange) {
        $(workChild).find('.select_status').val(statusWorkChildBeforeChange)
        $(workChild).find('.input_status').val(statusWorkChildBeforeChange);
      } else {
        $(workChild).find('.select_status').val($(workChild).find('.input_status').val());
      }
    })
  }

  _fetchMessageDelete(isOtherType, isInvoice, isWorkChild) {
    let messageDelete = ''
    if (isOtherType) {
      messageDelete = I18n.t('work.confirm_remove_order');
    } else if (isInvoice) {
      messageDelete = I18n.t('work.delete_work_exist_invoice');
    } else if (isWorkChild) {
      messageDelete = I18n.t('work.delete_work_exist_children');
    } else {
      messageDelete = I18n.t('work.delete_work');
    }
    return messageDelete;
  }

  _removeAllWork(allWorksEle, workIds) {
    this._addWorkDelete(workIds);
    allWorksEle.remove();
    if ($('body').find('.delete-work').length === 0) $('input#submit-form').trigger('click');
  }

  _removeAllTotalQuantity(tbodyEle) {
    tbodyEle.find('.total-number-quantity').val('');
    tbodyEle.find('.total-hours').val('');
    tbodyEle.find('.total-minutes').val('');
    tbodyEle.find('.show-quantity').text(0);
    tbodyEle.find('.show_total_quantity').text(0);
  }

  _modifyWorkQuantityEle(workQuantityEle, orderType, workQuantityComponent) {
    // let addClass = ''
    // let removeClass = ''
    // if (orderType === 'by_time') {
    //   addClass = 'col-lg-2 col-6'
    //   removeClass = 'col-md-5 col-12'
    // } else {
    //   addClass = 'col-lg-3 col-6'
    //   removeClass = 'col-md-5 col-12'
    // }
    // workQuantityEle.find('.work-quantity-description-title')
    //                .removeClass(`border-right-2 ${removeClass}`).addClass(`text-truncate ${addClass}`)
    workQuantityEle.find('.show-current-user').removeClass('d-none');
    workQuantityEle.find('.user-name').val(gon.log_work_feature.current_user.name);
    workQuantityEle.find('.user-id').val(gon.log_work_feature.current_user.id);
    // workQuantityEle.find('.title-hours-input, .hours-text, .quantity-minutes-input_outer').removeClass('col-md-1 col-2').addClass('col-lg-1 col-2');
    // workQuantityEle.find('.times-text').removeClass('col-2').addClass('col-1');

    Shared.toggleAttribute(workQuantityEle.find('input'), 'readonly', true);
    workQuantityEle.find('.work-quantity-created-at').attr('readonly')
    workQuantityEle.find('.work-quantity-description').removeClass('w-50')
                   .attr('onclick', 'logWorkAction.showDescriptionQuantity(this)');
    if (workQuantityComponent.find('.list-work-quantity').find('.work-quantities').length === 0) {
      workQuantityComponent.find('.list-work-quantity').find('.cell').removeClass('border-bottom-2-cerulean-blue')
      // workQuantityEle.find('.cell').addClass('border-bottom-2-cerulean-blue')
    }
    return workQuantityEle;
  }

  _fetchMaxQuantity(maxQuantityEle) {
    const maxQuantityByOrderReceived = $(maxQuantityEle).attr('max-quantity-of-order-received');
    const maxQuantity = Number($(maxQuantityEle).val());
    const maxQuantityByOrderClient = $(maxQuantityEle).attr('max-quantity-by-order')
    const isFixOrder = $(maxQuantityEle).attr('is-fixed-order') === 'true'
    if (!isFixOrder) return maxQuantity
    if (Number(maxQuantityByOrderReceived) > Number(maxQuantityByOrderClient) &&
        maxQuantity > Number(maxQuantityByOrderClient)) {
      return Number(maxQuantityByOrderClient)
    } else if (maxQuantityByOrderReceived && maxQuantity > Number(maxQuantityByOrderReceived)) {
      return Number(maxQuantityByOrderReceived);
    } else {
      return maxQuantity
    }
  }

  _totalAllWorkQuantity(modalEle) {
    let total = 0
    const listWorkQuantity = modalEle.find('table.list-work-quantity')
    listWorkQuantity.find('tr.work-quantities').toArray().forEach(quantity => {
      total += Number($(quantity).find('input.quantity-number').val())
    })
    return total
  }

  _isMuchQuantity(workId, orderIds, quantity) {
    const projectId = this._fetchProjectId();
    const taskId = this._fetchTaskId();
    return Shared.callAjax(
      Routes.check_much_quantity_owner_project_task_ticket_path(projectId, taskId), {
        order_ids: orderIds,
        work_id: workId,
        quantity: quantity
      }
    )
  }

  toggleQuantity(element){
    if($(element).hasClass('show')){
      $(element).dropdown('dispose').removeClass('show')
    }else{
      $(element).dropdown().addClass('show')
    }
  }

  popupConfirmShowInputEffort (envent, checkClientSuppiler = false, idWork){
    let typeWorkTarget = $('div.change_counting').text().trim();
    if(checkClientSuppiler){
      typeWorkTarget = "自社数";
    }
    const workTR = $(envent.target).parents('tr');
    const typeWork = workTR.find('.render_unit_work').val();
    let spantext =workTR.find('.render_unit_work').parents('td.column_quantity').find('input.data-effort').val().replaceAll('\n', '')
    if(spantext.includes('数量を入力')){
     if(typeWork === 'by_time'){
        spantext = '0.0h';
      }else if(typeWork === 'by_number'){
        spantext = '0.0個';
      }else{
        spantext = '0.0';
      }
    }else if (!spantext.includes('|')) {
      if(typeWork === 'by_time'){
        spantext += 'h';
      }else if(typeWork === 'by_number'){
        spantext += '個';
      }
    }
    let text = `現在の「${typeWorkTarget}」数量は<br>${spantext} です`;
    Popup.confirm(text, {
      success: () => {
        $(`#click-show-quantity-${idWork}`).trigger('click');
       },
      cancel: () => {},
      confirmText: '数量ページに移動する',
      cancelBtn: true
    })
  }

  initSortWork() {
    var fixHelperModified = function(e, tr) {
      var $originals = tr.children();
      var $helper = tr.clone();
      $helper.children().each(function(index)
      {
        $(this).outerWidth($originals.eq(index).outerWidth())
      });

      return $helper;
    };
    $("table.render_works").sortable({
      cursor: 'grabbing',
      start: function(event, ui) {
        ui.helper.css('display', 'table')
      },
      stop: function(event, ui) {
        ui.item.css('display', '')
      },
      helper: fixHelperModified,
      start: function(event, ui) {
        return ui.placeholder.children().each(function(index, child) {
          var source;
          source = ui.helper.children().eq(index);
          $(child).removeAttr('class').removeAttr('colspan');
          $(child).addClass(source.attr('class'));
          $(child).find('td').last().remove()
          if (source.attr('colspan')) {
            return $(child).attr('colspan', source.attr('colspan'));
          }
        });
      },
      items: 'tbody',
      update: function(event, ui) {
        if($("table.render_works").attr("data-sort-default") == 'true') {
          if(!gon.log_work_feature.is_not_show_popup_custom_sort) {
            if($('#notifyChangeSortProject').attr('data-show-notify') != 'true')
              Shared.showModalForElement($('#notifyChangeSortProject'));
          }
        } else {
          const url = Routes.object_sort_path($("table.render_works").attr('data-sort-id'));
          const taskId = $("table.render_works").attr('data-task-id');
          let dataSort = SortFunction.listDataSortTicket();
          Shared.callAjax(url, {data_sort: dataSort, type: 'ticket', object_id: taskId}, 'json', 'patch')
        }
      }
    })
    if(Shared.checkDeviceWithTouchAction()) {
      this.disableSortWork()
    }
  }

  disableSortWork() {
    $("table.render_works").sortable("disable");
  }

  enableSortWork() {
    if(!Shared.checkDeviceWithTouchAction()) {
      $("table.render_works").sortable("enable");
    }
  }

  hiddenModal(modalID) {
    if (!modalID) return;

    $(`${modalID}`).modal('hide')
  }
}

function appendCompanyAndOrder(workParentEle, orderSelectEle, inputWorkId, companySelectEle) {
  workParentEle.find('.quotation_sender').val(orderSelectEle.val()),
  workParentEle.find('.company_received_order').val(companySelectEle.val())
  Ticket.save(inputWorkId)
}
