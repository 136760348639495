export default class Order {
  release(event) {
    const element = event.target
    const has_work_blank = $(element).attr('data-has-work-blank');
    let is_release = true;
    if (has_work_blank == 'true') {
      $('#modal_warning_work_blank').modal('show');
      $('#modal_warning_work_blank a.btn-accept_realease_order')
        .attr('href', $(element).attr('href')).attr('data-method', 'post')
      is_release = false
    }
    if (is_release) {
      $(element).attr('data-method', 'post')
    } else {
      event.preventDefault();
    }
  }
}
