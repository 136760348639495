import React, { Component } from 'react';
import { Button, Input, Modal } from 'reactstrap';

export default class ModalShowCreateCommentNotLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  toggle = () => {
    this.props.togglePopup()
  }

  render() {
    const { isOpen, isNotShowCreateCommentNotLogin, changeNotShowModalCreateCommentNotLogin } = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.toggle()}
        className="modal-dialog-centered modal-create-comment-not-login w-fit-content m-auto modal-style"
        modalClassName="modal-second"
      >
        <div className='align-center-div'>
          <div className='text-for-header'>
            <h5 className='text-white font-weight-bold text-title'>コメントを更新しました。</h5>
          </div>
          <div className='text-center mt-2'>
            <div className='text-left text-white text-show-create-comment-not-login'>
              <span>まだメンバーへ返信されません。</span><br/>
              <span>メンバーへの赤入れ通知は、</span><br/>
              <span>画面右下「赤入れ通知」より通知されます。</span>
            </div>
          </div>
          <div className='text-center mt-2'>
            <h5 className='font-weight-bold text-underline text-white cursor-pointer text-title' onClick={() => this.toggle()}>コメントを続ける</h5>
            <div className='d-flex mt-2 text-white text-center justify-content-center align-items-center'>
              <input className='form-control input-check-not-show' checked={isNotShowCreateCommentNotLogin} type='checkbox' onClick={(event) => changeNotShowModalCreateCommentNotLogin(event)}></input>
              今後表示させない
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
