export default class InvoicePayment {
  handleEnterPaymentInfo(element, searchCompanyId) {
    if (searchCompanyId) {
      $('#invoice_payment__select_company').val(searchCompanyId).hide();
      $('#modalSelectCompanyEnterPayment').modal('show');
    } else {
      $('select#select_company_before_enter_payment').val('')
      $('#modalSelectCompanyBeforeEnterPayment').modal('show');
    }
  }

  selectCompanyBeforeEnterPayment(element, invoiceList = false) {
    const $ele = $(element);
    if (invoiceList) {
      this._fetchAmountReceivedForCompany($ele.val())
    } else {
      this._fetchAmountPaidForCompany($ele.val())
    }
    if (!$ele.val()) return
    $('#invoice_payment__select_company').val($ele.val()).show();
    $('#modalSelectCompanyBeforeEnterPayment').modal('hide');
    $('#modalSelectCompanyEnterPayment').modal('show');
  }

  setCurrentCompany() {
    const companySelectEle = $('#modalSelectCompanyBeforeEnterPayment').find('#select_company_before_enter_payment')
    if(companySelectEle) companySelectEle.val('').trigger('change')
  }

  handleAmount(element) {
    const $ele = $(element);
    let amount = this._convertAmountToInt($ele.val())
    this._enabledBtnSubmitPayment(amount)
    if (!amount) {
      return;
    }
    amount = Shared.numberToCurrency(amount);
    amount = '￥' + amount
    $ele.val(amount)
  }

  convertAmount(element) {
    const $ele = $(element);
    let amount = this._convertAmountToInt($ele.val())
    if (!amount) {
      return;
    }
    $ele.val(amount)
  }

  changePaidDate() {
    let amount = this._convertAmountToInt($('#input-paid-amount').val());
    this._enabledBtnSubmitPayment(amount)
  }

  showModalUpdateInvoicePayment(element, invoicePaymentId) {
    const currentPath = window.location.pathname
    Shared.callAjax(Routes.edit_owner_invoice_payment_path(invoicePaymentId), { current_path: currentPath }, 'html', 'get').done(function(result) {
      $('#modalEditInvoicePayment').find('.form-edit-invoice-payment').empty().html(result)
      defaultAction.runDatepicker();
      $('#modalEditInvoicePayment').modal('show')
    })
  }

  submitPaymentInfo(element) {
    const $ele = $(element);
    const modal = $ele.parents('#modalSelectCompanyEnterPayment');
    const companyId = modal.find('#invoice_payment__select_company').val();
    const paidDate = modal.find('#input-paid-date').val();
    const paidAmount = this._convertAmountToInt(modal.find('#input-paid-amount').val());
    if (!companyId || !paidDate || !paidAmount || paidAmount === 0) {
      return;
    }
    const url = Routes.owner_invoice_payments_path();
    const currentPath = window.location.pathname

    const data = { company_id: companyId, paid_date: paidDate, paid_amount: paidAmount, current_path: currentPath }
    Shared.callAjax(url, data).done(function(result) {
      const url = new URL(window.location.href)
      url.searchParams.set('show_invoice_payments', 'true')
      window.location = url
    })
  }

  updatePaidDate(element) {
    const $ele = $(element);
    const modal = $ele.parents('#modalEditInvoicePayment')
    this._enabledBtnUpdatePayment(modal)
  }

  updateAmount(element) {
    const $ele = $(element);
    const modal = $ele.parents('#modalEditInvoicePayment')
    this._enabledBtnUpdatePayment(modal)

    let amount = this._convertAmountToInt($ele.val())
    if (!amount) {
      return;
    }
    amount = Shared.numberToCurrency(amount);
    amount = '￥' + amount
    $ele.val(amount)
  }

  updatePaymentInfo(element, invoicePaymentId) {
    const $ele = $(element);
    const modal = $ele.parents('#modalEditInvoicePayment');
    const paidDate = modal.find('#input-paid-date').val();
    const paidAmount = this._convertAmountToInt(modal.find('#input-paid-amount').val());
    if (!paidDate || !paidAmount) {
      return;
    }
    const url = Routes.owner_invoice_payment_path(invoicePaymentId);
    const currentPath = window.location.pathname

    const data = {　paid_date: paidDate, paid_amount: paidAmount, current_path: currentPath }
    Shared.callAjax(url, data, 'json', 'patch').done(function(result) {
      Shared.reloadCurrentPage();
    })
  }

  deletePaymentInfo(event, invoicePaymentId, invoiceText) {
    event.preventDefault();
    Popup.confirm(`${invoiceText}情報を削除します。<br>よろしいですか？`,{
      confirmText: '削除する',
      cancelText: 'キャンセル',
      width: 'auto',
      success: () => {
        const url = Routes.owner_invoice_payment_path(invoicePaymentId);
        const data = {
          current_path: window.location.pathname
        };
        const callAjax = Shared.callAjax(url, data, 'html', 'delete');
        callAjax.done(function(data) {
          Shared.reloadCurrentPage();
        })
      },
    })
  }

  toggleInvoicePayment(element) {
    const url = new URL(window.location.href)
    const showPaymentParam = url.searchParams.get('show_invoice_payments')
    if (!!showPaymentParam) {
      url.searchParams.set('show_invoice_payments', '')
    } else {
      url.searchParams.set('show_invoice_payments', 'true')
    }
    window.location = url
  }

  async _fetchAmountReceivedForCompany(companyId) {
    await Shared.callAjax(Routes.sum_amount_received_owner_invoices_path(), { company_id: companyId })
    .done(function(result) {
      $('span#sum_amount_for_company').text(result.sum_amount)
    })
  }

  async _fetchAmountPaidForCompany(companyId) {
    await Shared.callAjax(Routes.sum_amount_paid_owner_invoices_path(), { company_id: companyId })
    .done(function(result) {
      $('span#sum_amount_for_company').text(result.sum_amount)
    })
  }

  _enabledBtnSubmitPayment(amount) {
    if (!!amount && Shared.isValidDate($('#input-paid-date').val())) {
      $('#btn-submit-payment_info').attr('disabled', false)
    } else {
      $('#btn-submit-payment_info').attr('disabled', true)
    }
  }

  _enabledBtnUpdatePayment(modal) {
    const elePaidDate = modal.find('#input-paid-date')
    const eleAmount = modal.find('#input-paid-amount')
    const newAmount = this._convertAmountToInt(eleAmount.val());
    const oldAmount = this._convertAmountToInt(eleAmount.attr('data-old-value'));
    const newPaidDate = elePaidDate.val();
    const oldPaidDate = elePaidDate.attr('data-old-value');
    if (!!newAmount && Shared.isValidDate(newPaidDate) && (oldAmount !== newAmount || newPaidDate !== oldPaidDate)) {
      modal.find('#btn-submit-payment_info').attr('disabled', false)
    } else {
      modal.find('#btn-submit-payment_info').attr('disabled', true)
    }
  }

  _convertAmountToInt(amount) {
    return Number(amount.replaceAll(',', '').replace('￥', ''))
  }
}
