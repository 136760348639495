export default class SideBar {
  showSideBar(event){
    this._handleShowSideBar(event, '#recently-projects')
  }
  showSideBarFromRecentlyTasks(event){
    this._handleShowSideBar(event, '#recently-tasks')
  }
  showSideBarRecently(event){
    this._handleShowSideBarRecently(event, '#recently-projects')
  }
  showSidebarRecentlyTasks(event) {
    this._handleShowSideBarRecently(event, '#recently-tasks')
  }

  _handleShowSideBar(event, elementId) {
    $(elementId).addClass('d-none');
    $('#sidebar-recently').toggleClass('d-none');
    event.stopPropagation()
  }

  _handleShowSideBarRecently(event, elementId) {
    $('#sidebar-recently').addClass('d-none');
    $(elementId).toggleClass('d-none');
    event.stopPropagation()
  }

  openModalCreateNew() {
    if ($('.plus.plus-icon').length > 0) {
      $('.plus.plus-icon').trigger('click');
    } else {
      $('.btn-create-new-footer').trigger('click');
    }
  }

  saveAkaireFileName(event) {
    var id = event.dataset["id"];
    var name = event.value;
    $.ajax({
      method: 'PUT',
      url: "/akaire_feature/akaire_files/" + event.dataset["id"],
      dataType: 'json',
      data: { id: id, name: name },
    }).done(function() {
      $(`.akaire-file-name-${id}`).text(name)
    })
  }

  showModalDownloadAnimation(ele) {
    if ($('.btn-download-video').length > 0) {
      $('.btn-download-video').trigger('click');
    } else {
      $('.btn-download-for-mobile').trigger('click');
    }
  }
}
