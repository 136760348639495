export default class PaymentTermRevision {
  validate(ele, sender, url, type) {
    let data = { sender: sender, type: type };
    let $form = $(ele).parents('form');
    data = `${$form.serialize()}&${$.param(data)}`;
    let callAjax = Shared.callAjax(url, data, 'json', 'POST');
    callAjax.done(function (res) {
      if (res['status']) {
        $form.submit();
      } else {
        $('.register-payment').modal('hide');
        Popup.alert(res['message']);
      }
    });
  }
}
