$(document).ready(function(){
    var hash = {};
    var value = JSON.parse(localStorage.getItem('hash'));
    var url = $(location).attr('href');
    if(url.indexOf('?') != -1) {
        $.each(value, function(k, v) {
            $('input[data-name="'+k+'"]').prop('checked', v);
            $('select option[data-name="'+k+'"]').prop('selected', v);
        });
    } else {
        localStorage.removeItem('hash')
    }

    $('#filter-form').on('submit', function(){
        $('input:checkbox').each(function(index, value) {
            if($(this).is(':checked')) {
                hash[$(this).attr('data-name')] = $(this).is(':checked');
            }
        })
        $('select option').each(function (index, value) {
            if($(this).is(':selected')) {
                hash[$(this).attr("data-name")] = $(this).is(':selected');
            }
        })
        localStorage.setItem('hash', JSON.stringify(hash))
    });

})
