export default class SettingPaymentMethod {
  setOptionPayment() {
    const $ele = $('input[type="radio"][name="company_payment_id"]:checked');
    if ($ele.val() === 'create_new') {
      $('#modalCreateNewPaymentMethodForQuotationOrder').modal('show')
    } else if ($ele.val() === 'temp_client') {
      $('span#quotation_setting_payment_method').text('*************')
      $('span#quotation_setting_payment_method').removeClass('text-underline').addClass('pt-2')
      $('input#set_quotation_payment_id').val('')
    } else if (!!$ele.val()) {
      $('input#set_quotation_payment_id').val($ele.val())
      $('span#content-payment-info').text($ele.attr('data-payment-info'))
      $('#modalNoticePaymentInfo').modal('show')
      $('span#quotation_setting_payment_method').text($ele.attr('data-payment-info'))
      $('span#quotation_setting_payment_method').removeClass('text-underline').removeClass('pt-2')
    }
  }

  createNewPaymentMethod(event, element) {
    const $ele = $(element);
    event.preventDefault();
    const form = $('#formCreateNewPaymentTermRevision')
    const valuesToSubmit = form.serialize();
    Shared.callAjax(Routes.owner_create_payment_term_path(), valuesToSubmit)
    .done(function(response) {
      $('#modalCreateNewPaymentMethodForQuotationOrder').modal('hide')
      $('input#set_quotation_payment_id').val(response.payment_id)
      $('span#content-payment-info').text(response.payment_info)
      $('#modalNoticePaymentInfo').modal('show')
      $('span#quotation_setting_payment_method').text(response.payment_info)
      $('span#quotation_setting_payment_method').removeClass('text-underline').removeClass('pt-2')
    })
    .fail(function(response) {
      if (response.responseJSON && response.responseJSON.message) {
        Popup.alert(`${response.responseJSON.message}`)
      }
    })
  }
}
