export function truncate(name, limit = null) {
  const size = window.innerWidth;
  if (name && name.length > 0) {
    switch(true){
      case(size < 1025):
        return name.length > 12 ? name.substring(0, limit || 10) + "..." : name;
      case(size < 1300):
        return name.length > 15 ? name.substring(0, limit || 14) + "..." : name;
    }
    return name.length > 30 ? name.substring(0, limit || 20) + "..." : name;
  }
}