export default class Animation {
  checkPassWordShareVideo(ele, urlToken) {
    const component = $(ele).parents('div.pass-share-video')
    const passWord = $(component).find('input').val()
    const url = Routes.check_password_video_animation_path(urlToken)
    Shared.callAjax(url, {password: passWord}).done(function(data) {
      if (data.success) {
        const newUrl = Routes.animation_path(urlToken)
        window.location.href = `${newUrl}?access_pass=true`
      } else {
        Popup.alert('無効なパスワードです')
      }
    })
  }

  changePasswordShareVideo(ele) {
    const passWord = $(ele).val()
    const component = $(ele).parents('div.pass-share-video')
    if (passWord.length >= 6) {
      $(component).find('button.btn-save-pass-share-video').removeAttr('disabled')
    } else {
      $(component).find('button.btn-save-pass-share-video').attr('disabled', true)
    }
  }

  forcusInputPassword(ele) {
    $(ele).attr('type', 'text')
  }

  outforcusInputPassword(ele) {
    $(ele).attr('type', 'password')
  }

  checkPassWordShareUrl(ele, urlToken) {
    const component = $(ele).parents('div.pass-share-video')
    const passWord = $(component).find('input').val()
    const url = Routes.check_password_share_object_path(urlToken)
    Shared.callAjax(url, {password: passWord}).done(function(data) {
      if (data.success) {
        const newUrl = Routes.object_path(urlToken)
        window.location.href = `${newUrl}?access_pass=true`
      } else {
        Popup.alert('無効なパスワードです')
      }
    })
  }

  changePasswordShareUrl(ele) {
    const passWord = $(ele).val()
    const component = $(ele).parents('div.pass-share-video')
    if (passWord.length >= 6) {
      $(component).find('button.btn-save-pass-share-video').removeAttr('disabled')
    } else {
      $(component).find('button.btn-save-pass-share-video').attr('disabled', true)
    }
  }

  removeParamsForUrlCheckPass() {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace(/[?&]access_pass=true/, '');
    window.history.replaceState({}, document.title, newUrl);
  }
}
