import React, { Component } from 'react';
import classnames from 'classnames';

export default class MarkShape extends Component {

  hex2RGBA = (hex, shape) => {
    if (shape == 'point') {
      return hex
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.15)`
  }
  render() {
    const { x, y, width, height, shape, color, isCommentPeriod } = this.props;
    let shapeColor = isCommentPeriod ? '#0066FF' : color;
    const style = {
      backgroundColor:  gon.is_login ? (shapeColor ? this.hex2RGBA(shapeColor, shape) : 'rgb(230,12,17, 0.15)') : 'rgba(0, 0, 0, 0.15)',
      borderColor: gon.is_login ? shapeColor : 'rgba(0, 0, 0)'
    }
    switch (shape) {
      case 'point':
        return (
          <div className="mark-shape" style={{ left: `${x / 100}%`, top: `${y / 100}%` }}>
            <div className="red-point" style={style}></div>
          </div>
        )
      case 'rect':
        return (
          <div className="mark-shape" style={{ left: `${x / 100}%`, top: `${y / 100}%`, width: `${width / 100}%`, height: `${height / 100}%` }}>
            <div className="red-rect" style={style}></div>
          </div>
        )
      case 'draw':
        return (
          <div className="mark-shape" style={{ left: `${x}%`, top: `${y}%` }}>
          </div>
        )
    }
  }
}
