import consumer from "./consumer"
import { PATTERN_URL, EMAIL_REGEX, PHONE_REGEX } from "./consumer"


$(document).on('turbolinks:load', function () {
	consumer.subscriptions.create({channel: "TaskChannel", id: $('#js-tab_chat-popup').attr('data-channel-id')}, {
		connected() {
			console.info('connected to TaskChannel')
			// Called when the subscription is ready for use on the server
			// console.log("connected to channel ", $('#js-tab_chat').attr('data-channel-project-id'))
		},

		disconnected() {
			// Called when the subscription has been terminated by the server
			// console.log("disconnected to channel ", $('#js-tab_chat').attr('data-channel-project-id'))
		},

		received(data) {
			const listClass = '#box-chat-popup'

			if (data.action && data.action == 'delete') {
				return removeChatItem(data.message)
			} else if (data.action == 'update') {
				return updateContentChatItem(data, listClass)
			}

			renderAndScrollBottomChatList(data, listClass)
		}
	});
});
