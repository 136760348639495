import React, { Component } from "react";
import { Input } from "reactstrap";
import classNames from 'classnames';

const MIN_SCALE = 0;
const MAX_SCALE = 500;

export default class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 50,
      isShow: false,
    };
    const { min, max } = this.props;
    this.maxScale = max || MAX_SCALE;
    this.minScale = min || MIN_SCALE;
  }

  _handleChange(e, input) {
    const { handleChange, step } = this.props;
    if (+e.target.value > (this.maxScale) || e.target.value < (this.minScale) || !e.target.value) return;
    const value = input
      ? +e.target.value
      : Math.ceil(e.target.value / step) * step;
    handleChange(value);
  }

  render() {
    const { value, containerClass, step, handleChange, className } = this.props;
    const { isShow } = this.state;
    return (
      <div className={classNames(className,
        {}, 'wrap-slide'
        )}
      >
        <div className="slidecontainer">
          <span
            onClick={() => {
              if (value === this.minScale) return;
              handleChange(+value - step);
            }}
            className="fas fa-window-minimize"
          ></span>

          <input
            type="range"
            min={this.minScale}
            max={this.maxScale}
            value={value}
            className="slider"
            onChange={(e) => this._handleChange(e)}
          />
          <i
            className="fas fa-plus"
            onClick={() => {
              if (value === this.maxScale) return;
              handleChange(+value + step);
            }}
          ></i>
          {isShow ? (
            <div className="wrap-input">
              <Input
                value={value}
                min={this.minScale}
                max={this.maxScale}
                step={step}
                autoFocus
                type="number"
                onChange={(e) => this._handleChange(e, "input")}
                onBlur={() => {
                  this.setState({ isShow: false });
                }}
              />
            </div>
          ) : (
            <span
              className="ml-3 value"
              onClick={() => {
                this.setState({ isShow: true });
              }}
            >
              {value}%
            </span>
          )}
        </div>
      </div>
    );
  }
}
