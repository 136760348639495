import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
  consumer.subscriptions.create({channel: "WorkChannel", id: $('#js-tab_chat-popup').attr('data-channel-id')}, {
    connected() {
      console.info('connected to WorkChannel')
    },

    disconnected() {
      console.info('Disconnected to WorkChannel')
    },

    received(data) {
      const listClass = '#box-chat-popup'

      if (data.action && data.action == 'delete') {
        return removeChatItem(data.message)
      } else if (data.action == 'update') {
        return updateContentChatItem(data, listClass)
      }

      renderAndScrollBottomChatList(data, listClass)
    }
  });
});
