export default class Input {
  validateFileType(element, regex, message) {
    let $element = $(element)
    return Input._isFileTypeValid($element, regex, message);
  }

  validateFile(element, removeElement = '',
               allowedExtensions = /(\.jpg|\.gif|\.png|\.JPEG)$/i,
               maxSize = 5242880,
               options =
                 { errorMessageExtensions: '画像形式を使用する(jpg, gif, png, JPEG)',
                   errorMessageSize: 'このファイルが最大許容ファイルサイズを超えています(5MB)',
                   filenameElement: null
                 }
  ) {
    let $element = $(element)
    if ($element.attr('type') !== 'file') {
      throw(`The function is invalid for Element ${element}`)
    }
    if (!$element.val()) {
      $element.removeClass('is-valid');
    }

    if (
      Input._isFileTypeValid($element, allowedExtensions, options.errorMessageExtensions) &&
      Input._isFileSizeValid($element, maxSize, options.errorMessageSize)
    ) {
      const fileLength = $element[0].files.length;
      const text = fileLength === 1 ? $element[0].files[0].name : `${fileLength} files selected`;

      $element.removeClass('is-invalid');
      $(options.filenameElement || `.filename[for='${$element.attr('id')}']`).text(text)

      $(removeElement).prop('checked', false)
    } else {
      $element.val('')
      return false;
    }
  }

  validateUrl(element) {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    const $element = $(element);
    return $element.val() === '' || $element.val().match(regex)
  }

  changeLabel(element, bindElement) {
    let $element = $(element)
    const fileName = $element[0].files[0].name;
    $element.parent('label').next(bindElement).text(fileName)
  }

  changeFile(element,
             fileTypes = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.xls|\.xlsx|\.mp4|\.wmv|\.avi|\.mov|\.csv|\.psd|\.ai|\.mp3|\.ppt|\.pptx|\.zip|\.draw|\.ait)$/i,
             alertText = 'Excel、PowerPoint、PDF、Word、DOC、MP3、MP4、MOV、WMV、AVI、illustrator、Photoshopファイルがアップロード可能'
  ) {
    if (!Input._checkValidateNewForm(element, fileTypes)) {
      Popup.alert(alertText);
    }
  }

  toggleVisible(element, handleElements) {
    if (typeof handleElements == 'string') handleElements = handleElements.split(' ')
    handleElements.forEach((handleElement) => {
      Visiable.toggle($(handleElement))
    })
  }

  copyText(element) {
    $(element).focus();
    $(element).select();
    document.execCommand('copy');
    Popup.alert('KeyCodeをコピーしました');
  }

  lengthAndLines(element) {
    const $ele = $(element);
    var rows = $ele.val().split("\n").length;
    const oldEle = $(`#${$ele.attr('id')}_old`);
    if (!$ele.attr('max-rows') || !$ele.attr('max-length')) return;
    if(rows > Number($ele.attr('max-rows')) || $ele.val().replaceAll('\n', '').length > Number($ele.attr('max-length')))
    {
      Popup.alert('300文字以内（5行以内）となります。')
      $ele.val(oldEle.val())
    } else {
      oldEle.val($ele.val())
    }
    $ele.focus()
  }

  static _isFileTypeValid($element, fileTypes, errorMessage) {
    if (!fileTypes.exec($element.val())) {
      Popup.alert(errorMessage);
      return;
    }
    return true
  }

  static _isFileSizeValid($element, maxSize, errorMessage) {
    const isOverSize = Array.from($element[0].files).some((file) => {
      return file.size > maxSize
    })
    if (isOverSize) {
      Popup.alert(errorMessage);
    } else {
      return true
    }
  }
}
