import React, { Component } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import IconUser from '../../../assets/images/icon_member.png';
import EmailIcon from '../../../assets/images/email_icon.png';

export default class ModalInputInfoOfNotLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
    };
  }

  toggle = () => {
    this.props.togglePopup()
  }

  saveInfoOfNotLogin = () => {
    const {saveInfoOfNotLogin, emailTempOfNotLogin} = this.props;
    const expression_for_email = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
    const regex_email = new RegExp(expression_for_email)
    if (emailTempOfNotLogin.match(regex_email)) {
      saveInfoOfNotLogin()
      this.setState({emailError: false})
    } else {
      this.setState({emailError: true})
    }
  }

  render() {
    const { isOpen, userNameTempOfNotLogin, emailTempOfNotLogin, changeEmailTempOfNotLogin,
            changeUserNameTempOfNotLogin
          } = this.props
    const {emailError} = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.toggle()}
        className="modal-dialog-centered w-fit-content m-auto modal-style"
        modalClassName="modal-second"
      >
        <div className='show-body-modal-input-info-not-login text-center mx-3'>
          <div className='align-center-div'>
            <div class="text-center font-weight-bold text-black">
              <h5>
                <span>下記を入力後、「コメントする」より</span><br/>
                <span>メンバーへコメントができます。</span>
              </h5>
            </div>
            <div className='text-center mt-2'>
              <div className='col-12 div-input-user-name-temp-not-login'>
                <div className='icon-for-temp'>
                  <img className='img-for-temp' src={IconUser} style={{height: '28px', width: '30px', marginRight: '6px', marginLeft: '12px'}}></img>
                  <span className='show-line'></span>
                </div>
                <input className='form-control input-for-temp-info-not-login' placeholder='taro yamada' value={userNameTempOfNotLogin} onChange={(event) => changeUserNameTempOfNotLogin(event)} />
              </div>
              <div className='col-12 div-input-email-temp-not-login mt-2'>
                <div className='icon-for-temp'>
                  <img className='img-for-temp ml-2' src={EmailIcon} height='23' width='40'></img>
                  <span className='show-line'></span>
                </div>
                <input className='form-control input-for-temp-info-not-login'placeholder='user@example.com' value={emailTempOfNotLogin} onChange={(event) => changeEmailTempOfNotLogin(event)} />
              </div>
              {
                emailError && <div className='text-left text-error-mail'><span className='text-red'>メールアドレスが正しくありません。</span></div>
              }
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <div className='d-flex align-items-end'>
                <a href='/signup' className='text-black link-of-temp font-weight-bold text-underline' target='_blank'>新規登録</a>
                <a href='/signin' className='text-black link-of-temp ml-2 font-weight-bold text-underline'target='_blank'>ログイン</a>
              </div>
              <button type="button" disabled={ !userNameTempOfNotLogin || !emailTempOfNotLogin }  onClick={() => this.saveInfoOfNotLogin()} className="btn btn-black">コメントする</button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
