export default class RegistrationTaxNumber {
  showHideInputTaxNumber(ele) {
    const inputEle = $(ele).parents('div.registration_tax_number_div').find('input.input_registration_tax_number')
    const spanTextInput = $(ele).parents('div.registration_tax_number_div').find('span.text-of-input-tax')
    if ($(ele).is(':checked')) {
      inputEle.removeAttr('disabled');
      inputEle.attr('required', true);
      inputEle.removeClass('disabled');
      spanTextInput.addClass('font-weight-bold')
    } else {
      inputEle.attr('disabled', true)
      inputEle.removeAttr('required')
      inputEle.addClass('disabled');
      spanTextInput.removeClass('font-weight-bold')
      inputEle.val('')
    }
  }
}
