import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UploadAkaire from 'images/upload-img.png'
import { ALLOWED_FILE_TYPES } from '../util/common';

export default class ListFileError extends Component {
  constructor(props) {
    super(props);
    const { baseURL } = props;
    this.state = {
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.props.onClickClose();
    }
  };

  onUploadProgress(percent) {
    this.setState({showProgressBar: true})
    if(!this.state.convert_file){
      this.setState({ completed: percent })
    }
  }
  componentWillReceiveProps(nextProps) {
    const { statusConvert } = this.props
    if (nextProps.finishConvert !== this.state.finishConvert) {
      this.setState({ completed: 100 })
      statusConvert(false)
    }
    if (nextProps.completed !== this.state.completed) {
      setTimeout(() => {
        this.setState({ completed: 0, showProgressBar: false });
      }, 3000);
    }
    if (nextProps.isOpen !== this.props.isOpen) {
      if (!nextProps.isOpen && this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.setState({ completed: 0, showProgressBar: false });
      }
    }
  }

  getNumberOfPagesPdf = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onloadend = () => {
        resolve(reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length || 1);
      }
      reader.readAsBinaryString(file);
    });
  }

  clearInputFileAndShowPopup(popupMessage, popupType = '') {
    $('.animation-uploader-modal input').val('');
    if (popupType === '') {
      Popup.alert(popupMessage, '', '', {kind: 'akaire'});
    } else {
      Popup.alert(popupMessage, popupType, '', {kind: 'akaire'});
    }
  }

  showMessageErrorFile = (file) => {
    if (!file || !file.name) return '';
    if (!ALLOWED_FILE_TYPES.includes(file.type)) return '形式不一致'

    return '容量オーバー'
  }

  render() {
    const { isOpen, onClickClose, type, is_mobile, files } = this.props;
    let divText = <div className="font-size-14px mt-2 black-text">
      <span className="text-black font-weight-bold fs-16">【動画データのアップロード注意点】</span><div></div>
      <span className="text-black ml-1">・アップロード可能形式：MP4・WMV・AVI・MOV形式</span><br />
      <span className="text-black ml-1">・1ファイルでアップロードできる容量：～3GB以内</span><br />
    </div>
    const filledData = files.length < 10 ? Array.from({ length: 10 }, (_, index) => files[index]) : files;
    return (
      <Modal isOpen={isOpen} toggle={onClickClose} ref={node => (this.modalRef = node)} className="modal-dialog-centered modal-style additional-akaire">
        <ModalBody>
          <div className='justify-content-center'>
            <div className='text-left'>
              <span className='additional-akaire-title '>
                下記理由の為、{files.length}件が除外されました。
              </span>
            </div>
          </div>
          <div className="align-center-div mt-3 show-list-file-error">
            <div className='show-list-table-list-files'>
              <div className='d-flex text-center justify-content-center align-items-center mt-2 mx-3 border-1px-black'>
                <div className='w-100'>
                  <div className='show-table-files'>
                    <table className='w-100 table table-bordered mb-1'>
                      <thead className='bg-black w-100 text-white sticky-top'>
                        <tr>
                          <th width="30%">ファイル名</th>
                          <th>容量</th>
                          <th width="40%">エラー理由</th>
                        </tr>
                      </thead>
                      {
                        files.length === 0 ? (
                          <tbody className=''>
                            {[...Array(10)].map((_, index) => (
                              <tr key={index}><td></td><td></td><td></td></tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody className=''>
                            {(
                              filledData.map((item, index) => (
                                <tr key={index}>
                                  <td className='text-left ml-1 truncate-1rem' style={ !!item ? {padding: '1px', paddingLeft: '3px'} : {}}>{item ? item.name : ''}</td>
                                  <td className='text-right mr-1' style={ !!item ? {padding: '1px', paddingRight: '3px'} : {}}>
                                    {item?.size ? Shared.formatFileSize(item?.size, 1) : ''}
                                  </td>
                                  <td className='text-left ml-1' style={ !!item ? {padding: '1px', paddingLeft: '3px'} : {}}>
                                    { this.showMessageErrorFile(item) }
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        )
                      }
                    </table>
                  </div>
                </div>
               
              </div>
              <div className="text-center">
                <div className='text-center d-flex align-items-center justify-content-center'>
                  <div className='text-left'>
                    {divText}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="additional-akaire-button text-center">
            <Button type="button" color="black" className="btn-primary" onClick={onClickClose} style={{ height: 'auto' }}>
              <img className="create-akaire-box-icon-upload"
                          src={UploadAkaire} alt="アップロード" width="30" />
              再度アップロードする
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
