import React, { Component } from 'react';
import { Button, Input, Modal } from 'reactstrap';

export default class ModalChangeCommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledNoti: false
    };
  }

  handleClick = () => {
    if(this.props.kind === 'disabledNotiComparePage') {
      this.props.disabledNotiComparePage()
    } else if (this.props.kind === 'disabledNotiCompareVersion') {
      this.props.disabledNotiCompareVersion()
    }
  }

  toggle = () => {
    this.props.togglePopup()
  }

  render() {
    const { text, currentAnimation, isOpen } = this.props

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this.toggle()}
        className="modal-dialog-centered w-fit-content m-auto modal-style"
        modalClassName="modal-second"
        contentClassName="akaire_alert_popup"
      >
        <div className='swal2-html-container text-center'>
          <div class="show-popup-not-compare-version font-weight-bold">
            「{text}」以外に切替えると、メディア画面と
            <br/>
            前稿コメントの比較などができます。
            <br/>
            切替え後も「{currentAnimation?.title}」のコメント作成は可能です。
            <br/>
          </div>
          <div className="mt-2">
            <Input type="checkbox" onClick={() => this.handleClick()} className="mr-1 d-inline"/>
            <label className="mb-0">今後表示させない</label>
          </div>
        </div>
      </Modal>
    )
  }
}
