export default class ItemEachPage {
  change(ele) {
    const url = new URL(window.location.href)
    url.searchParams.set('items_each_page', $(ele).val())
    window.location = url
  }

  changeEachPage(eachPage) {
    const url = new URL(window.location.href)
    url.searchParams.set('items_each_page', eachPage)
    window.location = url
  }
}
