export default class CollabStatus {

  reInvite(element) {
    var collab_id = $(element).parent().data('collab-id');
    var url = "/collaborators/re_invite"
    var data = { collab_id: collab_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待メールを再送信しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  removeInvitation(element) {
    var collab_id = $(element).parent().data('collab-id');
    var url = "/collaborators/remove_invitation"
    var data = { collab_id: collab_id }

    Shared.callAjax(url, data, 'json', 'delete').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待を解除しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
      $("#modalWaitToApprove").find(".button-back-list").addClass("back-to-collabs");
    })
  }

  quickApproveInvitation(element) {
    var collab_id = $(element).parent().data('collab-id');
    var url = "/collaborators/quick_approve_invitation"
    var data = { collab_id: collab_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("が外部メンバーになりました。")
      $("#modalBeInvited").find(".collab-name").html(data.collab_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  rejectInvitation(element) {
    var collab_id = $(element).parent().data('collab-id');
    var url = "/collaborators/reject_invitation"
    var data = { collab_id: collab_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("からの招待を解除しました。")
      $("#modalBeInvited").find(".collab-name").html(data.collab_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }
}
