import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import copy from 'copy-to-clipboard';
import iconOpenURL from '../ui/icons/iconOpenUrlNew.svg';


export default class PublicUrlModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClickCopy: false,
      isShowInputDemo: true,
    }
  }

  handleButtonClick = () => {
    this.setState({isClickCopy: true})
    setTimeout(() => {
      this.setState({isClickCopy: false})
    }, 2000);
  }

  onClickCopy = () => {
    const { public_video_token, onClickClose, onClickCopyPublicURL, is_create_pass_video } = this.props;
    copy(this.publicUrl(public_video_token));
    Popup.alert('クリップボードにコピーしました。', '', '', {kind: 'akaire'})
    // this.handleButtonClick()
  }

  onClickSetPassword = (event) => {
    const { is_create_pass_video, changeIsShowInputVideo, is_project_owner_member, isAkaponMember } = this.props;
    if (is_project_owner_member) {
      if (!is_create_pass_video) {
        let message = 'パスワード設定は<br>ProまたはBusinessプラン限定になります。'
        let confirmButtonColor = null;
        if (isAkaponMember) {
          message = 'パスワード設定は<br>StandardまたはPremiumプラン限定になります。'
          confirmButtonColor = '#d33'
        }
        Popup.confirm(message, {
          success: () => { window.open(Routes.owner_total_info_path()) },
          confirmText: 'プランを変更する',
          cancelBtn: 'false',
          width: 'auto',
          confirmButtonColor: confirmButtonColor,
          kind: 'akaire',
        })
        $(event.target).prop('checked', false);
      } else {
        changeIsShowInputVideo()
      }
    } else {
      Popup.alert('パスワード設定の権限がありません。', '', '', {kind: 'akaire'})
      $(event.target).prop('checked', false);
    }
  }

  checkPassWord = (event) => {
    const {checkPassWord} = this.props;
    checkPassWord(event)
  }

  savePassWord = (event) => {
    const { is_create_pass_video, setPasswordForShareVideo, passwordShareVideo } = this.props;
    if (!is_create_pass_video) {
      Popup.alert('パスワード設定の権限がありません。', '', '', {kind: 'akaire'})
      return
    }
    setPasswordForShareVideo(passwordShareVideo)
  }

  changeFocusInput = (event) => {
    const {changeFocus, is_create_pass_video} = this.props
    if (!is_create_pass_video) {
      return
    }
    changeFocus()
  }

  publicUrl = (public_video_token) => {
    return `${window.location.origin}/p/animations/${public_video_token}`
  }

  render() {
    const {
      isOpen, onClickClose, public_video_token, is_create_pass_video, currentAnimation, isShowInputPassVideo,
      setPasswordForShareVideo, isDisabledButtonSavePass, passwordShareVideo, isShowInputDemo, currentPage, project
    } = this.props;
    return (
      <Modal isOpen={isOpen} className="animation-uploader-modal modal-dialog-centered modal-lg modal-style">
        <ModalBody className="p-4 text-center">
          <div className='text-center'>
            <div className='d-flex'>
              <div className='justify-content-center mx-auto d-flex show-position-of-animation-page'>
                <span className='text-ellipsis max-width-111px'>{project?.name}</span> 
                <span className='mx-1'>＞</span>
                <span className='text-ellipsis max-width-111px'>{currentPage?.title}</span>
                <span className='mx-1'>＞</span>
                <span>{currentAnimation?.title}</span>
              </div>
            </div>
            <h5>動画の公開URL</h5>
          </div>
          <span className='mb-3'>
            会員及び会員以外のメンバーに動画URLを発行できます。<br/>
            会員以外には赤入れ履歴は表示されません。
          </span>
          <div className="text-center">
            <div className="d-flex justify-content-center mx-auto show-input-share-akaire-detail w-50">
              <input readOnly="" type="text" class="form-control input-share-video" value={this.publicUrl(public_video_token)} />
              <Button color="default" className="ml-1 bg-color-alto btn-copy-share-url-akaire-page" onClick={this.onClickCopy}>
                <img src={iconOpenURL} className='mg-left-and-bottom' alt="icon" width="20" />
              </Button>
            </div>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-sm-center justify-content-between">
              <div className='mt-2 show-text-of-modal-share-video'>
                <div className="d-flex justify-content-between h-21px">
                  <span className='text-black font-weight-bold mr-auto'>パスワード</span>
                  <div className='d-flex justify-content-end w-40px'>
                    { isShowInputPassVideo? (
                      <span className='text-black font-weight-bold mr-2 p-t-2px'>ON</span>) :
                      (<span className='text-black font-weight-bold mr-2 p-t-2px'>OFF</span>)
                    }
                    <div class="custom-control custom-switch custom-switch-radio-position switch-btn mt-1 w-30px">
                      <input type="checkbox" onClick={(event) => this.onClickSetPassword(event)} class="custom-control-input js-switch" is_search="false" id="switch1" checked={isShowInputPassVideo} />
                      <label class="custom-control-label switch-ticket" htmlFor="switch1"></label>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <span>パスワードを設定するとパスワードを知っている</span><br />
                </div>
                <div className='row justify-content-between show-all-input-pass-share'>
                  <span className='col-12 pr-0 d-flex'>メンバーのみにアクセス制限できます。</span>
                  {
                    isShowInputDemo && <div className={'col-5 d-flex pl-0 div-show-input-pass-share'}>
                    <input type="password" readOnly class="form-control input-share-video" value="****************"
                      onClick={event => this.changeFocusInput(event)} />
                    </div>
                  }
                  {isShowInputPassVideo && !isShowInputDemo && <div className={'col-5 pl-0 div-show-input-pass-share'}>
                    <div className='d-flex'>
                      <input type="text" class="form-control input-share-video" value={passwordShareVideo}
                            onInput={(event) => this.checkPassWord(event)} autoComplete="off" placeholder='パスワードを設定する'/>
                      <button className="btn btn-primary btn-save-password-share" disabled={isDisabledButtonSavePass}
                              onClick={(event) => this.savePassWord(event)}>保存</button>
                    </div>
                    <div className='font-size-10px'>※英字・数字（大・小文字）のいづれか6文字以上</div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className='text-center mt-3'>
            <Button color="primary" className="w-120px" onClick={onClickClose}>閉じる</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
