export default class CompanyIpLimit {
  checkAllowIpList(element, type) {
    this._handleCheckbox(element, type, true);
  }

  openModalHandleIp(type) {
    let url;
    let modalTitle;
    if (type == "allow") {
      url = Routes.allowed_ip_addresses_owner_company_ip_limits_path();
      modalTitle = "アクセスできるIP";
    } else {
      url = Routes.disallowed_ip_addresses_owner_company_ip_limits_path();
      modalTitle = "アクセスできないIP";
    }

    Shared.callAjax(url, {}, "html", "GET")
      .done(function (result) {
        $("#title-of-modal-handle-ip").text(modalTitle);
        $("#form-company-limit-ips table tr:not(.clone-row-add-ip)").remove();
        $("#form-company-limit-ips table tr:last").after(result);
        $("#modalHandleIP")
          .find("tr.clone-row-add-ip")
          .find("input#kind-of-limit-ip-sample")
          .val(type);
        $("#modalHandleIP").attr("data-confirmed-off", "");
        $("#modalHandleIP").attr("data-changed", "");
        $("#modalHandleIP").modal("show");
      })
      .fail(function () {});
  }

  cloneRowAddIP(element) {
    const tableIP = $("#modalHandleIP").find("table");
    const newTrNumber = $("#modalHandleIP").find("table").find("tr").length;
    const nodeSample = $("#modalHandleIP").find("tr.clone-row-add-ip");
    const cloneEle = nodeSample.clone();
    cloneEle.attr("class", "");
    cloneEle.find("input").each(function () {
      $(this).attr(
        "name",
        `company_ip_limit[${newTrNumber}][${$(this).attr("data-name")}]`
      );
    });
    cloneEle.find('input[name*="ip_address"]').val("");

    tableIP.append(cloneEle);
  }

  submitListIPAddress() {
    const form = $("#form-company-limit-ips");
    const formData = form.serialize();

    if (this._validateAllIPAddress()) {
      Shared.callAjax(Routes.owner_company_ip_limits_path(), formData).done(
        function (res) {
          Shared.reloadPage();
        }
      );
    }
  }

  checkIpAddress(element) {
    $("#modalHandleIP").attr("data-changed", true);
    const me = this;
    this.checkIsMyIpAddress(element).then(function (isMyIPAddress) {
      if (me._validateAllIPAddress() && !isMyIPAddress) {
        $("#form-company-limit-ips")
          .find("button.btn-submit-list-ip")
          .removeClass("disabled");
      } else {
        $("#form-company-limit-ips")
          .find("button.btn-submit-list-ip")
          .addClass("disabled");
      }
    });
  }

  async checkIsMyIpAddress(element) {
    if ($(element).parent().find('input[data-name="kind"]').val() === 'allow') return false;

    await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        if ($(element).val() === data.ip) {
          $(element).val("");
          Popup.alert(
            `現在使用中のIPアドレスは制限できません。<br>※使用中のIPアドレスを制限設定する場合は、<br>他のIPに切替えてから設定してください。`
          );
          return true;
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    return false;
  }

  deleteIpAddress(element) {
    const ele = $(element);
    const trEle = ele.parents("tr");
    const addressID = trEle.find('input[name*="[id]"]').val();
    const inputDeleteIds = $("#form-company-limit-ips").find(
      "input#delete_ip_address_ids"
    );
    $("#modalHandleIP").attr("data-changed", true);
    if (!!addressID) {
      let ids = JSON.parse(inputDeleteIds.val());
      ids.push(addressID);
      inputDeleteIds.val(JSON.stringify(ids));
    }
    trEle.remove();
    $("#form-company-limit-ips")
      .find("button.btn-submit-list-ip")
      .removeClass("disabled");
  }

  _validateAllIPAddress() {
    const form = $("#form-company-limit-ips");
    const inputs = form.find('input[name*="ip_address"]');
    const me = this;

    let submitStatus = true;

    if (this._allIpAddressBlank(inputs) && $('input#delete_ip_address_ids').val() == '[]') {
      return false;
    }

    inputs.each(function () {
      if (!me._validateIpAddress($(this).val())) {
        submitStatus = false;
        $(this).addClass("input-invalid");
      } else {
        $(this).removeClass("input-invalid");
      }
    });

    return submitStatus;
  }

  _validateIpAddress(value) {
    const regexIpAddress = new RegExp(
      "^(([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)\\.){3}([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)$"
    );

    if (!!value) {
      return !!value.match(regexIpAddress);
    }

    return true;
  }

  _allIpAddressBlank(inputs) {
    let isBlank = true;
    for (let i of inputs) {
      if (!!$(i).val()) {
        isBlank = false;
        break;
      }
    }

    return isBlank;
  }

  _uncheckRemainingType(type) {
    const me = this;
    let labelElement;
    let message;
    let currentLabelElement;
    let messageAlert;

    if (type == "allow") {
      currentLabelElement = $("label#checkbox-setting-type-allow-limit-ip");
      labelElement = $("label#checkbox-setting-type-disallow-limit-ip");
      message = "「アクセスできるIP」をIPアクセス制限の対象にします。";
      messageAlert = "「アクセスできるIP」がIPアクセス制限の対象になりました。";
    } else {
      currentLabelElement = $("label#checkbox-setting-type-disallow-limit-ip");
      labelElement = $("label#checkbox-setting-type-allow-limit-ip");
      message = "「アクセスできないIP」をIPアクセス制限の対象にします。";
      messageAlert =
        "「アクセスできないIP」がIPアクセス制限の対象になりました。";
    }

    const inputElement = labelElement
      .parents("td.list-check")
      .find("input.checkbox-limit-ip-company");
    if (!!inputElement.attr("checked")) {
      labelElement.trigger("click");
    }

    Popup.confirm(`${message}<br> よろしいですか？`, {
      success: () => {
        Shared.callAjax(
          Routes.update_type_limit_ip_owner_company_ip_limits_path(),
          { kind: type },
          "json",
          "GET"
        ).done(function () {
          $("#text-note-set-limit-ip").addClass("d-none");
          Popup.alert(messageAlert);
        });
      },
      cancel: () => {
        me._handleCheckbox(currentLabelElement, type, false);
      },
      confirmText: "OK",
      width: "auto",
    });
  }

  _uncheckType(type) {
    const me = this;
    let currentLabelElement;
    let message;
    let messageAlert;

    if (type == "allow") {
      currentLabelElement = $("label#checkbox-setting-type-allow-limit-ip");
      message = "「アクセスできるIP」をIPアクセス制限から除外します。";
      messageAlert = "「アクセスできるIP」がIPアクセス制限から除外されました。";
    } else {
      currentLabelElement = $("label#checkbox-setting-type-disallow-limit-ip");
      message = "「アクセスできないIP」をIPアクセス制限から除外します。";
      messageAlert =
        "「アクセスできないIP」がIPアクセス制限から除外されました。";
    }

    Popup.confirm(`${message}<br> よろしいですか？`, {
      success: () => {
        Shared.callAjax(
          Routes.update_type_limit_ip_owner_company_ip_limits_path(),
          { kind: "none" },
          "json",
          "GET"
        ).done(function () {
          $("#text-note-set-limit-ip").removeClass("d-none");
          Popup.alert(messageAlert);
        });
      },
      cancel: () => {
        me._handleCheckbox(currentLabelElement, type, false);
      },
      confirmText: "OK",
      width: "auto",
    });
  }

  _handleCheckbox(element, type, need_confirm = false) {
    const labelCheckBox = $(element);
    const inputElement = labelCheckBox
      .parents("td.list-check")
      .find("input.checkbox-limit-ip-company");
    const oldValueOfLabelEle = inputElement.attr("checked");
    const trElement = labelCheckBox.parents("tr");

    if (oldValueOfLabelEle) {
      labelCheckBox.attr("data-content", "");
      trElement.removeClass("active");
      trElement.find(".icon-edit").removeClass("filter-invert");
      if (need_confirm) this._uncheckType(type);
    } else {
      labelCheckBox.attr("data-content", "✔");
      trElement.addClass("active");
      trElement.find(".icon-edit").addClass("filter-invert");
      if (need_confirm) this._uncheckRemainingType(type);
    }
    inputElement.attr("checked", !oldValueOfLabelEle);
  }
}
