import '../bootstrap-datetimepicker'
import '../../../assets/stylesheets/bootstrap-datetimepicker.min.scss'
import '../../../assets/stylesheets/bootstrap-datetimepicker-standalone.scss'
import toastr from 'toastr';
window.toastr = toastr;
const DEFAULT_QUOTATION_SUB_TYPE = 'other'
const ERROR_PAYMENT_STAGE = '金額合計を超えました。'
var percents = [];
var amounts = [];

$(document).ready(function(){
  handleContractType()
  modalSubmitCheckByDay()
  changePaymentStagePeriods()
  cloneElement()
  removeElementClone()
  quotationPaymentStageType()
  handleQuotationItem()
  selectOwner()
  if (!!$('#select_owner_company').val()) $('#select_owner_company').trigger('change')
  datepicker()
  caculatePrice()
  editQuotation()
  selectPlan()
  resetModalClient()
})

function resetModalClient() {
  $("#new_client").on('hide.bs.modal', function(){
    Client.removeDataModal('#new_client')
  })
}

function handleContractType(){
  $('#quotation_contract_type').change(function(){
    switch($(this).val()) {
      case '単発':
        hiddenRenewContract()
        break;
      default:
        showRenewContract()
    }
  })
}

function showRenewContract() {
  $('.remove-border-top').css("border-top","unset");
  $('#quotation_contract_duration').parent().removeClass('d-none').addClass('d-flex')
  $('#quotation_contract_duration').attr('disabled', false)
  if ($('body').width() >= 1280)
    $('.js-border').addClass('border-top-unset')
  $('.js-renew_contract').closest('.item-box').addClass('d-flex')
  $('.js-renew_contract').attr('disabled', false)
  $("#quotation_contract_sub_type option[value='by_number']").attr('disabled', true).siblings();
  $("#quotation_contract_sub_type option[value='by_time']").attr('disabled', true).siblings();
  $("#quotation_payment_stage_type").val('契約通り').trigger('change')
  $("#quotation_payment_stage_type option[value='分割あり']").attr('disabled', true).siblings();
  switch($('#quotation_contract_sub_type').val()) {
    case 'other':
    case 'by_hours':
    case 'fixed_quantity':
      break;
    default:
      $('#quotation_contract_sub_type').val(DEFAULT_QUOTATION_SUB_TYPE)
      $('#quotation_contract_sub_type').trigger('change')
  }
}

function hiddenRenewContract() {
  $('.remove-border-top').css("border-top","1px solid #393e41");
  if ($('body').width() >= 1280)
    $('.js-border').removeClass('border-top-unset')
  $('.js-renew_contract').closest('.item-box').removeClass('d-flex')
  $('.js-renew_contract').attr('disabled', true)
  $("#quotation_contract_sub_type option[value='by_number']").attr('disabled', false).siblings();
  $("#quotation_contract_sub_type option[value='by_time']").attr('disabled', false).siblings();
  $("#quotation_payment_stage_type option[value='分割あり']").attr('disabled', false).siblings();
}

function modalSubmitCheckByDay() {
  $('.js-cancel_change_contract_sub_type').click(function () {
    let defaultData = $('#quotation_contract_sub_type').attr('default_data');
    $('#quotation_contract_sub_type').val(defaultData)
    $('#quotation_contract_sub_type').trigger('change')
  })

  $('#quotation_contract_sub_type').change(function(){
    let company_name_owner = $('#select_owner_company option:selected').text();
    let ths = $(this)
    let type = ths.val()
    let defaultType = ths.attr('default_data');
    $('body').find('span#text-company-name').text(company_name_owner);
    // confirmResetQuotationItems($(this).val())

    let tr = $('.quotation_item_table').find('tr')
    tr.each(function(i,e){
      if($(e).find('.click_plan').find('input[type="hidden').val() == 'false'){
        var name = $(e).find('.name').val()
        let amount = $(e).find('.amount_to_number_currency').val()
        if(name || amount != 0){
          Popup.confirm('', {
            description: '商品情報がリセットされます。<br>よろしいですか？',
            confirmText: 'OK',
            width: '20em',
            cancelBtn: 'false',
            success: () => {
              resetQuotationItems(type)
              changeContractSubType(type)
            },
            cancel: () => {
              ths.val(defaultType)
              return false
            }
          })
        }else{
          changeQuotationItems(ths, type)
          changeContractSubType(type)
        }
      }
    })

    ths.attr('default_data', type);

    // changeSelectPlan($(this).val())
  })
}

function changeContractSubType(type){
  switch(type) {
    case 'other':
      $('#quotation_contract_duration').parent().removeClass('d-none').addClass('d-flex')
      $('#quotation_contract_duration').attr('disabled', false)
      $('#quotation_payment_stage_type').removeClass('disabled')
      $('a.add_fields').removeClass('d-none');
      $('a.add_fields').parent().css('justify-content', 'space-between')
      break;
    case 'by_hours':
      $('#quotation_contract_duration').parent().removeClass('d-none').addClass('d-flex')
      $('#quotation_contract_duration').attr('disabled', false)
      $('#quotation_payment_stage_type').removeClass('disabled')
      $('a.add_fields').removeClass('d-none');
      $('a.add_fields').parent().css('justify-content', 'space-between')
      break;
    case 'fixed_quantity':
      $('#quotation_contract_duration').parent().removeClass('d-none').addClass('d-flex')
      $('#quotation_contract_duration').attr('disabled', false)
      $('#quotation_payment_stage_type').removeClass('disabled')
      $('a.add_fields').addClass('d-none')
      $('a.add_fields').parent().css('justify-content', 'flex-end')
      break;
    default:
      $('#quotation_contract_duration').parent().removeClass('d-flex').addClass('d-none')
      $('#quotation_contract_duration').attr('disabled', true)
      $('#quotation_payment_stage_type').val('契約通り');
      $('#quotation_payment_stage_type').addClass('disabled')
      $('.js-clone_quotation_payment_stage').addClass('d-none')
      $('a.add_fields').addClass('d-none')
      $('a.add_fields').parent().css('justify-content', 'flex-end')
  }
}

function changeQuotationItems(ths, type){
  switch(type) {
    case 'other':
      $('input.quantity').val(0).removeClass('disabled')
      $('input.quantity').parents('td').find('span').text(0).removeClass('disabled')
      $('.quantity_to_number_currency').val(0).removeClass('disabled').removeAttr('disabled')
      $('input.unit').val('').removeClass('disabled')
      break;
    case 'by_hours':
      let unitTemp = ths.val() == 'fixed_quantity' ? I18n.t('shared.quantity') : I18n.t('shared.time')
      $('input.quantity').val(0).removeClass('disabled')
      $('input.quantity').parents('td').find('span').text(0).removeClass('disabled')
      $('.quantity_to_number_currency').val(0).removeClass('disabled').removeAttr('disabled')
      $('input.unit').val(unitTemp).addClass('disabled')
      break;
    case 'fixed_quantity':
      let unit = ths.val() == 'fixed_quantity' ? I18n.t('shared.quantity') : I18n.t('shared.time')
      $('input.quantity').val(0).removeClass('disabled')
      $('input.quantity').parents('td').find('span').text(0).removeClass('disabled')
      $('.quantity_to_number_currency').val(0).removeClass('disabled').removeAttr('disabled')
      $('input.unit').val(unit).addClass('disabled')
      break;
    default:
      $('input.quantity').val(1).addClass('disabled')
      $('input.quantity').parents('td').find('span').text('1').addClass('disabled');
      $('.quantity_to_number_currency').val(1).addClass('disabled').attr('disabled', 'true')
      $('input.unit').val($('#quotation_contract_sub_type option:selected').text()).addClass('disabled')
    }

}

function resetQuotationItems(type){
  let tr = $('.quotation_item_table').find('tr')
  tr.each(function(i,e){
    let el = $(e)
    if(el.data('id') != 'new'){
      el.find('input').addClass('no_check_validation')
      el.find('.click_plan').find('input[type="hidden"]').val(1)
      el.addClass('d-none')
    }else{
      el.remove()
    }

  })

  let $clone = $('#quotationItemClone').find('.add_fields'),
  content               = $clone.data('association-insertion-template'),
  insertionMethod       = 'append',
  insertionNode         = $('tbody.quotation_item_table'),
  sumTable             = $('.sum_table'),
  regexp_braced         = new RegExp('\\[new_quotation_items\](.*?\\s)', 'g'),
  regexp_underscord     = new RegExp('_new_quotation_items_(\\w*)', 'g'),
  new_id                = new Date().getTime(),
  new_content           = content.replace(regexp_braced, '[' + new_id + ']$1');

  new_content = new_content.replace(regexp_underscord,  '_' + new_id + '_$1');

  insertionNode.append(new_content)

  let insertionNodeTr = insertionNode.find('tr[data-id="new"]')
  insertionNodeTr.find('#quotation_quotation_items_attributes_' + new_id + '_name').removeClass('disabled').removeClass('js-disabled')
  insertionNodeTr.find('.amount_to_number_currency').removeClass('disabled').removeClass('js-disabled')
  insertionNodeTr.find('.amount').removeClass('disabled').removeClass('js-disabled')
  insertionNodeTr.find('.consumption-tax').removeClass('disabled').removeClass('js-disabled').attr('disabled',false)
  insertionNodeTr.find('.tax-percent').find('.disabled').removeClass('disabled')
  let priceText = insertionNodeTr.find('.js-span-price').text()
  insertionNodeTr.find('.js-span-price').text( new Intl.NumberFormat('ja-JP', {}).format(priceText) )

  if(type == "by_number"){
    insertionNodeTr.find('.quantity').val(1)
    insertionNodeTr.find('.quantity_to_number_currency').val(1).addClass('disabled').attr('disabled', true)
    insertionNodeTr.find('.unit').val('個数計算').addClass('disabled')
  }else if(type == "by_time"){
    insertionNodeTr.find('.quantity').val(1)
    insertionNodeTr.find('.quantity_to_number_currency').val(1).addClass('disabled').attr('disabled', true)
    insertionNodeTr.find('.unit').val('時間計算').addClass('disabled')
  }else if(type == "other"){
    insertionNodeTr.find('.quantity').val(0)
    insertionNodeTr.find('.quantity_to_number_currency').val(0).removeClass('disabled').attr('disabled', false)
    insertionNodeTr.find('.unit').removeClass('disabled')
  }

  sumTable.find('#text_amount_before_tax').text('0円')
  sumTable.find('#amount_before_tax').val('')
  sumTable.find('#text_tax').text('0円')
  sumTable.find('#tax').val('')
  sumTable.find('#text_amount_after_tax').text('0円')
  sumTable.find('#amount_after_tax').val('')
}

function cloneElement() {
  $('body').on('click','#clone_quotation_payment_stage', function(){
    $('.js-clone_quotation_payment_stage').each(function(){
      $(this).find('.js-td_remove img').removeClass('disabled')
    })
    datepicker()
    let element = $('body').find('#quotation_payment_stage_0').clone()

    element.find("input[type='number']").each(function(){
      $(this).val('')
    })

    element.find("input[type='text']").each(function(){
      $(this).val('')
    })

    element.find('.td-checkbox').each(function(){
      $(this).children('input').prop('checked', false)
      $(this).children('i').addClass('d-none')
      $(this).next('td').children('input').attr('disabled', true)
      $(this).next('td').children().addClass('disabled')
    })

    element.find('.js-default-check').each(function(){
      $(this).children('input').prop('checked', true)
      $(this).children('i').removeClass('d-none')
      $(this).next('td').children('input').attr('disabled', false)
      $(this).next('td').children().removeClass('disabled')
    })

    element.find('#clone_quotation_payment_stage').parent().remove()
    $('#quotation_payment_stages').append(element)
    hanldeDataInElementClone()
  })
}

function removeElementClone(){
  $('body').on('click', '.js-td_remove img', function(){
    disabledBtnRemoveCloneQuotationPaymentStage()
    if ($('.js-clone_quotation_payment_stage').length == 1) return;

    $(this).parents('.js-clone_quotation_payment_stage').remove()
    disabledBtnRemoveCloneQuotationPaymentStage()
    $('#quotation_payment_stage_0').find('#clone_quotation_payment_stage').parent().remove()
    hanldeDataInElementClone()
    let btnClone = '<div class="d-flex" style="align-items: center"><i class="fas fa-plus-square btn-clone" id="clone_quotation_payment_stage"></i></div>'
    $('#quotation_payment_stage_0').children('div:first-child').append(btnClone)
  })
}

function disabledBtnRemoveCloneQuotationPaymentStage(){
  if ($('.js-clone_quotation_payment_stage').length == 1) {
    $('.js-clone_quotation_payment_stage').each(function(){
      $(this).find('.js-td_remove img').addClass('disabled')
    })
  }
}

function changePaymentStagePeriods() {
  disabledPaymentStagePeriodIsNotChoose()
  $('body').on('click', '.td-checkbox', function (){
    $(this).children("input[type='radio']").prop("checked", true);
    disabledPaymentStagePeriodIsNotChoose($(this))
  })

  $('body').on('focusout', 'input.money', function() {
    $(this).removeClass('is-invalid');
    var total_amount = $('#amount_after_tax').val()
    var amount = $(this).val()
    var _amount_percent = amountToPercent(amount, total_amount);
    let index = $(this).parents('.js-clone_quotation_payment_stage').attr('index')
    percents[index] = _amount_percent;
    $(this).parents('tbody').find('input.percent').val(_amount_percent);
    let moneys = $(this).parents('tbody').parents('table').find('input.money');
    var _totalPercentage = totalPercentage();
    if (_totalPercentage > 100) {
      $(this).val('');
      $(this).parents('tbody').find('input.percent').val('');
      percents.splice(index, 1)
      toastr.error(ERROR_PAYMENT_STAGE, '', { closeButton: true, progressBar: true });
      return;
    }
    let total_temp = 0;
    $.each(moneys, function() {
      total_temp += parseFloat($(this).val());
    })
    let total_temp2 = $('#amount_after_tax').val();
    if (total_temp > total_temp2) {
      $(this).val('');
      toastr.error(ERROR_PAYMENT_STAGE, '', { closeButton: true, progressBar: true });
      return;
    }
});

  $('body').on('focusout', 'input.percent', function(e) {
    var total_amount = $('#amount_after_tax').val();
    var index = $(this).parents('.js-clone_quotation_payment_stage').attr('index')
    var percent = $(this).val();
    if (percent == '') {
      $(this).parents('tbody').find('input.money').val('')
      return
    }
    percents[index] = percent;
    var _totalPercentage = totalPercentage();
    if (_totalPercentage > 100) {
      $(this).val('');
      $(this).parents('tbody').find('input.money').val('');
      percents.splice(index, 1)
      toastr.error(ERROR_PAYMENT_STAGE, '', { closeButton: true, progressBar: true });
      return;
    }
    var percent_amount = percentage(percent, total_amount);
    if (amounts[index] == undefined) {
      amounts[index] = percent_amount;
    }
    amounts[index] = percent_amount;
    $(this).parents('tbody').find('input.money').val(percent_amount);
  })
}

function disabledPaymentStagePeriodIsNotChoose(element = null){
  if (element) {
    let table = element.parents('table')
    table.find('.td-checkbox').each(function(){
      if ($(this).children("input[type='radio']").is(":checked")){
        $(this).children("i").removeClass('d-none')
        if (!$(this).hasClass('disabled')) {
          $(this).next('td').children('input').attr('disabled', false).attr('required', true)
          $(this).next('td').children().removeClass('disabled')
        }
      } else {
        $(this).children("i").addClass('d-none')
        $(this).next('td').children('input').val('')
        $(this).next('td').children('input').attr('disabled', true).attr('required', false)
        $(this).next('td').children().addClass('disabled')
      }
    })
  } else {
    $('td.td-checkbox').each(function(){
      if ($(this).children("input[type='radio']").attr('checked') == 'checked'){
        $(this).children("i").removeClass('d-none')
        if (!$(this).hasClass('disabled')) {
          $(this).next('td').children('input').attr('disabled', false).attr('required', true)
          $(this).next('td').children().removeClass('disabled')
        }
      } else {
        $(this).children("i").addClass('d-none')
        $(this).next('td').children('input').attr('disabled', true).attr('required', false)
        $(this).next('td').children().addClass('disabled')
      }
    })
  }
}

function hanldeDataInElementClone(){
  let index = 0;
  $('.js-clone_quotation_payment_stage').each(function(){
    $(this).attr('id', 'quotation_payment_stage_' + index)
    $(this).attr('index', index)
    let name = '分割' + (index + 1) + '回目'
    $(this).find('.title-qps').html(name)
    $(this).find("input[prefix-name='quotation[payment_stage][periods][name]']").val(name)
    handleNameInput($(this), 'name', index)
    handleNameInput($(this), 'money', index)
    handleNameInput($(this), 'percent', index)
    handleNameInput($(this), 'fix_price', index)
    handleNameInput($(this), 'date', index)
    handleNameInput($(this), 'date_of_payment_after_apply', index)
    handleNameInput($(this), 'date_of_payment_after_deliver', index)
    handleNameInput($(this), 'date_of_payment_fix', index)
    index += 1
  })
}

function handleNameInput(element, key_name, index){
  element.find("input[prefix-name='quotation[payment_stage][periods][" + key_name + "]']").attr('name', 'quotation[payment_stage][periods][' + index +'][' + key_name + ']')
}

function datepicker(){
  $(function () {
    let date = new Date().setHours(0,0,0,0);
    $('.datepicker').datetimepicker({
      format: 'YYYY/MM/DD',
      locale: 'ja',
      useCurrent: false,
      minDate: date
    }).on('dp.change', function (ev) {
      $(this).trigger('change')
    });
  });
};

function quotationPaymentStageType() {
  $('body').on('change', '#quotation_payment_stage_type', function () {
    if ($(this).val() == '分割あり') {
      $('.js-clone_quotation_payment_stage').removeClass('d-none')
      disabledBtnRemoveCloneQuotationPaymentStage()
      disabledPaymentStagePeriodIsNotChoose()
      $('.js-clone_quotation_payment_stage').find('input:not(:disabled)').attr('required', true)
    } else {
      $('.js-clone_quotation_payment_stage').addClass('d-none')
      $('.js-clone_quotation_payment_stage').find('input:not(:disabled)').attr('required', false)
    }
  })
}

function handleQuotationItem() {
  // change tax quotation item
  $(document).on('change', 'select.tax-select', function(e) {
    if ($(this).val() == '10') {
      $(this).parents('td').find('.input-group > .tax-input').addClass('d-none').removeAttr('required').val(10).trigger('focusout');
      $(this).parents('td').find('span').removeClass('d-none').addClass('disabled')
      $(this).parents('td').find('span').addClass('disabled').html('10%')
    } else if (!$(this).hasClass('js-disabled')) {
      $(this).parents('td').find('span').removeClass('disabled').html('10%')
      $(this).parents('td').find('.input-group > .tax-input').attr('required', true).val(10).trigger('focusout').focus();
    }
  });

  $(document).on('change', 'select.consumption-tax', function() {
    status = $(this).val()
    switch (status) {
      case 'internal_tax':
      case 'external_tax':
        let tax_select = $(this).parents('tr').find('.input-group .tax-select')
        if (tax_select.val() == 'other') {
          tax_select.attr('required', true);
          tax_select.parents('td').find('span').removeClass('disabled')
        } else {
          tax_select.val(10)
          tax_select.parents('td').find('span').addClass('disabled').text('10%')
        }
        $(this).parents('tr').find('.input-group .tax-select').removeAttr('disabled', true);
        break;
      default:
        $(this).parents('tr').find('.input-group > .tax-input').removeAttr('required').val(0);
        $(this).parents('tr').find('.input-group > .tax-input').parents('td').find('span').addClass('disabled').text('0%')
        $(this).parents('tr').find('.input-group .tax-select').attr('disabled', true).val('other');
    }
    // if ($(this).val() == 'tax_exemption') {
    //     $(this).parents('tr').find('.input-group > .tax-input').addClass('disabled').removeAttr('required').val(0);
    //     $(this).parents('tr').find('.input-group .tax-select').attr('disabled', true).val('other');
    // } else {
    //     if ($(this).parents('tr').find('.input-group .tax-select').val() == 'other') {
    //       $(this).parents('tr').find('.input-group > .tax-input').removeClass('disabled').attr('required', true);
    //     }
    //     $(this).parents('tr').find('.input-group .tax-select').removeAttr('disabled', true);
    // }
    let total = handlePrice($(this))
    let text_total = new Intl.NumberFormat('ja-JP', {}).format(total);
    // let text_total = total - parseInt(total) > 0 ? total.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : total.toLocaleString()
    $(this).parents('tr').find('.js-span-price').text(text_total)
    $(this).parents('tr').find('.price').val(total).trigger('change');
  });

  $('.consumption-tax').each(function(index, value) {
    $(this).trigger('change');
  });

  $(document).on('focusout', 'input.tax-input', function() {
    if ($(this).val() == '') {
      $(this).val(0);
    } else {
        if ($(this).val() < 0) {
          $(this).val(0);
        }
        let price = $(this).parents('tr').find('input.price')
        let total = handlePrice($(this))
        let tax = parseFloat($(this).val())
        $(this).parents('td').find('span').removeClass('d-none').text(tax.toLocaleString() + '%')
        $(this).addClass('d-none')
        price.val(total).trigger('change');
    }
    $(this).parents('tr').find('.price').trigger('change');
  });

  $.each($('input.tax-input'), function(index, element) {
    if ($(element).val() == 10) {
      $(element).parents('tr').find('.tax-select').val("fix");
    } else {
      $(element).parents('tr').find('.tax-select').val("other");
    }
  })

  //js for calc total amount and calc total amount with tax
  $(document).on('change', 'input.quantity, input.amount', function(e) {
    let total = handlePrice($(this))
    let price = $(this).parents('tr').find('input.price')
    price.val(total).trigger('change');

  });

  $(document).on('change', 'input.amount_to_number_currency', function() {
    if($(this).val().trim() == '' || $(this).val() == undefined || isNaN($(this).val())) {
      $(this).val(0)
      toastr.error('半角数字を入力してください。', '', { closeButton: true, progressBar: true });
    } else {
      let amount = $(this).val() == '' || $(this).val() == undefined ? 0 : parseFloat($(this).val().replaceAll(',', ''))
      $(this).parent('td').find('input:eq(1)').val(amount).trigger('change');
      $(this).val(amount.toLocaleString())
    }
  })

  $(document).on('change', 'input.quantity_to_number_currency', function() {
    if($(this).val().trim() == '' || $(this).val() == undefined || isNaN($(this).val())) {
      $(this).val(0)
      toastr.error('半角数字を入力してください。', '', { closeButton: true, progressBar: true });
    } else {
      let quantity = $(this).val() == '' || $(this).val() == undefined ? 0 : parseInt($(this).val().replaceAll(',', ''))
      $(this).parent('td').find('input:eq(1)').val(quantity).trigger('change');
      $(this).val(quantity.toLocaleString())
    }
  })

  $(document).on('change', 'input.price', function() {
    let price = parseFloat($(this).val())
    $(this).prev('span.js-span-price').text(price.toLocaleString())
    caculatePrice()
  });

  // focus input quotation item

  $('body').on('click', '.js-focus-input', function(e){
    if ($(e.target).prop("tagName") == 'SELECT')
      return
    if ($(this).find('span').hasClass('disabled'))
      return
    $(this).css('padding-right', 'unset')
    $(this).find('span').addClass('d-none')
    $(this).find('input').removeClass('d-none').focus()
  })


  //js for calc percent and money in quotation payment plan
  $('#amount_after_tax').on('change', function() {
    $('input.money').each(function(){
      if ($(this).parents('tr').find('input[type="radio"]').attr('checked')) {
        $(this).parents('table').find('input.money').trigger('focusout');
      }
    })

    $('input.percent').each(function(){
      if ($(this).parents('tr').find('input[type="radio"]').attr('checked')) {
        $(this).parents('table').find('input.percent').trigger('focusout');
      }
    })
  });
}

function selectOwner() {
  if ($('#select_owner_company').hasClass('disabled'))
    $('.btn-modal-q').addClass('disabled')
  let defaultProject = getDefaultProject();
  if ($('#quotation-form').attr('action_quotation') != 'show') {
    if ($('#select_owner_company').val() == '' || $('#select_owner_company').val() == undefined){
      Quotation.disabledNotChooseCompany()
    }else {
      Quotation.enabledChooseCompany()
    }
  }
  // defineOwner($('#select_owner_company').val());
  $(document).on('change', '#select_owner_company', function() {
    Quotation.fetchDataOnBasicInfo('#select_project', this, 'owner')
  });

  $('body').on('click', '#select-client', function(){
    let company = $('#input_select_company').val()
    let user = $('#input_select_user').val()
    if (company == '' || company == undefined || user == '' || user == undefined) return;

    let selected = $('#select_owner_company option:contains(' + company + ' - ' + $('#input_select_company').attr('quotation_for') + ')').val()
    $('#select_owner_company').val(selected).trigger('change')
  })

  if ($('#select_project').val() != '' && $('#select_owner_company').val() == '') {
    $('#select_project').trigger('change');
  }

  if ($('#select_project').val() == '' && $('#select_owner_company').val() != '') {
    $('#select_owner_company').trigger('change');
  }
}

// Select plan

function selectPlan() {
  $("#select-plan").on("click", function(e) {
    e.preventDefault();
    $("#popup-select-plan").addClass("show");
    $("#popup-select-plan").show();

  });
  $("#submit-plan").on("click", function() {
    var project_id = $('#select-plan').attr('project_id');
    var plan_id = $("select#plan_id :selected").val();

    if (plan_id == 0) {
      // TODO: FIXME change texts
      toastr.error('少なくとも1つのプランを選択してください', '', {
        closeButton: true,
        progressBar: true,
      });
      return;
    } else {
      var payload = {
        plan_id: plan_id,
        project_id: project_id
      };
      // hide_modal()
      $.ajax({
        url: "/quotations/select_plan_items",
        method: "POST",
        dataType: "script",
        data: payload,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
      })
    }
  });

  $("#close-select-plan").on("click", function() {
    hide_modal()
  });
}

function hide_modal() {
  $(".modal").attr("aria-hidden", "true").css("display", "none");
  $("#popup-select-plan").hide();
}


// handle Data

function caculateTax(status, price, tax){
  let amount_before_tax = parseFloat(price)
  let amount_after_tax = parseFloat(price)
  let taxF = parseFloat(tax)
  let amount_tax = 0
  switch (status) {
    case 'internal_tax':
      amount_tax = amount_after_tax * (1 - 1/(1 + taxF/100))
      amount_before_tax = amount_after_tax - amount_tax
      break;
    case 'external_tax':
      amount_tax = amount_before_tax * taxF/100
      amount_after_tax = amount_before_tax + amount_tax
      break;
    default:
      amount_tax = 0
  }
  return [amount_before_tax, amount_tax, amount_after_tax]
}

function handlePrice(element){
  let amount = element.parents('tr').find('input.amount').val();
  let quantity = element.parents('tr').find('input.quantity').val();
  amount = amount == '' || amount == undefined ? 0 : amount
  quantity = quantity == '' || quantity == undefined ? 0 : quantity
  let price = parseFloat(amount) * parseInt(quantity);
  let tax = element.parents('tr').find('input.tax-input').val();
  let status = element.parents('tr').find('select.consumption-tax').val();
  let total =  caculateTax(status, price, tax)[2].toFixed();
  let taxMoney = caculateTax(status, price, tax)[1].toFixed()
  $(element).parents('tr').find('.tax-money').text(new Intl.NumberFormat('ja-JP', {}).format(taxMoney))
  return total - taxMoney
}

function totalAllTax() {
  let totalTax = 0
  $('tbody.quotation_item_table').find('tr:not([style*="display: none"])').find('.tax-money').toArray()
  .forEach( tax => { totalTax += changeCommaToInt($(tax).text()) })
  return totalTax.toLocaleString();
}

function changeCommaToInt(num){
  let number = num.replace(/,/g, '')
  return parseInt(number, 10)
}

function editQuotation(){
  // setting data payment stage

  $('.js-period-date').each(function(){
    let checked = $(this).attr('is_checked');
    let input = $(this).find('input[prefix-name="' + checked + '"]')
    input.parents('tr').find('.td-checkbox i').removeClass('d-none')
    input.parents('tr').find('.td-checkbox input').attr('checked', true)
  })

  $('.js-period-money').each(function(){
    let checked = $(this).attr('is_checked');
    let input = $(this).find('input[prefix-name="' + checked + '"]')
    input.parents('tr').find('.td-checkbox i').removeClass('d-none')
    input.parents('tr').find('.td-checkbox input').attr('checked', true)
  })

  if ($('#quotation_contract_type').val() === '定期') {
    Disabled.toggle($("#quotation_contract_sub_type option[value='by_number']"), true);
    Disabled.toggle($("#quotation_contract_sub_type option[value='by_time']"), true);
    Disabled.toggle($("#quotation_payment_stage_type option[value='分割あり']"), true);
  }
}

function getDefaultProject() {
  var arr = [];
  $.each($('#select_project').find('option'), function(index, e) {
    if ($(e).val() !== '') {
      arr.push({id: $(e).val(), name: $(e).text()});
    }
  });
  return arr;
}

function caculatePrice(){
  let total_before_tax = 0;
  let total_tax = 0;
  let total_after_tax = 0;
  let prices = $('input.price');
  $.each(prices, function(k, v) {
   if (!$(v).parents('tr').hasClass('js-plan') && $(v).parents('tr').attr('style') !== 'display: none;') {
    let tax_select = $(v).parents('tr').find('select.consumption-tax');
    let tax_input = $(v).parents('tr').find('input.tax-input');
    let amount = $(v).parents('tr').find('input.amount').val();
    let quantity = $(v).parents('tr').find('input.quantity').val();
    let price_before_tax = parseFloat(amount) * parseInt(quantity);
    let caculate_tax = caculateTax(tax_select.val(), price_before_tax, tax_input.val())
    total_before_tax += Number(caculate_tax[0].toFixed());
    total_tax += caculate_tax[1];
    total_after_tax += Number(caculate_tax[2].toFixed());
   }
  });

  $('#amount_before_tax').val(total_before_tax);
  $('#tax').val(total_tax);
  $('#amount_after_tax').val(total_after_tax).trigger('change');
  total_before_tax = total_before_tax - parseInt(total_before_tax) > 0 ? total_before_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_before_tax)
  total_tax = total_tax - parseInt(total_tax) > 0 ? total_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_tax)
  total_after_tax = total_after_tax - parseInt(total_after_tax) > 0 ? total_after_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_after_tax)
  $('#text_amount_before_tax').text(`${total_before_tax}円`);
  $('#text_tax').text(`${totalAllTax()}円`);
  $('#text_amount_after_tax').text(total_after_tax + '円')
}

function totalPercentage() {
  return percents.reduce(function(a, b) {
    return parseFloat(a) + parseFloat(b);
  });
}

function percentage(percent, total) {
  return ((percent / 100) * total).toFixed(2)
}

function amountToPercent(amount, total) {
  return ((amount / total) * 100).toFixed(2)
}

// function changeSelectPlan(contract_sub_type){
//   $.ajax({
//     method: 'GET',
//     url: "/quotations/change_list_plans",
//     dataType: 'json',
//     data: { contract_sub_type: contract_sub_type},
//     headers: {
//         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//     }
//   }).done(function(data) {
//     $('select#plan_id').empty().append("<option value></option>");
//     let plans = data.plans
//     if (data.plans.length > 0) {
//       for (let i = 0; i < data.plans.length; i++) {
//         $('select#plan_id').append('<option value="' + plans[i].id +  '">' + plans[i].name + '</option>');
//       }
//     }
//   })
// }
