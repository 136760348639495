import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import iconOpenUrl from '../ui/icons/iconOpenURL.svg';

export default class AnimationUrl extends Component {
  constructor() {
    super();
    this.state = {
    };
  }
  renderMobile() {
    const { currentAnimationUrl } = this.props;
    return (
      <Button color='default' className='ml-2'>
        <a href={currentAnimationUrl} target="_blank">
          <FontAwesome name="external-link-alt" className="text-primary"/>
        </a>
      </Button>
    )
  }
  renderNormal() {
    const { currentAnimationUrl } = this.props;
    return (
      <div className='d-flex justify-content-center align-items-center mb-3'>
        <div style={{color: 'white', whiteSpace: 'nowrap'}}>URL</div>
        <Input type="text" value={currentAnimationUrl} className='ml-2'/>
        <Button color='default' className='ml-2 bg-external-link-alt' style={{ padding: '0', paddingBottom: '10px' }}>
          <a href={currentAnimationUrl} target="_blank">
            <img alt="" src={iconOpenUrl} style={{ scale: '1.2' }} />
          </a>
        </Button>
      </div>
    )
  }
  render(){
    const { isMobile } = this.props;
    return (
      this.renderNormal()
    )
  }
}
