import React, { Component } from 'react';
import { Table, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import emailValidator from 'email-validator';
import classnames from 'classnames';
import toastr from 'toastr';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import Autosuggest from 'react-autosuggest';
import '../css/auto-suggest.css';
// import ShareSocialModal from './ShareSocialModal'
import iconOpenURL from '../ui/icons/iconOpenUrlNew.svg';


import { canCreateAssignment, canUpdateAssignment, canDeleteAssignment, canGenerateProjectPublicToken } from '../authorizations';

const inviter_name = 0
const inviter_email = 1

export default class ProjectShareModal extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      suggestions: [],
      hasValidEmail: false,
      invitersFilter: [],
      showNoticeUpgrade: false
    }
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  renderPromotionTooltip() {
    return (
      <ReactTooltip id="share-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          こちらは会員専用機能となります。
        </div>
        <div className="mt-2 mb-2">
          無料会員でも全機能が利用可！<br />
          保存期間も『無期限』
          <span className="small">※利用状況により3ヶ月間</span>
        </div>
        <a href="/signup" style={{ pointerEvents: 'initial' }} className="btn btn-warning" target="_blank">
          会員登録はこちら
        </a>
      </ReactTooltip>
    );
  }
  componentDidMount() {
    const { rootUrl, currentUserId } = this.props;
    fetch(`${rootUrl}/users/${currentUserId}/get_inviters`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState(
            {invitersFilter: result}
          )
        })
  }
  getSuggestions = value => {
    const inputValue = value.trim().toString().toLowerCase();
    const inputLength = inputValue.length;
    const {invitersFilter} = this.state

    return inputLength === 0 ? [] : invitersFilter.filter(inviter =>
      inviter[inviter_name].toLowerCase().indexOf(inputValue) > -1 || inviter[inviter_email].toLowerCase().indexOf(inputValue) > -1
    );
  };

  getSuggestionValue = suggestion => suggestion[inviter_email];
  renderSuggestion = suggestion => (
    <div>
      <b>{suggestion[inviter_name]}</b>
      <p>{suggestion[inviter_email]}</p>
    </div>
  );
  onClickCopy = () => {
    copy(this.publicUrl());
    toastr.success('クリップボードにコピーしました。');
  }
  publicUrl() {
    const { rootUrl, publicToken, token, currentPageId } = this.props;
    return `${rootUrl}p/projects/${publicToken}?page_id=${currentPageId}&animation_id=${token}`;
  }

  onClickCopyShareUrl = () => {
    copy(this.buildShareUrl());
    Popup.alert('クリップボードにコピーしました。', '', '', {kind: 'akaire'})
  }

  buildShareUrl() {
    const { rootUrl, token, } = this.props;
    if (rootUrl === gon.shared.host_local) {
      return `http://${rootUrl}:3000/p/objects/${token}`
    }
    return `https://${rootUrl}/p/objects/${token}`;
  }
  clear() {
    this.setState({
      value: '',
      hasValidEmail: false,
    });
  }
  closeNotice = () => {
    this.setState({ showNoticeUpgrade: false });
  }
  onSubmitInvitationForm(event) {
    const { apiClient, projectId, onInvite, rootUrl, currentUserId } = this.props;
    const { value } = this.state;
    event.preventDefault();
    return apiClient.post(`/assignments`, {
      assignment: {
        email: value,
        role: 'commenter'
      }
    })
      .then((response) => {
        toastr.success('招待しました');
        if (response.data.messages != null && response.data.messages.length > 0) {
          this.setState({
            showNoticeUpgrade: true
          });
        }
        fetch(`${rootUrl}/users/${currentUserId}/get_inviters`)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState(
              {invitersFilter: result}
            )
          })
        this.clear();
        onInvite && onInvite();
      })
      .catch(({ response }) => {
        const errors = response.data && response.data.errors || [];
        toastr.error(`招待に失敗しました。${errors.join('\n')}`);
        this.clear();
      })
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      hasValidEmail: emailValidator.validate(newValue)
    });
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onClickRemoveButton(targetId) {
    return () => {
      if (!confirm('本当に共有を解除しますか？')) return;
      const { projectId, onRemove, apiClient } = this.props;
      return apiClient.delete(`/assignments/${targetId}`)
        .then(() => {
          toastr.success('共有を解除しました');
          onRemove && onRemove();
        })
        .catch(() => {
          toastr.error('共有の解除に失敗しました');
        })
    }
  }
  onChangeRole(targetId) {
    return ({ target: { value }}) => {
      if (!confirm('権限を変更しますか？')) return;
      const { projectId, onChangeRole, apiClient } = this.props;
      return apiClient.patch(`/assignments/${targetId}`, {
        assignment: {
          role: value
        }
      })
        .then(() => {
          toastr.success('権限を変更しました');
          onChangeRole && onChangeRole();
        })
        .catch(() => {
          toastr.error('権限の変更に失敗しました');
        })
    }
  }
  onChangeSuperInviter(targetId, is_checked) {
    return ({ target: { value }}) => {
      const { projectId, onChangeSuperInviter, apiClient } = this.props;
      return apiClient.patch(`/assignments/${targetId}`, {
        assignment: {
          super_inviter: !is_checked
        }
      })
        .then(() => {
          if (!is_checked) {
            toastr.success('非会員招待に変更しました');
          }else {
            toastr.success('会員招待に変更しました');
          }
          onChangeSuperInviter && onChangeSuperInviter();
        })
        .catch(({response}) => {
          const errors = response.data && response.data.errors || [];
          toastr.error(`招待に失敗しました。${errors.join('\n')}`);
        })
    }
  }
  onClickGenerateLink = () => {
    const { projectId, onGenerateLink, apiClient } = this.props;
    return apiClient.post(`/public_token`)
      .then(() => {
        toastr.success('リンクを作成しました');
        onGenerateLink && onGenerateLink();
      })
      .catch(({ response }) => {
        toastr.error('リンクの作成に失敗しました。');
      });
  }
  onClickDeletePublicUrl = () => {
    if(!window.confirm('本当に削除しますか？')) return;
    const { projectId, onDeleteLink, apiClient } = this.props;
    return apiClient.delete(`/public_token`)
      .then(() => {
        toastr.success('リンクを削除しました');
        onDeleteLink && onDeleteLink();
      })
      .catch(({ response }) => {
        toastr.error('リンクの削除に失敗しました。');
      });
  }

  onClickSetPasswordUrl = (event) => {
    const { is_create_pass_video, changeIsShowShareInputPassUrl, is_project_owner_member, isAkaponMember } = this.props;
    if (is_project_owner_member) {
      if (!is_create_pass_video) {
        let message = 'パスワード設定は<br>ProまたはBusinessプラン限定になります。'
        let confirmButtonColor = null;
        if (isAkaponMember) {
          message = 'パスワード設定は<br>StandardまたはPremiumプラン限定になります。'
          confirmButtonColor = '#d33'
        }
        Popup.confirm(message, {
          success: () => { window.open(Routes.owner_total_info_path()) },
          confirmText: 'プランを変更する',
          cancelBtn: 'false',
          width: 'auto',
          confirmButtonColor: confirmButtonColor,
          kind: 'akaire',
        })
        $(event.target).prop('checked', false);
      } else {
        changeIsShowShareInputPassUrl()
      }
    } else {
      Popup.alert('パスワード設定の権限がありません。', '', '', {kind: 'akaire'})
      $(event.target).prop('checked', false);
    }
  }

  checkPassWord = (event) => {
    const {checkPassWordUrl} = this.props;
    checkPassWordUrl(event)
  }

  savePassWord = (event) => {
    const { is_create_pass_video, setPasswordForShareUrl, passwordShareUrl } = this.props;
    if (!is_create_pass_video) {
      Popup.alert('パスワード設定の権限がありません。', '', '', {kind: 'akaire'})
      return
    }
    setPasswordForShareUrl(passwordShareUrl)
  }

  changeFocusInput = (event) => {
    const {changeFocusUrl, is_create_pass_video} = this.props
    if (!is_create_pass_video) {
      return
    }
    changeFocusUrl()
  }

  render() {
    const { role: currentUserRole, currentUserId, publicToken, isOpen, onClickClose, assignments, user, mobile: isMobile, owner,
    user: {
      current_subscription_type: currentSubscriptionType, limit_super_inviter: limitSI}, mobile, isTaskType,
      isShowInputPassUrl, passwordShareUrl, isDisabledButtonSavePassUrl, isShowInputDemoUrl
    } = this.props;
    const { value, suggestions, hasValidEmail, showNoticeUpgrade } = this.state;
    const countSuperInviters = assignments.filter((assignment) => assignment.super_inviter).length;
    const remainingAmount = limitSI == 1000 ? '無制限' : limitSI - countSuperInviters
    const inputProps = {
      type: 'email',
      name: 'email',
      value,
      placeholder: 'メールアドレス',
      onChange: this.onChange,
      disabled: !canCreateAssignment(currentUserRole, user),
      size: '30',
      className: 'form-control'
    };
    const projectShareForm = (
      <Form onSubmit={this.onSubmitInvitationForm.bind(this)} inline style={{alignItems: 'baseline', justifyContent: 'initial'}}data-tip data-for="invite-tooltip">
        {/* <Input type="email" name="email" value={value} placeholder= "メールアドレス" onChange={this.onChange.bind(this)} disabled={!canCreateAssignment(currentUserRole, user)} size="30" /> */}
        <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps} />
        <Button type="submit" color="primary" disabled={!hasValidEmail || !canCreateAssignment(currentUserRole, user)} className={classnames({ 'btn-block': isMobile, 'mt-3': isMobile, 'ml-3': !isMobile })}>招待する</Button>
      </Form>
    );
    const upgradeLink = (
      <div>
        <p>招待メンバー数が上限に達しています。<br/>他のメンバーの共有を解除するか、<br/>プランのアップグレードをお試しください。</p>
        <p className="mt-2"><a href="/plans">プランをアップグレードする</a></p>
      </div>
    );
    const unsubmittable = !currentUserId;
    const clear_mb = {
      marginBottom: '0'
    }
    const formHtml = canCreateAssignment(currentUserRole)
      ? ''
      : currentUserId
        ? <p>権限がありません</p>
        : (
            <div>
              <div className="text-danger font-weight-bold">
                こちらは会員専用機能となります。
              </div>
              <div className="mt-2 mb-2">
                会員登録すると、共有したいメンバーを <br/>
                プロジェクトに招待する事ができます！
              </div>
              <a href="/signup" style={{ pointerEvents: 'initial' }} className="btn btn-warning" target="_blank">
                会員登録はこちら
              </a>
            </div>
          );
    return (
      <Modal isOpen={isOpen} className="project-share-modal modal-dialog-centered modal-lg modal-style">
        <ModalBody className="p-4 text-center">
          <div className='text-center'>
            <h5>赤入れ共有URL</h5>
          </div>
          <div>
            {
              currentUserId && (
              <div className='text-center'>
                <span className='text-left show-text-share-akaire-page' style={{ fontSize: '13px', marginBottom: '10px' }}>
                  ※下記URLを付与すると、会員以外でも「このファイルの全てのページの最新バージョン」の
                  { !mobile && <br/> }
                  赤入れ閲覧及びレビューが可能になります。
                </span>
                <div className="text-center">
                  <div className="d-flex justify-content-center mx-auto show-input-share-akaire-detail w-50">
                    <input className='form-control input-share-video' type="text" value={this.buildShareUrl()} readOnly/>
                    <Button color="default" className="bg-color-alto ml-1 btn-copy-share-url-akaire-page" onClick={this.onClickCopyShareUrl}>
                      <img src={iconOpenURL} className='mg-left-and-bottom' alt="icon" width="20" />
                    </Button>
                  </div>
                </div>
                <div className="text-center">
                  <div className="d-flex justify-content-sm-center justify-content-between">
                    <div className='mt-2 show-text-of-modal-share-video'>
                      <div className="d-flex justify-content-between h-21px">
                        <span className='text-black font-weight-bold mr-auto'>パスワード</span>
                        <div className='d-flex justify-content-end w-40px'>
                          { isShowInputPassUrl? (
                          <span className='text-black font-weight-bold mr-2 p-t-2px'>ON</span>) :
                          (<span className='text-black font-weight-bold mr-2 p-t-2px'>OFF</span>)
                          }
                          <div class="custom-control custom-switch custom-switch-radio-position switch-btn mt-1 w-30px">
                            <input type="checkbox" onClick={(event) => this.onClickSetPasswordUrl(event)} class="custom-control-input js-switch" is_search="false" id="switch1" checked={isShowInputPassUrl} />
                            <label class="custom-control-label switch-ticket" htmlFor="switch1"></label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex">
                        <span>パスワードを設定するとパスワードを知っている</span><br />
                      </div>
                      <div className='row justify-content-between show-all-input-pass-share'>
                        <span className='col-12 pr-0 d-flex'>メンバーのみにアクセス制限できます。</span>
                        {
                          isShowInputDemoUrl && <div className={'col-5 d-flex pl-0 div-show-input-pass-share'}>
                          <input type="password" readOnly class="form-control input-share-video" value="****************"
                            onClick={event => this.changeFocusInput(event)} />
                          </div>
                        }
                        {isShowInputPassUrl && !isShowInputDemoUrl && <div className={'col-5 pl-0 div-show-input-pass-share'}>
                          <div className='d-flex'>
                            <input type="text" class="form-control input-share-video" value={passwordShareUrl}
                                  onInput={(event) => this.checkPassWord(event)} autoComplete="off" placeholder='パスワードを設定する'/>
                            <button className="btn btn-primary btn-save-password-share" disabled={isDisabledButtonSavePassUrl}
                                    onClick={(event) => this.savePassWord(event)}>保存</button>
                          </div>
                          <div className='font-size-10px'>※英字・数字（大・小文字）のいづれか6文字以上</div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center mt-3'>
                  <Button color="primary" onClick={onClickClose}>閉じる</Button>
                </div>
              </div>
              )
            }
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
