const MESSAGE = 'プロジェクトを選んでください。'

export default class QuotationTask {
  getTasks(quotation_id) {
    const checkExchangeURL = Routes.owner_quotation_check_exchange_order_path(quotation_id);
    const checkExchangeAjax = Shared.callAjax(checkExchangeURL, {}, 'json', 'GET');
    checkExchangeAjax.done(() => {
      let projectId = $('#select_project').val()
      if (!projectId) {
        toastr.error(MESSAGE)
        return
      }
      let data = {
        project_id: projectId
      }
      $.ajax({
        method: 'GET',
        url: Routes.list_tasks_owner_quotation_path(quotation_id),
        dataType: 'html',
        data: data
      }).done( (res) => {
        $('#modal_new_task').html(res).modal('show')
        let date = new Date().setHours(0,0,0,0);
        $('.datepicker').datetimepicker({
          format: 'YYYY/MM/DD',
          locale: 'ja',
          useCurrent: false,
          minDate: date
        })
      })
    })
    .fail(({ responseJSON }) => {
      if (responseJSON && responseJSON.payment_method_link) {
        Popup.alert(
          I18n.t('send_order.have_not_payment') +
          I18n.t('access_payment_page', { payment_path: responseJSON.payment_method_link }),
          'warning'
        );
      }
    })
  }

  add() {
    Visiable.hide('.tasks')
    $('#task_id').attr('disabled', true).attr('required', false)
    Visiable.show('.new-task')
    $('.js_is-required').attr('required', true)
  }

  showNameFiles(element) {
    let names = []
    let files = $(element).get(0).files
    Object.values(files).forEach(file => {
      names.push(file.name);
    })
    $('#task_file_label').text(setTaskFileLabel(names));
  }
}

function setTaskFileLabel(arr) {
  let value = '';
  if (arr.length > 1) {
      value = arr.length + ' files';
  } else if (arr.length === 1) {
      value = arr[arr.length - 1];
  }
  return value
}
