import toastr from "toastr";

export default class Notify {
  static success(message) {
    toastr.success(message, "", {
      closeButton: true,
      progressBar: true,
    });
  }

  static error(message) {
    toastr.error(message, "", {
      closeButton: true,
      progressBar: true,
    });
  }

}
