export default class Visiable {
  hide(element) {
    $(element).addClass('d-none')
  }

  show(element) {
    $(element).removeClass('d-none')
  }

  toggle(element, isToggle) {
    $(element).toggleClass('d-none', isToggle)
  }

  disabled(element, isDisabled) {
    $(element).attr('disabled', isDisabled)
  }

  disabled_change(element) {
    $(element).css('pointer-events', 'none')
    $(element).css('background-color', '#eeeeee')
  }

  enabled_change(element) {
    $(element).css('pointer-events', '')
    $(element).css('background-color', 'white')
  }

}
