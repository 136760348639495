const url = new URL(window.location.href)
const searchParams = url.searchParams
const objectId = searchParams.get("show_popup_chat");
const typeChat = searchParams.get("type_chat");
const show_msg_id = searchParams.get("show_msg_id");
let chatFromEmail = objectId && typeChat;

export function showPopupChatTicketWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'work') {
    if (show_msg_id) changeLink(`#js_chat_ticket_${objectId}`)
    _checkElementExists(document.getElementById(`js_chat_ticket_${objectId}`))
  }
}

export function showPopupChatAkaireFileWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'akaire_file') {
    if (show_msg_id) changeLink(`#js_chat_akaire_file_${objectId}`)
    _checkElementExists(document.getElementById(`js_chat_akaire_file_${objectId}`))
  }
}

export function showPopupChatTaskWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'task') {
    setTimeout(function() {
      if (show_msg_id) changeLink(`#js_icon_chat_task_${objectId}`)
      _checkElementExists(document.getElementById(`js_icon_chat_task_${objectId}`))
    }, 2500)
  }
}

export function showPopupProjectWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'project') {
    const groupType = searchParams.get("group_type");
    setTimeout(function() {
      _checkElementExists(
        document.getElementById(`js_show_popup_chat_project_${objectId}`)
      )
    }, 2000)
  }
}

export function showPopupCompanyWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'company') {
    if (show_msg_id) changeLink(`#js_show_popup_chat_company_${objectId}`, true)
    _checkElementExists(
      document.getElementById(`js_show_popup_chat_company_${objectId}`)
    )
  }
}

export function showPopupUserWhenClickFromEmail() {
  if (chatFromEmail && typeChat == 'user') {
    if (show_msg_id) changeLink(`#js_show_popup_chat_user_${objectId}`)
    _checkElementExists(
      document.getElementById(`js_show_popup_chat_user_${objectId}`)
    )
  }
}

function changeLink(idElement, isChatCompany = false) {
  const textParams = isChatCompany ? '&' : '?'
  const href = $(document).find(`${idElement}`).attr('href')
  $(document).find(`${idElement}`).attr('href', `${href}${textParams}show_msg_id=${show_msg_id}`)
}

function _checkElementExists(element) {
  if (element) {
    element.click();
    setTimeout(function() {
      let color_class = $('.form-sent').prop('class').match(/background-color-\S+/g);
      if (!!color_class) {
        color_class = color_class[0] == 'background-color-green' ? 'ticket-choosen' : color_class[0] + '__light'
        $(element).closest('tr').addClass(color_class);
      }
    }, 1000)
  } else {
    Popup.alert('アクセスできません');
  }
}
