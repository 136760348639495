import React from 'react';
import { Input } from 'reactstrap';

const AccountLoginSettings = ({
  accUsername,
  accPassword,
  showAccPassword,
  username,
  password,
  showBasicPassword,
  savePasswordSelected,
  savePasswordOptions,
  handleChange,
  toggleAccPassword,
  toggleBasicPassword,
  handleSelectSavePassword,
  showPopupCaptureUrl
}) => (
  <div className="d-flex align-items-center flex-wrap">
    <div className="w-65 w-sp-87 fs-16">
      <div className="my-2">
        <div className="vl w-100">
          <div className="">
            <div className="font-weight-bold text-black fs-text-popup-url text-left">
              ログイン情報
            </div>
            <div className="row">
              <div className="col-md-6 pr-0 col-sm-12">
                <div className="d-flex w-100 align-items-center">
                  <Input
                    type="text"
                    placeholder="ID"
                    autoComplete="new-password"
                    name="accUsername"
                    onChange={(event) => handleChange(event)}
                    value={accUsername}
                    className="h-37px bg-seashell"
                  />
                </div>
              </div>
              <div className={`col-md-6 pr-0 col-sm-12`}>
                <div className="position-relative w-100">
                  <Input
                    type={showAccPassword ? "text" : "password"}
                    placeholder=" パスワード"
                    autoComplete="new-password"
                    name="accPassword"
                    onChange={(event) => handleChange(event)}
                    value={accPassword}
                    className="h-37px bg-seashell"
                  />
                  <div className="btn-toggle-password" onClick={toggleAccPassword}>
                    <span className="btn-toggle-password-text">{showAccPassword ? "非表示" : "表示"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vl mt-2 w-100">
          <div className="">
            <div className="row">
              <div className="col-8 font-weight-bold text-black fs-text-popup-url text-left">
                ベーシック認証
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pr-0 col-sm-12">
                <div className="d-flex w-100 align-items-center">
                  <Input
                    type="text"
                    placeholder="ID"
                    autoComplete="new-password"
                    name="username"
                    onChange={(event) => handleChange(event)}
                    value={username}
                    className="h-37px bg-seashell"
                  />
                </div>
              </div>
              <div className={`col-md-6 pr-0 col-sm-12`}>
                <div className="position-relative w-100">
                  <Input
                    type={showBasicPassword ? "text" : "password"}
                    placeholder=" パスワード"
                    autoComplete="new-password"
                    name="password"
                    onChange={(event) => handleChange(event)}
                    value={password}
                    className="h-37px bg-seashell"
                  />
                  <div className="btn-toggle-password" onClick={toggleBasicPassword}>
                    <span className="btn-toggle-password-text">{showBasicPassword ? "非表示" : "表示"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="dropdown-save-account pt-3">
      <div className="ml-4 pl-3 d-flex align-items-center">
        <div className="dropdown">
          <button
            className="btn btn-dark-new font-weight-bold h-37px btn-choose-screen-size dropdown-toggle w-full"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <div className="d-flex justify-content-between align-items-center text-white h-0">
              <span className="">{savePasswordSelected.text}</span>
              <i className="fa fa-chevron-down text-white ml-1"></i>
            </div>
          </button>
          <ul className="dropdown-menu w-100 cursor-pointer bg-primary text-white">
            {savePasswordOptions.map((option) => (
              <li
                className="d-flex align-items-center p-1 pl-3 row"
                style={{ marginLeft: "0px", marginRight: "0px" }}
                onClick={() => handleSelectSavePassword(option.value)}
                key={option.value}
              >
                <div>
                  <span>{option.text}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <i className="fas fa-question-circle ml-3 scale-1_5 cursor-pointer text-black" onClick={() => {showPopupCaptureUrl('option-save-account')}}></i>
      </div>
    </div>
  </div>
);

export default AccountLoginSettings;
