import React, { Component } from 'react';

export function btnLoadTicketPage(env, root_url, port, isTicketType, projectId = null, taskId = null, ticketId = null) {
	let environmentPort = (env == 'development') ? `:${port}` : ''
	return isTicketType ? `https://${root_url}${environmentPort}/projects/${projectId}/${taskId}/ticket?highlight_tickets=${ticketId}` : 'javascript:void(0)'
}

export function renderByFileUrl(url) {
	if (!url) return url;
	let last4CharactersOfUrl = url.split('?')[0].slice(-4);
	let urlSplit = url.split('/');
	let lastPathInUrl = urlSplit[urlSplit.length - 1].split('?')[0];

	if (
		['.png', '.jpg', '.gif', '.svg'].includes(last4CharactersOfUrl) ||
		lastPathInUrl.includes('draw-image-') ||
		lastPathInUrl.includes('draw-video-image-')
	) return <img src={url} />;

	lastPathInUrl = decodeURI(lastPathInUrl)
	return lastPathInUrl.length > 20 ? `${lastPathInUrl.slice(0, 10)}...${lastPathInUrl.slice(-5)}` : lastPathInUrl;
}
