// x = 5000..7500 -> 2500 -> 5000

export function positionStyle({ x, y, width, height, commentBox = false }) {
  const maxMarker = 10000;
  if(commentBox){
    var isOverX = true;
    var isOverY = true;
    var overY = y;
    var overX = 2400;
    if($('#show-compare-version-akaire').length) overX = 1500;
    if(window.innerWidth < 1550) overX = 1000;

    if (y > maxMarker * 0.6){
      overY = maxMarker - y;
      isOverY = false;
    }
    if (y > maxMarker * 0.1) {
      setTimeout(function(){
        const element = document.getElementById('btn-submit-comment-box');
        if (element) {
          element.scrollIntoViewIfNeeded();
        }
      }, 500);
    }
  }else{
    var isOverX = (x + width) < 6000;
    var isOverY = (y + height) < 5000;
    var overX, overY;
    overY = (isOverY ? y + height : maxMarker - y)
    overX = (isOverX ? x + width : maxMarker - x)
    if (width > 3000 && !isOverX) {
      if(overX > maxMarker * 0.9) {
        overX = x + (maxMarker * 0.1);
      }else{
        overX = x;
      }
      isOverX = true;
    }
    if (height > 2500 && !isOverY) {
      overY = y + height;
      isOverY = true;
      if (overY > maxMarker * 0.5){
        overY = maxMarker - overY
        isOverY = false
      }
    }
  }
  return {
    [isOverX ? 'left' : 'right']: `${overX / 100}%`,
    [isOverY ? 'top' : 'bottom']: `${overY / 100}%`,
  };
};
