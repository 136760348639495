const _100_MB = 100 * 1024 * 1024;

export function isValidAttachFiles(stateFiles, filesAttach) {
	if ([...stateFiles, ...filesAttach].length > 10) {
    Popup.alert("１ファイル100MB以内・ファイル数は10個以内となります。");
    return false;
  }

  for (let i = 0; i < filesAttach.length; i++) {
    if (filesAttach[i].size > _100_MB) {
      Popup.alert("１ファイル100MB以内・ファイル数は10個以内となります。");
      return false;
    }
  }

  return true;
}
