export default class Signature {
  constructor() {
    let queryString = window.location.search; 
    let urlParams = new URLSearchParams(queryString);
    let showPDFContractId = urlParams.get('show_pdf_contract_id')
    const me = this
    if (!!showPDFContractId) {
      setTimeout(function() {
        if ($(`.btn-signed-contract-for-${showPDFContractId}`).length > 0) {
          $(`div#contract-show-id-${showPDFContractId}`).trigger('click')
          me.showPDFToSignedContract($(`.btn-signed-contract-for-${showPDFContractId}`))
          document.querySelector(`div#contract-show-id-${showPDFContractId}`).scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 1000)
    }
  }

  showHidePriviewPdf(ele) {
    $('div.show-pdf-preview-contract').empty();
    const isShowPdf = $(ele).attr('is-show-pdf')
    if (isShowPdf === 'false') {
      $(ele).attr('is-show-pdf', 'true')
      const eleShowPdf = $(ele).parents('li')
      const divShowPdf = eleShowPdf.find('div.show-pdf-preview-contract')
      const isShowPdfRevision = divShowPdf.attr('is-show-pdf-revision') === 'true'
      const btnSignedContract = eleShowPdf.find('button.btn-signed-contract')
      if (!isShowPdfRevision || btnSignedContract.length !== 0) return

      this.renderCanvasPdf(divShowPdf)
      reloadPdf(divShowPdf, eleShowPdf)
      $('.preloader').prop('style', '').addClass('loading').fadeIn();
    } else {
      $('.preloader').fadeOut();
      $(ele).attr('is-show-pdf', 'false')
      $(ele).parents('li').find('.btn-signed-contract').html('確認する')
    }
  }

  renderCanvasPdf(divShowPdf) {
    const urlPdf = divShowPdf.data('link-pdf')
    const urlDownloadPdf = divShowPdf.data('link-download-pdf')
    let canvas = `<canvas id="the-canvas-preview-pdf" data-pdf-link="${urlPdf}" class="mt-4 w-100 the-canvas-preview-pdf"></canvas>`
    let renderPage = `<div class="justify-content-between d-flex mx-2 my-2">
                        <div class="show-download-pdf">
                          <a type="button" class="btn btn-outline-dark border-dark" href="${urlDownloadPdf}" target="_blank">
                            <image src="${gon.assets.icon_download}" width="20" height="18"> ダウンロード
                          </a>
                        </div>
                        <div class="show-pagy-and-btn d-flex">
                          <button class="btn btn-secondary mr-2" onclick="goToLastPagePdfSignature()">
                            署名ページに移動する
                          </button>
                          <nav class="align-center ml-2" aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                              <li class="page-item">
                                <a class="page-link">
                                  <span id="page_num"></span> / <span id="page_count"></span>
                                </a>
                              </li>
                              <li id="prev" class="page-item cursor-pointer"><a class="page-link previous-page-pdf">< 前へ</a></li>
                              <li id="next" class="page-item cursor-pointer"><a class="page-link next-page-pdf">次へ></a></li>
                              <li id="last-page-pdf" class="d-none"><a class="page-link last-page-pdf">次へ></a></li>
                            </ul>
                          </nav>
                        </div>
                      </div>`
    divShowPdf.append(renderPage)
    divShowPdf.append(canvas)
  }

  showButtonSubmitSignature(ele, isCreateByCurrentCompany) {
    const divSignature = $(ele).parents('div.div-show-signature')
    const divhowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const divButton = divSignature.find('button.btn-submitted-signature')
    const date = divhowPdfSignature.find('.date-signature').val()
    if (!!date && !!$(ele).val()) {
      divButton.removeAttr('disabled')
    } else {
      divButton.attr('disabled', true)
    }
  }

  changeInputDate(ele, isCreateByCurrentCompany) {
    const divhowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const divButton = divhowPdfSignature.find('button.btn-submitted-signature')
    const textAreaSender = divhowPdfSignature.find('.signature-of-created-by')
    const textAreaReceived = divhowPdfSignature.find('.signature-of-partner')
    if (!!$(ele).val() && (!!textAreaReceived.val() || !!textAreaSender.val())) {
      divButton.removeAttr('disabled')
    } else {
      divButton.attr('disabled', true)
    }
  }

  submitSignature(ele, contractRevsionId) {
    $(ele).attr('disabled', true)
    const divhowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const date = divhowPdfSignature.find('.date-signature').val()
    const signatureSender = divhowPdfSignature.find('.signature-of-created-by').val()
    const signatureReceiver = divhowPdfSignature.find('.signature-of-partner').val()
    const url = Routes.save_signature_contract_revision_owner_handle_contract_index_path()
    const signature = this
    if (!date || (!signatureSender && !signatureReceiver)) return
    Shared.callAjax(url, {
      contract_revision_id: contractRevsionId,
      signature_of_sender: signatureSender,
      signature_of_receiver: signatureReceiver,
      date_signature: date,
      base_url: window.location.href
    }).done(function (data) {
      if (data.status === 'success') {
        if (data.is_show_popup_save_signature) {
          const url = new URL(window.location.href)
          url.searchParams.delete('contract_draft')
          url.searchParams.delete('contract_id')
          const newUrl = url.href.replace('#', '')
          const paramsPath = newUrl.includes('?') ? '&' : '?'
          location.href =
            newUrl + `${paramsPath}contract_revision_id=${contractRevsionId}&show_popup_set_signature=true&is_sender=${data.is_sender}&is_save_sender=${data.is_save_sender}`
        } else {
          signature.removeParamsHref()
        }
      } else {
        $(ele).removeAttr('disabled')
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      }
    })
  }

  removeParamsHref() {
    const urlSearchParams = new URL(location.href)
    urlSearchParams.searchParams.delete('contract_draft')
    urlSearchParams.searchParams.delete('contract_id')
    location.href = urlSearchParams.href
  }

  showPopupSignedContract(ele, contractId) {
    const eleShowPdf = $(ele).parents('li')
    const divShowPdf = eleShowPdf.find('div.show-pdf-preview-contract')
    const isShowPdf = divShowPdf.find('canvas')
    const me = this
    if (isShowPdf.length !== 0) {
      return
    }
    Popup.confirm(
      '<div class="font-weight-bold">書類内容を確認の上、締結する場合は<br>書類最終ページの「署名欄」に記入してください。</div>',
      {
        confirmText: 'OK',
        success: () => {
          me.showPDFToSignedContract(ele)
          setTimeout(function() {
            eleShowPdf[0].scrollIntoView({
              behavior: 'smooth'
            })
          }, 1000)
        }
      }
    )
  }

  signedSignature(ele, contractRevsionId) {
    $(ele).attr('disabled', true)
    const url = Routes.save_signature_partner_contract_revision_owner_handle_contract_index_path()
    const divhowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const signatureReceiver = divhowPdfSignature.find('.signature-of-partner').val()
    Shared.callAjax(url, {
      contract_revision_id: contractRevsionId,
      signature_of_receiver: signatureReceiver,
      base_url: window.location.href
    }).done(function (data) {
      if (data.status === 'success') {
        const url = window.location.host + window.location.pathname
        location.href =
          url + `?contract_revision_id=${contractRevsionId}&show_popup_set_signature=true&is_sender=${data.is_sender}&is_save_sender=${data.is_save_sender}`
      } else {
        $(ele).removeAttr('disabled')
        Popup.alert(I18n.t('shared.one_error_please_try_again'))
      }
    })
  }

  showButtonSignedSignature(ele) {
    const divSignature = $(ele).parents('div.div-show-signature')
    const divButton = divSignature.find('div.button-signed-signature')
    if (!!$(ele).val()) {
      divButton.removeClass('d-none').addClass('d-flex')
    } else {
      divButton.addClass('d-none').removeClass('d-flex')
    }
  }

  enableSenderSignature(ele, isChangeAll) {
    const divShowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const textAreaSender = divShowPdfSignature.find('textarea.signature-of-created-by')
    const textAreaReceived = divShowPdfSignature.find('textarea.signature-of-partner')
    const checkBoxReceived = divShowPdfSignature.find('.check-box-signature-received')
    if (isChangeAll !== 'true') {
      if (checkBoxReceived.is(':checked')) {
        $(ele).prop('checked', false)
      } else if(!checkBoxReceived.is(':checked')) {
        $(ele).prop('checked', true)
      }
      return
    }
    if (!checkBoxReceived.is(':checked')) {
      $(ele).prop('checked', true)
      return
    }
    if(!!textAreaReceived.val()) {
      if (textAreaReceived.val() === '') {
        textAreaSender.val(gon.current_contract)
      } else {
        textAreaSender.val(textAreaReceived.val())
      }
    } else if (gon.current_contract !== '') {
      textAreaSender.val(gon.current_contract)
    }
    textAreaSender.removeAttr('readonly')
    textAreaReceived.val('')
    textAreaReceived.attr('readonly', true)
    checkBoxReceived.prop('checked', false)
    this.removeAllBackgroundColor(divShowPdfSignature)
    const divShowSender = $(ele).parents('.show-signature-sender')
    divShowSender.find('.div-show-text').addClass('bg-gallery')
    divShowSender.find('.div-check-box').addClass('bg-gallery')
    divShowSender.find('textarea').addClass('bg-gallery')
  }

  enableReceivedSignature(ele, isChangeAll) {
    const divShowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const textAreaSender = divShowPdfSignature.find('textarea.signature-of-created-by')
    const textAreaReceived = divShowPdfSignature.find('textarea.signature-of-partner')
    const checkBoxSender = divShowPdfSignature.find('.check-box-signature-sender')
    if (isChangeAll !== 'true') {
      if (checkBoxSender.is(':checked')) {
        $(ele).prop('checked', false)
      } else if (!checkBoxSender.is(':checked')) {
        $(ele).prop('checked', true)
      }
      return
    }
    if (!checkBoxSender.is(':checked')) {
      $(ele).prop('checked', true)
      return
    }
    if(!!textAreaSender.val()) {
      if(textAreaSender.val() === '') {
        textAreaReceived.val(gon.current_contract)
      } else {
        textAreaReceived.val(textAreaSender.val())
      }
    } else if (gon.current_contract !== '') {
      textAreaReceived.val(gon.current_contract)
    }
    textAreaReceived.removeAttr('readonly')
    textAreaSender.val('')
    textAreaSender.attr('readonly', true)
    checkBoxSender.prop('checked', false)
    this.removeAllBackgroundColor(divShowPdfSignature)
    const divShowReceived = $(ele).parents('.show-signature-received')
    divShowReceived.find('.div-show-text').addClass('bg-gallery')
    divShowReceived.find('.div-check-box').addClass('bg-gallery')
    divShowReceived.find('textarea').addClass('bg-gallery')
  }

  removeAllBackgroundColor(divShowPdfSignature) {
    divShowPdfSignature.find('textarea').removeClass('bg-gallery')
    divShowPdfSignature.find('.div-show-text').removeClass('bg-gallery')
    divShowPdfSignature.find('.div-check-box').removeClass('bg-gallery')
  }

  checkSignatureReveived(ele) {
    const divShowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const divShowSignature = divShowPdfSignature.find('.div-show-signature')
    const checkBoxSender = divShowSignature.find('.check-box-signature-sender')
    if (checkBoxSender.is(':checked')) {
      Popup.alert('入力できません')
    }
  }

  checkSignatureSender(ele) {
    const divShowPdfSignature = $(ele).parents('div.show-pdf-signature')
    const divShowSignature = divShowPdfSignature.find('.div-show-signature')
    const checkBoxReceived = divShowSignature.find('.check-box-signature-received')
    if (checkBoxReceived.is(':checked')) {
      Popup.alert('入力できません')
    }
  }

  showPDFToSignedContract(ele) {
    const eleShowPdf = $(ele).parents('li')
    const divShowPdf = eleShowPdf.find('div.show-pdf-preview-contract')
    const isShowPdf = divShowPdf.find('canvas')
    const divSignature = eleShowPdf.find('div.show-pdf-signature')
    $(ele).attr('is_show_pdf', 'true')
    this.renderCanvasPdf(divShowPdf)
    reloadPdf(divShowPdf, eleShowPdf)
    const textAreaReceved = divSignature.find('textarea.signature-of-partner')
    this.showButtonSignedSignature(textAreaReceved)
    this.showButtonSubmitSignature(textAreaReceved)
    $(ele).html('締結する')
    $('.preloader').prop('style', '').addClass('loading').fadeIn();
  }
}
