export default class Client {
  create(element, url) {
    let $form = $(element).parents('form.new_company')
    let $selectProject = $form.find('#select_project')
    if ($form[0].reportValidity()) {
      let data = $form.serialize()
      Shared.callAjax(url, data).done((res) => {
        if (res['client']){
          let client = res['client']
          $('#select_owner_company').append(`<option value="${client.id}">${client.name}</option>`)
          $('#select_owner_company').val(`${client.id}`).trigger('change')
          $('#new_client').modal('hide')
          $form.find('.client-temp-id').val(client.id)
          // $(element).attr('onclick', `Client.create(this, '${Routes.owner_client_path(client.id)}')`).text(I18n.t('shared.update_btn'));
          Notify.success(res['message'])
        } else {
          Notify.error(res['message'])
        }
      })
    }
  }

  removeDataModal(element) {
    $(element).find('form')[0].reset()
  }
}
