export default class NewProject {

  selectProject(evt) {
    const allProject = document.querySelectorAll(".single-project");
    allProject.forEach(singleProject => {
      //enable form element if it was disable
      singleProject.querySelectorAll("*").forEach(element => element.removeAttribute("disabled"));
      //hide form that isn't being selected
      singleProject.classList.add("display-none");
      const nameSelected = evt.target.options[evt.target.selectedIndex].dataset.name;
      if (singleProject.getAttribute("data-name") === nameSelected) singleProject.classList.remove("display-none");
      if (!!nameSelected) {
        $('div#project-type-status').removeClass('border-bottom-none');
        $('.th.item-project-type').css('border-bottom', '')
      } else {
        $('.th.item-project-type').css('border-bottom', 'none')
        $('div#project-type-status').addClass('border-bottom-none');
      }
    });
  }

  submitForm(element) {
    const allProject = document.querySelectorAll(".single-project");
    const projectSelectOption = document.querySelector("select#project-type");
    let nameSelected;
    if (projectSelectOption) {
      nameSelected = projectSelectOption.options[projectSelectOption.selectedIndex].getAttribute("data-name")
    } else {
      nameSelected = $('input#project-type').val()
    }
    //disable form elements that isn't use
    allProject.forEach(singleProject => {
      if (singleProject.getAttribute("data-name") !== nameSelected) {
        singleProject.querySelectorAll("*").forEach(element => element.disabled = true);
      }
    });
    ProjectPage.validateRange('#js-estimate_costs_from', '#js-estimate_costs_to')
  }

  triggerSubmitForm(element) {
    $(element).prop("disabled", true)
    $(element).addClass("disabled")

    document.getElementById("project-submit-button").click();

    setTimeout(function(){
      $(element).prop("disabled", false)
      $(element).removeClass("disabled")
    }, 5000);
  }

  toggleCustomerNameOption(evt) {
    const applicationSelectedType = evt.target.options[evt.target.selectedIndex].dataset.name;
    const companyList = document.getElementById("company_list");
    if (applicationSelectedType !== "project_of_client") {
      companyList.classList.add("d-none")
      companyList.querySelectorAll("*").forEach(element => element.disabled = true);
    } else {
      companyList.classList.remove("d-none");
      companyList.querySelectorAll("*").forEach(element => element.removeAttribute("disabled"));
    }
  }

  confirmRedirectToProjects(ele) {
    Popup.confirm('複製データが削除されます。 <br>よろしいですか？', {
      success: () => { location.href = Routes.owner_projects_path() },
      cancel: () => {},
      confirmText: 'OK'
    })
  }

  async selectQuotationDuplicate(ele, isQuotation, projectId) {
    if (isQuotation === 'true') {
      $('#popup-select-est').modal('show')
    } else {
      this.showSelectAllUser(projectId)
    }
  }

  selectQuotationDup(ele, quotationId) {
    const quotationID = Number(quotationId)
    const listQuotations = $('#list-quotation-duplicate').val()
    const quotations = JSON.parse(listQuotations)
    let is_quotation_checked = false
    if ($(ele).is(':checked')) {
      $('#text-next-select-users').attr('class', 'cursor-pointer text-underline text-dark')
      quotations.push(`${quotationID}`)
      $('#list-quotation-duplicate').val(`[${quotations}]`)
    } else {
      const index = quotations.indexOf(`${quotationID}`)
      quotations.splice(index, 1)
      $('#list-quotation-duplicate').val(`[${quotations}]`)
      $('#check-all-quotations').prop('checked', false)
      const listCheckedQuotation = $(ele).parents('table').find('input.check-box-select-quotation')
      listCheckedQuotation.toArray().forEach(quotationEle => {
        if ($(quotationEle).is(':checked')) {
          is_quotation_checked = true
        }
      })
      if(!is_quotation_checked) {
        $('#text-next-select-users').attr('class', 'text-light-gray text-underline')
      }
    }
  }

  checkAllQuotation(ele) {
    const listCheckedQuotation = $(ele).parents('table').find('input.check-box-select-quotation')
    if ($(ele).is(':checked')) {
      listCheckedQuotation.toArray().forEach(quotationEle => {
        $('#text-next-select-users').attr('class', 'cursor-pointer text-underline text-dark')
        if (!$(quotationEle).is(':checked')) {
          const quotationID = Number($(quotationEle).val())
          const listQuotations = $('#list-quotation-duplicate').val()
          const quotations = JSON.parse(listQuotations)
          quotations.push(`${quotationID}`)
          $('#list-quotation-duplicate').val(`[${quotations}]`)
          $(quotationEle).prop('checked', true)
        }
      })
    } else {
      listCheckedQuotation.toArray().forEach(quotationEle => {
        $(quotationEle).prop('checked', false)
      })
      $('#list-quotation-duplicate').val('[]')
      $('#text-next-select-users').attr('class', 'text-light-gray text-underline')
    }
  }

  nextShowPopupSelectUsers(ele, projectId) {
    const projectModel = this
    if (!$(ele).hasClass('text-dark')) return

    $('#popup-select-est').modal('hide')
    Popup.confirm('複製するプロジェクト<br> に見積書を移動しました。', {
      success: () => { projectModel.showSelectAllUser(projectId) },
      cancel: () => { projectModel.showSelectAllUser(projectId) },
      confirmText: 'OK',
      cancelBtn: 'false'
    })
  }

  async showSelectAllUser(projectId) {
    const quotations = $('#list-quotation-duplicate').val()
    const url = Routes.owner_project_render_modal_select_user_duplicate_path(projectId)
    await Shared.callAjax(url, { quotation_ids: quotations }, 'html').done(function(response) {
      $('#content-list-users').html(response)
      $('#show-modal-select-users').modal('show')
    })
    .fail(function(response) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _showPopupSelectUsers() {
    $('#show-modal-select-users').modal('show')
  }

  checkAllUser(ele) {
    const listUserEle = $(ele).parents('table').find('input.check-box-select-user').toArray()
    if ($(ele).is(':checked')) {
      listUserEle.forEach(userEle => {
        const userID = Number($(userEle).val())
        const listUsers = $('#list-users-duplicate').val()
        const users = JSON.parse(listUsers)
        if (!users.includes(userID)) users.push(userID)
        $('#list-users-duplicate').val(`[${users}]`)
        if (!$(userEle).is(':checked')) $(userEle).prop('checked', true)
      })
    } else {
      listUserEle.forEach(userEle => {
        if (!$(userEle).is(':disabled')) {
          const userID = Number($(userEle).val())
          const listUsers = $('#list-users-duplicate').val()
          const users = JSON.parse(listUsers)
          const index = users.indexOf(userID)
          users.splice(index, 1);
          $('#list-users-duplicate').val(`[${users}]`)
          $(userEle).prop('checked', false)
        }
      })
    }
  }

  selectUsersDup(ele, userId) {
    const listUsers = $('#list-users-duplicate').val()
    const users = JSON.parse(listUsers)
    if($(ele).is(':checked')) {
      users.push(Number(userId))
    } else {
      const index = users.indexOf(Number(userId))
      users.splice(index, 1)
      $('#check-all-users').prop('checked', false)
    }
    $('#list-users-duplicate').val(`[${users}]`)
  }

  showPopupRenameProjectDup() {
    $('#show-modal-select-users').modal('hide')
    const project = this
    Popup.confirm('複製するプロジェクト<br> にメンバーを招待しました。', {
      success: () => { $('#show-modal-rename-project-dup').modal('show') },
      cancel: () => { $('#show-modal-rename-project-dup').modal('show') },
      confirmText: 'OK',
      cancelBtn: 'false'
    })
  }

  handleDuplicateProject(ele, projectId) {
    $('#show-modal-rename-project-dup').modal('hide')
    const quotation_ids = $('#list-quotation-duplicate').val()
    const user_ids = $('#list-users-duplicate').val()
    const projectName = $('#project-name-dup').val()
    const project = this
    const url = Routes.owner_project_duplicate_project_deleted_path(projectId)
    Shared.callAjax(url, {
      quotation_ids: quotation_ids, user_ids: user_ids, project_name: projectName
    }).done(function(data) {
      project._reDirectToProjectList('複製されました。')
    })
    .fail(function(data) {
      Popup.alert(I18n.t('shared.one_error_please_try_again'))
    })
  }

  _reloadPage(message) {
    Popup.confirm(message, {
      success: () => { location.reload() },
      cancel: () => { location.reload() },
      confirmText: 'OK',
      cancelBtn: 'false'
    })
  }

  _reDirectToProjectList(message) {
    Popup.confirm(message, {
      success: () => { location.href = Routes.owner_projects_path() },
      cancel: () => { location.href = Routes.owner_projects_path() },
      confirmText: 'OK',
      cancelBtn: 'false'
    })
  }
}
