import toastr from 'toastr';
window.toastr = toastr;

export default class BankAccount {
  validate(event) {
    event.stopPropagation()
    event.preventDefault()

    const $form = $(event.target).parents('form')
    let isValid = true
    $form.find('input:visible').each((_, ele) => {
      const $ele = $(ele)
      if($ele.attr('require') && $ele.val() === '') {
        isValid = false
        if($ele.attr('id') === 'bank_account_account_name') {
          BankAccount._checkValidationWithPlaceholder($ele, "この項目が未入力です。");
        } else {
          BankAccount._checkValidation($ele);
        }
      } else {
        const pattern = $ele.attr('pattern')
        if (pattern) {
          const patternRegex = new RegExp(pattern)
          if ($ele.val() && !patternRegex.test($ele.val())) {
            isValid = false
            if($ele.attr('id') === 'bank_account_account_name') {
              BankAccount._checkValidationWithPlaceholder($ele, "この文字は入力できません。");
            } else {
              BankAccount._checkValidation($ele);
            }
          }
        }
      }
    })

    if (isValid){
      Popup.confirm('', {
        description: '口座情報を変更すると販売先に通知されます。<br>変更してよろしいですか？',
        confirmText: 'OK',
        success: () => {
          $form.submit()
        }
      })
    }
  }

  static _checkValidation(element) {
    element.addClass('is-invalid');
    element.val('');
    setTimeout(function() { element.removeClass('is-invalid') }, 5000000);
  };

  static _checkValidationWithPlaceholder(element, placeholder) {
    Popup.alert('口座名義名項目に、入力できない文字があります。')
    element.addClass('is-invalid');
    element.val('');
    element.attr("placeholder", placeholder);
    setTimeout(function() { element.removeClass('is-invalid') }, 5000000);
  };
}
