import './bootstrap-datetimepicker'
import '../../assets/stylesheets/bootstrap-datetimepicker.min.scss'
import '../../assets/stylesheets/bootstrap-datetimepicker-standalone.scss'

$(document).ready(function(){
    $('body').on('dp.change', 'input.end_date' , function(e){
    $(this).find('input.end_date').data("DateTimePicker");
    });
    var hash = {};
    var value = JSON.parse(localStorage.getItem('hash'));

        $.each(value, function(k, v) {
            $('input[data-name="'+k+'"]').prop('checked', v);
            $('select option[data-name="'+k+'"]').prop('selected', v);
        });


    $('#filter-form').on('submit', function(){
        $('input:checkbox').each(function(index, value) {
            if($(this).is(':checked')) {
                hash[$(this).attr('data-name')] = $(this).is(':checked');
            }
        })
        $('select option').each(function (index, value) {
            if($(this).is(':selected')) {
                hash[$(this).attr("data-name")] = $(this).is(':selected');
            }
        })
        localStorage.setItem('hash', JSON.stringify(hash))
    });

    hide_show_search_form($("#customSwitch1"))

    $("#customSwitch1").on('click', function() {
        hide_show_search_form($("#customSwitch1"))
    });

    function hide_show_search_form(selector) {
        if (selector.is(':checked')) {
            $('.project-search').removeClass('d-none');
        } else {
            $('.project-search').removeClass('d-flex').addClass('d-none');
        }
    }

    if (gon.new_client === 'true') {
      DashboardClientModal.upgradeToOwner();
    }

})

