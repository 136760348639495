export default class ClientStatus {

  reInvite(element) {
    var client_id = $(element).parent().data('client-id');
    var url = Routes.owner_client_re_invite_path()
    var data = { client_id: client_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待メールを再送信しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  removeInvitation(element) {
    var client_id = $(element).parent().data('client-id');
    var url = Routes.owner_client_remove_invitation_path()
    var data = { client_id: client_id }

    Shared.callAjax(url, data, 'json', 'delete').done(function(data) {
      $("#modalWaitToApprove").find(".text-message").html("招待を解除しました。")
      $("#modalWaitToApprove").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
      $("#modalWaitToApprove").find(".button-back-list").addClass("back-to-clients");
    })
  }

  quickApproveInvitation(element) {
    var client_id = $(element).parent().data('client-id');
    var url = Routes.owner_client_quick_approve_invitation_path()
    var data = { client_id: client_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("が販売先になりました。")
      $("#modalBeInvited").find(".client-name").html(data.company_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }

  rejectInvitation(element) {
    var client_id = $(element).parent().data('client-id');
    var url = Routes.owner_client_reject_invitation_path()
    var data = { client_id: client_id }

    Shared.callAjax(url, data, 'json', 'post').done(function(data) {
      $("#modalBeInvited").find(".text-message").html("からの招待を解除しました。")
      $("#modalBeInvited").find(".company-name").html(data.company_name)
      $("#modalBeInvited").modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })
    })
  }
}
