import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';

const icons = {
  all: 'comment',
  circle: 'dot-circle',
  none: 'comment-slash'
};

export default class AnimationMarkersToggler extends Component {
  render() {
    const { visibility, onClick, addClass } = this.props;
    const icon = icons[visibility];
    
    return (
      <FontAwesome
        name={icon}
        className={classnames('animation-markers-toggler', 'video-react-control', 'video-react-button')}
        onClick={onClick}
        id={addClass}
      />
    );
  }
};
