export default class ProjectPage {
  validateFile(element,
               allowedExtensionsArray = ['doc', 'docx', 'odt', 'pdf', 'tex', 'txt', 'rtf', 'xls', 'xlsx', 'mp4', 'wmv',
                                         'avi', 'mov', 'csv', 'psd', 'ai', 'mp3', 'ppt', 'pptx', 'zip', 'draw', 'ait',
                                         'jpg', 'png', 'gif'],
               filenameElement) {
    const allowedExtensions = new RegExp('(\\.' + allowedExtensionsArray.join('|\\.') + ')$','i');
    const maxSize = 5242880 * 20
    let typeAllow = allowedExtensionsArray.map(type => type.toUpperCase()).join(', ');

    Input.validateFile(element, '', allowedExtensions, maxSize,
      {
        errorMessageExtensions: `${typeAllow} ファイルがアップロード可能`,
        errorMessageSize: `ファイル ${Math.fround(maxSize / 1048576)} MB以内までアップロードできます。`,
        filenameElement
      }
    )
  }

  validateRange(minElement, maxElement) {
    const $minElement = $(minElement)
    const $maxElement = $(maxElement)

    // reset requirement
    $minElement.attr('required', false)
    $maxElement.attr('required', false)
    $minElement.attr('max', false)

    if ($minElement.val() !== '' && $maxElement.val() !== '') {
      $minElement.attr('max', $maxElement.val() - 1)
    } else if ($minElement.val() !== '' || $maxElement.val() !== '') {
      $minElement.attr('required', true)
      $maxElement.attr('required', true)
    }
  }
}
