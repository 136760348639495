import 'packs/jquery-ui.min'

export default class SortFunction {
  createCustomizeSort(type, object_id, entity_type = null) {
    const customizeSortName = $('#name-of-customize-sort').val().trim();
    if(customizeSortName.length == 0) {
      $('#createCustomizeSort').find('.name-not-blank').removeClass('d-none');
    } else {
      $('#createCustomizeSort').find('.name-not-blank').addClass('d-none');
      const url = Routes.object_sorts_path()
      let dataSort = {};
      if(type == 'project') {
        dataSort = this.listDataSortProject()
      } else if(type == 'task') {
        dataSort = this.listDataSortTask()
      } else if(type == 'ticket') {
        dataSort = this.listDataSortTicket()
      } else if(type == 'akaire_file') {
        dataSort = this.listDataSortAkaireFile()
      }

      Shared.callAjax(
        url,
        { name: customizeSortName, data_sort: dataSort, type: type, object_id: object_id, entity_type: entity_type },
        'json'
      ).done(function(data) {
        $('#notifyCreateSortSuccess').find('.sort-object-name').html(data.name)
        $('#createCustomizeSort').modal('hide')
        $('#notifyCreateSortSuccess').modal('show')
        $("#notifyCreateSortSuccess").on('hide.bs.modal', function(){
          window.location = data.location;
        })
      })
    }
  }

  listDataSortProject() {
    let dataSort = {};
    let numOfPaginate = $('.select-items-each-page').html();
    if($('#tbody-list-project').attr('data-sort-default-hash').length > 0){
      dataSort = this.handleDataDefault($('#tbody-list-project').attr('data-sort-default-hash').split(','));
    }
    const page = $('.project-pagy').find('.pagination').find('.page-item.active').find('.page-link').text() - 1;

    $('#tbody-list-project').find('tr.project-record').each(function(index, element){
      const projectId = $(element).attr('data-project-id');
      dataSort[projectId] = (page*numOfPaginate) + index + 1
    });

    return dataSort
  }

  listDataSortTask() {
    let dataSort = {};
    let numOfPaginate = $('.select-items-each-page').val();
    if($('#tbody-list-task').attr('data-sort-default-hash').length > 0){
      dataSort = this.handleDataDefault($('#tbody-list-task').attr('data-sort-default-hash').split(','));
    }
    const page = $('#task-table').find('.task-list-pagination').find('.page-item.active').find('.page-link').text() - 1;

    $('#tbody-list-task').find('tr.parent_row').each(function(index, element){
      const task = $(element).attr('data-id');
      dataSort[task] = (page*numOfPaginate) + index + 1
    });

    return dataSort
  }

  listDataSortAkaireFile() {
    let dataSort = {};
    let numOfPaginate = $('.select-items-each-page').text();
    if($('#tbody-list-akaire_file').attr('data-sort-default-hash').length > 0){
      dataSort = this.handleDataDefault($('#tbody-list-akaire_file').attr('data-sort-default-hash').split(','));
    }
    const page = $('#task-table').find('.akaire_file-list-pagination').find('.page-item.active').find('.page-link').text() - 1;
    $('#tbody-list-akaire_file').find('tr.parent_row').each(function(index, element){
      const task = $(element).attr('data-id');
      dataSort[task] = (page*numOfPaginate) + index + 1
    });

    return dataSort
  }

  editCustomizeSortName(e, element) {
    $('.input-customize-sort-name').addClass('d-none')
    $('.customize-sort-name').removeClass('d-none')
    $(element).parent().find('.input-customize-sort-name').removeClass('d-none')
    $(element).parent().find('.customize-sort-name').addClass('d-none')
    $(element).parent().find('.input-customize-sort-name').on("keypress", function(event) {
      if (event.key === "Enter") {
        $(element).parent().find('.input-customize-sort-name').trigger('change');
        event.preventDefault();
      }
    });
    e.stopPropagation();
  }

  submitCustomizeSortName(element, sort_id, current_sort_id) {
    const url = Routes.update_name_object_sort_path(sort_id);
    const name = $(element).val();
    if(name.length == 0) return

    const ele = element;
    Shared.callAjax(url, {name: name}, 'json', 'patch').done(function(data) {
      $(`#customize-sort-${data.id}`).parent().find('.input-customize-sort-name').addClass('d-none')
      $(`#customize-sort-${data.id}`).parent().find('.customize-sort-name').removeClass('d-none')
      $(`.customize-sort-${data.id}`).text(data.name)
      if(data.id == current_sort_id) {
        $('.sort-text-display').text(data.name)
      }
    })
  }

  listDataSortTicket() {
    let dataSort = {};
    let numOfPaginate = $('.select-items-each-page').val();
    if($('table.render_works').attr('data-sort-default-hash').length > 0){
      dataSort = this.handleDataDefault($('table.render_works').attr('data-sort-default-hash').split(','));
    }

    const page = $(".render_pagy").find(".pagination").find('.page-item.active').find('.page-link').text() - 1;
    $('table.render_works').find('tbody.work-row').each(function(index, element){
      const ticket = $(element).attr('data-work-id');
      dataSort[ticket] = (page*numOfPaginate) + index + 1
    });

    return dataSort
  }

  listDataSortEffort() {
    let dataSort = {};

    $('.show-work-quantity.show').find('.work-quantity-tbody').find(".work-quantities").each(function(index, element){
      const effort = $(element).attr('data-work-quantity-id');
      dataSort[effort] = index + 1
    });

    return dataSort
  }

  openCreateCustomizeSort(type = 'none') {
    if(type === 'hidden_text') {
      $('#createCustomizeSort').find('.text-detail').removeClass('d-none')
    } else {
      $('#createCustomizeSort').find('.text-detail').addClass('d-none')
    }
    $('#modalSort').modal('hide')
    Shared.showModalForElement('#createCustomizeSort')
    SearchForm.selectSortDisplay('toggle', event)
  }

  handleButtonSubmit(element) {
    let button = $('.submit-customize-sort')
    if($(element).val().length > 0) {
      $('.submit-customize-sort').prop('disabled', false)
      $('.submit-customize-sort').removeClass('disabled')
    } else {
      $('.submit-customize-sort').prop('disabled', true)
      $('.submit-customize-sort').addClass('disabled')
    }
  }

  handleDataDefault(arrays) {
    let data = {};
    arrays.forEach(function (arr) {
      if(arr.length > 0) data[arr.split('-')[0]] = arr.split('-')[1]
    });
    return data
  }
}
