import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Player, BigPlayButton, ControlBar, Shortcut, VolumeMenuButton } from 'video-react';
import classnames from 'classnames';
import { canUserNotPermission } from '../authorizations';
import memoize from "memoize-one";

import AnimationMarkerForm from './AnimationMarkerForm';
import AnimationMarkersInPlayer from './AnimationMarkersInPlayer';
import AnimationMarkerPointsDisplay from './AnimationMarkerPointsDisplay';
import MarkShape from './MarkShape';
import PolyLineSvg from "./PolyLineSvg";
import { styles } from "../util/styleDrawComponent";
import {getStyleOfSize} from "../util/getStyleofSize";
import SettingButton from './SettingButton';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { fetchHeightHeader } from '../shared';
import CommentDetail from './CommentDetail';
import AnimationMarkerLine from './AnimationMarkerLine';
import AnimationCommentsPane from './AnimationCommentsPane';
import AnimationMarkerPeriod from './AnimationMarkerPeriod';

const markersVisibilities = ['all', 'circle', 'none'];
const showSVGIndex = 1000;
const hideSVGIndex = 0;
const timestamp = new Date().getTime();

export default class AnimationPlayer extends Component {
  constructor() {
    super();
    this.state = {
      isMarking: false,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      currentWidth: 0,
      currentHeight: 0,
      currentShape: 'point',
      shouldShowForm: false,
      markersVisibility: 'all',
      currentTime: 0,
      canvasWidth: 0,
      canvasHeight: 0,
      zIndexSvg: hideSVGIndex,
      listItemSvg: [],
      currentItemSvg: null,
      settingDraw: {
        color: "black",
        lineWidth: 3,
      },
      isLargeScreen: true,
      currentCanvasWidth: 0,
      currentCanvasHeight: 0,
      isShowControlBar: false,
      isShowModalNotCompareVersion: false,
      totalDuration: 0,
      periodRange: null,
      showPeriodRange: [],
    };
    this.overlayComponent = this.overlay();
    this.videoRef = React.createRef();

    this.showSeekBar = this.showSeekBar.bind(this);
    this.hintSeekBar = this.hintSeekBar.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.player.play();
      this.player.pause();
      this.refs.shortcut.shortcuts = [];
    }, 500);
    this.reset();
    var width = document.getElementById("animation-player").offsetWidth;
    var height = document.getElementById("animation-player").offsetHeight;
    this.setState({
      canvasWidth: width,
      canvasHeight: height,
      currentCanvasWidth: width,
      currentCanvasHeight: height,
    });
    this.disabledScrollBodyOfVideoMobile()
    // window.addEventListener("resize", this.update);
  }
  disabledScrollBodyOfVideoMobile() {
    if (window.innerWidth < 768) {
      document.querySelector('body').style.setProperty('overflow' ,'hidden',  'important')
    } else {
      document.querySelector('body').style.setProperty('overflow' ,'auto',  'important')
    }
  }
  update = () => {
    let width = document.getElementById("animation-player").offsetWidth;
    let height = document.getElementById("animation-player").offsetHeight;

    if (width !== this.state.canvasWidth) {
      this.setState(
        {
          canvasWidth: width,
          canvasHeight: height,
          listItemSvg: [],
        },
        () => {
          this.ctx = this.canvas.getContext("2d");
          this.ctx.clearRect(
            0,
            0,
            this.state.canvasWidth,
            this.state.canvasHeight
          );
          this.ctx.drawImage(
            this.player.video.video,
            0,
            0,
            this.state.canvasWidth,
            this.state.canvasHeight
          );
        }
      );
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.settingDraw != this.state.settingDraw) {
      this.setState({
        settingDraw: newProps.settingDraw,
      });
    }
  }
  mouseRelativeXY(event) {
    return this.relativeXY(event.clientX, event.clientY);
  }
  mouseRelativeXYDraw(event) {
    return this.relativeXY(
      event.nativeEvent.clientX,
      event.nativeEvent.clientY
    );
  }
  touchRelativeXY(event) {
    return this.relativeXY(event.touches[0].clientX, event.touches[0].clientY);
  }
  relativeXY(eventX, eventY) {
    const playerRect = findDOMNode(this.player).getBoundingClientRect();
    return [
      (eventX - playerRect.left) / playerRect.width * 10000,
      (eventY - playerRect.top) / playerRect.height * 10000,
    ];
  }
  startMark = ({ x, y }) => {
    this.player.pause();
    const { player: { currentTime } } = this.player.getState();
    this.setState({
      isMarking: true,
      shouldShowForm: false,
      startX: x,
      startY: y,
      currentX: x,
      currentY: y,
      currentWidth: 0,
      currentHeight: 0,
      currentShape: 'point',
      currentTime,
    });
  }
  moveMark = ({ x, y }) => {
    const { isMarking, startX, startY } = this.state;
    if (!isMarking) return;
    const { max, abs } = Math;
    const distance = max(abs(x - startX), abs(y - startY));
    distance > 10 && startX < x && startY < y
      ? this.setState({
        currentWidth: max(x - startX, 0),
        currentHeight: max(y - startY, 0),
        currentShape: 'rect',
      })
      : this.setState({
        currentWidth: 0,
        currentHeight: 0,
        currentShape: 'point',
      })
  }
  endMark = (event) => {
    if(!this.state.isMarking) return
    event.stopPropagation();
    event.preventDefault();
    const { currentAnimationId, animationSelectCurrent, toggleShowModalInputInfoOfNotLogin, isInputInfoMationOfNotLogin }= this.props;
    this.setState({
      isMarking: false,
    });
    if (currentAnimationId === animationSelectCurrent) {
      if (gon.is_login || isInputInfoMationOfNotLogin) {
        if (this.props.isOpenNouislider && !this.state.periodRange) {
          Popup.confirm(`範囲の指定が1ヶ所のみです。<br>範囲指定を終了し、コメントを入力しますか？`, {
            confirmText: 'OK',
            success: () => {
              this.props.closeNouislider()
              this.setState({ shouldShowForm: true, showPeriodRange: [], isMarking: false, });
            },
            cancel: () => {
              this.setState({ shouldShowForm: false, showPeriodRange: [], isMarking: false, });
            }
          })
        } else {
          this.setState({
            shouldShowForm: true, showPeriodRange: [], isMarking: false,
          });
        }
      } else {
        toggleShowModalInputInfoOfNotLogin()
      }
      this.disableMmount();
    }
  };
  openPeriod = (periodRange) => {
    if (this.state.startX === 0 &&
      this.state.startY === 0 &&
      this.state.currentX === 0 &&
      this.state.currentY === 0) {
      this.setState({
        shouldShowForm: true,
        periodRange: periodRange,
        startX: 1000,
        startY: 1000,
        currentX: 1000,
        currentY: 1000
      });
    } else {
      this.setState({
        shouldShowForm: true,
        periodRange: periodRange,
        showPeriodRange: [],
      });
    }
  }
  pauseVideo = () => {
    this.player.pause();
  }
  getStyleOnMode = ()=>{
    const {onMode} = this.props
    let style={}
    onMode ? style ={
      top:0,
      bottom:"unet"
    } : style ={
      top:"unset"
    }
    return style
  }
  disableMmount(e = "") {
    var menus = document.querySelectorAll(".show-mark-shape");
    menus = [].slice.call(menus);
    menus.forEach(function (element) {
      element.style.pointerEvents = e;
    });
  }
  overlay() {
    return () => (
      <div
        className="overlay akapon"
        style={this.getStyleOnMode()}
        onMouseDown={(event) => {
          this.disableMmount("none");
          event.stopPropagation();
          event.preventDefault();
          const [x, y] = this.mouseRelativeXY(event);
          this.startMark({ x, y });
        }}
        onTouchStart={(event) => {
          event.stopPropagation();
          event.preventDefault();
          this.setOption();
          const [x, y] = this.touchRelativeXY(event);
          this.startMark({ x, y });
        }}
        onMouseMove={(event) => {
          event.stopPropagation();
          event.preventDefault();
          const [x, y] = this.mouseRelativeXY(event);
          this.moveMark({ x, y });
        }}
        onTouchMove={(event) => {
          event.stopPropagation();
          event.preventDefault();
          const [x, y] = this.touchRelativeXY(event);
          this.moveMark({ x, y });
        }}
        onMouseUp={this.endMark}
        onTouchEnd={this.endMark}
        onMouseLeave={this.endMark}
        onClick={this.setOption}
      ></div>
    );
  }
  onSubmitMarkerForm = (body, files, editorSetting) => {
    const { onSubmitMarkerForm } = this.props;
    const {
      currentX: x,
      currentY: y,
      currentWidth: width,
      currentHeight: height,
      currentShape: shape,
      currentTime: time,
      periodRange
    } = this.state;
    return onSubmitMarkerForm({
      body,
      x,
      y,
      width,
      height,
      shape,
      time,
      files,
      editorSetting,
      periodRange
    }).then(() =>
      this.setState({
        shouldShowForm: false,
        periodRange: null,
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0,
        currentShape: 'point'
      })
    );
  };
  onCloseMarkerForm = () => {
    this.setState(
      {
        shouldShowForm: false,
      },
      () => {
        this.ctx = this.canvas.getContext("2d");
        this.ctx.clearRect(
          0,
          0,
          this.state.canvasWidth,
          this.state.canvasHeight
        );
        this.ctx.drawImage(
          this.player.video.video,
          0,
          0,
          this.state.canvasWidth,
          this.state.canvasHeight
        );
      }
    );
  };
  activeMarkers() {
    const { markers } = this.props;
    const { currentTime } = this.state;
    return markers.filter(_ =>
      (currentTime - 1 <= _.seek_time && _.seek_time <= currentTime + 1) ||
        (_.period_end > 0 && _.seek_time <= currentTime && currentTime - 1 <= _.period_end)
    );
  }
  onTimeUpdate() {
    const { player: { currentTime } } = this.player.getState();
    this.setState({
      currentTime
    });
  }
  seekTo(time) {
    this.player.seek(time);
  }
  onClickMarkersToggler = () => {
    const currentIndex = markersVisibilities.indexOf(this.state.markersVisibility);
    const nextIndex = (currentIndex + 1) < markersVisibilities.length ? currentIndex + 1 : 0;
    this.setState({ markersVisibility: markersVisibilities[nextIndex] });
  }
  refCallback = element => {
    const { getHeightAnimaiton } =this.props
    if (element && getHeightAnimaiton) {
      this.elementRef = element;
      getHeightAnimaiton(element.getBoundingClientRect().height);
    }
  };

  showSeekBar(){
    const { mobile } = this.props
    if(mobile) return

    document.querySelector('.video-react-control-bar').style.display = "flex";
    document.querySelector('.video-react-big-play-button').style.opacity = "1";
    this.setState({ isShowControlBar: true });
  }

  hintSeekBar(){
    const { mobile } = this.props
    if(mobile) return

    document.querySelector('.video-react-control-bar').style.display = 'none';
    document.querySelector('.video-react-big-play-button').style.opacity = '0.01';
    this.setState({ isShowControlBar: false });
  }

  showSeekBarMobile(){
    const { mobile } = this.props
    if(!mobile)
      return
    var element = document.querySelector('.video-react-paused');
    var playButton = document.querySelector('.video-react-control-bar');
    if (element && playButton){
      this.showSeekBar();
    } else if (playButton){
      playButton.style.display = 'none';
    }
  }

  draw(e) {
    this.player.video.pause();
    this.setState(
      {
        isDel: false,
        shouldShowForm: false,
        // isLargeScreen: false,
      },
      () => {
        this.setState(
          {
            canvasHeight: this.state.currentCanvasHeight,
            canvasWidth: this.state.currentCanvasWidth,
          },
          () => {
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");
            context.drawImage(
              this.player.video.video,
              0,
              0,
              this.state.canvasWidth,
              this.state.canvasHeight
            );
          }
        );
      }
    );

    this.setState({
      zIndexSvg: showSVGIndex,
    });
  }

  eraser() {
    this.setState({
      isDel: true,
    });
  }

  capture(e) {
    document.getElementById("animation-player").style["overflow"] = 'unset'
    this.player.video.pause();
    const currentTime = this.player.video
      ? this.player.video.props.player.currentTime
      : 0;
    const fileDrawName = `draw-video-image-${moment.now()}`;
    const files = [];
    var svgURL = new XMLSerializer().serializeToString(this.svg);
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    var img = new Image();
    img.onload = () => {
      context.drawImage(
        img,
        0,
        0,
        this.state.canvasWidth,
        this.state.canvasHeight
      );
      var imageSrc = this.canvas.toDataURL();
      const { listItemSvg } = this.state;
      if (listItemSvg.length === 0) {
        return;
      } else {
        const { listItemSvg } = this.state;
        const arr = listItemSvg[0].capturePoint.split(",");
        const convertArr = arr[1].trim().split(" ");
        const markPointCaptureY = Number(convertArr[0]);
        const markPointCaptureX = Number(convertArr[1]);
        this.setState({
          currentX: markPointCaptureX,
          currentY: markPointCaptureY,
        });
      }
      this.urltoFile(imageSrc, fileDrawName, "image/png").then((file) => {
        files.push(file);
        this.setState(
          {
            currentShape: "draw",
            currentTime: currentTime,
            shouldShowForm: true,
          },
          () => {
            this.makerForm.onFileChange(null, files);
          }
        );
      });
    };
    img.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgURL);
  }

  urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  deleteAllDraw() {
    this.setState({
      isDel: false,
      listItemSvg: [],
      shouldShowForm: false,
      zIndexSvg: hideSVGIndex,
      isLargeScreen: true,
    });
  }

  reset(isDelete) {
    if(!isDelete){
      this.setState({
        isDraw: false,
        isDel: false,
        listItemSvg: [],
        shouldShowForm: false,
        zIndexSvg: hideSVGIndex,
        isLargeScreen: true,
      });
    } else {
      this.setState({
        isDraw: false,
        isDel: false,
        shouldShowForm: false,
        isLargeScreen: true,
      },
      () => {
        this.setState(
          {
            canvasHeight: this.state.currentCanvasHeight,
            canvasWidth: this.state.currentCanvasWidth,
          },
          () => {
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");
            context.drawImage(
              this.player.video.video,
              0,
              0,
              this.state.canvasWidth,
              this.state.canvasHeight
            );
          }
        );
      });
    }
    this.ctx = this.canvas.getContext("2d");
    this.ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
  }

  disableScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  enableScroll() {
    window.onscroll = function() {};
  }

  onMouseDown(event, isMobile) {
    if (isMobile) {
      document.getElementById("animation-player").style["overflow"] = "hidden";
      document.body.style.overflow = "hidden";
    }
    if (!this.state.isDraw && !this.state.isDel) {
      this.setState({
        isDraw: true,
      });
      var newItem = {
        id: Date.now(),
        points: "",
        color: this.state.settingDraw.color,
        lineWidth: this.state.settingDraw.lineWidth,
        capturePoint: "",
      };
      if (!isMobile) {
        var [x, y] = this.mouseRelativeXYDraw(event);
        newItem.capturePoint = ` , ${y} ${x}`;
      } else {
        var [x, y] = this.touchRelativeXY(event);
        newItem.capturePoint = ` , ${y} ${x}`;
        this.disableScroll();
      }
      this.setState({
        listItemSvg: [...this.state.listItemSvg, newItem],
        currentItemSvg: newItem,
      });
    }
  }

  onMouseMove = (event, isMobile) => {
    if (this.state.isDraw) {
      let heightTopToAnimation = fetchHeightHeader()
      var item = document.getElementById(this.state.currentItemSvg.id);
      var points = item.getAttribute("points");
      var capturePoint = item.getAttribute("capturePoint");
      if (isMobile) {
        let xh = event.nativeEvent.layerX;
        let yh = event.nativeEvent.layerY
        xh = (xh * this.state.currentCanvasWidth / this.player.video.video.offsetWidth).toFixed()
        yh = (yh *  this.state.currentCanvasHeight / this.player.video.video.offsetHeight).toFixed()
        points += ` ${xh}, ${yh}`;
        var [x, y] = this.mouseRelativeXYDraw(event);
        capturePoint += ` ${x}, ${y}`;
      } else {
        var [x, y] = this.touchRelativeXY(event);
        capturePoint += ` ${x}, ${y}`;
        let xh = event.touches[0].pageX - document.getElementById("animation-player").offsetLeft
        let yh =
          event.touches[0].pageY -
          document.getElementById("animation-player").offsetTop - heightTopToAnimation
        xh = (xh * this.state.currentCanvasWidth / this.player.video.video.offsetWidth).toFixed()
        yh = (yh *  this.state.currentCanvasHeight / this.player.video.video.offsetHeight).toFixed()
        points += ` ${xh}, ${yh}`;
      }
      let itemInList = this.state.listItemSvg.find(
        (obj) => obj.id === this.state.currentItemSvg.id
      );
      if (itemInList) {
        itemInList.points = points;
        itemInList.capturePoint = capturePoint;
        itemInList.currentPoints = points;
        itemInList.currentCapturePoint = capturePoint;
      }
      item.setAttribute("points", points);
      item.setAttribute("capturePoint", capturePoint);
    }
  };

  onMouseUp() {
    document.body.style.overflow = "auto";
    const styleOverflow = this.state.shouldShowForm ? 'unset' : 'auto';
    document.getElementById("animation-player").style["overflow"] = styleOverflow;
    if (this.state.isDraw) {
      this.setState({
        isDraw: false,
      });
      this.enableScroll()
    }
  }

  onItemMouseOver(event) {
    const { mobile } = this.props;
    const polyWidht = event.target.getBBox().width;
    const polyHeight = event.target.getBBox().height;
    if (!this.state.isDraw && this.state.isDel) {
      if (!mobile) {
        event.target.setAttribute("stroke-dasharray", "5 5");
      }
      if (polyWidht <= 35 && polyHeight <= 35) {
        let id = event.target.getAttribute("id");
        let newLists = this.state.listItemSvg.filter((obj) => obj.id != id);
        this.setState({
          listItemSvg: newLists,
        });
      }
    }
  }

  onItemMouseLeave(event) {
    if (!this.state.isDraw && this.state.isDel) {
      event.target.setAttribute("stroke-dasharray", "");
    }
  }

  onDelete(event) {
    if (!this.state.isDraw && this.state.isDel) {
      let id = event.target.getAttribute("id");
      let newLists = this.state.listItemSvg.filter((obj) => obj.id != id);
      this.setState({
        listItemSvg: newLists,
      });
    }
  }

  setOption = () => {
    const { currentAnimationId, animationSelectCurrent } = this.props;
    if (currentAnimationId !== animationSelectCurrent) {
      this.fetchCurrentAnimation()
    }
  }

  handleOutsideClick = (e) => {
    if (!this.nodeModalVersion.contains(e.target)) {
      this.setOption();
    }
  };

  fetchCurrentAnimation = () => {
    const { setCurrentAnimation } = this.props;
    this.setState({ isShowModalNotCompareVersion: false }, () => setCurrentAnimation())
  }

  handleLoadedMetadata = (e) => {
    const duration = e.currentTarget.duration;
    this.setState({ totalDuration: duration });
  };

  closeAnimationMarkerForm = () => {
    this.setState({ 
      shouldShowForm: false,
      periodRange: null,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      currentShape: 'point',
      showPeriodRange: [],
    })
    if (this.props.isOpenNouislider) {
      this.props.updateIsOpenNouislider()
    }
  }

  setPeriodRangeWhenClickTimeLine = (periodRange) => {
    this.setState({ showPeriodRange: periodRange })
  }

  setShowPeriodRangeFromProp = memoize(
    (showPeriodRangeProp) => {
      this.setState({ showPeriodRange: showPeriodRangeProp })
    }
  );

  render() {
    const { src, downloadUrl, currentUserId, animationMarkerCreationPath, markers, markerCreatable,
            onClickDownloadButton, mobile, onMarkerUpdationCalled, updateMakerShow, role, owner, user, scaleImage,
            currentAnimation, animationSelectCurrent, currentAnimationId, onMarkerDeletionCalled, reloadMarkers, apiClient, duration,
            deleteReplyMarker, avatarUrl, isShowDrawComponent, isOpenNouislider, updateIsOpenNouislider, showPeriodRangeProp
          } = this.props;
    const { currentX, currentY, currentWidth, currentHeight, currentShape, shouldShowForm, currentTime, markersVisibility, isMarking,
            isLargeScreen, isShowControlBar, isShowModalNotCompareVersion, showPeriodRange } = this.state;
    this.setShowPeriodRangeFromProp(showPeriodRangeProp);
    const Overlay = this.overlayComponent;
    const encodeName = src.split('/').pop()
    const decodeName = decodeURI(decodeURI(encodeName));
    let src2 = `${src.replace(encodeName, decodeName)}?v=${timestamp}`
    return (
      <div className={mobile ? 'wrap-animation-preview-for-mobile-video' : ''}>
        <div id="wrap-center"
        className={`wrap-animation-preview ${getStyleOfSize(scaleImage,"wrap")}`}
        style={{minHeight:"100%"}}
        >
          <div
              id="animation-player"
              style={{ width: `${scaleImage}%` }}
              className={classnames("animation-player", { marking: isMarking },{'d-table mt-auto mb-auto':scaleImage && scaleImage <100})}
              ref={this.refCallback}
              onMouseEnter={this.showSeekBar}
              onMouseLeave={this.hintSeekBar}
            >
              <canvas
                id="canvas"
                ref={(ref) => (this.canvas = ref)}
                width={`${this.state.canvasWidth}px`}
                height={`${this.state.canvasHeight}px`}
                style={Object.assign({}, styles.canvas, {
                  zIndex: this.state.zIndexSvg,
                })}
              ></canvas>

              <div
                ref={(ref) => (this.drawSvg = ref)}
                className="draw-svg"
                id="draw-svg"
                style={{
                  zIndex: this.state.zIndexSvg,
                }}
                onMouseDown={(event) => this.onMouseDown(event, false)}
                onMouseMove={(event) => this.onMouseMove(event, true)}
                onMouseUp={(event) => this.onMouseUp(event, false)}
                onMouseLeave={() => this.onMouseUp()}
                onTouchStart={(event) => this.onMouseDown(event, true)}
                onTouchEnd={() => this.onMouseUp()}
                onTouchMove={(event) => this.onMouseMove(event, false)}
              >
                <svg
                  viewBox={`0 0 ${this.state.canvasWidth} ${this.state.canvasHeight}`}
                  className={
                    this.state.isDel
                      ? "cursor_eraser"
                      : `cursor_pencil_${this.state.settingDraw.color}`
                  }
                  width={`${this.state.canvasWidth}px`}
                  height={`${this.state.canvasHeight}px`}
                  ref={(ref) => (this.svg = ref)}
                  style={Object.assign({}, styles.canvas, {
                    zIndex: this.state.zIndexSvg,
                  })}
                >
                  {this.state.listItemSvg.map((item, i) => (
                    <PolyLineSvg
                      id={item.id}
                      points={item.points}
                      color={item.color}
                      lineWidth={item.lineWidth}
                      onItemMouseOver={(event) => this.onItemMouseOver(event)}
                      onItemMouseLeave={(event) => this.onItemMouseLeave(event)}
                      onDelete={(event) => this.onDelete(event)}
                    />
                  ))}
                </svg>
              </div>
            <Player
              src={src2} aspectRatio={isLargeScreen ? "16:9" : "auto"} ref={ref => this.player = ref}
              onTimeUpdate={this.onTimeUpdate.bind(this)}
              id="video"
              isFullscreen={true}
              crossOrigin="anonymous"
              onLoadedMetadata={this.handleLoadedMetadata}
              muted={true}
            >
              {
                <Overlay order="2" />
              }
              {
                isMarking && <MarkShape x={currentX} y={currentY} width={currentWidth} height={currentHeight} shape={currentShape} isCommentPeriod={isOpenNouislider} />
              }
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <SettingButton order={7} rates={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]}
                              isShowControlBar={isShowControlBar} />
                <VolumeMenuButton vertical order={2} />
              </ControlBar>
              <AnimationMarkersInPlayer
                markers={this.activeMarkers()}
                visibility={markersVisibility}
                order={11}
                onMarkerUpdationCalled={onMarkerUpdationCalled}
                image={this.player}
                updateMakerShow={updateMakerShow}
              />
              {
                shouldShowForm && (
                  <AnimationMarkerForm
                    ref={(ref) => (this.makerForm = ref)}
                    x={currentX} y={currentY} width={currentWidth} height={currentHeight} shape={currentShape}
                    time={currentTime} onSubmit={this.onSubmitMarkerForm}
                    onClickClose={this.closeAnimationMarkerForm} order={12}
                    mobile={mobile} shouldShowForm={shouldShowForm} currentUserId={currentUserId}
                    role={role} owner={owner} user={user}
                    scaleImage={scaleImage}
                    is_embed_video={false}
                    is_show_draw_component={true}
                    onlyBUAForCommentPeriod={isOpenNouislider}
                  />
                )
              }

              <Shortcut ref="shortcut" />
            </Player>

          </div>
          <CommentDetail
            selectedMarker={this.props.selectedMarker}
            updateSelectedMarker={this.props.updateSelectedMarker}
            onMarkerUpdationCalled={onMarkerUpdationCalled}
            currentUserId={currentUserId}
            owner={owner}
            role={role}
            animationSelectCurrent={animationSelectCurrent}
            currentAnimationId={currentAnimationId}
            onMarkerDeletionCalled={onMarkerDeletionCalled}
            reloadMarkers={reloadMarkers}
            apiClient={apiClient}
            markers={markers}
            deleteReplyMarker={deleteReplyMarker}
            avatarUrl={avatarUrl}
            shouldShowForm={shouldShowForm}
          />
          <div ref={nodeModalVersion => { this.nodeModalVersion = nodeModalVersion }}>
            <Modal isOpen={isShowModalNotCompareVersion}
                className="animation-uploader-modal modal-dialog-centered modal-not-compare-version"
                size="md" >
              <ModalBody>
                <div className='text-center text-black'>
                  <div className='font-size-17px font-weight-bold text-black text-center'>
                    <span>メディア画面とコメントツールの </span>
                    <span>バージョンが異なる為、赤入れできません。</span>
                  </div>
                  <Button className="btn btn-danger my-2 button-set-current-animation" onClick={this.fetchCurrentAnimation}>
                    表示中のメディア画面を赤入れする
                  </Button>
                  <span>
                    ※クリックするとコメントツールのバージョンが{currentAnimation.final ? '最終稿' : currentAnimation.title}に変わります。
                  </span>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      <div className="comment-timeline-container">
        <AnimationMarkerLine markers={markers}
          duration={this.state.totalDuration}
          player={this.player}
          isEmbedVideo={false}
          order={10}
          isShowDrawComponent={isShowDrawComponent}
          setPeriodRangeWhenClickTimeLine={this.setPeriodRangeWhenClickTimeLine}
        />
        <AnimationMarkerPeriod
          duration={this.state.totalDuration}
          player={this.player}
          isEmbedVideo={false}
          openPeriodCommentBox={this.openPeriod}
          isOpenNouislider={isOpenNouislider}
          updateIsOpenNouislider={updateIsOpenNouislider}
          showPeriodRange={showPeriodRange}
        ></AnimationMarkerPeriod>
      </div>
    </div>
    )
  }
}
