const honorifics = "さん\n"
export const allowedExtensions = [
  "jpg", "jpeg", "gif", "png", "psd", "ai", "csv", "doc", "docx", "log", "odt", "pdf", "xls", "xlsx", "ods",
  "ppt", "pptx", "txt", "zip", "mp3", "pptm", "xps", "thmx", "ppsx", "ppsm", "pps", "asf", "avi", "mp4", "m4v",
  "mov", "flv", "mpg", "mpeg", "wmv", "tiff", "eps", "indd", "raw", "rtf", "wps", "xlr", "draw", "ait", "potx",
  "potm", "pot", "pem", "crt", "key", "p7b", "p7c", "der", "cer", "pfx", "p12", "svg", "wav"
]

export function removeChatItem(message) {
  const chatItem = $(`.chat-list-item[data-id=${message.id}]`)
  if (chatItem) {
    chatItem.remove()
  }
}

export function updateContentChatItem(data, listClass) {
  let message = data.message;
  const chatItem = $(`.chat-list-item[data-id=${message.id}]`)
  if (chatItem) {
    chatItem.html(data.html_message);
    if (!chatItem.find('.base-date-update span.date img').length) {
      chatItem.find('.base-date-update span.date').prepend('<img src="/assets/pencil-170fe72f3c702147552a76bce4682ee6e57b5bb77dda1ea47f2d579bfed4da89.png" width="9" height="12"></img>')
    }
    chatItem.find('.base-date-update span.date_updated').remove()
    chatItem.find('.base-date-update').prepend(`
      <span class="date_updated">
        投稿： ${message.created_at}
        <br>
        編集： ${message.updated_at}
      </span>
      `
    )
    const lastMessageEle = $('body').find(`${listClass} .chat-list-item.base-list:last`)
    addClassUnread(listClass)
    checkPermissionShowEditOrDelete(listClass, data.message)
    hanldeReadMessage(data.message.group, lastMessageEle)
    _scrollToBottom(listClass)
  }
}

export function renderAndScrollBottomChatList(data, listClass) {
  if ( $('body').find(`${listClass} .chat-list-item.base-list`).length > 0) {
    $('body').find(`${listClass} .chat-list-item.base-list:last`).after(data.html_message);
  } else {
    $(`${listClass}`).append(data.html_message);
  }
  const lastMessageEle = $('body').find(`${listClass} .chat-list-item.base-list:last`)
  addClassUnread(listClass)
  checkPermissionShowEditOrDelete(listClass, data.message)
  hanldeReadMessage(data.message.group, lastMessageEle)
  _scrollToBottom(listClass)
}

export function addClassUnread(listClass) {
  const lastMessageEle = $('body').find(`${listClass} .chat-list-item.base-list:last`).find('.chat-details')
  const user_id = lastMessageEle.data('user_id');
  if (gon.shared.current_user.id == Number(user_id) || lastMessageEle.hasClass('chat-read-by-manual')) return

  lastMessageEle.find('.btn-unread-new').removeClass('d-none');
  lastMessageEle.find('.btn-delete-chat').addClass('d-none');
  lastMessageEle.find('.btn-edit-chat').addClass('d-none');
}

export function sentMessage(bodyChat) {
  $(`${bodyChat} .send-message`).on("click", function(e) {
    Chat.toggleResultMessage($(this), false);
    e.preventDefault()
    tinyMCE.triggerSave();
    Chat.appendToInContentChat($(this).parents('form'))
    sendChatMessage(bodyChat)
    removeAllReplyDisabled(e.target)
    $(this).parents('.chat-box').css('height', '');
    $('.chat-messages.body-chat-message').css('height', '');
    if ($(this).parents('.chat-newstyle').hasClass('chat-type-Work')) {
      $(this).attr('is-send', true)
    }
  });
}

export function scrollToEndListChat(chat_id) {
  const url = new URL(window.location.href)
  const messageId = url.searchParams.get('show_msg_id')
  const messageEle = $(chat_id).find(`#show-message-${messageId}`)
  if (messageId && messageEle) {
    let scroll = setTimeout(() => {
      messageEle[0].scrollIntoView();
    }, 100)
  } else {
    $(chat_id).animate({ scrollTop: $(chat_id).prop("scrollHeight") }, 200)
  }
}

export function setOffset(class_list, group_type, offset_normal, offset_admins) {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams
  const showMsgId = searchParams.get("show_msg_id");
  const groupNormal = 'normal'

  if (showMsgId) { return $(class_list).find('.chat-list-item.base-list').length }
  return group_type == groupNormal ? offset_normal : offset_admins
}

export function onClickOpenChat(chatBody, group) {
  $(`a[href="${chatBody}"]`).on('click', function() {
    setTimeout(function() {
      scrollToEndListChat(chatBody);
      ajaxReadMesssage(group, chatBody);
    }, 500)
  })

  // MessageKeep.renderMessage('.chat-box')
}

export function dragAndDropFiles(e, bodyClass) {
  e.preventDefault();
  $(`${bodyClass} .container-name-files .new_file_attachment`).empty();
  let files = e.target.files || (e.dataTransfer ? e.dataTransfer.files : e.originalEvent.dataTransfer.files);
  files = addOldFileToDragAndDropFiles(files, $(`${bodyClass} .attach_file_chat_project`))
  const filesLength = files && files.length

  if (filesLength > 0) {
    if (check_size_files(files)) {
      $(`${bodyClass} p.note-file-upload`).css('color', 'red')
    } else {
      $(`${bodyClass} p.note-file-upload`).css('color', '#686c71')
      $(`${bodyClass} .attach_file_chat_project`).prop("files", files);
      const containerName = $(`${bodyClass} .container-name-files`)

      for (let i = 0; i < filesLength; i++) {
        iconDeleteFile(containerName, files[i].name, i)
      }
    }
  }
}

export function checkAcceptExtensionFile(files, filesLength, allowedExtensions) {
  let allowedUpload = true;
  for (let i = 0; i < filesLength; ++i) {
    let file_extension = files[i].name.substr(files[i].name.lastIndexOf('.') + 1).toLowerCase();
    if ($.inArray(file_extension, allowedExtensions) < 0) {
      allowedUpload = false;
      break;
    }
  }

  return allowedUpload;
}

export function deleteMessageFailed() {
  $('body').on('ajax:error', '.chat-details .menu-button.delete', () => {
    toastr.error('このメッセージに対しては本アクションを実行できません。', '', { closeButton: true, progressBar: true });
  })
}

export function deleteMessageSuccess() {
  $('body').on('ajax:success', '.chat-details .menu-button.delete', (e) => {
    $(e.originalEvent.target).parents('.chat-list-item.my-message').remove()
  })
}

export function handlerEditMessage() {
  $('body').on('click', '.menu-button.edit', (e) => {
    const element = $(e.originalEvent.target)
    const messageId = element.parents('.chat-list-item.my-message').attr('data-id')
    const content = element.parents('.chat-details').find('div.base.content').attr('raw-content')
    const form = element.parents('.tab-pane.fade.show.active').find('form')
    const files = element.parents('.chat-details').find('.file-attach-of-message').find('a.message__attachments')
    const containerFileName = form.find('.container-name-files');
    tinymce.get('message_content').setContent(content);
    form.attr('action', `/messages/${messageId}`);
    form.append('<input type="hidden" name="_method" value="PUT">');
    _removeInputDeleteAttachment();
    form.append('<input type="hidden" name="delete_attachment_chat_ids" value="">');
    _showAttachmentEdit(files, containerFileName);
  })
}

export function handleQuoteMessage(action = 'quote') {
  $('body').on('click', `.menu-button.${action}`, (e) => {
    let textAction = action;
    if (textAction === 'quote') textAction = I18n.t('message.quote')
    if (textAction === 'reply') textAction = I18n.t('message.reply')
    const element = $(e.originalEvent.target)
    const messageId = element.parents('.chat-list-item').attr('data-id')
    const senderName = element.parents('.chat-list-item').attr('data-sender-name')
    const content = element.parents('.chat-details').find('p.content').text()
    const form = element.parents('.tab-pane.fade.show.active').find('form')

    form.append(`<input type="hidden" name="message[parent_id]" value="${messageId}">`);
    form.append(`<input type="hidden" name="message[status]" value="${action}">`);

    const textAre = form.find('textarea');
    let messageContent = textAre.val()
    let newText = `[${textAction}:${messageId}]`;
    const userSenderId = element.parents('div.menu_chat').attr('sender')
    if (action === 'reply') {
      addReplyToChatTo(element, userSenderId)
      disabledAllReply(element)
    }
    if (action !== 'reply') { newText = `[${textAction}:${messageId}]${senderName}${honorifics}`; }
    const newTextChat = Chat.handleContentChat(messageContent, action, newText)
    const currentMessage = tinymce.get('message_content').getContent().replaceAll('<div>', '').replace('</div>', '')
    tinymce.get('message_content').setContent(`${newTextChat}${currentMessage}`)
  })
}

function disabledAllReply(ele) {
  const allMessEle = $(ele).parents('div.chat-messages')
  allMessEle.find('a.reply').addClass('disabled')
}

export function removeAllReplyDisabled(ele) {
  const allMessEle = $(ele).parents('div.body-chat-area').find('div.chat-messages')
  allMessEle.find('a.reply').removeClass('disabled')
}

function addReplyToChatTo(ele, userId) {
  const formPopupChat =  $(ele).parents('div.body-chat-area').find('form.new_message')
  const elementPopupUser = formPopupChat.find('.chat-users')
  const divEle = elementPopupUser.find(`p[user_id="${userId}"]`).parents('div.js-chat-user')
  $(divEle).trigger('click')
  elementPopupUser.find('a[onclick="Chat.aprovalUsers(this)"]').trigger('click')
  const eleSendMessage = formPopupChat.find('ul.view_box_lists')
  const userELe = eleSendMessage.find(`li[onclick="Chat.removeUserChat(this, '${userId}')"]`)
  userELe.css('background-image', 'none')
  userELe.attr('not-remove', 'true')
}

export function closeQuote() {
  $('body').on('click', 'a.close.quote', (e) => {
    e.preventDefault();
    const element = $(e.originalEvent.target)
    const form = element.parents('.tab-pane.fade.show.active').find('form')
    clearFormQuateOrReply(form)
  })
}

export function showSearchUser(formChat) {
  $(`${formChat} a.search-user`).on('click', function(e) {
    e.preventDefault()
    const element = $(e.originalEvent.target)
    const form = element.parents('.tab-pane.fade.show.active').find('form')
    const select2 = form.find('.select2.select2-container')

    if (select2.hasClass('show_tag_user-select2')) {
      showTagUser(form)
      clearSelect(form)
      select2.removeClass('show_tag_user-select2')
    } else {
      select2.addClass('show_tag_user-select2')
      form.find(".tag_users-select2").select2("open");
    }
  })
}

export function ajaxReadMesssage(group, classList, idInfoChannel='#js-tab_chat') {
  // #hanlde order id
  setTimeout(function() {
    const entity_type = $('body').find(idInfoChannel).attr('data-channel-type')
    if (entity_type) {
      const entity_id = $('body').find(idInfoChannel).attr('data-channel-id')
      const data = {
        msg_source_id: entity_id,
        msg_source_type: entity_type,
        group: group,
        class_list: `chat-list-${group}`
      }
      Shared.callAjax(Routes.readed_messages_path(), data, 'json', 'get', false).done(function(data) {
        $(document).find(`idInfoChannel a[href="${classList}"] span.number-unread-message`).remove()
        removeItemUnread(data)
      })
    }
  }, 3500)
}

function removeItemUnread(data) {
  if (data.message_ids.length === 0) {
    $('body').find('.chat-details').toArray().forEach(chatDetail => {
      if ($(chatDetail).hasClass('chat-read-by-manual')) return
      $(chatDetail).find('.btn-unread-new').addClass('d-none')
      $(chatDetail).find('.chat-details').removeClass('unread_on')
    })
    return
  }
  data.message_ids.forEach(id => {
    if ($(`#show-message-${id}`) && !$(`#show-message-${id}`).find('.chat-details').hasClass('chat-read-by-manual')) {
      $(`#show-message-${id}`).find('.btn-unread-new').addClass('d-none')
      $(`#show-message-${id}`).find('.chat-details').removeClass('unread_on')
      $(`#show-message-${id}`).removeClass('unread-message')
    }
  })
  $(`#show-message-${data.message_ids[0]}`).parents('.chat-outer').find('.number-unread-message').text(data.number_unread)
}

export function checkActionByTime() {
  const currentTime = (new Date).getTime()

  $('body').find('.data-time').each(function() {
    let date = $(this).attr('data-time')
    if (date) {
      date = new Date(date).getTime() + 1000*60*10
      if (date <= currentTime) {
        $(this).parent().find('.menu-button.edit, .menu-button.delete').remove()
      }
    }
  })
}

export function iconDeleteFile(containerFile, name, indexFile, fileId = null) {
  var newFileClass = fileId ? 'file_already_exists' : 'new_file_attachment';
  containerFile.append(
    `<div class="body-quote ${newFileClass}">
      ${name}
      <a class="close attachment " data-index="${indexFile}" data-file-id="${fileId}" href="#!"></a>
    </div>`
  )
}

export function setHeightFileName(files, containerName, bodyList) {
}

function check_size_files(files) {
  for (var i = 0; i < files.length; i++) {
    if (files[i].size/1024/1024 > gon.shared.max_upload_file_size) {
      return true;
    }
  }
  return false;
}

function sendChatMessage(bodyChat) {
  const body = $(bodyChat)
  const chatComponent = $(body).closest('.chat-outer');
  const form = body.find('form.chat-form')
  const url = `${location.pathname}?${chatComponent.find('.copy_link').val().split('?')[1]}`;
  form.find('#url_user_message').remove();
  form.append(`<input type="hidden" id="url_user_message" name="url_message" value="${url}">`)

  const message = body.find('.message_content').val()
  const attachFiles = body.find('.attach_file_chat_project').val()

  if ((message == undefined || message.trim().length == 0) && !attachFiles) {
    return;
  }
  const id_form = `${bodyChat} form.chat-form`

  const dataForm = new FormData($(id_form)[0]);
  const urlData = $(id_form).attr('action')
  Shared.callAjax(urlData, dataForm, 'json', 'post', false, true).done(function(data) {
    body.find('.message_content').val('');
    tinymce.get('message_content').setContent('')
    body.find('.attach_file_chat_project').val('');
    body.find('.container-name-files').empty();
    body.find('.receiver_list').text('');
    _removeInputDeleteAttachment();
    MessageKeep.removeMessage('.send-message')
    clearFormEdit(id_form)
    clearFormQuateOrReply(id_form)
  }).fail(function(data) {
    Popup.alert(data.responseJSON.errors.join(''))
  })
}

function clearFormEdit(id_form) {
  $(id_form).attr('action', '/messages');
  $(id_form).find('input[name="_method"]').remove()
  $(id_form).find('#message_work_check_list_id').val('')
  $(id_form).find('#message_message_check_list_type').val('')
}

function clearFormQuateOrReply(id_form) {
  $(id_form).find('input[name="message[parent_id]"]').remove()
  $(id_form).find('input[name="message[status]"]').remove()
  $(id_form).find('p.quote').removeClass('show')
  $(id_form).find('a.close.quote').removeClass('show')
  $(id_form).find('p.quote').text('')
  $(id_form).find('p.quote').append('<a class="close quote" href="#!">x</a>')
  clearSelect($(id_form))
}


function checkPermissionShowEditOrDelete(listClass, message) {
  const uid = $(listClass).attr("data-user-id")
  if (parseInt(uid) != parseInt(message.sender_id)) {
    const element = $(`${listClass} .chat-list-item.my-message:last`)
    element.find('a.edit').remove()
    element.find('a.delete').remove()
  }
}

function hanldeReadMessage(group, lastMessageEle) {
  const classBox = lastMessageEle.closest('.chat-messages').attr('id')
  const dataChanelType = lastMessageEle.find('.chat-details').attr('data-channel-type');
  if (parseInt($('body').find(`#${classBox}`).css('width')) > 300) {
    if (dataChanelType === 'NewProject')
      if (group == 'normal') {
        ajaxReadMesssage('normal', '#chat-list-normal');
      } else {
        ajaxReadMesssage('admins', '#chat-list-admins');
      }
    else {
      ajaxReadMesssage('normal', '#list_chat', '#js-tab_chat-popup');
    }
  }
}

function clearSelect(form) {
  form.find('.select2.select2-container').removeClass('show')
  form.find('ul.select2-selection__rendered').empty()
  form.find('select[name="message[tag_users][]"]').val('')
}

export function showTagUser(form) {
  // const listLine = form.find('.list-inline').find('.receiver_list');

  const textare = form.find('textarea');
  let messageContent = textare.val()
  let newText = ''
  $.each(form.find('select').children("option").filter(":selected"), function() {
    newText += `[To:${$(this).val()}]${$(this).text()}${honorifics}`
  })
  const newTextChat = Chat.handleContentChat(messageContent, 'To', newText)
  textare.val(newTextChat)
}

export function showErrorOverFileSize(input) {
  toastr.error(I18n.t('shared.max_upload_file_size'), "", { closeButton: true, progressBar: false });
  const notFileUploadEle = $(input).parents('form').find('.note-file-upload');
  notFileUploadEle.addClass('color-red')
  setTimeout(() => { notFileUploadEle.removeClass('color-red') }, 5000)
}

function addOldFileToDragAndDropFiles(files, element) {
  let input = $(element)[0]
  let dt = new DataTransfer()
  let allowedUpload = checkAcceptExtensionFile(files, files.length, allowedExtensions);

  const oldFiles = input.files
  let totalLength = oldFiles.length + files.length + $('.container-name-files .file_already_exists').length

  if (oldFiles) {
    $.each(oldFiles, function(k, file) {
      dt.items.add(file)
    })
  }
  if (!allowedUpload) {
    toastr.error('ファイル形式が正しくありません。', "", {
      closeButton: true,
      progressBar: true,
    });
  } else if (totalLength > 10) {
    toastr.error('ファイル数は10個以内となります。', "", {
      closeButton: true,
      progressBar: true,
    });
  } else if (check_size_files(files)) {
    showErrorOverFileSize(input)
  } else {
    $.each(files, function(k, file) {
      dt.items.add(file)
    })
  }

  if (dt.files.length > 0) {
    input.files = dt.files
  } else {
    input.val('')
  }

  return input.files
}

function _scrollToBottom(listClass) {
  feather.replace();
  $(listClass).animate({
    scrollTop: $(listClass).prop("scrollHeight")
  }, 1000)
}

function _removeInputDeleteAttachment() {
  $('input[name="delete_attachment_chat_ids"]').remove();
}

function _showAttachmentEdit(files, containerFileName) {
  containerFileName.empty();
  for (let i = 0; i < files.length; i++) {
    let file = $(files[i]);
    let fileUrl = file.attr('href');
    let fileId = file.attr('data-id');
    let fileName = decodeURI(fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length));
    iconDeleteFile(containerFileName, fileName, i, fileId)
  }
}
