export default class ActionInvalid {
  openChatNewTab() {
    Popup.confirm('', {
      description: I18n.t('shared.invalid_action'),
      iconHtml: '<i class="fas fa-info"></i>',
      confirmText: 'OK',
      width: '20em',
      cancelBtn: 'false',
      success: () => {
      }
    })
  }
}
