// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.


export const PATTERN_URL = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
export const EMAIL_REGEX = /(\b[^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/gim;
export const PHONE_REGEX = /(\b\d{10}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/gim;

import { createConsumer } from "@rails/actioncable"

export default createConsumer()
