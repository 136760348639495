export default class DetailInvoice {
  ENTER_KEYCODE = 13;
  show(event, element, showModal, url = '', id) {
    let tagName = $(event.target).prop("tagName")
    if ( (tagName === 'A' && !$(element).hasClass('page-link')) || tagName === 'INPUT')
      return
    event.preventDefault()
    let item_each_page = tagName === 'TD' ? 10 : $('#item_each_page').val()
    let data = url && showModal ? { item_each_page: item_each_page } : {}
    url = url || element.href
    if (!showModal) {
      let params = $('#search_invoice_works').serializeArray()
      params.forEach(function(obj){
        data[obj.name] = obj.value
      })
      if (data['q[updated_at_lteq]'])
        data['q[updated_at_lteq]'] = DetailInvoice._changeToEndOfDate(data['q[updated_at_lteq]'])
    }

    let urlDetail = null;
    if ($(element).attr('data-id')) {

      urlDetail = Routes.owner_show_invoices_path($(element).attr('data-id'));
    } else {
      window.history.pushState("", "", "/documents/submission/payment/" + id);
    }

    var currentUrl = window.location.pathname;

    if (urlDetail && currentUrl.includes("documents/submission/sale/invoice")) {

      window.history.pushState('', '', urlDetail);

      $("#modal_detail_invoice").on("hidden.bs.modal", function () {

        const url = Routes.owner_show_invoices_path($(element).attr('invoive-id'));
        window.history.pushState('', '', url);
      });
    }

    DetailInvoice._callAjax(data, '#modal_detail_invoice', showModal, url)
  }

  showOrders(event, element, showModal, url = '', id) {
    let tagName = $(event.target).prop("tagName")
    if ( tagName === 'INPUT' || tagName === 'IMG')
      return
    event.preventDefault()
    let item_each_page = tagName === 'TD' ? 10 : $('#item_each_page').val()
    let data = url && showModal ? { item_each_page: item_each_page } : {}
    url = url || element.href
    data.action_invoice = $(element).attr('action_invoice')

    let urlDetail = null;
    if ($(element).attr('data-id')) {

      urlDetail = Routes.owner_show_invoices_path($(element).attr('data-id'));
    } else {
      window.history.pushState("", "", "/documents/submission/payment/" + id);
    }

    if (!showModal)
      data = $('#search_invoice_childs').serialize() + '&' + $.param(data);

    var currentUrl = window.location.pathname;
    if (urlDetail && currentUrl == Routes.owner_list_invoices_path()) {

      window.history.pushState('', '', urlDetail);
    }
    DetailInvoice._callAjax(data, '#modal_invoice_orders', showModal, url)
  }


  static _callAjax(data, modal, showModal, url) {
    let callAjax = Shared.callAjax(url, data, 'html', 'GET')
    callAjax.done((res) => {
      $(modal).html(res)
      defaultAction.runDatepicker()
      if (showModal) $(modal).modal('show')
      if ($('.js-show_invoice_ticket_search_form').attr('checked_on') === 'true') {
        $('.js-show_invoice_ticket_search_form').trigger('click')
      }
    })
  }

  confirmInvoice(element, invoiceDate, invoiceType, invoiceId, action_invoice, invoiceText) {
    Popup.confirm('', {
      description: I18n.t('invoice.confirm_accept', {
        invoiceText: invoiceText, invoiceId: invoiceId, invoiceDate: invoiceDate
      }),
      confirmText: 'OK',
      width: 'auto',
      success: () => {
        const url = Routes.confirmed_owner_invoice_path(invoiceId);
        const data = {
          'action_invoice': action_invoice
        };
        const callAjax = Shared.callAjax(url, data, 'html');
        callAjax.done(function(data) {
          location.reload();
        })
      },
    })
  }

  emancipate(element, id, invoiceDate, invoiceText){
    Popup.confirm('', {
      description: I18n.t('invoice.confirm_emancipate', {invoiceText: invoiceText, id: id, invoiceDate: invoiceDate}),
      confirmText: 'OK',
      width: 'auto',
      success: () => {
        const form = $(element).parents('form.emancipate-invoice')
        const data = form.serialize()
        Shared.callAjax(form.attr('action'), data).done(function(response) {
          location.reload();
        })
      }
    })
  }

  showInvoicePayment(element) {
    const trSelected = $(element).parents('tr');
    const tbodySelected = trSelected.parents('tbody')
    if (trSelected.hasClass('invoice-parent')) {
      if (tbodySelected.next().find('.show-invoice-payment').length > 0) {
        if ($(element).is(':checked')) {
          tbodySelected.next().find('.show-invoice-payment').removeClass('d-none');
        } else {
          tbodySelected.next().find('.show-invoice-payment').addClass('d-none');
          tbodySelected.next().find('.show-invoice-payment:last')
                              .find('.invoice__table--checkbox').prop('checked', false)
          tbodySelected.next().find('.invoice__table--new').remove();
        }
      } else {
        this._addInvoicePayment(element);
      }
    } else {
      this._addInvoicePayment(element)
    }
  }

  showInvoicePaymentSupplier(element) {
    const ths = $(element);
    if(ths.prop('checked')){
      ths.closest('.invoice__payment--tbody').next('.invoice__payment--slide')
                                             .find('tr.show-invoice-payment')
                                             .removeClass('d-none').find('th, div').slideDown();
      ths.closest('.invoice__payment--tbody').next('.invoice__payment--slide').find('td, th').addClass('check_border')
    }else{
      ths.closest('.invoice__payment--tbody').next('.invoice__payment--slide')
                                             .find('tr.show-invoice-payment')
                                             .addClass('d-none').find('th, div').slideUp();
      ths.closest('.invoice__payment--tbody').next('.invoice__payment--slide').find('td, th').removeClass('check_border');
    }
  }

  cancelSubmit(event) {
    var code = event.keyCode ? event.keyCode : event.which;
    switch(code) {
      case this.ENTER_KEYCODE:
        event.preventDefault();
        event.stopPropagation();
        return;
    }
  }

  async _addInvoicePayment(element) {
    var index = $(element).attr('index');
    if ($(element).is(':checked')) {
      var data_clone = $('tbody[index="' + index + '"]').find('.invoice__payment--demo').first().clone();
      data_clone.removeClass('d-none');
      data_clone.find('input').removeAttr('disabled').attr('required');
      data_clone.addClass('invoice__table--new');
      var count = $('.invoice__table--new').length;
      data_clone.attr('new__index', count);
      $('tbody[index="' + index + '"]').append(data_clone);
      defaultAction.runDatepicker();
    } else {
      var current_new_index = $(element).parents('.invoice__table--new').attr('new__index');
      if (current_new_index != undefined) {
        var trs = $('tbody[index="' + index + '"]').find('.invoice__table--new');
        trs.each(function(index, tr) {
          if (parseInt($(tr).attr('new__index')) > parseInt(current_new_index)) {
            $(tr).remove();
          }
        })
      } else {
        $('tbody[index="' + index + '"]').find('.invoice__table--new').remove();
      }
    }
  }

  _checkBalanceAmountPositive(invoiceId) {
    return Shared.callAjax(Routes.check_blance_amount_owner_invoice_path(invoiceId))
  }

  static _changeToEndOfDate(dateString) {
    let date = new Date(dateString);
    date.setHours(23,59,59,999)
    return date.toLocaleString('ja')
  }
}
