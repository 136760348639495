export default class DashboardToggle {
  toggleProject() {
    $('.project-dashboard').toggleClass('d-none');
  }

  toggleQuotation() {
    $('.quotation-dashboard').toggleClass('d-none');
  }

  toggleTicket() {
    $('.ticket-dashboard').toggleClass('d-none');
  }

  togglePartner() {
    $('.partner-dashboard').toggleClass('d-none');
  }

  toggleSupplier() {
    $('.supplier-dashboard').toggleClass('d-none');
  }
}
