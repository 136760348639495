import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Menu from './Menu';

const propTypes = {
  inline: PropTypes.bool,
  items: PropTypes.array,
  className: PropTypes.string,
  onSelectItem: PropTypes.func,
  onClickBack: PropTypes.func,
  children: PropTypes.any,
  selectedIndex: PropTypes.number
};

export default class MenuSpeedOption extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, index) {
    const { onSelectItem } = this.props;
    onSelectItem(index);
  }

  render() {
    const { items, onClickBack } = this.props;
    return (
      <Menu onClickBack={onClickBack}>
        {items.map((item, i) => (
          <li onClick={(event) => this.handleClick(event, i)}>{item.label}</li>
        ))}
      </Menu>
    );
  }
}

MenuSpeedOption.propTypes = propTypes;
MenuSpeedOption.displayName = 'MenuSpeedOption';
