export default class TotalInfo {

  disabledInput(element, submitBtn, opacityElement){
    let isChecked = $(element).is(':checked')

    this.toggleBtnToggleModal(isChecked);
    $(submitBtn).attr('disabled', !isChecked);
    $(opacityElement).toggleClass('opacity-0_5')
  }

  toggleBtnToggleModal(isOpen = true){
    $('.disabled-toggle').attr('data-toggle', isOpen ? 'modal' : '')
  }
}
