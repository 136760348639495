export default class MessageKeep {
  renderMessage(elements) {
    $(elements).toArray().forEach(function(ele) {
      let typeChat = $(ele).find('input[name="message[msg_source_type]"]').val()
      let groupID = $(ele).find('input[name="message[group]"]').val()
      let chatID = $(ele).find('input[name="message[msg_source_id]"]').val()
      let room = `${typeChat}-${chatID}-${groupID}`
      let message = localStorage.getItem(room);
      if (message && $(ele).find('#message_content').val() === '') {
        setTimeout(function() {
          tinymce.get('message_content').setContent(message)
        }, 1000)
      }
    })
  }

  keepMessage(elements) {
    $(elements).toArray().forEach(function(ele) {
      tinyMCE.triggerSave();
      let typeChat = $(ele).find('input[name="message[msg_source_type]"]').val()
      let groupID = $(ele).find('input[name="message[group]"]').val()
      let chatID = $(ele).find('input[name="message[msg_source_id]"]').val()
      let chatBox = `${typeChat}-${chatID}-${groupID}`
      let newMessage = $(ele).find('#message_content').val()
      if (newMessage !== '') {
        localStorage.setItem(chatBox, newMessage);
      } else {
        localStorage.removeItem(chatBox)
      }
    })
  }

  removeMessage(element) {
    let typeChat = $(element).parents('.chat-box').find('input[name="message[msg_source_type]"]').val()
    let groupID = $(element).parents('.chat-box').find('input[name="message[group]"]').val()
    let chatID = $(element).parents('.chat-box').find('input[name="message[msg_source_id]"]').val()
    let chatBox = `${typeChat}-${chatID}-${groupID}`
    localStorage.removeItem(chatBox)
    tinymce.get('message_content').setContent('')
  }
}
