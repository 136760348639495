import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import reactWindowSize from 'react-window-size';

class AnimationNotify extends Component {
  constructor() {
    super();
    const displayed = localStorage.getItem('displayed') ? true : false;
    this.state = {
      shouldShowNotifyModal: !displayed,
      checkedBox: false
    }
  }
  toggle = bool => name =>
    this.setState({ [`shouldShow${name}`]: bool })
  open = this.toggle(true)
  close = this.toggle(false)
  setOnce = () => {
    this.setState({ [`checkedBox`]: true });
  }
  onClickSet = () => {
    const { currentUserId } = this.props;
    const { checkedBox } = this.state;
    const temp = localStorage.getItem('displayed');
    if (temp == null && checkedBox) {
      localStorage.setItem('displayed', true);
    }
    this.setState({ [`shouldShowNotifyModal`]: false });
  }
  detectmob() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPadPro/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }
  renderMobile() {
    const { shouldShowNotifyModal, checkedBox } = this.state;
    return (
      <Modal id="modal-show-notify-mobile" className="registration-modal modal-lg" isOpen={shouldShowNotifyModal}>
        <ModalHeader >
          注意
        </ModalHeader>
        <ModalBody>
          <div className="box not-registration notifi-mobile">
            <b>〇切替機能について</b><br />
            画面左下の「グレー」ボタンを押して「青」に変更<br />
            すると画面スクロールが可能になります。もう一度<br />
            押して「グレー」に戻すと赤入れが可能になります。<br />
            （URLもしくは画像の場合のみ）<br />
            <br />
            <b>〇ご利用versionについて</b><br />
            PC以外でご利用頂く場合、iOS12.4以前/android6.0<br />
            以前については、修正箇所を赤枠で囲う機能及び<br />
            スクロール機能がご利用できません。ご了承ください。<br />
            <br />
            <b>〇動作保証</b><br />
            iPhone及びiPadでは、一部のMP4形式の動画の動作<br />
            保証がされておりません。
          </div>
          <div className="text-center mb-3">
            <label htmlFor="" className='check-box-notify-mobile'>
              <input type='checkbox' onChange={this.setOnce} />
              &nbsp; 次回から表示させない
            </label>
            <br/>
            <Button color="dark-new" size="lg" onClick={this.onClickSet}>OK</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };
  render() {
    var modalNotify = '';
    if (this.detectmob()) {
      modalNotify = this.renderMobile();
    }
    return modalNotify
  }
}

export default reactWindowSize(AnimationNotify);