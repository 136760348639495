import React, { Component } from 'react';
import moment from 'moment';
import ellipsize from 'ellipsize';

const projectLink = ({ id, name }) => {
  return <a href={`/projects/${id}`} target="_blank">{name}</a>;
};

const animationLink = (project, { id, title }) => {
  return <a href={`/projects/${project.id}?animation_id=${id}`} target="_blank">Ver{title}</a>;
};

const generators = {
  'Comment.create': ({ owner, project, detail: { commentable: animation }, subject }) => {
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(project)}」の{animationLink(project, animation)}にコメント「
        {ellipsize(subject.body, 15)}
        」を投稿しました
      </span>
    );
  },
  'Animation.create': ({ owner, project, subject }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(project)}」に{animationLink(project, subject)}を追加しました
      </span>
    );
  },
  'Animation.finalize': ({ owner, project, subject }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(project)}」の{animationLink(project, subject)}を完成にしました
      </span>
    );
  },
  'Project.destroy_animation': ({ owner, subject, detail: { animation } }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(subject)}」のVer{animation.title}を削除しました
      </span>
    );
  },
  'Project.update_name': ({ owner, subject, detail: { prev_name: prevName } }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink({ ...subject, name: prevName })}」を「{projectLink(subject)}」に変更しました
      </span>
    );
  },
  'Project.update_description': ({ owner, subject }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(subject)}」の概要を変更しました
      </span>
    );
  },
  'Assignment.create': ({ owner, project }) =>{
    return (
      <span className="activity-item-body">
        {owner.name}さんが「{projectLink(project)}」に参加しました
      </span>
    );
  },
};

export default class ActivityItem extends Component {
  body() {
    const { activity } = this.props;
    const { subject_type: subjectType, key } = activity;
    return generators[`${subjectType}.${key}`](activity);
  }
  render() {
    const { activity: { created_at: createdAt } } = this.props;
    return (
      <div className="activity-item py-2">
        {this.body()}
        <div className="activity-item-datetime text-muted small">
          {moment(createdAt).fromNow()}
        </div>
      </div>
    );
  }
}
