import Swal from 'sweetalert2'
const OPTION = {
  customClass: {
    confirmButton: `btn ${gon.is_plan_akapon ? 'btn-danger' :'btn-primary'} mx-1 font-size-button-popup`,
    cancelButton: 'btn btn-secondary mx-1 font-size-button-popup',
    popup: 'new_alert_popup',
  },
  buttonsStyling: false
}
const swalWithBootstrapButtons = Swal.mixin(OPTION)

export default class Popup {
  alert(content, icon = '', title = '', options = {}) {
    options.popupCustomClass ||= null
    options.timer ||= null
    if (options.kind && options.kind == 'akaire') {
      options.customClass = {
        popup: 'akaire_alert_popup'
      }
    }
    const customOption = {}
    Object.keys(OPTION).forEach(
      key => customOption[key] = typeof OPTION[key] === 'object' ? Object.assign(OPTION[key], customOption[key] || null) : OPTION[key]
    );

    const swalCustom = Swal.mixin(customOption)
    swalCustom.fire({
      title: title,
      html: content,
      icon: icon,
      width: 'auto',
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      ...options
    })
  }

  notice(text, status = 'success') {
    swalWithBootstrapButtons.fire({
      text,
      icon: status
    })
  }

  popupNotify(text) {
    Swal.fire({
      position: 'top-end',
      title: `<span style="color: black; font-size: 20px">${text}</span>`,
      showConfirmButton: false,
      timer: 2000,
    })
  }

  confirm(text, options = {}) {
    options.confirmText ||= I18n.t('shared.confirm')
    options.cancelText ||= I18n.t('shared.cancel')
    options.success ||= null
    options.fail ||= null
    options.icon ||= null
    options.iconHtml ||= null
    options.width ||= null
    options.cancelBtn = options.cancelBtn === 'true'
    options.successBtn = options.successBtn ? false : true
    options.allowOutsideClick = options.allowOutsideClick==false ? false : true
    options.allowEscapeKey = options.allowEscapeKey==false ? false : true
    options.confirmButtonColor ||= null
    options.cancelButtonColor ||= null
    options.timer ||= null
    options.mustCancel ||= false
    options.classPopup ||= null
    options.classBtnPopup ||= null

    if (options.kind && options.kind == 'akaire') {
      OPTION['customClass']['popup'] = 'akaire_alert_popup'
    }

    if(!!options.classPopup){
      OPTION['customClass']['popup'] = `${OPTION['customClass']['popup']} ${options.classPopup}`
    }

    if(!!options.classBtnPopup){
      OPTION['customClass']['confirmButton'] = `${OPTION['customClass']['confirmButton']} ${options.classBtnPopup}`
    }

    swalWithBootstrapButtons.fire({
      title: text,
      html: options.description,
      showCancelButton: options.cancelBtn,
      showConfirmButton: options.successBtn,
      confirmButtonText: options.confirmText,
      cancelButtonText: options.cancelText,
      icon: options.icon,
      iconHtml: options.iconHtml,
      width: options.width,
      allowOutsideClick: options.allowOutsideClick,
      allowEscapeKey: options.allowEscapeKey,
      confirmButtonColor: options.confirmButtonColor,
      timer: options.timer
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeof options.success === 'function') options.success()
      } else if (
        (options.mustCancel && result.isDismissed && result.dismiss === 'cancel') ||
        (!options.mustCancel && result.isDismissed)
      ) {
        if (typeof options.cancel === 'function') options.cancel()
      }
    })
    if (options.confirmButtonColor) {
      $('.swal2-confirm').css('background-color', options.confirmButtonColor)
                         .css('border-color', options.confirmButtonColor)
    }
    if (options.cancelButtonColor) {
      $('.swal2-cancel').css('background-color', options.cancelButtonColor)
                         .css('border-color', options.cancelButtonColor)
    }
  }

  customConfirm(idTemplate, successFunction, falseFunction, cancelFunction) {
    Swal.fire({
      template: idTemplate
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeof successFunction === 'function') successFunction();
      } else if (result.isDenied) {
        if (typeof falseFunction === 'function') falseFunction();
      } else if (result.dismiss) {
        if (typeof cancelFunction === 'function') cancelFunction();
      }
    })
  }

  async popupConfirmCheckbox(text, options = {}) {
    options.inputPlaceholder ||= ''
    options.confirmButtonText ||= 'OK'
    options.confirmButtonColor || '#004a8f'
    options.success ||= null
    options.cancel ||= null
    const { value: accept } = await Swal.fire({
      title: `<h5 class="no-bottom-margin">${text}</h5>`,
      input: 'checkbox',
      inputValue: 0,
      inputPlaceholder: options.inputPlaceholder,
      confirmButtonText: options.confirmButtonText,
    })
    if (options.confirmButtonColor) {
      $('.swal2-confirm').css('background-color', options.confirmButtonColor)
                         .css('border-color', options.confirmButtonColor)
    }
    if (accept) {
      if (typeof options.success === 'function') options.success()
    } else {
      if (typeof options.cancel === 'function') options.cancel()
    }
  }

  close() {
    Swal.close();
  }
}
