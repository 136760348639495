import React, { Component } from 'react';
class PolyLineSvg extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { id, points, color, lineWidth, onItemMouseOver, onItemMouseLeave, onDelete } = this.props;
        return (
                <polyline
                id={id}
                points={points}
                stroke={color}
                strokeWidth={lineWidth}
                fillOpacity="0"
                onMouseOver={onItemMouseOver}
                onMouseOut={onItemMouseLeave}
                onMouseLeave={onItemMouseLeave}
                onTouchStart={onItemMouseOver}
                onClick={onDelete}
                strokeLinecap="round"
                ></polyline>
        );
    }
};

export default PolyLineSvg;
