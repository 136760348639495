export default class Page {
  handleClick(element) {
    let type = element.value;
    if (type == 'pdf') {
      $('#typePdf').removeClass('d-none')
      $('#typeText').addClass('d-none')
    } else if (type == 'text') {
      $('#typeText').removeClass('d-none')
      $('#typePdf').addClass('d-none')
    }
  }

  clickButtonUpload() {
    $('#upload_file').click();
  }

  clickButtonDeletePage(id){
    Popup.confirm(`削除後は復元できません。<br>削除してよろしいですか？`, {
      cancelBtn: false,
      confirmText: 'OK',
      success: () => {
        $(`a.delete-page-${id}`)[0].click();
      }
    })
  }
}
