import consumer from "./consumer"

$(document).on('turbolinks:load', function () {
	consumer.subscriptions.create({channel: "UnreadNotifyChannel", id: $('.js-unread_notify_number').attr('data-channel-id')}, {
		connected() {
			// Called when the subscription is ready for use on the server
		},

		disconnected() {
			// Called when the subscription has been terminated by the server
		},

    received(data) {
      if (data) {
        $(".js-unread_notify_number").html(data.unread_notify_number);
        let numberUnreadMessageByGroup = data.number_unread_message_by_group
        for (var key in numberUnreadMessageByGroup) {
          $(`#js-unread_number_for_${key}`).html(numberUnreadMessageByGroup[key]);
        }
      }
    }
  });
});
