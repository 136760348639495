import axios from 'axios';

const csrfTokenTag = document.querySelector('meta[name="csrf-token"]');

export default function apiClient(options = {}) {
  return axios.create({
    headers: {
      'X-CSRF-TOKEN': csrfTokenTag && csrfTokenTag.getAttribute('content') || ''
    },
    ...options,
  })
};
