export default class ContractAction {
  openModalConfirmRemoveRevision(elem) {
    const companyId = $(elem).attr("data-id")
    const contractName = $(elem).attr("data-name")

    $("#toggleRemoveRevisionModal #ok-btn").attr("data-id", companyId)
    $("#toggleRemoveRevisionModal").find("#content-message").html(`${contractName}の最新バージョンを削除します。<br> よろしいですか？`)
    $("#toggleRemoveRevisionModal").modal("show")
  }

  submitRemoveRevision(elem) {
    const revisionid = $(elem).attr("data-id");
    $.ajax({
      url: `/suppliers/${revisionid}/destroy_revision`,
      type: "POST",
      dataType: "json",
      success: function (data) {
        location.reload();
      },
      error: function (data) {
        location.reload();
      },
    });
  }

  submitSuggestion(element, partnerName){
    let contractForm = $(element).closest('#new_contract')
    let contractName = contractForm.find('#contract_name')
    let contractTemplate = contractForm.find('#contractTemplate')
    let errorCount = 0
    $('.error-message').each(function(i, e){
      $(e).text("")
    })
    if (contractName.val() == "") {
      contractName.next('.error-message').text('書類名を入力してください。')
      errorCount++
    }
    if (contractTemplate.val() == "") {
      contractTemplate.next('.error-message').text('書類を選択してください。')
      errorCount++
    }
    if(errorCount == 0){
      contractForm.submit();
    }else{
      return false
    }
  }
}
