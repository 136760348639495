import React, { Component } from "react";
import iconMagnifyingGlass from "../ui/icons/iconMagnifyingGlass.png";
import { Button } from "reactstrap";

export default class UserToSelect extends Component {
  constructor(props) {
    super(props);

    const {defaultUserTo, receivers} = this.props;

    this.state = {
      selectedMembers: receivers.filter(object => object.value == defaultUserTo.id),
      suggestMembers: receivers,
      inputValue: ''
    };
  }

  handleUserClick = (member) => {
    const { selectedMembers } = this.state;
    const index = selectedMembers.indexOf(member);

    if (index === -1) {
      this.setState({ selectedMembers: [...selectedMembers, member] });
    } else {
      const updatedMembers = [...selectedMembers];
      updatedMembers.splice(index, 1);
      this.setState({ selectedMembers: updatedMembers });
    }
  };

  handleOkClick = () => {
    this.props.updateSelectedMember(this.state.selectedMembers);
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  render() {
    const { isOpenModalTo, receivers } = this.props;
    const { selectedMembers, inputValue } = this.state;
    const filteredMembers = receivers.filter(member =>
      member.label.toLowerCase().includes(inputValue?.toLowerCase())
    );

    return (
      isOpenModalTo && (
        <div className="modal-to-users-container">
          <div className="search-wrapper">
            <img src={iconMagnifyingGlass}></img>
            <div className="search-wrapper-users">
              {selectedMembers.map((member) => (
                <span key={member.value}>{member.label}</span>
              ))}
            </div>
            <input placeholder="メンバーを検索" onChange={this.handleInputChange} value={inputValue} />
          </div>

          <div className="suggestion-wrapper">
            {filteredMembers.map((member) => (
              <div
                className="suggestion-item"
                onClick={() => {
                  this.handleUserClick(member);
                }}
                key={member.value}
              >
                {member.avatar ? (
                  <img className="item-avatar" src={member.avatar}></img>
                ) : (
                  <span className="item-avatar">{member.label[0]}</span>
                )}
                <div key={member.value}>{member.label}</div>
              </div>
            ))}
          </div>

          <div className="hr"></div>

          <div className="d-flex justify-content-center">
            <Button className="button-submit" onClick={this.handleOkClick}>
              OK
            </Button>
          </div>
        </div>
      )
    );
  }
}
