import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { version } from '../util/animation';

export default class CompletionModal extends Component {
  constructor() {
    super();
  }
  submit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  }
  onClose = () => {
    const { onClickClose } = this.props;
    onClickClose();
  }
  render() {
    const { isOpen, currentAnimation, type } = this.props;
    var title = '';
    
    if (['動画', 'video'].includes(type)) {
      title = '動画';
    }
    else {
      title = '画像';
    }
    return (
      <Modal isOpen={isOpen} className="completion-modal">
        <ModalHeader>
          {title}の完成
        </ModalHeader>
        <ModalBody>
          <p>
            バージョン{version(currentAnimation)}の{title}を最終稿にして完成しますか？
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={this.onClose}>キャンセル</Button>
          <Button color="primary" onClick={this.submit}>OK</Button>
        </ModalFooter>
      </Modal>
    );
  }
};
