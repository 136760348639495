export default class MessageStatus {
  unread(messageId, element, user_message_ids) {
    const url = Routes.destroy_all_unread_of_chat_owner_unread_index_path();
    const arrayUserMessage = JSON.parse("[" + user_message_ids + "]");
    const data = { message_id: messageId, user_message_ids: arrayUserMessage[0] }
    this.handleActionDeleteUnread(element, messageId, I18n.t('message.marked_this_chat_as_read'), url, data)
  }

  unBookmark(messageId, element) {
    const url = Routes.destroy_bookmark_owner_unread_index_path();
    const data = { message_id: messageId }
    this.handleActionDeleteUnread(element, messageId, I18n.t('message.i_canceled_this_chat'), url, data)
  }

  handleActionDeleteUnread(element, messageId, message_success, url, data) {
    const userMessELe = this.fetchUserMessComponent(element, messageId)
    Shared.callAjax(url, data, null, 'delete').done(function(data) {
      Modal.hide(`#unreadSelectPopup-${messageId}`)
      userMessELe.remove();
      $(document).find('.modal-backdrop').remove();
      MessageStatus._showPopupAndReloadPage(message_success)
    }).fail(function(e) {
      MessageStatus._showPopupAndReloadPage(I18n.t('shared.one_error_please_try_again'))
    })
  }

  fetchUserMessComponent(element) {
    return $(element).parents('.list_row')
  }

  static _showPopupAndReloadPage(message) {
    Popup.confirm(message, {
      confirmText: 'OK',
      cancelBtn: 'false',
      cancel: () => { Shared.reloadPage() },
      success: () => { Shared.reloadPage() }
    })
  }
}
