import datepicker from 'js-datepicker'
export default class Task {

  async delete(projectId, taskId, taskName) {
    let isEffortNotReleased = await this.checkDeleteTask(projectId, taskId)
    this.handleDelete(
      Routes.owner_destroy_task_path(projectId, taskId), projectId, taskName, taskId, isEffortNotReleased
    )
  }

  async popupDeleteTask(elementText, projectId, taskId, taskName) {
    const element = $(document).find(`#${elementText}`)
    const modalComponent = $(element).parents('div#exampleModalCenter')
    const url = Routes.owner_project_task_show_modal_delete_task_child_path(projectId, taskId)
    await Shared.callAjax(url, {}, 'html').done(function(data) {
      $('#show-content-modal').html(data)
      modalComponent.modal('show')
    })
    .fail(function(data) {
      modalComponent.modal('hide')
      Popup.alert(I18n.t('shared.one_error_please_try_again'));
    })
  }

  async deleteTaskWithPopup(element, projectId, taskId, taskName) {
    const modalEle = $(element).parents('.modal-content');
    const deleteAll = modalEle.find('.modal-body').find('input:checked').val();
    let isEffortNotReleased = await this.checkDeleteTask(projectId, taskId, deleteAll)
    this.handleDelete(
      Routes.owner_destroy_task_path(projectId, taskId, {delete_all: deleteAll}),
      projectId, taskName, taskId, isEffortNotReleased
    )
  }

  handleDelete(url, projectId, taskName, taskId, isEffortNotReleased) {
    const task = this
    if (isEffortNotReleased) {
      const urlEffort = Routes.undelivered_works_efforts_path({ task_id: taskId })
      const urlEffortNotReleased =
        Routes.deleted_works_efforts_path({search_kind: 'task_delete_id_cont', q: { kind_id: taskId } })
      Popup.confirm('', {
        description: `
        <div>
          <div class="text-center">未納品作業が存在します。 </div><br>
          <span>
            タスクを削除すると、関連書類及び未納品作業データ以外は全て削除されます。<br>
            <a class="text-underline font-weight-bold" target="_blank" href="${urlEffort}">未納品作業一覧ページ</a>より未納品作業をご確認の上、削除を行ってください。<br>
            タスク削除後、10日間は<a class="text-underline font-weight-bold" target="_blank" href="${urlEffortNotReleased}">削除一覧ページ</a>より未納品作業のStatus変更が可能です。<br>
            タスク削除後、10日を超過すると未納品作業のStatus変更は出来ません。 <br>
            ※タスクと紐づいている書類は削除されません。書類管理から削除できます。
          </span>
        </div>
      `,
        confirmText: '削除する',
        width: '40rem',
        success: () => {
          task.successDeleteTask(task, projectId, url)
        }
      })
    } else {
      var text = 'タスク';
      if(gon.is_plan_akapon) text = 'ファイル';
      Popup.confirm('', {
        description: `「${taskName}」を削除します。<br> 「${taskName}」内のデータが全て削除され<br>${text}の復元はできません。<br>削除してよろしいですか？`,
        confirmText: 'OK',
        success: () => {
          task.successDeleteTask(task, projectId, url)
        }
      })
    }
  }

  successDeleteTask(taskModel, projectId, url) {
    Shared.callAjax(url, {}, 'json', 'delete')
    .done(function(data) {
      taskModel.reloadWithDeleteTask(projectId)
    })
    .fail(function(data) {
      Popup.alert(data.responseJSON.message)
    })
  }

  reloadWithDeleteTask(projectId) {
    Popup.confirm(``, {
      description: '削除されました。',
      confirmText: 'OK',
      width: 'auto',
      cancelBtn: 'false',
      success: () => { window.location = Routes.owner_list_grantcharts_path(projectId)  },
      cancel: () => { window.location = Routes.owner_list_grantcharts_path(projectId)  },
    })
  }

  async checkDeleteTask(projectId, taskId, isDeleteAll = false) {
    const url = Routes.owner_project_task_check_delete_task_path(projectId, taskId, { is_delete_all: isDeleteAll })
    let isEffortNotReleased = false
    await Shared.callAjax(url, {}).done(function(data) {
      isEffortNotReleased = data.status
    })
    return isEffortNotReleased
  }

  toggleTaskChilds(element, taskId, taskChildIds) {
    Collapsible.toggle($(element), Task._closeTaskCallback(taskChildIds))
    Visiable.toggle(`.task_parent_${taskId}`)
  }

  toggleWorks(element) {
    let taskId = $(element).attr('task_id')
    Visiable.toggle(`.task_${taskId}`)
  }

  disabledDeleteAll(element, isConfirm) {
    if (isConfirm !== 'true' || !$(element).is(':checked')) return
    $(element).parents('.modal-content').find('.confirm-delete-task').attr('disabled', $(element).val() === 'true')
  }

  copy(taskId) {
    const projectId = $('#js_select_company_type_task').attr('project_id');
    const taskComponent = $('#task-new');
    const url = Routes.owner_project_task_copy_path(projectId, taskId)
    Shared.callAjax(url, {}).done((data) => {
      Task._renderTaskCopy(data.task, taskComponent, data.users);
      taskComponent.modal('show');
    }).fail((data) => {
      Popup.alert(I18n.t('shared.one_error_please_try_again'));
    })
  }

  getCompany(element, is_has_client) {
    var type_text = {
      owners: { type: 'owner', text: '自社' },
      suppliers: { type: 'supplier', text: '仕入先' },
      clients: { type: 'client', text: '販売先' },
      collaborators: { type: 'collaborator', text: '外部' }
    }
    const company_type = $(element).val();
    if (company_type === '') {
      $(element).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
      return
    }

    if (company_type === 'clients' && is_has_client === 'false') {
      Popup.alert(
        '販売先は「プロジェクト」から招待できます。<br>「権限一括設定」または「プロジェクト一覧＞メンバー」<br>から招待してください。'
      )
      $('#show-selected-company-type').empty();
      $(element).val('').trigger('change')
      return
    }

    $('#js_select_company_invites_task').empty()
    if (company_type !== 'owners') {
      let company_type_text = I18n.t(`shared.company_types.${company_type}`);
      $('#js_select_company_invites_task').append('<option value="">会社を選択</option>');
      if (!(company_type === 'clients' && is_has_client === 'true')) {
        $('#js_select_company_invites_task').append(`<option value="invite_company">新規に${company_type_text}メンバーを招待する</option>`);
      }
      $('#inviteExistUserByEmail').find('#permission_type').val(type_text[company_type].type)
      if (!$('#select_current_company').hasClass('d-none')) $('#select_current_company').addClass('d-none')
      $('#select_company_by_type').removeClass('d-none')
      $('#button_add_new_member').addClass('d-none').css('width', '100%')
    } else {
      $('#select_current_company').removeClass('d-none')
      $('#select_company_by_type').addClass('d-none')
      $('#button_add_new_member').removeClass('d-none').css('width', 'calc(100% - 30px)')
    }
    if(company_type === 'clients' && is_has_client === 'true') {
      $('#select_company_by_type').find('.btn-mint-absolute').addClass('d-none')
    } else {
      $('#select_company_by_type').find('.btn-mint-absolute').removeClass('d-none')
    }
    $('#js-select_users_invites').empty().append(`<option value="">ユーザーを選択</option>${company_type !== 'owners' ? '<option value="invite_partner_member">新規に招待する</option>' : ''}`);
    const projectId = $(element).attr('project_id');
    const workId = $('#addPersonInChargeOfObject').data('work-id')
    this._handleFetchData(
      Routes.owner_get_company_by_task_path(projectId), { 'company_type': company_type, work_id: workId }
    )
    $('#button_add_member_of_partner').addClass('d-none')
    if (company_type === 'owners') {
      setTimeout(function() {
        $('#js_select_company_invites_task').val($("#js_select_company_invites_task option:last").val()).trigger('change')
      }, 300)
    }
  }

  removeDisabledBtnInvite(element) {
    const modalEle = $(element).parents('div.modal-body');
    const companySelect = modalEle.find('#js_select_company_invites_task').val()
    const userSelect = modalEle.find('#js-select_users_invites').val()
    if (companySelect && companySelect !== '' && userSelect && userSelect !== '') {
      $(element).parents('div.modal-body').find('#inviteAdd').removeClass('disabled');
    } else {
      $(element).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
    }
  }

  changeUserOfaddMemberListTask(ele) {
    if ($(ele).val() === '') {
      $(ele).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
    } else if($(ele).val() === 'invite_partner_member') {
      $(ele).parents('div.modal-body').find('.invite-member-of-partner-button').trigger('click');
    } else if($(ele).val() === 'invite_member') {
      $('#button_add_new_member').find('.btn-mint').trigger('click');
    } else {
      this.removeDisabledBtnInvite($(ele))
    }
  }

  fetchUserInvite(element) {
    let company_type = $('#js_select_company_type_task').val();
    $('#js-select_users_invites').empty().append(`<option value="">ユーザーを選択</option>${company_type !== 'owners' ? '<option value="invite_partner_member">新規に招待する</option>' : ''}`);
    if (company_type === 'owners') {
      $('#js-select_users_invites').append('<option value="invite_member" style="text-decoration: underline;">新規に自社メンバーを招待する</option>');
    }

    if ($(element).val() === '') {
      $(element).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
      return
    } else if ($(element).val() === 'invite_company') {
      $(element).closest('#select_company_by_type').find('.btn.btn-mint').trigger('click');
      return;
    }

    const companyId = $(element).val();
    const projectId = $('#js_select_company_type_task').attr('project_id');
    const companyType = $('#js_select_company_type_task').val();
    const exceptUserIds = JSON.parse($('.list-user-invite-task').val());
    $('#inviteExistUserByEmail').find('#company_id').val(companyId)
    const data = { 'company_id': companyId, 'company_type': companyType, 'except_users': exceptUserIds }
    this._handleFetchData(Routes.owner_get_user_of_task_path(projectId), data, 'users');
  }

  fetchUserInviteProject(element) {
    $('#js-select_users_invites').empty().append('<option value="">ユーザーを選択</option>');
    if ($('#js_select_company_type_task').val() === 'owners') {
      $('#js-select_users_invites').append('<option value="invite_member" style="text-decoration: underline;">新規に自社メンバーを招待する</option>');
    }

    if ($(element).val() === '') {
      $(element).parents('div.modal-body').find('#inviteAdd').addClass('disabled');
      return
    } else if ($(element).val() === 'invite_company') {
      $(element).closest('#select_company_by_type').find('.btn.btn-mint').trigger('click');
      return;
    }

    const companyId = $(element).val();
    const projectId = $('#js_select_company_type_task').attr('project_id');
    const companyType = $('#js_select_company_type_task').val();
    const data = { 'company_id': companyId, 'company_type': companyType }
    this._handleFetchData(Routes.owner_get_user_of_task_path(projectId), data, 'users');
  }

  removeAttachment(element) {
    const attachmentOuter = $(element).closest('.attachment_outer');
    const id = attachmentOuter.find('.attachment').attr('data-id');
    attachmentOuter.hide(500);
    if($('#delete_attachment').val()){
      $('#delete_attachment').val(`${$('#delete_attachment').val()}, ${id}`)
    }else{
      $('#delete_attachment').val(id)
    }
    this.submitEditTaskDetail()
  }

  async create(element) {
    const form = $(element).parents('#new_task');
    const isError = await this._validateTask(form);
    if (isError) return
    form.submit();
    $(element).attr('disabled', true);
  }

  openAddPicModal(resetValues = false) {
    if (resetValues) {
      $('#js_select_company_type_task').val('');
      $('#js_select_company_invites_task').empty();
      $('#js-select_users_invites').empty();
      $('#add_new_email_permission').val('');
      $('#add_new_email_permission_type').val('')
    }

    if($('#js-select_users_invites').val() == 'invite_member') {
      $('#js-select_users_invites').val('');
    }

    if ($('#js_select_company_invites_task').val() == 'invite_company') {
      $('#js_select_company_invites_task').val('');
    }
    var tastName = $("#personInChargeOfObject").find('.task_name_header').text()
    $("#personInChargeOfObject").modal("hide")
    $("#addPersonInChargeOfObject").find('.task_name_header').html(tastName)
    Shared.appendSelect2('#addPersonInChargeOfObject', '#js_select_company_invites_task')
    Shared.appendSelect2('#addPersonInChargeOfObject', '#js-select_users_invites')
    $("#addPersonInChargeOfObject").modal("show")
  }

  addUserInvite(element, isAkapon) {
    const types = { owners: '自社', suppliers: '仕入先', clients: '販売先', collaborators: '外部' }
    const inviteUser = $('#js-select_users_invites').val();
    const inviteLists = $('#inviteLists');
    let listUserInvite = JSON.parse(inviteLists.find('.list-user-invite-task').val())
    const userName = $('#js-select_users_invites option:selected').text()
    const companyId = $('#js_select_company_invites_task :selected').val();
    const companyName = $(element).parent().find("#js_select_company_invites_task option:selected").text()
    const actionOnclick = `Task.deleteUserInvite(this)`
    const idAddUserInvite = $('#idAddUserInvite').val()

    if(inviteUser){
      var tdName = `<div class="td t_company"><p>${companyName}</p></div>`
      if(isAkapon == 'true') tdName = ''
      var elementAdd = `<div class="tr d-flex">
                <div class="td t_invite cursor-pointer" onclick="TaskPic.handleCheckAction(this)">
                  <input type="checkbox" name="pic" id="pic" value="${inviteUser}" class="pick-pics d-none" checked>
                  <div class="check-view fs-18 c-red">✔️</div>
                </div>
                <div class="td t_type"><p>${types[$('#js_select_company_type_task').val()]}</p></div>`
                 + tdName +
                `<div class="td t_name font-weight-bold"><p>${userName}</p></div>
              </div>`

      if($("#personInChargeOfObject").find(`input[value="${inviteUser}"]`).length) {
        $("#personInChargeOfObject").find(`input[value="${inviteUser}"]`).parent().click()
      } else {
        $("#personInChargeOfObject").find(".pics-table").append(elementAdd)
      }
      $("#personInChargeOfObject").attr('data-is-change', 'true')

      $("#personInChargeOfObject").attr('data-is-show', 'true')
      $("#personInChargeOfObject").modal("show")
      $("#addPersonInChargeOfObject").modal("hide")
      inviteLists.find('.invite_lists_inner').append(`<span class="invite_name" data-flag="new_permission_task" data-companyid="${companyId}" data-inviteid="${inviteUser}" onclick="${actionOnclick}">${userName}</span>`);
      const elementAdd_tmp = `<li class="dropdown-item" onclick="${actionOnclick}" data-flag="new_permission_task" data-companyid="${companyId}" data-inviteid="${inviteUser}"><span>${userName}</span><i data-feather="x-square" class="invite_delete"></i></li>`;
      inviteLists.find('.view_box_lists').append(elementAdd_tmp);
      listUserInvite.push(inviteUser)
      inviteLists.find('.list-user-invite-task').val(`[${listUserInvite}]`)
      $('#js-select_users_invites').find(`option[value="${inviteUser}"]`).remove();

      if(idAddUserInvite.length > 0){
        $('#idAddUserInvite').val(`${idAddUserInvite},${inviteUser}`)
      }else{
        $('#idAddUserInvite').val(inviteUser)
      }
    }
  }

  deleteUserInvite(element) {
    const inviteId = $(element).data('inviteid');
    const inviteLists = $('#inviteLists')
    const inputProjectAndTask = $('#project_id_and_task_id');
    const projectId = inputProjectAndTask.attr('project_id');
    const taskId = inputProjectAndTask.attr('task_id');
    const isNewPermissionTask = $(element).data('flag') === 'new_permission_task';

    if (isNewPermissionTask) {
      Task._handleDeleteUserInvite(inviteLists, inviteId);
    } else {
      Task._checkToRemoveUser(element, inviteId, projectId, taskId);
    }
  }

  static _checkToRemoveUser(element, inviteId, projectId, taskId) {
    var ajaxUrl;
    var userId = { 'user_id': inviteId };
    if (taskId) {
      ajaxUrl = Routes.owner_project_task_is_delete_permission_path(projectId, taskId);
    } else {
      ajaxUrl = Routes.check_delete_permission_for_new_task_owner_project_path(projectId);
    }

    Shared.callAjax(ajaxUrl, userId).done(function(data) {
      if (data.is_delete) {
        Task.checkMemberBeforeDeletePic(element, inviteId, projectId, taskId)
      } else {
        Popup.alert(I18n.t('task.cannot_remove_permission'));
      }
    })
  }

  selectFile(element, submit) {
    if(submit)  {
      this.submitEditTaskDetail()
      return;
    } else {
      let files = $(element)[0].files;
      this._showFilesSelected(files)
    }
  }

  removeFile(element) {
    const attachmentOuter = $(element).closest('.attachment_outer');
    const id = attachmentOuter.find('.attachment').attr('data-id');
    attachmentOuter.remove(500);
    const inputFile = attachmentOuter.parents('.display-file-area').parents('.txt').find('input#task_file');
    let files = inputFile[0].files;
    files = [...files].filter(file => file !== files[id])
    this._showFilesSelected(files)
    if (files.length > 0) {
      let dt = new DataTransfer()
      $.each(files, function(k, file) {
        dt.items.add(file)
      })
      inputFile[0].files = dt.files
    } else {
      inputFile.val('')
    }
  }

  _showFilesSelected(files) {
    const container = $('.display-file-area .wrapp');
    let html = '';
    for (let i = 0; i < files.length; i++) {
      let fileName = files[i].name.split('.');
      let truncateFileName = `${Shared.truncateString(fileName[0], 10)}.${fileName[1]}`
      let isImage = ['jpg', 'gif', 'png', 'jpeg'].includes(fileName[1]);

      html += `<div class="attachment_outer">
        <div data-id="${i}" class="attachment">
          <div class="download-link ${isImage ? 'download-image' : 'download-text'} fs-12 text-dark">
            <div class="filename">${truncateFileName}</div>
            ${isImage ? `<img src="${window.URL.createObjectURL(files[i])}" width="100%">` : '<i data-feather="file-text" class="icon_file_text"></i>'}
          </div>
        </div>
        <button type="button" class="btn-delete-attachment" onclick="Task.removeFile(this)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text_x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </button>
      </div>`
    }
    if (html == '') {
      container.addClass('d-none')
      container.removeClass('d-flex')
    } else {
      container.addClass('d-flex')
      container.removeClass('d-none')
      container.html(html)
      feather.replace();
    }
  }

  reset() {
    defaultAction.run()
    $('#new_task').find('#title').val('');
    $('#new_task').find('.js-datepicker').val('');
    $('#new_task').find('#task_description').val('');
    $('#new_task').find('#task_parent_id').val('');
    $("#createTaskProjectModal").modal("hide")
  }

  showHideChangeStatus(element) {
    const thisTr = $(element).closest('.ggroupitem')
    if (thisTr.hasClass('child-ticket')) return
    const thisPopup = $(element).closest('.gadditional-pStatus').find('.taskStatusPpopup')
    if(thisPopup.hasClass('show')){
      thisPopup.removeClass('show')
    }else{
      if($('.taskStatusPpopup').hasClass('show')){
        $('.taskStatusPpopup').removeClass('show')
      }
      const lastPopupStatus = $('body').find('.taskStatusPpopup:last');
      if (!lastPopupStatus.hasClass('last-task')) lastPopupStatus.addClass('last-task')
      thisPopup.addClass('show')
    }
  }

  showHideChangeStatusSp(ele) {
    const thisPopup = $(ele).find('.taskStatusPpopupSp')
    if(thisPopup.hasClass('show')){
      thisPopup.removeClass('show')
    }else{
      if($('.taskStatusPpopupSp').hasClass('show')){
        $('.taskStatusPpopupSp').removeClass('show')
      }
      thisPopup.addClass('show')
    }
  }

  settingDeadlineDate(element, taskId, projectId) {
    $(`#modal_expiration_task_${taskId}`).modal('show')
  }

  showFileSizeTask(ele, taskId) {
    $(`#modal-show-task-file-size-${taskId}`).modal('show')
  }

  showTotalQuantity(ele, taskId) {
    $(`#modal-show-task-total-quantity-${taskId}`).modal('show')
  }

  resetExpirationDate(ele, taskId) {
    const modalComponent = $(ele).parents('.modal-content');
    let color = $(ele).attr('data-current-color') || 'blue';
    modalComponent.find('input#expiration-date-start').val('');
    modalComponent.find('input#expiration-date-end').val('');
    modalComponent.find('#colorPicker').find(`div[data-color="${color}"]`).trigger('click');
  }

  cancelSelectExpirationDate(event) {
    const tdEle = $(event.target).parents('div.modal_expiration_task');
    const startDate = tdEle.find('input#expiration-date-start').data('current-date').replaceAll('-', '/');
    const endDate = tdEle.find('input#expiration-date-end').data('current-date').replaceAll('-', '/');
    $(event.target).parents('.modal-content').find('input#expiration-date-start').val(startDate);
    $(event.target).parents('.modal-content').find('input#expiration-date-end').val(endDate);
  }

  setExpirationDate(ele, projectId, taskId) {
    const modalComponent = $(ele).parents('.modal_expiration_task')
    const startDate = modalComponent.find('#expiration-date-start').val()
    const endDate = modalComponent.find('#expiration-date-end').val()
    const color = modalComponent.find(`input#colorInput_task_${taskId}`).val()
    const url = Routes.change_dateline_of_task_owner_project_tasks_path(projectId)
    const task = this
    Shared.callAjax(url, { task_id: taskId, start_date: startDate, due_date: endDate, colors: color }).done(function(data) {
      task._showPopupReload('期限を更新しました。')
    })
    .fail(function(data) {
      task._showPopupReload('変更に失敗しました。')
    })
  }

  _showPopupReload(message) {
    Popup.confirm(message, {
      cancelBtn: 'false',
      confirmText: 'OK',
      success: () => { location.reload() },
      cancel: () => { location.reload() },
      width: 'auto',
      confirmButtonColor: this.colorPlanAkapon()
    })
  }

  showModalWorkHistory(workId) {
    $(`#dropdownMenuButtonWorkHistoty-${workId}`).modal('show')
  }

  showWorkSize(ele, workId) {
    $(`#modal-show-work-file-size-${workId}`).modal('show')
  }

  showHideSettingPic(projectID, taskId, newTask = false) {
    const url = Routes.owner_project_task_get_list_participants_path(projectID, taskId)
    Shared.callAjax(url, { task_id: taskId, new_task: newTask }, 'script', 'GET')
  }

  settingShowAgain(element) {
    const url = Routes.setting_show_again_owner_users_path()
    const check_status = $(element).is(":checked")
    Shared.callAjax(url, { checked: check_status }, 'script', 'POST')
  }

  settingShowProjectAgain(element) {
    const url = Routes.setting_show_again_project_owner_users_path()
    const check_status = $(element).is(":checked")
    Shared.callAjax(url, { checked: check_status }, 'script', 'POST')
  }

  akaireTaskRemoveMember(event, element, projectId, taskId, userId) {
    event.preventDefault();
    Task._checkToRemoveUser(element, userId, projectId, taskId)
  }

  changeStatusPopup(element, taskId, projectID){
    const newState = $(element)
    const oldState = newState.closest('.taskStatusPpopup').find('.active')
    const status_data = {'status_green': 'preparation', 'status_orange': 'working', 'status_red': 'complete', 'status_gray': 'on_hold'}
    oldState.removeClass('active')
    const oldStateName = oldState.attr('class').split(' ')[0]
    oldState.attr('onclick', `Task.changeStatus(this, '${taskId}', '${projectID}', '${status_data[oldStateName]}')`)
    newState.addClass('active').attr('onclick', '')
  }

  closeChangeStatus() {
    $(document).on('click', function(e) {
      if ($(e.target).hasClass('taskStatus')) return
      $('.taskStatusPpopup').removeClass('show')
    })
  }

  changeStatusWithSelect(ele, taskId, projectId, reload = false) {
    const status = $(ele).attr('value')
    const url = Routes.owner_project_task_change_status_of_task_with_akaire_path(projectId, taskId)
    Shared.callAjax(url, { status: status }, 'html').done(function(data) {
      if (data.includes('errors:')) {
        Popup.alert(data.replaceAll('errors:'))
        return
      }
      if (reload) {
        Shared.reloadCurrentPage();
      } else {
        Popup.alert(I18n.t('task.update.status.success'))
      }
      $('#change-status-of_task-new').html(data)
    })
  }

  showListStatusTask(ele) {
    $(ele).find('.taskStatusPpopupLi').toggleClass('show')
    $(ele).find('.taskStatusPpopupLi').toggleClass('d-none')
  }

  showListStatusTaskWithId(ele, taskId) {
    $(ele).find(`#taskStatusPpopupLi_${taskId}`).toggleClass('show')
    $(ele).find(`#taskStatusPpopupLi_${taskId}`).toggleClass('d-none')
  }

  showPopupUpgradePlanOfExpirationNotice() {
    $('#modalUpgradePlanOfExpirationNotice').modal('show')
  }

  changeStatus(element, taskId, projectID, status_new, status_old = '') {
    if ($(element).hasClass('active')) return

    const url = Routes.owner_project_task_change_status_path(projectID, taskId)
    const statusStyle = this.fetchStatusStyle(status_new);
    const oldStatusStyle = this.fetchStatusStyle(status_old);
    Shared.callAjax(url, { status: status_new }).done(function(data) {
      $(`#status-task-${taskId}`).parent().find('.style-point').removeClass(oldStatusStyle).addClass(statusStyle)
      $(`#status-task-${taskId}`).parent().find('.status_text_description').html(data.status)
      $(element).parent().find('li').removeClass('active')
      $(`#modalSelectStatus-${taskId}`).find(`li[data-status='${status_new}']`).addClass('active')
      $(`#modalSelectStatus-${taskId}`).modal('hide')

      if (window.innerWidth < gon.shared.break_point_for_mobile) {
        Popup.confirm('', {
          description: Shared.textTaskAkaire(I18n.t('task.update.status.success'), I18n.t('task.update.status.success_akapon')),
          confirmText: 'OK',
          cancelBtn: 'false',
          success: () => { window.location.reload() },
          cancel: () => { window.location.reload() },
        })
        return
      }
      Popup.alert(Shared.textTaskAkaire(I18n.t('task.update.status.success'), I18n.t('task.update.status.success_akapon')))
    }).fail(function(data) {
      if (data.status && data.status_code === 401) {
        Popup.alert(Shared.textTaskAkaire(I18n.t('task.update.status.not_permission'), I18n.t('task.update.status.success_akapon')))
        return
      }
      Popup.alert(data.error)
    })
  }

  showStatusWork(ele, workId) {
    $(`#modal-show-work-status-sp-${workId}`).modal('show')
  }

  showDeadLineWork(ele, workId) {
    const modalDate = $(`#modal_expiration_${workId}`)
    modalDate.find('div.modal-body :input').prop('disabled', true)
    modalDate.find('div.modal-footer :button').prop('disabled', true)
    modalDate.find('div.modal-footer :button').attr('onclick', '')
    modalDate.modal('show')
  }

  showTotalQuantityWork(ele, workId) {
    $(`#modal-show-work-total-quantity-${workId}`).modal('show')
  }

  fetchStatusStyle(status_task) {
    let status_style = ''
    switch(status_task) {
      case 'preparation':
          status_style = 'status_green_point'
          break;
      case 'working':
          status_style = 'status_orange_point'
          break;
      case 'complete':
          status_style = 'status_red_point'
          break;
      case 'on_hold':
          status_style = 'status_gray_point'
      break;
    }
    return status_style
  }

  openModalSettingStatusTask(task_id, project_id, current_status, bypass_confirm) {
    $('#offTaskNoticeStatus').find('.submit-update-status').attr('data-task-id', task_id)
    $('#offTaskNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    $('#onTaskNoticeStatus').find('.submit-update-status').attr('data-task-id', task_id)
    $('#onTaskNoticeStatus').find('.submit-update-status').attr('data-project-id', project_id)
    const bypass_after_setting = $('#tbody-list-task').attr('data-show-confirm-again')
    if((bypass_confirm == 'false' || bypass_confirm == '') && bypass_after_setting == 'false') {
      if(current_status) {
        $('#offTaskNoticeStatus').modal('show')
      } else {
        $('#onTaskNoticeStatus').modal('show')
      }
    } else {
      this.updateNotifyStatus($('#onTaskNoticeStatus').find('.submit-update-status'), !current_status)
    }
  }

  cannotSettingNotify() {
    $('#cannotSettingNotify').modal('show')
  }

  updateNotifyStatus(element, status) {
    let task_id = $(element).attr('data-task-id')
    let project_id = $(element).attr('data-project-id')
    let url = Routes.update_notify_status_owner_project_task_path(project_id, task_id)
    Shared.callAjax(url, {status: status}, 'json', 'patch')
    .done(function(data) {
      if(data.status) {
        $('#onTaskNoticeStatus').modal('hide')
        $(`#status-${task_id}`).find('.accept-notify').removeClass("d-none")
        $(`#status-${task_id}`).find('.reject-notify').addClass("d-none")
      } else {
        $('#offTaskNoticeStatus').modal('hide')
        $(`#status-${task_id}`).find('.accept-notify').addClass("d-none")
        $(`#status-${task_id}`).find('.reject-notify').removeClass("d-none")
      }
    }).fail(function(result) {
      $('#onTaskNoticeStatus').modal('hide')
      $('#offTaskNoticeStatus').modal('hide')
      $('#cannotSettingNotify').modal('show')
    })
  }

  toggleTaskAncestryRow(ele, taskId){
    console.log(taskId)
    let element = $(ele)
    let tbody = element.closest('tbody')
    let ancestryRow = tbody.find(`.ancestory_row[data-parent="${taskId}"]`)
    let idChilder = ancestryRow.find('td.td_id').text();
    let ancestryRowChilder = tbody.find(`.ancestory_row[data-parent="${idChilder}"]`)
    if(element.hasClass('open')){
      ancestryRow.addClass('d-none')
      if(ancestryRowChilder) {
        ancestryRowChilder.addClass('d-none')
        ancestryRow.find('a.icon_toggle').removeClass('open').text('＋')
      }
      $(ele).removeClass('open').text('＋')
    }else{
      ancestryRow.removeClass('d-none')
      $(ele).addClass('open').text('ー')

    }
  }

  static checkMemberBeforeDeletePic(element, userID, projectId, taskId) {
    if ($('.hide-button').hasClass('d-none')) {
      return;
    }
    const inviteId = $(element).data('inviteid');
    const inviteLists = $('#inviteLists')
    const isNewPermissionTask = $(element).data('flag') === 'new_permission_task';
    if (isNewPermissionTask) {
      Task._removeInviteUser(inviteLists, inviteId);
      return;
    }
    const url = Routes.owner_project_task_check_member_before_delete_pic_path(projectId, taskId)
    const type = $('.akaire_members_pic_box').data('akaire-type')
    Shared.callAjax(url, { user_id: userID })
    .done(function(result){
      if (result.show_popup_warning) {
        Popup.confirm(
          `<div class='text-center font-weight-normal popup-check-member-before-delete'>${result.company_name}の未納品作業が存在します。
            紐づけを解除すると提出済（未納品）作業は削除されます。
            解除すると、未納品作業は<a class="text-underline font-weight-bold" href="${Routes.deleted_works_efforts_path()}">削除済一覧ページ</a>に移動します。
          </div>`,
          {
            success: () => {
              if (type === 'task') {
                Task._requestDeleteUserPermision(projectId, taskId, userID);
              } else {
                Task._removeInviteUser(inviteLists, inviteId);
              }
            },
            cancel: () => {},
            confirmText: '解除する',
            cancelText: 'キャンセル',
            width: 'auto',
          }
        )
      } else if (result.only_send_mail) {
        if (type === 'task') {
          Task._handleDeleteUserInvite(inviteLists, userID, 'action_from_akaire', projectId, taskId);
        } else {
          Task._handleDeleteUserInvite(inviteLists, inviteId);
        }
      }
    }).fail(function(result) {
      Popup.confirm('',{
        description: I18n.t('task.cannot_remove_project_owner'),
        confirmText: 'OK',
        cancelBtn: 'false',
      })
    })
  }

  _setTaskFileName(arr, element) {
    let value = '';
    if (arr.length > 1 && arr.length <= 10) {
      value = arr.length + ' files';
      $('#task_file_label').text(value);
    } else if(arr.length > 10) {
      Popup.alert(I18n.t('task.cannot_select_ten_file'), 'warning');
      $(element).val('');
      return
    } else if (arr.length == 1) {
      value = arr[arr.length - 1];
      $('#task_file_label').text(value);
    }

  }

  _handleFetchData(url, dataAjax, dataType = 'company') {
    const task = this
    const idRenderData = dataType === 'company' ? 'js_select_company_invites_task' : 'js-select_users_invites'
    let company_type = $('#js_select_company_type_task').val();
    if(company_type == 'owners') {
      $('#button_add_member_of_partner').addClass('d-none')
    } else {
      $('#button_add_member_of_partner').removeClass('d-none')
    }
    Shared.callAjax(url, dataAjax).done(function(data) {
      if (data.length === 0) return
      let hasSetDefault = false;
      let dataRender = data.map(user => {
        if(user.default_manager && !hasSetDefault) {
          hasSetDefault = true
          return `<option selected="selected" value="${user.id}">${user.name}</option>`
        }
        else return `<option value="${user.id}">${user.name}</option>`
      })
      $(`#${idRenderData}`).append(dataRender.join(""));
      task.removeDisabledBtnInvite($(`#${idRenderData}`))
    }).fail(function(data) {
      Popup.alert(I18n.t('task.fetch_company_error'), 'warning');
    })
  }

  _validateTask(form) {
    let isError = false;
    if (form.find('input[name="task[name]"]').val() === '') {
      isError = true;
      Shared.errorFieldMessage($('input[name="task[name]"]'), '');
    }
    form.find('select[required="required"]').toArray().forEach(input => {
      if ($(input).val() === '') {
        isError = true;
        Shared.errorFieldMessage($(input), '');
      }
    })
    return isError;
  }

  _validateTaskFiles(element) {
    var allowedExtensions = ['doc', 'docx', 'odt', 'pdf', 'tex', 'txt', 'rtf', 'xls', 'xlsx', 'mp4', 'wmv', 'avi', 'mov', 'csv', 'psd', 'ai', 'mp3', 'ppt', 'pptx', 'zip', 'draw', 'ait', 'jpg', 'jpeg', 'png', 'wav'];
    var files = $(element)[0].files
    for (let i=0; i<files.length; i++) {
        let file_extension = files[i].name.substr(files[i].name.lastIndexOf('.') + 1).toLowerCase();
        if ($.inArray( file_extension, allowedExtensions ) < 0) {
          Popup.alert(I18n.t('task.not_upload_with_format'));
          $(element).val('');
          return false
        } else {
          $(element).removeClass('is-invalid');
          return true
        }
    }
  }

  showMessageWithChangeTaskType(ele) {
    const currentValue = $(ele).attr('task_category')
    let description = I18n.t('task.change_task_type_web_to_video')
    if (currentValue !== $(ele).val()) {
      if($(ele).val() == 'web') {
        description = I18n.t('task.change_task_type_video_to_web')
      }

      Popup.confirm('',{
        description: description,
        confirmText: 'OK',
        cancelText: 'キャンセル',
        success: () => {
          $(ele).attr('task_category', $(ele).val())
          this.submitEditTaskDetail()
        },
        cancel: () => {
          $(".edit_task").find("#task_category").val(currentValue)
        }
      })
    }
  }

  changePersonInChargeList(element, objectId, projectId, userId, type, is_akaire_file = false) {
    const $ele = $(element);
    const addAction = $ele.is(":checked");
    const listUserUncheck = $('#list-user-uncheck')
    if (!addAction) {
      $("#list-user-uncheck").val(`${listUserUncheck.val()}${userId},`)
      this._checkMemberBeforeDeletePic(element, objectId, projectId, userId, type, is_akaire_file)
    } else {
      $("#list-user-uncheck").val(listUserUncheck.val().replace(`${userId},`, ''));
      this._handleChangePersonInChargeList(element, objectId, projectId, userId, type, is_akaire_file)
    }
  }

  showEleFilterNewAdd(ele, isPositive = true) {
    const tr = $(ele).parents('.show-list-member-for-page')
    if (tr.length > 0 && tr.attr('old-check') && tr.attr('old-check') === 'true') return

    const modal = $(ele).parents('.modal')
    const spanShow = modal.find('.show-number-member-add')
    const text = spanShow.data('text')
    const number = isPositive ? Number(spanShow.attr('data-number')) + 1 : Number(spanShow.attr('data-number')) - 1
    spanShow.attr('data-number', number)
    if (spanShow.attr('is-show') === 'false') {
      spanShow.text(`${number} ${text}`)
    } else {
      spanShow.text(spanShow.attr('data-text-hide'))
    }
    if (number === 0) {
      spanShow.addClass('d-none')
      tr.removeAttr('is-new-add')
    } else {
      spanShow.removeClass('d-none')
      tr.attr('is-new-add', 'true')
    }
  }

  showListNewAdd(ele) {
    const modal = $(ele).parents('.modal')
    const listTr = modal.find('div.show-list-member-for-page').toArray()
    const numerCurrentPageMember = modal.find('.render_pagy_of_list_member').find('.page-item.active').data('index').toString()
    if ($(ele).attr('is-show') && $(ele).attr('is-show') === 'true') {
      listTr.forEach(tr => {
        if($(tr).attr('data-page') === numerCurrentPageMember || $(tr).hasClass('tr-new-email')) {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'false')
      const text = $(ele).data('text')
      const number = $(ele).attr('data-number')
      $(ele).text(`${number} ${text}`)
      modal.find('.new-style-with-js').removeClass('d-none')
    } else {
      listTr.forEach(tr => {
        if ($(tr).attr('is-new-add') && $(tr).attr('is-new-add') === 'true') {
          $(tr).css('display', '')
        } else {
          $(tr).css('display', 'none')
        }
      })
      $(ele).attr('is-show', 'true')
      $(ele).text($(ele).attr('data-text-hide'))
      modal.find('.new-style-with-js').addClass('d-none')
    }
    document.getElementById("show-table-list-member").scrollIntoView();
  }


  removeParam(key, sourceURL) {
    if (!sourceURL) {
      return;
    }

    let rtn = sourceURL.split("?")[0];
    let param;
    let params_arr = [];
    let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  changePagynationLinks() {
    const array = $(document).find('.task-list-pagination').find('a.page-link').toArray();
    array.forEach(tagA => {
      let tagAUrl = $(tagA).attr('href')
      tagAUrl = this.removeParam("new_task", tagAUrl);
      tagAUrl = this.removeParam("task_id", tagAUrl);
      $(tagA).attr('href', tagAUrl)
    })
  }

  handleUpdatePicOfTask(element, projectId, taskId){
    let user_invite_ids = [];
    const user_delete_ids = $('#list-user-uncheck').val().split(',').filter(Boolean);
    let user_invite_emails = [];
    let list_invite_member_of_partner = [];
    const currentUrl = window.location.href
    if(currentUrl.includes('new_task=true')) {
      let newUrl = this.removeParam("new_task", currentUrl);
      newUrl = this.removeParam("task_id", newUrl);
      window.history.pushState( '', '', newUrl);
      this.changePagynationLinks();
    }
    $(element).parent().parent().find(".pick-pics").each(function( _index, ele ) {
      if($(ele).is(":checked")) {
        if($(ele).attr("name").includes("email")) {
          user_invite_emails.push({email: $(ele).val(), type: $(ele).attr("data-type")})
        } else if($(ele).attr("name").includes("partners")) {
          list_invite_member_of_partner.push({email: $(ele).val(), type: $(ele).attr("data-type"), company_id: $(ele).attr("data-company-id"), name: $(ele).attr("data-name")})
        } else {
          user_invite_ids.push($(ele).val())
        }
      }
    });
    let check_new_task = $("#personInChargeOfObject").attr("data-new-task")
    const url = Routes.owner_update_pics_of_task_path(projectId, taskId)
    Shared.callAjax(url, { list_user_invite: user_invite_ids, list_user_delete: user_delete_ids, list_user_invite_by_email: user_invite_emails, list_invite_member_of_partner: list_invite_member_of_partner, check_new_task: check_new_task })
    .done(function(result){
      if(result.self_remove) location.reload()
      $("#personInChargeOfObject").modal("hide")
      $(`#pic_list_${result.task_id}`).html(`自分と他${result.number_user}名`)
      var akaire_member_text = 'メンバー：自分'
      if(result.number_user != 0) {
        akaire_member_text = `メンバー：自分と他${result.number_user}名`
      }
      $(`#pic_list_${result.task_id}`).html(`自分と他${result.number_user}名`)
      if(result.number_user == '0') $(`#pic_list_${result.task_id}`).html('自分')
      $('.text-akaire-member-list').html(akaire_member_text)
      if(result.new_task && $("#personInChargeOfObject").attr("data-new-task") == 'true') {
        if($("#akaponAddTaskSuccess").length || $("#ccAddTaskSuccess").length) {
          if(result.plan_akapon) {
            $("#akaponAddTaskSuccess").modal("show")
            $("#akaponAddTaskSuccess").find(".transit-button").attr("href", Routes.akaire_feature_project_task_path(projectId, taskId))
          } else {
            $("#ccAddTaskSuccess").modal("show")
            $("#ccAddTaskSuccess").find(".transit-button").attr("href", Routes.owner_log_work_task_path(projectId, taskId))
          }
        } else {
          $("#updatePersonInChargeSuccess").modal("show")
        }
      } else {
        if($("#personInChargeOfObject").attr("data-check-new") == 'true') {
          $("#createTaskSuccess").modal("show")
        } else {
          $("#updatePersonInChargeSuccess").modal("show")
        }
      }
      $('.list-permission').find('.list-permission-name').html(result.approve_participants_name)
      $('.list-waiting-permission').find('.list-permission-name').html(result.unapprove_participants_name)
    }).fail(function() {
      Popup.confirm(I18n.t('work.changed_refresh'), {
        success: () => {
          window.location.href = Routes.owner_list_grantcharts_path(projectId)
        },
        cancel: () => {
          window.location.href = Routes.owner_list_grantcharts_path(projectId)
        },
        confirmText: 'OK',
        cancelBtn: 'false',
        width: '35rem'
      })
    })
  }

  submitEditTaskDetail() {
    $("#submit-edit-task").click()
  }

  _checkMemberBeforeDeletePic(element, objectId, projectId, userId, type, is_akaire_file) {
    if($(element).parent().find(".check-view").attr("class").includes('c-red')) {
      const userName = $(element).parent().parent().find(".t_name").find('p').attr("data-original-title")
      this._removeMemberNotSubmit(userId, userName)
      this.showEleFilterNewAdd(element, false)
      return
    }

    const url = Routes.owner_project_task_check_member_before_delete_pic_path(projectId, objectId)
    const task = this
    const listUserUncheck = $('#list-user-uncheck').val().split(',').filter(Boolean)

    Shared.callAjax(url, { user_id: userId, list_user_uncheck: listUserUncheck, is_akaire_file: is_akaire_file })
    .done(function(result){
      $("#personInChargeOfObject").attr('data-is-change', 'true')
      let message = `<div class='text-center font-weight-normal popup-check-member-before-delete'><p>${result.user_name}さんを招待解除すると、このタスクの紐づけが解除され <br>未納品作業が削除されます。</p>
        <a class="text-underline font-weight-bold" target="_blank" href="${Routes.undelivered_works_efforts_path({search_kind: 'task_id_cont', q: { kind_id: objectId, created_by_eq: result.is_client ? null : userId }})}">未納品作業一覧ページ</a>より未納品作業をご確認の上、メンバー解除を行ってください。
        メンバー解除後、10日間は<a class="text-underline font-weight-bold" target="_blank" href="${Routes.deleted_works_efforts_path()}">削除一覧ページ</a>より未納品作業のStatus変更が可能です。
        10日を超過すると未納品作業のStatus変更は出来ません。
      </div>`
      if (result.is_client) {
        message = `<div class='text-center font-weight-normal popup-check-member-before-delete'>${result.company_name}の未納品が存在します。<br>紐づけを解除すると提出済（未納品）作業は削除されます。<br>解除すると、未納品作業は<a class="text-underline font-weight-bold" href="${Routes.deleted_works_efforts_path()}" target="_blank">削除済一覧ページ</a>に移動します。
        </div>`
      }

      if (result.show_popup_warning) {
        Popup.confirm(
          message,
          {
            success: () => {},
            cancel: () => {
              $(element).prop("checked", true)
              $(element).parent().append("<div class='check-view fs-18'>✔️</div>")
            },
            confirmText: '解除する',
            cancelText: 'キャンセル',
            width: 'auto',
          }
        )
      } else if (result.only_send_mail) {}
    }).fail(function(result) {
      var description = I18n.t('task.cannot_remove_project_owner')
      if(result.responseJSON.delete_pic_role == 'main_admin'){
        description = I18n.t('task.cannot_remove_project_main_admin')
      }

      if (result.responseJSON.delete_self == 'self'){
        description = 'メンバーから自分の解除はできません。';
      }

      $(element).prop("checked", true)
      $(element).parent().append("<div class='check-view fs-18'>✔️</div>")
      Popup.confirm('',{
        description: description,
        confirmText: 'OK',
        cancelBtn: 'false',
      })
    })
  }

  _handleDeleteUserInviteOfListTask(element, objectId, projectId, userId, type) {
    const url = Routes.delete_person_incharge_owner_project_tasks_path(projectId)
    Shared.callAjax(url, { task_id: objectId, type: type, user_id: userId }).done(function(data) {
      if(data.self_remove) location.reload()
      $(element).parents('td').find('span.text-number-user').text(`自分と他${data.number_user}名`)
    })
    .fail(function(result) {
      Popup.alert(I18n.t('task.cannot_remove_project_owner'))
    })
  }

  _removeMemberNotSubmit(userId, userName) {
    const idAddUserInvite = $('#idAddUserInvite').val()
    const list_permission_name = $('.list-permission').find('.list-permission-name').html()
    const userDelete = $('.list-user-invite-delete');

    if(idAddUserInvite.includes(`, ${userId}`)){
      $('#idAddUserInvite').val(idAddUserInvite.replace(`, ${userId}`, ''))
    }else{
      $('#idAddUserInvite').val(idAddUserInvite.replace(userId, ''))
    }

    if($('.list-permission').find('.list-permission-name').length) {
      if(list_permission_name.includes(`, ${userName}`)) {
        $('.list-permission').find('.list-permission-name').html(list_permission_name.replace(`, ${userName}`, ''))
      } else {
        $('.list-permission').find('.list-permission-name').html(list_permission_name.replace(userName, ''))
      }
    }

    userDelete.val(`${userDelete.val()}, ${userId}`)
    $('.list-user-invite-task').val(`[${$('#idAddUserInvite').val()}]`)
  }

  _handleChangePersonInChargeList(element, objectId, projectId, userId, type, is_akaire_file) {
    const task = this
    const url = Routes.check_add_permission_to_task_owner_project_tasks_path(projectId)
    const userDelete = $('.list-user-invite-delete').val();
    Shared.callAjax(url, { task_id: objectId, user_id: userId, is_akaire_file: is_akaire_file }).done(function(data) {
      $("#personInChargeOfObject").attr('data-is-change', 'true')
      if (data.is_invite) {
        // task._handleAddPermisionToTask(element, objectId, projectId, userId, type)
        if($(".list-permission").find(".list-permission-name").length) {
          const idAddUserInvite = $('#idAddUserInvite').val()
          if(idAddUserInvite.length > 0){
            $('#idAddUserInvite').val(`${idAddUserInvite},${userId}`)
          }else{
            $('#idAddUserInvite').val(userId)
          }
          $('.list-user-invite-task').val(`[${$('#idAddUserInvite').val()}]`)
        }

        if(userDelete.includes(`, ${userId}`)) {
          $('.list-user-invite-delete').val(userDelete.replace(`, ${userId}`, ''))
        } else {
          $('.list-user-invite-delete').val(userDelete.replace(userId, ''))
        }
        task.showEleFilterNewAdd(element)
      } else {
        $(element).prop("checked", false)
        $(element).parent().find(".check-view").remove()
        Popup.alert(I18n.t('task.not_invite'))
      }
    })
    .fail(function(data) {
      $(element).prop("checked", false)
      $(element).parent().find(".check-view").remove()
      if (data.responseJSON.is_plan_akapon) {
        var entityFile = $('#entity_type_akaire_file').val();
        var message = '';
        if(entityFile == 'Work') {
          message = 'アカポン会員はチケットファイル<br>に招待できません。'
        } else if(entityFile == 'Task') {
          message = 'アカポン会員はタスクファイル<br>に招待できません。'
        } else {
          message = 'アカポン会員はタスク<br>に招待できません。'
        }
        Popup.alert(message)
        return
      }
      Popup.alert(I18n.t('task.not_invite'))
    })
  }

  _handleAddPermisionToTask(element, objectId, projectId, userId, type) {
    const url = Routes.add_person_incharge_owner_project_tasks_path(projectId)
    Shared.callAjax(url, { task_id: objectId, type: type, user_id: userId }).done(function(data) {
      $(element).parents('td').find('span.text-number-user').text(`自分と他${data.number_user}名`)
    })
    .fail(function(data) {
      Popup.alert(I18n.t('task.not_invite'))
    })
  }

  settingPicsOffTask(task_id){
    $(`#personInCharge${task_id}`).modal("show")
  }

  static _removeInviteUser(inviteLists, inviteId) {
    const inviteListsItem = inviteLists.find(`span[data-inviteid='${inviteId}']`);
    const inviteListsItemName = inviteListsItem.text();
    const isNewPermissionTask = inviteListsItem.data('flag') === 'new_permission_task';
    const companySelectedId = $('#js_select_company_invites_task :selected').val();

    if (companySelectedId == inviteListsItem.data('companyid')) {
      const elementAdd = `<option value="${inviteId}">${inviteListsItemName}</option>`;
      $('#js-select_users_invites').append(elementAdd);
    }
    $('.view_box_lists').find(`.dropdown-item[data-inviteid='${inviteId}']`).remove();
    inviteListsItem.remove();
    const listUserInvite = JSON.parse(inviteLists.find('.list-user-invite-task').val())
    const newListUser = Shared.removeElementOfArray(listUserInvite, inviteId)
    inviteLists.find('.list-user-invite-task').val(`[${newListUser}]`);

    if (!isNewPermissionTask) {
      const userDelete = inviteLists.find('.list-user-invite-delete');
      userDelete.val(`${userDelete.val()}, ${inviteId}`)
    }
  }

  static _closeTaskCallback(taskChildIds) {
    taskChildIds.forEach( (id) => {
      Visiable.hide(`.task_parent_${id}`)
      Collapsible.close($(`#fas_${id}`))
    })
  }

  static _renderTaskCopy(task, taskComponent, users) {
    taskComponent.find('#task_parent_id').val(task.parent_id);
    taskComponent.find('#title').val(task.name)
    taskComponent.find('#select_category').val(task.category);
    taskComponent.find('#task_priority_colors').val(task.priority_colors);
    taskComponent.find('#task_start_date').val(task.start_date);
    taskComponent.find('#task_due_date').val(task.due_date);
    taskComponent.find('#task_description').val(task.description);
    taskComponent.find('#colorPicker').find(`div[data-color="${task.colors}"]`).trigger('click');
    taskComponent.find('.invite_lists_inner').empty();
    taskComponent.find('.view_box_lists').empty();
    taskComponent.find('.list-user-invite-task').val('[]')
    const actionOnclick = `Task.deleteUserInvite(this)`

    users.forEach(user => {
      let listUserInvite = JSON.parse(taskComponent.find('.list-user-invite-task').val())
      let spanTag = `<span class="invite_name" onclick="${actionOnclick}" data-inviteid="${user[0]}">${user[1]}</span>`
      let liTag = `<li class="dropdown-item" onclick="${actionOnclick}" data-inviteid="${user[0]}">${user[1]}</li>`
      taskComponent.find('.invite_lists_inner').append(spanTag);
      taskComponent.find('.view_box_lists').append(liTag);
      listUserInvite.push(user[0]);
      taskComponent.find('.list-user-invite-task').val(`[${listUserInvite}]`);
    })
  }

  static _requestDeleteUserPermision(projectId, taskId, userId) {
    const userIdVar = userId;

    Shared.callAjax(
      Routes.supplier_project_task_remove_user_task_permission_path(projectId, taskId, {user_id_to_remove: userId})
    ).done(function(data) {
      $(`.akaire_members_pic_box .user-item[data-userid='${userIdVar}'`).remove();
      const memberCount = parseInt($('.btn-akaire-member-list')[0].dataset.membersCount)
      if (memberCount - 1 == 1) {
        $('.btn-akaire-member-list').find('a')[0].text = 'メンバー：自分';
      }
      else {
        $('.btn-akaire-member-list').find('a')[0].text = `メンバー：自分と他${memberCount - 2}名`
      }
      $('.btn-akaire-member-list')[0].dataset.membersCount = memberCount - 1
    }).fail(function(data) {
      Popup.alert('エラーが発生しました。再度確認してください。');
    })
  }

  static _handleDeleteUserInvite(inviteLists, inviteId, action_from = null, projectId = null, taskId = null) {
    if(!inviteLists.hasClass('disabled')){
      Popup.confirm('', {
          description: I18n.t('task.do_you_want_remove_user_invite'),
          confirmText: 'OK',
          success: () => {
            if (action_from) {
              Task._requestDeleteUserPermision(projectId, taskId, inviteId);
            } else {
              Task._removeInviteUser(inviteLists, inviteId);
            }
          },
        }
      )
    }
  }

  showChangeTaskType(taskId) {
    $(`#modal-show-change-task-type-${taskId}`).modal('show');
  }

  changeTaskType(ele, taskId, projectId, type) {
    $(`#modal-show-change-task-type-${taskId}`).modal('hide')
    const typeText = type === 'video' ? '動画' : 'WEB'
    const message = `赤入れ種別を${typeText}に変更します。<br>種別を変更すると、前の種別データは削除されます。<br>変更してよろしいですか？`
    Popup.confirm(message, {
      confirmText: 'OK',
      success: () => {
        this._handeChangeTaskType(taskId, projectId, type)
      },
      cancelText: 'キャンセル',
      confirmButtonColor: this.colorPlanAkapon()
    })

  }

  _handeChangeTaskType(taskId, projectId, type) {
    const typeText = type === 'video' ? '動画' : 'WEB'
    const message = `赤入れ種別を${typeText}に変更しました。`
    const url = Routes.owner_project_task_change_task_type_path(projectId, taskId)
    Shared.callAjax(url, { type: type }).done((res) => {
      Popup.confirm(message, {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { Shared.reloadPage() },
        cancel: () => { Shared.reloadPage() },
        confirmButtonColor: this.colorPlanAkapon()
      })
    }).fail((res) => {
      let message = 'エラーが発生しました。再度確認してください。'
      if (res.responseJSON && res.responseJSON.message) message = res.responseJSON.message
      Popup.confirm(message, {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { Shared.reloadPage() },
        cancel: () => { Shared.reloadPage() },
        confirmButtonColor: this.colorPlanAkapon()
      });
    })
  }

  openEditTask(ele) {
    const tdEle = $(ele).parents('td.td_name')
    tdEle.find('a.link-show-name-task').addClass('d-none')
    tdEle.find('input.input-task-text-name').removeClass('d-none')
  }

  saveTaskName(ele, taskId, projectId) {
    if (!$(ele).val()) {
      Popup.alert('タスク名を入力してください。')
      return
    }
    const url = Routes.owner_project_task_update_task_name_path(projectId, taskId)
    Shared.callAjax(url, { name: $(ele).val() }).done((data) => {
      Shared.reloadPage();
    }).fail((data) => {
      let message = 'エラーが発生しました。再度確認してください。'
      if (data.responseJSON && data.responseJSON.message) message = res.responseJSON.message
      Popup.confirm(message, {
        confirmText: 'OK',
        cancelBtn: 'false',
        success: () => { Shared.reloadPage() },
        cancel: () => { Shared.reloadPage() },
      });
    })
  }

  colorPlanAkapon(){
    const plan_akapon = $('body').find('nav#navbar-common').data('plan-akapon');
    if (plan_akapon == 'undefined'){
      return '#202f55';
    }
    return plan_akapon ? '#E60C11' : '#202f55';
  }

  srollToLeftGrantChart(ele) {
    const componentShow = document.getElementById('GanttChartDIVgcharthead')
    const widthOfCol = document.getElementsByClassName('gtaskcell')[0].offsetWidth || 33;
    componentShow.scrollLeft -= widthOfCol;
    $('.div-show-scroll-for-grant-chart-right').show();
    if (componentShow.scrollLeft == 0) {
      $('.div-show-scroll-for-grant-chart-left').hide();
    }
  }

  srollToRightGrantChart(ele) {
    const componentShow = document.getElementById('GanttChartDIVgcharthead')
    const widthOfCol = document.getElementsByClassName('gtaskcell')[0].offsetWidth || 33;
    componentShow.scrollLeft += widthOfCol;
    var maxScrollLeft = componentShow.scrollWidth - componentShow.clientWidth;
    $('.div-show-scroll-for-grant-chart-left').show();
    if (maxScrollLeft == componentShow.scrollLeft) {
      $('.div-show-scroll-for-grant-chart-right').hide();
    }
  }

  closeAddPersonInChargeOfObject() {
    $('#addPersonInChargeOfObject').modal('hide')
    $('#personInChargeOfObject').modal('show')
  }
}
