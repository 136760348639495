export default class Discussion {
  selectReceivingCompanies(element) {
    if (!$(element).is(':checked')) return
    const form = this._fetchFromNewDiscussionEle(element)
    if ($(element).val() === 'target_all') {
      const supplierIds = form.find('.all-company-ids').val();
      form.find('#discussion_receiving_companies').val(supplierIds);
      form.find('.select-company').val('').prop('disabled', true).trigger('change');
    } else {
      form.find('.select-company').prop('disabled', false);
      form.find('#discussion_receiving_companies').val('[]')
    }
    form.find('.list-company-name').val('')
  }

  async removeDiscussionCompany(element, supplierId) {
    const form = this._fetchFromNewDiscussionEle(element);
    const componentViewBoxList = $(element).parents('.view_box_lists')
    componentViewBoxList.empty();
    let supplierIds = JSON.parse(form.find('#discussion_receiving_companies').val());
    supplierIds = supplierIds.filter(function(e) { return e != supplierId })
    const supplierName = []
    await gon.discussions_feature.suppliers.forEach(supplier => {
      supplierIds.forEach(supplierId => {
        if (supplier[0] === Number(supplierId)){
          supplierName.push(supplier[1]);
          componentViewBoxList.append(`<li class="dropdown-item" onclick="Discussion.removeDiscussionCompany(this, ${supplier[0]})">${this.truncateString(supplier[1], 15)}</li>`)
        }
      })
    });
    form.find('#discussion_receiving_companies').val(`[${supplierIds}]`)
    form.find('.list-company-name').val(supplierName);
    if (componentViewBoxList.find('li').length === 0) {
      componentViewBoxList.parents('.show-list-company-name').find('#dropdown-menu-company-list').addClass('d-none')
    }
  }

  async showSupplierName(element, company_ids = []) {
    const form = this._fetchFromNewDiscussionEle(element);
    const showCompanysToView = form.find('.show-list-company-name')
    showCompanysToView.find('.view_box_lists').empty();;

    if (form.find('.type-select-all').is(':checked')) {
      form.find('.list-company-name').val(form.find('.all-company-name').val())
    } else {
      let supplierIds = form.find('.select-company').val();
      let public_suppliers = form.find('#discussion_receiving_companies_added_from_public_suppliers').val();
      let ids_filtered = [];

      if (public_suppliers != '') {
        supplierIds = supplierIds.concat(JSON.parse(public_suppliers));
      } else if (company_ids.length > 0) {
        ids_filtered = company_ids.filter(n => !supplierIds.includes(n.toString()));
        form.find('#discussion_receiving_companies_added_from_public_suppliers').val(`[${ids_filtered}]`);
        supplierIds = supplierIds.concat(ids_filtered);
      }

      const supplierName = []
      await gon.discussions_feature.suppliers.forEach(supplier => {
        supplierIds.forEach(supplierId => {
          if (supplier[0] === Number(supplierId)){
            supplierName.push(supplier[1]);
            showCompanysToView.find('.view_box_lists').append(`<li class="dropdown-item" onclick="Discussion.removeDiscussionCompany(this, ${supplier[0]})">${this.truncateString(supplier[1], 15)}</li>`)
          }
        })
      });

      form.find('#discussion_receiving_companies').val(`[${supplierIds}]`)
      form.find('.list-company-name').val(supplierName);
      if (showCompanysToView.find('.view_box_lists').find('li').length === 0) {
        showCompanysToView.find('#dropdown-menu-company-list').addClass('d-none')
      }
      else {
        showCompanysToView.find('#dropdown-menu-company-list').removeClass('d-none')
      }
    }
  }

  showDescription(element) {
    Popup.alert($(element).val())
  }

  truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  create(element, items) {
    const form = this._fetchFromNewDiscussionEle(element);
    const isError = this._validateDiscussion(form);
    if (isError) {
      Popup.alert(I18n.t('shared.attribute_not_blank'), 'warning')
      return
    }
    const data = {
      discussion: {
        name: form.find('#discussion_name').val(),
        receiving_companies: JSON.parse(form.find('#discussion_receiving_companies').val()),
        description: form.find('#discussion_detail').val()
      },
      items_each_page: items
    }
    Shared.callAjax(Routes.owner_discussions_path(), data, 'script')
  }

  changeStatus(discussionId, statusOld, statusNew, items) {
    let textStatus = statusOld === 'going_on' ? '送信済' : '終 了';
    if (statusOld === statusNew) {
      Popup.alert(
        I18n.t('discussion.change_status.not_change', { status_old: textStatus, status_new: textStatus }), 'warning'
      )
      return
    }
    const dataParams = { id: discussionId, status: statusNew , items_each_page: items }
    Shared.callAjax(Routes.change_status_owner_discussions_path(), dataParams, 'script')
  }

  showChangeStatus() {
    $('.button_status').on('click', function(){
      $(this).find('.change_status').slideDown(300,function(){
        $('.change_status').on('mouseleave', function(){
          $('.change_status').slideUp(300)
        })
      });
    })
  }

  reset(element) {
    const form = this._fetchFromNewDiscussionEle(element);
    form.find('.select-company').val('').trigger('change');
    form.find('.type-select-one').prop('checked', true).trigger('click');
    form[0].reset();
    form.find('#discussion_receiving_companies').val('[]');
    form.find('#discussion_receiving_companies_added_from_public_suppliers').val('[]');
    form.find('#dropdown-menu-company-list').addClass('d-none');
    form.find('.view_box_lists').empty();
  }

  copy(discussionId) {
    if (discussionId === '') return
    const form = $('#new_discussion');
    Shared.callAjax(Routes.fetch_discussion_owner_discussions_path(), {
      id: discussionId
    }).done(function (data) {
      appendDiscussion(form, data.discussion, data.name_suppliers);
      Popup.alert(I18n.t('discussion.copy.success'));
    }).fail(function() {
      Popup.alert(I18n.t('shared.one_error_please_try_again'), 'warning');
    })
  }

  showAllCompany(element) {
    const listCompany = $(element).parents('.list-link-to-company');
    Visiable.show(listCompany.find('.show-last-company'))
    Visiable.show(listCompany.find('.read-less-button'))
    Visiable.hide($(element))
  }

  readLessCompany(element) {
    const listCompany = $(element).parents('.list-link-to-company');
    Visiable.show(listCompany.find('.read-more-button'))
    Visiable.hide(listCompany.find('.show-last-company'))
    Visiable.hide($(element))
  }

  _fetchFromNewDiscussionEle(element) {
    return $(element).parents('#new_discussion');
  }

  _validateDiscussion(form) {
    let isError = false;
    const arrayInputCheck = ['discussion_name', 'discussion_receiving_companies', 'discussion_detail'];
    form.find('input').toArray().forEach(input => {
      if (arrayInputCheck.includes($(input).attr('id')) && ($(input).val() === '' || $(input).val() === '[]')) {
        Shared.errorFieldMessage($(input), I18n.t('shared.attribute_not_blank'));
        if ($(input).attr('id') === 'discussion_receiving_companies') {
          Shared.errorFieldMessage(form.find('.list-company-name'), I18n.t('shared.attribute_not_blank'));
        }
        isError = true;
      }
    })
    if (form.find('#discussion_detail').val() === '') {
      Shared.errorFieldMessage(form.find('#discussion_detail'), I18n.t('shared.attribute_not_blank'));
      isError = true;
    }
    return isError;
  }

  showHideListDiscussion() {
    if (sessionStorage.getItem('showListDiscussion')) {
      sessionStorage.removeItem('showListDiscussion')
    } else {
      sessionStorage.setItem('showListDiscussion', true)
    }
    this.handleShowHideListDiscussion()
  }

  handleShowHideListDiscussion() {
    let text = ''
    if (sessionStorage.getItem('showListDiscussion')) {
      text = '新規作成'
      $('.show-add-discussion').addClass('d-none')
      $('.show-list-discussion').removeClass('d-none')
    } else {
      $('.show-add-discussion').removeClass('d-none')
      $('.show-list-discussion').addClass('d-none')
      text = '送信履歴'
    }
    $('.text-show-hide-list-discussion').text(text)
  }
}

function appendDiscussion(form, discussion, suppliersName) {
  form.find('#discussion_name').val(discussion.name);
  form.find('.type-select-one').trigger('click');
  form.find('#discussion_detail').val(discussion.description);
  form.find('#discussion_receiving_companies').val(`[${discussion.receiving_companies}]`);
  form.find('.select-company ').val(discussion.receiving_companies).trigger('change')
  form.find('.list-company-name').val(suppliersName);
}
