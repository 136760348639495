import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PopupButton from './PopupButton';
import MenuSpeedOption from './MenuSpeedOption';
import OutsideClickHandler from './OutsideClickHandler';

const propTypes = {
  player: PropTypes.object,
  actions: PropTypes.object,
  rates: PropTypes.array,
  className: PropTypes.string,
  alwaysShowVolume: PropTypes.bool
};

const defaultProps = {
  rates: [2, 1.5, 1.25, 1, 0.5, 0.25]
};

export default class SettingButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: false,
      showMenuSpeedOption: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.clickOption = this.clickOption.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShowControlBar !== this.props.isShowControlBar) {
      if (!this.props.isShowControlBar) this.setState({ active: false, showMenuSpeedOption: false });
    }
  }

  handleClick(event) {
    if (!event.target.classList.contains('video-react-icon-settings')) return
    this.setState({ active: true });
  }

  closeMenu() {
    this.setState({
      active: false,
      showMenuSpeedOption: false
    });
  }

  handleOutsideClick() {
    this.setState({ active: false, showMenuSpeedOption: false });
  }

  clickOption() {
    this.setState({ showMenuSpeedOption: true });
  }

  handleSelectItem(index) {
    this.setState({ active: false, showMenuSpeedOption: false });
    const { rates, actions } = this.props;
    if (index >= 0 && index < rates.length) actions.changeRate(rates[index]);
  }

  render() {
    const { rates, player, className } = this.props;
    const items = rates.map(rate => ({
      label: (rate == 1) ? '標準' : rate,
      value: rate
    }));

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick} handleWhenUnMount={this.handleWhenUnMount}>
        <PopupButton
          className={classNames(
            className,
            {
              'show-option-menu': this.state.active,
            }, 'video-react-icon-settings menu-setting'
          )}
          onClick={(event) => this.handleClick(event)}
          inline={false}
        >

          {
            !this.state.showMenuSpeedOption &&
            <div className="menu-setting-items" onClick={this.clickOption}>
              <span>再生速度</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>{`${player.playbackRate == 1 ? '標準' : player.playbackRate}`}　＞</span>
            </div>
          }

          {
            this.state.showMenuSpeedOption &&
            <MenuSpeedOption items={items} onSelectItem={this.handleSelectItem} onClickBack={this.closeMenu} />

          }
        </PopupButton>
      </OutsideClickHandler>
    );
  }
}

SettingButton.propTypes = propTypes;
SettingButton.defaultProps = defaultProps;
