import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
  children: PropTypes.any,
  onClickBack: PropTypes.func
};

export default class Menu extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { onClickBack } = this.props;
    
    return (
      <div>
        <span onClick={onClickBack} className="btn-close-menu-speed">＜　再生速度</span>
        <hr style={{margin: '0px'}} />
        <ul className="">{this.props.children}</ul>
      </div>
      
    );
  }
}

Menu.propTypes = propTypes;
Menu.displayName = 'Menu';
