export default class CheckPolicy {
  check(element) {
    $('button.sign-up-user').attr('disabled', !$(element).is(":checked"))

    if ($(element).is(":checked")) {
      $('button.sign-up-user').removeClass('none-hover');
    } else {
      $('button.sign-up-user').addClass('none-hover');
    }
  }
}
