import React, { Component } from 'react';
import AnimationInspector from './AnimationInspector';

export default class CompareVersion extends Component {
  constructor(props) {
    super(props);
    const { animation_first_id, animation_last_id } = props;
    this.state = {
      isRefresh: false,
      animationFirstId: animation_first_id,
      animationLastId: animation_last_id
    };
  }

  reFreshNow = () => {
    this.setState({ isRefresh: !this.state.isRefresh });
  }

  setAnimationIdWhenChange = (animationId, AnimationNo) => {
    if(AnimationNo === 'firstAnimation') {
      this.setState({ animationFirstId: animationId });
    } else if(AnimationNo === 'lastAnimation') {
      this.setState({ animationLastId: animationId });
    }
  }

  render() {
    const { isRefresh, animationFirstId, animationLastId } = this.state;
    const {
      additional_info, akaire_file_id, avatar_url, baseURL, can_use_page_management, category,
      colors, company_id, complete_date, complete_updater_id, created_at, created_by, currentTicketId,
      current_limit_video, current_subscription_type, current_user_id, deleted_at, delivery_date, description,
      due_date, duplicate_completed, entity_id, entity_type, env, extensions, id, isAkaponProject, is_akapon_member,
      is_create_pass_video, is_create_video_embed, is_project_owner_member, member_setten, name, new_pic_ids,
      noti_compare_page, noti_compare_version, owner, page_last_animation, pass_word_share_url, password_share_akaire,
      pic_ids, port, priority_colors, project_id, project_type, public_history_chat, relationship, role,
      root_url, show_mode, start_date, status, token_public_url, total_file_size, updated_at, updated_by,
      url_token, windowHeight, windowWidth
    } = this.props;
    return (
      <div class='d-flex compare-version-screen'>
        <div class='col-6 first-version'>
          <AnimationInspector animation_id={animationFirstId}
            additional_info={additional_info} akaire_file_id={akaire_file_id} avatar_url={avatar_url} baseURL={baseURL}
            can_use_page_management={can_use_page_management} category={category} colors={colors}
            company_id={company_id} complete_date={complete_date} complete_updater_id={complete_updater_id}
            created_at={created_at} created_by={created_by} currentTicketId={currentTicketId}
            current_limit_video={current_limit_video} current_subscription_type={current_subscription_type}
            current_user_id={current_user_id} deleted_at={deleted_at} delivery_date={delivery_date}
            description={description} due_date={due_date} duplicate_completed={duplicate_completed}
            entity_id={entity_id} entity_type={entity_type} env={env} extensions={extensions} id={id}
            isAkaponProject={isAkaponProject} is_akapon_member={is_akapon_member}
            is_create_pass_video={is_create_pass_video} is_create_video_embed={is_create_video_embed}
            is_project_owner_member={is_project_owner_member} member_setten={member_setten} name={name}
            new_pic_ids={new_pic_ids} noti_compare_page={noti_compare_page} noti_compare_version={noti_compare_version}
            owner={owner} page_last_animation={page_last_animation} pass_word_share_url={pass_word_share_url}
            password_share_akaire={password_share_akaire} pic_ids={pic_ids} port={port} priority_colors={priority_colors}
            project_id={project_id} project_type={project_type} public_history_chat={public_history_chat}
            relationship={relationship} role={role} root_url={root_url} show_mode={show_mode} start_date={start_date}
            status={status} token_public_url={token_public_url} total_file_size={total_file_size} updated_at={updated_at}
            updated_by={updated_by} url_token={url_token} windowHeight={windowHeight} windowWidth={windowWidth}
            isRefresh={isRefresh} reFreshNow={this.reFreshNow} isFirstAnimation={true} setAnimationIdWhenChange={this.setAnimationIdWhenChange}
          />
        </div>
        <div class='col-6 second-version'>
          <AnimationInspector animation_id={animationLastId}
            additional_info={additional_info} akaire_file_id={akaire_file_id} avatar_url={avatar_url} baseURL={baseURL}
            can_use_page_management={can_use_page_management} category={category} colors={colors}
            company_id={company_id} complete_date={complete_date} complete_updater_id={complete_updater_id}
            created_at={created_at} created_by={created_by} currentTicketId={currentTicketId}
            current_limit_video={current_limit_video} current_subscription_type={current_subscription_type}
            current_user_id={current_user_id} deleted_at={deleted_at} delivery_date={delivery_date}
            description={description} due_date={due_date} duplicate_completed={duplicate_completed}
            entity_id={entity_id} entity_type={entity_type} env={env} extensions={extensions} id={id}
            isAkaponProject={isAkaponProject} is_akapon_member={is_akapon_member}
            is_create_pass_video={is_create_pass_video} is_create_video_embed={is_create_video_embed}
            is_project_owner_member={is_project_owner_member} member_setten={member_setten} name={name}
            new_pic_ids={new_pic_ids} noti_compare_page={noti_compare_page} noti_compare_version={noti_compare_version}
            owner={owner} page_last_animation={page_last_animation} pass_word_share_url={pass_word_share_url}
            password_share_akaire={password_share_akaire} pic_ids={pic_ids} port={port} priority_colors={priority_colors}
            project_id={project_id} project_type={project_type} public_history_chat={public_history_chat}
            relationship={relationship} role={role} root_url={root_url} show_mode={show_mode} start_date={start_date}
            status={status} token_public_url={token_public_url} total_file_size={total_file_size} updated_at={updated_at}
            updated_by={updated_by} url_token={url_token} windowHeight={windowHeight} windowWidth={windowWidth}
            isRefresh={isRefresh} reFreshNow={this.reFreshNow} isLastAnimation={true} setAnimationIdWhenChange={this.setAnimationIdWhenChange}
          />
        </div>
      </div>
    )
  }
}
