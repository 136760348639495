export default class DashboardClientModal {
  upgradeToOwner() {
    Popup.confirm('', {
      description: I18n.t('dashboard.modal.client_upgrade_to_owner'),
      iconHtml: '<i class="fas fa-info"></i>',
      confirmText: 'Owner版を利用する',
      cancelText: 'Owner版を利用しない',
      width: '46em',
      success: () => {
        window.location.href = Routes.owner_total_info_path()
      },
      cancel: () => {
        window.location.href = Routes.owner_dashboard_path()
      }
    })
  }
}
