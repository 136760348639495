import React, { Component } from 'react';
import { Popover, PopoverBody, Button } from 'reactstrap';

import ActivityItem from './ActivityItem';
import apiClient from '../util/api-client';

const instances = [];
let index = 0;

export default class ActivitiesButton extends Component {
  constructor() {
    super();
    instances.push(this);
    this.state = {
      isOpen: false,
      activities: [],
      activitiesCount: 0,
      currentPage: 0,
      isLoading: false,
      index: index++,
    };
  }
  componentDidMount() {
    this.fetch();
  }
  reload() {
    this.fetch(0, true);
  }
  fetch = (page = 0, isReset = false) => {
    this.setState({ isLoading: true });
    return this.fetchCount()
      .then(_ => {
        return apiClient().get(`/activities?page=${page}`)
          .then(_ => {
            this.setState({ activities: (isReset ? _.data : [...this.state.activities, ..._.data]), currentPage: page, isLoading: false })
          });
      }).catch(console.error);
  }
  fetchCount = () => {
    return apiClient().get('/activities_count')
      .then(_ => this.setState({ activitiesCount: _.data }))
      .catch(console.error);
  }
  fetchNext = () => {
    const { currentPage } = this.state;
    return this.fetch(currentPage + 1);
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { index, isOpen, activities, activitiesCount, isLoading } = this.state;
    const domId = `activities-popover-${index}`;
    return (
      <a className="header__nav--link" href="javascript:void(0)">
        <span id={domId} onClick={this.toggle}>
          <span className="app-icon icon-bell d-none d-md-inline-block"></span>
          <span className="header__nav__text">アクティビティ</span>
        </span>
        <Popover className="scrollbar-primary thin activities-popover" placement="bottom" isOpen={isOpen} target={domId} toggle={this.toggle}>
          <PopoverBody>
            {
              activities.length > 0 ? (
                <div className="activities">
                  {
                    activities.map((activity, i) => {
                      return <ActivityItem activity={activity} key={i} />
                    })
                  }
                  {
                    activities.length < activitiesCount && (
                      <div className="d-flex justify-content-center">
                        <Button color="secondary" onClick={this.fetchNext} disabled={isLoading}>もっとみる</Button>
                      </div>
                    )
                  }
                </div>
              ) : (
                <div>アクティビティはありません</div>
              )
            }
          </PopoverBody>
        </Popover>
      </a>
    );
  }
}

export function reload() {
  instances.forEach(_ => _.reload());
}
