import React, { Component } from 'react';
import { Card, CardBody, Button, Input, Form } from 'reactstrap';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';

import TextEditor from './TextEditor';
import { canCreateComment } from '../authorizations';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

export default class AnimationCommentForm extends Component {
  constructor() {
    super();
    this.state = {
      body: '',
      isSubmitting: false,
      files: [],
    };
  }
  onChangeBody = (body) => {
    this.setState({ body });
  }
  onAddFiles = (files) => {
    this.setState({
      files: [...this.state.files, ...files]
    });
  }
  clearForm = (text) => {
    this.setState({
      isSubmitting: false,
      body: text,
      files: [],
    });
  };
  is_still_space = (file_size) => {
    const {user: {total_file_size: totalFileSize, total_file_size_on_this_cycle: totalAllCycle, max_storage: maxStorage, total_file_size_limit: totalFileSizeLimit }} = this.props;
    return true
    return (file_size + Number(totalAllCycle) <= Number(totalFileSizeLimit) || file_size + Number(totalFileSize) <= Number(maxStorage) || Number(maxStorage) == 0 || file_size == 0)
  }
  onSubmit = (event) => {
    const { onSubmit } = this.props;
    const { body, files } = this.state;
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const totalFilesSize = files.reduce((sum, file) => sum + file.size, 0);
    if (totalFilesSize >= Math.pow(10, 8)) {
      toastr.error('100MB以上の為、アップロードが出来ません');
      this.clearForm(body);
      return
    }
    // if (!this.is_still_space(totalFilesSize)) {
    //   this.clearForm(body);
    //   return
    // }
    onSubmit(body, files).then(() => {
      this.clearForm('');
    });
  }
  onClickFileButton = () => {
    this.refs.fileInput.click();
  }
  onFileChange = ({ target }) => {
    const { files } = target;
    this.setState({
      files: [...this.state.files, ...files]
    });
    target.value = null;
  }
  renderUnregisterUserTooltip() {
    return (
      <ReactTooltip id="comment-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          権限がありません
        </div>
      </ReactTooltip>
    );
  }

  renderPromotionTooltip() {
    return (
      <ReactTooltip id="comment-tooltip" place="top" effect="solid" delayHide={1000} type="light">
        <div className="text-danger font-weight-bold">
          こちらは会員専用機能となります。
        </div>
        <div className="mt-2 mb-2">
          無料会員でも全機能が利用可！<br />
          保存期間も『無期限』
          <span className="small">※利用状況により3ヶ月間</span>
        </div>
        <a href="/signup" style={{ pointerEvents: 'initial' }} className="btn btn-warning" target="_blank">
          会員登録はこちら
        </a>
      </ReactTooltip>
    );
  }
  render() {
    const { disabled, placeholder, mobile: isMobile, currentUserId, role: currentUserRole, owner} = this.props;
    const { body, isSubmitting, files } = this.state;
    const unsubmittable = isSubmitting || body.length === 0 || !currentUserId && owner || currentUserId && !canCreateComment(currentUserRole) && owner;
    return (
      <div className="l-container">
        {/* <div className="l-main">
          <div className="animation-comment-form">
            <Form onSubmit={this.onSubmit}>
              <div className="input">
                <TextEditor body={body} files={files} onChangeBody={this.onChangeBody} onAddFiles={this.onAddFiles} disabled={disabled || isSubmitting} rows={4} inputId="animation-comment-body"  placeholder={placeholder || '動画全体に対するコメントはこちらに入力してください'} />
                {
                  !isMobile && (
                    <p className="attach-file">ドラッグ&ドロップで画像ファイルを添付できます。</p>
                  )
                }
              </div>
              <div className="d-flex justify-content-end">
                <div className="button-image" data-tip data-for="comment-tooltip" >
                  <Button type="button" className="px-4 mr-2 button-upload-image" onClick={this.onClickFileButton} disabled={unsubmittable}>
                    <FontAwesome name="image" />
                  </Button>
                </div>
                <input type="file" hidden onChange={this.onFileChange} ref="fileInput" accept="image/*" />
                <div className="button-image" data-tip data-for="comment-tooltip">
                  <Button type="submit" disabled={unsubmittable} className="submit">投稿</Button>
                </div>
              </div>
            </Form>
          </div>
        </div> */}
        {/* Dont display it anymore */}
        {/* {!currentUserId && owner && this.renderPromotionTooltip()} */}
        {/* {currentUserId && !canCreateComment(currentUserRole) & owner && this.renderUnregisterUserTooltip()} */}
      </div>
    )
  }
}
