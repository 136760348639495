export const ALLOWED_FILE_TYPES = [
  'video/mp4',
  'video/mov',
  'video/avi',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/x-msvideo'
];

export function isMobileOrTablet() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // check device is android
  if (/android/i.test(userAgent)) {
    return true;
  }

  // check device is iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}
