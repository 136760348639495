import React, { Component } from 'react';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import reactWindowSize from 'react-window-size';

class RegistrationModal extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowRegistrationModal: true,
      shouldShowTermsModal: false,
    }
  }
  toggle = bool => name =>
    this.setState({ [`shouldShow${name}`]: bool })
  open = this.toggle(true)
  close = this.toggle(false)
  termsModal () {
    const { terms } = this.props;
    const { shouldShowTermsModal } = this.state;
    return (
      <Modal className="registration-modal modal-lg" isOpen={shouldShowTermsModal}>
        <ModalHeader toggle={this.close.bind(this, 'TermsModal')}>
          利用規約
        </ModalHeader>
        <ModalBody>
          <span dangerouslySetInnerHTML={{__html: terms}}></span>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={this.close.bind(this, 'TermsModal')}>閉じる</Button>
        </ModalFooter>
      </Modal> );
  }
  renderNormal() {
    const { shouldShowRegistrationModal } = this.state;
    return (
      <Modal className="registration-modal modal-lg" isOpen={shouldShowRegistrationModal}>
        <ModalHeader>
          会員登録(無料)
        </ModalHeader>
        <ModalBody>
          <Table className="table-bordered">
            <thead>
              <tr>
                <th style={{ width: '20%' }}></th>
                <th className="not-registration">会員登録なしで<br />利用する</th>
                <th className="registration">会員登録して<br />利用する</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>プラン</th>
                <td>無料(非会員)</td>
                <td>無料(会員)</td>
              </tr>
              <tr>
                <th>料金</th>
                <td>無料</td>
                <td>無料</td>
              </tr>
              <tr>
                <th>赤入れ機能</th>
                <td>あり</td>
                <td>あり</td>
              </tr>
              <tr>
                <th>動画容量</th>
                <td>0.5GB</td>
                <td>0.5GB</td>
              </tr>
              <tr>
                <th>招待人数</th>
                <td>0人</td>
                <td>無制限</td>
              </tr>
              <tr>
                <th>保存期間</th>
                <td>プロジェクト<br />作成後30日間</td>
                <td>プロジェクト<br />作成後30日間</td>
              </tr>
              <tr>
                <th>広告表示</th>
                <td>あり</td>
                <td>あり</td>
              </tr>
              <tr className="start-btn-area">
                <th style={{ width: '20%' }}></th>
                <td>
                  <a href="/p/projects/new" className="btn btn-lg btn-warning">開始する</a>
                </td>
                <td>
                  <Button color="primary" size="lg" onClick={this.close.bind(this, 'RegistrationModal')}>開始する</Button>
                </td>
              </tr>
            </tbody>
          </Table>
          { this.termsModal() }
          <p className="text-center">会員登録なしでご利用になる場合は、<a href="#" onClick={this.open.bind(this, 'TermsModal')}>利用規約</a> に同意したものとさせて頂きます。</p>
          <p className="text-center">すでに登録済みの方は<a href="/users/sign_in">こちら</a></p>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={this.close.bind(this, 'RegistrationModal')}>閉じる</Button>
        </ModalFooter>
      </Modal> );
  };
  renderMobile() {
    const { shouldShowRegistrationModal } = this.state;
    return (
      <Modal className="registration-modal modal-lg" isOpen={shouldShowRegistrationModal}>
        <ModalHeader toggle={this.close.bind(this, 'RegistrationModal')}>
          会員登録(無料)
        </ModalHeader>
        <p className="text-center mt-3">すでに登録済みの方は<a href="/users/sign_in">こちら</a></p>
        <ModalBody>
          <div className="box not-registration">
            <div className="text-center mb-3">
              <a href="/p/projects/new" className="btn btn-lg btn-warning">会員登録なしで利用する<br /></a>
              { this.termsModal() }
              <p className="text-center mt-2">会員登録なしでご利用になる場合は、<br />
                <a href="#" onClick={this.open.bind(this, 'TermsModal')}>利用規約</a>
                に同意したものとさせて頂きます。</p>
            </div>
            <dl>
              <dt>プラン</dt>
              <dd>無料(非会員)</dd>
            </dl>
            <dl>
              <dt>料金</dt>
              <dd>無料</dd>
            </dl>
            <dl>
              <dt>赤入れ機能</dt>
              <dd>あり</dd>
            </dl>
            <dl>
              <dt>動画容量</dt>
              <dd>0.5GB</dd>
            </dl>
            <dl>
              <dt>招待人数</dt>
              <dd>0人</dd>
            </dl>
            <dl>
              <dt>保存期間</dt>
              <dd>プロジェクト<br />作成後30日間</dd>
            </dl>
            <dl>
              <dt>広告表示</dt>
              <dd>あり</dd>
            </dl>
          </div>

          <div className="box member">
            <div className="text-center mb-3">
              <Button color="primary" size="lg" onClick={this.close.bind(this, 'RegistrationModal')}>会員登録して利用する<br /></Button>
            </div>
            <dl>
              <dt>プラン</dt>
              <dt>無料(会員)</dt>
            </dl>
            <dl>
              <dt>料金</dt>
              <dd>無料</dd>
            </dl>
            <dl>
              <dt>赤入れ機能</dt>
              <dd>あり</dd>
            </dl>
            <dl>
              <dt>動画容量</dt>
              <dd>0.5GB</dd>
            </dl>
            <dl>
              <dt>招待人数</dt>
              <dd>無制限</dd>
            </dl>
            <dl>
              <dt>保存期間</dt>
              <dd>プロジェクト<br />作成後30日間</dd>
            </dl>
            <dl>
              <dt>広告表示</dt>
              <dd>あり</dd>
            </dl>
          </div>
        </ModalBody>
      </Modal> );
  };
  render() {
    const { windowWidth } = this.props;
    return windowWidth > 991 ? this.renderNormal() : this.renderMobile();
  }
}

export default reactWindowSize(RegistrationModal);
